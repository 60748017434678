import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import withInputFormValidation from "hoc/inputFormValidation";

const useStyles = makeStyles((theme) =>
  createStyles({
    labelRoot: {
      color: theme.palette.text.tertiaryBlack,
      fontSize: theme.typography.pxToRem(20),
    },
    labelShrink: {
      color: theme.palette.text.formLabel,
      transform: "translate(0, 1.5px) scale(1)",
      fontSize: theme.typography.pxToRem(16),
    },
    input: {
      fontSize: theme.typography.pxToRem(20),
      color: theme.palette.text.input,
      "&::placeholder": {
        textOverflow: "ellipsis !important",
        color: theme.palette.text.input,
      },
      "&:-webkit-autofill": {
        transition: "all 0s 50000s",
      },
    },
    inputRoot: {
      height: "100%",
    },
  })
);

const InputTextField = ({
  label,
  helperText,
  condensed,
  margin,
  onChange,
  error,
  disabled,
  variant,
  size,
  defaultValue,
  secured,
  options,
  cy,
  endAdornment,
  form,
  InputProps,
  rootStyle = {},
  ...props
}) => {
  const classes = useStyles();

  const [focused, setFocused] = useState(false);

  const InputLabelProps = {
    shrink: focused || !!props.value,
    classes: {
      root: classes.labelRoot,
      shrink: classes.labelShrink,
    },
  };
  InputProps = {
    ...InputProps,
    classes: { root: classes.inputRoot, input: classes.input },
  };
  if (endAdornment) {
    InputProps["endAdornment"] = endAdornment;
  }

  const onFocus = (e) => {
    if (typeof props.onFocus === "function") {
      props.onFocus(e);
    }
    setFocused(true);
  };

  const onBlur = (e) => {
    if (typeof props.onBlur === "function") {
      props.onBlur(e);
    }
    setFocused(false);
  };

  return (
    <TextField
      label={label}
      helperText={helperText}
      fullWidth={!condensed}
      style={{ ...rootStyle, margin: margin, textAlign: "left" }}
      onChange={onChange}
      error={!!error}
      type={secured ? "password" : ""}
      disabled={disabled}
      variant={variant || "standard"}
      size={size || "medium"}
      defaultValue={defaultValue}
      data-cy={cy}
      InputLabelProps={InputLabelProps}
      InputProps={InputProps}
      {...props}
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};

export default React.memo(withInputFormValidation(InputTextField));
