import React, { useState, useEffect } from 'react';
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import cn from "classnames";
import { Grid, makeStyles } from "@material-ui/core";
import { backgroundImg } from "assets/images/app";
import { partners } from "assets/images/partners";

import Services from "./Services";
import Solutions from "./Solutions";
import ConsultationForm from "./ConsultationForm";
import Expertise from "./Expertise";
import Stack from "./Stack";


const useStyles = makeStyles((theme) => ({
  partnerImageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    textRendering: "optimizeSpeed", 
    fontFamily: "Open Sans,sans-serif", 
    fontSize: "16px", 
    color: "#3b3c3d",
    boxSizing: "border-box", 
    height: "73px",
    width: "10215%", 
    position: "relative", 
    transitionDuration: "0s",
  },
  partnerImageHolder: {
    float: "left", 
    position: "relative", 
    width: "114.857px", 
    marginRight: "10px", 
    marginLeft: "10px", 
    marginBottom: "10px",
  },
  serviceTabContainerData: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "624.281px"
  },
  serviceTabContentContainer: {
    position: "relative",
    width: "92%",
    transitionDuration: "0s",
  },
  serviceTabContent: {
    float: "left",
    listStyle: "none",
    position: "relative",
  },
}));

const Home = () => {

    const classes = useStyles();
    const intl = useIntl();
    const { formatMessage } = intl;

    const renderPartnerSlider = () =>{
        return <Grid xs={12} container className={cn("partners__slider", classes.partnerImageContainer)} alignItems='center' alignContent='center' >
            {(partners || [])?.map((action) => {
                return (
                    <Grid key={action?.id} xs={2} className={classes.partnerImageHolder}>
                        <div className="partners__item bx-clone">
                            <img src={action?.img} width="127" height="47" alt="" className="lazy entered loaded" sizes="100w"/>
                        </div>
                    </Grid>
                );
            })}
        </Grid>
    }

    return (
        <React.Fragment>
            <main id="maincontent" className="page-main lowercase-headers">
                <div className="home-page">
                    <div className="area_content ">
                        <div className="sb-wrapper">
                            <div className="sb-title-wrap">
                                <h1 className="sb-heading">
                                    Software Development and Consulting  
                                </h1>
                                <div className="sb-title-row">
                                    <div className="sb-title">
                                        <p>We</p>
                                    </div>
                                    <div className="sb-animated-inner" style={{width: "190px"}}>
                                        <div className="sb-animated-label">Action</div>
                                        <div className="sb-animated-word">bring</div>
                                    </div>
                                    <div className="sb-title">
                                        <p>innovation to life</p>
                                    </div>
                                </div>
                            </div>
                            <div className="sb-list-wrap">
                                <div className="sb-slide sb-active">
                                    <div className="sb-slide-image">
                                        <img alt="" className="lazy entered loaded" src={backgroundImg} />
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container"></div>
                    <div className="spectrecreation_area_clients spectrecreation_area_content ">
                        <section className="partners">
                            <div className="container">
                                <div className="partners__text col-12 step-up">
                                    <p>
                                        At Spectre Creation, our journey has been defined by a commitment to transform businesses on a grand scale. 
                                        Since 2016, we've been dedicated to crafting powerful and adaptable digital solutions that not only satisfy the needs of 
                                        today but also unlock the limitless opportunities of tomorrow.
                                    </p>
                                </div>
                                <div className="partners__list-wrapper waypoint waypoint-reveal">
                                    <div className="partners__list step-up">
                                        <div className="bx-wrapper" style={{maxWidth: "1754px"}}>
                                            <div className="bx-viewport"
                                                style={{width: "100%", overflow: "hidden", position: "relative", height: "73px"}}>                                                
                                                {renderPartnerSlider()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                    <Services />
                    <Solutions />
                    <Stack />
                    <Expertise />
                    {/* Disabled for now */}
                    {/* <ConsultationForm /> */}
                </div>
            </main>

        </React.Fragment>
    );
}

export default Home;