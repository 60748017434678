import MaintenancePage from "./components";
import NonAuthLayout from "components/NonAuthLayout";
export const ROUTE_MAINTENANCE = "/maintenance";

/* TODO: add redirect here instead of being coded in the Login Container */
export default { 
  routeProps: [
    {
      path: ROUTE_MAINTENANCE,
      exact: true,
      component: MaintenancePage,
      isAuthProtected: false,
      authRequired: false,
      layout: NonAuthLayout
    },
  ],
  name: "MaintenancePage",
  authscreen: false,
  enabled: true,
  default: true,
};
