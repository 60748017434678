import { put, takeLatest, all, spawn } from "redux-saga/effects";
import { events } from "../actions";

import { postCallRequest } from "./marketing";

function* requestSaga() {
  const sagas = [
    {
      event: events.marketing.callback.REQUEST,
      generator: postCallRequest,
      method: takeLatest,
    },
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield saga.method.call(null, saga.event, saga.generator);
            break;
          } catch (e) {
            yield put({ type: events.loading.COMPLETED });
            console.error(e);
          }
        }
      })
    )
  );
}

export default requestSaga;