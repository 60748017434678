import React from "react";

export const getMainMenus = () => {

  return [
    {
      label: "COMMON_ABOUT",
      isActive: false,
      hasSubMenus: true,
      headerSubMenu:{
        title: "COMMON_COMPANY",
        description: "",
        columns:[
          {
            title: "COMMON_COMPANY",                        
            links:[{
                link: "/about/company",
                name: "ABOUT_COMPANY",
                isActive: true,
              },
              {
                link: "/management-team",
                name: "COMMON_LEADERSHIP",
                isActive: false,
              },
              {
                link: "/experts",
                name: "COMMON_EXPERTS",
                isActive: false,
              },
              {
                link: "/case-studies",
                name: "COMMON_PORTFOLIO",
                isActive: false,
              },
              {
                link: "/about/testimonials",
                name: "CLIENTS_REVIEW",
                isActive: false,
              },
              {
                link: "/about/partnerships",
                name: "OUR_PARTNERS",
                isActive: false,
              },
              {
                link: "/about/locations",
                name: "COMMON_LOCATIONS",
                isActive: true,
              }
            ]
          },
          {
            title: "COMMON_APPROACH",
            links: [
              {
                link: "/about/how-we-start",
                name: "BUSINESS_HOW_TO",
                isActive: true,
              },
              {
                link: "/about/how-we-work",
                name: "APPROACH_SOFTWARE_DEVELOPMENT",
                isActive: true,
              }
            ]
          },
          {
            title: "JOIN_US",
            links:[
              {
                link: "/about/become-our-agent",
                name: "BECOME_AGENT",
                isActive: true,
              },
              {
                link: "/about/careers",
                name: "CAREERS",
                isActive: true,
              },
              {
                link: "/about/referral-program",
                name: "REFERRAL_PROGRAM",
                isActive: true,
              }
            ]
          }
        ]
      }
    },
    {
      label: "COMMON_SERVICES",
      isActive: false,
      hasSubMenus: true,
      headerSubMenu:{
        title: "COMMON_SERVICES",
        description: "SERVICES_DESCRIPTION",
        columns:[
          {
            title: "",                        
            links:[
              {
                link: "/services/software-development",
                name: "SOFTWARE_DEVELOPMENT",
                isActive: true,
              },
              {
                link: "/services/web-development",
                name: "WEB_DEVELOPMENT",
                isActive: true,
              },
              {
                link: "/services/mobile-app-development",
                name: "MOBILE_DEVELOPMENT",
                isActive: true,
              },
            ]
          },
          {
            title: "",                        
            links:[
              {
                link: "/software-testing",
                name: "TESTING_QA",
                isActive: true,
              },
              {
                link: "/services/application",
                name: "APPLICATION_SERVICES",
                isActive: true,
              },
              {
                link: "/services/web-design",
                name: "UI_UX",
                isActive: true,
              },
            ]
          },
          {
            title: "",
            links: [
              {
                link: "/services/it-infrastructure",
                name: "INFRASTRUCTURE_SERVICES",
                isActive: true,
              },
              {
                link: "/services/digital-transformation",
                name: "DIGITAL_TRANSFORMATION",
                isActive: true,
              },
              {
                link: "/services/managed-it",
                name: "IT_SERVICES",
                isActive: true,
              },
            ]
          },
          {
            title: "",
            links: [
              {
                link: "/services/it-outsourcing",
                name: "IT_OUTSOURCING",
                isActive: true,
              },
              {
                link: "/services/it-consulting",
                name: "IT_CONSULTING",
                isActive: true,
              },
              {
                link: "/it-support",
                name: "IT_SUPPORT",
                isActive: true,
              },
            ]
          },
          {
            title: "",
            links: [
              {
                link: "/services/analytics",
                name: "DATA_ANALYTICS",
                isActive: true,
              },
              {
                link: "/services/security",
                name: "CYBERSECURITY",
                isActive: true,
              }
            ]
          }
        ]
      }
    },
    {
      label: "COMMON_SOLUTIONS",
      isActive: false,
      hasSubMenus: true,
      headerSubMenu:{
        title: "COMMON_SOLUTIONS",
        description: "OUR_SOLUTION_DESCRIPTIONS",
        columns:[
          {
            title: "COMMON_SOLUTIONS",                        
            links:[
              {
                link: "/services/software-development/erp",
                name: "ERP",
                isActive: true,
              },
              {
                link: "/services/it-consulting/operations",
                name: "OPERATIONS_MANAGEMENT",
                isActive: true,
              },
              {
                link: "/financial-management",
                name: "FINANCIAL_MANAGEMENT",
                isActive: false,
              },
              {
                link: "/scm",
                name: "SUPPLY_CHAIN_MANAGEMENT",
                isActive: true,
              },
              {
                link: "/asset-management",
                name: "ASSET_MANAGEMENT",
                isActive: true,
              },
              {
                link: "/fleet-management",
                name: "FLEET_MANAGEMENT",
                isActive: true,
              },
              {
                link: "/services/analytics",
                name: "DATA_ANALYTICS",
                isActive: true,
              },
              {
                link: "/services/software-development/hr",
                name: "HUMAN_RESOURCES",
                isActive: true,
              },
            ]
          },
          {
            title: "",
            links: [ 
              {
                link: "/services/crm",
                name: "CRM",
                isActive: true,
              },                           
              {
                link: "/elearning",
                name: "E_LEARNING",
                isActive: true,
              },
              {
                link: "/retail/marketing-software",
                name: "MARKETING_ADVERTISING",
                isActive: true,
              },
              {
                link: "/ecommerce",
                name: "E_COMMERCE",
                isActive: true,
              },
              {
                link: "/software-development/kiosk",
                name: "KIOSK_SOFTWARE",
                isActive: false,
              },
              {
                link: "/web-portal-development",
                name: "WEB_PORTALS",
                isActive: true,
              },
              {
                link: "/document-management/automation",
                name: "DOCUMENT_MANAGEMENT",
                isActive: true,
              },
              {
                link: "/services/web-development/cms",
                name: "CONTENT_MANAGEMENT",
                isActive: false,
              },
              {
                link: "/project-management/custom",
                name: "PROJECT_MANAGEMENT",
                isActive: true,
              }
            ]
          },
          {
            title: "INDUSTRIES",
            links:[
              {
                link: "/healthcare",
                name: "HEALTHCARE",
                isActive: true,
              },
              {
                link: "/financial-software-development",
                name: "FINANCIAL_SERVICES",
                isActive: true,
              },
              {
                link: "/manufacturing",
                name: "MANUFACTURING",
                isActive: true,
              },
              {
                link: "/professional-services",
                name: "PROFESSIONAL_SERVICES",
                isActive: true,
              },
              {
                link: "/retail",
                name: "RETAIL",
                isActive: true,
              },
              {
                link: "/transportation-and-logistics",
                name: "TRANSPORTATION_LOGISTICS",
                isActive: false,
              },
              {
                link: "/telecommunications",
                name: "TELECOMMUNICATIONS",
                isActive: true,
              },
              {
                link: "/oil-gas",
                name: "MINING",
                isActive: true,
              },
              {
                link: "/case-studies/construction",
                name: "CONSTRUCTION",
                isActive: true,
              }
            ]
          }
        ]
      }
    },
    {
      label: "TECHNOLOGIES",
      isActive: false,
      hasSubMenus: true,
      headerSubMenu:{
        title: "TECHNOLOGIES",
        description: "OUR_TECHNOLOGIES_DESCRIPTION",
        columns:[
          {
            title: "TECHNOLOGY_STACKS",                        
            links:[
              {
                link: "/services/java-development",
                name: "JAVA",
                isActive: true,
              },
              {
                link: "/services/hire-python-developers",
                name: "PYTHON",
                isActive: true,
              },
              {
                link: "/services/php",
                name: "PHP",
                isActive: true,
              },
              {
                link: "/services/cpp-development",
                name: "C_PLUS_PLUS",
                isActive: true,
              },
              {
                link: "/services/nodejs",
                name: "NODEJS",
                isActive: true,
              },
              {
                link: "/services/javascript",
                name: "JAVASCRIPT",
                isActive: true,
              },
              {
                link: "/services/hire-react-native-developers",
                name: "REACT_NATIVE",
                isActive: true,
              },
              {
                link: "/services/mobile-app-development",
                name: "MOBILE",
                isActive: true,
              }
            ]
          },
          {
            title: "TRENDING",
            links: [
              {
                link: "/cloud",
                name: "CLOUD",
                isActive: true,
              },
              {
                link: "/services/software-development/saas",
                name: "SAAS",
                isActive: true,
              },
              {
                link: "/services/iot",
                name: "IOT",
                isActive: true,
              },
              {
                link: "/services/big-data",
                name: "BIG_DATA",
                isActive: true,
              },
              {
                link: "/services/data-science",
                name: "DATA_SCIENCE",
                isActive: true,
              },
              {
                link: "/services/data-science/machine-learning",
                name: "MACHINE_LEARNING",
                isActive: false,
              },
              {
                link: "/artificial-intelligence",
                name: "ARTIFICIAL_INTELLIGENCE",
                isActive: false,
              },
              {
                link: "/virtual-reality/development",
                name: "VIRTUAL_REALITY",
                isActive: false,
              },
              {
                link: "/services/augmented-reality-development",
                name: "AUGMENTED_REALITY",
                isActive: false,
              },
              {
                link: "/image-analysis",
                name: "COMPUTER_VISION",
                isActive: false,
              }
            ]
          },
          {
            title: "PLATFORMS",
            links:[
              {
                link: "/microsoft",
                name: "MICROSOFT",
                isActive: true,
              },
              {
                link: "/cloud/azure/consulting",
                name: "AZURE",
                isActive: true,
              },
              {
                link: "/sharepoint-office-365",
                name: "OFFICE_365",
                isActive: true,
              },
              {
                link: "/aws",
                name: "AWS",
                isActive: true,
              }
            ]
          }
        ]
      }
    },
    {
      label: "PORTFOLIO",
      mainLink: "/case-studies",
      isActive: false,
      hasSubMenus: false,
    }
  ];
};

export const getMobileMenus = () => {

  return [
    {
      label: "COMMON_ABOUT",
      isActive: false,
      hasSubMenus: true,
      columns:[
        {
          title: "COMMON_COMPANY",                        
          links:[{
              link: "/about/company",
              name: "ABOUT_COMPANY",
              isActive: true,
            },
            {
              link: "/management-team",
              name: "COMMON_LEADERSHIP",
              isActive: false,
            },
            {
              link: "/experts",
              name: "COMMON_EXPERTS",
              isActive: false,
            },
            {
              link: "/case-studies",
              name: "COMMON_PORTFOLIO",
              isActive: false,
            },
            {
              link: "/about/testimonials",
              name: "CLIENTS_REVIEW",
              isActive: false,
            },
            {
              link: "/about/partnerships",
              name: "OUR_PARTNERS",
              isActive: false,
            },
            {
              link: "/about/locations",
              name: "COMMON_LOCATIONS",
              isActive: true,
            }
          ]
        },
        {
          title: "COMMON_APPROACH",
          links: [
            {
              link: "/about/how-we-start",
              name: "BUSINESS_HOW_TO",
              isActive: true,
            },
            {
              link: "/about/how-we-work",
              name: "APPROACH_SOFTWARE_DEVELOPMENT",
              isActive: true,
            }
          ]
        },
        {
          title: "JOIN_US",
          links:[
            {
              link: "/about/become-our-agent",
              name: "BECOME_AGENT",
              isActive: true,
            },
            {
              link: "/about/careers",
              name: "CAREERS",
              isActive: true,
            },
            {
              link: "/about/referral-program",
              name: "REFERRAL_PROGRAM",
              isActive: true,
            }
          ]
        }
      ]
    },
    {
      label: "COMMON_SERVICES",
      isActive: false,
      hasSubMenus: true,
      columns:[
        {
          title: "",                        
          links:[
            {
              link: "/services/software-development",
              name: "SOFTWARE_DEVELOPMENT",
              isActive: true,
            },
            {
              link: "/services/web-development",
              name: "WEB_DEVELOPMENT",
              isActive: true,
            },
            {
              link: "/services/mobile-app-development",
              name: "MOBILE_DEVELOPMENT",
              isActive: true,
            },
          ]
        },
        {
          title: "",                        
          links:[
            {
              link: "/software-testing",
              name: "TESTING_QA",
              isActive: true,
            },
            {
              link: "/services/application",
              name: "APPLICATION_SERVICES",
              isActive: true,
            },
            {
              link: "/services/web-design",
              name: "UI_UX",
              isActive: true,
            },
          ]
        },
        {
          title: "",
          links: [
            {
              link: "/services/it-infrastructure",
              name: "INFRASTRUCTURE_SERVICES",
              isActive: true,
            },
            {
              link: "/services/digital-transformation",
              name: "DIGITAL_TRANSFORMATION",
              isActive: true,
            },
            {
              link: "/services/managed-it",
              name: "IT_SERVICES",
              isActive: true,
            },
          ]
        },
        {
          title: "",
          links: [
            {
              link: "/services/it-outsourcing",
              name: "IT_OUTSOURCING",
              isActive: true,
            },
            {
              link: "/services/it-consulting",
              name: "IT_CONSULTING",
              isActive: true,
            },
            {
              link: "/it-support",
              name: "IT_SUPPORT",
              isActive: true,
            },
          ]
        },
        {
          title: "",
          links: [
            {
              link: "/services/analytics",
              name: "DATA_ANALYTICS",
              isActive: true,
            },
            {
              link: "/services/security",
              name: "CYBERSECURITY",
              isActive: true,
            }
          ]
        }
      ]
    },
    {
      label: "COMMON_SOLUTIONS",
      isActive: false,
      hasSubMenus: true,
      columns:[
        {
          title: "COMMON_SOLUTIONS",                        
          links:[
            {
              link: "/services/software-development/erp",
              name: "ERP",
              isActive: true,
            },
            {
              link: "/services/it-consulting/operations",
              name: "OPERATIONS_MANAGEMENT",
              isActive: true,
            },
            {
              link: "/financial-management",
              name: "FINANCIAL_MANAGEMENT",
              isActive: false,
            },
            {
              link: "/scm",
              name: "SUPPLY_CHAIN_MANAGEMENT",
              isActive: true,
            },
            {
              link: "/asset-management",
              name: "ASSET_MANAGEMENT",
              isActive: true,
            },
            {
              link: "/fleet-management",
              name: "FLEET_MANAGEMENT",
              isActive: true,
            },
            {
              link: "/services/analytics",
              name: "DATA_ANALYTICS",
              isActive: true,
            },
            {
              link: "/services/software-development/hr",
              name: "HUMAN_RESOURCES",
              isActive: true,
            },
          ]
        },
        {
          title: "",
          links: [ 
            {
              link: "/services/crm",
              name: "CRM",
              isActive: true,
            },                           
            {
              link: "/elearning",
              name: "E_LEARNING",
              isActive: true,
            },
            {
              link: "/retail/marketing-software",
              name: "MARKETING_ADVERTISING",
              isActive: true,
            },
            {
              link: "/ecommerce",
              name: "E_COMMERCE",
              isActive: true,
            },
            {
              link: "/software-development/kiosk",
              name: "KIOSK_SOFTWARE",
              isActive: false,
            },
            {
              link: "/web-portal-development",
              name: "WEB_PORTALS",
              isActive: true,
            },
            {
              link: "/document-management/automation",
              name: "DOCUMENT_MANAGEMENT",
              isActive: true,
            },
            {
              link: "/services/web-development/cms",
              name: "CONTENT_MANAGEMENT",
              isActive: false,
            },
            {
              link: "/project-management/custom",
              name: "PROJECT_MANAGEMENT",
              isActive: true,
            }
          ]
        },
        {
          title: "INDUSTRIES",
          links:[
            {
              link: "/healthcare",
              name: "HEALTHCARE",
              isActive: true,
            },
            {
              link: "/financial-software-development",
              name: "FINANCIAL_SERVICES",
              isActive: true,
            },
            {
              link: "/manufacturing",
              name: "MANUFACTURING",
              isActive: true,
            },
            {
              link: "/professional-services",
              name: "PROFESSIONAL_SERVICES",
              isActive: true,
            },
            {
              link: "/retail",
              name: "RETAIL",
              isActive: true,
            },
            {
              link: "/transportation-and-logistics",
              name: "TRANSPORTATION_LOGISTICS",
              isActive: false,
            },
            {
              link: "/telecommunications",
              name: "TELECOMMUNICATIONS",
              isActive: true,
            },
            {
              link: "/oil-gas",
              name: "MINING",
              isActive: true,
            },
            {
              link: "/case-studies/construction",
              name: "CONSTRUCTION",
              isActive: true,
            }
          ]
        }
      ]
    },
    {
      label: "TECHNOLOGIES",
      isActive: false,
      hasSubMenus: true,
      columns:[
        {
          title: "TECHNOLOGY_STACKS",                        
          links:[
            {
              link: "/services/java-development",
              name: "JAVA",
              isActive: true,
            },
            {
              link: "/services/hire-python-developers",
              name: "PYTHON",
              isActive: true,
            },
            {
              link: "/services/php",
              name: "PHP",
              isActive: true,
            },
            {
              link: "/services/cpp-development",
              name: "C_PLUS_PLUS",
              isActive: true,
            },
            {
              link: "/services/nodejs",
              name: "NODEJS",
              isActive: true,
            },
            {
              link: "/services/javascript",
              name: "JAVASCRIPT",
              isActive: true,
            },
            {
              link: "/services/hire-react-native-developers",
              name: "REACT_NATIVE",
              isActive: true,
            },
            {
              link: "/services/mobile-app-development",
              name: "MOBILE",
              isActive: true,
            }
          ]
        },
        {
          title: "TRENDING",
          links: [
            {
              link: "/cloud",
              name: "CLOUD",
              isActive: true,
            },
            {
              link: "/services/software-development/saas",
              name: "SAAS",
              isActive: true,
            },
            {
              link: "/services/iot",
              name: "IOT",
              isActive: true,
            },
            {
              link: "/services/big-data",
              name: "BIG_DATA",
              isActive: true,
            },
            {
              link: "/services/data-science",
              name: "DATA_SCIENCE",
              isActive: true,
            },
            {
              link: "/services/data-science/machine-learning",
              name: "MACHINE_LEARNING",
              isActive: true,
            },
            {
              link: "/artificial-intelligence",
              name: "ARTIFICIAL_INTELLIGENCE",
              isActive: true,
            },
            {
              link: "/virtual-reality/development",
              name: "VIRTUAL_REALITY",
              isActive: true,
            },
            {
              link: "/services/augmented-reality-development",
              name: "AUGMENTED_REALITY",
              isActive: true,
            },
            {
              link: "/image-analysis",
              name: "COMPUTER_VISION",
              isActive: true,
            }
          ]
        },
        {
          title: "PLATFORMS",
          links:[
            {
              link: "/microsoft",
              name: "MICROSOFT",
              isActive: true,
            },
            {
              link: "/cloud/azure/consulting",
              name: "AZURE",
              isActive: true,
            },
            {
              link: "/sharepoint-office-365",
              name: "OFFICE_365",
              isActive: true,
            },
            {
              link: "/aws",
              name: "AWS",
              isActive: true,
            }
          ]
        }
      ]
    },
    {
      label: "PORTFOLIO",
      mainLink: "/case-studies",
      isActive: false,
      hasSubMenus: false,
    }
  ];
};





