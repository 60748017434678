import { PURGE } from "redux-persist";
import { events as actions } from "actions";
import { events } from "actions/global";

const getInitialState = () => {
  return {
    isCallbackRequestSubmitted: false,
    callbackRequestError: {}
  };
};

export default (state = getInitialState(), response = {}) => {
  switch (response.type) {
    case actions.marketing.callback.RECEIVED:
      return {
        ...state,
        isCallbackRequestSubmitted: response?.payload
      };
    case actions.marketing.callback.FAILED:
      return {
        ...state,
        callbackRequestError: {
          errorCode: response?.errorCode,
          errorMessage: response?.message,
        },
      };
    case PURGE:
      return {
        ...getInitialState(),
      };
    default:
      return state;
  }
};
