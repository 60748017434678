import * as actionTypes from "./actionTypes";
import { actions as layoutActions, events as layoutEvents } from "./layout";
import { actions as loadingActions, events as loadingEvents } from "./error";

const otpSend = (customerId, otpType) => ({
  type: actionTypes.OTP_SEND,
  customerId,
  otpType,
});
const otpResend = (customerId, otpType) => ({
  type: actionTypes.OTP_RESEND,
  customerId,
  otpType,
});
const otpVerify = ( customerId, otp, otpType ) =>({
  type: actionTypes.OTP_VERIFY,
  customerId,
  otp,
  otpType,
});
const otpClear = () => ({
  type: actionTypes.OTP_CLEAR,
});
const otpErrorDismiss = () => ({
  type: actionTypes.OTP_ERROR_DISMISS,
});

const requestLogin = ( user, password, saveUserDataInCache = false, locale = "en" ) => ({
  type: actionTypes.REQUEST_LOGIN,
  user,
  password,
  saveUserDataInCache,
  locale
});
const updateAuthToken = () => ({
  type: actionTypes.REQUEST_REFRESH_JWT_TOKEN,
});


const destroySession = () => ({
  type: actionTypes.DESTROY_SESSION,
});
const lockSession = () => ({
type: actionTypes.LOCK_SESSION,
});



const requestChangePassword = (emailId) => ({
  type: actionTypes.REQUEST_CHANGE_PASSWORD,
  emailId
});

const resetPassword = (password, otp) => ({
  type: actionTypes.REQUEST_RESET_CHANGE_PASSWORD,
  password,
  otp
});

const activateAccount = (otp) => ({
  type: actionTypes.REQUEST_ACCOUNT_ACTIVATION,
  otp
});

const requestOtpAccountActivation = (emailId) => ({
  type: actionTypes.REQUEST_OTP_ACCOUNT_ACTIVATION,
  emailId
});



const requestEmailSignup = (email) => ({
  type: actionTypes.REQUEST_EMAIL_SIGNUP,
  email,
});
const requestPasswordSignup = (customerId, password) => ({
  type: actionTypes.REQUEST_PASSWORD_SIGNUP,
  customerId,
  password,
});
const resetSignup = () => ({
  type: actionTypes.RESET_SIGNUP,
});
const requestMobileSignup = (countryCode,mobileNumber) => ({
  type: actionTypes.REQUEST_MOBILE_SIGNUP,
  countryCode,
  mobileNumber,
});
const requestVerifyMobile = (customerId, otp) => ({
  type: actionTypes.REQUEST_MOBILE_VERIFY,
  customerId,
  otp,
});
const requestResendOtp = (customerId, otpType) => ({
  type: actionTypes.REQUEST_RESEND_OTP,
  customerId,
  otpType,
});
const requestCancelVerifyOtp = (payload = false) => ({
  type: actionTypes.CANCEL_VERIFY_OTP,
  payload,
});
const resetRegisterMobile = () => ({
  type: actionTypes.RESET_REGISTER_MOBILE,
});
const resetVerifyMobile = () => ({
  type: actionTypes.RESET_VERIFY_MOBILE,
});





const requestCallback = (callbackData) => ({
  type: actionTypes.REQUEST_CALL_BACK,
  callbackData
});


export const actions = {  
  ...layoutActions,
  ...loadingActions,

    auth: {
      requestLogin,
      updateAuthToken,
      // requestChangePassword,
      // resetPassword,
      // activateAccount,
      // requestOtpAccountActivation,
      session: {
        destroySession,
        lockSession
      },
      otp: {
        otpSend,
        otpResend,
        otpVerify,
        otpClear,
        otpErrorDismiss,
      },
      signup: {
        // requestEmailSignup,
        // requestPasswordSignup,
        // resetSignup,
        // requestMobileSignup,
        // requestVerifyMobile,
        // requestResendOtp,
        // requestCancelVerifyOtp,
        // resetRegisterMobile,
        // resetVerifyMobile,
      },
    },



    marketing:{
      requestCallback,
    }
};

export const events = {
  ...layoutEvents,
  ...loadingEvents,
  auth:{
    token: {
      REQUEST_REFRESH_JWT_TOKEN: actionTypes.REQUEST_REFRESH_JWT_TOKEN,
      RECEIVED_REFRESH_JWT_TOKEN: actionTypes.RECEIVED_REFRESH_JWT_TOKEN,
      FAILED_REFRESH_JWT_TOKEN: actionTypes.FAILED_REFRESH_JWT_TOKEN,
    },
    login: {
      REQUEST: actionTypes.REQUEST_LOGIN,
      RECEIVED: actionTypes.RECEIVED_LOGIN,
      FAILED: actionTypes.FAILED_LOGIN,
    },    
    emailVerify: {
      EMAIL_VERIFY_OTP_SEND: actionTypes.EMAIL_VERIFY_OTP_SEND,
      EMAIL_VERIFY_OTP_RESEND: actionTypes.EMAIL_VERIFY_OTP_RESEND,
      EMAIL_VERIFY_OTP_RECEIVED: actionTypes.EMAIL_VERIFY_OTP_RECEIVED,
      EMAIL_VERIFY_OTP_FAILED: actionTypes.EMAIL_VERIFY_OTP_FAILED,
      EMAIL_VERIFY_OTP_CLEAR: actionTypes.EMAIL_VERIFY_OTP_CLEAR,
      EMAIL_VERIFY_OTP_ERROR_DISMISS: actionTypes.EMAIL_VERIFY_OTP_ERROR_DISMISS,
      EMAIL_VERIFY_OTP_VERIFY: actionTypes.EMAIL_VERIFY_OTP_VERIFY,
      EMAIL_VERIFY_OTP_VERIFY_SUCCESS:
        actionTypes.EMAIL_VERIFY_OTP_VERIFY_SUCCESS,
      EMAIL_VERIFY_OTP_VERIFY_FAILED: actionTypes.EMAIL_VERIFY_OTP_VERIFY_FAILED,
      EMAIL_VERIFIED_SUCCESS_UPDATE: actionTypes.EMAIL_VERIFIED_SUCCESS_UPDATE,
    },
    otp: {
      OTP_SEND: actionTypes.OTP_SEND,
      OTP_RESEND: actionTypes.OTP_RESEND,
      OTP_RECEIVED: actionTypes.OTP_RECEIVED,
      OTP_FAILED: actionTypes.OTP_FAILED,
      OTP_CLEAR: actionTypes.OTP_CLEAR,
      OTP_ERROR_DISMISS: actionTypes.OTP_ERROR_DISMISS,
      OTP_VERIFY: actionTypes.OTP_VERIFY,
      OTP_VERIFY_SUCCESS: actionTypes.OTP_VERIFY_SUCCESS,
      OTP_VERIFY_FAILED: actionTypes.OTP_VERIFY_FAILED,
    },
    password: {
      checkDefaultPassword: {
        REQUEST: actionTypes.REQUEST_DEFAULT_PASSWORD,
        RECEIVED: actionTypes.RECEIVED_DEFAULT_PASSWORD,
        FAILED: actionTypes.FAILED_DEFAULT_PASSWORD,
        CANCELED: actionTypes.CANCELED_DEFAULT_PASSWORD,
      },
      generatePassword: {
        REQUEST: actionTypes.REQUEST_GENERATE_PASSWORD,
        RECEIVED: actionTypes.RECEIVED_GENERATE_PASSWORD,
        FAILED: actionTypes.FAILED_GENERATE_PASSWORD,
        CANCELED: actionTypes.CANCELED_GENERATE_PASSWORD,
      },
      setPassword: {
        REQUEST: actionTypes.REQUEST_SET_PASSWORD,
        RECEIVED: actionTypes.RECEIVED_SET_PASSWORD,
        FAILED: actionTypes.FAILED_SET_PASSWORD,
        CANCELED: actionTypes.CANCELED_SET_PASSWORD,
      },
      changePassword: {
        REQUEST: actionTypes.REQUEST_CHANGE_PASSWORD,
        RECIEVED: actionTypes.RECEIVED_CHANGE_PASSWORD,
        FAILED: actionTypes.FAILED_CHANGE_PASSWORD,
        RESET: actionTypes.RESET_CHANGE_PASSWORD_STATE,
      },
    },
    resetPassword:{
      // REQUEST: actionTypes.REQUEST_RESET_CHANGE_PASSWORD,
      // RECEIVED: actionTypes.RECEIVED_RESET_CHANGE_PASSWORD,
      // FAILED: actionTypes.FAILED_RESET_CHANGE_PASSWORD,
    },
    session: {
      DESTROY_SESSION: actionTypes.DESTROY_SESSION,
      LOCK_SESSION: actionTypes.LOCK_SESSION,
    },
    activation:{
      // REQUEST: actionTypes.REQUEST_ACCOUNT_ACTIVATION,
      // RECEIVED: actionTypes.RECEIVED_ACCOUNT_ACTIVATION,
      // FAILED: actionTypes.FAILED_ACCOUNT_ACTIVATION,
    },
  },



  marketing:{
    callback:{
      REQUEST: actionTypes.REQUEST_CALL_BACK,
      RECEIVED: actionTypes.REQUEST_CALL_BACK_SUCCESS,
      FAILED: actionTypes.REQUEST_CALL_BACK_FAIL,
    }
  }
};