import { events } from "../actions";
import { put } from "redux-saga/effects";
import qs from "qs";

const ApiKey = process.env.REACT_APP_API_KEY || "x32jcsHA3240SSdfdk";
const ApiTimeout = 1000 * 60; // 60 seconds

const getDeviceName = () =>{
  var deviceName = "";
  const isAndroid = navigator.userAgent.match(/Android/i);
  const isDataLogic = navigator.userAgent.match(/DL-AXIS/i);
  const isBluebird = navigator.userAgent.match(/EF500/i);
  const isHoneywell = navigator.userAgent.match(/CT50/i);
  const isZebra = navigator.userAgent.match(/TC70|TC55/i);
  const isBlackBerry = navigator.userAgent.match(/BlackBerry/i);
  const isiOS = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  const isWindows = navigator.userAgent.match(/IEMobile|Windows/i);

  if (isDataLogic) deviceName = "DataLogic";
  else if (isBluebird) deviceName = "Bluebird";
  else if (isHoneywell) deviceName = "Honeywell";
  else if (isZebra) deviceName = "Zebra";
  else if (isBlackBerry) deviceName = "BlackBerry";
  else if (isiOS) deviceName = "iOS";
  else if (isAndroid) deviceName = "Android";
  else if (isWindows) deviceName = "Windows";

  return deviceName;
}

export const formatRequest = ( path, method, data, useAuth = true, useIdToken = false, timeout = ApiTimeout, customHeader = null ) => {
  const apiVersion = process.env.REACT_APP_API_VERSION;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let headers = {
    "x-api-key": ApiKey,
    "x-api-version": apiVersion,
    "x-user-platform": "WEB",
    "Access-Control-Allow-Origin": "*",
    "x-api-zoneId": timezone,
    "x-device-name": getDeviceName(),
  };

  if (customHeader) {
    headers = { ...headers, ...customHeader };
  }

  let query = `${qs.stringify({ ...(method === "get" ? data : undefined), })}`;
  const url = `${path}${query !== "" ? "?" + query : ""}`;
  return { method, url, data, headers, timeout };
};

export function* onRequestError(eventType, errorCode, errorMessage, params) {
  if (Array.isArray(eventType)) {
    for (const type of eventType)
      yield put({ type, message: errorMessage, errorCode, params });
  } else {
    yield put({ type: eventType, message: errorMessage, errorCode, params });
  }

  yield put({
    type: events.error.RECEIVED,
    message: errorMessage,
    errorCode,
    params,
  });
  yield put({ type: events.loading.COMPLETED });
}

export function* onCompleteRequest(eventType, data) {
  if (Array.isArray(eventType)) {
    for (const type of eventType) yield put({ type, payload: data });
  } else { 
    yield put({ type: eventType, payload: data });
  }
  yield put({ type: events.loading.COMPLETED });
}
