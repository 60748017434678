export const getQueryStringParameter = (field, url) => {
  url = url ? url : window.location.href;
  const reg = new RegExp("[?&]" + field + "=([^&#]*)", "i");
  const param = reg.exec(url);
  return param ? param[1] : null;
};

export const setQueryStringParameter = (parameter, parameterValue, url) => {
  url = url ? url : window.location.href;
  let newAdditionalURL = "";
  let tempArray = url.split("?");
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== parameter) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }
  const rows_txt = temp + "" + parameter + "=" + parameterValue;
  window.history.replaceState(
    "",
    "",
    baseURL + "?" + newAdditionalURL + rows_txt
  );
};

export const removeQueryStringParameter = (parameter, url) => {
  url = url ? url : window.location.href;
  let newAdditionalURL = "";
  let tempArray = url.split("?");
  const baseURL = tempArray[0];
  const additionalURL = tempArray[1];
  let temp = "";
  if (additionalURL) {
    tempArray = additionalURL.split("&");
    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i].split("=")[0] !== parameter) {
        newAdditionalURL += temp + tempArray[i];
        temp = "&";
      }
    }
  }
  window.history.replaceState("", "", baseURL + "?" + newAdditionalURL);
};
