import logo from "assets/images/logos/logo.png";

import lTab from "assets/images/logos/logo_tablet_2048.png";
import lDesk from "assets/images/logos/logo_desktop_1920.png";
import mDesk from "assets/images/logos/logo_desktop_1600.png";
import smDesk from "assets/images/logos/logo_desktop_1200.png";
import mTab from "assets/images/logos/logo_tablet_1280.png";
import smTab from "assets/images/logos/logo_tablet_768.png";
import lPhone from "assets/images/logos/logo_phone_640.png";
import mPhone from "assets/images/logos/logo_phone_420.png";
import smPhone from "assets/images/logos/logo_phone_320.png";

export default { smPhone, smTab, smDesk, mPhone, mTab, mDesk, lPhone, lTab, lDesk, logo }






