import gr from "./locales/gr.json"
import it from "./locales/it.json"
import rs from "./locales/rs.json"
import sp from "./locales/sp.json"
import en from "./locales/eng.json"

const language = localStorage.getItem("I18N_LANGUAGE")
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en")
}

export default { gr, it, rs, sp, en }
