import NotFoundPage from "./components";
import NonAuthLayout from "components/NonAuthLayout";
export const ROUTE_NOT_FOUND = "/not-found";

/* TODO: add redirect here instead of being coded in the Login Container */
export default { 
  routeProps: [
    {
      path: ROUTE_NOT_FOUND,
      exact: true,
      component: NotFoundPage,
      isAuthProtected: false,
      authRequired: false,
      layout: NonAuthLayout
    },
  ],
  name: "NotFoundPage",
  authscreen: true,
  enabled: true,
  default: true,
};
