import * as actionTypes from "./actionTypes";

const changeLayout = layout => ({
  type: actionTypes.CHANGE_LAYOUT,
  payload: layout,
})
const changePreloader = layout => ({
  type: actionTypes.CHANGE_PRELOADER,
  payload: layout,
})
const changeLayoutWidth = width => ({
  type: actionTypes.CHANGE_LAYOUT_WIDTH,
  payload: width,
})
const changeSidebarTheme = theme => ({
  type: actionTypes.CHANGE_SIDEBAR_THEME,
  payload: theme,
})
const changeSidebarType = (sidebarType, isMobile) => {
  return {
    type:actionTypes. CHANGE_SIDEBAR_TYPE,
    payload: { sidebarType, isMobile },
  }
}
const changeTopbarTheme = topbarTheme => ({
  type: actionTypes.CHANGE_TOPBAR_THEME,
  payload: topbarTheme,
})
const changelayoutMode = (layoutMode, layoutType) => ({
  type: actionTypes.CHANGE_LAYOUT_THEME,
  payload: { layoutMode, layoutType },
});
const changeLayoutPosition = layoutPosition => ({
  type: actionTypes.CHANGE_LAYOUT_POSITION,
  payload: layoutPosition,
})
const showRightSidebarAction = isopen => ({
  type: actionTypes.SHOW_RIGHT_SIDEBAR,
  payload: isopen,
})
const showSidebar = isopen => ({
  type: actionTypes.SHOW_SIDEBAR,
  payload: isopen,
})
const toggleLeftmenu = isopen => ({
  type: actionTypes.TOGGLE_LEFTMENU,
  payload: isopen,
});


export const actions = {
    layout:{
      changeLayout, 
      changePreloader, 
      changeLayoutWidth, 
      changeSidebarTheme, 
      changeSidebarType, 
      changeTopbarTheme,
      changelayoutMode,
      changeLayoutPosition,
      showRightSidebarAction, 
      showSidebar, 
      toggleLeftmenu
    },
};

export const events = {
  layout:{
    CHANGE_LAYOUT: actionTypes.CHANGE_LAYOUT,
    CHANGE_PRELOADER: actionTypes.CHANGE_PRELOADER,
    CHANGE_LAYOUT_WIDTH: actionTypes.CHANGE_LAYOUT_WIDTH,
    CHANGE_SIDEBAR_THEME: actionTypes.CHANGE_SIDEBAR_THEME,
    CHANGE_SIDEBAR_TYPE: actionTypes. CHANGE_SIDEBAR_TYPE,
    CHANGE_TOPBAR_THEME: actionTypes.CHANGE_TOPBAR_THEME,
    CHANGE_LAYOUT_THEME: actionTypes.CHANGE_LAYOUT_THEME,
    CHANGE_LAYOUT_POSITION: actionTypes.CHANGE_LAYOUT_POSITION,
    SHOW_RIGHT_SIDEBAR: actionTypes.SHOW_RIGHT_SIDEBAR,
    SHOW_SIDEBAR: actionTypes.SHOW_SIDEBAR,
    TOGGLE_LEFTMENU: actionTypes.TOGGLE_LEFTMENU,
  },
};