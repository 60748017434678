import React from "react"
import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({   
    pageShare:{
      textRendering: "optimizeSpeed",
      fontFamily: "Open Sans,sans-serif",
      fontSize: "6px",
      color: "#3b3c3d",
      boxSizing: "border-box",
      display: "flex",
      flexDirection: "row",
      marginBottom: "50px",
      marginTop: "30px",
      position: "relative",
      top: "unset"
    },
    pageShareDesc:{
      boxSizing: "border-box",
      marginBottom: "18px",
      marginTop: 0,
      fontSize: "18px",
      lineHeight: "40px",
      marginRight: "15px"
    },
    pageShareLink:{
      boxSizing: "border-box",
      textDecorationSkipInk: "auto",
      textDecoration: "none",
      borderRadius: "50%",
      boxShadow: "0 2px 8px 0 rgba(0,0,0,.17)",
      color: "#ED2600",
      display: "inline-block",
      fontSize: "22px",
      height: "40px",
      lineHeight: "44px",
      marginBottom: 0,
      marginRight: "10px",
      textAlign: "center",
      width: "40px"
    }
  })
);

const SocialShare = () => {
  const classes = useStyles();
  return (
    <div className={classes.pageShare}>
      <p className={classes.pageShareDesc}>Share:</p>
      <a className={classes.pageShareLink} href="https://www.facebook.com/sharer.php?u=https://www.spectrecreation.co.bw/about/privacy-policy" rel="noopener noreferrer nofollow">
        <span className="sr-only">facebook</span>
        <i className="icon-s-facebook"></i>
      </a>
      <a className={classes.pageShareLink} href="https://www.linkedin.com/shareArticle?mini=true&amp;url=https://www.spectrecreation.co.bw/about/privacy-policy" rel="noopener noreferrer nofollow">
        <span className="sr-only">linkedin</span>
        <i className="icon-s-linkedin"></i>
      </a>
    </div>
  )
}

export default SocialShare
