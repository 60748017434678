import TermsAndConditionsPage from "./components";
import VerticalLayout from "components/VerticalLayout/";
export const ROUTE_TERMS_CONDITION = "/about/terms-of-use";

/* TODO: add redirect here instead of being coded in the Login Container */
export default { 
  routeProps: [
    {
      path: ROUTE_TERMS_CONDITION,
      exact: true,
      component: TermsAndConditionsPage,
      isAuthProtected: false,
      authRequired: false,
      layout: VerticalLayout
    },
  ],
  name: "TermsAndConditionsPage",
  authscreen: false,
  enabled: true,
  default: true,
};
