import { setQueryStringParameter } from "utils/location";

export const SET_LANGUAGE = "SET_LANGUAGE";
export const UPDATES_SUBSCRIBE = "UPDATES_SUBSCRIBE";
export const SET_ACTIVE_PAGE = "SET_ACTIVE_PAGE";

export const events = {
  global: {    
    SET_LANGUAGE,
    UPDATES_SUBSCRIBE,
    SET_ACTIVE_PAGE
  },
};

export const setCurrentLanguage = (language) => {
  setQueryStringParameter("locale", language);
  return {
    type: SET_LANGUAGE,
    language,
  };
};
const activateLivenessSubscription = (emailAddress) => ({
  type: UPDATES_SUBSCRIBE,
  emailAddress,
});

const setActivePageName = (pageName) => ({
  type: SET_ACTIVE_PAGE,
  pageName,
});

export const actions = {
  global: {
    setCurrentLanguage,
    activateLivenessSubscription,
    setActivePageName
  },
};
