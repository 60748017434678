import lesedikanna from "./lesedikanna.png"
import napha from "./napha.png"
import sharearide from "./sharearide.png"

const partners = [
  { id: 1, img: napha, },
  { id: 2, img: sharearide, },
  { id: 3, img: lesedikanna },
];

export {
  partners,
  lesedikanna,
  napha,
  sharearide
}


