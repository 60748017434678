
/* LAYOUT */
export const CHANGE_LAYOUT = "CHANGE_LAYOUT"
export const CHANGE_LAYOUT_WIDTH = "CHANGE_LAYOUT_WIDTH"
export const CHANGE_SIDEBAR_THEME = "CHANGE_SIDEBAR_THEME"
export const CHANGE_SIDEBAR_TYPE = "CHANGE_SIDEBAR_TYPE"
// topbar
export const CHANGE_TOPBAR_THEME = "CHANGE_TOPBAR_THEME"
// layout mode
export const CHANGE_LAYOUT_THEME = "CHANGE_LAYOUT_THEME"
// layout postion
export const CHANGE_LAYOUT_POSITION = "CHANGE_LAYOUT_POSITION"
// show sidebar
export const SHOW_SIDEBAR = "SHOW_SIDEBAR"
export const TOGGLE_LEFTMENU = "TOGGLE_LEFTMENU"
// RIGHT SIDEBAR
export const SHOW_RIGHT_SIDEBAR = "SHOW_RIGHT_SIDEBAR"
// Preloader
export const CHANGE_PRELOADER = "CHANGE_PRELOADER"

/**Token Refresh */
export const FAILED_REFRESH_JWT_TOKEN = "FAILED_REFRESH_JWT_TOKEN";
export const RECEIVED_REFRESH_JWT_TOKEN = "RECEIVED_REFRESH_JWT_TOKEN";
export const REQUEST_REFRESH_JWT_TOKEN = "REQUEST_REFRESH_JWT_TOKEN";

/**Sign-in */
export const FAILED_LOGIN = "FAILED_LOGIN";
export const RECEIVED_LOGIN = "RECEIVED_LOGIN";
export const REQUEST_LOGIN = "REQUEST_LOGIN";

/**Email Verify */
export const EMAIL_VERIFY_OTP_SEND = "EMAIL_VERIFY_OTP_SEND";
export const EMAIL_VERIFY_OTP_RESEND = "EMAIL_VERIFY_OTP_RESEND";
export const EMAIL_VERIFY_OTP_RECEIVED = "EMAIL_VERIFY_OTP_RECEIVED";
export const EMAIL_VERIFY_OTP_FAILED = "EMAIL_VERIFY_OTP_FAILED";
export const EMAIL_VERIFY_OTP_CLEAR = "EMAIL_VERIFY_OTP_CLEAR";
export const EMAIL_VERIFY_OTP_ERROR_DISMISS = "EMAIL_VERIFY_OTP_ERROR_DISMISS";
export const EMAIL_VERIFY_OTP_VERIFY = "EMAIL_VERIFY_OTP_VERIFY";
export const EMAIL_VERIFY_OTP_VERIFY_SUCCESS = "EMAIL_VERIFY_OTP_VERIFY_SUCCESS";
export const EMAIL_VERIFY_OTP_VERIFY_FAILED = "EMAIL_VERIFY_OTP_VERIFY_FAILED";
export const EMAIL_VERIFIED_SUCCESS_UPDATE = "EMAIL_VERIFIED_SUCCESS_UPDATE";

/* Otp actions */
export const OTP_SEND = "OTP_SEND";
export const OTP_RESEND = "OTP_RESEND";
export const OTP_RECEIVED = "OTP_RECEIVED";
export const OTP_FAILED = "OTP_FAILED";
export const OTP_CLEAR = "OTP_CLEAR";
export const OTP_ERROR_DISMISS = "OTP_ERROR_DISMISS";
export const OTP_VERIFY = "OTP_VERIFY";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAILED = "OTP_VERIFY_FAILED";


/* Password*/
//Reset Password
export const REQUEST_DEFAULT_PASSWORD = "REQUEST_DEFAULT_PASSWORD";
export const RECEIVED_DEFAULT_PASSWORD = "RECEIVED_DEFAULT_PASSWORD";
export const FAILED_DEFAULT_PASSWORD = "FAILED_DEFAULT_PASSWORD";
export const CANCELED_DEFAULT_PASSWORD = "CANCELED_DEFAULT_PASSWORD";
//Generate password
export const REQUEST_GENERATE_PASSWORD = "REQUEST_GENERATE_PASSWORD";
export const RECEIVED_GENERATE_PASSWORD = "RECEIVED_GENERATE_PASSWORD";
export const FAILED_GENERATE_PASSWORD = "FAILED_GENERATE_PASSWORD";
export const CANCELED_GENERATE_PASSWORD = "CANCELED_GENERATE_PASSWORD";
//Set Password
export const REQUEST_SET_PASSWORD = "REQUEST_SET_PASSWORD";
export const RECEIVED_SET_PASSWORD = "RECEIVED__SET_PASSWORD";
export const FAILED_SET_PASSWORD = "FAILED_SET_PASSWORD";
export const CANCELED_SET_PASSWORD = "CANCELED_SET_PASSWORD";

/**Change Password*/
export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const RECEIVED_CHANGE_PASSWORD = "RECEIVED_CHANGE_PASSWORD";
export const FAILED_CHANGE_PASSWORD = "FAILED_CHANGE_PASSWORD";
export const REQUEST_RESET_CHANGE_PASSWORD = "REQUEST_RESET_CHANGE_PASSWORD";
export const RECEIVED_RESET_CHANGE_PASSWORD = "RECEIVED_RESET_CHANGE_PASSWORD";
export const FAILED_RESET_CHANGE_PASSWORD = "FAILED_RESET_CHANGE_PASSWORD";
export const RESET_CHANGE_PASSWORD_STATE = "RESET_CHANGE_PASSWORD_STATE";

/**Activate account */

export const REQUEST_ACCOUNT_ACTIVATION = "REQUEST_ACCOUNT_ACTIVATION";
export const RECEIVED_ACCOUNT_ACTIVATION = "RECEIVED_ACCOUNT_ACTIVATION";
export const FAILED_ACCOUNT_ACTIVATION = "FAILED_ACCOUNT_ACTIVATION";

export const REQUEST_OTP_ACCOUNT_ACTIVATION = "REQUEST_OTP_ACCOUNT_ACTIVATION";
export const RECEIVED_OTP_ACCOUNT_ACTIVATION = "RECEIVED_OTP_ACCOUNT_ACTIVATION";
export const FAILED_OTP_ACCOUNT_ACTIVATION = "FAILED_OTP_ACCOUNT_ACTIVATION";

/**Sign out */
export const DESTROY_SESSION = "DESTROY_SESSION";
/**Lock screen */
export const LOCK_SESSION="LOCK_SESSION";






/** Error */
export const ERROR_RECEIVED = "ERROR_RECEIVED";
export const ERROR_CANCELED = "ERROR_CANCELED";
/** Loading */
export const LOADING_RECEIVED = "LOADING_RECEIVED";
export const LOADING_COMPLETED = "LOADING_COMPLETED";



/* Signup by email */
export const REQUEST_EMAIL_SIGNUP = "REQUEST_EMAIL_SIGNUP";
export const RECEIVED_EMAIL_SIGNUP = "RECEIVED_EMAIL_SIGNUP";
export const FAILED_EMAIL_SIGNUP = "FAILED_EMAIL_SIGNUP";

export const REQUEST_PASSWORD_SIGNUP = "REQUEST_PASSWORD_SIGNUP";
export const RECEIVED_PASSWORD_SIGNUP = "RECEIVED_PASSWORD_SIGNUP";
export const FAILED_PASSWORD_SIGNUP = "FAILED_PASSWORD_SIGNUP";
export const RESET_SIGNUP = "RESET_SIGNUP";

/* Signup by mobile */
export const REQUEST_MOBILE_SIGNUP = "REQUEST_MOBILE_SIGNUP";
export const RECEIVED_MOBILE_SIGNUP = "RECEIVED_MOBILE_SIGNUP";
export const FAILED_MOBILE_SIGNUP = "FAILED_MOBILE_SIGNUP";
export const RESET_REGISTER_MOBILE = "RESET_REGISTER_MOBILE";
/* Verify Mobile OTP */
export const REQUEST_MOBILE_VERIFY = "REQUEST_MOBILE_VERIFY";
export const RECEIVED_MOBILE_VERIFY = "RECEIVED_MOBILE_VERIFY";
export const FAILED_MOBILE_VERIFY = "FAILED_MOBILE_VERIFY";
export const RESET_VERIFY_MOBILE = "RESET_VERIFY_MOBILE";
/* Resend Mobile OTP */
export const REQUEST_RESEND_OTP = "REQUEST_RESEND_OTP";
export const RECEIVED_RESEND_OTP = "RECEIVED_RESEND_OTP";
export const FAILED_RESEND_OTP = "FAILED_RESEND_OTP";
export const CANCEL_VERIFY_OTP = "CANCEL_VERIFY_OTP";





/* Call back request */
export const REQUEST_CALL_BACK = "REQUEST_CALL_BACK";
export const REQUEST_CALL_BACK_SUCCESS = "REQUEST_CALL_BACK_SUCCESS";
export const REQUEST_CALL_BACK_FAIL = "REQUEST_CALL_BACK_FAIL";

/**Header background */
export const TOGGLE_HEADER_BACKGROUND = "TOGGLE_HEADER_BACKGROUND";