/**
 * Create React App entry point. This and `public/index.html` files can not be changed or moved.
 */
 
import React from "react";
import ReactDOM from "react-dom/client";
import AppI18n from "container";
import { BrowserRouter } from "react-router-dom";
import "./locales/i18n";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import * as helpers from "./helpers";
import store, { persister } from "./store";
import { getFBRemoteConfig } from "./utils/firebaseConfig";

import reportWebVitals from './reportWebVitals';

/**
* Base URL of the website.
*
* @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
*/
const { PUBLIC_URL } = process.env;

async () => {
  await getFBRemoteConfig();
};

/**
 * Inject interceptors for axios.
 * 
 * @see https://github.com/axios/axios#interceptors
 */
 helpers.setupAxios(axios);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  /* Provide Redux store */
  <Provider store={store}>
    {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
    <PersistGate persistor={persister} loading={null}>
      {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
      <React.Suspense fallback={null}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={PUBLIC_URL}>
          <AppI18n />
        </BrowserRouter>
      </React.Suspense>
    </PersistGate>
  </Provider>
);

function sendToGoogleAnalytics({ name, delta, value, id, attribution }) {
  const eventParams = {
    event_category: 'Web Vitals',
    event_label: id,
    value: delta,
    metric_id: id,
    metric_value: value,
    metric_delta: delta,
    non_interaction: true,
  };

  switch (name) {
    case 'CLS':
      eventParams.debug_target = attribution.largestShiftTarget;
      break;
    case 'FID':
      eventParams.debug_target = attribution.eventTarget;
      eventParams.debug_event_time = attribution.eventTime;
      break;
    case 'LCP':
      eventParams.debug_target = attribution.element;
      eventParams.debug_element_render_delay = attribution.elementRenderDelay;   
      break;
    case 'INP':
      eventParams.debug_target = attribution.element;
      eventParams.debug_event_time = attribution.eventTime;     
      break;
  }
  window.gtag('event', name, eventParams);
}

reportWebVitals(sendToGoogleAnalytics);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.register();