import HomePage from "./components";
import VerticalLayout from "components/VerticalLayout/";
export const ROUTE_HOME = "/home";

/* TODO: add redirect here instead of being coded in the Login Container */
export default { 
  routeProps: [
    {
      path: ROUTE_HOME,
      exact: true,
      component: HomePage,
      isAuthProtected: false,
      authRequired: false,
      layout: VerticalLayout
    },
  ],
  name: "HomePage",
  authscreen: false,
  enabled: true,
  default: true,
};
