import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"
import requestSaga from "sagas"
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import reducers from "reducers/";
import { REQUIRED_STORE_REDUCERS, APP_CACHE_KEY } from "../utils/constants";
import { composeWithDevTools } from "redux-devtools-extension";

const sagaMiddleware = createSagaMiddleware();
const persistConfig = { 
    key: APP_CACHE_KEY, 
    storage, 
    whitelist: REQUIRED_STORE_REDUCERS, 
};
const enhancer = 
    process.env.NODE_ENV === "development" 
    ? composeWithDevTools(applyMiddleware(sagaMiddleware)) 
    : compose(applyMiddleware(sagaMiddleware));

const persistedReducer = persistReducer(persistConfig, reducers || (() => {}));

const store = createStore(persistedReducer, enhancer);

export const persister = persistStore(store);

sagaMiddleware.run(requestSaga);

export default store
