import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import images from "assets/images/logos/index";
import { makeStyles, createStyles } from "@material-ui/core";
import cn from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({    
    headerMessage: {
        fontSize: "25px",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
            fontSize: "50px",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "35px",
        },
    },
    message:{
        fontSize: "20px",
        [theme.breakpoints.down("sm")]: {
            fontSize: "35px",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "20px",
        },
    },
    avatar:{
        height: "3rem",
        width: "3rem",
        [theme.breakpoints.down("sm")]: {
            height: "10rem",
            width: "10rem",
        },
        [theme.breakpoints.up("sm")]: {
            height: "5rem",
            width: "5rem",
        },
    },
    icon:{
        [theme.breakpoints.down("sm")]: {
            fontSize: "5rem",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "2.5rem",
        },
    },
    innerHeaderMessage: {
        fontSize: "1.5rem",
        fontWeight: "bold",
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.5rem",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "1rem",
        },
    },
    innerMessage:{
        fontSize: "1.5rem",
        [theme.breakpoints.down("sm")]: {
            fontSize: "2.5rem",
        },
        [theme.breakpoints.up("sm")]: {
            fontSize: "1rem",
        },
    },
  })
);
const PageMaintenance = () => {
    
  const intl = useIntl();
  const { formatMessage } = intl;
    const { smPhone, smTab, smDesk, mPhone, mTab, mDesk, lPhone, lTab, lDesk, logo } = images;
    const classes = useStyles();

    const getDeviceDimension = (dimension) =>{
        switch(dimension){
            case 25:
                return 350;
            case 48:
                return 200;
            case 75:
                return 250;
            default:
                return 300;
        }
    }


    return (
        <>
            <div className="bg-soft-light min-vh-100 py-5">
                <div className="py-4">
                    <Container>
                        <Row>
                            <Col xs={12}>
                                <div className="text-center">
                                    <div className="mb-5">
                                        <Link to="/dashboard">
                                            <picture>
                                                <source media="(max-width: 25em)" srcSet={`${smPhone}, ${mPhone}, ${lPhone}`} height={getDeviceDimension(25)}/>
                                                <source media="(max-width: 48em)" srcSet={`${smTab}, ${mTab}, ${lTab}`} height={getDeviceDimension(48)}/>
                                                <source media="(max-width: 75em)" srcSet={`${smDesk}, ${mDesk}, ${lDesk}`} height={getDeviceDimension(75)}/>
                                                <img src={logo} alt="logo" title="logo" className="me-1" height={getDeviceDimension(100)}/>
                                            </picture>
                                        </Link>
                                    </div>

                                    <div className="maintenance-cog-icon text-primary pt-4">
                                        <i className="mdi mdi-cog spin-right display-3" />
                                        <i className="mdi mdi-cog spin-left display-4 cog-icon" />
                                    </div>
                                    <h3 className={classes.headerMessage}>{formatMessage(messages.SITE_MAINTENANCE_HEADER_MAIN)}</h3>
                                    <p className={classes.message}>{formatMessage(messages.SITE_MAINTENANCE_HEADER_SUB_TEXT)}</p>
                                    <Row className="mt-4">
                                        <Col md={4} xs={12}>
                                            <div className="mt-4 maintenance-box">
                                                <div className="p-4">
                                                    <div className={cn(`${classes.avatar} mx-auto`)}>
                                                        <span className="avatar-title bg-soft-primary rounded-circle">
                                                            <i className={cn(`${classes.icon} mdi mdi-access-point-network text-primary`)}></i>
                                                        </span>
                                                    </div>
                                                    <h5 className={cn(`${classes.innerHeaderMessage} text-uppercase mt-4`)}>
                                                        {formatMessage(messages.SITE_MAINTENANCE_REASON)}
                                                    </h5>
                                                    <p className={cn(`${classes.innerMessage} text-muted mb-0`)}>{formatMessage(messages.SITE_MAINTENANCE_REASON_EX)}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={12}>
                                            <div className="mt-4 maintenance-box">
                                                <div className="p-4">
                                                    <div className={cn(`${classes.avatar} mx-auto`)}>
                                                        <span className="avatar-title bg-soft-primary rounded-circle">
                                                            <i className={cn(`${classes.icon} mdi mdi-clock-outline text-primary`)}></i>
                                                        </span>
                                                    </div>
                                                    <h5 className={cn(`${classes.innerHeaderMessage} text-uppercase mt-4`)}>
                                                        {formatMessage(messages.SITE_MAINTENANCE_ETA_LABEL)}
                                                    </h5>
                                                    <p className={cn(`${classes.innerMessage} text-muted mb-0`)}>{formatMessage(messages.SITE_MAINTENANCE_ETA)}</p>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col md={4} xs={12}>
                                            <div className="mt-4 maintenance-box">
                                                <div className="p-4">
                                                    <div className={cn(`${classes.avatar} mx-auto`)}>
                                                        <span className="avatar-title bg-soft-primary rounded-circle">
                                                            <i className={cn(`${classes.icon} mdi mdi-email-outline text-primary`)}></i>
                                                        </span>
                                                    </div>
                                                    <h5 className={cn(`${classes.innerHeaderMessage} text-uppercase mt-4`)}>
                                                        {formatMessage(messages.SITE_MAINTENANCE_SUPPORT_LABEL)}
                                                    </h5>
                                                    <p className={cn(`${classes.innerMessage} text-muted mb-0`)}>{formatMessage(messages.SITE_MAINTENANCE_SUPPORT_LABEL_SUB)} 
                                                    <a href="mailto:info@spectrecreation.co.bw" className="text-decoration-underline">info@spectrecreation.co.bw</a></p>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </>
    );
}

export default PageMaintenance;