import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import { getMainMenus } from "utils/menus";

const Header = ({ setIsMenuOpen}) => {

  const intl = useIntl();
  const { formatMessage } = intl;
  const [activeMenu, setActiveMenu] = useState("");

  useEffect(() => {
    if (!activeMenu) {
      setIsMenuOpen(false);
    }else{
      setIsMenuOpen(true);
    }
  }, [activeMenu]);

  const menus = getMainMenus();

  const removeMenuReference = () =>{    
    setActiveMenu("");
  }

  const renderMenus = () =>{
    return menus.map((data) => {
      const menu = JSON.parse(JSON.stringify(data))
      return (
        <>
          {menu?.isActive && (
            <div key={`${menu?.label}`} className={`header-menu-item ${menu?.hasSubMenus && 'has-sub-menu'} ${menu?.label === activeMenu ? "active-submenu" : ""}`}>
              {(menu?.label && menu?.hasSubMenus) && <p className="header-menu-item-label" onClick={()=> setActiveMenu(menu?.label !== activeMenu ? menu?.label : "")}>{formatMessage(messages[menu?.label])}</p>}
              {menu?.hasSubMenus && (
                <div className="header-sub-menu">
                  <div className="container header-sub-menu-container">
                    <div className={`header-sub-menu-intro-column col-3 ${ !menu?.headerSubMenu?.description && 'has-badge'}`}>
                      {menu?.headerSubMenu?.title && <p className="header-sub-menu-intro-column-title">{formatMessage(messages[menu?.headerSubMenu?.title])}</p>}
                      {menu?.headerSubMenu?.description && <p className="header-sub-menu-intro-column-text">{formatMessage(messages[menu?.headerSubMenu?.description])}</p>}
                    </div>
                    <div className={`header-sub-menu-columns columns-${menu?.headerSubMenu?.columns?.length}`}>
                      {renderSubMenus(menu?.headerSubMenu?.columns || [])}
                    </div>
                    <div className="header-sub-menu-close-btn" onClick={()=> removeMenuReference()}></div>
                  </div>
                </div>
              )}
              {(menu?.label && !menu?.hasSubMenus) &&(
                <a href={menu?.mainLink} className="header-menu-item-label">
                  <span>{formatMessage(messages[menu?.label])}</span>
                </a>
              )}
            </div>
          )}
        </>
      );
    })
  }

  const renderSubMenus = (subMenus) =>{
    return (
      <>
        {subMenus?.map((subMenu, index) => {
          return (
            <div key={index} className="header-sub-menu-column">
              {subMenu?.title && <p className="header-sub-menu-title">{formatMessage(messages[subMenu?.title])}</p>}
              {renderSubMenuLink(subMenu?.links || [])}
            </div>
          );
        })}
      </>
    );
  }

  const renderSubMenuLink = (links) =>{
    return (
      links?.map((link, index) => {
        return (
          link?.isActive ?
            <div key={index} className="header-sub-menu-link ">
              <Link to={link?.link} onClick={()=> {
                setActiveMenu(null);
                setIsMenuOpen(false);
              }}>
                <span>{formatMessage(messages[link?.name])}</span>
              </Link>
            </div>
            :
          ""
        );
      })
    )
  }

  return (
    <nav className="header-menu" itemType="https://schema.org/SiteNavigationElement" role="menu">
      {renderMenus()}
      <div className="header-menu-page-shadow"></div>
    </nav>
  );
};

export default Header;
