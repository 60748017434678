import { makeStyles, createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    footerLogo: {
      flex: "0 0 auto",
      marginRight: "16px",
      '@media (maxWidth:900px)': {
        flex: "1 1 100%"
      },
    },
  
    footerLogoImgWrapper: {
      display: "block",
      marginTop: "-130px",
      '&& img:': {
        display: "block",
        height: "100px",
        width: "100px",
      }
    },  
  
    footerSocials: {
      marginTop: "16px"
    },
  
    footerSocialsLinks: {
      alignItems: "center",
      display: "flex",
      '&& a': {
        display: "block",
        transition: "opacity 16s ease-in-out"
      },
      ' && a:not(:last-child)': {
        marginRight: "30px"
      },
      '&& a:hover': {
        opacity: 5
      }
    },

    socialsLinksBefore: {
      color: "#fff",
      display: "block",
      fontFamily: "s-icons",
      fontSize: "22px",
      fontWeight: 400,
      height: "24px",
      width: "24px"
    },

    linkedin: {
      content: "\e907"
    },  
    twitter: {
      content: "\e90e"
    },  
    facebook: {
      content: "\e904"
    },  
    dribble: {
      content: "\e919"
    },
  
    footerContactsCall: {
      position: "relative",
      width: "fit-content"
    },
  
    // footer-contacts-callactive footerContactsCall-btn: {
    //   backgroundColor: "hsla(0, 0%, 100%, 1)"
    // }
  
    // footerContactsCall-btn: {
    //   backgroundColor: "transparent",
    //   border: "1px solid #fff",
    //   color: "#fff",
    //   cursor: "pointer",
    //   fontSize: "14px",
    //   lineHeight: "normal",
    //   padding: "10px 56px",
    //   transition: "all 16s ease"
    // }
  
    // footerContactsCall-btn:hover: {
    //   backgroundColor: "hsla(0, 0%, 100%, 1)"
    // }
  
    // footerContactsCall-tooltip: {
    //   background: "#fff",
    //   borderRadius: "1px",
    //   bottom: "calc(100% + 14px)",
    //   boxShadow: "0 2px 30px 0 rgba(0, 0, 0, 1)",
    //   left: "50%",
    //   opacity: 0,
    //   padding: "16px 24px",
    //   position: "absolute",
    //   transform: "translateX(-50%)",
    //   transition: "opacity 26s ease-in-out, visibility 0s 26s",
    //   visibility: "hidden",
    //   width: "400px",
    //   zIndex: 10
    // }
  
    // @media (maxWidth:767px): {
    //   footerContactsCall-tooltip: {
    //     width: "330px"
    //   }
    // }
  
    // footerContactsCall-tooltip-ledge: {
    //   borderColor: "#fff transparent transparent",
    //   borderStyle: "solid",
    //   borderWidth: "8px 8px 0",
    //   bottom: "calc(100% + 65px)",
    //   height: 0,
    //   left: "calc(50% - 8px)",
    //   opacity: 0,
    //   position: "absolute",
    //   transition: "opacity 26s ease-in-out, visibility 0s 26s",
    //   visibility: "hidden",
    //   width: 0,
    //   zIndex: 11
    // }
  
    // footerContactsCall-tooltip-content: {
    //   position: "relative"
    // }
  
    // footerContactsCall-tooltip-title: {
    //   color: "#222",
    //   fontSize: "18px",
    //   fontWeight: 600,
    //   marginBottom: "16px"
    // }
  
    // footerContactsCall-tooltip-close: {
    //   background: "transparent",
    //   border: "none",
    //   color: "#222",
    //   cursor: "pointer",
    //   display: "block",
    //   outline: "none",
    //   padding: "3px",
    //   position: "absolute",
    //   right: 0,
    //   top: 0
    // }
  
    // footerContactsCall-tooltip-close i: {
    //   fontSize: "14px",
    //   fontWeight: 700
    // }
  
    // footerContactsCall-tooltip-form-row: {
    //   alignItems: "flex-start",
    //   display: "flex",
    //   marginBottom: "16px"
    // }
  
    // @media (maxWidth:767px): {
    //   footerContactsCall-tooltip-form-row: {
    //     display: "block"
    //   }
    // }
  
    // footerContactsCall-tooltip-form-row form-input-wrapper: {
    //   flex: "1 1 auto"
    // }
  
    // footerContactsCall-tooltip-form-row form-input-wrapper:not(:last-child): {
    //   marginRight: "16px"
    // }
  
    // @media (maxWidth:767px): {
    //   footerContactsCall-tooltip-form-row form-input-wrapper:not(:last-child): {
    //     margin: "0 0 16px"
    //   }
    // }
  
    // footerContactsCall-tooltip-form-row form-input: {
    //   padding: "8px 10px"
    // }
  
    // footerContactsCall-tooltip-success-text: {
    //   color: "#222",
    //   fontSize: "18px",
    //   lineHeight: 178
    // }
  
    // footerContactsCall-tooltip-submit: {
    //   background: "#000",
    //   border: "none",
    //   borderRadius: "2px",
    //   color: "#fff",
    //   cursor: "pointer",
    //   fontSize: "16px",
    //   fontWeight: 600,
    //   lineHeight: 15,
    //   outline: "none",
    //   padding: "9px 32px",
    //   transition: "all 16s ease"
    // }
  
    // footerContactsCall-tooltip-submit:hover: {
    //   backgroundColor: "#CBD4DD"
    // }
  
    // footerContactsCall-tooltip-submitdisabled: {
    //   opacity: 5,
    //   pointerEvents: "none",
    //   backgroundColor: "#303131",
    // }
  
    // footerContactsCall-tooltip-submit-success: {
    //   backgroundColor: "#005eb8",
    //   borderRadius: "2px",
    //   color: "#fff",
    //   display: "none",
    //   fontFamily: "s-icons",
    //   fontSize: "20px",
    //   fontWeight: 600,
    //   lineHeight: 15,
    //   marginLeft: "16px",
    //   opacity: 5,
    //   padding: "8px 32px"
    // }
  
    // footerContactsCall-tooltip-submit-successactive: {
    //   alignItems: "center",
    //   display: "flex",
    //   justifyContent: "center"
    // }
  
    // footer-contacts-callactive footerContactsCall-tooltip,
    // footer-contacts-callactive footerContactsCall-tooltip-ledge: {
    //   opacity: 1,
    //   transition: "opacity 26s ease-in-out, visibility 0s 0s",
    //   visibility: "visible"
    // }
  
    // footer-contacts: {
    //   flex: "1 1 100%"
    // }
  
    // @media (maxWidth:1279px): {
    //   footer-contacts: {
    //     flex: "1 1 auto"
    //   }
    // }
  
    // @media (maxWidth:900px): {
    //   footer-contacts: {
    //     marginTop: "44px"
    //   }
    // }
  
    // footer-contacts-row: {
    //   alignItems: "flex-start",
    //   display: "flex",
    //   flex: "1 1 100%"
    // }
  
    // footer-contacts-links: {
    //   display: "flex",
    //   flex: "1 1 100%",
    //   justifyContent: "space-between",
    //   marginBottom: "-16px"
    // }
  
    // @media (maxWidth:1279px): {
    //   footer-contacts-links: {
    //     flexWrap: "wrap",
    //     justifyContent: "flex-start",
    //     marginLeft: "auto",
    //     maxWidth: "644px",
    //     paddingLeft: "24px"
    //   }
    // }
  
    // @media (maxWidth:900px): {
    //   footer-contacts-links: {
    //     marginLeft: 0,
    //     paddingLeft: 0
    //   }
    // }
  
    // @media (maxWidth:680px): {
    //   footer-contacts-links: {
    //     display: "block"
    //   }
    // }
  
    // footer-contacts-links-item: {
    //   alignItems: "flex-start",
    //   color: "hsla(0, 0%, 100%, 7)",
    //   display: "flex",
    //   flex: "0 0 auto",
    //   fontSize: "14px",
    //   lineHeight: 15,
    //   marginBottom: "16px",
    //   maxWidth: "270px",
    //   minWidth: "120px"
    // }
  
    // @media (maxWidth:1279px): {
    //   footer-contacts-links-item: {
    //     maxWidth: "unset"
    //   }
  
    //   footer-contacts-links-item:nth-child(odd): {
    //     flex: "0 0 58%"
    //   }
    // }
  
    // footer-contacts-links-item:first-child: {
    //   marginLeft: "7%"
    // }
  
    // @media (maxWidth:1439px): {
    //   footer-contacts-links-item:first-child: {
    //     marginLeft: "4%"
    //   }
    // }
  
    // @media (maxWidth:1279px): {
    //   footer-contacts-links-item:first-child: {
    //     marginLeft: 0
    //   }
    // }
  
    // footer-contacts-links-item:not(:last-child): {
    //   marginRight: "16px"
    // }
  
    // footer-contacts-links-item>i: {
    //   display: "block",
    //   height: "24px",
    //   marginRight: "8px",
    //   width: "24px"
    // }
  
    // footer-contacts-links-item>i:before: {
    //   alignItems: "center",
    //   color: "#fff",
    //   display: "flex",
    //   fontSize: "24px",
    //   height: "24px",
    //   justifyContent: "center",
    //   lineHeight: "normal",
    //   width: "24px"
    // }
  
    // footer-contacts-links-item>iicon-s-phone:before: {
    //   fontSize: "22px"
    // }
  
    // footer-contacts-links-item a: {
    //   transition: "all 16s ease-in-out"
    // }
  
    // footer-contacts-links-item a:hover: {
    //   color: "#fff"
    // }
  
    // footer-contacts-links-item-email:hover: {
    //   textDecoration: "underline"
    // }
  
    // footer-contacts-links-item-phones: {
    //   width: "100%"
    // }
  
    // footer-contacts-links-item-phone: {
    //   display: "block"
    // }
  
    // @media (maxWidth:1023px): {
    //   footer-contactsaddresses-count-2: {
    //     flex: "1 1 100%",
    //     marginTop: "44px"
    //   }
    // }
  
    // footer-contactsaddresses-count-2 footer-contacts-rows: {
    //   display: "flex",
    //   justifyContent: "space-evenly"
    // }
  
    // @media (maxWidth:1023px): {
    //   footer-contactsaddresses-count-2 footer-contacts-rows: {
    //     justifyContent: "flex-start"
    //   }
    // }
  
    // @media (maxWidth:680px): {
    //   footer-contactsaddresses-count-2 footer-contacts-rows: {
    //     display: "block",
    //     marginBottom: "-30px"
    //   }
    // }
  
    // footer-contactsaddresses-count-2 footer-contacts-row: {
    //   display: "block",
    //   flex: "0 0 auto"
    // }
  
    // @media (maxWidth:1023px): {
    //   footer-contactsaddresses-count-2 footer-contacts-row: {
    //     marginRight: "auto"
    //   }
    // }
  
    // @media (maxWidth:680px): {
    //   footer-contactsaddresses-count-2 footer-contacts-row: {
    //     paddingBottom: "30px"
    //   }
    // }
  
    // footer-contactsaddresses-count-2 footer-contacts-links: {
    //   display: "block",
    //   flex: "0 0 auto"
    // }
  
    // @media (maxWidth:1023px): {
    //   footer-contactsaddresses-count-2 footer-contacts-links: {
    //     marginLeft: 0,
    //     paddingLeft: 0
    //   }
    // }
  
    // @media (maxWidth:1279px): {
    //   footer-contactsaddresses-count-2 footer-contacts-links-item: {
    //     maxWidth: "270px"
    //   }
    // }
  
    // footer-contactsaddresses-count-2 footer-contacts-links-item:first-child: {
    //   marginLeft: "0 !important"
    // }
  
    // footer-links: {
    //   alignItems: "center",
    //   display: "flex",
    //   flexWrap: "wrap",
    //   marginBottom: "-16px",
    //   marginRight: "16px"
    // }
  
    // @media (maxWidth:680px): {
    //   footer-links: {
    //     display: "block"
    //   }
    // }
  
    // footer-links-item: {
    //   color: hsla(0, 0%, 100%, 7),
    //   fontSize: "14px",
    //   marginBottom: "16px"
    // }
  
    // footer-links-item:not(:last-child): {
    //   marginRight: "32px"
    // }
  
    // footer-links-item a: {
    //   color: "hsla(0, 0%, 100%, 7)",
    //   transition: "all 16s ease-in-out"
    // }
  
    // footer-links-item a:hover: {
    //   color: "#fff"
    // }
  
    // footer-badges: {
    //   display: "flex",
    //   marginBottom: "-16px",
    //   marginLeft: "auto"
    // }
  
    // @media (maxWidth:1279px): {
    //   footer-badges: {
    //     flex: "1 1 100%",
    //     marginLeft: 0,
    //     marginTop: "44px"
    //   }
    // }
  
    // @media (maxWidth:680px): {
    //   footer-badges: {
    //     flexWrap: "wrap"
    //   }
    // }
  
    // footer-badges>*: {
    //   marginBottom: "16px"
    // }
  
    // footer-badges-financial-times: {
    //   display: "block",
    //   marginRight: "32px"
    // }
  
    // footer-badges-financial-times img: {
    //   display: "block",
    //   height: "57px",
    //   width: "auto"
    // }
  
    // footer-badges-bbb: {
    //   display: "block",
    //   height: "57px",
    //   marginRight: "32px !important",
    //   overflow: "hidden",
    //   width: "150px"
    // }
  
    // footer-badges-bbb img: {
    //   display: "block",
    //   height: "57px",
    //   width: "300px"
    // }
  
    // footer-badges-bbb img:hover: {
    //   marginLeft: "-150px"
    // }
  
    // footer-badges-clutch: {
    //   alignItems: "center",
    //   display: "flex",
    //   height: "58px"
    // }
  
    // footer-badges-clutch iframe: {
    //   backgroundColor: "#fff",
    //   borderRadius: "3px",
    //   cursor: "pointer",
    //   margin: "0 -21px",
    //   transform: "scale(65)",
    //   width: "120px"
    // }
  
    // footer-copyright: {
    //   color: "hsla(0, 0%, 100%, 7)",
    //   fontSize: "14px",
    //   marginRight: "16px"
    // }
  
    // @media (maxWidth:680px): {
    //   footer-copyright: {
    //     width: "100%"
    //   }
    // }
  
    // footer-copyright-text: {
    //   display: "flex",
    //   flexWrap: "wrap",
    //   marginBottom: "-4px"
    // }
  
    // footer-copyright-text-corporation: {
    //   marginRight: "4px"
    // }
  
    // footer-copyright-text p: {
    //   fontSize: "inherit",
    //   lineHeight: "inherit",
    //   marginBottom: "4px"
    // }
  
    // footer-copyright-dmca: {
    //   marginTop: "8px"
    // }
  
    // footer-language: {
    //   fontSize: "14px",
    //   marginLeft: "auto",
    //   position: "relative"
    // }
  
    // @media (maxWidth:680px): {
    //   footer-language: {
    //     marginLeft: 0,
    //     marginTop: "16px"
    //   }
    // }
  
    // footer-language-current: {
    //   alignItems: "center",
    //   color: "#fff",
    //   cursor: "pointer",
    //   display: "flex",
    //   padding: "8px 22px 8px 2px",
    //   position: "relative"
    // }
  
    // footer-language-current:after: {
    //   content: "\e90d",
    //   display: "block",
    //   fontFamily: "s-icons",
    //   fontSize: "12px",
    //   height: "12px",
    //   position: "absolute",
    //   right: "3px",
    //   top: "calc(50% - 6px)",
    //   transform: "rotate(-90deg)",
    //   transition: "transform 26s ease-in-out",
    //   width: "7px"
    // }
  
    // footer-language-current img: {
    //   display: "block",
    //   marginRight: "6px"
    // }
  
    // footer-language-list: {
    //   backgroundColor: "#193d5f",
    //   bottom: "100%",
    //   opacity: 0,
    //   position: "absolute",
    //   right: 0,
    //   transition: "opacity 32s ease, visibility 0s 32s",
    //   visibility: "hidden"
    // }
  
    // footer-language-list-item: {
    //   alignItems: "center",
    //   backgroundColor: "inherit",
    //   color: "hsla(0, 0%, 100%, 7)",
    //   cursor: "pointer",
    //   display: "flex",
    //   padding: "8px 12px",
    //   transition: "all 16s ease"
    // }
  
    // footer-language-list-item:hover: {
    //   backgroundColor: "hsla(0, 0%, 100%, 3)",
    //   color: "#fff"
    // }
  
    // footer-language-list-item img: {
    //   display: "block",
    //   marginRight: "6px"
    // }
  
    // footer-languageactive footer-language-current:after: {
    //   transform: "rotate(-270deg)"
    // }
  
    // footer-languageactive footer-language-list: {
    //   opacity: 1,
    //   transition: "opacity 32s ease-in-out, visibility 0s 0s",
    //   visibility: "visible"
    // }
  
    footerContent: {
      backgroundColor: "#000",
      padding: "44px 0",
      '@media (maxWidth:1279px)': {
        paddingBottom: "52px"
      },
    
      '@media (maxWidth:680px)': {
        paddingBottom: "44px"
      }
    },
  
    
  
    footerRow: {
      display: "flex",
      '&& not(:last-child)': {
        marginBottom: "32px"
      },
      '@media (maxWidth:1279px)': {
        flexWrap: "wrap",
        '&& not(:last-child)': {
          marginBottom: "44px"
        }
      },
    }
  })
);
export default useStyles
