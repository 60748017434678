import { events } from "../actions";
import { onRequestError, onCompleteRequest, formatRequest } from "./common";
import axios from "axios";
import { call } from "redux-saga/effects";

function submitCallRequest(callbackData) {
  return axios(
    formatRequest(
      `/api/app/marketing/request/call-back`,
      "post",
      callbackData,
      false,
      false,
      undefined,
      null,
      true
    )
  );
}
export function* postCallRequest(action) {
  try {    
    const { data: apiResponse = {} } = yield call(
      submitCallRequest,
      action.callbackData,
    );
    let { success, errors = [], message } = apiResponse;
    if (success) {        
      yield onCompleteRequest(events.marketing.callback.RECEIVED, success);
    } else {

      const errorObject = errors.length > 0 ? errors[0] : {};
      let errorCode = errorObject?.errorCode || "GENERIC_ERROR";
      const errorMsgFromBE = errorObject?.errorMsg || message || "";

      yield onRequestError(events.marketing.callback.FAILED, errorCode, errorMsgFromBE);
    }
  } catch (e) {
    yield onRequestError(events.marketing.callback.FAILED, "GENERIC_ERROR", e.message ? e.message : e);
  }
}