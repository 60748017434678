import React, { useEffect } from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import App from "./App";
import { actions as globalActions } from "actions/global";
import translations from "i18n";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const AppI18n = ({locale, setLang})=>{
    const userLanguage = locale || "en";
    const messages = translations[userLanguage];
    useEffect(() => {
        setLang(userLanguage);
    }, [])

    return (
        <IntlProvider locale={locale} messages={messages}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </IntlProvider>
    );
}

function mapStateToProps(state) {
  return {
    locale: state?.Global?.language || "en",
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    setLang: (lang) => dispatch(globalActions.global.setCurrentLanguage(lang)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppI18n);
