import React from 'react';
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";

import { Acunetix, Aircrack, AmazonS3, AndroidLogo, AngularLogo,
    Ansible, ApacheHbaseLogo, ApacheKafka, ApacheMesosLogoVertical, Appium, Aws,
    AzureDevopsLogoVertical, AzureFull, Burpsuite, CPlusPlus, Cassandra, Chef, Cloudflare, Css, DatadogLogo,
    DigitalOcean, FlutterLogo, Fmbt, GitlabCiCdLogo, Gitlab, GoogleCloudLogo, Html, CouchBaseServer, Redis, Hadoop, Elasticsearch,
    AwsDeveloperTools, GrafanaLogo, // ApacheJmeter, DirbLogo, , GoogleDeveloperTools, , Hive, HpQuicktestProfessional, ApacheZookeperLogo, }
    
    IosLogo, JavaLogo, JavascriptLogo, Jenkins, KubernetesLogo, Masscan, MetasploitR7, MongodbLogo, MsSql, Mysql, NagiosLogo, Nessus, Nmap, NodeJs, 
    Oracle, PackerLogo, PhpLogo, PostgresqlVerticalLogo, PrometheusLogo, PwaLogo, Qradar, Ranorex, ReactJsLogo, ReactNativeLogo, Saltstack, Selenium,
    Spark, Teamcity, TerraformLogo, TeststackWhite, Xcuit, ZabbixLogo, Zmap, Python,
    
    OpenshiftLogo, Puppet, Postman, //Protractor,  , SiegeLogo, UnifiedFunctionalTesting, Wireshark, Xcuitest,
} from "assets/images/app/stack/stack";

const stackItems = [
    {
        title: "Web",
        showInfo: true,
        groups: [
            {
                title: "Back end",
                groupTechnologies: [
                    {
                        link: "/services/java-development",
                        img: JavaLogo,
                        isActive: true,
                    },
                    {
                        link: "/services/hire-python-developers",
                        img: Python,
                        isActive: false,
                    },
                    {
                        link: "/services/nodejs",
                        img: NodeJs,
                        isActive: true,
                    },
                    {
                        link: "/services/php",
                        img: PhpLogo,
                        isActive: true,
                    },
                    {
                        link: "/services/cpp-development",
                        img: CPlusPlus,
                        isActive: false,
                    },
                ]
            },
            {
                title: "Front end",
                groupTechnologies: [
                    {
                        link: "#",
                        img: Html,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Css,
                        isActive: true,
                    },
                    {
                        link: "/services/javascript",
                        img: JavascriptLogo,
                        isActive: true,
                    },
                    {
                        link: "/services/angular",
                        img: AngularLogo,
                        isActive: true,
                    },
                    {
                        link: "/services/reactjs-consulting",
                        img: ReactJsLogo,
                        isActive: true,
                    },
                ]
            }
        ]
    },
    {
        title: "Mobile",
        showInfo: true,
        groups: [
            {
                groupTechnologies: [
                    {
                        link: "/services/mobile-app-development/ios",
                        img: IosLogo,
                        isActive: true,
                    },
                    {
                        link: "/services/mobile-app-development/android",
                        img: AndroidLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: PwaLogo,
                        isActive: true,
                    },
                    {
                        link: "services/hire-react-native-developers",
                        img: ReactNativeLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: FlutterLogo,
                        isActive: true,
                    },
                ]
            }
        ]
    },
    {
        title: "Clouds",
        showInfo: true,
        groups: [
            {
                groupTechnologies: [
                    {
                        link: "/aws/cloud-consulting",
                        img: Aws,
                        isActive: true,
                    },
                    {
                        link: "/cloud/azure/consulting",
                        img: AzureFull,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: GoogleCloudLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: DigitalOcean,
                        isActive: true,
                    },
                ]
            }
        ]
    },
    {
        title: "Databases & data storages",
        showInfo: true,
        groups: [
            {
                groupTechnologies: [
                    {
                        link: "#",
                        img: MsSql,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Mysql,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Oracle,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: PostgresqlVerticalLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: AmazonS3,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: CouchBaseServer,
                        isActive: true,
                    },
                    
                    {
                        link: "#",
                        img: Redis,
                        isActive: true,
                    },
                ]
            }
        ]
    },
    {
        title: "Big data",
        showInfo: true,
        groups: [
            {
                groupTechnologies: [
                    {
                        link: "/services/big-data/hadoop",
                        img: Hadoop,
                        isActive: true,
                    },
                    {
                        link: "/services/big-data/spark",
                        img: Spark,
                        isActive: true,
                    },
                    {
                        link: "/services/big-data/cassandra",
                        img: Cassandra,
                        isActive: true,
                    },
                    {
                        link: "/analytics/big-data/kafka",
                        img: ApacheKafka,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: "Hive",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: "ApacheZookeperLogo",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: ApacheHbaseLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Elasticsearch,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: MongodbLogo,
                        isActive: true,
                    },
                ]
            }
        ]
    },
    {
        title: "DevOps",
        showInfo: true,
        groups: [
            {
                title: "Containerization",
                groupTechnologies: [
                    {
                        link: "#",
                        img: Jenkins,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: KubernetesLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: OpenshiftLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: ApacheMesosLogoVertical,
                        isActive: true,
                    },
                ]
            },
            {
                title: "Automation",
                groupTechnologies: [
                    {
                        link: "#",
                        img: Ansible,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Puppet,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Chef,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Saltstack,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: TerraformLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: PackerLogo,
                        isActive: true,
                    },
                ]
            },
            {
                title: "CI/CD tools",
                groupTechnologies: [
                    {
                        link: "#",
                        img: AwsDeveloperTools,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: AzureDevopsLogoVertical,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: "GoogleDeveloperTools",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: GitlabCiCdLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Gitlab,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Teamcity,
                        isActive: true,
                    },
                ]
            },
            {
                title: "Monitoring",
                groupTechnologies: [
                    {
                        link: "#",
                        img: ZabbixLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: NagiosLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Elasticsearch,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: PrometheusLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: GrafanaLogo,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: DatadogLogo,
                        isActive: true,
                    },
                ]
            },
        ]
    },
    {
        title: "Test automation tools",
        showInfo: true,
        groups: [
            {
                groupTechnologies: [
                    {
                        link: "#",
                        img: Selenium,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Appium,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: "Protractor",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: Fmbt,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: "Xcuitest",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: TeststackWhite,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Xcuit,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Ranorex,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Postman,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: "ApacheJmeter",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: "HpQuicktestProfessional",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: "UnifiedFunctionalTesting",
                        isActive: false,
                    },
                ]
            },
        ]
    },
    {
        title: "Information security",
        showInfo: true,
        groups: [
            {
                groupTechnologies: [
                    {
                        link: "/services/security/siem/ibm-qradar",
                        img: "Gradar",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: Cloudflare,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: "SiegeLogo",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: Burpsuite,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Nessus,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Aircrack,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Acunetix,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: MetasploitR7,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Nmap,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: "DirbLogo",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: "Wireshark",
                        isActive: false,
                    },
                    {
                        link: "#",
                        img: Zmap,
                        isActive: true,
                    },
                    {
                        link: "#",
                        img: Masscan,
                        isActive: true,
                    },
                ]
            },
        ]
    }
];

const useStyles = makeStyles((theme) => ({
  partnerImageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    textRendering: "optimizeSpeed", 
    fontFamily: "Open Sans,sans-serif", 
    fontSize: "16px", 
    color: "#3b3c3d",
    boxSizing: "border-box", 
    height: "73px",
    width: "10215%", 
    position: "relative", 
    transitionDuration: "0s",
  },
  partnerImageHolder: {
    float: "left", 
    position: "relative", 
    width: "114.857px", 
    marginRight: "10px", 
    marginLeft: "10px", 
    marginBottom: "10px",
  },
  serviceTabContainerData: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "624.281px"
  },
  serviceTabContentContainer: {
    position: "relative",
    width: "92%",
    transitionDuration: "0s",
  },
  serviceTabContent: {
    float: "left",
    listStyle: "none",
    position: "relative",
  },
}));

const Stack = () => {

    const classes = useStyles();
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <>
            <div className="spectrecreation_area_technologies spectrecreation_area_content ">
                <div className="t-wrapper container" >
                    <div className="t-content ">
                        <h2 className="t-title"><span id="Technologies" className="not-hover-link" name="Technologies">Our Technological Stack</span></h2>
                        <div className="t-description d-none"></div>
                        <div className="t-items-wrapper lazy entered loaded" style={{ backgroundColor: "rgb(219 223 225)" }}>
                            <div className="t-items-container">
                                <div className="spectrecreation_area_technologies spectrecreation_area_content ">
                                    <div className="t-items">
                                        {stackItems.map((item) => {
                                            return item?.showInfo && <div className="t-item" key={item?.title}>
                                                {item?.title && <h3 className="t-item-title">{item?.title}</h3>}
                                                <div className="t-item-groups">
                                                    {item?.groups?.map((group) => {
                                                        return <div className="t-item-group" key={group?.title}>
                                                            {group?.title && <p className="t-item-group-title">{group?.title}</p>}
                                                            <div className="t-item-group-technologies">
                                                                {group?.groupTechnologies?.map((tech) => {
                                                                    return tech?.isActive && <div className="t-item-group-technology" key={tech?.img}>
                                                                        <a target="" className="t-item-group-technology-content has-popup"> {/** href={tech?.link} */}
                                                                            <img src={tech?.img} width="81" height="43" className="lazy entered loaded"></img>
                                                                        </a>
                                                                    </div>
                                                                })}
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className="t-items-shadow"></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Stack;