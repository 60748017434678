import { combineReducers } from "redux";
import * as actionTypes from "../actions/actionTypes";
import Error from "./Error";
import Loading from "./Loading";
import Global from "./Global";
import Layout from "./Layout"
import Marketing from "./Marketing"

const appReducer = combineReducers({
  Layout,
  Error,
  Loading,
  Global,
  Marketing
});

const rootReducer = (state, action) => {
  // Clear all data in redux store to initial.
  if (action.type === actionTypes.DESTROY_SESSION) state = undefined;

  return appReducer(state, action);
};
export default rootReducer;