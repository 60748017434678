import { createTheme } from "@material-ui/core/styles";

const primaryColor = "#ED2600";
const brandLightColor = "#306375";
const primaryColorDisabled = "#42a5f5";
const primaryColorHover = "#384551";
const vividYellow = "#fbd105";


const infoAlertBG = "#fffce5";
const infoAlertBorder = "#fbe840";
const alertBorder = "#fbe840";
const greyColor = "#aeafb0";
const errorAlertText = "#f14f4b";



const cosmicCobalt = "#2F2E80";
const bluePurple = "#A7AFDD";

const secondaryColor = vividYellow;
const secondaryTextColor = "#333333";
const secondaryBackgroundColor = cosmicCobalt;

const success = "#05AC41"; 
const error = "#F52523";
const warning = "#FF8907";
const warningPrimary = "#d5292a";
const deepRed = "#ffccd1";
const lightYellow = "#fff7bf";

const darkGrey = "#90969A";
const veryDarkGray = "#3c3c3c";
const lightGrey = "#DBDDE1";
const backgroundGray = "#F4F4F5";
const limeGreen = "#4a4f4c";
const brightYellow = "#fbe840";
const paleWhiteBlue = "#f7f9ff";
const lightGrayishBlue = "#eaecf7";
const lightGrayish = "#eaeef6";
const desaturatedBlue = "#6b76c3";
const lightGreen = "#a3bd52";
const lightishGreen = "#e3ebc9";
const blueBerry = "#3C4096";

const textLabel = "#a8abae";
const formLabel = "#757a77";
const formInput = "#2a2d2a";
const link = "#3c4096";
const tertiaryBlack = "#282d2a";
const placeHolder = "#d8d9df";

const errorAlertBorder = "#f19997";
const errorAlertBG = "#ffebee";
const infoAlertIcon = "#696969";
const darkGreyCyan = "#4d5651";
const lightGrayishBlueSecondary = "#f0f2fc";
const tertiaryOrange = "#e88923";
const palmLeaf = "#7f9a40";
const feldspar = "#F6D5B2";
const aquaPearl = "#5DAED7";

const white = "#ffffff";
const lightGreen2 = "#D1DEA7";

const theme = createTheme({
  typography: {
    heading1: {
      fontSize: 20,
    },
    heading: {
      fontSize: 24,
    },
    heading2: {
      fontSize: 20,
    },
    globalFont: {
      fontSize: 16,
    },
    subtitle: {
      fontSize: 12,
    },
    subtitle1: {
      fontSize: 14,
    },
    fontFamily: `"LatoRegular", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 400,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 600,
    fontWeightBoldRegular: 700,
  },
  breakpoints: {
    values: {
      xs: 360,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
  palette: {
    colors: {
      white,
      veryDarkGray,
      vividYellow,
      greyColor
    },
    primary: {
      main: primaryColor,
      light: brandLightColor,
      disabled: primaryColorDisabled,
      link: link,
      iconBorder: vividYellow,
      alertBorder: brightYellow,
      tertiaryOrange,
    },
    secondary: {
      main: secondaryColor,
    },
    info: {
      main: lightGrey,
      alertBG: infoAlertBG,
      infoBorder: infoAlertBorder,
      alertIcon: infoAlertIcon,
      infoText: formLabel,
      alertBorder: alertBorder,
    },
    success: {
      main: success,
    },
    error: {
      main: error,
      alertBG: errorAlertBG,
      alertBorder: errorAlertBorder,
      alertText: errorAlertText,
    },
    warning: {
      main: warning,
      primary: warningPrimary,
    },
    stepper: {
      progress: lightGreen,
    },
    text: {
      primary: "#000",
      secondary: secondaryTextColor,
      disabled: darkGrey,
      label: textLabel,
      formLabel: formLabel,
      input: formInput,
      labelWhite: white,
      tertiaryBlack: tertiaryBlack,
      placeHolder: placeHolder,
      lightGrayishBlue: lightGrayishBlue,
      desaturatedBlue,
      darkGreyCyan,
      paleWhiteBlue,
      palmLeaf,
      blueBerry,
      secondaryBackgroundColor,
      bluePurple,
    },
    background: {
      default: paleWhiteBlue,
      main: white,
      lightGrayish,
      lightGrayishBlue,
      white,
      lightGrayishBlueSecondary,
      lightishGreen,
      lightYellow,
      lightRed: deepRed,
      feldspar,
      aquaPearl,
      secondaryBackgroundColor,
    },
    gray: {
      main: darkGrey,
      medium: secondaryTextColor,
      light: lightGrey,
      limeGreen,
    },
    label: {
      main: formInput,
    },
    fonts: {
      fontFamilyLatoBlack: "LatoBlack",
      fontFamilyLatoBold: "LatoBold",
      fontFamilyLatoLight: "LatoLight",
      fontFamilyLato: "Lato",
      fontFamilyLatoRegular: "LatoRegular",
    },
  },
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "none",
      },
    },
    MuiSelect: {
      root: {
        borderRadius: "1px",
      },
      outlined: {
        borderRadius: "1px",
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        paddingRight: "30px",
        paddingLeft: "30px",
        minWidth: "140px",
        borderRadius: "1px",
      },
      containedPrimary: {
        "&:hover": {
          backgroundColor: primaryColorDisabled,
        },
        height: "40px",
      },
      text: {
        minWidth: "0px",
      },
      outlinedPrimary: {
        borderRadius: "1px",
        "&:hover": {
          backgroundColor: backgroundGray,
        },
        height: "40px",
      },
      outlinedSecondary: {
        borderRadius: "1px",
        height: "40px",
      },
      contained: {
        "&.Mui-disabled": {
          backgroundColor: primaryColorDisabled,
        },
      },
    },
    MuiListItem: {
      root: {
        "&$selected": {
          backgroundColor: primaryColor,
          color: secondaryColor,
        },
        "&.Mui-selected": {
          color: "unset",
          backgroundColor: "unset",
          "&:last-child": {
            borderRight: "unset",
          },
        },
      },
      gutters: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: 0,
        margin: "23px 0 0 0",
        "&:first-child": {
          marginTop: "unset",
        },
        "&:last-child": {
          borderTop: `1px solid ${link}`,
          margin: "0 20px",
        },
      },
    },
    MuiListItemIcon: {
      root: {
        marginLeft: "2px",
        marginTop: "2px",
        minWidth: "unset",
        display: "unset",
        padding: "6px 0",
      },
    },
    MuiTabs: {
      indicator: {
        height: "4px",
      },
      flexContainer: {
        backgroundColor: "#fff !important",
        height: "64px",
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: "capitalize",
        fontSize: "16px",
      },
    },
    MuiOutlinedInput: {
      root: {
        border: "solid 1px #000e1b",
        borderRadius: "0px",
      },
    },
    MuiTableRow: {
      head: {
        height: "64px",
      },
    },
    MuiFormLabel: {
      root: {
        "&.Mui-error": {
          color: formLabel,
        },
        "&.Mui-focused": {
          color: formLabel,
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: textLabel,
      },
    },
    MuiAccordion: {
      root: {
        borderRadius: "15px",
        "&:before": {
          display: "none",
        },
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: "0 30px",
        minHeight: 70,
        "&.Mui-expanded": {
          minHeight: 70,
        },
      },
      content: {
        color: tertiaryBlack,
        fontSize: "16px",
        margin: "18px 0",
        "&.Mui-expanded": {
          margin: "18px 0",
        },
      },
      expandIcon: {
        marginRight: 0,
        padding: 0,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        justifyContent: "space-around",
      },
      iconButton: {
        color: tertiaryBlack,
        fontSize: 16,
        fontWeight: 700,
      },
      transitionContainer: {
        width: "unset",
        "&>*": {
          position: "relative",
          color: tertiaryBlack,
          fontSize: 16,
          fontWeight: 700,
        },
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: vividYellow,
        color: tertiaryBlack,
        fontWeight: 700,
        "&:hover": {
          backgroundColor: vividYellow,
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: lightGrayishBlue,
          backgroundPosition: "right",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
        },
        ".MuiDialogContent-root::-webkit-scrollbar": {
          width: "5px",
        },
        ".MuiDialogContent-root::-webkit-scrollbar-track": {
          "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
          borderRadius: "10px",
        },
        ".MuiDialogContent-root::-webkit-scrollbar-thumb": {
          backgroundColor: greyColor,
        },
        ".rec-arrow-left": {
          backgroundColor: "inherit !important",
          color: "black !important",
          boxShadow: "none !important",
          borderRadius: "0 !important",
          border: "none !important",
        },
        ".rec-arrow-right": {
          backgroundColor: "inherit !important",
          color: "black !important",
          boxShadow: "none !important",
          borderRadius: "0 !important",
          border: "none !important",
        },
      },
    },
    MuiSlider: {
      root: {
        color: vividYellow,
      },
      rail: {
        height: "40px",
        borderRadius: "5px",
      },
      track: {
        height: "40px",
        borderRadius: "5px",
      },
      thumb: {
        backgroundImage: "url('/images/slider-handle.png')",
        backgroundPosition: "right",
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        width: "10px",
        height: "16px",
        borderRadius: "unset",
        marginLeft: "-20px",
        marginTop: "12px",
        "&.Mui-focusVisible": {
          boxShadow: "none",
        },
        "&:hover": {
          boxShadow: "none",
        },
      },
    },
    MuiPagination: {
      root: {
        backgroundColor: lightGrayishBlue,
        width: "100%",
      },
    },
    MuiPaginationItem: {
      page: {
        fontSize: "16px",
        color: darkGreyCyan,
        "&.Mui-selected": {
          backgroundColor: vividYellow,
          borderRadius: "5px",
          width: "50px",
          height: "50px",
          color: formInput,
          fontSize: "20px",
          fontWeight: "700",
        },
      },
      ellipsis: {
        display: "none",
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: white,
        color: formInput,
        padding: "20px",
        borderRadius: "10px",
      },
      message: {
        padding: "0px !important",
        width: "222px",
      },
    },
    MuiInput: {
      formControl: {
        "label + &": { marginTop: "25px" },
      },
    },
    MuiSvgIcon: {
      root: {
        width: "60px",
      }
    }
  },
});

export default createTheme(theme);
