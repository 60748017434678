import React from 'react';
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import cn from "classnames";
import { Grid, makeStyles } from "@material-ui/core";

import { 
    Banking, 
    Healthcare, InformationTechnologies, Insurance, Investment, Lending, Manufacturing, MarketingAndAdvertising,
    Mining, PublicSector, Retail, Telecoms, Transportation,
    // ProfessionalServices
} from "assets/images/app/expertise";

const expertiseList = [
    {
        icon: Healthcare,
        name: "Healthcare",
        link: "/healthcare/software-development"
    },
    {
        icon: Banking,
        name: "Banking",
        link: "/banking"
    },
    {
        icon: Lending,
        name: "Lending",
        link: "/lending"
    },
    {
        icon: Investment,
        name: "Investment",
        link: "/investment"
    },
    {
        icon: Insurance,
        name: "Insurance",
        link: "/insurance/software-development"
    },
    {
        icon: Retail,
        name: "Retail",
        link: "/retail/software-development"
    },
    {
        icon: Manufacturing,
        name: "Manufacturing",
        link: "/manufacturing"
    },
    {
        icon: InformationTechnologies,
        name: "Information Technologies",
        link: "/case-studies/information-technology"
    },
    {
        icon: Mining,
        name: "Mining",
        link: "/mining"
    },
    {
        icon: Telecoms,
        name: "Telecommunication",
        link: "/telecommunications"
    },
    {
        icon: Transportation,
        name: "Transportation",
        link: "/transportation-and-logistics"
    },
    // {
    //     icon: ProfessionalServices,
    //     name: "Professional Services",
    //     link: "/professional-services"
    // },
    {
        icon: MarketingAndAdvertising,
        name: "Marketing and Advertising",
        link: "/adtech-martech"
    },
    {
        icon: PublicSector,
        name: "Public Services",
        link: "/case-studies/public-services"
    },
]

const useStyles = makeStyles((theme) => ({
  partnerImageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    textRendering: "optimizeSpeed", 
    fontFamily: "Open Sans,sans-serif", 
    fontSize: "16px", 
    color: "#3b3c3d",
    boxSizing: "border-box", 
    height: "73px",
    width: "10215%", 
    position: "relative", 
    transitionDuration: "0s",
  },
  partnerImageHolder: {
    float: "left", 
    position: "relative", 
    width: "114.857px", 
    marginRight: "10px", 
    marginLeft: "10px", 
    marginBottom: "10px",
  },
  serviceTabContainerData: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "624.281px"
  },
  serviceTabContentContainer: {
    position: "relative",
    width: "92%",
    transitionDuration: "0s",
  },
  serviceTabContent: {
    float: "left",
    listStyle: "none",
    position: "relative",
  },
}));

const Expertise = () => {

    const classes = useStyles();
    const intl = useIntl();
    const { formatMessage } = intl;

    return (
        <>
            <div className="spectrecreation_area_icon-box spectrecreation_area_content">
                <div className=" icon-box-editable icons-4">
                    <div className="icon-box-editable-wrapper">
                        <h2 className="icon-box-editable__title">
                            Industry Expertise
                        </h2>
                        <p className="icon-box-editable__description">
                            Drawing from extensive experience across diverse industries, we leverage valuable insights to deliver truly beneficial solutions
                            to our customers. Our proficiency spans various sectors, ensuring tailored and impactful outcomes for your unique business needs.
                        </p>
                        <Grid className="icon-box-editable__item-wrapper" container xs={12}>
                            {(expertiseList || [])?.map((action) => {
                                return (
                                    <Grid item xs={6} md={4} lg={3} key={action?.link}>
                                        <a className="icon-box-editable__item hasLink" key={action?.link}> {/** href={action?.link}  */}
                                            {/* <i className="icon-font icon-s-arrow"></i> */}
                                            <div className="icon-box-editable__wrapper">
                                                <div className="icon-box-editable__image">
                                                    <img className="lazy" width="84" height="84" src={action?.icon} alt="" />
                                                </div>
                                                <p className="icon-box-editable__text">
                                                    {action?.name}
                                                </p>
                                            </div>
                                        </a>
                                    </Grid>
                                );
                            })}
                        </Grid>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Expertise;