import React from "react"
import PropTypes from 'prop-types'
import { useDispatch } from "react-redux";
import { actions as globalActions } from "actions/global";

const Breadcrumb = props => {
  const dispatch = useDispatch();
  return (
    <div className="breadcrumbs j-breadcrumb">
      <ul className="breadcrumbs__list " itemScope="" itemType="http://schema.org/BreadcrumbList">
        <li className="breadcrumbs__item" id="breadcrumb_1" itemScope="" itemType="http://schema.org/ListItem">
          <a className="breadcrumbs__item-link" href={props?.titleLink} title={props?.title} itemType="http://schema.org/Thing" onClick={() => dispatch(globalActions.global.setActivePageName(props?.title?.replace(/\s+/g, '')))}>
            <span className="breadcrumbs__title">{props?.title}</span>
            <meta content="1" />
          </a>
        </li>
        <li className="breadcrumbs__item" id="breadcrumb_2" itemScope="" itemType="http://schema.org/ListItem">
          <i className="icon-s-slider-arrow breadcrumbs__icon"></i>
          <a className="breadcrumbs__item-link" href={props?.breadcrumbItemLink} itemType="http://schema.org/Thing" onClick={() => dispatch(globalActions.global.setActivePageName(props?.breadcrumbItem?.replace(/\s+/g, '')))}>
            <span className="breadcrumbs__title">{props?.breadcrumbItem}</span>
            <meta content="2" />
          </a>
        </li>
      </ul>
    </div>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string
}

export default Breadcrumb
