import React, { useState, useEffect } from "react";
import logoImage from "assets/images/logos/logo-inactive.png";
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import dmcaLogo from "assets/images/app/dmca_protected_sml_120l.png";
import * as Yup from "yup";
import { useFormik } from "formik";
import { stringRequired, requestPhoneNumberRequired, stringNotRequired } from "utils/validationUtils";
import { TextField } from "@mui/material";
import { Form } from "reactstrap";
import cn from "classnames";
import { connect } from "react-redux";
import cssStyles from "./styles";
import { actions } from "actions";
import { actions as globalActions } from "actions/global";

const Footer = ({
  errorCode, loading, isCallbackRequestSubmitted, closeError, clearLoading, requestCallback, setActivePage
}) => {
  
  const classes = cssStyles();
  const intl = useIntl();
  const { formatMessage } = intl;  
  const [showRequestForm, setShowRequestForm] = useState(false);

  const contactFormSchema = Yup.object().shape({
    userName: stringRequired(formatMessage(messages.REQUIRED)),
    phone: requestPhoneNumberRequired(formatMessage(messages.REQUIRED), formatMessage(messages.PHONE_NO_VALIDATION_MESSAGE)),
    company: stringNotRequired(),
  });

  const formik = useFormik({
    initialValues:{
      userName: "",
      phone: "",
      company: "",
    },
    enableReinitialize: true,
    validationSchema: contactFormSchema,
    onSubmit: (values) => {
      requestCallback(values)
    }
  });

  const isSubmitButtonDisabled = () =>{
    return loading || (formik.errors && Object.keys(formik.errors).length !== 0) || !(formik.values.userName && formik.values.phone)
  }

  useEffect(() => {
    // clear all errors and loading state once the component is freshly loaded.
    clearLoading();
  }, []);

  const parseErrorCode = () => {
    if (!errorCode) {
      return null;
    }
    if (errorCode && errorCode in messages) {
      return formatMessage(messages[errorCode]);
    }

    return formatMessage(messages.GENERIC_ERROR);
  };

  return (
    <React.Fragment>
      <footer>
        <div className={"footer-content"}>
          <div className="container">
            <div className={"footer-row"}>
              <div className={"footer-logo"}>
                <span className={"footer-logo-img-wrapper"}>
                  <img width="100" height="100" className="lazy" src={logoImage} alt="Spectre Creation footer icon" />
                </span>
                <div className={"footer-socials"}>
                  <div className={"footer-socials-links"}>
                    <a href="https://www.linkedin.com/company/spectre.creation/" target="_blank" rel="noreferrer" aria-label="Linkedin" className={"footer-socials-links-linkedin"}></a>
                    <a href="https://www.facebook.com/SpectreCreation" target="_blank" rel="noreferrer" aria-label="Facebook" className={"footer-socials-links-facebook"}></a>
                  </div>
                </div>
              </div>
              <div className="footer-contacts addresses-count-1">
                <div className="footer-contacts-rows">
                  <div className="footer-contacts-row">
                    <div className="footer-contacts-links">
                      <div className="footer-contacts-links-item">
                        <i className="icon-s-map-marker"></i>
                        <a href="https://goo.gl/maps" target="_blank" rel="noreferrer" className="footer-contacts-links-item-address-location">
                          {formatMessage(messages.COMPANY_PHYSICAL_ADDRESS)}
                        </a>
                      </div>
                      <div className="footer-contacts-links-item">
                        <i className="icon-s-envelope"></i>
                        <a href={`mailto:${formatMessage(messages.COMPANY_EMAIL_ADDRESS)}`} dir="ltr" className="footer-contacts-links-item-email">
                          {formatMessage(messages.COMPANY_EMAIL_ADDRESS)}
                        </a>
                      </div>
                      <div className="footer-contacts-links-item">
                        <i className="icon-s-phone"></i>
                        <div className="footer-contacts-links-item-phones">
                          <a href={`tel:${formatMessage(messages.COMPANY_PHONE_NUMBER)}`} dir="ltr" className="footer-contacts-links-item-phone">
                            {formatMessage(messages.COMPANY_PHONE_NUMBER)}
                          </a>                          
                        </div>
                      </div>
                      <div className={`footer-contacts-links-item footer-contacts-call ${showRequestForm ? "active" : ""}`}>
                        {/* Disabled for now */}
                        {/* <button type="button" className="footer-contacts-call-btn" onClick={()=> {
                          setShowRequestForm(true);
                          closeError();
                          clearLoading();
                        }}>
                          {formatMessage(messages.COMMON_REQUEST_CALL_BACK)}
                        </button> */}
                        <div className="footer-contacts-call-tooltip" style={{left: 'calc(50%)'}}>
                          <div className="footer-contacts-call-tooltip-content">
                            <p className="footer-contacts-call-tooltip-title">
                              {formatMessage(messages.COMMON_REQUEST_CALL_BACK)}
                            </p>
                            <button type="button" className="footer-contacts-call-tooltip-close" aria-label="Close request a call tooltip" onClick={()=> {
                              setShowRequestForm(false);
                            }}>
                              <i className="icon-s-close"></i>
                            </button>
                            <Form className="footer-contacts-call-tooltip-form" onSubmit={(e) => {
                              e.preventDefault();
                              formik.handleSubmit();
                              return false;
                            }}>
                              <div className={`footer-contacts-call-tooltip-form-row ${isCallbackRequestSubmitted ? "d-none": ""}`}>
                                <div className="form-element-wrapper form-input-wrapper">
                                  <TextField
                                    className="form-input" 
                                    fullWidth
                                    id="userName"
                                    name="userName"
                                    label={`${formatMessage(messages.NAME_TITLE)} *`}
                                    value={formik.values.userName}
                                    onChange={formik.handleChange}
                                    error={formik.touched.userName && Boolean(formik.errors.userName)}
                                    helperText={formik.touched.userName && formik.errors.userName}
                                    InputProps={{
                                      "aria-label": "userName",
                                      "name": "userName",
                                      form: {
                                        autocomplete: "off",
                                        maxLength:"150"
                                      },
                                      ...formik.getFieldProps("userName")
                                    }}
                                  />
                                </div>
                                <div className="form-element-wrapper form-input-wrapper">
                                  <TextField
                                    className="form-input" 
                                    fullWidth
                                    id="company"
                                    name="company"
                                    label={formatMessage(messages.COMMON_COMPANY)}
                                    value={formik.values.company}
                                    onChange={formik.handleChange}
                                    error={formik.touched.company && Boolean(formik.errors.company)}
                                    helperText={formik.touched.company && formik.errors.company}
                                    InputProps={{
                                      "aria-label": "company",
                                      "name": "company",
                                      form: {
                                        autocomplete: "off",
                                        maxLength:"150"
                                      },
                                      ...formik.getFieldProps("company")
                                    }}
                                  />
                                </div>
                              </div>
                              <div className={`footer-contacts-call-tooltip-form-row mb-0 ${isCallbackRequestSubmitted ? "d-none": ""}`}>
                                <div className="form-element-wrapper form-input-wrapper">
                                  <TextField
                                    className="form-input" 
                                    fullWidth
                                    id="phone"
                                    name="phone"
                                    type="tel"
                                    label={`${formatMessage(messages.FORM_PHONE_NUMBER_LABEL)} *`}
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                    helperText={formik.touched.phone && formik.errors.phone}
                                    InputProps={{
                                      "aria-label": "phone",
                                      "name": "phone",
                                      form: {
                                        autocomplete: "off",
                                        maxLength:"150"
                                      },
                                      ...formik.getFieldProps("phone")
                                    }}
                                  />
                                </div>
                                <button type="submit" className={`footer-contacts-call-tooltip-submit ${isSubmitButtonDisabled() ? "disabled": ""}`}>
                                  {loading ? formatMessage(messages.SENDING): formatMessage(messages.SEND)}
                                </button>
                              </div>
                              <div className={`footer-contacts-call-tooltip-success-text ${isCallbackRequestSubmitted ? "": "d-none"}`}>
                                {formatMessage(messages.COMMON_THANK_YOU_FOR_REACHING_OUT)} <span className="footer-call-user-name">{formik?.values?.userName}</span>!<br />
                                {formatMessage(messages.COMMON_CUS_CARE_CONTACT_HELP_MSG)}.
                              </div>
                            </Form>
                          </div>
                        </div>
                        <div className="footer-contacts-call-tooltip-ledge"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={"footer-row"}>
              <div className="footer-links">
                {/* Disabled for now */}
                {/* <div className="footer-links-item ">
                  <a href="/about/company" className="footer-links-item-a" onClick={()=>setActivePage("About")}>
                    {formatMessage(messages.ABOUT_COMPANY)}
                  </a>
                </div> */}
                
                {/* <div className="footer-links-item ">
                  <a href="/about/become-our-agent" className="footer-links-item-a">
                    {formatMessage(messages.BECOME_AGENT)}
                  </a>
                </div> */}
                <div className="footer-links-item ">
                  <a href="/about/privacy-policy" className="footer-links-item-a" onClick={()=>setActivePage("PrivacyPolicy")}>
                    {formatMessage(messages.PRIVACY_POLICY_TEXT)}
                  </a>
                </div>
                <div className="footer-links-item ">
                  <a href="/about/terms-of-use" className="footer-links-item-a" onClick={()=>setActivePage("TermsOfUse")}>
                    {formatMessage(messages.TERMS_OF_USE_TEXT)}
                  </a>
                </div>
              </div>
            </div>
            <div className={"footer-row"}>
              <div className="footer-copyright">
                <div className="footer-copyright-text">
                  <p className="footer-copyright-text-corporation">© {new Date().getFullYear()} Spectre Creation.</p>All rights reserved.
                </div>
                {/* Disable for now */}
                {/* <div className="footer-copyright-dmca">
                  <a href="https://www.dmca.com/Protection/Status.aspx" target="_blank" rel="noreferrer" title="DMCA.com Protection Status" className="footer-copyright-dmca-badge" >
                    <img src={dmcaLogo} width="121" height="24" alt="DMCA.com  Status Protection logo" className="lazy"/>
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
      
    </React.Fragment>
  )
}

function mapStateToProps(state) {
  return {
    errorCode: state.Marketing?.callbackRequestError?.errorCode || null,
    loading: state.Loading.loading || false,
    isCallbackRequestSubmitted: state.Marketing?.isCallbackRequestSubmitted || false
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    requestCallback: ( callbackData) => {
      dispatch(actions.marketing.requestCallback(callbackData));
      dispatch(actions.loading.requestLoadingAction());
    },
    closeError: () => {
      dispatch(actions.error.cancelErrorAction());
    },
    clearLoading: () => {
      dispatch(actions.loading.completeLoadingAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
