import React from "react";
import { Redirect } from "react-router-dom";

import ComingSoonModule from "./ComingSoon";
import MaintenanceModule from "./Maintenance";
import NotFoundModule from "./NotFound";
import InternalErrorModule from "./InternalError";


import HomeModule from "./home";
import PrivacyPolicyModule from "./PrivacyPolicy";
import TnCModule from "./TnC";

export default [
    // ComingSoonModule,
    MaintenanceModule,
    NotFoundModule,
    // InternalErrorModule,

    HomeModule,
    PrivacyPolicyModule,
    TnCModule
];