import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { CssBaseline } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import modules from "modules";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { Redirect } from "react-router-dom";
import Authmiddleware from "routes/middleware/Authmiddleware";
import Error404 from "modules/NotFound/components";
// Import scss
import "assets/scss/theme.scss";
import "assets/scss/preloader.scss";
import projectTheme from "assets/theme";

const App = ({isAuthenticated}) => {

  return (
    <ThemeProvider theme={projectTheme}>
      <CssBaseline />
      <Router>
        <Switch>
          {modules
            .filter((e) => e.enabled)
            .map((module) =>
              module.routeProps.map((route) => {
                return <Authmiddleware
                  key={route?.path}
                  path={route?.path}
                  isAuthProtected={route?.isAuthProtected}
                  component={route?.component}
                  layout={route?.layout}
                  isAuthenticated={isAuthenticated}
                  exact={route?.exact}
                />
              })
            )
          }
          <Route path="/not-found" component={Error404} />
          <Redirect to="/home" /> {/** To change to home page once the website has been activated and enabled */}
        </Switch>
      </Router>
    </ThemeProvider>
  )
}


const mapStateToProps = state => {
  return {
    isAuthenticated: state.Auth?.isAuthenticated || false,
  }
}

export default connect(mapStateToProps)(injectIntl(App));