import * as yup from "yup";
import { phoneRegExp, landPhoneRegExp, requestPhoneRegExp } from "./constants";

export const passwordRequired = (msg, min, max, formatValidationMsg) => {
  return stringRequired(msg)
    // .matches(/(^[a-zA-Z0-9])/, formatValidationMsg)
    .min(min)
    .max(max);
};

export const string = (defaultValue) => defaultValue ? yup.string().strict().nullable().default(defaultValue) : yup.string().strict().nullable();
export const stringRequired = (msg) => string().required(msg);
export const stringDefaultRequired = (value, msg) => string(value).required(msg);
export const stringNotRequired = () => string().notRequired();

export const phoneNumberRequired = (msg, phoneValidationMsg) => {
  return stringRequired(msg).matches(phoneRegExp || landPhoneRegExp, phoneValidationMsg);
};
export const requestPhoneNumberRequired = (msg, phoneValidationMsg) => {
  return stringRequired(msg).matches(requestPhoneRegExp, phoneValidationMsg);
};

export const dateRequired = (msg) => yup.date().required(msg).strict().nullable();
export const dateNotRequired = () => yup.date().strict().nullable();
export const date = () => yup.date();

export const emailRequired = (msg, emailValidationMsg) => {
  return stringRequired(msg).matches(
    /(^[a-zA-Z0-9]+[a-zA-Z0-9-_.]+@[a-zA-Z0-9-]+\.[a-zA-Z-.]+[a-zA-Z]{1,}$)/,
    emailValidationMsg
  );
};