import { PURGE } from "redux-persist";
import { events } from "../actions";

const INITIAL_STATE = { error: { status: false, message: null } };

export default (state = INITIAL_STATE, response) => {
  switch (response.type) {
    case events.error.RECEIVED:
      return {
        ...state,
        error: {
          status: true,
          message: response.message,
        },
      };
    case events.error.CANCELED:
      return {
        ...state,
        error: {
          status: false,
          message: null
        },
      };
    case PURGE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
