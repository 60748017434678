import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logoInactive from "assets/images/logos/logo-inactive.png";
import logoActive from "assets/images/logos/logo-active.png";
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import botswanaFlag from "assets/images/flags/botswana_flag.png";
import Menu from "./Menu";
import MobileMenu from "./MobileMenu";
import { makeStyles, createStyles } from "@material-ui/core";
import cn from "classnames";

const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      backgroundColor: "#fff",
      boxShadow: "0 2px 8px 0 rgba(0, 0, 0, .1)",
      height: "92px",
      left: 0,
      position: "fixed",
      right: 0,
      top: 0,
      transition: "background-color .2s ease-in-out, box-shadow .2s ease-in-out",
      width: "100%",
      zIndex: 100
    }
  })
);

const Header = ({ makeBackgroundTransparent, setMakeBackgroundTransparent, activePage, setActivePage }) => {
  const classes = useStyles();
  const intl = useIntl();
  const { formatMessage } = intl;
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenu, setActiveMenu] = useState("");

  const getHeaderOpenMenuCss = () =>{
    if(isMenuOpen){
      try {
        const isMobile = /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || window.matchMedia("(max-width: 767px)").matches;
        if(isMobile) return "menu-mobile-active";
        return "header-menu-open";
      } catch (e) {
        return "header-menu-open";
      }
    }
    return "";
  }

  const changeNavBackground = () => {
    if (["Home"].includes(activePage)) {
      window.scrollY >= 800 ? setMakeBackgroundTransparent(false) : setMakeBackgroundTransparent(true);    
    }   
  }

  useEffect(() => {
    window.addEventListener('scroll', changeNavBackground);
    return () => {
      window.removeEventListener('scroll', changeNavBackground);
    }
  }, []);

  return (
    <React.Fragment> 
      <header className={cn(classes.header, `${makeBackgroundTransparent ? "sb-header-transparent header-transparent": ""} ${ getHeaderOpenMenuCss() }`)} >
        <div className="header-global-line" >
          <div className="container">
            <a href={`mailto:${formatMessage(messages.COMPANY_EMAIL_ADDRESS)}`} className="header-global-line-contacts-email" dir="ltr">
              <i className="icon-s-envelope"></i>
              <span className="header-global-line-contacts-email-text">{formatMessage(messages.COMPANY_EMAIL_ADDRESS)}</span>
            </a>
            <a href={`tel:${formatMessage(messages.COMPANY_PHONE_NUMBER)}`} className="header-global-line-contacts-phone" dir="ltr">
              <img src={botswanaFlag} width="16" height="12" alt="bw flag" />
              <span className="header-global-line-contacts-phone-text">{formatMessage(messages.COMPANY_PHONE_NUMBER)}</span>
            </a>
          </div>
        </div>
        <div className="container header-container">
          <div className="header-row">
            <div className="header-logo">
              <div className="header-logo-svg-wrapper">
                <a href="/" onClick={()=>setActivePage("Home")}><img src={makeBackgroundTransparent ? logoInactive : logoActive} alt="logo" className="header-logo-svg" title="logo"/></a>
              </div>
            </div>
            <Menu setIsMenuOpen={setIsMenuOpen}/>
            <a href={`tel:${formatMessage(messages.COMPANY_PHONE_NUMBER)}`} className="header-phone" aria-label="Call Us">
              <i className="icon-s-phone-bordered"></i>
              <img src={botswanaFlag} width="16" height="12" alt="bw flag" />
              <span className="header-phone-text">{formatMessage(messages.COMPANY_PHONE_NUMBER)}</span>
            </a>
            {/*Disabled for now  */}
            {/* <div className="header-red-buttons">
              <div className="header-red-button">
                <a href="/contact-us" className="header-red-button-label" >
                  {formatMessage(messages.CONTACT_US)}
                </a>
              </div>
            </div> */}
            <button className="header-burger-btn" aria-label="Open mobile menu" onClick={()=> setIsMenuOpen(true)}></button>
          </div>
        </div>        
        
        <div className="header-menu-mobile">
          <button className="header-menu-mobile-close-btn" aria-label="Close mobile menu" onClick={()=> {
            setIsMenuOpen(false);
            setActiveMenu("")
          }}>
            <i className="icon-s-close"></i>
          </button>
          <MobileMenu setIsMenuOpen={setIsMenuOpen} activeMenu={activeMenu} setActiveMenu={setActiveMenu}/>
        </div>
        <div className="header-menu-mobile-shadow"></div>
      </header>
    </React.Fragment>
  );
};

export default Header;
