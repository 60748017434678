import React from "react";

import Breadcrumbs from "components/Common/Breadcrumb";
import { makeStyles, createStyles } from "@material-ui/core";
import SocialShare from "components/Common/socialShareComponent";


const useStyles = makeStyles((theme) =>
  createStyles({   
    header: {
      color: "#ED2600",
      fontFamily: "Sumana, serif",
      fontSize: "36px",
      fontWeight: 400,
      lineHeight: 1.28,
      margin: "50px 0 32px",
      position: "relative",
      zIndex: 1,
    },
    title: {
      color: "#000",
    },
    breadcrumbHolder:{
      marginTop: "120px"
    }
  })
);

const PrivacyPolicyComponent = () => {
  const classes = useStyles();
  document.title = "Privacy Policy | Spectre Creation"
  return (
    <React.Fragment>
      <main id="maincontent" className="page-main lowercase-headers">
        <div className="simple-page" >
          <div className="container">
            <div className={classes.breadcrumbHolder}>
              <Breadcrumbs title="Home" titleLink="/" breadcrumbItem="Privacy Policy" breadcrumbItemLink="/about/privacy-policy"/>
            </div>            

            <h1 className={classes.title}>Privacy Policy</h1>
            <div className="sp-content">
              <div className="spectrecreation_area_wysiwyg spectrecreation_area_content ">
                <div className="container">
                  <section className="a-wysiwyg ">
                    <p>This privacy statement explains what personal data Spectre Creation collects from you and how we use that data.</p>
                    <p>Last updated on 12/19/2023.</p>

                    <h2 className={classes.header}>Who We Are</h2>
                    <p>Welcome to Spectre Creation! We are a Botswana-based company specializing in custom software development and IT consulting, with over 5 years of 
                      experience in ICT services. As you explore our website, you're interacting with the digital face of our company. Our commitment is to provide you 
                      with a fantastic browsing experience, share interesting materials, address any questions promptly, and ensure the smooth operation of the website 
                      to keep you undisturbed. We greatly appreciate your assistance in achieving this by sharing your personal data with us. We acknowledge the sensitivity 
                      of personal information and take your privacy seriously. To ensure transparency, we have crafted this privacy policy, covering every detail related 
                      to how we handle your personal data.</p>

                    <h2 className={classes.header}>What Personal Data We Collect</h2>
                    <p>Spectre Creation gathers information to offer direct responses regarding our services. Some of this information is supplied by you directly, 
                      such as when you complete the Contact Us form to reach out for support or provide feedback on our articles. Additionally, we obtain certain 
                      data through technologies like cookies and receive error reports or usage information from software operating on your device.</p>
                    <p>We also acquire information from third-party sources. The data obtained from third parties is safeguarded in accordance with the practices outlined 
                      in this statement, along with any additional constraints stipulated by the data source. Although the third-party sources may change over time, they 
                      presently encompass:</p>
                    <ul>
                      <li>Service providers that help us determine a location based on your IP address in order to customize certain products to your location.</li>
                      <li>Partners with which we offer co-branded services or engage in joint marketing activities.</li>
                      <li>Publicly-available sources such as open government databases or other data in the public domain.</li>
                    </ul>

                    <p>You have the option to decide whether to provide the data requested. However, if you opt not to furnish data essential for a service, feature, or 
                      feedback, it may impact your ability to use the service or feature or receive feedback.</p>
                    <p>The data we collect can include the following:</p>
                    <p><strong>Name and contact data</strong>. We collect your first and last name, work email address, phone number, company name and other similar contact data.</p>
                    <p><strong>Demographic data</strong>. We collect data about you such as your country and preferred language.</p>
                    <p><strong>Website data. </strong>We collect data about how you interact within Spectre Creation website. For example, we collect:</p>
                    <ul>
                      <li style={{textAlign:"justify"}}>Details of visitor behavior patterns, i.e. data about the website sections you visit and how much time you spend there.</li>
                      <li style={{textAlign:"justify"}}>Configuration data. We collect data about the network you use to connect to our website. It includes your IP address.</li>
                      <li style={{textAlign:"justify"}}>Error reports and performance data. We collect data about problems you experience with our services. This data helps us to diagnose problems and to provide solutions. 
                      Depending on your browsing environment and settings, error reports can include such data as the type or the severity of the problem, details of the software or 
                      the hardware related to the error, the contents of the files you were using when the error occurred, as well as the data about other software on your device.</li>
                      <li style={{textAlign:"justify"}}>Troubleshooting and Help Data. In case Spectre Creation is engaged in troubleshooting and help, we collect data about you and your hardware, 
                      software, and other details related to the incident. Such data includes the contact data, the content of your chats and other communications with Spectre Creation, the data 
                      about the condition of the machine and the application when the fault occurred and during diagnostics, and the system and registry data about software installations 
                      and hardware configurations.</li>
                    </ul>

                    <p><strong>Please, be aware you may communicate personal data we do NOT require, including your payment data, in the description section of a CONTACT-US form. We will not use this information in any way, besides storing it at our backup server. Please, do not share with us any personal data not requested from you directly.</strong></p>

                    <p>Our website is not intended for children and for private individuals, and we do not knowingly collect data relating to them. Parents can revoke the consent choices previously made, and review, edit or request the deletion of their children's personal data.</p>

                    <p>We may also collect personal data from third parties who have your consent to pass your details to us, or from publicly available sources.</p>

                    <h2 className={classes.header}>Why We Collect Personal Data</h2>

                    <p>Spectre Creation collects the data for the following purposes: sending communications, including promotional communications; advertising; analyzing website visitor 
                      statistics; technical website troubleshooting; improving website user experience.</p>

                    <p>In carrying out these purposes, we intend to tell you about issues you’ve asked us to tell you about; to contact you if we need to obtain or provide additional 
                      information; to make sure our records are right and to check every now and then that you’re happy and satisfied. We don't rent or trade email lists with other 
                      organizations and businesses.</p>

                    <p>However, to enhance privacy, we have built in technological and procedural safeguards designed to prevent data leaks. For example, we store data we collect 
                      from you indirectly or directly, such as your name, email address or phone number, company name, in a secure database.</p>

                    <p>Additionally, you can sign up for email subscriptions and choose whether you wish to receive promotional communications from Spectre Creation by email, 
                      SMS, and telephone.</p>

                    <h2 className={classes.header}>Why We Share Personal Data</h2>

                    <p>We will share your information with our Development Center, which will handle your request. Our Development Center must abide by our data privacy and 
                      security requirements and is not allowed to use personal data it receives from us for any purposes other than specified above. 
                      To ensure an adequate level of protection of personal data you share, all obligations of the Development Center are established in an agreement 
                      between Spectre Creation and the Development Center.</p>

                    <p>We will access, transfer, disclose, and preserve personal data, when we have a good faith belief that doing so is necessary to:</p>

                    <ol>
                      <li>Comply with the applicable law or respond to a valid legal process, including in response to a request from law enforcement or other government agencies.</li>
                      <li>Protect our customers, for example, to prevent spam or attempts to defraud users of our services, or to help prevent the loss of life or serious injury of anyone.</li>
                      <li>Protect the rights of Spectre Creation, including enforcing the terms governing the use of the services.</li>
                    </ol>

                    <p>If third-party agents process personal data on our behalf in a manner inconsistent with the principles contained herein, we remain liable unless we 
                      prove we are not responsible for the event giving rise to the damage.</p>

                    <p>Please, note that some of our website pages include links to products of third parties whose privacy practices may differ from those of Spectre Creation. 
                      If you provide personal data to any of those parties, your data is governed by their privacy statements.</p>

                    <h2 className={classes.header}>What Rights You Have</h2>

                    <p>You are entitled to view, amend, or delete the personal information that we hold:</p>

                    <ul>
                      <li>You may ask what personal data about you we hold and who the recipients of your personal data are, at any time.</li>
                      <li>You may ask us to update and correct any out-of-date or incorrect/incomplete personal data that we hold about you free of charge.</li>
                      <li>You may opt out of any marketing communications that we may send you. This choice does not apply to mandatory service communications.</li>
                      <li>You may recall your consent for the processing of your information at any time.</li>
                      <li>You may request erasing or updating your personal information <a href="/about/privacy-policy/personal-data-management">here</a>.</li>
                    </ul>

                    <p>Email your request to our data protection office at <a href="mailto:privacy@spectrecreation.co.bw">privacy@spectrecreation.co.bw</a>. 
                    We will respond to requests to access or delete your personal data within 30 days.</p>

                    <h2 className={classes.header}>Cookies & Similar Technologies</h2>

                    <div>&nbsp;</div>

                    <p>Spectre Creation uses cookies (small text files placed on your device) and similar technologies to provide our online services and to help 
                      collect data. The text in a cookie often consists of a string of numbers and letters that uniquely identifies your computer, 
                      but it can contain other information as well.</p>

                    <p>We use cookies and similar technologies for several purposes, including:</p>

                    <ul>
                      <li><strong>Security</strong>. We use cookies to detect fraud and abuse of our website and services.</li>
                      <li><strong>Storing information you provide on a website</strong>. When you provide information, we store the data in a cookie to 
                      remember the information you have added.</li>
                    </ul>

                    <p>The main reason we typically set cookies is to gather website statistics.</p>

                    <h3><strong>How to Control Cookies</strong></h3>

                    <p>You may usually switch off cookies in your browser.</p>

                    <h3><strong>Other Similar Technologies</strong></h3>

                    <p>In addition to standard cookies, we can use other similar technologies to store and read data files on your computer. 
                      This is typically done to improve speed and performance by storing certain files locally. Similar to standard cookies, these technologies 
                      can also be used to store a unique identifier for your computer, which can then be used to track behavior. 
                      These technologies include Local Shared Objects (or "Flash cookies").</p>

                    <h2 className={classes.header}>Notice to End Users</h2>

                    <div>&nbsp;</div>

                    <p>Spectre Creation is intended to deliver its services to organizations. Your use of Spectre Creation’s website may be subject to your 
                      organization's policies, if any. If your organization is administering your use of Spectre Creation’s website, 
                      please direct your privacy inquiries to your administrator. Spectre Creation is not responsible for the privacy or security practices of our customers, 
                      which may differ from those set forth in this privacy statement.</p>

                    <p>If you use an email address provided by an organization you are affiliated with, such as an employer or school, the owner of the domain (e.g., your employer) 
                      associated with your email address may access and process your data, including the contents of your communications and files.</p>

                    <h2 className={classes.header}>How We Secure Your Data</h2>

                    <p>We use a variety of security technologies and procedures to help protect your personal data from unauthorized access, use or disclosure. 
                      For example, we store the personal data you provide on computer systems that have limited access and are in controlled facilities.</p>

                    <h2 className={classes.header}>For How Long the Data Will Be Stored</h2>

                    <p>Spectre Creation retains personal data for as long as necessary to provide the services and the feedback you have requested, or for other essential purposes, 
                      such as complying with our legal obligations, enforcing our agreements. Because these needs can vary for different data types, actual retention periods may vary. 
                      The criteria used to determine the retention periods include:</p>

                    <ul>
                      <li><em>Is the personal data of a sensitive type?</em> If so, a shortened retention time would generally be appropriate, which amounts to up to five years.</li>
                      <li><em>Is Spectre Creation subject to a legal, contractual, or similar obligation to retain the data?</em> Examples can include mandatory data retention laws 
                      in the applicable jurisdiction, government orders to preserve data relevant to an investigation, or data that must be retained for the purposes of litigation.</li>
                    </ul>

                    <h2 className={classes.header}>How We Change this Privacy Policy</h2>

                    <p>We will update this privacy statement when necessary to amend its terms or reflect customer feedback. When we post changes to this statement, 
                      we will revise the "last updated" date at the top of the statement. If there are material changes to the statement, we will notify you either by 
                      prominently posting a notice of such changes before they take effect or by directly sending you a notification.</p>

                    <h2 className={classes.header}>How to Contact Us</h2>

                    <p>If you have a privacy concern, complaint, or question to Data Protection Officer, please email us at <a href="mailto:privacy@spectrecreation.co.bw">privacy@spectrecreation.co.bw</a>. We will respond within 30 days.</p>
                  </section>
                </div>
              </div>
              <SocialShare />
            </div>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
};

export default PrivacyPolicyComponent;
