import * as actionTypes from "./actionTypes";

const cancelErrorAction = () => ({ type: actionTypes.ERROR_CANCELED });
const completeLoadingAction = () => ({ type: actionTypes.LOADING_COMPLETED });
const requestLoadingAction = (apiType, actionName) => ({
  type: actionTypes.LOADING_RECEIVED,
  apiType,
  actionName,
});

export const actions = {    
  error: {
    cancelErrorAction,
  },
  loading: {
    requestLoadingAction,
    completeLoadingAction,
  }
};

export const events = {  
  error: {
    RECEIVED: actionTypes.ERROR_RECEIVED,
    CANCELED: actionTypes.ERROR_CANCELED,
  },
  loading: {
    RECEIVED: actionTypes.LOADING_RECEIVED,
    COMPLETED: actionTypes.LOADING_COMPLETED,
  }
};