export const LOCAL_STORAGE_CUSTOMER_DATA_KEY = "LOCAL_STORAGE_CUSTOMER_DATA_KEY";

export const GENERIC_ERROR_CODE = "GENERIC_ERROR";
export const Required = "Required";
export const APIErrorsEnum = {
  API_ERROR: "API_ERROR",
  NETWORK_ERROR: "NETWORK_ERROR",
  INTERNAL_ERROR_CHANGE_PASSWORD: "INTERNAL_ERROR_CHANGE_PASSWORD",
  ACCOUNT_STATUS_INACTIVE: "ACCOUNT_STATUS_INACTIVE",
  INTERNAL_ERROR: "INTERNAL_ERROR",
  PASSWORD_EXPIRED: "PASSWORD_EXPIRED",
  PASSWORD_REUSE: "PASSWORD_REUSE",
};
// Please add the reducers that needs to be persisted in the browser cache so that it can be added to store whenever page refresh or open the app in browser
export const REQUIRED_STORE_REDUCERS = [
  "Layout",  
  "Home", 
  "Global",  
]; //To-do revise the reducer names
export const APP_CACHE_KEY = "SpectreCreation";

export const DELAY_TO_SHOW_LOADER = 2 * 1000;
export const phoneRegExp= /^[0-9]{8}$/;
export const phoneNoLength= 8;
export const landPhoneRegExp= /^[0-9]{7}$/;
export const requestPhoneRegExp= /^[0-9]{7,8}$/;
export const firebaseConfigKeys = {
  APP_CONFIG_PROD: "APP_CONFIG_PROD",
  APP_CONFIG_DEV: "APP_CONFIG_DEV",
  APP_CONFIG_TESTING: "APP_CONFIG_TESTING",
};