import PrivacyPolicyPage from "./components";
import VerticalLayout from "components/VerticalLayout/";
export const ROUTE_PRIVACY = "/about/privacy-policy";

/* TODO: add redirect here instead of being coded in the Login Container */
export default { 
  routeProps: [
    {
      path: ROUTE_PRIVACY,
      exact: true,
      component: PrivacyPolicyPage,
      isAuthProtected: false,
      authRequired: false,
      layout: VerticalLayout
    },
  ],
  name: "PrivacyPolicyPage",
  authscreen: false,
  enabled: true,
  default: true,
};
