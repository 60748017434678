import { PURGE } from "redux-persist";
import { events as actions } from "actions";
import { events } from "actions/global";

const getInitialState = () => {
  return {
    language: "en",
    country: "",
    preferredLanguage: "en",
    activePage: "Home",
  };
};

export default (state = getInitialState(), response = {}) => {
  switch (response.type) {
    case actions.auth.login.RECEIVED:
      return {
        ...state,
        preferredLanguage: response?.payload?.preferredLanguage || "en",
        country: response?.payload?.countryName || "Botswana",
        language: response?.payload?.preferredLanguage || "en",
      };
    case events.global.SET_LANGUAGE:
      return {
        ...state,
        language: response?.language,
      };
    case events.global.SET_ACTIVE_PAGE:
      return {
        ...state,
        activePage: response?.pageName,
      };
    case PURGE:
      return {
        ...getInitialState(),
      };
    default:
      return state;
  }
};