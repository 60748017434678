import React from 'react';
import messages from "i18n/messages";
import { useIntl } from "react-intl";


const solutionList = [
    {
        link: "/services/application",
        name: "Enterprise applications",
    },
    {

        link: "services/software-development/erp",
        name: "ERP"
    },
    {

        link: "/services/crm",
        name: "CRM"
    },
    {

        link: "/services/software-development/hr",
        name: "HR software"
    },
    {

        link: "/elearning",
        name: "eLearning software"
    },
    {

        link: "/ecommerce",
        name: "eCommerce"
    },
    {

        link: "/services/mobile-app-development",
        name: "Mobile Apps"
    },
    {

        link: "/asset-management",
        name: "Asset Management"
    },
    {

        link: "/fleet-management",
        name: "Fleet management software"
    },
    {

        link: "/services/analytics/consulting",
        name: "Data Analytics"
    },
    {

        link: "/web-portal-development",
        name: "Web Portals"
    },
    {

        link: "/services/itsm",
        name: "ITSM"
    }
]


const Solutions = () => {
    const intl = useIntl();
    const { formatMessage } = intl;

    const renderSolutionList = () =>{
        return (solutionList || [])?.map((solution)=>{
            return <a key={solution?.link} className="a-solution-types__link a-card step-up">{/* href={solution?.link} */}
                {solution?.name}
                {/* <i className="icon-font icon-s-arrow"></i> */}
            </a>
        });
    }

    return (
        <>
            <div className="spectrecreation_area_solution-types spectrecreation_area_content ">
                <section className="a-solution-types waypoint waypoint-reveal" >
                    <div className="container">
                        <h2 className="a-section__title h2 step-up 1 ">
                            <span id="Solutions" className="not-hover-link" name="Solutions">Our Spectrum of Solutions</span>
                        </h2>
                        <p className="a-section__description step-up">
                            Empowering All B2B, B2C Interactions, and Internal Operations Through IT
                        </p>
                        <div className="a-solution-types__list step-up">
                            {renderSolutionList()}
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Solutions;