
import { persister } from "../store";

export default function setupAxios(axios) {
  axios.interceptors.response.use((response) =>{
    return response;
  }, (error) =>{
    if (!error.response) {
      if(error.message === "Network Error"){
        return Promise.reject("We failed to reach the server for your request processing. Please try again later");
      }
    }

    if(error.response.status === 500) {
      return Promise.reject("We have experienced an unexpected error on our end. Please try again later while we resolve the issue. Thank you for your patience");
    }

    if(error.response.status === 404) {
      return Promise.reject("Service is temporarily unavailable, please try again later or contact us on info@spectrecreation.co.bw for further assistance");
    }
    if(error.response.status === 403) {
      persister.purge();
      return Promise.reject("Please login again");
    }

    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }

    return Promise.reject(error.message);
  });
}
