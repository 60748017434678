import React, { useState } from 'react';
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";

const servicesTabs = [
    {
        index: 0,
        name: "SOFTWARE_DEVELOPMENT",
        description: `Transform your ideas into reality with Spectre Creation, a trailblazing software development company boasting seven years of unparalleled 
        business excellence. We specialize in crafting reliable, scalable, and secure software solutions tailored for any operating system, browser, and device.
        Empower your business with software solutions that go beyond expectations. Spectre Creation combines a legacy of excellence with a commitment to innovation. 
        Partner with us to transform your vision into a digital reality.`,
        link: "/services/software-development",
        links:[
            {
                link: "/services/software-consulting",
                name:"Software consulting",
            },
            {
                link: "/services/software-development/custom",
                name:"Custom software development"
            },
            {
                link: "/services/software-development-outsourcing",
                name:"Software development outsourcing"
            },
            {
                link: "/services/software-product-development",
                name:"Software product development"
            },
            {
                link: "/services/it-staff-augmentation",
                name:"Team augmentation"
            },
            {
                link: "/services/cloud-application-development",
                name:"Cloud application development"
            },
            {
                link: "/services/application/modernization",
                name:"Legacy software modernization"
            }
        ]
    },
    {
        index: 1,
        name: "TESTING_QA_SHORT",
        description: `At Spectre Creation, we redefine quality assurance and testing with a full-range suite of outsourcing services. Whether you're looking to 
        establish a robust QA framework or enhance your existing one, we've got you covered. Our experts specialize in Test Center of Excellence (TCoE) 
        setup and evolution, ensuring your software meets the highest standards of excellence. Elevate your software quality with Spectre Creation as your 
        QA and testing partner. We go beyond conventional testing, ensuring your software not only meets but exceeds expectations.`,
        link: "/software-testing",
        links: [
            {
                link: "/software-testing/services/qa-outsourcing",
                name: "QA outsourcing"
            },
            {
                link: "/software-testing/services/qa-consulting",
                name: "QA consulting"
            },
            {
                link: "/services/security/penetration-testing",
                name: "Security testing"
            },
            {
                link: "/software-testing/services/functional",
                name: "Functional testing"
            },
            {
                link: "/software-testing/services/usability",
                name: "Usability testing"
            },
            {
                link: "/software-testing/services/performance",
                name: "Performance testing"
            },
            {
                link: "/software-testing/services/automated",
                name: "Test automation"
            }
        ]
    },
    {
        index: 2,
        name: "APPLICATION_SERVICES",
        description: `At Spectre Creation, we specialize in empowering mid-sized and large firms to not just run, but thrive in the digital landscape. 
        Our team of experts is dedicated to building, testing, protecting, managing, migrating, and optimizing digital solutions, ensuring they are not just functional but 
        always at their peak performance. Unlock the full potential of your digital solutions with Spectre Creation. Let's build, test, protect, manage, migrate, 
        and optimize your digital future together.`,
        link: "/services/application",
        links: [
            {
                link: "/services/application/management",
                name: "Application management"
            },
            {
                link: "/services/application/modernization",
                name: "Application modernization"
            },
            {
                link: "/services/application/integration",
                name: "Application integration"
            },
            {
                link: "",
                name: "Application security services"                                                                    
            },
            {
                link: "/services/application/development",
                name: "Application development"
            },
            {
                link: "/services/application/testing",
                name: "Application testing"
            },
            {
                link: "/services/application-maintenance-and-support",
                name: "Application maintenance and support"
            }
        ]
    },
    {
        index: 3,
        name: "UI_UX",
        description: `At Spectre Creation, we redefine user experiences by seamlessly blending the latest UI/UX trends with our clients' unique goals and aspirations. 
        Our expertise spans across all types of websites, SaaS platforms, and web/mobile apps, ensuring that your digital presence is not just functional but an 
        immersive journey for your users. A seamless and engaging user experience is at the heart of every successful digital venture. With Spectre Creation's UI/UX design, 
        your digital presence becomes a powerful tool that not only meets but exceeds user expectations. Collaborate with Spectre Creation to transform your digital 
        experiences. From websites to SaaS and apps, our UI/UX designs drive business success.`,
        link: "/services/web-design",
        links: [
            {
                link: "/services/ui-design",
                name: "User Interface (UI) Design"
            },
            {
                link: "/services/web-design/responsive",
                name: "Responsive Web & Apps Design"
            },
            {
                link: "/services/saas-ui-design",
                name: "Software-as-as-Service (SaaS) UI Design"
            },
            {
                link: "/ecommerce/services/development",
                name: "Ecommerce Website Design"
            },
            {
                link: "/services/ux-design",
                name: "User Experience (UX) Design"
            },
            {
                link: "/services/website-redesign",
                name: "Website Redesign"
            }
        ]
    },
    {
        index: 4,
        name: "IT_CONSULTING",
        description: `At Spectre Creation, our team of experts is dedicated to shaping and implementing robust IT strategies tailored to catapult your business 
        into the digital era. We don't just advise; we collaborate to drive seamless digital transformations, integrate systems, and elevate your digital customer 
        experience. In a rapidly evolving digital landscape, a robust IT strategy is the cornerstone of sustainable business growth. Spectre Creation's expertise 
        ensures your strategy is not just effective but a catalyst for success. Partner with Spectre Creation to unlock the full potential of your digital initiatives. 
        From strategy development to implementation, we're your trusted ally in the digital realm.`,
        link: "/services/it-consulting",
        links: [
            {
                link: "/services/it-consulting/digital-transformation",
                name: "Digital Transformation Consulting"
            },
            {
                link: "/services/it-consulting/project-management",
                name: "Project Management Consulting"
            },
            {
                link: "/services/it-consulting/crisis-management",
                name: "Digital Crisis Management Consulting"
            },
            {
                link: "/services/itsm",
                name: "IT Service Management Consulting"
            },
            ,
            {
                name: "Solution consulting"
            },,
            {
                name: "Platform consulting"
            },,
            {
                name: "Enterprise IT consulting"
            }
        ]
    },
    {
        index: 5,
        name: "DATA_ANALYTICS",
        description: `At Spectre Creation, we are your partners in unlocking the power of data to drive fact-based decision-making. Our suite of services is meticulously 
        crafted to transform historical and real-time data, whether traditional or big, into actionable insights that propel your business forward. 
        In today's dynamic business landscape, data is not just information; it's a strategic asset. Spectre Creation's analytics services ensure that your data 
        becomes a catalyst for growth and success. Partner with Spectre Creation to convert your data into actionable insights. From data preparation to advanced 
        analytics, we're committed to elevating your decision-making capabilities.`,
        link: "/services/analytics",
        links: [
            {
                link: "/services/business-intelligence",
                name: "Business Intelligence"
            },
            {
                link: "/services/big-data",
                name: "Big Data"
            },
            {
                link: "/services/business-intelligence/data-warehouse",
                name: "Data Warehousing"
            },
            {
                link: "/services/data-science",
                name: "Data Science"
            },
            {
                link: "/services/data-management",
                name: "Data Management"
            },
            {
                link: "/services/data-science/machine-learning",
                name: "Machine and Deep Learning"
            },
            {
                link: "/services/analytics/data-analytics-as-a-service",
                name: "Data Analytics as a Service"
            },
        ]
    },
    {
        index: 6,
        name: "HELP_DESK_SERVICES",
        description: `At Spectre Creation, we redefine support by offering comprehensive help desk services designed to enhance your IT environment and optimize your 
        software products. Our expert team takes on a spectrum of challenges, from addressing application functionality queries to executing code-level fixes and 
        enhancements. The result improve software adoption, seamless functionality, and heightened end-user satisfaction. In the digital landscape, seamless support 
        is non-negotiable. Spectre Creation's help desk services bridge the gap between challenges and solutions, ensuring uninterrupted operations and user satisfaction. 
        Partner with Spectre Creation for help desk services that go beyond the ordinary. We are committed to elevating your IT environment, one support ticket at a time.`,
        link: "/it-support",
        links: [
            {
                link: "/it-support/help-desk/outsourcing",
                name: "Help desk outsourcing"
            },
            {                                                                
                link: "/it-support/help-desk",
                name: "IT help desk services"
            },
            {
                link: "/it-support/help-desk/msp",
                name: "Outsourced help desk for MSP"
            },
            {
                link: "/it-support/noc",
                name: "NOC outsourcing"
            }
        ]
    },
    {
        index: 7,
        name: "INFRASTRUCTURE_SERVICES",
        description: `With over six years of expertise, Spectre Creation proudly provides a comprehensive suite of infrastructure services. 
        Our commitment? Ensuring your data security is never compromised. When you partner with us, you gain more than services; you gain peace of mind. 
        In today's digital world, data security is paramount. Spectre Creation's infrastructure services are tailored to ensure not only functionality but 
        also the utmost security for your valuable information. Partner with Spectre Creation for infrastructure services that reflect our commitment to excellence. 
        We bring confidence to your operations, ensuring a secure and reliable foundation for your digital journey.`,
        link: "/services/it-infrastructure",
        links: [
            {
                link: "/services/managed-it/support",
                name: "Managed IT support"
            },
            {
                link: "/services/cloud-consulting",
                name: "Cloud consulting and management"
            },
            {
                link: "/services/devops-consulting",
                name: "DevOps consulting and implementation"
            },
            {
                link: "/services/it-infrastructure/management/networking",
                name: "Networking management services"
            }
        ]
    },
    {
        index: 8,
        name: "CYBERSECURITY_SERVICES",
        description: `At Spectre Creation, we bring a wealth of experience to the forefront of information security. Through the implementation of robust information 
        security management practices, we stand as your trusted partner in achieving unparalleled protection for your company's applications and networks. In an era 
        where data is a cornerstone of business, information security is non-negotiable. Spectre Creation combines experience with innovation to deliver security 
        solutions that transcend expectations. Partner with Spectre Creation to fortify your applications and networks. Our commitment to excellence ensures that your 
        digital assets are shielded with the highest standards of information security.`,
        link: "/services/security",
        links: [
            {
                link: "/security/assessment",
                name: "Security assessment"
            },
            {
                link: "/services/managed/security",
                name: "Managed security services"
            },
            {
                link: "/services/security/testing",
                name: "Security testing"
            },
            {
                link: "/services/security/consulting",
                name: "Information security consulting"
            },
            {
                link: "/software-development/code-review",
                name: "Security code review"
            },
            {
                link: "/security/compliance",
                name: "Compliance assessment"
            },
            {
                link: "/services/security/penetration-testing",
                name: "Penetration testing"
            },
        ]
    },
];

const useStyles = makeStyles((theme) => ({
  partnerImageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    textRendering: "optimizeSpeed", 
    fontFamily: "Open Sans,sans-serif", 
    fontSize: "16px", 
    color: "#3b3c3d",
    boxSizing: "border-box", 
    height: "73px",
    width: "10215%", 
    position: "relative", 
    transitionDuration: "0s",
  },
  partnerImageHolder: {
    float: "left", 
    position: "relative", 
    width: "114.857px", 
    marginRight: "10px", 
    marginLeft: "10px", 
    marginBottom: "10px",
  },
  serviceTabContainerData: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "624.281px"
  },
  serviceTabContentContainer: {
    position: "relative",
    width: "92%",
    transitionDuration: "0s",
  },
  serviceTabContent: {
    float: "left",
    listStyle: "none",
    position: "relative",
  },
}));

const Services = () => {

    const classes = useStyles();
    const intl = useIntl();
    const { formatMessage } = intl;
    const [activeServiceTab, setActiveServiceTab] = useState(0);

    const renderServicesTabs = () =>{
        return <ul className="a-services__tabs-container-ul">
            {servicesTabs.map((tab)=>{
                return <li key={tab?.index} onClick={()=>setActiveServiceTab(tab?.index)}>
                    <a className={`a-services__tabs-label ${ activeServiceTab === tab?.index ? "active" : ""}`}>
                        <p>{formatMessage(messages[tab?.name])}</p>
                    </a>
                </li>
            })}
        </ul>
    }

    const renderServiceContent = () =>{
        let activeTabDetails = servicesTabs?.filter(tab=> tab.index === activeServiceTab);
        activeTabDetails = activeTabDetails.length > 0 ? activeTabDetails[0]: {}
        return <div className={cn("a-services__content active", classes.serviceTabContent)}>
            <div className="d-flex flex-column h-100">
                <h3 className="a-services__name">{formatMessage(messages[activeTabDetails?.name])}</h3>
                <div className="a-services__description">{activeTabDetails?.description}</div>
                <div className="a-services__bullets d-flex flex-row flex-wrap justify-content-between">
                    {(activeTabDetails?.links || [])?.map((link)=>{
                        return <div className="a-services__bullet" key={link?.link}>
                            {/* <a href={link?.link}> */}
                                {link?.name}
                            {/* </a> */}
                        </div>
                    })}
                </div>
                {/* Disable for now */}
                {/* <div className="a-services__link">
                    <a id="" className="btn btn-link-arrow  btn-link-arrow--right" href={activeTabDetails?.link}>
                        {formatMessage(messages.EXPLORE_MORE)}
                    </a>
                </div> */}
            </div>
        </div>
    }

    return (
        <>
            <div className="spectrecreation_area_services spectrecreation_area_content ">
                <section className="a-services waypoint waypoint-reveal" >
                    <div className="container">
                        <h2 className="a-section__title h2 step-up 1 "><span id="Services" className="not-hover-link" name="Services">Explore Our Offering</span></h2>
                        <div className="d-flex a-services__tabs step-in">
                            <div className="a-services__tabs-container services-pager step-in-left col-md-4 order-md-0">
                                {renderServicesTabs()}
                            </div>
                            <div className="a-services__content-block step-in-right col-md-7 p-0">
                                <div className="bx-wrapper" style={{maxWidth: "855px"}}>
                                    <div className={cn("bx-viewport", classes.serviceTabContainerData)}>
                                        <div className={cn("a-services__content-container", classes.serviceTabContentContainer)}>
                                            {renderServiceContent()}
                                        </div>
                                    </div>
                                    <div className="bx-controls"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}

export default Services;