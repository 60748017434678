import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import logo from "assets/images/logo.png";
import messages from "locales/messages";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { actions } from "actions";
import { actions as globalActions } from "actions/global";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { emailRequired } from "utils/validationUtils";
import InputTextField from "components/Common/InputTextField";
import { connect } from "react-redux";

const PagesComingSoon = ({ errorCode, loading, lockStartTime, subscriptionStatus, subscribe, closeError, clearLoading }) => {

  const intl = useIntl();
  const { formatMessage } = intl;
  const [isLive, setIsLive] = useState(false)
  const history = useHistory();
  let forminkActions = null;

  const subscriptionSchema = Yup.object({
    emailAddress: emailRequired(formatMessage(messages.REQUIRED), 
    formatMessage(messages.EMAIL_MUST_BE_VALID)),
  });

  useEffect(() => {
    clearLoading();
  }, []);

  const resetErrors = () => {
    closeError();
  };

  const submitHandler = async (values) => {
    subscribe(values?.emailAddress);
  };


  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    setIsLive(completed)
    if (completed) {
      history.push({
        pathname: "/",
        search: window.location.search,
        state: null
      });
    } else {
      return (
        <>
          <div className="counter-number mt-5">
            <div className="coming-box">
              <div className="count-title">Days</div>
              <div className="count-num">{days}</div>
            </div>{" "}
            <div className="coming-box">
              <div className="count-title">Hours</div>
              <div className="count-num">{hours}</div>
            </div>{" "}
            <div className="coming-box">
              <div className="count-title">Minutes</div>
              <div className="count-num">{minutes}</div>
            </div>{" "}
            <div className="coming-box">
              <div className="count-title">Seconds</div>
              <div className="count-num">{seconds}</div>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="coming-content min-vh-100 py-4 px-3 py-sm-5">
        <div className="bg-overlay bg-primary"></div>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="text-center py-4 py-sm-5">
                <div className="mb-5">
                  <a href="/">
                    <img src={logo} alt="logo" height="100" className="me-1" />
                  </a>
                </div>
                {!isLive && <p className="text-white mt-5 font-size-20">
                  {formatMessage(messages.LAUNCHING_SOON_TEXT)}
                </p>}

                <Countdown date="2024/02/10" renderer={renderer} />
                {/**Disable subscribe form for now */}
                {/* {!isLive && 
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    emailAddress: ""
                  }}
                  onSubmit={(values) => submitHandler(values)}
                  validationSchema={subscriptionSchema}>
                    {(formikProps) => {
                      forminkActions = formikProps;
                      return (
                        <Form>
                          <div className="app-search mt-5 mx-auto">
                            <div className="position-relative">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your email address"
                                name="emailAddress"
                                onChange={(value)=>{
                                  formikProps.setFieldValue("emailAddress", value.target.value);
                                  formikProps.setFieldTouched("emailAddress");
                                }}
                              />
                              <button className="btn btn-primary" type="button" disabled={loading || !formikProps.values.emailAddress || formikProps.errors.emailAddress}>
                                <i className="bx bx-paper-plane align-middle"></i>
                              </button>
                            </div>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    errorCode: state.Global.errorCode || null,
    loading: state.Loading.loading || false,
    lockStartTime: "",//state.Global.liveness,
    subscriptionStatus: ""//state.Auth.subscription.status,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    subscribe: (emailAddress) => {
      dispatch(
        globalActions.global.activateLivenessSubscription(emailAddress)
      );
      dispatch(actions.loading.requestLoadingAction());
    },
    closeError: () => {
      dispatch(actions.error.cancelErrorAction());
    },
    clearLoading: () => {
      dispatch(actions.loading.completeLoadingAction());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PagesComingSoon);
