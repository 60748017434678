import React, { useState, useEffect } from 'react';
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import cn from "classnames";
import { makeStyles } from "@material-ui/core";

import * as Yup from "yup";
import { useFormik } from "formik";
import { stringRequired, requestPhoneNumberRequired, stringNotRequired } from "utils/validationUtils";
import { TextField } from "@mui/material";
import { Form } from "reactstrap";


const useStyles = makeStyles((theme) => ({
  partnerImageContainer: {
    display: "flex",
    justifyContent: "flex-start",
    textAlign: "center",
    textRendering: "optimizeSpeed", 
    fontFamily: "Open Sans,sans-serif", 
    fontSize: "16px", 
    color: "#3b3c3d",
    boxSizing: "border-box", 
    height: "73px",
    width: "10215%", 
    position: "relative", 
    transitionDuration: "0s",
  },
  partnerImageHolder: {
    float: "left", 
    position: "relative", 
    width: "114.857px", 
    marginRight: "10px", 
    marginLeft: "10px", 
    marginBottom: "10px",
  },
  serviceTabContainerData: {
    width: "100%",
    overflow: "hidden",
    position: "relative",
    height: "624.281px"
  },
  serviceTabContentContainer: {
    position: "relative",
    width: "92%",
    transitionDuration: "0s",
  },
  serviceTabContent: {
    float: "left",
    listStyle: "none",
    position: "relative",
  },
}));

const ConsultationForm = () => {

    const classes = useStyles();
    const intl = useIntl();
    const { formatMessage } = intl;
    
    const contactFormSchema = Yup.object().shape({
        userName: stringRequired(formatMessage(messages.REQUIRED)),
        phone: requestPhoneNumberRequired(formatMessage(messages.REQUIRED), formatMessage(messages.PHONE_NO_VALIDATION_MESSAGE)),
        company: stringNotRequired(),
    });

    const formik = useFormik({
        initialValues: {
            userName: "",
            phone: "",
            company: "",
        },
        enableReinitialize: true,
        validationSchema: contactFormSchema,
        onSubmit: (values) => {
            setIsSubmitting(true)
            //Send details to server api

            // setIsSubmitting(false)
        }
    });

    return (
        <>
            <div className="spectrecreation_area_grid spectrecreation_area_content ">
                <div className="">
                    <div className="container grid-editable-wrapper ">
                        <div className="grid-container colored-background">
                            <div className="background-color-block" style={{ backgroundColor: "rgb(250, 250, 250)", width: "1264px" }}></div>
                            <div className="grid-wrapper grid-column">
                                <div className="grid-item " style={{ flexBasis: "100%", maxWidth: "100%" }}>
                                    <div className="grid-item-content">
                                        <div className="spectrecreation_area_wysiwyg spectrecreation_area_content ">
                                            <div className="container waypoint waypoint-reveal">
                                                <section className="a-wysiwyg fade-in">
                                                    <h2>Need a Consultation?</h2>
                                                </section>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="grid-item " style={{ flexBasis: "100%", maxWidth: "100%" }}>
                                    <div className="grid-item-content">
                                        <div className="spectrecreation_area_grid spectrecreation_area_content ">
                                            <div className="">
                                                <div className="container grid-editable-wrapper">
                                                    <div className="grid-container ">
                                                        <div className="background-color-block" style={{ width: "1264px" }}></div>
                                                        <div className="grid-wrapper grid-row">
                                                            <div className="grid-item " style={{ flexBasis: "75%", maxWidth: "75%" }}>
                                                                <div className="grid-item-content">
                                                                    <div className="spectrecreation_area_contact-us-template spectrecreation_area_content ">
                                                                        <div className="contact-us-editable empty-title">
                                                                            <div className="p-contact">
                                                                                <div className="container">
                                                                                    <div className="p-contact-block">
                                                                                        <div className="p-contact-block__form contact-us-template-wrapper">
                                                                                            <div className="p-contact-block__description"><p>Drop us a line! We are here to answer your questions 24/7.</p></div>
                                                                                            <Form name="ContactUs" id="ContactForm" className="modal-form__form piwik-form piwik-submit">
                                                                                                <div className="contact-form-responsible-wrapper">
                                                                                                    <div className="align-items-end justify-content-between d-block contact-form-input-wrapper">
                                                                                                        <div className="inputs-row">
                                                                                                            <div className="md-field">
                                                                                                                <TextField
                                                                                                                    className="form-input md-input track-input"
                                                                                                                    fullWidth
                                                                                                                    id="name"
                                                                                                                    name="name"
                                                                                                                    type="text"
                                                                                                                    label={"Full Name"}//`${formatMessage(messages.FORM_PHONE_NUMBER_LABEL)} *`}
                                                                                                                    value={formik.values.name}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    error={formik.touched.name && Boolean(formik.errors.name)}
                                                                                                                    helperText={formik.touched.name && formik.errors.name}
                                                                                                                    InputProps={{
                                                                                                                        "aria-label": "name",
                                                                                                                        "name": "name",
                                                                                                                        form: {
                                                                                                                            autocomplete: "off",
                                                                                                                            maxLength: "150"
                                                                                                                        },
                                                                                                                        ...formik.getFieldProps("name")
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="md-field">
                                                                                                                <TextField
                                                                                                                    className="form-input md-input track-input"
                                                                                                                    fullWidth
                                                                                                                    id="company"
                                                                                                                    name="company"
                                                                                                                    type="text"
                                                                                                                    label={"Company"}//`${formatMessage(messages.FORM_PHONE_NUMBER_LABEL)} *`}
                                                                                                                    value={formik.values.company}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    error={formik.touched.company && Boolean(formik.errors.company)}
                                                                                                                    helperText={formik.touched.company && formik.errors.company}
                                                                                                                    InputProps={{
                                                                                                                        "aria-label": "company",
                                                                                                                        "name": "company",
                                                                                                                        form: {
                                                                                                                            autocomplete: "off",
                                                                                                                            maxLength: "150"
                                                                                                                        },
                                                                                                                        ...formik.getFieldProps("company")
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="align-items-end justify-content-between d-block  contact-form-input-wrapper">
                                                                                                        <div className="inputs-row">
                                                                                                            <div className="md-field">
                                                                                                                <TextField
                                                                                                                    className="form-input md-input track-input"
                                                                                                                    fullWidth
                                                                                                                    id="company"
                                                                                                                    name="company"
                                                                                                                    type="text"
                                                                                                                    label={"Work Email"}//`${formatMessage(messages.FORM_PHONE_NUMBER_LABEL)} *`}
                                                                                                                    value={formik.values.email}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                                                                                                    helperText={formik.touched.email && formik.errors.email}
                                                                                                                    InputProps={{
                                                                                                                        "aria-label": "email",
                                                                                                                        "name": "email",
                                                                                                                        form: {
                                                                                                                            autocomplete: "off",
                                                                                                                            maxLength: "150"
                                                                                                                        },
                                                                                                                        ...formik.getFieldProps("email")
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                            <div className="md-field">
                                                                                                                <TextField
                                                                                                                    className="form-input md-input track-input"
                                                                                                                    fullWidth
                                                                                                                    id="phone"
                                                                                                                    name="phone"
                                                                                                                    type="tel"
                                                                                                                    label={"Work Phone"}//`${formatMessage(messages.FORM_PHONE_NUMBER_LABEL)} *`}
                                                                                                                    value={formik.values.phone}
                                                                                                                    onChange={formik.handleChange}
                                                                                                                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                                                                                                                    helperText={formik.touched.phone && formik.errors.phone}
                                                                                                                    InputProps={{
                                                                                                                        "aria-label": "phone",
                                                                                                                        "name": "phone",
                                                                                                                        form: {
                                                                                                                            autocomplete: "off",
                                                                                                                            maxLength: "150"
                                                                                                                        },
                                                                                                                        ...formik.getFieldProps("phone")
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="contact-form-textarea-wrapper contact-form-input-wrapper">
                                                                                                        <div className="inputs-row">
                                                                                                            <div className="md-field textarea">
                                                                                                                <textarea type="text" id="inquiry" className="md-input contact-popup-question track-input txtstuff" name="contact-popup-question" rows="3" placeholder="How can we help you?" maxLength="4500"></textarea>
                                                                                                                <label htmlFor="inquiry" className="md-label">How can we help you?</label>
                                                                                                                <div className="md-error"></div>
                                                                                                                <i className="icon-s-error"></i>
                                                                                                            </div>
                                                                                                            <div className="form-group" id="contact-popup-upload">
                                                                                                                <label htmlFor="contact-popup-upload">Attachment (optional)</label>
                                                                                                                <div className="upload-field">
                                                                                                                    <div className="upload-field-area">
                                                                                                                        <i className="icon-s-upload"></i>
                                                                                                                        <div className="upload-field-area__message">
                                                                                                                            <div className="upload-field-area__message-text">
                                                                                                                                <p className="d-none d-lg-inline-block">
                                                                                                                                    Drag and drop or &nbsp;
                                                                                                                                </p>
                                                                                                                                <button type="button" className="upload-field-area__message-btn">
                                                                                                                                    <p className="d-inline-block d-lg-none">
                                                                                                                                        Tap here to upload a file
                                                                                                                                    </p>
                                                                                                                                    <p className="d-none d-lg-inline-block">
                                                                                                                                        browse to upload your files
                                                                                                                                    </p>
                                                                                                                                </button>
                                                                                                                            </div>
                                                                                                                            <div className="d-none d-lg-inline-block">
                                                                                                                                <div className="upload-field-area__message-limit">
                                                                                                                                    (up to 5 files of 20MB overall)
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <div className="upload-field-area__message-filename"></div>
                                                                                                                        </div>
                                                                                                                        <div className="available-extensions-tooltip js-tooltip tooltip-wrapper" style={{ display: "inline-block" }}>
                                                                                                                            <i className="icon-s-info"></i>
                                                                                                                            <div className="tooltip-message">
                                                                                                                                <p style={{ fontSize: "14px", letterSpacing: "-0.34px", color: "#222222", marginBottom: "10px" }}>Supported formats:</p>
                                                                                                                                <p>doc, docx, xls, xlsx, ppt, pptx, pps, ppsx, odp, jpeg, jpg, png, psd, webp, svg, mp3, mp4, webm, odt, ods, pdf, rtf, txt, csv, log</p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="upload-field__error">
                                                                                                                        <div className="upload-field-item active" id="size">
                                                                                                                            <i className="icon-s-error"></i>
                                                                                                                            <span className="upload-field-item-text">You've exceeded the limit of 20 MB, please, choose a smaller file(s)</span>
                                                                                                                        </div>
                                                                                                                        <div className="upload-field-item active" id="count">
                                                                                                                            <i className="icon-s-error"></i>
                                                                                                                            <span className="upload-field-item-text">Please, upload up to 5 files</span>
                                                                                                                        </div>
                                                                                                                        <div className="upload-field-item active" id="extension">
                                                                                                                            <i className="icon-s-error"></i>
                                                                                                                            <span className="upload-field-item-text">The type of the file extension is not allowed.</span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div>
                                                                                                                    <input type="file" name="requirements[]" id="requirements-0" className="d-none" multiple="multiple" />
                                                                                                                </div>
                                                                                                                <div className="upload-field-list"></div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="load-gdpr">
                                                                                                    <div className="snippet-content ">
                                                                                                        <div className="author-ask-modal__gdpr">
                                                                                                            <label
                                                                                                                className="checkcontainer checkcontainer--checkbox"> I agree to have Spectre Creation contact me via email, phone, messengers.
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="gdpr-confirm-provide-info"
                                                                                                                    id=""
                                                                                                                    className="track-input"
                                                                                                                    required=""
                                                                                                                    value="ContactAgree" />
                                                                                                                <span className="checkmark"></span>
                                                                                                            </label>


                                                                                                            <label
                                                                                                                className="checkcontainer checkcontainer--checkbox">
                                                                                                                I agree to have Spectre Creation provide my request information to Spectre Creation's affiliated development center.
                                                                                                                <input
                                                                                                                    type="checkbox"
                                                                                                                    name="gdpr-confirm-contact-me"
                                                                                                                    id=""
                                                                                                                    className="track-input"
                                                                                                                    required=""
                                                                                                                    value="AffiliateAgree" />
                                                                                                                <span
                                                                                                                    className="checkmark"></span>
                                                                                                            </label>


                                                                                                            <div
                                                                                                                className="author-ask-modal__gdpr--desc">
                                                                                                                <p>In compliance with GDPR, your personal
                                                                                                                    information will be collected and stored for ten
                                                                                                                    years on servers.
                                                                                                                    After this term is expired, your
                                                                                                                    information will be erased. We will
                                                                                                                    share your information with our development
                                                                                                                    center, which will handle your request. At our
                                                                                                                    headquarters and our development center we apply
                                                                                                                    the same level of care in respect of your information as
                                                                                                                    prescribed with GDPR rules. For
                                                                                                                    more information, please refer to our <a href="/about/privacy-policy">Privacy Policy</a>.
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className="text-center">
                                                                                                    <input type="submit" id="submit" className="btn btn-default modal-form__btn" name="contact-popup-btn" onClick="if (!window.__cfRLUnblockHandlers) return false; " value="Submit" />
                                                                                                </div>
                                                                                            </Form>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="grid-item " style={{ flexBasis: "25%", maxWidth: "25%" }}>
                                                                <div className="grid-item-content">
                                                                    <div className="spectrecreation_area_grid spectrecreation_area_content ">
                                                                        <div className="">
                                                                            <div className="container grid-editable-wrapper ">
                                                                                <div className="grid-container ">
                                                                                    <div className="background-color-block" style={{ width: "100%" }}></div>
                                                                                    <div className="grid-wrapper grid-column">
                                                                                        <div className="grid-item " style={{ flexBasis: "33.3%", maxWidth: "33.3%" }}>
                                                                                            <div className="grid-item-content">
                                                                                                <div className="spectrecreation_area_contact-information spectrecreation_area_content ">
                                                                                                    <div className="contact-information-editable">
                                                                                                        <p className="contact-information-title">
                                                                                                            Our contact details
                                                                                                        </p>
                                                                                                        <div className="contact-information-phone">
                                                                                                            <i className="icon-s-phone"></i>
                                                                                                            <div className="ci-phones">
                                                                                                                <a className="underline" href="tel:+267 72725955" dir="ltr" >
                                                                                                                    +267 72725955
                                                                                                                </a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="contact-information-email">
                                                                                                            <i className="icon-s-envelope"></i>
                                                                                                            <a className="underline" href="mailto:info@spectrecreation.co.bw" dir="ltr">
                                                                                                                info@spectrecreation.co.bw
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="grid-item " style={{ flexBasis: "33.3%", maxWidth: "33.3%" }}>
                                                                                            <div className="grid-item-content">
                                                                                                <div className="spectrecreation_area_link spectrecreation_area_content ">
                                                                                                    <div className="link-editable-wrapper " style={{ textAlign: "left" }}>
                                                                                                        <div className="container ">
                                                                                                            <p className="link-editable-title">
                                                                                                                Join our team
                                                                                                            </p>
                                                                                                            <a href="/about/careers" target="" className="btn btn-link-arrow btn-link-arrow--right link-editable-link">
                                                                                                                Check our open vacancies
                                                                                                            </a>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ConsultationForm;