import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { actions } from "actions";
import { actions as globalActions } from "actions/global";
// Layout Related Components
import Header from "./Header";
import Footer from "components/Footer";

const Layout = ({
  isPreloader,
  layoutWidth,
  leftSideBarType,
  topbarTheme,
  leftSideBarTheme,
  leftSidebarTypes,
  
  children,

  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changeTopbarTheme,
  showRightSidebarAction,
  changeLayout,
  activePage,
  setActivePage
}) => {

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const [makeBackgroundTransparent, setMakeBackgroundTransparent] = useState(true);

  //hides right sidebar on body click
  const hideRightBar = (event) => {
    const rightBar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightBar && rightBar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightBar then fire action for hide rightBar
      showRightSidebarAction(false);
    }
  };

  useEffect(() => {
    //init body click event fot toggle rightBar
    document.body.addEventListener("click", hideRightBar, true);

    if (isPreloader === true) {
      document.getElementById("preloader").style.display = "block";

      setTimeout(function () {
        document.getElementById("preloader").style.display = "none";
      }, 2500);
    } else {
      document.getElementById("preloader").style.display = "none";
    }
  }, [isPreloader]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    changeLayout("vertical");
  }, []);

  useEffect(() => {
    if (leftSideBarTheme) {
      changeSidebarTheme(leftSideBarTheme);
    }
  }, [leftSideBarTheme]);

  useEffect(() => {
    if (leftSidebarTypes) {
      changeSidebarType(leftSidebarTypes);
    }
  }, [leftSidebarTypes]);

  useEffect(() => {
    if (layoutWidth) {
      changeLayoutWidth(layoutWidth);
    }
  }, [layoutWidth]);

  useEffect(() => {
    if (leftSideBarType) {
      changeSidebarType(leftSideBarType);
    }
  }, [leftSideBarType]);

  useEffect(() => {
    if (topbarTheme) {
      changeTopbarTheme(topbarTheme);
    }
  }, [topbarTheme]);

  useEffect(() => {
    if (["Home"].includes(activePage)) {
      setMakeBackgroundTransparent(true)
    }else{
      setMakeBackgroundTransparent(false)
    }
  }, [activePage]);

  return (
    <React.Fragment>
      <div className="pace pace-active" id="preloader">
        <div className="pace-progress" data-progress-text="100%" data-progress="99" style={{ transform: "translate3d(100%, 0px, 0px)" }}>
          <div className="pace-progress-inner"></div>
        </div>
        <div className="pace-activity"></div>
      </div>

      <div id="layout-wrapper">
        <Header 
          makeBackgroundTransparent={makeBackgroundTransparent} 
          setMakeBackgroundTransparent={setMakeBackgroundTransparent} 
          activePage={activePage} 
          setActivePage={setActivePage}/>
        <div>{children}</div>
        <Footer setActivePage={setActivePage}/>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  return {
    isPreloader: state.Layout.isPreloader,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
    leftSidebarTypes: state.Layout.leftSidebarTypes,
    activePage: state.Global.activePage,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {    
    clearLoading: () => {
      dispatch(actions.loading.completeLoadingAction());
    },
    changeLayoutWidth: (layoutWidth) => {
      dispatch(actions.layout.changeLayoutWidth(layoutWidth));
    },
    changeSidebarTheme: (leftSideBarTheme) => {
      dispatch(actions.layout.changeSidebarTheme(leftSideBarTheme));
    },
    changeSidebarType: (sidebarType, isMobile) => {
      dispatch(actions.layout.changeSidebarType(sidebarType, isMobile));
    },
    changeTopbarTheme: (topbarTheme) => {
      dispatch(actions.layout.changeTopbarTheme(topbarTheme));
    },
    showRightSidebarAction: (state)=>{
      dispatch(actions.layout.showRightSidebarAction(state));
    },
    changeLayout: (layout)=>{
      dispatch(actions.layout.changeLayout(layout));
    },
    setActivePage: (pageName) => {
      dispatch(globalActions.global.setActivePageName(pageName));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
