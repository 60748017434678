import { defineMessages } from "react-intl";

const messageKeys = {
    REQUIRED: { 
        id: "REQUIRED", 
        defaultMessage: "Required" 
    },
    EMAIL_MUST_BE_VALID: {
        id: "EMAIL_MUST_BE_VALID",
        defaultMessage: "Incorrect email Id. Please enter a valid email Id."
    },
    WELCOME_BACK: {
        id: "WELCOME_BACK",
        defaultMessage: "Welcome back {custom}"
    },
    LOGIN_WELCOME_BACK_TITLE: {
        id: "LOGIN_WELCOME_BACK_TITLE",
        defaultMessage: "Sign in to continue using to Idea+."
    },
    WELCOME_TO: {
        id: "WELCOME_TO",
        defaultMessage: "Welcome to {custom}"
    },
    LOGIN_WELCOME_SUB_TITLE: {
        id: "LOGIN_WELCOME_SUB_TITLE",
        defaultMessage: "Please login with your Email Id & Password"
    },
    EMAIL_ID: { id: "EMAIL_ID", defaultMessage: "Email address" },
    ACTIVATE_ACCOUNT: { id: "ACTIVATE_ACCOUNT", defaultMessage: "Activate Account" },
    NOT_YOU: { id: "NOT_YOU", defaultMessage: "Not you?" },
    SIGN_UP: { id: "SIGN_UP", defaultMessage: "Sign up" },
    PASSWORD: { id: "PASSWORD", defaultMessage: "Password" },
    REMEMBER_ME: { id: "REMEMBER_ME", defaultMessage: "Remember me" },
    RESET_PASSWORD: { id: "RESET_PASSWORD", defaultMessage: "Reset Password" },  
    LOGIN: { id: "LOGIN", defaultMessage: "Log-In" },
    LOGIN_WELCOME_BACK_ERROR: {
      id: "LOGIN_WELCOME_BACK_ERROR",
      defaultMessage: "Your entered password does not match",
    },
    LOGIN_ATTEMPTS_EXCEEDED: {
      id: "LOGIN_ATTEMPTS_EXCEEDED",
      defaultMessage:
        "You have exhausted all attempts. Reset your password or try again after {time}.",
    },
    GENERIC_ERROR: {
      id: "GENERIC_ERROR",
      defaultMessage: "An unexpected error occurred. Please try again later.",
    },
    LAUNCHING_SOON_TEXT: {
      id: "LAUNCHING_SOON_TEXT",
      defaultMessage: "We are launching very soon, please stay tuned or subscribe below to get launching news",
    },
    ENTER_EMAIL_ADDRESS: {
      id: "ENTER_EMAIL_ADDRESS",
      defaultMessage: "Enter your email address",
    },
    LIVENESS_MESSAGE: {
      id: "LIVENESS_MESSAGE",
      defaultMessage: "We are live now",
    },
    SITE_MAINTENANCE_HEADER_MAIN: {
      id: "SITE_MAINTENANCE_HEADER_MAIN",
      defaultMessage: "Site is Under Maintenance",
    },
    SITE_MAINTENANCE_HEADER_SUB_TEXT: {
      id: "SITE_MAINTENANCE_HEADER_SUB_TEXT",
      defaultMessage: "Please check back in sometime.",
    },
    SITE_MAINTENANCE_REASON: {
      id: "SITE_MAINTENANCE_REASON",
      defaultMessage: "Why is the Site Down?",
    },
    SITE_MAINTENANCE_REASON_EX: {
      id: "SITE_MAINTENANCE_REASON_EX",
      defaultMessage: "There is a scheduled maintenance on site, we are currently doing system upgrades and hence request us to halt system from use.",
    },
    SITE_MAINTENANCE_ETA_LABEL: {
      id: "SITE_MAINTENANCE_ETA_LABEL",
      defaultMessage: "What is the Downtime?",
    },
    SITE_MAINTENANCE_ETA: {
      id: "SITE_MAINTENANCE_ETA",
      defaultMessage: "ETA of UNKNOWN",
    },
    SITE_MAINTENANCE_SUPPORT_LABEL: {
      id: "SITE_MAINTENANCE_SUPPORT_LABEL",
      defaultMessage: "Do you need Support?",
    },
    SITE_MAINTENANCE_SUPPORT_LABEL_SUB: {
      id: "SITE_MAINTENANCE_SUPPORT_LABEL_SUB",
      defaultMessage: "If you need any assistance, please feel free to reach us at ",
    },
    PAGE_NOT_FOUND: {
      id: "PAGE_NOT_FOUND",
      defaultMessage: "Sorry, page not found",
    },
    DASHBOARD_NAVIGATE_LABEL: {
      id: "DASHBOARD_NAVIGATE_LABEL",
      defaultMessage: "Back to Dashboard",
    },    
    INTERNAL_SERVER_ERROR: {
      id: "INTERNAL_SERVER_ERROR",
      defaultMessage: "Internal Server Error",
    },    
    NOTIFICATIONS: {
    id: "NOTIFICATIONS",
    defaultMessage: "Notifications",
  },    
  VIEW_ALL: {
    id: "VIEW_ALL",
    defaultMessage: "View all",
  },
  PROFILE_TEXT: { id: "PROFILE_TEXT", defaultMessage: "Profile" },
  SETTINGS_TEXT: { id: "SETTINGS_TEXT", defaultMessage: "Settings" },
  LOCK_SCREEN: { id: "LOCK_SCREEN", defaultMessage: "Lock screen" },
  LOGOUT: { id: "LOGOUT", defaultMessage: "Logout" },
  LOCK_SCREEN_SUB_TEXT: { id: "LOCK_SCREEN_SUB_TEXT", defaultMessage: "Enter your password to unlock the screen!"},
  UNLOCK: { id: "UNLOCK", defaultMessage: "Unlock" },
  LOGIN_FAILED: {
    id: "LOGIN_FAILED",
    defaultMessage: "Your entered login details do not match.",
  },





  COMPANY_EMAIL_ADDRESS: { id: "COMPANY_EMAIL_ADDRESS", defaultMessage: "info@spectrecreation.co.bw" },
  COMPANY_PHONE_NUMBER: { id: "COMPANY_PHONE_NUMBER", defaultMessage: "+267 727 259 55" },
  COMPANY_PHYSICAL_ADDRESS: { id: "COMPANY_PHYSICAL_ADDRESS", defaultMessage: "Plot 10062, Samora Machel Drive, Fairgrounds, Gaborone, Botswana" },
  COMMON_ABOUT: { id: "COMMON_ABOUT", defaultMessage: "About" },
  COMMON_COMPANY: { id: "COMMON_COMPANY", defaultMessage: "Company" },
  ABOUT_COMPANY: { id: "ABOUT_COMPANY", defaultMessage: "About Spectre Creation" },
  COMMON_LEADERSHIP: { id: "COMMON_LEADERSHIP", defaultMessage: "Leadership" },
  COMMON_EXPERTS: { id: "COMMON_EXPERTS", defaultMessage: "Experts" },
  COMMON_PORTFOLIO: { id: "COMMON_PORTFOLIO", defaultMessage: "Portfolio" },
  CLIENTS_REVIEW: { id: "CLIENTS_REVIEW", defaultMessage: "Client Reviews" },
  OUR_PARTNERS: { id: "OUR_PARTNERS", defaultMessage: "Our Partners" },
  COMMON_LOCATIONS: { id: "COMMON_LOCATIONS", defaultMessage: "Locations" },  
  COMMON_APPROACH: { id: "COMMON_APPROACH", defaultMessage: "Approach" },
  BUSINESS_HOW_TO: { id: "BUSINESS_HOW_TO", defaultMessage: "How to Start Our Cooperation" },
  APPROACH_SOFTWARE_DEVELOPMENT: { id: "APPROACH_SOFTWARE_DEVELOPMENT", defaultMessage: "Our Approach to Software Development" },
  JOIN_US: { id: "JOIN_US", defaultMessage: "Join Us" },
  BECOME_AGENT: { id: "BECOME_AGENT", defaultMessage: "Become Our Agent" },
  CAREERS: { id: "CAREERS", defaultMessage: "Careers" },
  REFERRAL_PROGRAM: { id: "REFERRAL_PROGRAM", defaultMessage: "Referral Program" },  
  COMMON_SERVICES: { id: "COMMON_SERVICES", defaultMessage: "Services" },
  SERVICES_DESCRIPTION: { id: "SERVICES_DESCRIPTION", defaultMessage: "We pride ourselves on being more than just a service provider; we are your dedicated partner in navigating the dynamic landscape of software development. Our comprehensive service portfolio spans the entire software development life cycle, ensuring that your business needs are not just met but exceeded." },
  SOFTWARE_DEVELOPMENT: { id: "SOFTWARE_DEVELOPMENT", defaultMessage: "Software Development" },
  WEB_DEVELOPMENT: { id: "WEB_DEVELOPMENT", defaultMessage: "Web Development" },
  MOBILE_DEVELOPMENT: { id: "MOBILE_DEVELOPMENT", defaultMessage: "Mobile App Development" },
  TESTING_QA: { id: "TESTING_QA", defaultMessage: "Testing and Quality Assurance" },
  APPLICATION_SERVICES: { id: "APPLICATION_SERVICES", defaultMessage: "Application Services" },
  UI_UX: { id: "UI_UX", defaultMessage: "UI/UX Design" },
  INFRASTRUCTURE_SERVICES: { id: "INFRASTRUCTURE_SERVICES", defaultMessage: "Infrastructure Services" },
  DIGITAL_TRANSFORMATION: { id: "DIGITAL_TRANSFORMATION", defaultMessage: "Digital Transformation" },
  IT_SERVICES: { id: "IT_SERVICES", defaultMessage: "Managed IT Services" },
  IT_OUTSOURCING: { id: "IT_OUTSOURCING", defaultMessage: "IT Outsourcing" },
  IT_CONSULTING: { id: "IT_CONSULTING", defaultMessage: "IT Consulting" },
  IT_SUPPORT: { id: "IT_SUPPORT", defaultMessage: "IT Support" },
  DATA_ANALYTICS: { id: "DATA_ANALYTICS", defaultMessage: "Data Analytics" },
  CYBERSECURITY: { id: "CYBERSECURITY", defaultMessage: "Cybersecurity" },
  COMMON_SOLUTIONS: { id: "COMMON_SOLUTIONS", defaultMessage: "Solutions" },
  OUR_SOLUTION_DESCRIPTIONS: { id: "OUR_SOLUTION_DESCRIPTIONS", defaultMessage: "Welcome to Spectre Creation, where we transcend the ordinary to craft sustainable technology solutions that redefine the future. With a foundation built on unparalleled IT domain expertise and deep industry knowledge, we don't just provide solutions; we engineer success." },
  ERP: { id: "ERP", defaultMessage: "ERP" },
  OPERATIONS_MANAGEMENT: { id: "OPERATIONS_MANAGEMENT", defaultMessage: "Operations Management" },
  FINANCIAL_MANAGEMENT: { id: "FINANCIAL_MANAGEMENT", defaultMessage: "Financial Management" },
  SUPPLY_CHAIN_MANAGEMENT: { id: "SUPPLY_CHAIN_MANAGEMENT", defaultMessage: "Supply Chain Management" },
  ASSET_MANAGEMENT: { id: "ASSET_MANAGEMENT", defaultMessage: "Asset Management" },
  FLEET_MANAGEMENT: { id: "FLEET_MANAGEMENT", defaultMessage: "Fleet Management" },
  HUMAN_RESOURCES: { id: "HUMAN_RESOURCES", defaultMessage: "Human Resources" },
  E_LEARNING: { id: "E_LEARNING", defaultMessage: "eLearning" },
  CRM: { id: "CRM", defaultMessage: "CRM" },
  MARKETING_ADVERTISING: { id: "MARKETING_ADVERTISING", defaultMessage: "Marketing &amp, Advertising" },
  E_COMMERCE: { id: "E_COMMERCE", defaultMessage: "E-commerce" },
  KIOSK_SOFTWARE: { id: "KIOSK_SOFTWARE", defaultMessage: "Kiosk Software" },
  WEB_PORTALS: { id: "WEB_PORTALS", defaultMessage: "Web Portals" },
  DOCUMENT_MANAGEMENT: { id: "DOCUMENT_MANAGEMENT", defaultMessage: "Document Management" },
  CONTENT_MANAGEMENT: { id: "CONTENT_MANAGEMENT", defaultMessage: "Content Management" },
  PROJECT_MANAGEMENT: { id: "PROJECT_MANAGEMENT", defaultMessage: "Project Management" },
  INDUSTRIES: { id: "INDUSTRIES", defaultMessage: "Industries" },
  HEALTHCARE: { id: "HEALTHCARE", defaultMessage: "Healthcare" },
  FINANCIAL_SERVICES: { id: "FINANCIAL_SERVICES", defaultMessage: "Banking, Financial Services, and Insurance" },
  MANUFACTURING: { id: "MANUFACTURING", defaultMessage: "Manufacturing" },
  PROFESSIONAL_SERVICES: { id: "PROFESSIONAL_SERVICES", defaultMessage: "Professional Services" },
  RETAIL: { id: "RETAIL", defaultMessage: "Retail" },
  TRANSPORTATION_LOGISTICS: { id: "TRANSPORTATION_LOGISTICS", defaultMessage: "Transportation and Logistics" },
  TELECOMMUNICATIONS: { id: "TELECOMMUNICATIONS", defaultMessage: "Telecommunications" },
  MINING: { id: "MINING", defaultMessage: "Mining" },
  CONSTRUCTION: { id: "CONSTRUCTION", defaultMessage: "Construction" },
  TECHNOLOGIES: { id: "TECHNOLOGIES", defaultMessage: "Technologies" },
  OUR_TECHNOLOGIES_DESCRIPTION: { id: "OUR_TECHNOLOGIES_DESCRIPTION", defaultMessage: "At Spectre Creation, we don't just keep up with technology; we set the pace. Our expertise spans the entire spectrum of major technologies and platforms, advancing seamlessly into the realm of innovative trends. When you partner with us, you're not just embracing technology; you're embracing the future." },
  TECHNOLOGY_STACKS: { id: "TECHNOLOGY_STACKS", defaultMessage: "Technology Stacks" },
  JAVA: { id: "JAVA", defaultMessage: "Java" },
  PYTHON: { id: "PYTHON", defaultMessage: "Python" },
  PHP: { id: "PHP", defaultMessage: "PHP" },
  C_PLUS_PLUS: { id: "C_PLUS_PLUS", defaultMessage: "C++" },
  NODEJS: { id: "NODEJS", defaultMessage: "Node.js" },
  JAVASCRIPT: { id: "JAVASCRIPT", defaultMessage: "JavaScript" },
  REACT_NATIVE: { id: "REACT_NATIVE", defaultMessage: "React Native" },
  MOBILE: { id: "MOBILE", defaultMessage: "Mobile" },
  TRENDING: { id: "TRENDING", defaultMessage: "Trending" },
  CLOUD: { id: "CLOUD", defaultMessage: "Cloud" },
  SAAS: { id: "SAAS", defaultMessage: "Software as a Service (SaaS)" },
  IOT: { id: "IOT", defaultMessage: "Internet of Things" },
  BIG_DATA: { id: "BIG_DATA", defaultMessage: "Big Data" },
  DATA_SCIENCE: { id: "DATA_SCIENCE", defaultMessage: "Data Science" },
  MACHINE_LEARNING: { id: "MACHINE_LEARNING", defaultMessage: "Machine Learning" },
  ARTIFICIAL_INTELLIGENCE: { id: "ARTIFICIAL_INTELLIGENCE", defaultMessage: "Artificial Intelligence" },
  VIRTUAL_REALITY: { id: "VIRTUAL_REALITY", defaultMessage: "Virtual Reality" },
  AUGMENTED_REALITY: { id: "AUGMENTED_REALITY", defaultMessage: "Augmented Reality" },
  COMPUTER_VISION: { id: "COMPUTER_VISION", defaultMessage: "Computer Vision" },
  PLATFORMS: { id: "PLATFORMS", defaultMessage: "Platforms" },
  MICROSOFT: { id: "MICROSOFT", defaultMessage: "Microsoft" },
  AZURE: { id: "AZURE", defaultMessage: "Azure" },
  OFFICE_365: { id: "OFFICE_365", defaultMessage: "SharePoint and Office 365" },
  AWS: { id: "AWS", defaultMessage: "Amazon Web Services" },
  PORTFOLIO: { id: "PORTFOLIO", defaultMessage: "Portfolio" },
  CONTACT_US: { id: "CONTACT_US", defaultMessage: "Contact us" },
  COMMON_REQUEST_CALL_BACK: {
    id: "COMMON_REQUEST_CALL_BACK",
    defaultMessage: "Request a call back",
  },
  COMMON_THANK_YOU_FOR_REACHING_OUT: {
    id: "COMMON_THANK_YOU_FOR_REACHING_OUT",
    defaultMessage: "Thank you for reaching out.",
  },
  COMMON_CUS_CARE_CONTACT_HELP_MSG: {
    id: "COMMON_CUS_CARE_CONTACT_HELP_MSG",
    defaultMessage: "Our customer care team will get back to you shortly",
  },
  PRIVACY_POLICY_TEXT: {
    id: "PRIVACY_POLICY_TEXT",
    defaultMessage: "Privacy Policy",
  },
  TERMS_OF_USE_TEXT: {
    id: "TERMS_OF_USE_TEXT",
    defaultMessage: "Terms of Use",
  },
  SEND: {
    id: "SEND",
    defaultMessage: "Send",
  },
  SENDING: {
    id: "SENDING",
    defaultMessage: "Sending",
  },
  NAME_TITLE: {
    id: "NAME_TITLE",
    defaultMessage: "Name",
  },
  FORM_PHONE_NUMBER_LABEL: {
    id: "FORM_PHONE_NUMBER_LABEL",
    defaultMessage: "Phone number",
  },
  PHONE_NO_VALIDATION_MESSAGE: {
    id: "PHONE_NO_VALIDATION_MESSAGE",
    defaultMessage: "Please enter a valid phone number",
  },
  TESTING_QA_SHORT: { id: "TESTING_QA_SHORT", defaultMessage: "Testing & QA" },
  HELP_DESK_SERVICES: { id: "HELP_DESK_SERVICES", defaultMessage: "Help Desk Services" },
  CYBERSECURITY_SERVICES: { id: "CYBERSECURITY_SERVICES", defaultMessage: "Cybersecurity Services" },
  EXPLORE_MORE: { id: "EXPLORE_MORE", defaultMessage: "Explore more" },



  










    
  
  POWERED_BY: { id: "POWERED_BY", defaultMessage: "Powered by" },
  COMMON_BACK: { id: "COMMON_BACK", defaultMessage: "Back" },
  LOGIN_BRAND_TITLE: { id: "LOGIN_BRAND_TITLE", defaultMessage: "LetsGo" },
  RESUME_LATER: { id: "RESUME_LATER", defaultMessage: "Resume later" },




  LOGIN_THIS_IS_NOT_ME: {
    id: "LOGIN_THIS_IS_NOT_ME",
    defaultMessage: "This is not me",
  },

  LOGOUT_TEXT: {
    id: "LOGOUT_TEXT",
    defaultMessage: "Are you sure you want to logout?",
  },

  SIGNUP_EMAIL_WELCOME_TITLE: {
    id: "SIGNUP_EMAIL_WELCOME_TITLE",
    defaultMessage: "Provide your",
  },
  SIGNUP_EMAIL_SUBTITLE: {
    id: "SIGNUP_EMAIL_SUBTITLE",
    defaultMessage: "Provide your email address to link this account.",
  },
  SIGNUP_EMAIL_HELP_TEXT: {
    id: "SIGNUP_EMAIL_HELP_TEXT",
    defaultMessage: "Need help?",
  },
  SIGNUP_CALL_CUSTOMER_CARE: {
    id: "SIGNUP_CALL_CUSTOMER_CARE",
    defaultMessage: "Call Customer Care",
  },
  GREAT: { id: "GREAT", defaultMessage: "Great" },
  ALL_SET: { id: "ALL_SET", defaultMessage: "You are all set" },
  PROCEED: { id: "PROCEED", defaultMessage: "Proceed" },
  EXISTING_CUSTOMER_TEXT_L1: {
    id: "EXISTING_CUSTOMER_TEXT_L1",
    defaultMessage: "You are an existing customer,",
  },
  TRANSACTION_LIMITS_NOTIFICATION: {
    id: "TRANSACTION_LIMITS_NOTIFICATION",
    defaultMessage: "Increase your transaction limits",
  },
  DAILY_LIMITS_MESSAGE: {
    id: "DAILY_LIMITS_MESSAGE",
    defaultMessage: "Your daily transaction limit is ",
  },
  DAILY_LIMITS_PROCEED_MESSAGE: {
    id: "DAILY_LIMITS_PROCEED_MESSAGE",
    defaultMessage:
      " Please visit our branch to increase your transaction limits.",
  },
  EXISTING_CUSTOMER_TEXT_L2: {
    id: "EXISTING_CUSTOMER_TEXT_L2",
    defaultMessage:
      "Your details are saved with us. Please click proceed to review your details and submit application",
  },
  EXISITING_CUSTOMER_TITLE: {
    id: "EXISITING_CUSTOMER_TITLE",
    defaultMessage: "",
  },
  DONE: { id: "DONE", defaultMessage: "Done" },
  GO_TO_DIGITAL_MALL: {
    id: "GO_TO_DIGITAL_MALL",
    defaultMessage: "Go to Digital Mall",
  },
  FOOTER_CONFIRM_TEXT: {
    id: "FOOTER_CONFIRM_TEXT",
    defaultMessage: "Confirm",
  },
  HOME_WE_FOUND_YOU_MSE_DESCRIPTION: {
    id: "HOME_WE_FOUND_YOU_MSE_DESCRIPTION",
    defaultMessage:
      "Confirm your KYC details and proceed to your personalised dashboard to start your journey & explore exciting offers.",
  },
  HOME_NON_MSE_INTRO: {
    id: "HOME_NON_MSE_LABEL",
    defaultMessage: "Looks like you are not yet an MSE customer",
  },

  HOME_NON_MSE_DESCRIPTION: {
    id: "HOME_NON_MSE_DESCRIPTION",
    defaultMessage:
      "Currently Purchase Order is available only for existing customers. You can explore other service offerings on our Digital Mall",
  },
  MSE_MODULE_TITLE: { id: "MSE_MODULE_TITLE", defaultMessage: "Purchase" },
  MSE_MODULE_SUBTITLE: {
    id: "MSE_MODULE_SUBTITLE",
    defaultMessage: "Order",
  },
  MSE_MODULE_CONTENT: {
    id: "MSE_MODULE_CONTENT",
    defaultMessage:
      "Easily manage your existing loans by tracking them realtime",
  },

  HELP_US: {
    id: "HELP_US",
    defaultMessage: "Help us.",
  },

  IDENTIFY_YOU: {
    id: "HELP_US",
    defaultMessage: "Identify you",
  },

  DELETE_MY_LETSGO_PROFILE: {
    id: "DELETE_MY_LETSGO_PROFILE",
    defaultMessage: "Delete my Letsgo profile",
  },
  DELETE_YOUR_INFO_FROM_CHANNELS: {
    id: "DELETE_YOUR_INFO_FROM_CHANNELS",
    defaultMessage:
      "Delete all your information from all the digital mall channels",
  },
  DELETE_ACCOUNT: {
    id: "DELETE_ACCOUNT",
    defaultMessage: "Delete profile",
  },
  WE_ARE_SAD_TXT: {
    id: "WE_ARE_SAD_TXT",
    defaultMessage: "We are sad",
  },
  TO_SEE_YOU_GO_TXT: {
    id: "TO_SEE_YOU_GO_TXT",
    defaultMessage: "To see you go",
  },
  DELETE_ACC_REASON_PROMPT: {
    id: "DELETE_ACC_REASON_PROMPT",
    defaultMessage: "Kindly tell us why you are deleting your profile",
  },
  REASONS_TO_LEAVE_TXT: {
    id: "REASONS_TO_LEAVE_TXT",
    defaultMessage: "Reasons for deleting profile",
  },
  EXPLAIN_REASON_TXT: {
    id: "EXPLAIN_REASON_TXT",
    defaultMessage: "Explain your reason",
  },
  SERVICES_NOT_FOR_ME_TXT: {
    id: "SERVICES_NOT_FOR_ME_TXT",
    defaultMessage: "Services are not for me",
  },
  CONTINUE_OFFLINE_TXT: {
    id: "CONTINUE_OFFLINE_TXT",
    defaultMessage: "I want to continue offline",
  },
  LACK_OF_SUPPORT_TXT: {
    id: "LACK_OF_SUPPORT_TXT",
    defaultMessage: "Lack of support",
  },
  EASE_OF_USE_TXT: {
    id: "EASE_OF_USE_TXT",
    defaultMessage: "Ease of use",
  },
  SELECT_OFFER_AFFORD: {
    id: "SELECT_OFFER_AFFORD",
    defaultMessage: "Affordability",
  },
  SELECT_OFFER_CURRENT: {
    id: "SELECT_OFFER_CURRENT",
    defaultMessage: "Current",
  },
  SELECT_OFFER_MODAL_TITLE: {
    id: "SELECT_OFFER_MODAL_TITLE",
    defaultMessage: "Select a loan to calculate your personalised loan offer",
  },
  SELECT_OFFER_MODAL_AFFORDABLE_AMOUNT: {
    id: "SELECT_OFFER_MODAL_AFFORDABLE_AMOUNT",
    defaultMessage: "Maximum affordable amount",
  },
  SELECT_OFFER_SELECT_OPTION: {
    id: "SELECT_OFFER_SELECT_OPTION",
    defaultMessage: "Select new/on-going loan",
  },
  SELECT_OFFER_DESCRIPTION: {
    id: "SELECT_OFFER_DESCRIPTION",
    defaultMessage:
      "Get a new loan or top-up your existing loan and calculate your personalised loan offer basis your new affordability",
  },
  BACK: { id: "BACK", defaultMessage: "Back" },
  SKIP: { id: "SKIP", defaultMessage: "Skip" },
  RETRY: { id: "RETRY", defaultMessage: "Retry" },
  BACK_LOWERCASE: { id: "BACK_LOWERCASE", defaultMessage: "back!" },
  WELCOME: { id: "WELCOME", defaultMessage: "Welcome" },
  PRESIDENT: { id: "PRESIDENT", defaultMessage: "A President" },
  VICEPRESIDENT: { id: "VICEPRESIDENT", defaultMessage: "A Vice-President" },
  CABINETMINISTER: {
    id: "CABINETMINISTER",
    defaultMessage: "A Cabinet Minister",
  },
  SPEAKER: {
    id: "SPEAKER",
    defaultMessage: "A Speaker of the National Assembly",
  },
  DEPUTYSPEAKER: {
    id: "DEPUTYSPEAKER",
    defaultMessage: "A Deputy Speaker of the National Assembly",
  },
  MEMBER: { id: "MEMBER", defaultMessage: "A member of the National Assembly" },
  COUNCILLOR: { id: "COUNCILLOR", defaultMessage: "A Councillor" },
  JUDICIALOFFICER: {
    id: "JUDICIALOFFICER",
    defaultMessage: "A judicial officer",
  },
  KGOSI: { id: "KGOSI", defaultMessage: "A Kgosi" },
  SENIORGOVT: {
    id: "SENIORGOVT",
    defaultMessage: "A senior government official",
  },
  SENIOREXECPRIVATE: {
    id: "SENIOREXECPRIVATE",
    defaultMessage: "A senior executive of a private entity",
  },
  SENIOREXECPUBLIC: {
    id: "SENIOREXECPUBLIC",
    defaultMessage: "A senior executive of a public body;",
  },
  SENIOREXECPOLITICAL: {
    id: "SENIOREXECPOLITICAL",
    defaultMessage: "A senior executive of a political party",
  },
  RELIGIOUSLEADER: {
    id: "RELIGIOUSLEADER",
    defaultMessage: "religious leaders",
  },
  SENIOREXECINTERNATIONAL: {
    id: "SENIOREXECINTERNATIONAL",
    defaultMessage:
      "senior executives of international organisations operating in Botswana",
  },
  ANYPOSITIONPEP: {
    id: "ANYPOSITIONPEP",
    defaultMessage:
      "a person who has in the last five years held any of the positions referred to in paragraphs (a) to (o); or",
  },
  PEPMISC: {
    id: "PEPMISC",
    defaultMessage: "such person as may be prescribed",
  },
  EXPRESSION_TITLE: { id: "EXPRESSION_TITLE", defaultMessage: "BVN Verified!" },
  RESET_PASSWORD_SUB_TITLE: {
    id: "RESET_PASSWORD_SUB_TITLE",
    defaultMessage:
      "Use this password to log-in and secure your account details.",
  },
  CONFIRM_EMAIL_RESET_PASSWORD_SUB_TITLE: {
    id: "CONFIRM_EMAIL_RESET_PASSWORD_SUB_TITLE",
    defaultMessage: "Enter your registered email ID",
  },
  CONFIRM_MOBILE_RESET_PASSWORD_SUB_TITLE: {
    id: "CONFIRM_MOBILE_RESET_PASSWORD_SUB_TITLE",
    defaultMessage: "Enter your registered mobile number",
  },
  DEFAULT_PASSWORD_TITLE: {
    id: "DEFAULT_PASSWORD_TITLE",
    defaultMessage: "Default password",
  },
  SET_PASSWORD: { id: "SET_PASSWORD", defaultMessage: "Set your password" },
  CONFIRM_PASSWORD: {
    id: "CONFIRM_PASSWORD",
    defaultMessage: "Confirm password",
  },
  CUSTOMER_NOT_FOUND: {
    id: "CUSTOMER_NOT_FOUND",
    defaultMessage: "Username does not exist",
  },


  GENERIC_ERROR_ID_VALIDATION: {
    id: "GENERIC_ERROR_ID_VALIDATION",
    defaultMessage: "An unexpected error occurred",
  },
  INTERNAL_ERROR: {
    id: "INTERNAL_ERROR",
    defaultMessage: "An unexpected error occurred. Please try again later.",
  },
  AFFORDABILITY_CHECK_FAILED_ON_USER_INPUT: { 
    id: "AFFORDABILITY_CHECK_FAILED_ON_USER_INPUT",
    defaultMessage:
      "You don’t qualify for this loan amount or tenure. Please adjust details to re-check your eligibility.",
  },
  AFFORDABILITY_CHECK_NEW_OFFER_ON_USER_INPUT_MSG: {
    id: "AFFORDABILITY_CHECK_NEW_OFFER_ON_USER_INPUT_MSG",
    defaultMessage:
      "We have adjusted your loan amount and tenure based on what you qualify for.",
  },
  AFFORDABILITY_CHECK_COMPLETED_ON_USER_INPUT_MSG: {
    id: "AFFORDABILITY_CHECK_COMPLETED_ON_USER_INPUT_MSG",
    defaultMessage:
      "We have calculated your eligibility, you can apply for a loan.",
  },

  EMPLOYED_FULL_TIME: {
    id: "EMPLOYED_FULL_TIME",
    defaultMessage: "Employed permanently",
  },
  EMPLOYMENT_TYPE_PERMANENT: {
    id: "EMPLOYMENT_TYPE_PERMANENT",
    defaultMessage: "Employed permanently",
  },
  EMPLOYMENT_TYPE_CONTRACT: {
    id: "EMPLOYMENT_TYPE_CONTRACT",
    defaultMessage: "Employed on contract",
  },
  EMPLOYMENT_TYPE_SELF_EMPLOYED: {
    id: "EMPLOYMENT_TYPE_SELF_EMPLOYED",
    defaultMessage: "Self-employed",
  },
  EMPLOYMENT_TYPE_UNEMPLOYED: {
    id: "EMPLOYMENT_TYPE_UNEMPLOYED",
    defaultMessage: "Unemployed",
  },
  EMPLOYMENT_TYPE_RETIRED: {
    id: "EMPLOYMENT_TYPE_RETIRED",
    defaultMessage: "Retired",
  },
  ELIGIBILITY_QUESTION_EMPLOYMENT_TYPE: {
    id: "ELIGIBILITY_QUESTION_EMPLOYMENT_TYPE",
    defaultMessage: "What is your employment type?",
  },
  ELIGIBILITY_QUESTION_COMPANY_NAME: {
    id: "ELIGIBILITY_QUESTION_COMPANY_NAME",
    defaultMessage: "Where do you work?",
  },
  ELIGIBILITY_QUESTION_NET_SALARY: {
    id: "ELIGIBILITY_QUESTION_NET_SALARY",
    defaultMessage: "What is your monthly net salary?",
  },
  ELIGIBILITY_QUESTION_MONTHLY_INSTALMENT: {
    id: "ELIGIBILITY_QUESTION_MONTHLY_INSTALMENT",
    defaultMessage: "Total monthly instalment in other banks",
  },
  ELIGIBILITY_QUESTION_MONTHLY_INSTALMENT_HELP_TEXT: {
    id: "ELIGIBILITY_QUESTION_MONTHLY_INSTALMENT_HELP_TEXT",
    defaultMessage:
      "Sum of all credit installments paid monthly in other banks",
  },
  ELIGIBILITY_QUESTION_GROSS_SALARY: {
    id: "ELIGIBILITY_QUESTION_GROSS_SALARY",
    defaultMessage: "What is your monthly gross salary?",
  },
  ELIGIBILITY_QUESTION_BASE_SALARY: {
    id: "ELIGIBILITY_QUESTION_BASE_SALARY",
    defaultMessage: "What is your monthly base salary?",
  },
  SELECT: { id: "SELECT", defaultMessage: "Select" },
  SELECT_TYPE: { id: "SELECT_TYPE", defaultMessage: "Select type" },
  SUBMIT: { id: "SUBMIT", defaultMessage: "Submit" },
  EMPLOYER_NAME: { id: "EMPLOYER_NAME", defaultMessage: "Employer name" },
  BASE_SALARY_HELP_TEXT: {
    id: "BASE_SALARY_HELP_TEXT",
    defaultMessage:
      "Your approximate basic salary without factoring in any kind of extra compensation",
  },
  GROSS_SALARY_HELP_TEXT: {
    id: "GROSS_SALARY_HELP_TEXT",
    defaultMessage:
      "Your approximate monthly salary including all the allowances without any tax deductions",
  },
  NET_SALARY_HELP_TEXT: {
    id: "NET_SALARY_HELP_TEXT",
    defaultMessage:
      "Your approximate take home monthly salary received in your account after all the deductions",
  },
  COMPANY_HELP_TEXT_OTHER: {
    id: "COMPANY_HELP_TEXT_OTHER",
    defaultMessage: "Enter your company name",
  },
  COMPANY_HELP_TEXT_PERMANENT: {
    id: "COMPANY_HELP_TEXT_PERMANENT",
    defaultMessage:
      "You need to be employed for minimum 2 years with the government to apply online",
  },
  OTHER: { id: "OTHER", defaultMessage: "Other" },
  RESET_TITLE: { id: "RESET_TITLE", defaultMessage: "Reset" },
  DEFAULT_PASSWORD_ERROR: {
    id: "DEFAULT_PASSWORD_ERROR",
    defaultMessage: "Incorrect default password.",
  },
  PASSWORD_MIN_CHAR_ERROR: {
    id: "PASSWORD_MIN_CHAR_ERROR",
    defaultMessage: "Make sure your password is minimum 4 characters.",
  },
  PASSWORD_MAX_CHAR_ERROR: {
    id: "PASSWORD_MAX_CHAR_ERROR",
    defaultMessage: "Make sure your password is up to 32 characters.",
  },
  PASSWORD_COMBINATION_ERROR: {
    id: "PASSWORD_COMBINATION_ERROR",
    defaultMessage:
      "Combination of alphabets & number; min 8 & max 32 characters.",
  },
  PASSWORD_NOT_MATCH: {
    id: "PASSWORD_NOT_MATCH",
    defaultMessage: "Your Password does not match.",
  },
  OLD_PASSWORD_INVALID: {
    id: "OLD_PASSWORD_INVALID",
    defaultMessage: "Old password provided by user is invalid",
  },
  PASSWORD_REUSE: {
    id: "PASSWORD_REUSE",
    defaultMessage:
      "Your new password cannot be one of your 4 previous passwords!",
  },
  PASSWORD_RESET_REQUIRED: {
    id: "PASSWORD_RESET_REQUIRED",
    defaultMessage:
      "You are requested to reset your password. Kindly change your password now.",
  },
  STRONG_PASSWORD_REQUIRED: {
    id: "STRONG_PASSWORD_REQUIRED",
    defaultMessage:
      "Your current password does not meet our new password policy requirement. Please reset your password to access our solutions.",
  },
  PASSWORD_SENT_MESSAGE_MOBILE: {
    id: "PASSWORD_SENT_MESSAGE_MOBILE",
    defaultMessage:
      "Default Password has been sent to your registered mobile number.",
  },
  PASSWORD_SENT_MESSAGE_SMS: {
    id: "PASSWORD_SENT_MESSAGE_SMS",
    defaultMessage:
      "Default Password has been sent to your registered email address.",
  },
  EMAIL_ID_ALREADY_REGISTERED: {
    id: "EMAIL_ID_ALREADY_REGISTERED",
    defaultMessage: "Looks like this email address is already registered",
  },
  EMAIL_ALREADY_VERIFIED: {
    id: "EMAIL_ALREADY_VERIFIED",
    defaultMessage: "Email address is already verified.",
  },
  INVALID_PASSOWRD: {
    id: "INVALID_PASSOWRD",
    defaultMessage:
      "Password must be alphanumeric and between 4 and 32 characters",
  },
  PASSOWRD_HEADING_TEXT: {
    id: "PASSOWRD_HEADING_TEXT",
    defaultMessage: "Set up a",
  },
  PASSWORD_SUBTITLE: {
    id: "PASSWORD_SUBTITLE",
    defaultMessage:
      "Use this password to log-in and secure your account details.",
  },
  PASSWORD_VALIDATION_DISCLAIMER: {
    id: "PASSWORD_VALIDATION_DISCLAIMER",
    defaultMessage:
      "Use a combination of letters and numbers. Min 4 – max 32 characters long.",
  },
  CONFIRM: { id: "CONFIRM", defaultMessage: "Confirm" },
  PROCEED_LOGIN: { id: "PROCEED_LOGIN", defaultMessage: "Proceed to log-in" },
  PASSWORD_MISMATCH: {
    id: "PASSWORD_MISMATCH",
    defaultMessage: "Your entered password does not match",
  },
  PASSWORD_MIN_LENGTH_ERROR: {
    id: "PASSWORD_MIN_LENGTH_ERROR",
    defaultMessage: "Make sure your password is at least 4 characters",
  },
  PASSWORD_MAX_LENGTH_ERROR: {
    id: "PASSWORD_MAX_LENGTH_ERROR",
    defaultMessage: "Your password can only be up to 32 characters",
  },
  PASSWORD_SUCCESS_CARD_HEADING: {
    id: "PASSWORD_SUCCESS_CARD_HEADING",
    defaultMessage: "Great!",
  },
  PASSWORD_SUCCESS_CARD_SUBHEADING: {
    id: "PASSWORD_SUCCESS_CARD_SUBHEADING",
    defaultMessage: "You are all set",
  },
  PASSWORD_SUCCESS_CARD_TITLE: {
    id: "PASSWORD_SUCCESS_CARD_TITLE",
    defaultMessage: "Your password has been set-up successfully.",
  },
  PASSWORD_SUCCESS_CARD_SUBTITLE: {
    id: "PASSWORD_SUCCESS_CARD_SUBTITLE",
    defaultMessage: "Please proceed to log-in.",
  },
  SIGNUP_PHONENO_TITLE: {
    id: "SIGNUP_PHONENO_TITLE",
    defaultMessage: "Enter your",
  },
  SIGNUP_PHONENO_TITLE_NUMBER: {
    id: "SIGNUP_PHONENO_TITLE_NUMBER",
    defaultMessage: "Mobile number",
  },
  SIGNUP_PHONENO_SUBTITLE: {
    id: "SIGNUP_PHONENO_SUBTITLE",
    defaultMessage:
      "Enter your registered mobile number and you will receive an OTP to verify your number.",
  },
  SIGNUP_PHONENO_EXISTING_LOAN: {
    id: "SIGNUP_PHONENO_EXISTING_LOAN",
    defaultMessage: "I have an existing loan with Letshego",
  },
  SIGNUP_PHONENO_RECEIVE_LETSGO_MALL_UPDATES: {
    id: "SIGNUP_PHONENO_RECEIVE_LETSGO_MALL_UPDATES",
    defaultMessage:
      "I would like to receive updates on what's new on the LetsGo Mall",
  },
  SIGNUP_PHONENO_AGREE: {
    id: "SIGNUP_PHONENO_AGREE",
    defaultMessage: "I agree to all {termsAndCondition} and {privacyPolicies}",
  },
  SIGNUP_PHONENO_TERMS: {
    id: "SIGNUP_PHONENO_TERMS",
    defaultMessage: "Terms & Conditions",
  },
  SIGNUP_PHONENO_PRIVACY: {
    id: "SIGNUP_PHONENO_PRIVACY",
    defaultMessage: "Privacy Policies",
  },
  SIGN_UP_INSTITUTION_FAIDIKA: {
    id: "SIGN_UP_INSTITUTION_FAIDIKA",
    defaultMessage: "Letshego Faidika",
  },

  INSTITUTION_FAIDIKA_VALUE: {
    id: "INSTITUTION_FAIDIKA_VALUE",
    defaultMessage: "FAIDIKA",
  },

  INSTITUTION_LETSHEGO_BANK_TANZANIA_VALUE: {
    id: "INSTITUTION_LETSHEGO_BANK_TANZANIA_VALUE",
    defaultMessage: "LBT",
  },

  SWITCH_INSTITUTION_PROMPT_LOGOUT_AND_SWITCH: {
    id: "SWITCH_INSTITUTION_PROMPT_LOGOUT_AND_SWITCH",
    defaultMessage: "Yes, logout and register",
  },
  SIGN_UP_INSTITUTION_LETSHEGO_BANK_TANZANIA: {
    id: "SIGN_UP_INSTITUTION_LETSHEGO_BANK_TANZANIA",
    defaultMessage: "Letshego Bank Tanzania",
  },
  SIGN_UP_INSTITUTION_SELECT: {
    id: "SIGN_UP_INSTITUTION_SELECT",
    defaultMessage: "Select Institution",
  },
  SIGN_UP_INSTITUTION_SELECT_HELPER_TEXT: {
    id: "SIGN_UP_INSTITUTION_SELECT_HELPER_TEXT",
    defaultMessage:
      "Select your preferred institution between Letshego Faidika and Letshego Bank Tanzania",
  },
  INSTITUTION_ALREADY_REGISTERED_ERROR_TEXT: {
    id: "INSTITUTION_ALREADY_REGISTERED_ERROR_TEXT",
    defaultMessage:
      "You are already registered with this selected institution. Please change to proceed.",
  },
  INSTITUTION_ALREADY_LOGGED_IN_ERROR_TEXT: {
    id: "INSTITUTION_ALREADY_LOGGED_IN_ERROR_TEXT",
    defaultMessage:
      "You are already logged in with this selected institution. Please change to proceed.",
  },
  REGISTRATION_SUCCESS_FAIDIKA: {
    id: "REGISTRATION_SUCCESS_FAIDIKA",
    defaultMessage: "Registration for LetsGo Faidika is successful.",
  },
  REGISTRATION_SUCCESS_LBT: {
    id: "REGISTRATION_SUCCESS_LBT",
    defaultMessage: "Registration for LetsGo Bank Tanzania is successful.",
  },
  PHONENO_LENGTH: {
    id: "PHONENO_LENGTH",
    defaultMessage: "Mobile number must be {length} digits",
  },
  PHONENO_MUST_BE_VALID: {
    id: "PHONENO_MUST_BE_VALID",
    defaultMessage: "Invalid Number. Please enter a valid mobile number",
  },
  NUIT_MUST_BE_VALID: {
    id: "NUIT_MUST_BE_VALID",
    defaultMessage: "Invalid Number. Please enter a valid  number",
  },
  LAND_PHONE_NO_MUST_BE_VALID: {
    id: "LAND_PHONE_NO_MUST_BE_VALID",
    defaultMessage: "Invalid Number. Please enter a valid landline number",
  },
  PHONENO_VERIFY_YOURSELF: {
    id: "PHONENO_VERIFY_YOURSELF",
    defaultMessage: "Yourself",
  },
  PHONENO_VERIFY_SUBTITLE: {
    id: "PHONENO_VERIFY_SUBTITLE",
    defaultMessage: "Enter the OTP sent to your registered mobile number",
  },
  VERIFY: { id: "VERIFY", defaultMessage: "Verify" },
  OTP: { id: "OTP", defaultMessage: "OTP" },
  RESEND_OTP: { id: "RESEND_OTP", defaultMessage: "Resend OTP" },
  RESEND_DEFAULT_OTP: {
    id: "RESEND_DEFAULT_OTP",
    defaultMessage: "Resend default password",
  },
  INCORRECT_OTP: {
    id: "INCORRECT_OTP",
    defaultMessage:
      "Invalid OTP. Please re-enter or get a new OTP by clicking Resend OTP button.",
  },
  EXPIRED_OTP: {
    id: "EXPIRED_OTP",
    defaultMessage: "OTP expired. Please click resend",
  },
  ATTEMPTS_EXCEEDED: {
    id: "ATTEMPTS_EXCEEDED",
    defaultMessage:
      "You reached maximum attempts for OTP.  Please try again in {time}.",
  },
  RESEND_TIME_LIMIT_EXCEEDED: {
    id: "RESEND_TIME_LIMIT_EXCEEDED",
    defaultMessage:
      "Maximum resend tries exceeded. Please try again in {time}.",
  },
  OTP_GENERATION_FAILED: {
    id: "OTP_GENERATION_FAILED",
    defaultMessage:
      "Some error occured, try resend password again (otp generation failed)",
  },
  PASSWORD_RESET_ATTEMPTS_EXCEEDED: {
    id: "PASSWORD_RESET_ATTEMPTS_EXCEEDED",
    defaultMessage:
      "Maximum password reset tries exceeded - please try again later, or contact customer care",
  },
  CUSTOMER_ALREADY_REGISTERED: {
    id: "CUSTOMER_ALREADY_REGISTERED",
    defaultMessage: "Customer with given id is already registered",
  },
  FOOTER_VERIFY_TEXT: {
    id: "FOOTER_VERIFY_TEXT",
    defaultMessage: "It just takes a minute",
  },
  FOOTER_VERIFY: { id: "FOOTER_VERIFY", defaultMessage: "Verify it's you" },
  FOOTER_APPLY_LOAN: {
    id: "FOOTER_APPLY_LOAN",
    defaultMessage: "Don’t have a loan with Letshego?",
  },
  CLICK_HERE: { id: "CLICK_HERE", defaultMessage: "Click here" },
  REMOVE_PHOTO: { id: "REMOVE_PHOTO", defaultMessage: "Remove Photo" },
  UPLOAD_PHOTO: { id: "UPLOAD_PHOTO", defaultMessage: "Upload Photo" },
  UPLOAD_PHOTO_OPTIONAL: {
    id: "UPLOAD_PHOTO_OPTIONAL",
    defaultMessage: "Upload photo (optional)",
  },
  UPLOAD_PHOTO_UPLOAD_DESC: {
    id: "UPLOAD_PHOTO_UPLOAD_DESC",
    defaultMessage: "Upload a photo as a proof",
  },
  CLICK_PHOTO: { id: "CLICK_PHOTO", defaultMessage: "Click Photo" },
  CAPTURE_ID_PROCEED: { id: "CAPTURE_ID_PROCEED", defaultMessage: "Proceed" },
  PROVIDE_NATIONALITY_AND_ID: {
    id: "PROVIDE_NATIONALITY_AND_ID",
    defaultMessage:
      "Please provide your country of citizenship and ID information.",
  },
  CAPTURE_ID_HEADER: {
    id: "CAPTURE_ID_HEADER",
    defaultMessage: "Enter Your ID details",
  },
  CAPTURE_ID_NATIONALITY_LABEL: {
    id: "CAPTURE_ID_NATIONALITY_LABEL",
    defaultMessage: "Nationality",
  },
  NATIONAL_ID_LABEL: {
    id: "NATIONAL_ID_LABEL",
    defaultMessage: "National ID Number",
  },
  NATIONAL_ID_ERROR: {
    id: "NATIONAL_ID_ERROR",
    defaultMessage: "Please enter a valid national id number",
  },
  BVN_LABEL: { id: "BVN_LABEL", defaultMessage: "Bank Verification Number" },
  BVN_ERROR: {
    id: "BVN_ERROR",
    defaultMessage: "Please enter a valid bank verification number",
  },
  BVN_VERIFICATION_FAILED: {
    id: "BVN_VERIFICATION_FAILED",
    defaultMessage: "BVN validation failed. Please try again",
  },
  BVN_NUMBER_NOT_MATCH: {
    id: "BVN_NUMBER_NOT_MATCH",
    defaultMessage: "Use Number linked to BVN",
  },
  BVN_NUMBER_NOT_MATCH_TEXT: {
    id: "BVN_NUMBER_NOT_MATCH_TEXT",
    defaultMessage:
      "We will be replacing the mobile number registered on this app with the number registered with BVN.",
  },
  BVN_NUMBER_NOT_MATCH_ERROR: {
    id: "BVN_NUMBER_NOT_MATCH_ERROR",
    defaultMessage:
      "We will use your mobile number linked with BVN ({phone}) for all future communications through the Letsgo application for additional security.",
  },
  BVN_NUMBER_NOT_MATCH_DISCLAIMER: {
    id: "BVN_NUMBER_NOT_MATCH_DISCLAIMER",
    defaultMessage:
      "All future communications will be sent to the number registered with BVN.",
  },
  BVN_MODAL_EXISTING_TEXT: {
    id: "BVN_MODAL_EXISTING_TEXT",
    defaultMessage:
      "Your Bank Verification Number (BVN) and mobile number is already registered with us",
  },
  BVN_UNABLE_TO_VALIDATE: {
    id: "BVN_UNABLE_TO_VALIDATE",
    defaultMessage: "We are unable to validate your BVN",
  },
  PASSPORT_LABEL: { id: "PASSPORT_LABEL", defaultMessage: "Passport Number" },
  PASSPORT_ERROR: {
    id: "PASSPORT_ERROR",
    defaultMessage: "Please enter a valid passport number",
  },
  DRIVERS_LINCENSE_LABEL: {
    id: "DRIVERS_LINCENSE_LABEL",
    defaultMessage: "Driving License",
  },
  DRIVERS_LINCENSE_ERROR: {
    id: "DRIVERS_LINCENSE_ERROR",
    defaultMessage: "Please enter a valid driving lincense",
  },
  YOUR_INFORMATION_INCORRECT: {
    id: "YOUR_INFORMATION_INCORRECT",
    defaultMessage: "Your information is incorrect",
  },
  ID_VERIFICATION_HELPER_1: {
    id: "ID_VERIFICATION_HELPER_1",
    defaultMessage:
      "Unfortunately, your information doesn’t match what we have about you.",
  },
  ID_VERIFICATION_HELPER_2: {
    id: "ID_VERIFICATION_HELPER_2",
    defaultMessage:
      "Please retry in {hour} hours to proceed with your the application.",
  },
  ID_VERIFICATION_ATTEMPTS_EXHAUSTED: {
    id: "ID_VERIFICATION_ATTEMPTS_EXHAUSTED",
    defaultMessage:
      "You’ve exhausted your questions from  NIDA. Please retry in {hours} hours.",
  },
  NIDA_NUMBER: {
    id: "NIDA_NUMBER",
    defaultMessage: "NIDA number",
  },
  NIDA_LABEL: { id: "NIDA_LABEL", defaultMessage: "NIDA" },
  NIDA_ERROR: {
    id: "NIDA_ERROR",
    defaultMessage: "Please enter a valid NIDA",
  },
  ID_NUMBER_VERIFICATION_FAILED: {
    id: "ID_NUMBER_VERIFICATION_FAILED",
    defaultMessage: "Please enter a valid passport number",
  },
  HURRAY: { id: "HURRAY", defaultMessage: "Hurray" },
  EXISTING_CUSTOMER_TEXT: {
    id: "EXISTING_CUSTOMER_TEXT",
    defaultMessage: "You are an existing customer",
  },
  HOME_WE_FOUND_YOU: {
    id: "HOME_WE_FOUND_YOU",
    defaultMessage: "We found you",
  },
  HOME_WE_FOUND_YOU_TITLE: {
    id: "HOME_WE_FOUND_YOU_TITLE",
    defaultMessage: "Redirecting you to your personalized dashboard.",
  },
  SORRY: { id: "SORRY", defaultMessage: "Sorry" },
  SORRY_NAME: { id: "SORRY_NAME", defaultMessage: "Sorry {patientName}!" },
  SORRY_USERNAME: { id: "SORRY_USERNAME", defaultMessage: "Sorry {userName}," },
  ERROR_OCCURED: { id: "ERROR_OCCURED", defaultMessage: "An error occurred" },
  UNABLE_TO_IDENTIFY_ERROR_OCCURED: {
    id: "UNABLE_TO_IDENTIFY_ERROR_OCCURED",
    defaultMessage:
      "We were unable to identify the necessary details provided. Please re-upload for smooth application.",
  },
  UNABLE_TO_IDENTIFY_ERROR_OCCURED_RETRY: {
    id: "UNABLE_TO_IDENTIFY_ERROR_OCCURED_RETRY",
    defaultMessage:
      "We are sorry. We were not able to identify your necessary details. Kindly get in touch with our team and we promise they will help you out.",
  },
  CARD_NOT_VERIFIED: {
    id: "CARD_NOT_VERIFIED",
    defaultMessage: "We could not verify your card",
  },
  REWARD: { id: "REWARD", defaultMessage: "Reward" },
  REFERRAL_TEXT: {
    id: "REFERRAL_TEXT",
    defaultMessage: "Give credit if you were helped  or referred by someone",
  },
  GIVE_REWARD: { id: "GIVE_REWARD", defaultMessage: "Give reward" },
  REFERRAL_BY_STAFF: {
    id: "REFERRAL_BY_STAFF",
    defaultMessage: "Staff or Agent",
  },
  PLATFORM_BUILDING_MESSAGE: {
    id: "PLATFORM_BUILDING_MESSAGE",
    defaultMessage:
      "We are currently building a platform that helps you save better and gives you the power to do more",
  },
  REFERRAL_BY_FAMILY: {
    id: "REFERRAL_BY_FAMILY",
    defaultMessage: "Family or Friend",
  },
  REFERRAL_ENTER_NAME: {
    id: "REFERRAL_ENTER_NAME",
    defaultMessage: "Enter name or code",
  },
  ENTER_REFERRAL_CODE: {
    id: "ENTER_REFERRAL_CODE",
    defaultMessage: "Enter referral code",
  },
  ENTER_REFER_CODE_SHARED: {
    id: "ENTER_REFER_CODE_SHARED",
    defaultMessage: "Please enter the code shared with you",
  },
  REFERRAL_WHO_REFERRED_TEXT: {
    id: "REFERRAL_WHO_REFERRED_TEXT",
    defaultMessage:
      "Please enter the name or code of the Letshego agent or staff",
  },
  REFERRAL_TITLE: {
    id: "REFERRAL_TITLE",
    defaultMessage: "Were you helped or referred?",
  },
  SURVEY_QUESTION: {
    id: "SURVEY_QUESTION",
    defaultMessage: "How did you discover LetsGo App?",
  },
  HOME_UNABLE_TO_FIND_YOU: {
    id: "HOME_UNABLE_TO_FIND_YOU",
    defaultMessage: "We are unable to find you",
  },
  HOME_UNABLE_TO_FIND_YOU_CONTACT_CC: {
    id: "HOME_UNABLE_TO_FIND_YOU_CONTACT_CC",
    defaultMessage:
      "For any queries, please contact our customer care at {phone}",
  },
  HOME_DONT_HAVE_LOAN: {
    id: "HOME_DONT_HAVE_LOAN",
    defaultMessage:
      "You can continue to explore our awesome range of products as new customer.",
  },
  CUSTOMER_CARE: {
    id: "CUSTOMER_CARE",
    defaultMessage:
      "Please contact at our customer care at {no} avaliable from 8AM - 4PM",
  },
  LOAN_DISBURSED_TITLE: {
    id: "LOAN_DISBURSED_TITLE",
    defaultMessage: "Manage",
  },
  LOAN_DISBURSED_SUBTITLE: {
    id: "LOAN_DISBURSED_SUBTITLE",
    defaultMessage: "Existing loans",
  },
  LOAN_DISBURSED_CONTENT: {
    id: "LOAN_DISBURSED_CONTENT",
    defaultMessage:
      "Easily manage your existing loans by tracking them realtime",
  },
  LOAN_APPROVED_TITLE: { id: "LOAN_APPROVED_TITLE", defaultMessage: "Offer" },
  LOAN_APPROVED_SUBTITLE: {
    id: "LOAN_APPROVED_SUBTITLE",
    defaultMessage: "Just for you",
  },
  LOAN_APPROVED_CONTENT: {
    id: "LOAN_APPROVED_CONTENT",
    defaultMessage: "Get an exciting personalized loan offer",
  },
  ERROR_TOPPUP_TITLE: { id: "ERROR_TOPPUP_TITLE", defaultMessage: "Instant" },
  ERROR_TOPPUP_SUBTITLE: {
    id: "ERROR_TOPPUP_SUBTITLE",
    defaultMessage: "Top-ups",
  },
  ERROR_TOPPUP_CONTENT: {
    id: "ERROR_TOPPUP_CONTENT",
    defaultMessage: "Get an instant top-up to your loan in a few easy clicks.",
  },
  DASHBOARD_BORROW_TITLE: {
    id: "DASHBOARD_BORROW_TITLE",
    defaultMessage: "How much do you",
  },
  INSTANT_LOAN_BORROW_TITLE: {
    id: "INSTANT_LOAN_BORROW_TITLE",
    defaultMessage: "How much money",
  },
  INSTANT_LOAN_BORROW_SUBTITLE: {
    id: "INSTANT_LOAN_BORROW_SUBTITLE",
    defaultMessage: "do you want?",
  },
  INSTANT_LOAN_REPAY_TITLE: {
    id: "INSTANT_LOAN_REPAY_TITLE",
    defaultMessage: "How much money do",
  },
  INSTANT_LOAN_REPAY_SUBTITLE: {
    id: "INSTANT_LOAN_REPAY_SUBTITLE",
    defaultMessage: "you want to repay?",
  },
  INSTANT_LOAN_REPAYMENT_WIDGET_TITLE: {
    id: "INSTANT_LOAN_REPAYMENT_WIDGET_TITLE",
    defaultMessage: "Loan repayment",
  },
  INSTANT_LOAN_REPAYMENT_WIDGET_SUBTITLE: {
    id: "INSTANT_LOAN_REPAYMENT_WIDGET_SUBTITLE",
    defaultMessage: "duration",
  },
  INSTANT_LOAN_REPAYMENT_TITLE: {
    id: "INSTANT_LOAN_REPAYMENT_TITLE",
    defaultMessage: "Loan payment",
  },
  INSTANT_LOAN_REPAYMENT_SUBTITLE: {
    id: "INSTANT_LOAN_REPAYMENT_SUBTITLE",
    defaultMessage: "duration",
  },
  RETRY_LOAN_MODAL_UNABLE_TO_SERVE: {
    id: "RETRY_LOAN_MODAL_UNABLE_TO_SERVE",
    defaultMessage:
      "Unfortunately, we were not able to calculate the final eligibility.",
  },
  RETRY_LOAN_MODAL_DESCRIPTION: {
    id: "RETRY_LOAN_MODAL_DESCRIPTION",
    defaultMessage: "Please retry to proceed in the application.",
  },
  COMMON_ACCEPT_REVISED_AMOUNT: {
    id: "COMMON_ACCEPT_REVISED_AMOUNT",
    defaultMessage: "Accept Revised Amount.",
  },
  COMMON_REJECT_REVISED_AMOUNT: {
    id: "COMMON_REJECT_REVISED_AMOUNT",
    defaultMessage: "No, I am not interested.",
  },
  RETRY_LOAN_MODAL_RETRY_EXHAUSTED: {
    id: "RETRY_LOAN_MODAL_RETRY_EXHAUSTED",
    defaultMessage:
      "You have exceeded your 3 chances. Please try after sometime.",
  },
  INSTANT_LOAN_REPAYMENT_SECURITY_ALERT: {
    id: "INSTANT_LOAN_REPAYMENT_SECURITY_ALERT",
    defaultMessage: "The loan repayment duration is 1 month by default",
  },
  WANT_TO_PAY: {
    id: "WANT_TO_PAY",
    defaultMessage: "Want to pay?",
  },
  WANT_TO_ADD: {
    id: "WANT_TO_ADD",
    defaultMessage: "Want to add?",
  },
  PLEASE_CONFIRM: {
    id: "PLEASE_CONFIRM",
    defaultMessage: "Please confirm",
  },
  THE_DETAILS: {
    id: "THE_DETAILS",
    defaultMessage: "The details",
  },
  AMOUNT_WANT_TO_ADD: {
    id: "AMOUNT_WANT_TO_ADD",
    defaultMessage: "Amount you want to add",
  },
  ADDING_MONEY_FROM: {
    id: "ADDING_MONEY_FROM",
    defaultMessage: "Adding money from",
  },
  TRANSFERING_TO: {
    id: "TRANSFERING_TO",
    defaultMessage: "Transfering to",
  },
  SENDING_MONEY_FROM: {
    id: "SENDING_MONEY_FROM",
    defaultMessage: "Sending money from",
  },
  COMMON_AC_NO: {
    id: "COMMON_AC_NO",
    defaultMessage: "A/C No.",
  },
  COMMON_CHANGE: {
    id: "COMMON_CHANGE",
    defaultMessage: "Change",
  },
  COMMON_BALANCE: {
    id: "COMMON_BALANCE",
    defaultMessage: "Balance",
  },
  DASHBOARD_CANNOT_BORROW_MSG: {
    id: "DASHBOARD_CANNOT_BORROW_MSG",
    defaultMessage: "You can not borrow your requested amount.",
  },
  DASHBOARD_BORROW_SUBTITLE: {
    id: "DASHBOARD_BORROW_SUBTITLE",
    defaultMessage: "Need to borrow?",
  },
  DASHBOARD_LOAN_CALCULATE_TEXT: {
    id: "DASHBOARD_LOAN_CALCULATE_TEXT",
    defaultMessage: "Calculate a personalized loan offer",
  },
  LOAN_AMOUNT_BORROW_TEXT: {
    id: "LOAN_AMOUNT_BORROW_TEXT",
    defaultMessage: "Amount to borrow",
  },
  LOAN_SLIDER_DRAG_TEXT: {
    id: "LOAN_SLIDER_DRAG_TEXT",
    defaultMessage:
      "Drag the slider above to select the loan amount to your needs between {symbol} {min}  to  {symbol} {max}",
  },
  INSTANT_LOAN_SLIDER_BORROW_TEXT: {
    id: "INSTANT_LOAN_SLIDER_BORROW_TEXT",
    defaultMessage: "Expected sum to borrow",
  },
  INSTANT_LOAN_SLIDER_DRAG_TEXT: {
    id: "INSTANT_LOAN_SLIDER_DRAG_TEXT",
    defaultMessage:
      "Drag the slider above to select the loan amount. Please note that this is the disbursement amount.",
  },
  INSTANT_LOAN_REPAY_SLIDER_DRAG_TEXT: {
    id: "INSTANT_LOAN_REPAY_SLIDER_DRAG_TEXT",
    defaultMessage:
      "Drag the slider above to select the loan amount. Please note that this is the repayment amount.",
  },
  INSTANT_LOAN_SLIDER_REPAY_TEXT: {
    id: "INSTANT_LOAN_SLIDER_REPAY_TEXT",
    defaultMessage: "Amount to repay",
  },
  LOAN_REJECTION_ERROR: {
    id: "LOAN_REJECTION_ERROR",
    defaultMessage: "Please choose a reason of rejection",
  },
  LOAN_DOCUMENTS_NEED_TEXT: {
    id: "LOAN_DOCUMENTS_NEED_TEXT",
    defaultMessage: "The documents you will need",
  },
  LOAN_PURPOSE_LIMIT_ERROR: {
    id: "LOAN_PURPOSE_LIMIT_ERROR",
    defaultMessage: "error! you can not enter character more than 140 words..",
  },
  HEY_TEXT: { id: "HEY_TEXT", defaultMessage: "Hey" },
  YOU_DID_GREAT_TEXT: {
    id: "YOU_DID_GREAT_TEXT",
    defaultMessage: "You did great!",
  },
  NATIONAL_ID: { id: "NATIONAL_ID", defaultMessage: "National ID" },
  NATIONAL_ID_WITH_SELFIE: {
    id: "NATIONAL_ID_WITH_SELFIE",
    defaultMessage: "Take a selfie with National ID",
  },
  TAKE_SELFIE_WITH_NID: {
    id: "TAKE_SELFIE_WITH_NID",
    defaultMessage: "Take a selfie with { documentType }",
  },
  TAKE_YOUR_SELFIE: {
    id: "TAKE_YOUR_SELFIE",
    defaultMessage: "Take a selfie",
  },
  UPLOAD_SELFIE: {
    id: "UPLOAD_SELFIE",
    defaultMessage: "Upload your Selfie",
  },
  NATIONAL_ID_DESCRIPTOR: {
    id: "NATIONAL_ID_DESCRIPTOR",
    defaultMessage: "Your national identity card",
  },
  SALARY_SLIP: { id: "SALARY_SLIP", defaultMessage: "Salary slip" },
  SALARY_SLIP_DESCRIPTOR: {
    id: "SALARY_SLIP_DESCRIPTOR",
    defaultMessage: "Last 3 months salary slips",
  },
  BANK_STATEMENT: { id: "BANK_STATEMENT", defaultMessage: "Bank Statement" },
  BANK_STATEMENT_OPTIONAL: {
    id: "BANK_STATEMENT_OPTIONAL",
    defaultMessage: "Bank Statement (optional)",
  },
  BANK_STATEMENT_DEBIT_CARD_MINI_STMT: {
    id: "BANK_STATEMENT_DEBIT_CARD_MINI_STMT",
    defaultMessage: "Bank Statement or Bank Debit with mini-statement",
  },
  BANK_STATEMENT_DESCRIPTOR: {
    id: "BANK_STATEMENT_DESCRIPTOR",
    defaultMessage: "Last 3 months statements of your salary account",
  },
  EMPLOYMENT_LETTER: {
    id: "EMPLOYMENT_LETTER",
    defaultMessage: "Employment letter",
  },
  EMPLOYMENT_LETTER_OPTIONAL: {
    id: "EMPLOYMENT_LETTER_OPTIONAL",
    defaultMessage: "Employment letter (optional)",
  },
  PROOF_OF_RESIDENCE: {
    id: "PROOF_OF_RESIDENCE",
    defaultMessage: "Proof of Residence",
  },
  PROOF_OF_RESIDENCE_DESCRIPTOR: {
    id: "PROOF_OF_RESIDENCE_DESCRIPTOR",
    defaultMessage: "Proof of Residence",
  },
  CONFIRMATION_LETTER: {
    id: "CONFIRMATION_LETTER",
    defaultMessage: "Confirmation Letter",
  },
  EMPLOYMENT_LETTER_DESCRIPTOR: {
    id: "EMPLOYMENT_LETTER_DESCRIPTOR",
    defaultMessage: "Employment confirmation letter from your employer",
  },
  ID_PROOF: { id: "ID_PROOF", defaultMessage: "ID Proof" },
  ID_PROOF_DESCRIPTOR: {
    id: "ID_PROOF_DESCRIPTOR",
    defaultMessage: "Any photo ID card as proof of identity",
  },
  KNOW_MORE: { id: "KNOW_MORE", defaultMessage: "Know more" },
  SOCIAL_HELP_TEXT: {
    id: "SOCIAL_HELP_TEXT",
    defaultMessage: "How can we help you?",
  },
  SOCIAL_HELP_TEXT_DESCRIPTOR: {
    id: "SOCIAL_HELP_TEXT_DESCRIPTOR",
    defaultMessage:
      "Contact us via social media and our team will get back to you instantly",
  },
  ELIGIBILITY_TEXT: {
    id: "ELIGIBILITY_TEXT",
    defaultMessage: "Check if you qualify with 3 simple questions",
  },
  CHECK_ELIGIBILITY: {
    id: "CHECK_ELIGIBILITY",
    defaultMessage: "Check if you qualify",
  },
  ONLINE: { id: "ONLINE", defaultMessage: "Online" },
  LOAN_PROCESS: { id: "LOAN_PROCESS", defaultMessage: "Loan process" },
  LOAN_DISCLAIMER: {
    id: "LOAN_DISCLAIMER",
    defaultMessage: "Get your loan in just 3 easy steps",
  },
  LOAN_PURPOSE: {
    id: "LOAN_PURPOSE",
    defaultMessage: "Purpose for requested funds",
  },
  ENTER_lOAN_PURPOSE: {
    id: "ENTER_lOAN_PURPOSE",
    defaultMessage: "Enter Purpose for loan",
  },
  LOAN_PURPOSE_ERROR: {
    id: "LOAN_PURPOSE_ERROR",
    defaultMessage: "please provide a purpose for loan",
  },
  LOAN_APPLY: { id: "LOAN_APPLY", defaultMessage: "Apply online" },
  LOAN_APPLY_TIME: { id: "LOAN_APPLY_TIME", defaultMessage: "In just 2 mins" },
  LOAN_TIMER_TEXT: { id: "LOAN_TIMER_TEXT", defaultMessage: "Get decision" },
  LOAN_TIMER_TEXT_TIME: {
    id: "LOAN_TIMER_TEXT_TIME",
    defaultMessage: "Within 20 mins",
  },
  LOAN_FUNDS_TEXT: { id: "LOAN_FUNDS_TEXT", defaultMessage: "Get Funds" },
  LOAN_FUNDS_TEXT_TIME: {
    id: "LOAN_FUNDS_TEXT_TIME",
    defaultMessage: "Within 24 hrs",
  },
  LOAN_PURPOSE_MORTAGAE: {
    id: "LOAN_PURPOSE_MORTAGAE",
    defaultMessage: "Mortgage",
  },
  LOAN_PURPOSE_CELEBRATION: {
    id: "LOAN_PURPOSE_CELEBRATION",
    defaultMessage: "Celebration event",
  },
  LOAN_PURPOSE_MEDICAL: {
    id: "LOAN_PURPOSE_MEDICAL",
    defaultMessage: "Medical emergency",
  },
  LOAN_PURPOSE_FUNERAL: {
    id: "LOAN_PURPOSE_FUNERAL",
    defaultMessage: "Funeral",
  },
  LOAN_PURPOSE_CAR_PURCHASE: {
    id: "LOAN_PURPOSE_CAR_PURCHASE",
    defaultMessage: "Car Purchase",
  },
  LOAN_PURPOSE_CAR_MAINTAINANCE: {
    id: "LOAN_PURPOSE_CAR_MAINTAINANCE",
    defaultMessage: "Car Maintenance",
  },
  LOAN_PURPOSE_EDUCATION_OR_EQUIPMENT: {
    id: "LOAN_PURPOSE_EDUCATION_OR_EQUIPMENT",
    defaultMessage: "Education / Equipment",
  },
  LOAN_PURPOSE_HOUSE_RENOVATION_OR_CONSTRUCTION: {
    id: "LOAN_PURPOSE_HOUSE_RENOVATION_OR_CONSTRUCTION",
    defaultMessage: "House renovation / construction",
  },
  LOAN_PURPOSE_OTHERS: { id: "LOAN_PURPOSE_OTHERS", defaultMessage: "Others" },
  REJECT_PURPOSE: {
    id: "REJECT_PURPOSE",
    defaultMessage: "Select a reason for rejection",
  },
  LOWER_LOAN_OFFER: {
    id: "LOWER_LOAN_OFFER",
    defaultMessage: "Loan offer is much lower than what I requested",
  },
  BETTER_LOAN_OFFER: {
    id: "BETTER_LOAN_OFFER",
    defaultMessage: "I got a better offer with another lender / bank",
  },
  DONOT_LOAN_OFFER: {
    id: "DONOT_LOAN_OFFER",
    defaultMessage: "I don't need the money anymore",
  },
  REJECT_LOAN_OFFER: {
    id: "REJECT_LOAN_OFFER",
    defaultMessage: "Reject loan offer",
  },
  REJECT_LOAN_OFFER_CHECK_TEXT: {
    id: "REJECT_LOAN_OFFER_CHECK_TEXT",
    defaultMessage:
      "You will loose this loan offer.Are you sure want to reject?",
  },
  REJECT_ACTION_NO: {
    id: "REJECT_ACTION_NO",
    defaultMessage: "No,continue to apply",
  },
  REJECT_ACTION_YES: {
    id: "REJECT_ACTION_YES",
    defaultMessage: "Yes, reject offer",
  },
  LOAN_MODAL_UNABLE_TO_SERVE: {
    id: "LOAN_MODAL_UNABLE_TO_SERVE",
    defaultMessage: " We were not able to serve you!",
  },
  COMMON_CONGRATULATIONS: {
    id: "COMMON_CONAGRDTULATIONS",
    defaultMessage: "Congratulations",
  },
  COMMON_COMPLETED_APPLICATION: {
    id: "COMMON_COMPLETED_APPLICATION",
    defaultMessage: "You have completed the application",
  },
  COMMON_GO_TO_MALL: {
    id: "COMMON_GO_TO_MALL",
    defaultMessage: "Go to Digital Mall",
  },
  LOAN_REJECTION_REASON: {
    id: "LOAN_REJECTION_REASON",
    defaultMessage:
      "We have registered your reason for rejection and will work towards providing you better service in the future.",
  },
  LOAN_MODAL_UNABLE_TO_SERVE_PARA: {
    id: "LOAN_MODAL_UNABLE_TO_SERVE_PARA",
    defaultMessage:
      "We have registered your reason for rejection and will work towards providing you better service in the future",
  },
  CONGRATULATION: { id: "CONGRATULATION", defaultMessage: "Congratulations!" },
  CARD_IS_LINKED: {
    id: "CARD_IS_LINKED",
    defaultMessage: "Your card has been linked !",
  },
  QUALIFY: { id: "QUALIFY", defaultMessage: "You qualify!" },
  EMPLOYMENT: { id: "EMPLOYMENT", defaultMessage: "Employment" },
  EMPLOYER: { id: "EMPLOYER", defaultMessage: "Employer" },
  SALARY: { id: "SALARY", defaultMessage: "Salary" },
  MAX_ELIGIBLE_AMNT: {
    id: "MAX_ELIGIBLE_AMNT",
    defaultMessage: "Maximum eligible amount",
  },
  MAX_ELIGIBLE_EMI: {
    id: "MAX_ELIGIBLE_EMI",
    defaultMessage: "You have a maximum EMI eligibility of up to {maxEmi}",
  },
  LOAN_AMOUNT_ERROR: {
    id: "LOAN_AMOUNT_ERROR",
    defaultMessage:
      "Please enter amount between {symbol} {min} to {symbol} {max}",
  },
  TENURE_RANGE_ERROR: {
    id: "TENURE_RANGE_ERROR",
    defaultMessage: "Please enter tenure between {min} to {max}",
  },
  TENURE_TEXT: { id: "TENURE_TEXT", defaultMessage: "Tenure" },
  TENURE_IN_MONTHS: {
    id: "TENURE_IN_MONTHS",
    defaultMessage: "{tenure} Months",
  },
  TENUREMONTHS_TEXT: { id: "TENUREMONTHS_TEXT", defaultMessage: "months" },
  TENURE_SLIDER_DRAG_TEXT: {
    id: "TENURE_SLIDER_DRAG_TEXT",
    defaultMessage:
      "Drag the slider to select the tenure as per your preference between {min} month to {max} months",
  },
  ELIGIBLE_HIGHER_AMOUNT_TEXT: {
    id: "ELIGIBLE_HIGHER_AMOUNT_TEXT",
    defaultMessage: "Great! You are now eligible for more than what you asked",
  },
  ELIGIBLE_LOWER_AMOUNT_TEXT: {
    id: "ELIGIBLE_LOWER_AMOUNT_TEXT",
    defaultMessage:
      "You qualify for a lower amount based on your employment details",
  },
  MONTHLY_PAYMENT_TEXT: {
    id: "MONTHLY_PAYMENT_TEXT",
    defaultMessage: "Monthly Payment",
  },
  AMOUNT_REPAY_TEXT: {
    id: "AMOUNT_REPAY_TEXT",
    defaultMessage: "Amount to repay",
  },
  INTEREST_RATE: { id: "INTEREST_RATE", defaultMessage: "Interest Rate" },
  INTEREST_RATE_PERCENT: {
    id: "INTEREST_RATE_PERCENT",
    defaultMessage: "{interestRate} %",
  },
  MONTHLY_PAYMENT_CALCULATION_TEXT: {
    id: "MONTHLY_PAYMENT_CALCULATION_TEXT",
    defaultMessage:
      "The above calculation may vary based on documents provided",
  },
  START_APPLICATION: {
    id: "START_APPLICATION",
    defaultMessage: "Start Application",
  },
  THANK_YOU: { id: "THANK_YOU", defaultMessage: "Thank you!" },
  CONTINUE_APPLY: { id: "CONTINUE_APPLY", defaultMessage: "Continue to apply" },
  APPLY_FOR_TEXT: {
    id: "APPLY_FOR_TEXT",
    defaultMessage: "You are applying for",
  },
  DISBURSEMENT_TEXT: {
    id: "DISBURSEMENT_TEXT",
    defaultMessage: "Disbursement amount",
  },
  COMPLETE_APPLICATION_DISCLAIMER: {
    id: "COMPLETE_APPLICATION_DISCLAIMER",
    defaultMessage: "It will just takes few minutes to finish your application",
  },
  DISCARD: { id: "DISCARD", defaultMessage: "Discard" },
  DISCARD_APPLICATION: {
    id: "DISCARD_APPLICATION",
    defaultMessage: "Discard application",
  },
  DISCARD_APPLICATION_CHECK_TEXT: {
    id: "DISCARD_APPLICATION_CHECK_TEXT",
    defaultMessage: "Are you sure you want to delete your application?",
  },
  DISCARD_APPLICATION_CHECK_SUBTEXT: {
    id: "DISCARD_APPLICATION_CHECK_SUBTEXT",
    defaultMessage: "It will reject all the progress so far",
  },
  DISCARD_ACTION_NO: {
    id: "DISCARD_ACTION_NO",
    defaultMessage: "No, I wish to continue",
  },
  DISCARD_ACTION_YES: {
    id: "DISCARD_ACTION_YES",
    defaultMessage: "Yes, delete my application",
  },
  CONTINUE_APPLICATION: {
    id: "CONTINUE_APPLICATION",
    defaultMessage: "Continue application",
  },
  RESUME_APLICATION: {
    id: "RESUME_APLICATION",
    defaultMessage: "Resume application",
  },
  RESUME_APLICATION_LATER: {
    id: "RESUME_APLICATION_LATER",
    defaultMessage: "Resume Later",
  },
  NOT_ELIGIBLE_LOAN_INFO: {
    id: "NOT_ELIGIBLE_LOAN_INFO",
    defaultMessage:
      "Based on your details, we can't offer you a payroll loan but don't worry, our team will get in touch with you to help.",
  },
  NOT_ELIGIBLE_EXPLORE_TITLE: {
    id: "NOT_ELIGIBLE_EXPLORE_TITLE",
    defaultMessage: "We got you covered!",
  },
  NOT_ELIGIBLE_EXPLORE_SUBTITLE: {
    id: "NOT_ELIGIBLE_EXPLORE_SUBTITLE",
    defaultMessage: "Explore other Letshego lending solutions.",
  },
  EXPLORE_MORE_TEXT: {
    id: "EXPLORE_MORE_TEXT",
    defaultMessage: "Explore more",
  },
  NO_PRODUCTS_TEXT: {
    id: "NO_PRODUCTS_TEXT",
    defaultMessage:
      "We do not have any products for you now. we are working towards serving all  your needs.",
  },
  LOAN_DASHBOARD_MAX_EMI_ALERT: {
    id: "LOAN_DASHBOARD_MAX_EMI_ALERT",
    defaultMessage:
      "Please increase tenure or reduce amount to borrow, to keep EMI below {maxEmi}",
  },
  RECALCULATE_ELIGIBILITY: {
    id: "RECALCULATE_ELIGIBILITY",
    defaultMessage: "Recalculate eligibility",
  },
  LOAN_DEAILS: { id: "LOAN_DEAILS", defaultMessage: "Loan details" },
  INSTANT_LOAN_HEADER_1: {
    id: "INSTANT_LOAN_HEADER_ONE",
    defaultMessage: "Apply for loan up to",
  },
  INSTANT_LOAN_MAX_AMOUNT_TEXT: {
    id: "INSTANT_LOAN_MAX_AMOUNT_TEXT",
    defaultMessage: "Maximum Amount",
  },
  INSTANT_LOAN_DURATION_TEXT: {
    id: "INSTANT_LOAN_DURATION_TEXT",
    defaultMessage: "Loan Duration",
  },
  INSTANT_LOAN_INTEREST_TEXT: {
    id: "INSTANT_LOAN_INTEREST_TEXT",
    defaultMessage: "Rate of Interest",
  },
  INSTANT_LOAN_REPAYMENT_TEXT: {
    id: "INSTANT_LOAN_REPAYMENT_TEXT",
    defaultMessage: "Repayment Amount",
  },
  INTANT_LOAN_DETAILS_HEADER: {
    id: "INTANT_LOAN_DETAILS_HEADER",
    defaultMessage: "LetsGo {productName}",
  },
  INSTANT_LOAN_CONFIRM_TITLE: {
    id: "INSTANT_LOAN_CONFIRM_TITLE",
    defaultMessage: "Confirm",
  },
  INSTANT_LOAN_CONFIRM_SUB_TITLE: {
    id: "INSTANT_LOAN_CONFIRM_SUB_TITLE",
    defaultMessage: "your details!",
  },
  INSTANT_LOAN_LOAN_DETAILS_TAP_1: {
    id: "INSTANT_LOAN_LOAN_DETAILS_TAP_1",
    defaultMessage: "Loan Details",
  },
  INSTANT_LOAN_LOAN_DETAILS_TAP_2: {
    id: "INSTANT_LOAN_LOAN_DETAILS_TAP_2",
    defaultMessage: "Final decision",
  },
  INSTANT_LOAN_CONFIRM_DETAILS_QTN_TEXT: {
    id: "INSTANT_LOAN_CONFIRM_DETAILS_QTN_TEXT",
    defaultMessage: "Have any of your personal details changed?",
  },
  INSTANT_LOAN_NATIONAL_ID_HAS_EXPIRED: {
    id: "INSTANT_LOAN_NATIONAL_ID_HAS_EXPIRED",
    defaultMessage: "National ID has expired",
  },
  INSTANT_LOAN_NATIONAL_ID_HAS_EXPIRED_TEXT_1: {
    id: "INSTANT_LOAN_NATIONAL_ID_HAS_EXPIRED_TEXT_1",
    defaultMessage:
      "We are sorry. We won’t be able to proceed with your application as your National ID did has expired.",
  },
  /*Instant Loan sprint-PCR.2.2 */
  INSTANT_LOAN_TENURE: {
    id: "INSTANT_LOAN_TENURE",
    defaultMessage: "Loan Period",
  },
  INSTANT_LOAN_FEES: {
    id: "INSTANT_LOAN_FEES",
    defaultMessage: "Application Fees",
  },
  INSTANT_LOAN_ERROR_OCCURED: {
    id: "INSTANT_LOAN_ERROR_OCCURED",
    defaultMessage: "An error has occured",
  },
  INSTANT_LOAN_NOT_ABLE_TO_VERIFY_NECESSARY_DEATILS: {
    id: "INSTANT_LOAN_NOT_ABLE_TO_VERIFY_NECESSARY_DEATILS",
    defaultMessage:
      "We are sorry. We were not able to identify your necessary details.",
  },
  INSTANT_LOAN_NOT_ABLE_TO_PROCEED: {
    id: "INSTANT_LOAN_NOT_ABLE_TO_PROCEED",
    defaultMessage:
      "We are sorry. We won’t be able to proceed with your application as your picture and National ID couldn't be validated.",
  },
  INSTANT_LOAN_NOT_ABLE_TO_PROCEED_EXPIRED_ID: {
    id: "INSTANT_LOAN_NOT_ABLE_TO_PROCEED_EXPIRED_ID",
    defaultMessage:
      "We are sorry. We won’t be able to proceed with your application as your National ID did has expired.",
  },
  INSTANT_LOAN_OCR_REUPLOAD_ERROR: {
    id: "INSTANT_LOAN_OCR_REUPLOAD_ERROR",
    defaultMessage:
      "Please re-upload a better quality front and back image of the National ID.",
  },
  INSTANT_LOAN_OCR_VERIFCATION_SUCCESS_DONE: {
    id: "INSTANT_LOAN_OCR_VERIFCATION_SUCCESS_DONE",
    defaultMessage: "ID verification successful",
  },
  INSTANT_LOAN_OCR_VERIFCATION_SUCCESS_WELLDONE: {
    id: "INSTANT_LOAN_OCR_VERIFCATION_SUCCESS_WELLDONE",
    defaultMessage: "Well done!",
  },
  INSTANT_LOAN_OCR_ERROR_MISMATCH: {
    id: "INSTANT_LOAN_OCR_ERROR_MISMATCH",
    defaultMessage: "That's mismatch",
  },
  INSTANT_LOAN_OCR_ERROR_REUPLOAD: {
    id: "INSTANT_LOAN_OCR_ERROR_REUPLOAD",
    defaultMessage: "Please re-upload for smooth application.",
  },
  INSTANT_LOAN_VERIFCATION_ERROR: {
    id: "INSTANT_LOAN_VERIFCATION_ERROR",
    defaultMessage: "National ID has expired",
  },
  INSTANT_LOAN_VERIFCATION_SUCCESS: {
    id: "INSTANT_LOAN_VERIFCATION_SUCCESS",
    defaultMessage:
      "That’s a match! Now proceed further to complete a few details for fast disbursement.",
  },
  /*Instant Loan sprint-PCR.2.2 */
  INSTANT_LOAN_NATIONAL_ID_HAS_EXPIRED_TEXT_2: {
    id: "INSTANT_LOAN_NATIONAL_ID_HAS_EXPIRED_TEXT_2",
    defaultMessage:
      "Kindly get in touch with our team and we promise they will help you out.",
  },
  INSTANT_LOAN_NATIONAL_ID_HAS_MISMATCH: {
    id: "INSTANT_LOAN_NATIONAL_ID_HAS_MISMATCH",
    defaultMessage: "That’s a mismatch",
  },
  INSTANT_LOAN_NATIONAL_ID_HAS_MISMATCH_TEXT_1: {
    id: "INSTANT_LOAN_NATIONAL_ID_HAS_MISMATCH_TEXT_1",
    defaultMessage:
      "We are sorry. We won’t be able to proceed with your application as your picture and National ID did not match.",
  },
  INSTANT_LOAN_NATIONAL_ID_HAS_MISMATCH_TEXT_2: {
    id: "INSTANT_LOAN_NATIONAL_ID_HAS_MISMATCH_TEXT_2",
    defaultMessage:
      "Kindly get in touch with our team and we promise they will help you out.",
  },
  MONTHLY_INSTALLMENT: {
    id: "MONTHLY_INSTALLMENT",
    defaultMessage: "Monthly Installment",
  },
  MONTHS_TO_REPAY: { id: "MONTHS_TO_REPAY", defaultMessage: "Months to repay" },
  RATE_OF_INTEREST: {
    id: "RATE_OF_INTEREST",
    defaultMessage: "Rate of interest",
  },
  UPDATE_LOAN_TEXT: {
    id: "UPDATE_LOAN_TEXT",
    defaultMessage: "Update loan request",
  },
  LOAN_AMOUNT_DISCLAIMER_TEXT: {
    id: "LOAN_AMOUNT_DISCLAIMER_TEXT",
    defaultMessage:
      "Enter the loan amount to your needs between {min} to {max}",
  },
  MONTHS_TO_REPAY_DISCLAIMER: {
    id: "MONTHS_TO_REPAY_DISCLAIMER",
    defaultMessage:
      "Enter your preferred tenure between {min} and {max} months",
  },
  UPDATE: { id: "UPDATE", defaultMessage: "Update" },
  LOAN_MAX_EMI_ELIGIBILITY_ALERT: {
    id: "LOAN_MAX_EMI_ELIGIBILITY_ALERT",
    defaultMessage: "You have a maximum EMI eligibility of up to {max}",
  },
  LOAN_MIN_AMOUNT_ERROR: {
    id: "LOAN_MIN_AMOUNT_ERROR",
    defaultMessage: "Please enter amount above {min}",
  },
  LOAN_MAX_AMOUNT_ERROR: {
    id: "LOAN_MAX_AMOUNT_ERROR",
    defaultMessage: "Please enter amount up to {max}",
  },
  LOAN_MAX_AMOUNT_MSG: {
    id: "LOAN_MAX_AMOUNT_MSG",
    defaultMessage:
      " Based on the information provided, you are eligible for {amount}",
  },
  LOAN_MIN_TENURE_ERROR: {
    id: "LOAN_MIN_TENURE_ERROR",
    defaultMessage: "Please select tenure above {min} months",
  },
  LOAN_MAX_TENURE_ERROR: {
    id: "LOAN_MAX_TENURE_ERROR",
    defaultMessage: "Please select tenure up to {max} months",
  },
  NOT_ELIGIBLE_EXPLORE_SORRY: {
    id: "NOT_ELIGIBLE_EXPLORE_SORRY",
    defaultMessage: "Sorry!",
  },
  NOT_ELIGIBLE_EXPLORE_NOT_APPLY: {
    id: "NOT_ELIGIBLE_EXPLORE_NOT_APPLY",
    defaultMessage: "You are not eligible for a payroll loan",
  },
  VERIFY_YOUR_IDENTITY: {
    id: "VERIFY_YOUR_IDENTITY",
    defaultMessage: "Verify your identity",
  },
  BVN_ENTRY_TEXT: {
    id: "BVN_ENTRY_TEXT",
    defaultMessage: "Enter your BVN number to confirm your identity",
  },
  SELFIE_VERIFCATION_MODAL_TITLE: {
    id: "SELFIE_VERIFCATION_MODAL_TITLE",
    defaultMessage: "Enter your details <custom>ID Details</custom>",
  },
  BVN: { id: "BVN", defaultMessage: "BVN" },
  BVN_CUSTOMER_CARE: {
    id: "BVN_CUSTOMER_CARE",
    defaultMessage:
      "To get your BVN dial <*565*0#> from your registered mobile number.",
  },
  BVN_CONSENT_INFORMATION: {
    id: "BVN_CONSENT_INFORMATION",
    defaultMessage:
      "Please note by clicking ‘Validate BVN’ you are giving consent to obtain information from relevant third parties about you, to make a decision on your application",
  },
  WHY_BVN: { id: "WHY_BVN", defaultMessage: "Why do we ask for BVN?" },
  BVN_DISCLAIMER: {
    id: "BVN_DISCLAIMER",
    defaultMessage:
      "To confirm your identity on Letshego, you need to connect your BVN.",
  },
  BVN_DISCLAIMER_DETAIL: {
    id: "BVN_DISCLAIMER_DETAIL",
    defaultMessage:
      "This just enables Letshego to confirm your identity with the help of your bank and does NOT give any access to your bank information/balances .",
  },
  VALIDATE_BVN: { id: "VALIDATE_BVN", defaultMessage: "Validate BVN" },
  TRY_AGAIN_LATER: { id: "TRY_AGAIN_LATER", defaultMessage: "Try again later" },
  ERROR_OCCURED_IN_SENDING_MESSAGE: {
    id: "ERROR_OCCURED_IN_SENDING_MESSAGE",
    defaultMessage: "Error occurred in sending SMS . Please try again later !",
  },
  BVN_VALIDATION_ERROR: {
    id: "BVN_VALIDATION_ERROR",
    defaultMessage: "Please enter a Valid BVN",
  },
  BVN_VALIDATE_ERROR_TEXT: {
    id: "BVN_VALIDATE_ERROR_TEXT",
    defaultMessage: "We were not able to validate your BVN",
  },
  BVN_ERROR_CUSTOMER_CARE: {
    id: "BVN_ERROR_CUSTOMER_CARE",
    defaultMessage:
      "Please contact our customer care from 8am - 4pm on {customerCare}.",
  },
  BVN_VALIDATION_FAILED: {
    id: "BVN_VALIDATION_FAILED",
    defaultMessage: "BVN validation failed",
  },
  BVN_ALREADY_REGISTERED_DISCLAIMER: {
    id: "BVN_ALREADY_REGISTERED_DISCLAIMER",
    defaultMessage:
      "Your BVN and mobile number is already registered with us. \n                    Redirecting  to your personalised dashboard.\n                    <custom>Please do not refresh or go back</custom>",
  },
  DO_NOT_REFRESH: {
    id: "DO_NOT_REFRESH",
    defaultMessage: "Please do not refresh or go back",
  },
  TRY_LATER: { id: "TRY_LATER", defaultMessage: "Try later" },
  TRY_AGAIN: { id: "TRY_AGAIN", defaultMessage: "Try again" },
  BACK_TO_HOME: { id: "BACK_TO_HOME", defaultMessage: "Back to home" },
  ENTER_PASSWORD_TEXT: {
    id: "ENTER_PASSWORD_TEXT",
    defaultMessage: "Please enter your Letshego password to proceed",
  },
  ENTER_OTP_TEXT: {
    id: "ENTER_OTP_TEXT",
    defaultMessage:
      "Enter the OTP sent on your registered mobile number {phone}",
  },
  SAVIGNS_SELFIE_WITH_ID: {
    id: "SAVIGNS_SELFIE_WITH_ID",
    defaultMessage: "Selfie with {id}",
  },
  SAVINGS_ENTER_OTP_TEXT: {
    id: "SAVINGS_ENTER_OTP_TEXT",
    defaultMessage: "Enter the OTP sent to {phone}",
  },
  REVIEW_LOAN_REQUEST: {
    id: "REVIEW_LOAN_REQUEST",
    defaultMessage: "Review loan request",
  },
  ALMOST: { id: "ALMOST", defaultMessage: "Almost" },
  LOAN_SUMMARY_REVIEW_SUBMIT: {
    id: "LOAN_SUMMARY_REVIEW_SUBMIT",
    defaultMessage:
      "Please enter your reason to borrow the funds and review your loan request before submitting",
  },
  LOAN_SUMMARY_REVIEW_SUBMIT_EC: {
    id: "LOAN_SUMMARY_REVIEW_SUBMIT_EC",
    defaultMessage: "Please review your loan request before submitting",
  },
  LOAN_AMOUNT: { id: "LOAN_AMOUNT", defaultMessage: "Loan Amount" },
  LOAN_AMOUNT_PART: {
    id: "LOAN_AMOUNT_PART",
    defaultMessage: "Approved Loan amount",
  },
  TOTAL_PAID_PART: {
    id: "TOTAL_PAID_PART",
    defaultMessage: "Disbursed Loan amount",
  },
  LOAN_SUMMARY_ALERT_MESSAAGE: {
    id: "LOAN_SUMMARY_ALERT_MESSAAGE",
    defaultMessage: "The final amount may varry based on document verification",
  },
  WHY_INSURANCE: { id: "WHY_INSURANCE", defaultMessage: "Why insurance?" },
  LOAN_SUMMARY_INSURANCE_TEXT: {
    id: "LOAN_SUMMARY_INSURANCE_TEXT",
    defaultMessage:
      "The one time fee is a sum of insurance and loan administration fees. Insurance covers you in the event that you are not able to pay for the loan due to unforeseen life circumstances such as death or disability.",
  },
  LOAN_OFFER_INSURANCE_TEXT: {
    id: "LOAN_OFFER_INSURANCE_TEXT",
    defaultMessage:
      "LetsGo insurance covers you in the event that you are not able to pay your loan due to unforeseen life’s circumstances such as death and disability.",
  },
  LOAN_SUMMARY_TERM_AND_CONDITION_TEXT: {
    id: "LOAN_SUMMARY_TERM_AND_CONDITION_TEXT",
    defaultMessage:
      "I allow Letshego to asses my personal and financial information for a payroll loan and accept all",
  },
  LOAN_SUMMARY_TERM_AND_CONDITION_TEXT_BW_IND_LOANS: {
    id: "LOAN_SUMMARY_TERM_AND_CONDITION_TEXT_BW_IND_LOANS",
    defaultMessage:
      "I allow Letshego to asses my personal and financial information for a Letsgo Motshegetsi loan and accept all",
  },
  LOAN_SUMMARY_TERM_AND_CONDITION_TEXT_NG_IND_LOANS: {
    id: "LOAN_SUMMARY_TERM_AND_CONDITION_TEXT_NG_IND_LOANS",
    defaultMessage:
      "I allow Letshego to asses my personal and financial information for a Easy loan and accept all",
  },
  LOAN_SUMMARY_TERM_AND_CONDITION_TEXT_NA_IND_LOANS: {
    id: "LOAN_SUMMARY_TERM_AND_CONDITION_TEXT_NA_IND_LOANS",
    defaultMessage:
      "I allow Letshego to asses my personal and financial information for a Personal loan and accept all",
  },
  SAVINGS_TERMS_AND_CONDITION_TEXT: {
    id: "SAVINGS_TERMS_AND_CONDITION_TEXT",
    defaultMessage:
      "I allow Letshego to access my personal information for account opening. I accept all {link}",
  },
  SAVINGS_ACKNOWLEDGEMENT_TEXT: {
    id: "SAVINGS_ACKNOWLEDGEMENT_TEXT",
    defaultMessage:
      "I certify that all the information provided by me for this FlexiSave account application is correct.",
  },
  SAVINGS_NEW_ACCOUNT_TITLE: {
    id: "SAVINGS_NEW_ACCOUNT_TITLE",
    defaultMessage: "FlexiSave account",
  },
  LETSGO_SAVINGS_ACCOUNT: {
    id: "LETSGO_SAVINGS_ACCOUNT",
    defaultMessage: "LetsGo FlexiSave account",
  },
  LOAN_SUMMARY_CERTIFIED_TEXT: {
    id: "LOAN_SUMMARY_CERTIFIED_TEXT",
    defaultMessage:
      "I certify that all the information provided by me for this loan application is true and correct.",
  },
  SUBMIT_APPLICATION: {
    id: "SUBMIT_APPLICATION",
    defaultMessage: "Submit application",
  },
  EDIT_APPLICATION: {
    id: "EDIT_APPLICATION",
    defaultMessage: "Edit application",
  },
  ADDITIONAL_CHARGES: {
    id: "ADDITIONAL_CHARGES",
    defaultMessage: "Additional charges",
  },
  EARLY_REPAYMENT_CHARGES: {
    id: "EARLY_REPAYMENT_CHARGES",
    defaultMessage: "Early repayment charges",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_HEADER: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_HEADER",
    defaultMessage:
      "If the borrower pays a loan in full before it's expiry, the borrower will be charged: ",
  },
  LOAN_SUMMARY_ADDITION_CHARGES_PENALTY: {
    id: "LOAN_SUMMARY_ADDITION_CHARGES_PENALTY",
    defaultMessage:
      "Penalty of <custom> 5% </custom> ( early loan settlement penalty)",
  },
  LOAN_SUMMARY_ADDITION_CHARGES_PENALTY_INTEREST: {
    id: "LOAN_SUMMARY_ADDITION_CHARGES_PENALTY_INTEREST",
    defaultMessage:
      "This penalty is on  <custom> outstanding balance and interest </custom> adjustments that may apply",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_TERM_TEXT: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_TERM_TEXT",
    defaultMessage:
      "Full terms and conditions are explained in the loan agreement and loan schedule provided to the borrower.",
  },
  LOAN_SUMMARY_ADDITIONAL_TERM_TEXT: {
    id: "LOAN_SUMMARY_ADDITIONAL_TERM_TEXT",
    defaultMessage:
      "This website has been established by Letshego Holdings Limited and its subsidiaries (the Group) for the sole purpose of conveying information about the Group’s products and services and to allow communication between the Group, its customers and its stakeholders. Information that appears on this website should be considered an advertisement. Nothing contained in any page on this site takes the place of the Group’s agreements and disclosures that govern its products and services. If any information on the site conflicts with that in the Group’s agreements and disclosures, the agreements and disclosures will take precedence.From time to time the Group may place links to other websites and / or digital content on this page. The Group has no control over any other website and / or digital content, and is not responsible for the content on any site other than this one. Users assume all responsibility when they go to other sites and / or digital content via the links on this page.The information and materials contained in this website are owned by the Group or by others, as applicable. No material may be copied, displayed, transmitted, distributed, framed, sold, stored for use, downloaded, or otherwise reproduced except as permitted by law.The use of this website and all content contained herein is governed by the laws of Botswana and all other countries in which Letshego subsidiaries are registered institutions.",
  },
  LOAN_SUMMARY_ONE_TIME_TEXT: {
    id: "LOAN_SUMMARY_ONE_TIME_TEXT",
    defaultMessage: "One Time fees",
  },
  LOAN_SUMMARY_ADMIN_FEES: {
    id: "LOAN_SUMMARY_ADMIN_FEES",
    defaultMessage: "Admin fee",
  },
  MZ_LOAN_SUMMARY_ADMIN_FEES: {
    id: "MZ_LOAN_SUMMARY_ADMIN_FEES",
    defaultMessage: "Admin Fee Commission (MZN)",
  },
  LOAN_SUMMARY_PROCESSING_FEES_TEXT: {
    id: "LOAN_SUMMARY_PROCESSING_FEES_TEXT",
    defaultMessage: "Processing fees",
  },
  LOAN_SUMMARY_INSURANCE_FEES_TEXT: {
    id: "LOAN_SUMMARY_INSURANCE_FEES_TEXT",
    defaultMessage: "Insurance",
  },
  MZ_LOAN_SUMMARY_INSURANCE_FEES_TEXT: {
    id: "MZ_LOAN_SUMMARY_INSURANCE_FEES_TEXT",
    defaultMessage: "Admin Fee Commission (%)",
  },
  LOAN_SUMMARY_MONTHLY_FEES_TEXT: {
    id: "LOAN_SUMMARY_MONTHLY_FEES_TEXT",
    defaultMessage: "Monthly fees",
  },
  LOAN_SUMMARY_OTHER_FEES_TEXT: {
    id: "LOAN_SUMMARY_OTHER_FEES_TEXT",
    defaultMessage: "Other fees",
  },
  LOAN_OFFER_INSURANCE_FEES_TEXT: {
    id: "LOAN_OFFER_INSURANCE_FEES_TEXT",
    defaultMessage: "LetsGo insurance",
  },
  LOAN_SUMMARY_INITIATION_FEE_TEXT: {
    id: "LOAN_SUMMARY_INITIATION_FEE_TEXT",
    defaultMessage: "Initiation Fee",
  },
  LOAN_SUMMARY_FEES_ADDITIONAL_TEXT_ONE_TIME_FEES: {
    id: "LOAN_SUMMARY_FEES_ADDITIONAL_TEXT_ONE_TIME_FEES",
    defaultMessage: "One time fees and ",
  },
  LOAN_SUMMARY_FEES_ADDITIONAL_TEXT: {
    id: "LOAN_SUMMARY_FEES_ADDITIONAL_TEXT",
    defaultMessage: "<custom>Early repayment charges</custom> may apply.",
  },
  LOAN_OFFER_FEES_ADDITIONAL_TEXT: {
    id: "LOAN_OFFER_FEES_ADDITIONAL_TEXT",
    defaultMessage:
      "<custom>Additional Charges</custom> apply for early repayment.",
  },
  LOAN_OFFER_FEES_VIEW_DETAILS: {
    id: "LOAN_OFFER_FEES_VIEW_DETAILS",
    defaultMessage:
      "<custom>Additional Charges</custom> apply for early repayment.",
  },
  LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT: {
    id: "LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT",
    defaultMessage: "One time fees upto",
  },
  LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT_HELP_TEXT: {
    id: "LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT_HELP_TEXT",
    defaultMessage: "including processing fees and Letshego insurance",
  },
  LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT_CONDITION: {
    id: "LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT_CONDITION",
    defaultMessage:
      "The above calculations are indicative and final amounts will be provided with the loan offer.",
  },
  LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT_CONDITION_BOTSWANA: {
    id: "LOAN_SUMMARY_ONE_TIME_FEE_AMOUNT_CONDITION_BOTSWANA",
    defaultMessage:
      "All of the above calculations are indicative. The final amounts will be provided with the loan offer.",
  },
  LOAN_SUMMARY_EARLY_REPLAYMENT: {
    id: "LOAN_SUMMARY_EARLY_REPLAYMENT",
    defaultMessage:
      " <custom>Additional Charges</custom> apply for early repayment",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGE_TEXT1: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGE_TEXT1",
    defaultMessage: "apply for early repayment",
  },
  LOAN_SUMMARY_RECALCULATING_ELIGIBILITY: {
    id: "LOAN_SUMMARY_RECALCULATING_ELIGIBILITY",
    defaultMessage: "We are calculating your updated eligibility.",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_HEADER_MZ: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_HEADER_MZ",
    defaultMessage:
      "Additional fees will be applied in case of early settlements: ",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE1_MZ: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE1_MZ",
    defaultMessage:
      "Commission equivalent to <custom> 150 days of interest </custom>.",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE2_MZ: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE2_MZ",
    defaultMessage: "Calculated based on the daily interest rate.",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE3_MZ: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE3_MZ",
    defaultMessage: "Focusing on the total balance of remaining capital.",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_EXTRA_INFO_MZ: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_EXTRA_INFO_MZ",
    defaultMessage: "For more information terms and conditions applied",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE1_LS: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE1_LS",
    defaultMessage:
      "Penalty charged is for 3 months interest on the current outstanding loan balance.",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE2_LS: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE2_LS",
    defaultMessage:
      "One-month notice settlement when served has no penalty fees, however it is charged on outstanding balance and interest.",
  },
  LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE2_TZ1: {
    id: "LOAN_SUMMARY_ADDITIONAL_CHARGES_RULE2_TZ1",
    defaultMessage:
      "Penalty of <custom> 8% </custom> ( early loan settlement penalty)",
  },
  LOAN_SUMMARY_INSURANCE_HEADER_MZ: {
    id: "LOAN_SUMMARY_INSURANCE_HEADER_MZ",
    defaultMessage:
      "This Loan is associated with Insurance that covers only in cases of:",
  },
  LOAN_SUMMARY_INSURANCE_RULE1_MZ: {
    id: "LOAN_SUMMARY_INSURANCE_RULE1_MZ",
    defaultMessage: "Death.",
  },
  LOAN_SUMMARY_INSURANCE_RULE2_MZ: {
    id: "LOAN_SUMMARY_INSURANCE_RULE2_MZ",
    defaultMessage: "Permanent disability.",
  },
  LOAN_SUMMARY_INSURANCE_SUBTEXT_MZ: {
    id: "LOAN_SUMMARY_INSURANCE_SUBTEXT_MZ",
    defaultMessage:
      "It does not change the  approved & disbursed Loan value, because its cost is included on your monthly installment.",
  },
  VIEW_DETAILS: { id: "VIEW_DETAILS", defaultMessage: "View Details" },
  VIEW_ADDITIONAL_DETAILS: {
    id: "VIEW_ADDITIONAL_DETAILS",
    defaultMessage: "<custom>view details</custom>",
  },
  PROFILE_UPDATE_THANK_YOU_MSG: {
    id: "PROFILE_UPDATE_THANK_YOU_MSG",
    defaultMessage: "Thank you for updating your information!",
  },
  PROFILE_UPDATE_THANK_YOU_SUB_MSG: {
    id: "PROFILE_UPDATE_THANK_YOU_SUB_MSG",
    defaultMessage:
      "We have recalculated your eligibility based on the information provided.",
  },
  YOUR_APPLICATION_IS: {
    id: "YOUR_APPLICATION_IS",
    defaultMessage: "Your application is",
  },
  APPLICATION_INITIATED: {
    id: "APPLICATION_INITIATED",
    defaultMessage: "Application is initiated",
  },
  AMOUNT_TEXT: { id: "AMOUNT_TEXT", defaultMessage: "Amount" },
  INSTALLMENT_TEXT: { id: "INSTALLMENT_TEXT", defaultMessage: "Installment" },
  LOANAPPLICATION_SUCCESS_INFO: {
    id: "LOANAPPLICATION_SUCCESS_INFO",
    defaultMessage:
      "Our customer care team will contact on your registered mobile number { phoneNumber } for additional information.",
  },
  TRACK_YOUR_APPLICATION: {
    id: "TRACK_YOUR_APPLICATION",
    defaultMessage: "Track your application",
  },
  YOUR_APPLICATION: {
    id: "YOUR_APPLICATION",
    defaultMessage: "Your application",
  },
  SUBMISSION_FAILED: {
    id: "SUBMISSION_FAILED",
    defaultMessage: "Submission failed!",
  },
  SUBMISSION_TEXT: {
    id: "SUBMISSION_TEXT",
    defaultMessage: "We are unable to submit your request at the moment.",
  },
  APPLICATION_SAVE_TEXT: {
    id: "APPLICATION_SAVE_TEXT",
    defaultMessage:
      "Your application has been saved on your home screen. Please try again after sometime.",
  },
  CHECK_YOUR_OFFER: {
    id: "CHECK_YOUR_OFFER",
    defaultMessage: "Check your offer",
  },
  CHECKING_YOUR_OFFER: {
    id: "CHECKING_YOUR_OFFER",
    defaultMessage: "Check your offer...",
  },
  COMMON_MALE: { id: "COMMON_MALE", defaultMessage: "Male" },
  COMMON_FEMALE: { id: "COMMON_FEMALE", defaultMessage: "Female" },
  COMMON_MARRIED: { id: "COMMON_MARRIED", defaultMessage: "Married" },
  COMMON_SINGLE: { id: "COMMON_SINGLE", defaultMessage: "Single" },
  COMMON_DIVORCED: { id: "COMMON_DIVORCED", defaultMessage: "Divorced" },
  COMMON_WIDOW: { id: "COMMON_WIDOW", defaultMessage: "Widower/Widow" },
  COMMON_OTHER: { id: "COMMON_OTHER", defaultMessage: "Other" },
  COMMON_MARRIAGE_COMMUNITY: {
    id: "COMMON_MARRIAGE_COMMUNITY",
    defaultMessage: "Community of property",
  },
  COMMON_MARRIAGE_OTHERS: {
    id: "COMMON_MARRIAGE_OTHERS",
    defaultMessage: "Others",
  },
  COMMON_EMPLOYMENTSTATUS_PERMANENTLY: {
    id: "COMMON_EMPLOYMENTSTATUS_PERMANENTLY",
    defaultMessage: "Employed permanently",
  },
  COMMON_EMPLOYMENTSTATUS_FULLTIME: {
    id: "COMMON_EMPLOYMENTSTATUS_PERMANENTLY",
    defaultMessage: "Full time employed",
  },
  LETSGO_FEEDBACK_REFER_FRIEND_QUESTION: {
    id: "LETSGO_FEEDBACK_REFER_FRIEND_QUESTION",
    defaultMessage: "Would you recommend us to your friends and families?",
  },
  LETSGO_FEEDBACK_REFER_FRIEND_NO: {
    id: "LETSGO_FEEDBACK_REFER_FRIEND_NO",
    defaultMessage: "Why not?",
  },
  COMMON_EMPLOYMENTSTATUS_PARTTIME: {
    id: "COMMON_EMPLOYMENTSTATUS_PARTTIME",
    defaultMessage: "Part time employed",
  },
  COMMON_EMPLOYMENTSTATUS_STUDENT: {
    id: "COMMON_EMPLOYMENTSTATUS_STUDENT",
    defaultMessage: "Student",
  },
  COMMON_EMPLOYMENTSTATUS_CONTRACT: {
    id: "COMMON_EMPLOYMENTSTATUS_CONTRACT",
    defaultMessage: "Employed on contract",
  },
  COMMON_EMPLOYMENTSTATUS_SELF: {
    id: "COMMON_EMPLOYMENTSTATUS_SELF",
    defaultMessage: "Self Employed",
  },
  COMMON_EMPLOYMENTSTATUS_UNEMPLOYED: {
    id: "COMMON_EMPLOYMENTSTATUS_UNEMPLOYED",
    defaultMessage: "Unemployed",
  },
  COMMON_EMPLOYMENTSTATUS_RETIRED: {
    id: "COMMON_EMPLOYMENTSTATUS_RETIRED",
    defaultMessage: "Retired",
  },
  COMMON_HIGHEST_QUALIFICATION_POST_GRADUATE: {
    id: "COMMON_HIGHEST_QUALIFICATION_POST_GRADUATE",
    defaultMessage: "Post-Graduate",
  },
  COMMON_HIGHEST_QUALIFICATION_GRADUATE: {
    id: "COMMON_HIGHEST_QUALIFICATION_GRADUATE",
    defaultMessage: "Graduate",
  },

  COMMON_HIGHEST_QUALIFICATION_DIPLOMA: {
    id: "COMMON_HIGHEST_QUALIFICATION_DIPLOMA",
    defaultMessage: "Diploma",
  },

  COMMON_HIGHEST_QUALIFICATION_HIGH_SCHOOL: {
    id: "COMMON_HIGHEST_QUALIFICATION_HIGH_SCHOOL",
    defaultMessage: "High School",
  },

  COMMON_EMPLOYMENTTERM_ONE: {
    id: "COMMON_EMPLOYMENTTERM_ONE",
    defaultMessage: "0-2 years",
  },
  COMMON_EMPLOYMENTTERM_TWO: {
    id: "COMMON_EMPLOYMENTTERM_TWO",
    defaultMessage: "3-10 years",
  },
  COMMON_EMPLOYMENTTERM_THREE: {
    id: "COMMON_EMPLOYMENTTERM_THREE",
    defaultMessage: "11-30 years",
  },
  COMMON_EMPLOYMENTTERM_FOUR: {
    id: "COMMON_EMPLOYMENTTERM_FOUR",
    defaultMessage: "31-49 years",
  },
  COMMON_EMPLOYMENTTERM_FIVE: {
    id: "COMMON_EMPLOYMENTTERM_FIVE",
    defaultMessage: "Over 50 years",
  },
  COMMON_INDUSTRY_BANKING: {
    id: "COMMON_INDUSTRY_BANKING",
    defaultMessage: "Banking",
  },
  COMMON_INDUSTRY_TELECOM: {
    id: "COMMON_INDUSTRY_TELECOM",
    defaultMessage: "Telecom",
  },
  COMMON_INDUSTRY_TOURISM: {
    id: "COMMON_INDUSTRY_TOURISM",
    defaultMessage: "Tourism",
  },
  COMMON_INDUSTRY_MINING: {
    id: "COMMON_INDUSTRY_MINING",
    defaultMessage: "Mining",
  },
  COMMON_INDUSTRY_COMMERCE: {
    id: "COMMON_INDUSTRY_COMMERCE",
    defaultMessage: "Commerce, Trade",
  },
  COMMON_INDUSTRY_TEXTILE: {
    id: "COMMON_INDUSTRY_TEXTILE",
    defaultMessage: "Textile,Leather,Apparel",
  },
  COMMON_INDUSTRY_MANUCFACTURING: {
    id: "COMMON_INDUSTRY_MANUCFACTURING",
    defaultMessage: "Manufacturing",
  },
  COMMON_INDUSTRY_AGRICULTURE: {
    id: "COMMON_INDUSTRY_AGRICULTURE",
    defaultMessage: "Agriculture & Food",
  },
  COMMON_INDUSTRY_OTHER: {
    id: "COMMON_INDUSTRY_OTHER",
    defaultMessage: "Other",
  },
  COMMON_DESIGNATION_1: {
    id: "COMMON_DESIGNATION_1",
    defaultMessage: "Armed forces and Uniformed services",
  },
  COMMON_DESIGNATION_2: {
    id: "COMMON_DESIGNATION_2",
    defaultMessage: "Artisan, Craft and Trades worker",
  },
  COMMON_DESIGNATION_18: {
    id: "COMMON_DESIGNATION_18",
    defaultMessage: "Business owner and self -employed (formal sector)",
  },
  COMMON_DESIGNATION_3: {
    id: "COMMON_DESIGNATION_3",
    defaultMessage: "Clerk, clerical and administrative support",
  },
  COMMON_DESIGNATION_4: {
    id: "COMMON_DESIGNATION_4",
    defaultMessage: "General merchant",
  },
  COMMON_DESIGNATION_5: {
    id: "COMMON_DESIGNATION_5",
    defaultMessage: "Human health and social services employee",
  },
  COMMON_DESIGNATION_6: {
    id: "COMMON_DESIGNATION_6",
    defaultMessage: "Informal sector employee",
  },
  COMMON_DESIGNATION_7: {
    id: "COMMON_DESIGNATION_7",
    defaultMessage: "Middle to junior Government official",
  },
  COMMON_DESIGNATION_8: {
    id: "COMMON_DESIGNATION_8",
    defaultMessage: "Middle to junior private sector employee",
  },
  COMMON_DESIGNATION_9: {
    id: "COMMON_DESIGNATION_9",
    defaultMessage: "Plant and machine operator",
  },
  COMMON_DESIGNATION_10: {
    id: "COMMON_DESIGNATION_10",
    defaultMessage: "Formal sector employee",
  },
  COMMON_DESIGNATION_11: {
    id: "COMMON_DESIGNATION_11",
    defaultMessage: "Public administrator",
  },
  COMMON_DESIGNATION_17: {
    id: "COMMON_DESIGNATION_17",
    defaultMessage: "Self Employed (informal sector)",
  },
  COMMON_DESIGNATION_12: {
    id: "COMMON_DESIGNATION_12",
    defaultMessage: "Senior Government Official",
  },
  COMMON_DESIGNATION_13: {
    id: "COMMON_DESIGNATION_13",
    defaultMessage: "Senior Private sector employee or director",
  },
  COMMON_DESIGNATION_14: {
    id: "COMMON_DESIGNATION_14",
    defaultMessage: "Teaching and education sector Non-teaching staff",
  },
  COMMON_DESIGNATION_15: {
    id: "COMMON_DESIGNATION_15",
    defaultMessage: "Technician",
  },
  COMMON_DESIGNATION_16: {
    id: "COMMON_DESIGNATION_16",
    defaultMessage: "Others",
  },
  COMMON_LOADING_MSG: {
    id: "COMMON_LOADING_MSG",
    defaultMessage:
      "This seems to be taking longer than usual. Hang in there, our team is working hard to give you a faster decision.",
  },
  COMMON_CHECK_NOW: { id: "COMMON_CHECK_NOW", defaultMessage: "Check now" },
  COMMON_RECALCULATING_ELIGIBILITY: {
    id: "COMMON_RECALCULATING_ELIGIBILITY",
    defaultMessage: "Recalculating eligibility",
  },
  COMMON_WE_ARE_SORRY: {
    id: "COMMON_WE_ARE_SORRY",
    defaultMessage: "We are sorry",
  },
  COMMON_BUTTON_CONTINUE: {
    id: "COMMON_BUTTON_CONTINUE",
    defaultMessage: "Continue",
  },
  COMMON_SAVE_CONTINUE_CONTINUE: {
    id: "COMMON_SAVE_CONTINUE_CONTINUE",
    defaultMessage: "Save and continue",
  },
  COMMON_SPOUSE_NAME: {
    id: "COMMON_SPOUSE_NAME",
    defaultMessage: "Spouse name",
  },
  COMMON_SPOUSE_PHONE_NUMBER: {
    id: "COMMON_SPOUSE_PHONE_NUMBER",
    defaultMessage: "Spouse phone number",
  },
  COMMON_CURRENT_ADDRESS: {
    id: "COMMON_CURRENT_ADDRESS",
    defaultMessage: "Current address",
  },
  COMMON_SPOUSE_ID: {
    id: "COMMON_SPOUSE_ID",
    defaultMessage: "Spouse ID",
  },
  COMMON_CURRENT_ADDRESS_SAME_MSG: {
    id: "COMMON_CURRENT_ADDRESS_SAME_MSG",
    defaultMessage: "Same as applicant’s *address",
  },
  COMMON_MOBILE: { id: "COMMON_MOBILE", defaultMessage: "Mobile" },
  COMMON_LANDLINE: { id: "COMMON_LANDLINE", defaultMessage: "Landline" },
  COMMON_YES: { id: "COMMON_YES", defaultMessage: "Yes" },
  COMMON_SPOUSAL_CONSENT: {
    id: "COMMON_SPOUSAL_CONSENT",
    defaultMessage: "Spousal consent",
  },
  COMMON_BANK_CARD: {
    id: "COMMON_BANK_CARD",
    defaultMessage: "Bank card details",
  },
  COMMON_SPOUSE: { id: "COMMON_SPOUSE", defaultMessage: "Spousal" },
  COMMON_CONSENT: { id: "COMMON_CONSENT", defaultMessage: "Consent" },
  COMMON_SPOUSE_FULL_NAME: {
    id: "COMMON_SPOUSE_FULL_NAME",
    defaultMessage: "Full name of spouse",
  },
  COMMON_THANK_YOU: { id: "COMMON_THANK_YOU", defaultMessage: "Thank you" },
  COMMON_INSTRUCTIONS: {
    id: "COMMON_INSTRUCTIONS",
    defaultMessage: "Instructions",
  },
  COMMON_YOU_ARE_NOT_ELIGIBLE: {
    id: "COMMON_YOU_ARE_NOT_ELIGIBLE",
    defaultMessage: "You are not eligible",
  },
  COMMON_YOU_WERE_APPLYING_FOR: {
    id: "COMMON_YOU_WERE_APPLYING_FOR",
    defaultMessage: "You were applying for",
  },
  COMMON_DO_NOT_WORRY: {
    id: "COMMON_DO_NOT_WORRY",
    defaultMessage: "Don't worry",
  },
  COMMON_GET_IN_TOUCH_HELP_MSG: {
    id: "COMMON_GET_IN_TOUCH_HELP_MSG",
    defaultMessage: "Our team will get in touch with you to help.",
  },
  COMMON_ALMOST_DONE: {
    id: "COMMON_ALMOST_DONE",
    defaultMessage: "Almost done",
  },
  COMMON_ALMOST_THERE: {
    id: "COMMON_ALMOST_THERE",
    defaultMessage: "Almost there",
  },
  COMMON_ONE_LAST_THING: {
    id: "COMMON_ONE_LAST_THING",
    defaultMessage: "One last thing",
  },
  COMMON_TOTAL_AMT_TO_REPAY: {
    id: "COMMON_TOTAL_AMT_TO_REPAY",
    defaultMessage: "Total amount to repay",
  },
  COMMON_ADJUST_LOAN_DETAILS: {
    id: "COMMON_ADJUST_LOAN_DETAILS",
    defaultMessage: "Adjust loan details",
  },
  COMMON_DELAY_SECONDS_MSG: {
    id: "COMMON_DELAY_SECONDS_MSG",
    defaultMessage: "This might take a few more seconds",
  },
  COMMON_CHANGE_LOAN_AMT: {
    id: "COMMON_CHANGE_LOAN_AMT",
    defaultMessage: "Change loan amount",
  },
  ELIGIBILITY_API_ERROR: {
    id: "ELIGIBILITY_API_ERROR",
    defaultMessage:
      "Oops! Something went wrong. You may start over by checking your eligibility",
  },
  COMMON_API_ERROR_MSG: {
    id: "COMMON_API_ERROR_MSG",
    defaultMessage: "Oops! Something went wrong.",
  },
  COMMON_API_ERROR_HAS_OPEN_APPLICATION: {
    id: "COMMON_API_ERROR_HAS_OPEN_APPLICATION",
    defaultMessage:
      "Sorry you already have an ongoing application, please contact call center on {contactNo}",
  },
  GROSS_SALARY_VALIDATION_TEXT: {
    id: "GROSS_SALARY_VALIDATION_TEXT",
    defaultMessage: "Gross salary should be greater than Net Salary",
  },
  COMMON_API_ERROR_INCOMPLETE_WORKFLOW_FOUND: {
    id: "COMMON_API_ERROR_HAS_INCOMPLETE_WORKFLOW_FOUND",
    defaultMessage:
      "Kindly contact the call center {contactNo} for further assistance.",
  },
  COMMON_API_ERROR_INVALID_CUSTOMER_STATUS: {
    id: "COMMON_API_ERROR_INVALID_CUSTOMER_STATUS",
    defaultMessage:
      "Please go to the nearest branch, or call {contactNo} to enable you to apply.",
  },
  COMMON_PLEASE: { id: "COMMON_PLEASE", defaultMessage: "Please" },
  COMMON_TRY_AGAIN: { id: "COMMON_TRY_AGAIN", defaultMessage: "Try again" },
  COMMON_NETWORK_ERROR: {
    id: "COMMON_NETWORK_ERROR",
    defaultMessage: "Unable to process your request, due to network failure.",
  },
  COMMON_LOAN_OFFER: { id: "COMMON_LOAN_OFFER", defaultMessage: "Loan Offer" },
  COMMON_PERSONALISED: {
    id: "COMMON_PERSONALISED",
    defaultMessage: "Personalised",
  },
  
  COMMON_NEED_HELP: { id: "COMMON_NEED_HELP", defaultMessage: "Need help" },
  ERROR_VALIDATION_MINAGE: {
    id: "ERROR_VALIDATION_MINAGE",
    defaultMessage:
      "You need to be less then { value } years of age to apply for a payroll loan",
  },
  ERROR_VALIDATION_MAXAGE: {
    id: "ERROR_VALIDATION_MAXAGE",
    defaultMessage:
      "You need to be at least { value } years of age to apply for a payroll loan",
  },

  ERROR_VALIDATION_NATIONAL_ID_NUMBER: {
    id: "ERROR_VALIDATION_NATIONAL_ID_NUMBER",
    defaultMessage: "Please enter a Valid ID Number",
  },
  ERROR_VALIDATION_CAR_YEAR: {
    id: "ERROR_VALIDATION_CAR_YEAR",
    defaultMessage: "Please enter Year from 1900 to this year",
  },
  ERROR_VALIDATION_NATIONAL_ID_NUMBER_GENERAL: {
    id: "ERROR_VALIDATION_NATIONAL_ID_NUMBER_GENERAL",
    defaultMessage: "Please enter a valid {numberLabel}",
  },
  ERROR_VALIDATION_PHONE_NUMBER_GENERAL: {
    id: "ERROR_VALIDATION_PHONE_NUMBER_GENERAL",
    defaultMessage: "Please enter a valid phone number",
  },
  ERROR_VALIDATION_FUTURE_DATE_NOT_ALLOWED: {
    id: "ERROR_VALIDATION_FUTURE_DATE_NOT_ALLOWED",
    defaultMessage: "Please input a date from past",
  },
  ERROR_VALIDATION_DOJ_GREATER_THAN_DOB: {
    id: "ERROR_VALIDATION_DOJ_GREATER_THAN_DOB",
    defaultMessage: "Please input a date which is greater than your {dobLabel}",
  },
  ERROR_KIN_AND_SPOUSE_NAME_SAME: {
    id: "ERROR_KIN_AND_SPOUSE_NAME_SAME",
    defaultMessage: "Please enter a name other than your spouse",
  },
  ERROR_KIN_AND_ALTERNATE_KIN_NAME_SAME: {
    id: "ERROR_KIN_AND_ALTERNATE_KIN_NAME_SAME",
    defaultMessage: "Primary and alternate kin name cannot be same.",
  },
  ERROR_KIN_AND_SPOUSE_MOBILE_SAME: {
    id: "ERROR_KIN_AND_SPOUSE_MOBILE_SAME",
    defaultMessage: "Please enter a number other than your spouse",
  },
  ERROR_KIN_AND_ALTERNATE_KIN_MOBILE_SAME: {
    id: "ERROR_KIN_AND_ALTERNATE_KIN_MOBILE_SAME",
    defaultMessage: "Primary and alternate kin contact number cannot be same.",
  },
  ERROR_SPOUSE_AND_APPLICANT_NID_SAME: {
    id: "ERROR_SPOUSE_AND_APPLICANT_NID_SAME",
    defaultMessage: "Spouse and applicant id number cannot be same.",
  },
  ERROR_SPOUSE_AND_APPLICANT_PHONE_SAME: {
    id: "ERROR_SPOUSE_AND_APPLICANT_PHONE_SAME",
    defaultMessage: "Spouse and applicant phone number cannot be same.",
  },
  ID_NUMBER_ALREADY_REGISTERED: {
    id: "ID_NUMBER_ALREADY_REGISTERED",
    defaultMessage:
      "Check ID number, this number is already registered with Letsgo.",
  },
  GHANA_ID_NUMBER_ALREADY_REGISTERED: {
    id: "GHANA_ID_NUMBER_ALREADY_REGISTERED",
    defaultMessage:
      "Check Ghana card number, this number is already registered with Letsgo.",
  },
  LOAN_SECURITY_MSG: {
    id: "LOAN_SECURITY_MSG",
    defaultMessage:
      "We follow a strict security protocol and only review your information for the sole purpose of this loan application.",
  },
  LOAN_BACK_BTN: {
    id: "LOAN_BACK_BTN",
    defaultMessage: "Your loan application",
  },
  LOAN_BACK_BTN_OCR_UPLOAD: {
    id: "LOAN_BACK_BTN_OCR_UPLOAD",
    defaultMessage: "Upload your National ID",
  },
  HEALTH_DECLARATION_TITLE: {
    id: "HEALTH_DECLARATION_TITLE",
    defaultMessage: "Health Declaration",
  },
  HEALTH_DECLARATION_REQUIRED_MESSAGE: {
    id: "HEALTH_DECLARATION_REQUIRED_MESSAGE",
    defaultMessage:
      "It is mandatory to view and accept the health declaration or your application may get rejected",
  },
  DOCTOR_FIRST_NAME: {
    id: "DOCTOR_FIRST_NAME",
    defaultMessage: "Doctor's first name",
  },
  DOCTOR_LAST_NAME: {
    id: "DOCTOR_LAST_NAME",
    defaultMessage: "Doctor's last name",
  },
  DOCTOR_ADDRESS: {
    id: "DOCTOR_ADDRESS",
    defaultMessage: "Doctor's address",
  },
  DOCTOR_ADDRESS_HELP_TEXT: {
    id: "DOCTOR_ADDRESS_HELP_TEXT",
    defaultMessage:
      "Enter district / subcountry, village, street/road, plot where you live",
  },
  DOCTOR_PHONE_NUMBER: {
    id: "DOCTOR_PHONE_NUMBER",
    defaultMessage: "Doctor’s phone number",
  },
  SELECT_INSURANCE_COMPANY: {
    id: "SELECT_INSURANCE_COMPANY",
    defaultMessage: "Select your insurance company",
  },
  LOAN_SECTION_BANKDETAILS_NG_IND_LOANS: {
    id: "LOAN_SECTION_BANKDETAILS_NG_IND_LOANS",
    defaultMessage: "Salary Bank Account Details",
  },
  LOAN_SECTION_BANKDETAILS: {
    id: "LOAN_SECTION_BANKDETAILS",
    defaultMessage: "Bank Details",
  },

  SELECT_INSURANCE_COMPANY_HELP_TEXT: {
    id: "SELECT_INSURANCE_COMPANY_HELP_TEXT",
    defaultMessage:
      "Please select the insurance company name where you already have your insurance with",
  },
  INSURANCE_POLICY_NUMBER: {
    id: "INSURANCE_POLICY_NUMBER",
    defaultMessage: "Your insurance policy number",
  },
  INSURANCE_CONTRACT: {
    id: "INSURANCE_CONTRACT",
    defaultMessage: "Insurance contract",
  },
  INSURANCE_CONTRACT_UPLOAD_DESC: {
    id: "INSURANCE_CONTRACT_UPLOAD_DESC",
    defaultMessage: "Click a photo of your insurance contract",
  },
  NOTORIZED_DOCUMENT_UPLOAD: {
    id: "NOTORIZED_DOCUMENT_UPLOAD",
    defaultMessage: "Notorized document",
  },
  NOTORIZED_DOCUMENT_UPLOAD_DESC: {
    id: "NOTORIZED_DOCUMENT_UPLOAD_DESC",
    defaultMessage: "Upload a document here",
  },
  BANK_CARD_DOCUMENT_UPLOAD: {
    id: "BANK_CARD_DOCUMENT_UPLOAD",
    defaultMessage: "Bank Card document",
  },
  BANK_CARD_DOCUMENT_UPLOAD_DESC: {
    id: "BANK_CARD_DOCUMENT_UPLOAD_DESC",
    defaultMessage: "Upload a document here",
  },
  INSURANCE_FIT_IN_CRITERIA: {
    id: "INSURANCE_FIT_IN_CRITERIA",
    defaultMessage: "I fit in the above criteria",
  },
  INSURANCE_NOT_FIT_IN_CRITERIA: {
    id: "INSURANCE_NOT_FIT_IN_CRITERIA",
    defaultMessage: "I do not fit in the above criteria",
  },
  PROVIDE_INSURANCE_COVER: {
    id: "PROVIDE_INSURANCE_COVER",
    defaultMessage:
      "Would you like Letshego to provide Insurance Cover for your loan?",
  },
  HEALTH_DECLARATION_SUB_DESC: {
    id: "HEALTH_DECLARATION_SUB_DESC",
    defaultMessage:
      "I, the Policyholder / Debtor / Insured Person understand, agree and where applicable declare that:",
  },
  HEALTH_DECLARATION_DESC_ONE: {
    id: "HEALTH_DECLARATION_DESC_ONE",
    defaultMessage: "As on this day, I am in good health; and",
  },
  HEALTH_DECLARATION_DESC_TWO: {
    id: "HEALTH_DECLARATION_DESC_TWO",
    defaultMessage:
      "I have not, over the past 24 (twenty four) months, undergone, taken and/or received any treatment or medication for any medical or health condition apart from minor ailments such as common colds and influenza; and",
  },
  HEALTH_DECLARATION_DESC_THREE: {
    id: "HEALTH_DECLARATION_DESC_THREE",
    defaultMessage:
      "As on this day, I am unaware of any condition, exposure and/or circumstance which is likely to impact on my health or general well-being; and",
  },
  HEALTH_DECLARATION_DESC_FOUR: {
    id: "HEALTH_DECLARATION_DESC_FOUR",
    defaultMessage:
      "I have been duly advised by the Sales Consultant and accept the fact that non-disclosure and/or misrepresentation and/or omission of any pre-existing medical condition and/or other material fact may invalidate any claim made under my insurance policy with the insurance provider of my choice as provided for in the loan agreement and/or the policy in its entirety.",
  },
  HEALTH_DECLARATION_DESC_FIVE: {
    id: "HEALTH_DECLARATION_DESC_FIVE",
    defaultMessage:
      "I understand and agree that this statement shall form part of my application for insurance cover with a registered insurance provider of my choice.",
  },
  LOAN_SECTION_ABOUT: { id: "LOAN_SECTION_ABOUT", defaultMessage: "About you" },
  LOAN_SECTION_RESIDENCE: {
    id: "LOAN_SECTION_RESIDENCE",
    defaultMessage: "Current residence",
  },
  LOAN_SECTION_EMPLOYMENT: {
    id: "LOAN_SECTION_EMPLOYMENT",
    defaultMessage: "Employment",
  },
  LOAN_SECTION_INCOME_EXPENDITURE: {
    id: "LOAN_SECTION_INCOME_EXPENDITURE",
    defaultMessage: "Income and expenditure",
  },
  LOAN_SECTION_SPOUSE: {
    id: "LOAN_SECTION_SPOUSE",
    defaultMessage: "Spouse details",
  },
  LOAN_SECTION_NEXT_KIN: {
    id: "LOAN_SECTION_NEXT_KIN",
    defaultMessage: "Next of kin",
  },
  LOAN_PROFILE_NAME_OF_KIN: {
    id: "LOAN_PROFILE_NAME_OF_KIN",
    defaultMessage: "Name of kin",
  },
  LOAN_PROFILE_PRIMARY_KIN: {
    id: "LOAN_PROFILE_PRIMARY_KIN",
    defaultMessage: "Primary kin name",
  },
  LOAN_PROFILE_PRIMARY_KIN_PHONE_NUMBER: {
    id: "LOAN_PROFILE_PRIMARY_KIN_PHONE_NUMBER",
    defaultMessage: "Primary kin phone number",
  },
  LOAN_PROFILE_ALTERNATE_KIN: {
    id: "LOAN_PROFILE_ALTERNATE_KIN",
    defaultMessage: "Alternate kin name",
  },
  LOAN_PROFILE_ALTERNATE_KIN_PHONE_NUMBER: {
    id: "LOAN_PROFILE_ALTERNATE_KIN_PHONE_NUMBER",
    defaultMessage: "Alternate kin phone number",
  },
  LOAN_PROFILE_ALTERNATE_KIN_HELP_TEXT: {
    id: "LOAN_PROFILE_ALTERNATE_KIN_HELP_TEXT",
    defaultMessage:
      "Please enter details of your close relative other than your spouse.",
  },
  NEXT_OF_KIN_SUBTITLE: {
    id: "NEXT_OF_KIN_SUBTITLE",
    defaultMessage:
      "Please enter details of someone other than yourself and your spouse",
  },
  UPLOAD_DOCUMENTS_SUBTITLE: {
    id: "UPLOAD_DOCUMENTS_SUBTITLE",
    defaultMessage:
      "Please enter details of someone other than yourself and your spouse",
  },
  LOAN_PROFILE_NATIONALITY: {
    id: "LOAN_PROFILE_NATIONALITY",
    defaultMessage: "Nationality",
  },
  LOAN_PROFILE_COUNTRY_CITIZENSHIP: {
    id: "LOAN_PROFILE_COUNTRY_CITIZENSHIP",
    defaultMessage: "Country of citizenship",
  },
  LOAN_PROFILE_SPOUSE_COUNTRY_CITIZENSHIP: {
    id: "LOAN_PROFILE_SPOUSE_COUNTRY_CITIZENSHIP",
    defaultMessage: "Country of Spouse Citizenship",
  },
  LOAN_PROFILE_SPOUSE_DEFAULT_CITIZENSHIP: {
    id: "LOAN_PROFILE_SPOUSE_DEFAULT_CITIZENSHIP",
    defaultMessage: "Botswana",
  },
  LOAN_PROFILE_VOTER_ID: {
    id: "LOAN_PROFILE_VOTER_ID",
    defaultMessage: "Voter ID number",
  },
  LOAN_PROFILE_GHANA_ID_CARD: {
    id: "LOAN_PROFILE_GHANA_ID_CARD",
    defaultMessage: "Ghana ID card number",
  },
  LOAN_PROFILE_DRIVER_LICENSE: {
    id: "LOAN_PROFILE_DRIVER_LICENSE",
    defaultMessage: "License number",
  },
  LOAN_PROFILE_SSINT: {
    id: "LOAN_PROFILE_SSINT",
    defaultMessage: "SSINT number",
  },
  LOAN_PROFILE_IDNUMBER: {
    id: "LOAN_PROFILE_IDNUMBER",
    defaultMessage: "National ID number",
  },
  CHECK_NUMBER: {
    id: "CHECK_NUMBER",
    defaultMessage: "Check Number",
  },
  LOAN_PROFILE_IPPS_COMPUTER_NUMBER: {
    id: "LOAN_PROFILE_IPPS_COMPUTER_NUMBER",
    defaultMessage: "IPPS/Computer Number",
  },
  IPPS_COMP_NO: {
    id: "IPPS_COMP_NO",
    defaultMessage: "IPPS/Computer Number",
  },
  NEED_HELP_CUSTOMER: {
    id: "NEED_HELP_CUSTOMER",
    defaultMessage: "No IPPS/Computer Number",
  },
  ELIGIBILITY_MESSAGE_TOAST: {
    id: "ELIGIBILITY_MESSAGE_TOAST",
    defaultMessage:
      "If your employment details are not in the list, kindly contact {contactlink}  for further enquiries. ",
  },
  VERIFICATION_ID_CALLBACK_TOAST: {
    id: "ELIGIBILITY_MESSAGE_TOAST",
    defaultMessage: "Contact {contactlink} for further enquiries.",
  },
  LOAN_PROFILE_COMMON_ID_NUMBER: {
    id: "LOAN_PROFILE_COMMON_ID_NUMBER",
    defaultMessage: "ID number",
  },
  LOAN_PROFILE_COMMON_ID_HELP_TEXT: {
    id: "LOAN_PROFILE_COMMON_ID_HELP_TEXT",
    defaultMessage: "NID or Passport Number",
  },
  LOAN_PROFILE_PASSPORT: {
    id: "LOAN_PROFILE_PASSPORT",
    defaultMessage: "Passport number",
  },
  LOAN_PROFILE_BVN: { id: "LOAN_PROFILE_BVN", defaultMessage: "BVN" },
  LOAN_PROFILE_FIRSTNAME: {
    id: "LOAN_PROFILE_FIRSTNAME",
    defaultMessage: "First name",
  },
  LOAN_PROFILE_MIDDLENAME: {
    id: "LOAN_PROFILE_MIDDLENAME",
    defaultMessage: "Middle name (optional)",
  },
  LOAN_PROFILE_LASTNAME: {
    id: "LOAN_PROFILE_LASTNAME",
    defaultMessage: "Last name",
  },
  LOAN_PROFILE_WORK_EMAIL_ADDRESS: {
    id: "LOAN_PROFILE_WORK_EMAIL_ADDRESS",
    defaultMessage: "Work Email Address",
  },
  LOAN_BANKACCOUNT_NO: {
    id: "LOAN_BANKACCOUNT_NO",
    defaultMessage: "Bank Account No",
  },
  LOAN_BANKACCOUNT_NAME: {
    id: "LOAN_BANKACCOUNT_NAME",
    defaultMessage: "Bank Account Name",
  },
  LOAN_PROFILE_BIRTHDATE: {
    id: "LOAN_PROFILE_BIRTHDATE",
    defaultMessage: "Date of birth",
  },
  LOAN_PROFILE_ID_TYPE: {
    id: "LOAN_PROFILE_ID_TYPE",
    defaultMessage: "ID type",
  },
  LOAN_PROFILE_BIRTHDATE_WITH_FORMAT: {
    id: "LOAN_PROFILE_BIRTHDATE_WITH_FORMAT",
    defaultMessage: "Date of birth - DD/MM/YYYY",
  },
  LOAN_PROFILE_BIRTHDATE_ERROR: {
    id: "LOAN_PROFILE_BIRTHDATE_ERROR",
    defaultMessage:
      "You have to be 18 years or older to open a FlexiSave account with us.",
  },
  LOAN_PROFILE_GENDER: { id: "LOAN_PROFILE_GENDER", defaultMessage: "Gender" },
  LOAN_PROFILE_MARITALSTATUS: {
    id: "LOAN_PROFILE_MARITALSTATUS",
    defaultMessage: "Marital status",
  },
  MARRIED_IN_COMMUNITY_OF_PROPERTY: {
    id: "MARRIED_IN_COMMUNITY_OF_PROPERTY",
    defaultMessage: "Are you married in the ‘Community of property’?",
  },
  SPOUSE_FULL_NAME_TEXT: {
    id: "SPOUSE_FULL_NAME_TEXT",
    defaultMessage: "Spouse full name",
  },
  SPOUSE_MOBILE_NUMBER_TEXT: {
    id: "SPOUSE_MOBILE_NUMBER_TEXT",
    defaultMessage: "Spouse mobile number",
  },
  SEND_OTP_TO_SPOUSE: {
    id: "SEND_OTP_TO_SPOUSE",
    defaultMessage:
      "We will send the OTP to your spouse at the given mobile number for their consent.",
  },
  MARRIED_IN_COMMUNITY_OF_PROPERTY_DESC: {
    id: "MARRIED_IN_COMMUNITY_OF_PROPERTY_DESC",
    defaultMessage:
      "Since you are married in the ‘Community of property’ regime, we need you to provide a spouse consent",
  },
  SEND_OTP_TO_SPOUSE_PROMPT: {
    id: "SEND_OTP_TO_SPOUSE_PROMPT",
    defaultMessage: "We will send the OTP to your spouse's mobile number.",
  },
  ENTER_SPOUSE_OTP: {
    id: "ENTER_SPOUSE_OTP",
    defaultMessage: "Enter the OTP sent to your Spouse’s mobile number {phone}",
  },
  LOAN_PROFILE_EMPLOYMENT_TYPE: {
    id: "LOAN_PROFILE_EMPLOYMENT_TYPE",
    defaultMessage: "Employment Type",
  },
  LOAN_PROFILE_MARRIAGEREGIME: {
    id: "LOAN_PROFILE_MARRIAGEREGIME",
    defaultMessage: "Marriage Regime",
  },
  LOAN_PROFILE_CITY: {
    id: "LOAN_PROFILE_CITY",
    defaultMessage: "City of residence",
  },
  LOAN_PROFILE_CITY_MZ: {
    id: "LOAN_PROFILE_CITY_MZ",
    defaultMessage: "City",
  },
  LOAN_PROFILE_BRANCH: {
    id: "LOAN_PROFILE_BRANCH",
    defaultMessage: "Home Branch",
  },
  LOAN_PROFILE_PROVINCE: {
    id: "LOAN_PROFILE_PROVINCE",
    defaultMessage: "Province",
  },
  LOAN_PROFILE_ADDRESS: {
    id: "LOAN_PROFILE_ADDRESS",
    defaultMessage: "Address line 1",
  },
  LOAN_PROFILE_ADDRESS_HINT: {
    id: "LOAN_PROFILE_ADDRESS_HINT",
    defaultMessage: "Enter estate / house number",
  },
  LOAN_PROFILE_ADDRESS2: {
    id: "LOAN_PROFILE_ADDRESS2",
    defaultMessage: "Address line 2",
  },
  LOAN_PROFILE_ADDRESS2_HINT: {
    id: "LOAN_PROFILE_ADDRESS2_HINT",
    defaultMessage: "Enter area / street where you live",
  },
  LOAN_PROFILE_ADDRESS3: {
    id: "LOAN_PROFILE_ADDRESS3",
    defaultMessage: "Address line 3",
  },
  LOAN_PROFILE_ADDRESS3_HINT: {
    id: "LOAN_PROFILE_ADDRESS3_HINT",
    defaultMessage: "Enter your city and postal code",
  },
  LOAN_PROFILE_ZIPCODE: {
    id: "LOAN_PROFILE_ZIPCODE",
    defaultMessage: "Postal code (optional)",
  },
  LOAN_PROFILE_DIGITAL_ADDRESS: {
    id: "LOAN_PROFILE_DIGITAL_ADDRESS",
    defaultMessage: "Digital Address",
  },
  LOAN_PROFILE_EMPLOYMENTTYPE: {
    id: "LOAN_PROFILE_EMPLOYMENTTYPE",
    defaultMessage: "Employment type",
  },
  LOAN_PROFILE_EMPLOYERNAME: {
    id: "LOAN_PROFILE_EMPLOYERNAME",
    defaultMessage: "Employer name",
  },
  LOAN_PROFILE_DATEOFJOINING: {
    id: "LOAN_PROFILE_DATEOFJOINING",
    defaultMessage: "End date of contract",
  },
  LOAN_PROFILE_EMPLOYMENTTERM: {
    id: "LOAN_PROFILE_EMPLOYMENTTERM",
    defaultMessage: "Years with the employer",
  },
  LOAN_PROFILE_DATE_OF_EMPLOYMENT: {
    id: "LOAN_PROFILE_DATE_OF_EMPLOYMENT",
    defaultMessage: "Date of Employment",
  },
  LOAN_PROFILE_DATE_OF_EMPLOYMENT_WITH_FORMAT: {
    id: "LOAN_PROFILE_DATE_OF_EMPLOYMENT_WITH_FORMAT",
    defaultMessage: "Date of Employment - DD/MM/YYYY",
  },
  LOAN_PROFILE_DESIGNATION: {
    id: "LOAN_PROFILE_DESIGNATION",
    defaultMessage: "Position/designation",
  },
  LOAN_DESIGNATION: { id: "LOAN_DESIGNATION", defaultMessage: "Designation" },
  LOAN_PROFILE_DEPARTMENT: {
    id: "LOAN_PROFILE_DEPARTMENT",
    defaultMessage: "Department",
  },
  LOAN_PROFILE_DEPARTMENT_HINT: {
    id: "LOAN_PROFILE_DEPARTMENT_HINT",
    defaultMessage: "Example: Human resource, Finance, Digital etc.",
  },
  LOAN_PROFILE_SALARY: {
    id: "LOAN_PROFILE_SALARY",
    defaultMessage: "Net monthly salary",
  },
  LOAN_PROFILE_SALARY_HINT: {
    id: "LOAN_PROFILE_SALARY_HINT",
    defaultMessage:
      "Your approximate take home monthly salary received in your account after all the deductions",
  },
  LOAN_PROFILE_BASIC_INCOME: {
    id: "LOAN_PROFILE_BASIC_INCOME",
    defaultMessage: "Basic monthly income",
  },
  LOAN_PROFILE_GROSS_INCOME: {
    id: "LOAN_PROFILE_GROSS_INCOME",
    defaultMessage: "Gross monthly income",
  },
  LOAN_PROFILE_TPRS_AFFORDABILITY: {
    id: "LOAN_PROFILE_TPRS_AFFORDABILITY",
    defaultMessage: "TPRS Affordability",
  },
  LOAN_PROFILE_TOTAL_DEDUCTIONS: {
    id: "LOAN_PROFILE_TOTAL_DEDUCTIONS",
    defaultMessage: "Total Deductions",
  },
  LOAN_PROFILE_KIN_RELATIONSHIP: {
    id: "LOAN_PROFILE_KIN_RELATIONSHIP",
    defaultMessage: "Relationship with applicant",
  },
  LOAN_PROFILE_GROSS_INCOME_HINT: {
    id: "LOAN_PROFILE_GROSS_INCOME_HINT",
    defaultMessage:
      "Your approximate monthly salary including all the allowances without any tax deductions",
  },
  LOAN_PROFILE_OTHER_MONTHLY_INCOME: {
    id: "LOAN_PROFILE_OTHER_MONTHLY_INCOME",
    defaultMessage: "Other monthly income",
  },
  LOAN_PROFILE_OTHER_MONTHLY_INCOME_HINT: {
    id: "LOAN_PROFILE_OTHER_MONTHLY_INCOME_HINT",
    defaultMessage:
      "Your approximate total of other incomes that you receive for sources other than your employer",
  },
  LOAN_PROFILE_MONTHLY_EXPENDITURE: {
    id: "LOAN_PROFILE_MONTHLY_EXPENDITURE",
    defaultMessage: "Monthly expenditure",
  },
  LOAN_PROFILE_MONTHLY_EXPENDITURE_HINT: {
    id: "LOAN_PROFILE_MONTHLY_EXPENDITURE_HINT",
    defaultMessage:
      "Your approximate total of monthly fixed expenses which may include rent, credit card bills, family expenses, groceries, etc.",
  },
  LOAN_PROFILE_MODAL_HURRAY: {
    id: "LOAN_PROFILE_MODAL_HURRAY",
    defaultMessage: "Hurray!",
  },
  LOAN_PROFILE_MODAL_EXISTING: {
    id: "LOAN_PROFILE_MODAL_EXISTING",
    defaultMessage: "You are an existing customer",
  },
  LOAN_PROFILE_MODAL_FOUND: {
    id: "LOAN_PROFILE_MODAL_FOUND",
    defaultMessage: "We found you",
  },
  LOAN_PROFILE_MODAL_EXISTING_TEXT: {
    id: "LOAN_PROFILE_MODAL_EXISTING_TEXT",
    defaultMessage:
      "Your national id and mobile number is already registered with us. Redirecting  to your personalised dashboard",
  },
  LOAN_PROFILE_MODAL_FOUND_TEXT: {
    id: "LOAN_PROFILE_MODAL_FOUND_TEXT",
    defaultMessage: "Redirecting you to your personalized dashboard.",
  },
  LOAN_PROFILE_MODAL_EXISTING_HINT: {
    id: "LOAN_PROFILE_MODAL_EXISTING_HINT",
    defaultMessage: "Please do not refresh or go back",
  },
  LOAN_PROFILE_MODAL_FOUND_HINT: {
    id: "LOAN_PROFILE_MODAL_FOUND_HINT",
    defaultMessage:
      "Please do not refresh or go back until we redirect you to the dashboard",
  },
  LOAN_PROFILE_MODAL_EXISTING_TEXT2: {
    id: "LOAN_PROFILE_MODAL_EXISTING_TEXT2",
    defaultMessage:
      "Your national id is already registered with us. Please verify your registered mobile number to personalise your experience",
  },
  LOAN_PROFILE_MODAL_EXISTING_TEXT3: {
    id: "LOAN_PROFILE_MODAL_EXISTING_TEXT3",
    defaultMessage:
      "Please verify your registered mobile number to personalise your experience",
  },
  LOAN_PROFILE_MODAL_VERIFY_BTN: {
    id: "LOAN_PROFILE_MODAL_VERIFY_BTN",
    defaultMessage: "Verify it's you",
  },
  LOAN_PROFILE_MODAL_SUPPORT_NUMBER: {
    id: "LOAN_PROFILE_MODAL_SUPPORT_NUMBER",
    defaultMessage: "Changed number?",
  },
  LOAN_PROFILE_MODAL_SUPPORT_TITLE: {
    id: "LOAN_PROFILE_MODAL_SUPPORT_TITLE",
    defaultMessage: "We are here to help",
  },
  LOAN_PROFILE_MODAL_SUPPORT_TEXT: {
    id: "LOAN_PROFILE_MODAL_SUPPORT_TEXT",
    defaultMessage:
      "To update your mobile number please contact our customer service team",
  },
  LOAN_PROFILE_ID_NUMBER: {
    id: "LOAN_PROFILE_ID_NUMBER",
    defaultMessage: "ID number",
  },
  LOAN_PROFILE_MODAL_SUPPORT_BTN: {
    id: "LOAN_PROFILE_MODAL_SUPPORT_BTN",
    defaultMessage: "Customer service {phoneNumber}",
  },
  LOAN_PROFILE_MODAL_OTP_BTN: {
    id: "LOAN_PROFILE_MODAL_OTP_BTN",
    defaultMessage: "I don't have this number anymore",
  },
  LOAN_DETAILS: {
    id: "LOAN_DETAILS",
    defaultMessage: "Loan details",
  },
  PERSONAL_DETAILS: {
    id: "PERSONAL_DETAILS",
    defaultMessage: "Personal details",
  },
  PERSONAL_DETAILS_LABEL: {
    id: "PERSONAL_DETAILS",
    defaultMessage: "Personal Details",
  },
  OTHER_DETAILS: {
    id: "OTHER_DETAILS",
    defaultMessage: "Other details",
  },
  FINAL_DECISION: {
    id: "FINAL_DECISION",
    defaultMessage: "Final Decision",
  },
  UPLOAD_DOCUMENTS: {
    id: "UPLOAD_DOCUMENTS",
    defaultMessage: "Upload documents",
  },
  DECLARATION: {
    id: "DECLARATION",
    defaultMessage: "Declaration",
  },
  HOW_DOES_IT_WORK: {
    id: "HOW_DOES_IT_WORK",
    defaultMessage: "How does it work?",
  },
  UPLOAD_EMPLOYER_CONFIRMATION_LETTER: {
    id: "UPLOAD_EMPLOYMENT_CONFIRMATION_LETTER",
    defaultMessage: "Upload your Employment confirmation letter",
  },
  UPLOAD_EMPLOYER_AUTHORIZATION_LETTER: {
    id: "UPLOAD_EMPLOYMENT_AUTHORIZATION_LETTER",
    defaultMessage: "Upload your Employer authorization letter",
  },
  EMPLOYER_AUTHORIZATION_LETTER: {
    id: "UPLOAD_EMPLOYMENT_AUTHORIZATION_LETTER",
    defaultMessage: "Employer authorization letter",
  },
  EMPLOYER_AUTHORIZATION_LETTER_UPLOAD_DESC: {
    id: "EMPLOYER_AUTHORIZATION_LETTER_ULPOAD_DESC",
    defaultMessage: "Upload picture of Employer authorization letter",
  },
  EMPLOYER_AUTHORIZATION_GUIDELINE_1: {
    id: "EMPLOYER_AUTHORIZATION_GUIDELINE_1",
    defaultMessage: "to download employee authorization letter format",
  },
  EMPLOYER_AUTHORIZATION_GUIDELINE_2: {
    id: "EMPLOYER_AUTHORIZATION_GUIDELINE_2",
    defaultMessage:
      "Fill all the details and get an approval from the employer",
  },
  EMPLOYER_AUTHORIZATION_GUIDELINE_3: {
    id: "EMPLOYER_AUTHORIZATION_GUIDELINE_3",
    defaultMessage:
      "Take a picture of your filled Employer authorization letter and upload it below",
  },

  EMPLOYER_CONFIRMATION_LETTER: {
    id: "EMPLOYER_CONFIRMATION_LETTER",
    defaultMessage: "Employer confirmation letter",
  },
  EMPLOYER_CONFIRMATION_LETTER_UPLOAD_DESC: {
    id: "EMPLOYER_CONFIRMATION_LETTER_UPLOAD_DESC",
    defaultMessage: "Upload picture of Employment confirmation letter",
  },
  EMPLOYER_CONFIRMATION_GUIDELINE_1: {
    id: "EMPLOYER_CONFIRMATION_GUIDELINE_1",
    defaultMessage: "to download Employee confirmation letter format",
  },
  EMPLOYER_CONFIRMATION_GUIDELINE_2: {
    id: "EMPLOYER_CONFIRMATION_GUIDELINE_2",
    defaultMessage:
      "Fill all the details and get an approval from the employer",
  },
  EMPLOYER_CONFIRMATION_GUIDELINE_3: {
    id: "EMPLOYER_CONFIRMATION_GUIDELINE_3",
    defaultMessage:
      "Take a picture of your filled Employment confirmation letter and upload it below",
  },
  LOAN_SUMMARY_LOADER_MSG: {
    id: "LOAN_SUMMARY_LOADER_MSG",
    defaultMessage:
      "Now that we have all your details, we are checking your loan amount and other details.",
  },
  NOT_ELIGIBLE_FOR_LOAN_MSG: {
    id: "NOT_ELIGIBLE_FOR_LOAN_MSG",
    defaultMessage:
      "As per the details provided, it appears you're not eligible for a payroll loan at the moment.",
  },
  NOT_ELIGIBLE_ACTION: {
    id: "NOT_ELIGIBLE_ACTION",
    defaultMessage: "Check details provided",
  },
  NOT_ELIGIBLE_TENURE_IN_MONTHS: {
    id: "NOT_ELIGIBLE_TENURE_IN_MONTHS",
    defaultMessage: "for {tenure} Months",
  },
  BORROW_UPTO_AMOUNT_HELP_MSG: {
    id: "BORROW_UPTO_AMOUNT_HELP_MSG",
    defaultMessage: "Sweet! You can borrow up to {amount}",
  },
  ELIGIBILITY_AMOUNT_EQUAL_MSG: {
    id: "ELIGIBILITY_AMOUNT_EQUAL_MSG",
    defaultMessage: "Great! You can borrow your requested amount.",
  },
  BORROW_LESS_AMOUNT_HELP_MSG: {
    id: "BORROW_LESS_AMOUNT_HELP_MSG",
    defaultMessage: "Oops! You can only borrow up to {amount}.",
  },
  LOAN_ELIGIBILITY_CRITERIA_ONE: {
    id: "LOAN_ELIGIBILITY_CRITERIA_ONE",
    defaultMessage: "Above 18 years of age",
  },
  LOAN_ELIGIBILITY_CRITERIA_TWO: {
    id: "LOAN_ELIGIBILITY_CRITERIA_TWO",
    defaultMessage: "Should be employed permanently",
  },
  LOAN_ELIGIBILITY_CRITERIA_THREE: {
    id: "LOAN_ELIGIBILITY_CRITERIA_THREE",
    defaultMessage: "Working min 6 months with the same employer",
  },
  LOAN_ELIGIBILITY_CRITERIA_QUESTION: {
    id: "LOAN_ELIGIBILITY_CRITERIA_QUESTION",
    defaultMessage: "Who is Eligible for a payroll loan (DAS) at Letshego?",
  },
  LOAN_ELIGIBILITY_CRITERIA_ALL_LINK_TEXT: {
    id: "LOAN_ELIGIBILITY_CRITERIA_ALL_LINK_TEXT",
    defaultMessage: "Read all eligibility criteria",
  },
  LOAN_ELIGIBILITY_CUSTOMER_CARE_HELP_MSG: {
    id: "LOAN_ELIGIBILITY_CUSTOMER_CARE_HELP_MSG",
    defaultMessage:
      "Get an offer customised as per your affordability, disbursed in your account within 48 hours!",
  },
  LOAN_ELIGIBILITY_PERSONALISED_OFFER_TEXT: {
    id: "LOAN_ELIGIBILITY_PERSONALISED_OFFER_TEXT",
    defaultMessage: "To get a personalised offer",
  },
  LOAN_APPLICATION_IN_REVIEW: {
    id: "LOAN_APPLICATION_IN_REVIEW",
    defaultMessage: "Your application is in review",
  },
  LOAN_APPLICATION_IN_REVIEW_HELP_MSG: {
    id: "LOAN_APPLICATION_IN_REVIEW_HELP_MSG",
    defaultMessage:
      "To support your application, we may need more information from you. We will keep you informed.",
  },
  LOAN_APPLICATION_SUBMIT_ERROR: {
    id: "LOAN_APPLICATION_SUBMIT_ERROR",
    defaultMessage: "Something went wrong while processing your application",
  },
  LOAN_APPLICATION_SUBMIT_ERROR_HELP_MSG: {
    id: "LOAN_APPLICATION_SUBMIT_ERROR_HELP_MSG",
    defaultMessage:
      "Don't worry someone from our team will contact you shortly. Meanwhile, you can apply again.",
  },
  LOAN_APPLICATION_REJECT_ERROR: {
    id: "LOAN_APPLICATION_REJECT_ERROR",
    defaultMessage: "Sorry! Your loan application is rejected",
  },
  LOAN_APPLICATION_REJECT_ERROR_HELP_MSG: {
    id: "LOAN_APPLICATION_REJECT_ERROR_HELP_MSG",
    defaultMessage:
      "Unable to process your loan request, as a few details don't match our payroll loan criteria.",
  },
  LOAN_APPLICATION_RE_APPLY: {
    id: "LOAN_APPLICATION_RE_APPLY",
    defaultMessage: "Apply again",
  },
  LOAN_APPLICATION_REFERENCE_NO: {
    id: "LOAN_APPLICATION_REFERENCE_NO",
    defaultMessage: "Reference number",
  },
  LOAN_RECALCULATE_ELIGIBILITY: {
    id: "LOAN_RECALCULATE_ELIGIBILITY",
    defaultMessage: "Recalculate Eligibility",
  },
  LOAN_NID_ERROR_HELP: {
    id: "LOAN_NID_ERROR_HELP",
    defaultMessage:
      "Does this duplicate ID belong to you? Here’s what you can do :",
  },
  LOAN_NID_ERROR_OPTION1: {
    id: "LOAN_NID_ERROR_OPTION1",
    defaultMessage:
      "Have you registered on Letsgo earlier? If yes proceed to <custom>Login</custom>.",
  },
  LOAN_NID_ERROR_OPTION2: {
    id: "LOAN_NID_ERROR_OPTION2",
    defaultMessage:
      "If you have never registered on Letsgo before please <custom>request a call back.</custom>",
  },
  UPLOAD_DOCS_TAB_PERSONAL: {
    id: "UPLOAD_DOCS_TAB_PERSONAL",
    defaultMessage: "Personal documents",
  },
  UPLOAD_PROOF_OF_INCOME_TAB: {
    id: "UPLOAD_PROOF_OF_INCOME_TAB",
    defaultMessage: "Income Details",
  },
  PAY_SLIP: { id: "PAY_SLIP", defaultMessage: "Pay slip" },
  PAY_SLIP_OPTIONAL: {
    id: "PAY_SLIP_OPTIONAL",
    defaultMessage: "Pay slip (optional)",
  },
  PASSPORT: { id: "PASSPORT", defaultMessage: "International Passport" },
  PASSPORT_SHORT: { id: "PASSPORT_SHORT", defaultMessage: "Passport" },
  WORK_PERMIT: { id: "WORK_PERMIT", defaultMessage: "Work permit" },
  DRIVERS_LICENSE: { id: "DRIVERS_LICENSE", defaultMessage: "Driving License" },
  PERMANENT_VOTERS_CARD: {
    id: "PERMANENT_VOTERS_CARD",
    defaultMessage: "Permanent Voter’s Card",
  },
  NIMC_UPLOAD_DESC: {
    id: "NIMC_UPLOAD_DESC",
    defaultMessage: "Upload your NIMC front and back.",
  },
  NIMC: { id: "NIMC", defaultMessage: "NIMC" },
  EMPLOYER_ID: { id: "EMPLOYER_ID", defaultMessage: "Employer ID" },
  EMPLOYER_ID_UPLOAD_DESC: {
    id: "EMPLOYER_ID_UPLOAD_DESC",
    defaultMessage: "Upload your Employer ID",
  },
  VOTER_ID: { id: "VOTER_ID", defaultMessage: "Voter ID" },
  VOTER_ID_UPLOAD_DESC: {
    id: "VOTER_ID_UPLOAD_DESC",
    defaultMessage: "Please upload Voter ID",
  },
  GHANA_IDENTITY_CARD: {
    id: "GHANA_ID_CARD",
    defaultMessage: "Ghana Card",
  },
  GHANA_IDENTITY_CARD_NO: {
    id: "GHANA_IDENTITY_CARD_NO",
    defaultMessage: "Ghana Card Number",
  },
  GHANA_IDENTITY_CARD_UPLOAD_DESC: {
    id: "GHANA_ID_CARD_UPLOAD_DESC",
    defaultMessage: "Please upload Ghana Card front and back.",
  },
  SSNIT_CARD: { id: "SSNIT_CARD", defaultMessage: "SSNIT Card" },
  SSNIT_CARD_UPLOAD_DESC: {
    id: "SSNIT_CARD_UPLOAD_DESC",
    defaultMessage: "Please upload SSNIT Card",
  },
  DISBURSEMENT_MODE: {
    id: "DISBURSEMENT_MODE",
    defaultMessage: "Disbursement mode",
  },
  DISBURSEMENT_MODE_HINT: {
    id: "DISBURSEMENT_MODE_HINT",
    defaultMessage:
      "Choose which mode you would like to choose for your disbursement of loan amount",
  },
  MPESA: { id: "MPESA", defaultMessage: "MPESA Wallet" },
  MPESA_UPLOAD_DESC: {
    id: "MPESA_UPLOAD_DESC",
    defaultMessage: "Upload your latest MPESA statement",
  },
  RTGS: { id: "RTGS", defaultMessage: "RTGS" },
  RECOMMENDATION_LETTER: {
    id: "RECOMMENDATION_LETTER",
    defaultMessage: "Recommendation Letter",
  },
  NATIONAL_ID_UPLOAD_DESC: {
    id: "NATIONAL_ID_UPLOAD_DESC",
    defaultMessage: "Upload your National ID front and back.",
  },
  GHANA_CARD_ID_UPLOAD_DESC: {
    id: "GHANA_CARD_ID_UPLOAD_DESC",
    defaultMessage: "Upload your Ghana Card ID front and back.",
  },
  NATIONAL_ID_UPLOAD_DESC_2: {
    id: "NATIONAL_ID_UPLOAD_DESC_2",
    defaultMessage: "Upload your ID document.",
  },
  NUIT_UPLOAD_DESC: {
    id: "NUIT_UPLOAD_DESC",
    defaultMessage: "Upload your NUIT Document",
  },
  NATIONAL_ID_UPLOAD_OCR_DESC_FRONT: {
    id: "NATIONAL_ID_UPLOAD_OCR_DESC_FRONT",
    defaultMessage: "Upload your National ID front.",
  },
  NATIONAL_ID_UPLOAD_OCR_DESC_BACK: {
    id: "NATIONAL_ID_UPLOAD_OCR_DESC_BACK",
    defaultMessage: "Upload your National ID back.",
  },
  PASSPORT_UPLOAD_OCR_DESC_FRONT: {
    id: "PASSPORT_UPLOAD_OCR_DESC_FRONT",
    defaultMessage: "Upload your Passport front with expiry date.",
  },
  PASSPORT_UPLOAD_OCR_DESC_BACK: {
    id: "PASSPORT_UPLOAD_OCR_DESC_BACK",
    defaultMessage: "Upload your Passport back.",
  },
  PASSPORT_UPLOAD_DESC: {
    id: "PASSPORT_UPLOAD_DESC",
    defaultMessage: "Upload your Passport front and back.",
  },
  SAVINGS_PASSPORT_UPLAOD_DESC: {
    id: "SAVINGS_PASSPORT_UPLAOD_DESC",
    defaultMessage: "Upload your front and back of Passport",
  },
  WORK_PERMIT_UPLOAD_DESC: {
    id: "WORK_PERMIT_UPLOAD_DESC",
    defaultMessage: "Upload your work permit",
  },
  PAYSLIP_UPLOAD_DESC: {
    id: "PAYSLIP_UPLOAD_DESC",
    defaultMessage: "Upload your most recent salary slip/pay slip.",
  },
  PAYSLIP_UPLOAD_DESC_LAST_1: {
    id: "PAYSLIP_UPLOAD_DESC_LAST_1",
    defaultMessage: "Upload your last 1 month salary slip/pay slip.",
  },
  PAYSLIP_UPLOAD_DESC_LAST_3: {
    id: "PAYSLIP_UPLOAD_DESC_LAST_3",
    defaultMessage: "Upload your last 3 month salary slip/pay slip.",
  },
  PAYSLIP_UPLOAD_DESC_LAST_2: {
    id: "PAYSLIP_UPLOAD_DESC_LAST_2",
    defaultMessage: "Upload your last 2 month salary slip/pay slip.",
  },
  PAYSLIP_UPLOAD_DESC_NG: {
    id: "PAYSLIP_UPLOAD_DESC_NG",
    defaultMessage:
      "Upload your last 2 months salary slips as proof of employment.",
  },
  BANK_STATEMENT_UPLOAD_DESC: {
    id: "BANK_STATEMENT_UPLOAD_DESC",
    defaultMessage:
      "Upload last 3 months statement for the account where your salary is paid.",
  },
  PERMANENT_VOTERS_CARD_UPLOAD_DESC: {
    id: "PERMANENT_VOTERS_CARD_UPLOAD_DESC",
    defaultMessage: "Upload your Voter ID front and back.",
  },
  EMPLOYEMENT_LETTER_UPLOAD_DESC: {
    id: "EMPLOYEMENT_LETTER_UPLOAD_DESC",
    defaultMessage:
      "Upload a confirmation of employment letter signed and stamped by your employer.",
  },
  PROOF_OF_RESIDENCE_UPLOAD_DESC: {
    id: "PROOF_OF_RESIDENCE_UPLOAD_DESC",
    defaultMessage: "Upload your proof of residence.",
  },
  RECOMMENDATION_LETTER_UPLOAD_DESC: {
    id: "RECOMMENDATION_LETTER_UPLOAD_DESC",
    defaultMessage:
      "Upload a letter of approval signed / stamped by your employer",
  },
  DRIVERS_LICENSE_UPLOAD_DESC: {
    id: "DRIVERS_LICENSE_UPLOAD_DESC",
    defaultMessage: "Upload your Driving License front and back.",
  },
  MARRIAGE_CERTIFICATE: {
    id: "MARRIAGE_CERTIFICATE",
    defaultMessage: "Marriage certificate",
  },
  MARRIAGE_CERTIFICATE_UPLOAD_DESC: {
    id: "MARRIAGE_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Upload your marriage certificate",
  },
  MARRIAGE_REGIME: { id: "MARRIAGE_REGIME", defaultMessage: "Proof of regime" },
  MARRIAGE_REGIME_UPLOAD_DESC: {
    id: "MARRIAGE_REGIME_UPLOAD_DESC",
    defaultMessage: "Upload your proof of regime",
  },

  SETTLEMENT_QUOTATION: {
    id: "SETTLEMENT_QUOTATION",
    defaultMessage: "Loan settlement quotation",
  },
  SETTLEMENT_QUOTATION_UPLOAD_DESC: {
    id: "SETTLEMENT_QUOTATION_UPLOAD_DESC",
    defaultMessage: "Upload loan settlement quotation",
  },
  SETTLEMENT_QUOTATION_UPLOAD_HINT_NOT_UPLOADED: {
    id: "SETTLEMENT_QUOTATION_UPLOAD_HINT_NOT_UPLOADED",
    defaultMessage:
      "Reach out to your loan provider for settlement quotation document",
  },
  SETTLEMENT_QUOTATION_UPLOAD_HINT_UPLOADED: {
    id: "SETTLEMENT_QUOTATION_UPLOAD_HINT_UPLOADED",
    defaultMessage:
      "We will review your settlement request once you submit the loan application",
  },
  SETTLEMENT_QUOTATION_UPLOAD_ADD_MORE: {
    id: "SETTLEMENT_QUOTATION_UPLOAD_ADD_MORE",
    defaultMessage: "Add another quotation",
  },
  UPLOAD_TEXT: {
    id: "UPLOAD_TEXT",
    defaultMessage:
      "Your file should be in any of the following formats: .pdf; .jpeg; .jpg; .png and up-to 10MB in size.",
  },
  UPLOAD_TEXT_WITH_PROTECTED_PASSWORD_TEXT: {
    id: "UPLOAD_TEXT_WITH_PROTECTED_PASSWORD_TEXT",
    defaultMessage:
      "Your file should be in any of the following formats: .pdf; .jpeg; .jpg; .png and up-to 10MB in size. Kindly do not upload password Protected Document",
  },
  UPLOAD_TEXT_OCR: {
    id: "UPLOAD_TEXT_OCR",
    defaultMessage:
      "Your file should be in .jpeg, .jpg, .png formats with less than 10 MB size",
  },
  FRONTBACK_TEXT: { id: "FRONTBACK_TEXT", defaultMessage: "Front and back" },
  UPLOAD_INFO: {
    id: "UPLOAD_INFO",
    defaultMessage: "You can upload up to {pdf} pdf or {image} images files",
  },
  UPLOAD_INFO_2: {
    id: "UPLOAD_INFO_2",
    defaultMessage: "You can upload maximum of {pdf} pdf or {image} images",
  },
  UPLOAD_NOTORIZED_DOCUMENT: {
    id: "UPLOAD_NOTORIZED_DOCUMENT",
    defaultMessage:
      "You can upload up to {pdf} pdf files or {image} images files",
  },
  SAVE_APPLICATION: {
    id: "SAVE_APPLICATION",
    defaultMessage: "Save application",
  },
  DOCUMENT_SIZE_ERROR: {
    id: "DOCUMENT_SIZE_ERROR",
    defaultMessage: "Document uploaded should not exceed 10MB",
  },
  DOCUMENT_SIZE_ERROR_5MB: {
    id: "DOCUMENT_SIZE_ERROR_5MB",
    defaultMessage: "Document uploaded should not exceed 5MB",
  },
  DOCUMENT_TYPE_ERROR: {
    id: "DOCUMENT_TYPE_ERROR",
    defaultMessage:
      "Please ensure the document uploaded is in a jpeg / png format",
  },
  LOAN_SUMMARY_ADDITIONAL_TERM_TEXT_HEADER: {
    id: "LOAN_SUMMARY_ADDITIONAL_TERM_TEXT_HEADER",
    defaultMessage:
      "These terms govern the use of this website. Please read them carefully before accessing the site. If you do not agree with these terms do not access the website. By accessing the website or any of it’s pages you agree to be bound by these terms of use.",
  },
  DISCLAIMER: {
    id: "DISCLAIMER",
    defaultMessage: "Disclaimer",
  },
  ACCEPT: {
    id: "ACCEPT",
    defaultMessage: "Accept",
  },
  TOTAL_PAID: { id: "TOTAL_PAID", defaultMessage: "Total paid" },
  CURRENT_OUTSTANDING: {
    id: "CURRENT_OUTSTANDING",
    defaultMessage: "Current outstanding",
  },
  DOCUMENT_UPLOAD_ERROR: {
    id: "DOCUMENT_UPLOAD_ERROR",
    defaultMessage: "Document could not be uploaded",
  },
  PASSWORD_PROTECTED_FILE: {
    id: "PASSWORD_PROTECTED_FILE",
    defaultMessage: "Please upload non-protected file",
  },
  DOCUMENT_DELETE_ERROR: {
    id: "DOCUMENT_DELETE_ERROR",
    defaultMessage: "Document could not be deleted",
  },
  DOCUMENT_MAX_UPLOADED: {
    id: "DOCUMENT_MAX_UPLOADED",
    defaultMessage: "You have uploaded maximum documents",
  },
  EXISTING_CUSTOMER_LOAN_ACC_TEXT: {
    id: "EXISTING_CUSTOMER_LOAN_ACC_TEXT",
    defaultMessage: "Payroll Loan A/c no.",
  },
  EXISTING_CUSTOMER_INSTANT_LOAN_ACC_TEXT: {
    id: "EXISTING_CUSTOMER_INSTANT_LOAN_ACC_TEXT",
    defaultMessage: "{productName} A/c no.",
  },
  EXISTING_CUSTOMER_LOAN_ACC_TEXT_PART: {
    id: "EXISTING_CUSTOMER_LOAN_ACC_TEXT_PART",
    defaultMessage: "Das loan A/c no.",
  },
  EXISTING_CUSTOMER_NEXT_INSTALLMENT_DATE: {
    id: "EXISTING_CUSTOMER_NEXT_INSTALLMENT_DATE",
    defaultMessage: "Next installment date",
  },
  AMOUNT: { id: "AMOUNT", defaultMessage: "Amount" },
  AMOUNT_TO_PAY: { id: "AMOUNT_TO_PAY", defaultMessage: "Amount to pay" },
  PEP_LISTING_DESCRIPTION: {
    id: "PEP_LISTING_DESCRIPTION",
    defaultMessage:
      "I confirm that I am presently not or have not in the last twelve months been listed as or associated with a\n    Politically Exposed Person (PEP) or a Prominent Influential Person (PIP)",
  },
  WHAT_IS_PEP_PIP_TEXT: {
    id: "WHAT_IS_PEP_PIP_TEXT",
    defaultMessage: "Who is a PEP/PIP?",
  },
  VIEW_HEALTH_DECLARATION: {
    id: "VIEW_HEALTH_DECLARATION",
    defaultMessage: "View health declaration document here",
  },
  WHAT_PEP_PIP_MEANS: {
    id: "WHAT_PEP_PIP_MEANS",
    defaultMessage: "What does PIP mean?",
  },
  PEP_PIP_DESCRIPTION: {
    id: "PEP_PIP_DESCRIPTION",
    defaultMessage:
      "Prominent influential person means a person who is entrusted with public functions within Botswana or by a foreign country, his or her close associates or immediate member of the family or an international organisation and includes —",
  },
  PEP_LISTING_ASSOCIATED: {
    id: "PEP_LISTING_ASSOCIATED",
    defaultMessage: "I confirm listing / association",
  },
  PEP_LISTING_NOT_ASSOCIATED: {
    id: "PEP_LISTING_NOT_ASSOCIATED",
    defaultMessage: "Not listed / associated",
  },
  PEP_TEXT: {
    id: "PEP_TEXT",
    defaultMessage:
      "<custom>Are you </custom> a Politically Exposed person (PEP)?",
  },
  PEP_TEXT_ASSOCIATED: {
    id: "PEP_TEXT_ASSOCIATED",
    defaultMessage:
      "Are you <custom>associated with </custom> a Politically Exposed Person (PEP)?",
  },
  PEP_PIP_TEXT: {
    id: "PEP_PIP_TEXT",
    defaultMessage:
      "<custom>Are you </custom> a Politically Exposed person or a Prominent Influential Person (PEP/PIP)?",
  },
  PEP_PIP_TEXT_ASSOCIATED: {
    id: "PEP_PIP_TEXT_ASSOCIATED",
    defaultMessage:
      "Are you <custom>associated with </custom> a Politically Exposed Person or a Prominent Influential Person (PEP/PIP)?",
  },
  DISBUSRED: { id: "DISBUSRED", defaultMessage: "Disbursal" },
  APPROVAL: { id: "APPROVAL", defaultMessage: "Approval" },
  IN_REVIEW: { id: "IN_REVIEW", defaultMessage: "In review" },
  ACCEPTED: { id: "ACCEPTED", defaultMessage: "Accepted" },
  SUBMITTED: { id: "SUBMITTED", defaultMessage: "Submitted" },
  APPROVED: { id: "APPROVED", defaultMessage: "Approved" },
  REVIEW: { id: "REVIEW", defaultMessage: "Under review" },
  CUSTOMER_SERVICE_HELP_TEXT: {
    id: "CUSTOMER_SERVICE_HELP_TEXT",
    defaultMessage:
      "Track or cancel your loan application by calling {phoneNo} (available from 8AM - 4PM)",
  },
  CUSTOMER_SERVICE_HELP_TEXT_UG: {
    id: "CUSTOMER_SERVICE_HELP_TEXT_UG",
    defaultMessage:
      "Track or cancel your application by reaching us on WhatsApp {watsapp} or call us directly on {phoneNo} (8AM - 5PM Mon to Fri / 9AM -12PM on Sat)",
  },
  DISBURSAL_LOAN_OFFER_ACCEPTED: {
    id: "DISBURSAL_LOAN_OFFER_ACCEPTED",
    defaultMessage: "Loan offer accepted",
  },
  DISBURSAL_INPROGRESS_MSG: {
    id: "DISBURSAL_INPROGRESS_MSG",
    defaultMessage: "Disbursal may take upto 24-48 hrs",
  },
  HOURS_SHORT_LABEL: {
    id: "HOURS_SHORT_LABEL",
    defaultMessage: "hrs",
  },
  MINUTES_SHORT_LABEL: {
    id: "MINUTES_SHORT_LABEL",
    defaultMessage: "mins",
  },
  DISBURSAL_LOAN_DISBURSED: {
    id: "DISBURSAL_LOAN_DISBURSED",
    defaultMessage: "Your loan has been disbursed",
  },
  DISBURSAL_LOAN_DISBURSED_MSG: {
    id: "DISBURSAL_LOAN_DISBURSED_MSG",
    defaultMessage:
      "Loan amount of {loanAmountValue} has been disbursed to your {accountNumberValue} account.",
  },
  DISBURSAL_LOAN_DISBURSED_ACTION: {
    id: "DISBURSAL_LOAN_DISBURSED_ACTION",
    defaultMessage: "View loan",
  },
  ACTION_REQUIRED_TEXT: {
    id: "ACTION_REQUIRED_TEXT",
    defaultMessage: "For faster review, please complete the below: ",
  },
  ID_VERIFICATION: { id: "ID_VERIFICATION", defaultMessage: "ID verification" },
  SPOUSE_CONSENT: { id: "SPOUSE_CONSENT", defaultMessage: "Spousal Consent" },
  NEXT_OF_KIN: { id: "NEXT_OF_KIN", defaultMessage: "Next of Kin" },
  BANK_DETAILS: { id: "BANK_DETAILS", defaultMessage: "Bank details" },
  RE_UPLOAD_DOCUMENTS: {
    id: "RE_UPLOAD_DOCUMENTS",
    defaultMessage: "Re-upload documents",
  },
  ID_VERIFICATION_DESCRIPTION: {
    id: "ID_VERIFICATION_DESCRIPTION",
    defaultMessage:
      "We require your ID for verification and to release the loan funds.",
  },
  ID_VERIFICATION_DESCRIPTION_IN_PROGRESS: {
    id: "ID_VERIFICATION_DESCRIPTION_IN_PROGRESS",
    defaultMessage:
      "Our team has received your documents and is verifying your identity",
  },
  NOTARIZEDOCUMENT_VERIFICATION: {
    id: "NOTARIZEDOCUMENT_VERIFICATION",
    defaultMessage: "Upload signed and stamped documents",
  },
  NOTARIZEDOCUMENT_VERIFICATION_DESCRIPTION: {
    id: "NOTARIZEDOCUMENT_VERIFICATION_DESCRIPTION",
    defaultMessage:
      "We require you to get these documents notarized in order to disburse your loan amount",
  },
  NOTARIZEDOCUMENT_VERIFICATION_IN_PROGRESS: {
    id: "NOTARIZEDOCUMENT_VERIFICATION_IN_PROGRESS",
    defaultMessage:
      "Our team has received your documents and is verifying your identity",
  },
  CARD_DETAILS_VERIFICATION: {
    id: "CARD_DETAILS_VERIFICATION",
    defaultMessage: "Provide bank card details",
  },
  CARD_DETAILS_VERIFICATION_DESCRIPTION: {
    id: "CARD_DETAILS_VERIFICATION_DESCRIPTION",
    defaultMessage: "You will need to link your bank card to avail this loan.",
  },
  CARD_DETAILS_VERIFICATION_IN_PROGRESS: {
    id: "CARD_DETAILS_VERIFICATION_IN_PROGRESS",
    defaultMessage:
      "Our team has received your documents and is verifying your identity",
  },
  SPOUSE_CONSENT_DESCRIPTION: {
    id: "SPOUSE_CONSENT_DESCRIPTION",
    defaultMessage:
      "Get your spouse's consent for this loan by sending a link to them or upload a signed consent letter.",
  },
  SPOUSE_CONSENT_DESCRIPTION_IN_PROGRESS: {
    id: "SPOUSE_CONSENT_DESCRIPTION_IN_PROGRESS",
    defaultMessage:
      "Spousal consent has been initiated. We will review it shortly",
  },
  BANK_DETAILS_DESCRIPTION: {
    id: "BANK_DETAILS_DESCRIPTION",
    defaultMessage:
      "You need to confirm your bank details to receive the loan amount",
  },
  RE_UPLOAD_DOCUMENTS_DESCRIPTION: {
    id: "RE_UPLOAD_DOCUMENTS_DESCRIPTION",
    defaultMessage:
      "We found a few errors in the document uploaded ealier. Please re-upload mentioned documents.",
  },
  NEXT_OF_KIN_DESCRIPTION: {
    id: "NEXT_OF_KIN_DESCRIPTION",
    defaultMessage: "You will need to enter details of your next of Kin",
  },
  EMPLOYMENT_CONFIRMATION: {
    id: "EMPLOYMENT_CONFIRMATION",
    defaultMessage: "Employment confirmation",
  },
  EMPLOYMENT_CONFIRMATION_DESCRIPTION: {
    id: "EMPLOYMENT_CONFIRMATION_DESCRIPTION",
    defaultMessage:
      "We require an Employment confirmation letter from your employer confirming your employment.",
  },
  EMPLOYER_VERIFICATION_DESCRIPTION_IN_PROGRESS: {
    id: "EMPLOYER_VERIFICATION_DESCRIPTION_IN_PROGRESS",
    defaultMessage:
      "Our team has received your documents and is verifying your documents",
  },
  EMPLOYER_AUTHORIZATION: {
    id: "EMPLOYER_AUTHORIZATION",
    defaultMessage: "Employer authorization",
  },
  EMPLOYER_AUTHORIZATION_DESCRIPTION: {
    id: "EMPLOYER_AUTHORIZATION_DESCRIPTION",
    defaultMessage:
      "We require an Employer authorization letter from your employer confirming the loan debit mandate.",
  },
  DAS_APPLICATION: {
    id: "DAS_APPLICATION",
    defaultMessage: "Application number",
  },
  REVIEW_APP_MESSAGE: {
    id: "REVIEW_APP_MESSAGE",
    defaultMessage:
      " Complete the actions listed on the right, to help us {status} your loan application.",
  },
  YOUR_APP_TEXT: {
    id: "YOUR_APP_TEXT",
    defaultMessage: " Your application is",
  },
  APP_STATUS_TEXT: { id: "APP_STATUS_TEXT", defaultMessage: "{status}" },
  APP_STATUS_MESSAGE: { id: "APP_STATUS_MESSAGE", defaultMessage: "review" },
  PEP_OR_PIP_ASSOCIATION_LABEL: {
    id: "PEP_OR_PIP_ASSOCIATION_LABEL",
    defaultMessage: "We are reviewing your details",
  },
  PEP_OR_PIP_ASSOCIATION_TEXT: {
    id: "PEP_OR_PIP_ASSOCIATION_TEXT",
    defaultMessage:
      "Because you have been associated with a PEP/PIP,\n      our customer service team will contact you shortly to get few additional details for the loan application.",
  },
  REVIEW_CONTRACT_DOC_LABEL: {
    id: "REVIEW_CONTRACT_DOC_LABEL",
    defaultMessage: "Loan Contract Documents",
  },
  REVIEW_CONTRACT_DOC_LABEL_NIGERIA: {
    id: "REVIEW_CONTRACT_DOC_LABEL_NIGERIA",
    defaultMessage:
      "View your <custom>Loan Contract Documents</custom> for loan details and terms and conditions",
  },
  LOAN_OFFER_REVIEW_TERM_AND_CONDITION_LABEL: {
    id: "LOAN_OFFER_REVIEW_TERM_AND_CONDITION_LABEL",
    defaultMessage:
      "I accept that I have read and agreed to all the terms & conditions in the contract documents.",
  },
  REVIEW_LOAN_OFFER: {
    id: "REVIEW_LOAN_OFFER",
    defaultMessage: "Review loan offer",
  },
  ACCEPT_LOAN_OFFER: {
    id: "ACCEPT_LOAN_OFFER",
    defaultMessage: "Accept loan offer and contract",
  },
  ACCEPT_LOAN_OFFER_AND_PROCEED: {
    id: "ACCEPT_LOAN_OFFER_AND_PROCEED",
    defaultMessage: "Accept and Proceed",
  },
  REJECT_LOAN_OFFER_BUTTON: {
    id: "REJECT_LOAN_OFFER_BUTTON",
    defaultMessage: "Reject loan offer",
  },
  REJECT_LOAN_OFFER_BUTTON_TEXT: {
    id: "REJECT_LOAN_OFFER_BUTTON_TEXT",
    defaultMessage: "Reject the Offer",
  },
  APPROVED_FOR_LABEL: {
    id: "APPROVED_FOR_LABEL",
    defaultMessage: "You are approved for  ",
  },
  LOAN_PERIOD_LABEL: { id: "LOAN_PERIOD_LABEL", defaultMessage: "Loan period" },
  TOTAL_AMOUNT_LABEL: {
    id: "TOTAL_AMOUNT_LABEL",
    defaultMessage: "Total amount",
  },
  MONTHLY_INSTALLMENT_LABEL: {
    id: "MONTHLY_INSTALLMENT_LABEL",
    defaultMessage: "Monthly Installment",
  },
  PANEL_DISBURSAL_INPROGRESS_TITLE: {
    id: "PANEL_DISBURSAL_INPROGRESS_TITLE",
    defaultMessage: "Disbursal in progress",
  },
  PANEL_DISBURSAL_INPROGRESS_SUBTITLE: {
    id: "PANEL_DISBURSAL_INPROGRESS_SUBTITLE",
    defaultMessage: "This process takes upto {time} {timeUnit}",
  },
  PANEL_DISBURSAL_INPROGRESS_MSG_AMOUNT: {
    id: "PANEL_DISBURSAL_INPROGRESS_MSG_AMOUNT",
    defaultMessage:
      "We have initiated the transfer of loan amount {loanAmountValue} towards your account {accountNumberValue}, it takes upto 48 hours to reflect.",
  },
  PANEL_DISBURSAL_INPROGRESS_MSG_DOCS: {
    id: "PANEL_DISBURSAL_INPROGRESS_MSG_DOCS",
    defaultMessage:
      "Your loan contract documents have been sent to {emailIDValue}",
  },
  ACCOUNT_DETAILS_TITLE: {
    id: "ACCOUNT_DETAILS_TITLE",
    defaultMessage: "Account Details",
  },
  ACCOUNT_DETAILS_SUB_TITLE: {
    id: "ACCOUNT_DETAILS_SUB_TITLE",
    defaultMessage:
      "   Details of bank account where you will receive your funds",
  },
  ACCOUNT_DETAILS_SUBTITILE: {
    id: "ACCOUNT_DETAILS_SUBTITILE",
    defaultMessage:
      "Details of your salary bank account where you will receive funds.",
  },
  ACCOUNT_NAME_LABEL: {
    id: "ACCOUNT_NAME_LABEL",
    defaultMessage: "Account name",
  },
  ACCOUNT_NUMBER_LABEL: {
    id: "ACCOUNT_NUMBER_LABEL",
    defaultMessage: "Account number",
  },
  ACCOUNT_NUMBER_LABEL_MOZ: {
    id: "ACCOUNT_NUMBER_LABEL_MOZ",
    defaultMessage: "NIB",
  },
  BRANCH_LABEL: { id: "BRANCH_LABEL", defaultMessage: "Branch" },
  BANK_NAME_LABEL: { id: "BANK_NAME_LABEL", defaultMessage: "Bank Name" },
  ACCOUNT_DETAILS_FOOTER: {
    id: "ACCOUNT_DETAILS_FOOTER",
    defaultMessage:
      "If these bank details are not correct, please contact our customer service at {phoneNo} (available from 8AM - 4PM)",
  },
  ACCOUNT_DETAILS_FOOTER_UG: {
    id: "ACCOUNT_DETAILS_FOOTER_UG",
    defaultMessage:
      "Track or cancel your application by reaching us on WhatsApp {watsapp} or call us directly on {phoneNo} (8AM - 5PM Mon to Fri / 9AM -12PM on Sat)",
  },
  EXISTING_CUSTOMER_TAILOR_TEXT: {
    id: "EXISTING_CUSTOMER_TAILOR_TEXT",
    defaultMessage: "Tailored loan offer just for you!",
  },
  EXISTING_CUSTOMER_TAILOR_MULTIPLE_OFFER: {
    id: "EXISTING_CUSTOMER_TAILOR_MULTIPLE_OFFER",
    defaultMessage: "Tailored top-up loan offer just for you!",
  },
  EXISTING_CUSTOMER_TAILOR_MULTIPLE_NEW_OFFER: {
    id: "EXISTING_CUSTOMER_TAILOR_MULTIPLE_NEW_OFFER",
    defaultMessage: "Tailored new loan offer just for you!",
  },
  BORROW_UP_TO: { id: "BORROW_UP_TO", defaultMessage: "Borrow up to" },
  FOR_TENURE_UP_TO: {
    id: "FOR_TENURE_UP_TO",
    defaultMessage: "For tenure up to",
  },
  TAILOER_INSURANCE_TEXT: {
    id: "TAILOER_INSURANCE_TEXT",
    defaultMessage:
      "These rates are inclusive of a {value}% insurance rate and Letshego insurance.",
  },
  MULTIPLE_TOP_UP_TEXT: {
    id: "MULTIPLE_TOP_UP_TEXT",
    defaultMessage:
      " Apply for this top-up offer for your existing loan {value} and get your loan disbursed within 48 hours!",
  },
  MULTIPLE_NEW_LOAN_TEXT: {
    id: "MULTIPLE_NEW_LOAN_TEXT",
    defaultMessage:
      "Apply for this new loan  offer and get your loan disbursed within 48 hours!",
  },
  TAILORED_ACCEPT_OFFER_TEXT: {
    id: "TAILORED_ACCEPT_OFFER_TEXT",
    defaultMessage: "Accept offer to get loan disbursed within 48 hours!",
  },
  APPLY: { id: "APPLY", defaultMessage: "Apply" },
  WHATS_APP_TEXT: { id: "WHATS_APP_TEXT", defaultMessage: "WhatsApp" },
  WHATS_APP_TEXT_CONTENT: {
    id: "WHATS_APP_TEXT_CONTENT",
    defaultMessage:
      "Save {phoneNo} as Letshego contact. Say ‘Hi’ to and start interacting with Letshego on WhatsApp.",
  },
  WHATSAPP_CONTENT_ICON_TEXT: {
    id: "WHATSAPP_CONTENT_ICON_TEXT",
    defaultMessage:
      "Bank is always available to answers to your queries on our products",
  },
  WHATSAPP_CONTENT_SECURE_ICON_TEXT: {
    id: "WHATSAPP_CONTENT_SECURE_ICON_TEXT",
    defaultMessage: "It is secure! (End to end encryption)",
  },
  WHATSAPP_CONTENT_CHAT_TEXT: {
    id: "WHATSAPP_CONTENT_CHAT_TEXT",
    defaultMessage: "Chat on WhatsApp",
  },
  CALL_US_TEXT: { id: "CALL_US_TEXT", defaultMessage: "Call us" },
  CALL_US_TEXT_HEADER: {
    id: "CALL_US_TEXT_HEADER",
    defaultMessage: "Call us on {phoneNo} for service related queries.",
  },
  ACCEPTED_OFFER_HEADER: {
    id: "ACCEPTED_OFFER_HEADER",
    defaultMessage: "You have accepted the loan offer!",
  },
  ACCEPTED_OFFER_SUB_HEADER: {
    id: "ACCEPTED_OFFER_SUB_HEADER",
    defaultMessage:
      "Your loan offer of {amount} will be disbursed to your {accountNumber} account.",
  },
  ACCEPTED_OFFER_SUB10_SUB_HEADER: {
    id: "ACCEPTED_OFFER_SUB10_SUB_HEADER",
    defaultMessage:
      "Loan amount of {amount} will be disbursed to your {accountNumber} account.",
  },
  ACCEPTED_OFFER_INFO: {
    id: "ACCEPTED_OFFER_INFO",
    defaultMessage:
      "It may take 24 - 48 hours for the amount to reflect in your account",
  },
  ACCEPT_LOAN_OFFER_DESCRIPTION: {
    id: "ACCEPT_LOAN_OFFER_DESCRIPTION",
    defaultMessage:
      "Carefully review final loan offer and contract documents before accepting the final offer. ",
  },
  LOAN_OFFER_EXPIRES_INFO: {
    id: "LOAN_OFFER_EXPIRES_INFO",
    defaultMessage: "Your loan offer will expires in {daysCount} on {date}",
  },
  LOAN_OFFER_EXPIRES_TODAY: {
    id: "LOAN_OFFER_EXPIRES_TODAY",
    defaultMessage: "Your loan offer will expire today",
  },
  LOAN_CONSOLIDATION_CARD_TITLE: {
    id: "LOAN_CONSOLIDATION_CARD_TITLE",
    defaultMessage: "Want to buy over your existing loan?",
  },
  LOAN_CONSOLIDATION_CARD_MSG: {
    id: "LOAN_CONSOLIDATION_CARD_MSG",
    defaultMessage:
      "Save on monthly payments by consolidating your payroll loans with us!",
  },
  LOAN_CONSOLIDATION_CARD_ACTION_NC: {
    id: "LOAN_CONSOLIDATION_CARD_ACTION_NC",
    defaultMessage: "Get started by checking eligibility",
  },
  LOAN_CONSOLIDATION_CARD_ACTION_EC: {
    id: "LOAN_CONSOLIDATION_CARD_ACTION_EC",
    defaultMessage: "Apply for loan",
  },
  SECURITY_MESSAGE_TOAST_PART_LOAN: {
    id: "SECURITY_MESSAGE_TOAST_PART_LOAN",
    defaultMessage:
      "You have a partially disbursed loan. Please go to the nearest branch or call  {branchphoneno}  to enable you to top up.",
  },
  LOAN_ACCOUNT_DETAILS_PAYSLIP_TEXT: {
    id: "LOAN_ACCOUNT_DETAILS_PAYSLIP_TEXT",
    defaultMessage:
      "Details of your salary bank account where you will receive funds.If you have provided an updated payslip in the previous step, these details will get updated accordingly.",
  },
  HOW_IT_WORK: { id: "HOW_IT_WORK", defaultMessage: "How it works" },
  GET_A_NEW_LOAN: {
    id: "GET_A_NEW_LOAN",
    defaultMessage: "Get a new loan or top-up",
  },
  THREE_STEP_TEXT: {
    id: "THREE_STEP_TEXT",
    defaultMessage: "In 3 easy steps within 48 hours,",
  },
  SECURITY_MESSAGE_TOAST_LOAN_RESTRICTED: {
    id: "SECURITY_MESSAGE_TOAST_LOAN_RESTRICTED",
    defaultMessage:
      "You can apply for a top-up only after paying the first installment of your new loan",
  },
  TERM_CONDITION_APPLY_TEXT: {
    id: "TERM_CONDITION_APPLY_TEXT",
    defaultMessage: "Terms and conditions apply*",
  },
  GET_STARTED_TEXT: {
    id: "GET_STARTED_TEXT",
    defaultMessage:
      "Get started on LetsGo today, download our app or simply open in web browser.",
  },
  COMMING_SOON: { id: "COMMING_SOON", defaultMessage: "Coming soon on" },
  PENDING: { id: "PENDING", defaultMessage: "Pending" },
  COMPLETED: { id: "COMPLETED", defaultMessage: "Completed" },
  PENDING_APPROVAL: {
    id: "PENDING_APPROVAL",
    defaultMessage: "Pending Approval",
  },
  REJECTED: { id: "REJECTED", defaultMessage: "Rejected" },
  FAILED: { id: "FAILED", defaultMessage: "Failed" },
  UNKNOWN: { id: "UNKNOWN", defaultMessage: "Unknown" },
  IN_PROGRESS: { id: "IN_PROGRESS", defaultMessage: "In Progress" },
  IN_PROCESSING: { id: "IN_PROCESSING", defaultMessage: "Processing" },
  IN_PROCESSING_TEXT: {
    id: "IN_PROCESSING_TEXT",
    defaultMessage: "Undergoing verification",
  },
  IN_PROCESSING_SUB_TEXT: {
    id: "IN_PROCESSING_SUB_TEXT",
    defaultMessage:
      "To support your application, we may need more information from you. We will keep you informed.",
  },
  NO_ACTION_REQUIRED_MESSAGE: {
    id: "NO_ACTION_REQUIRED_MESSAGE",
    defaultMessage:
      "No actions required from you right now. We will reach out in case we need any more information",
  },
  SPOUSE_CONSENT_STEP_HELP_TEXT: {
    id: "SPOUSE_CONSENT_STEP_HELP_TEXT",
    defaultMessage: "In just 3 simple steps",
  },
  SPOUSE_CONSENT_STEP1: {
    id: "SPOUSE_CONSENT_STEP1",
    defaultMessage: "Select how to request consent",
  },
  SPOUSE_CONSENT_STEP2: {
    id: "SPOUSE_CONSENT_STEP2",
    defaultMessage: "Spouse gives consent",
  },
  SPOUSE_CONSENT_STEP3: {
    id: "SPOUSE_CONSENT_STEP3",
    defaultMessage: "Letshego reviews consent",
  },
  SPOUSE_CONSENT_INFO_TEXT: {
    id: "SPOUSE_CONSENT_INFO_TEXT",
    defaultMessage:
      "Since you are married in the 'Community of Property' regime, spousal consent is required.",
  },
  SPOUSE_CONSENT_DECLARATION: {
    id: "SPOUSE_CONSENT_DECLARATION",
    defaultMessage:
      "I hereby certify that my spouse's details are true and correct for this application.",
  },
  SPOUSE_CONSENT_PROVIDE: {
    id: "SPOUSE_CONSENT_PROVIDE",
    defaultMessage: "Provide spouse consent",
  },
  SPOUSE_LAST_NAME: {
    id: "SPOUSE_LAST_NAME",
    defaultMessage: "Spouse Name",
  },
  SPOUSE_CONSENT_MOBILE_NO: {
    id: "SPOUSE_CONSENT_MOBILE_NO",
    defaultMessage: "Provide spouse mobile number",
  },
  SPOUSE_CONSENT_TYPE_UPLOAD: {
    id: "SPOUSE_CONSENT_TYPE_UPLOAD",
    defaultMessage: "Upload physical consent letter",
  },
  SPOUSE_CONSENT_TYPE_SMS: {
    id: "SPOUSE_CONSENT_TYPE_SMS",
    defaultMessage: "Send a link to your spouse's mobile number",
  },
  SPOUSE_CONSENT_TYPE_SMS_HELP: {
    id: "SPOUSE_CONSENT_TYPE_SMS_HELP",
    defaultMessage: "We will send your spouse the consent link on this number.",
  },
  SPOUSE_CONSENT_TYPE_SMS_ACTION: {
    id: "SPOUSE_CONSENT_TYPE_SMS_ACTION",
    defaultMessage: "Send spouse request",
  },
  SPOUSE_CONSENT_TYPE_UPLOAD_ACTION: {
    id: "SPOUSE_CONSENT_TYPE_UPLOAD_ACTION",
    defaultMessage: "Submit consent",
  },
  SPOUSE_CONSENT_LETTER: {
    id: "SPOUSE_CONSENT_LETTER",
    defaultMessage: "Spousal consent letter",
  },
  SPOUSE_CONSENT_LETTER_HELP_TEXT: {
    id: "SPOUSE_CONSENT_LETTER_HELP_TEXT",
    defaultMessage:
      "Upload a clear picture of a signed spousal consent letter.",
  },
  SPOUSE_CONSENT_FAQ_TITLE: {
    id: "SPOUSE_CONSENT_FAQ_TITLE",
    defaultMessage: "Why get spousal consent?",
  },
  SPOUSE_CONSENT_REQUEST_SENT: {
    id: "SPOUSE_CONSENT_REQUEST_SENT",
    defaultMessage: "Consent request sent!",
  },
  SPOUSE_CONSENT_REQUEST_SENT_HELP: {
    id: "SPOUSE_CONSENT_REQUEST_SENT_HELP",
    defaultMessage: "We will keep you updated about the loan request.",
  },
  SPOUSE_CONSENT_DOWNLOAD_LINK: {
    id: "SPOUSE_CONSENT_DOWNLOAD_LINK",
    defaultMessage: "View sample consent",
  },
  SPOUSE_CONSENT_SAMPLE_DOC: {
    id: "SPOUSE_CONSENT_SAMPLE_DOC",
    defaultMessage: "Sample consent",
  },
  SPOUSE_CONSENT_UPLOAD_DOC_RULE_1: {
    id: "SPOUSE_CONSENT_UPLOAD_DOC_RULE_1",
    defaultMessage: "All content from sample should be captured",
  },
  SPOUSE_CONSENT_UPLOAD_DOC_RULE_2: {
    id: "SPOUSE_CONSENT_UPLOAD_DOC_RULE_2",
    defaultMessage: "It must be signed by your spouse",
  },
  SPOUSE_CONSENT_UPLOAD_DOC_RULE_3: {
    id: "SPOUSE_CONSENT_UPLOAD_DOC_RULE_3",
    defaultMessage: "Police stamp / signature is not required",
  },
  SPOUSE_CONSENT_SUBMITTED: {
    id: "SPOUSE_CONSENT_SUBMITTED",
    defaultMessage: "Spousal consent submitted.",
  },
  FAQ: { id: "FAQ", defaultMessage: "FAQ's" },
  FAQ_SAVINGS: { id: "FAQ_SAVINGS", defaultMessage: "FAQs" },
  LOANS: { id: "LOANS", defaultMessage: "Loans" },
  ACCOUNTS: { id: "ACCOUNTS", defaultMessage: "Accounts" },
  PAYROLL_LOAN: { id: "PAYROLL_LOAN", defaultMessage: "Payroll loan" },
  MICROFINANCE_LOAN: {
    id: "MICROFINANCE_LOAN",
    defaultMessage: "Microfinance loan",
  },
  NAV_HOW_IT_WORKS: { id: "NAV_HOW_IT_WORKS", defaultMessage: "How it works" },
  NAV_ELIGIBILITY: { id: "NAV_ELIGIBILITY", defaultMessage: "Eligibility" },
  NAV_LOAN_CALCULATOR: {
    id: "NAV_LOAN_CALCULATOR",
    defaultMessage: "Loan calculator",
  },
  NAV_FAQS: { id: "NAV_FAQS", defaultMessage: "FAQs" },
  NAV_CONTACT_US: { id: "NAV_CONTACT_US", defaultMessage: "Contact us" },
  NAV_LOGIN: { id: "NAV_LOGIN", defaultMessage: "Login" },
  NAV_GET_STARTED: { id: "NAV_GET_STARTED", defaultMessage: "Get Started" },
  FEATURE_SIMPLE_STEPS_HEADING: {
    id: "FEATURE_SIMPLE_STEPS_HEADING",
    defaultMessage: "Simple steps to get a loan",
  },
  FEATURE_SIMPLE_STEPS_PARA: {
    id: "FEATURE_SIMPLE_STEPS_PARA",
    defaultMessage: "Apply online and eliminate the hustle of any paperwork",
  },
  FEATURE_DATA_SECURE_HEADING: {
    id: "FEATURE_DATA_SECURE_HEADING",
    defaultMessage: "Your data is secure with us",
  },
  FEATURE_DATA_SECURE_PARA: {
    id: "FEATURE_DATA_SECURE_PARA",
    defaultMessage: "Your information is safe with latest security measures",
  },
  FEATURE_FLEXIBLE_TERMS_HEADING: {
    id: "FEATURE_FLEXIBLE_TERMS_HEADING",
    defaultMessage: "Flexible terms ",
  },
  FEATURE_FLEXIBLE_TERMS_PARA: {
    id: "FEATURE_FLEXIBLE_TERMS_PARA",
    defaultMessage: "Select your terms to match your needs",
  },
  FEATURE_INTERACTIVE_HEADING: {
    id: "FEATURE_INTERACTIVE_HEADING",
    defaultMessage: "Interactive experience",
  },
  FEATURE_INTERACTIVE_PARA: {
    id: "FEATURE_INTERACTIVE_PARA",
    defaultMessage: "We are here to support you on your journey",
  },
  BANNER_HEADING_PRIMARY_NG: {
    id: "BANNER_HEADING_PRIMARY_NG",
    defaultMessage: "Its time to rise up",
  },
  BANNER_HEADING_SECONDARY_NG: {
    id: "BANNER_HEADING_SECONDARY_NG",
    defaultMessage: "Against the odds",
  },
  BANNER_HEADING_CTA1_NG: {
    id: "BANNER_HEADING_CTA1_NG",
    defaultMessage: "Explore",
  },
  BANNER_HEADING_CTA2_NG: {
    id: "BANNER_HEADING_CTA2_NG",
    defaultMessage: "View TVC",
  },
  BANNER_HEADING_PRIMARY: {
    id: "BANNER_HEADING_PRIMARY",
    defaultMessage: "Get a loan",
  },
  BANNER_HEADING_SECONDARY: {
    id: "BANNER_HEADING_SECONDARY",
    defaultMessage: "Anytime anywhere",
  },
  BANNER_HEADING_PARA: {
    id: "BANNER_HEADING_PARA",
    defaultMessage: "#LetsGo for round-the-clock solutions and services",
  },
  BANNER_HEADING_CTA: {
    id: "BANNER_HEADING_CTA",
    defaultMessage: "Get Started",
  },
  BANNER_HEADING_PRIMARY_SECOND: {
    id: "BANNER_HEADING_PRIMARY_SECOND",
    defaultMessage: "Get easy and",
  },
  BANNER_HEADING_SECONDARY_SECOND: {
    id: "BANNER_HEADING_SECONDARY_SECOND",
    defaultMessage: "Fast loans with LetsGo",
  },
  BANNER_HEADING_PARA_SECOND: {
    id: "BANNER_HEADING_PARA_SECOND",
    defaultMessage:
      "Like A-B-C! Eliminate the hustle of paperwork to get started",
  },
  BANNER_HEADING_CTA_SECOND: {
    id: "BANNER_HEADING_CTA_SECOND",
    defaultMessage: "Get Started",
  },
  BANNER_HEADING_PRIMARY_THIRD: {
    id: "BANNER_HEADING_PRIMARY_THIRD",
    defaultMessage: "Get quick & easy",
  },
  BANNER_HEADING_SECONDARY_THIRD: {
    id: "BANNER_HEADING_SECONDARY_THIRD",
    defaultMessage: "Top-up loans",
  },
  BANNER_HEADING_PARA_THIRD: {
    id: "BANNER_HEADING_PARA_THIRD",
    defaultMessage:
      "Quick top-ups in 3 easy steps, select your terms to match your needs!",
  },
  BANNER_HEADING_CTA_THIRD: {
    id: "BANNER_HEADING_CTA_THIRD",
    defaultMessage: "Get Started",
  },
  DISCOVERY_FOOTER_GET_IN_TOUCH: {
    id: "DISCOVERY_FOOTER_GET_IN_TOUCH",
    defaultMessage: "Get in touch",
  },
  DISCOVERY_FOOTER_LETS_CONNECT: {
    id: "DISCOVERY_FOOTER_LETS_CONNECT",
    defaultMessage: "Let’s connect",
  },
  DISCOVERY_FEEL_FREE_TEXT: {
    id: "DISCOVERY_FEEL_FREE_TEXT",
    defaultMessage:
      "Feel free to reach out to us, we would like to hear from you",
  },
  DISCOVERY_CONTACT_US_AT: {
    id: "DISCOVERY_CONTACT_US_AT",
    defaultMessage: "Contact us at",
  },
  DISCOVERY_HEAD_OFFICE: {
    id: "DISCOVERY_HEAD_OFFICE",
    defaultMessage: "Our head office",
  },
  DISCOVERY_HEAD_OFFICE_BOTSWANA: {
    id: "DISCOVERY_HEAD_OFFICE_BOTSWANA",
    defaultMessage: "Lot 54350 Zambezi Tower C, Gaborone CBD, Botswana",
  },
  SERENGETI_ADDRESS_TZ: {
    id: "SERENGETI_ADDRESS_TZ",
    defaultMessage:
      "Vodacom Towers – Paloma Park, Plot 23 Ursino Estate, P.O. Box 6137, Dar-es-Salaam, Tanzania",
  },
  DISCOVERY_HEAD_OFFICE_MZ_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_MZ_LINE_1",
    defaultMessage: "Av. Albert Lithulu Nr. 15,",
  },
  DISCOVERY_HEAD_OFFICE_MZ_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_MZ_LINE_2",
    defaultMessage: "6 andar, Maputo  Moçambique",
  },
  DISCOVERY_HEAD_OFFICE_LS_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_LS_LINE_1",
    defaultMessage: "Old Maseru Toyota Building,",
  },
  DISCOVERY_HEAD_OFFICE_LS_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_LS_LINE_2",
    defaultMessage: "20 Bowker Road, P.O Box 13670, Maseru ",
  },
  DISCOVERY_HEAD_OFFICE_SZ_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_SZ_LINE_1",
    defaultMessage: "Ground Floor Ingcongwane",
  },
  DISCOVERY_HEAD_OFFICE_SZ_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_SZ_LINE_2",
    defaultMessage: "Building Gwamile Street",
  },
  DISCOVERY_HEAD_OFFICE_SZ_LINE_3: {
    id: "DISCOVERY_HEAD_OFFICE_SZ_LINE_3",
    defaultMessage: " Mbabane, Eswatini",
  },
  DISCOVERY_HEAD_OFFICE_TANZANIA_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_TANZANIA_LINE_1",
    defaultMessage: "Letshego Tanzania Limited t/a",
  },
  DISCOVERY_HEAD_OFFICE_TANZANIA_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_TANZANIA_LINE_2",
    defaultMessage: "Vodacom Towers – Paloma",
  },
  DISCOVERY_HEAD_OFFICE_NA_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_UG_LINE_1",
    defaultMessage: "18 Scweringsburg Street,",
  },
  DISCOVERY_HEAD_OFFICE_NA_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_UG_LINE_2",
    defaultMessage: "Windhoek, Namibia",
  },
  COUNTRY_NAME_NAMIBIA: {
    id: "COUNTRY_NAME_NAMIBIA",
    defaultMessage: "Namibia",
  },
  FOREIGN_NATIONAL: {
    id: "FOREIGN_NATIONAL",
    defaultMessage: "Foreign National",
  },
  DISCOVERY_HEAD_OFFICE_UG_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_UG_LINE_1",
    defaultMessage: "Letshego Uganda Limited",
  },
  DISCOVERY_HEAD_OFFICE_UG_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_UG_LINE_2",
    defaultMessage: "Plot 9, Wampewo Avenue, Kololo",
  },
  DISCOVERY_HEAD_OFFICE_UG_LINE_3: {
    id: "DISCOVERY_HEAD_OFFICE_UG_LINE_3",
    defaultMessage: "Kampala, Uganda.",
  },
  DISCOVERY_HEAD_OFFICE_GHANA_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_GHANA_LINE_1",
    defaultMessage: "4th Floor Cocoshe House",
  },
  DISCOVERY_HEAD_OFFICE_GHANA_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_GHANA_LINE_2",
    defaultMessage: "Agostinho Neto Close, Airport",
  },
  DISCOVERY_HEAD_OFFICE_GHANA_LINE_3: {
    id: "DISCOVERY_HEAD_OFFICE_GHANA_LINE_3",
    defaultMessage: "Residential Area, Accra",
  },
  DISCOVERY_HEAD_OFFICE_KENYA_LINE_1: {
    id: "DISCOVERY_HEAD_OFFICE_KENYA_LINE_1",
    defaultMessage: "316 Upperhill Chambers 7th Floor,",
  },
  DISCOVERY_HEAD_OFFICE_KENYA_LINE_2: {
    id: "DISCOVERY_HEAD_OFFICE_KENYA_LINE_2",
    defaultMessage: "P.O. Box 52926-00200,",
  },
  DISCOVERY_HEAD_OFFICE_KENYA_LINE_3: {
    id: "DISCOVERY_HEAD_OFFICE_KENYA_LINE_3",
    defaultMessage: "2nd Ngong Avenue, Upperhill,",
  },
  DISCOVERY_HEAD_OFFICE_KENYA_LINE_4: {
    id: "DISCOVERY_HEAD_OFFICE_KENYA_LINE_4",
    defaultMessage: "Nairobi, Kenya",
  },
  DISCOVERY_HEAD_OFFICE_SECOND_NIGERIA: {
    id: "DISCOVERY_HEAD_OFFICE_SECOND_NIGERIA",
    defaultMessage: "305 Herbert Macaulay Way, Yaba, Lagos, Nigeria.",
  },
  DISCOVERY_DISCLAIMER_BOTSWANA: {
    id: "DISCOVERY_DISCLAIMER_BOTSWANA",
    defaultMessage:
      "Disclaimer: Letshego, a financial institution regulated by NBFIRA License No:8/3/59/LA",
  },
  DISCOVERY_DISCLAIMER_NIGERIA: {
    id: "DISCOVERY_DISCLAIMER_NIGERIA",
    defaultMessage:
      "Letshego Microfinance Bank is regulated entity by the Central Bank of Nigeria (RC 736874)",
  },
  DISCOVERY_SOCIAL_MEDIA_TEXT: {
    id: "DISCOVERY_SOCIAL_MEDIA_TEXT",
    defaultMessage: "Follow us on social media:",
  },
  DISCOVERY_DOWNLOAD_WEBAPP_TEXT: {
    id: "DISCOVERY_DOWNLOAD_WEBAPP_TEXT",
    defaultMessage:
      "Get started on LetsGo today, download our app or simply open in web browser. ",
  },
  DISCOVERY_FOOTER_COPY_RIGHT_TEXT: {
    id: "DISCOVERY_FOOTER_COPY_RIGHT_TEXT",
    defaultMessage: "©Copyrights Letshego. All rights reserved",
  },
  DISCOVERY_FOOTER_TERM_USE: {
    id: "DISCOVERY_FOOTER_TERM_USE",
    defaultMessage: "Terms and conditions",
  },
  DISCOVERY_FOOTER_POLICY: {
    id: "DISCOVERY_FOOTER_POLICY",
    defaultMessage: "Privacy Policy",
  },
  LOAN_CAL_GET_STARTED: {
    id: "LOAN_CAL_GET_STARTED",
    defaultMessage: "Get Started",
  },
  LOAN_CAL_DISCLAIMER: {
    id: "LOAN_CAL_DISCLAIMER",
    defaultMessage:
      "The above calculation are indicative and final offer may vary based on documents verification",
  },
  DISCOVERY_LOAN_SLIDER_DRAG_TEXT: {
    id: "DISCOVERY_LOAN_SLIDER_DRAG_TEXT",
    defaultMessage:
      "Drag the slider to select the amount as per your preference between {symbol} {min} to {symbol} {max}",
  },
  LOAN_CAL_HOW_MUCH_TEXT: {
    id: "LOAN_CAL_HOW_MUCH_TEXT",
    defaultMessage: "How much would you",
  },
  LOAN_CAL_LIKE_TO_BORROW_TEXT: {
    id: "LOAN_CAL_LIKE_TO_BORROW_TEXT",
    defaultMessage: "Like to borrow?",
  },
  LOAN_CAL_SUBTEXT: {
    id: "LOAN_CAL_SUBTEXT",
    defaultMessage:
      "Letshego promotes responsible lending. Kindly note that borrowing more than you can afford can lead to financial difficulties.",
  },
  APPLY_ONLINE: { id: "APPLY_ONLINE", defaultMessage: "Apply online" },
  GET_DESCISION: { id: "GET_DESCISION", defaultMessage: "Get decision" },
  GET_LOAN: { id: "GET_LOAN", defaultMessage: "Get loan" },
  OCR_MESSAGE: {
    id: "OCR_MESSAGE",
    defaultMessage:
      "Tap here to scan your ID and auto-populate your personal details.",
  },
  OCR_MESSAGE_FAILURE: {
    id: "OCR_MESSAGE_FAILURE",
    defaultMessage:
      "Scanning failed. Tap here to re-upload or fill application manually.",
  },
  INSTANT_LOAN_OCR_MESSAGE_FAILURE: {
    id: "OCR_MESSAGE_FAILURE",
    defaultMessage: "Scanning failed. Tap here to re-upload",
  },
  OCR_MESSAGE_SUCCESS: {
    id: "OCR_MESSAGE_SUCCESS",
    defaultMessage:
      "Your documents have been scanned and uploaded successfully.",
  },
  SUBMIT_DOCUMENTS: {
    id: "SUBMIT_DOCUMENTS",
    defaultMessage: "Submit documents",
  },
  SAVE_UPLOAD_DOC: {
    id: "SAVE_UPLOAD_DOC",
    defaultMessage: "Save uploaded documents",
  },
  DISCOVERY_ELIGIBILITY_TITLE_ONE: {
    id: "DISCOVERY_ELIGIBILITY_TITLE_ONE",
    defaultMessage: "Who is eligible for a",
  },
  DISCOVERY_ELIGIBILITY_TITLE_TWO: {
    id: "DISCOVERY_ELIGIBILITY_TITLE_TWO",
    defaultMessage: "LetsGo payroll loan?",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_TWO_NA: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_TWO_NA",
    defaultMessage:
      "Employed permanently or long-term contract at Government and/or approved Employer.",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_NEW_TZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_NEW_TZ",
    defaultMessage: "Deduction at source",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_ONE: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_ONE",
    defaultMessage: "Employed permanently or long-term contract",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_TWO: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_TWO",
    defaultMessage: "Working minimum 6 months, with the same employer",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_THREE: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_THREE",
    defaultMessage: "Above 18 years of age",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_ONE_MZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_ONE_MZ",
    defaultMessage:
      "Government & Public sector active employees, or working for a Private Institution with an agreement signed with the Bank",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_TWO_MZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_TWO_MZ",
    defaultMessage: "Minimum 2 (two) years of service",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_THREE_MZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_THREE_MZ",
    defaultMessage:
      "Have an active Salary Account for the purposes of Loan disbursement",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_FOUR_MZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_FOUR_MZ",
    defaultMessage: "Being a Mozambican citizen",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_FIVE_MZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_FIVE_MZ",
    defaultMessage: "Minimum 18 years of age",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_SIX_MZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_SIX_MZ",
    defaultMessage:
      "Maximum, 60 years for women, 65 for men, and 70 years for employees of entities approved by the Bank",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_ONE_GHANA: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_ONE_GHANA",
    defaultMessage:
      "If you are an employee of any  government institution on the Controller and Accountant General’s payroll or an institution that has partnered with Letshego",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_ONE_TZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_ONE_TZ",
    defaultMessage: "Customer needs to remain with one third of basic salary",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_TWO_TZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_TWO_TZ",
    defaultMessage: "You need to provide these documents",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_THREE_TZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_THREE_TZ",
    defaultMessage: "Latest bank statement",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_FOUR_TZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_FOUR__TZ",
    defaultMessage: "Current salary slip",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_FIVE_TZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_FIVE_TZ",
    defaultMessage: "One passport size photo",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_SIX_TZ: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_SIX_TZ",
    defaultMessage: "One passport size photo",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_ACTION_CHECK: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_ACTION_CHECK",
    defaultMessage: "Check eligibility",
  },
  DISCOVERY_ELIGIBILITY_CRITERIA_READ_ACTION_READ_MORE: {
    id: "DISCOVERY_ELIGIBILITY_CRITERIA_READ_ACTION_READ_MORE",
    defaultMessage: "Read more",
  },
  DISCOVERY_ELIGIBLE_MODAL_TITLE_ONE: {
    id: "DISCOVERY_ELIGIBLE_MODAL_TITLE_ONE",
    defaultMessage: "Great",
  },
  DISCOVERY_ELIGIBLE_MODAL_TITLE_TWO: {
    id: "DISCOVERY_ELIGIBLE_MODAL_TITLE_TWO",
    defaultMessage: "You qualify!",
  },
  DISCOVERY_ELIGIBLE_MODAL_DESC: {
    id: "DISCOVERY_ELIGIBLE_MODAL_DESC",
    defaultMessage:
      "You can now borrow from the comfort of your home, without having to visit a branch",
  },
  DISCOVERY_ELIGIBLE_MODAL_EMP_TYPE_LABEL: {
    id: "DISCOVERY_ELIGIBLE_MODAL_EMP_TYPE_LABEL",
    defaultMessage: "Employment type",
  },
  DISCOVERY_ELIGIBLE_MODAL_ACTION_START: {
    id: "DISCOVERY_ELIGIBLE_MODAL_ACTION_START",
    defaultMessage: "Start application",
  },
  DISCOVERY_ELIGIBLE_MODAL_ACTION_DOWNLOAD_APP: {
    id: "DISCOVERY_ELIGIBLE_MODAL_ACTION_DOWNLOAD_APP",
    defaultMessage: "Download LetsGo App",
  },
  LEAD_CAPTURE_TITLE: {
    id: "LEAD_CAPTURE_TITLE",
    defaultMessage: "Leave us your details",
  },
  LEAD_CAPTURE_SUBTITLE: {
    id: "LEAD_CAPTURE_SUBTITLE",
    defaultMessage: "We have LetsGo solutions that work for you",
  },
  LEAD_CAPTURE_DESC: {
    id: "LEAD_CAPTURE_DESC",
    defaultMessage:
      "Currently, you cannot benefit from the online Payroll loan.  We have other solutions that can fit your funding needs.",
  },
  LEAD_CAPTURE_FORM_NAME_LABEL: {
    id: "LEAD_CAPTURE_FORM_NAME_LABEL",
    defaultMessage: "Full name",
  },
  LEAD_CAPTURE_FORM_NAME_ERROR: {
    id: "LEAD_CAPTURE_FORM_NAME_ERROR",
    defaultMessage: "Please enter your name",
  },

  LEAD_CAPTURE_FORM_ACTION_RECHECK_ELIGIBILITY: {
    id: "LEAD_CAPTURE_FORM_ACTION_RECHECK_ELIGIBILITY",
    defaultMessage: "Check eligibility again",
  },
  LEAD_CAPTURE_SUCCESS_MESSAGE: {
    id: "LEAD_CAPTURE_SUCCESS_MESSAGE",
    defaultMessage:
      "Thank you for your details. A customer care agent will contact you shortly.",
  },
  ID_NOT_CLEAR: {
    id: "ID_NOT_CLEAR",
    defaultMessage: "Image uploaded is blurred.",
  },
  ID_EXPIRED: {
    id: "ID_EXPIRED",
    defaultMessage: "Your ID has expired.Please upload a valid ID.",
  },
  CUSTOMER_PROFILE_NOT_MATCH: {
    id: "CUSTOMER_PROFILE_NOT_MATCH",
    defaultMessage: "Uploaded document does not match your profile.",
  },
  DOC_FRAUDLENT: {
    id: "DOC_FRAUDLENT",
    defaultMessage:
      "Your document appears edited.Kindly upload the original document.",
  },
  IMAGE_NOT_CLEAR: {
    id: "IMAGE_NOT_CLEAR",
    defaultMessage: "Image uploaded is blurred. Please upload a new image.",
  },
  USAGE_VALIDITY: {
    id: "USAGE_VALIDITY",
    defaultMessage:
      "Uploaded pay slip is not latest. Please upload the latest pay slip",
  },
  INFORMATION_NOT_CLEAR: {
    id: "INFORMATION_NOT_CLEAR",
    defaultMessage:
      "Image uploaded is blurred or certain information is not clear.",
  },
  STATEMENT_INCOMPLETE: {
    id: "STATEMENT_INCOMPLETE",
    defaultMessage:
      "Your bank statement is incomplete. Please make sure your latest transaction is reflected.",
  },
  LAST_PAYSLIP: {
    id: "LAST_PAYSLIP",
    defaultMessage: "Please upload last 3 months pay slips",
  },
  LAST_BANK_STATEMENT: {
    id: "LAST_BANK_STATEMENT",
    defaultMessage: "Please upload lastest 3 months bank statement",
  },
  EMPLOYMENT_LETTER_INCOMPLETE: {
    id: "EMPLOYMENT_LETTER_INCOMPLETE",
    defaultMessage: "Your employment letter is incomplete",
  },
  VIEW_MORE: { id: "VIEW_MORE", defaultMessage: "View more" },
  VIEW_LESS: { id: "VIEW_LESS", defaultMessage: "View less" },
  EXPERT_TIP_TITLE: { id: "EXPERT_TIP_TITLE", defaultMessage: "Expert Tip" },
  EXPERT_TIP_TEXT: {
    id: "EXPERT_TIP_TEXT",
    defaultMessage:
      "These documents are required as part of the loan application.You can save form filing time by providing these now.",
  },
  EMPOYER_VERIFICATION_EXPERT_TIP_TEXT: {
    id: "EMPOYER_VERIFICATION_EXPERT_TIP_TEXT",
    defaultMessage:
      "These documents are required as a part of loan application. By providing them now you can save your form filling time.",
  },
  VERIFY_EMAIL_MESSAGE: {
    id: "VERIFY_EMAIL_MESSAGE",
    defaultMessage:
      "You haven’t verified your email. Kindly {verifyEmail} to apply for a loan. ",
  },
  VERIFY_EMAIL: { id: "VERIFY_EMAIL", defaultMessage: "verify your email " },
  VERIFY_EMAIL_ID_HEADER: {
    id: "VERIFY_EMAIL_ID_HEADER",
    defaultMessage: "Verify email address",
  },
  ENTER_EMAIL_OTP_TEXT: {
    id: "ENTER_EMAIL_OTP_TEXT",
    defaultMessage: "Enter the OTP sent to your registered email {email}",
  },
  ENTER_EMAIL_OTP_BEFORE_LOAN_APPLY: {
    id: "ENTER_EMAIL_OTP_BEFORE_LOAN_APPLY",
    defaultMessage:
      "Before you apply for a loan, please verify your details. Please enter the OTP sent on your registered email {email}",
  },
  ENTER_EMAIL_OTP_BEFORE_CONTINUE_APPLICATION: {
    id: "ENTER_EMAIL_OTP_BEFORE_CONTINUE_APPLICATION",
    defaultMessage:
      "You haven’t verified your email, please verify your details. Please enter the OTP sent on your registered email {email}",
  },
  VERIFIRD_DETIALD: {
    id: "VERIFIRD_DETIALD",
    defaultMessage: "Your details are verified",
  },
  EMAIL_VERIFIED_SUCCESSFULLY: {
    id: "EMAIL_VERIFIED_SUCCESSFULLY",
    defaultMessage: "Your email address has been verified successfully.",
  },
  EMAIL_BVN_VERIFIED_SUCCESSFULLY: {
    id: "EMAIL_BVN_VERIFIED_SUCCESSFULLY",
    defaultMessage:
      "Your email address and BVN have been verified successfully",
  },
  EMAIL_VERIFIED_FAILURE: {
    id: "EMAIL_VERIFIED_FAILURE",
    defaultMessage: "We are unable to verify your email address",
  },
  EMAIL_FAILURE_MESSAGE: {
    id: "EMAIL_FAILURE_MESSAGE",
    defaultMessage:
      "We could not contact our server for your email address verification. Please try again.",
  },
  PLEASE_TRY_AGAIN: {
    id: "PLEASE_TRY_AGAIN",
    defaultMessage: "Please try again.",
  },
  RENTER_CARD_DETAILS: {
    id: "RENTER_CARD_DETAILS",
    defaultMessage: "Re-enter card details",
  },
  RENTER_DETAILS: {
    id: "RENTER_DETAILS",
    defaultMessage: "Re-enter  details",
  },
  ERROR_CARD_VERIFICATION_SECONDARY: {
    id: "ERROR_CARD_VERIFICATION_SECONDARY",
    defaultMessage: "Go to dashboard",
  },
  RETRY_AFTER_TIME_MESSAGE: {
    id: "RETRY_AFTER_TIME_MESSAGE",
    defaultMessage:
      "Verify your email to apply for a loan. As you’ve exceeded all attempts, try again after 1 hour.",
  },
  VERIFY_BVN_LABEL: { id: "VERIFY_BVN_LABEL", defaultMessage: "Verify BVN" },
  CANCEL_BTN: { id: "CANCEL_BTN", defaultMessage: "Cancel" },
  EMAIL_VERIFIED: {
    id: "EMAIL_VERIFIED",
    defaultMessage: "Your email address is verified",
  },
  BVN_VERIFICATION_STEP_INFO: {
    id: "BVN_VERIFICATION_STEP_INFO",
    defaultMessage:
      "Your one step away, please verify your BVN to apply for a loan",
  },
  BVN_VERIFIED_SUCCESS: {
    id: "BVN_VERIFIED_SUCCESS",
    defaultMessage: "Great! Your BVN is verified",
  },
  DISCOVERY_FAQS_FREQUENTLY_ASKED: {
    id: "DISCOVERY_FAQS_FREQUENTLY_ASKED",
    defaultMessage: "Frequently asked",
  },
  DISCOVERY_FAQS_QUESTIONS: {
    id: "DISCOVERY_FAQS_QUESTIONS",
    defaultMessage: "Questions",
  },
  DISCOVERY_FAQS_SUBHEADING_TEXT: {
    id: "DISCOVERY_FAQS_SUBHEADING_TEXT",
    defaultMessage:
      "Have some questions? Read our FAQs to learn more about Payroll loan",
  },
  CONTINUE_DASHBOARD_ON_GOING_TEXT: {
    id: "CONTINUE_DASHBOARD_ON_GOING_TEXT",
    defaultMessage: "On going loan",
  },
  CONTINUE_DASHBOARD_APPLICATION_SAVED: {
    id: "CONTINUE_DASHBOARD_APPLICATION_SAVED",
    defaultMessage: "Application saved",
  },
  CONTINUE_DASHBOARD_SNACBAR_TOAST_MESSAGE: {
    id: "CONTINUE_DASHBOARD_SNACBAR_TOAST_MESSAGE",
    defaultMessage: "Application saved successfully",
  },
  LEAD_CAPTURED_TOAST_MESSAGE: {
    id: "LEAD_CAPTURED_TOAST_MESSAGE",
    defaultMessage:
      "Your request has been registered, someone from our team will contact you shortly.",
  },
  EXPLORE_TITLE: { id: "EXPLORE_TITLE", defaultMessage: "Explore LetsGo!" },
  EXPLORE_HOW_PAYROLL_WORKS_TITLE_ONE: {
    id: "EXPLORE_HOW_PAYROLL_WORKS_TITLE_ONE",
    defaultMessage: "How",
  },
  EXPLORE_HOW_PAYROLL_WORKS_TITLE_TWO: {
    id: "EXPLORE_HOW_PAYROLL_WORKS_TITLE_TWO",
    defaultMessage: "Payroll works?",
  },
  EXPLORE_HOW_PAYROLL_WORKS_SUBTITLE: {
    id: "EXPLORE_HOW_PAYROLL_WORKS_SUBTITLE",
    defaultMessage: "Get your loan in 3 easy steps",
  },
  EXPLORE_HOW_PAYROLL_WORKS_STEP_ONE: {
    id: "EXPLORE_HOW_PAYROLL_WORKS_STEP_ONE",
    defaultMessage: "Fill application",
  },
  EXPLORE_HOW_PAYROLL_WORKS_STEP_TWO: {
    id: "EXPLORE_HOW_PAYROLL_WORKS_STEP_TWO",
    defaultMessage: "Upload documents",
  },
  EXPLORE_HOW_PAYROLL_WORKS_STEP_THREE: {
    id: "EXPLORE_HOW_PAYROLL_WORKS_STEP_THREE",
    defaultMessage: "Get decision",
  },
  EXPLORE_HOW_PAYROLL_WORKS_DOCS_NEEDED: {
    id: "EXPLORE_HOW_PAYROLL_WORKS_DOCS_NEEDED",
    defaultMessage: "Documents needed to apply",
  },
  EXPLORE_SOCIAL_MEDIA_BANNER_TITLE_ONE: {
    id: "EXPLORE_SOCIAL_MEDIA_BANNER_TITLE_ONE",
    defaultMessage: "Let’s",
  },
  EXPLORE_SOCIAL_MEDIA_BANNER_TITLE_TWO: {
    id: "EXPLORE_SOCIAL_MEDIA_BANNER_TITLE_TWO",
    defaultMessage: "Get social!",
  },
  ID_DOCUMENT_LABEL_DESCRIPTOR: {
    id: "ID_DOCUMENT_LABEL_DESCRIPTOR",
    defaultMessage: "Your national identity card or Passport",
  },
  NIGERIA_ID_DOCUMENT_LABEL_DESCRIPTOR: {
    id: "NIGERIA_ID_DOCUMENT_LABEL_DESCRIPTOR",
    defaultMessage: "Your national identity card or Passport",
  },
  EXISTING_ID_DOCUMENT_LABEL_DESCRIPTOR: {
    id: "EXISTING_ID_DOCUMENT_LABEL_DESCRIPTOR",
    defaultMessage: "Your national identity card or Passport",
  },
  EXISTING_SALARY_SLIP_DESCRIPTOR: {
    id: "EXISTING_SALARY_SLIP_DESCRIPTOR",
    defaultMessage: "Last 1 month salary slip",
  },
  EXISTING_BANK_STATEMENT_DESCRIPTOR: {
    id: "EXISTING_BANK_STATEMENT_DESCRIPTOR",
    defaultMessage: "Last 1 month statement of your salary account",
  },
  DISCOVERY_TESTIMONIALS_TITLE: {
    id: "DISCOVERY_TESTIMONIALS_TITLE",
    defaultMessage: "Our customers",
  },
  DISCOVERY_TESTIMONIALS_SUBTITLE: {
    id: "DISCOVERY_TESTIMONIALS_SUBTITLE",
    defaultMessage: "Love what we do",
  },
  DISCOVERY_TESTIMONIALS_SUBTEXT: {
    id: "DISCOVERY_TESTIMONIALS_SUBTEXT",
    defaultMessage:
      "We value our customers and are happy to support you in realizing your dreams.",
  },
  WAY_TO_COMPLETE_ID_VERIFICATION_LABEL: {
    id: "WAY_TO_COMPLETE_ID_VERIFICATION_LABEL",
    defaultMessage:
      "You can complete your ID verification using any of the below:",
  },
  VERIFY_ONLINE_LABEL: {
    id: "VERIFY_ONLINE_LABEL",
    defaultMessage: "Verify online by taking a selfie with National ID",
  },
  VERIFY_ONLINE_MULTIPLE_DOCUMENTS_LABEL: {
    id: "VERIFY_ONLINE_MULTIPLE_DOCUMENTS_LABEL",
    defaultMessage:
      "Verify online by taking a selfie with National ID / Passport / Employer ID / Drivers License",
  },
  MORE_CONVENIENT_TEXT: {
    id: "MORE_CONVENIENT_TEXT",
    defaultMessage: "More Convenient",
  },
  PASSWORD_SIZE_PHOTO_VERIFICATION: {
    id: "PASSWORD_SIZE_PHOTO_VERIFICATION",
    defaultMessage:
      "Verify online by taking a passport size photo with National ID",
  },
  PASSWORD_SIZE_PHOTO_VERIFICATION_META_1: {
    id: "PASSWORD_SIZE_PHOTO_VERIFICATION_META_1",
    defaultMessage: "Take the passport size photo in a well-lit room",
  },
  PASSWORD_SIZE_PHOTO_VERIFICATION_META_2: {
    id: "PASSWORD_SIZE_PHOTO_VERIFICATION_META_2",
    defaultMessage: "This is to verify your picture with Ghana card number",
  },
  PASSWORD_SIZE_PHOTO_VERIFICATION_META_3: {
    id: "PASSWORD_SIZE_PHOTO_VERIFICATION_META_3",
    defaultMessage:
      "If required, ask someone else to take a picture using the back camera from up-close",
  },
  APP_DOWNLOAD_LABEL: {
    id: "APP_DOWNLOAD_LABEL",
    defaultMessage: "Don’t have a web camera? {download} to do it seamlessly",
  },
  DOWNLOAD_APP: { id: "DOWNLOAD_APP", defaultMessage: "Download the app" },
  COMMON_OR: { id: "COMMON_OR", defaultMessage: "OR" },
  VISIT_NEAREST_BRANCH_LABEL: {
    id: "VISIT_NEAREST_BRANCH_LABEL",
    defaultMessage: "Visit your nearest Letshego branch",
  },
  VISIT_NEAREST_BRANCH: {
    id: "VISIT_NEAREST_BRANCH",
    defaultMessage:
      "Please visit your nearest Branch to complete your application.",
  },
  THINGS_TO_CARRY: {
    id: "THINGS_TO_CARRY",
    defaultMessage: "Things to carry : ",
  },
  TAKE_A_SELFIE_BUTTON: {
    id: "TAKE_A_SELFIE_BUTTON",
    defaultMessage: "Take a picture",
  },
  UPLOAD_SELFIE_SIDE_PANEL_HEADER: {
    id: "UPLOAD_SELFIE_SIDE_PANEL_HEADER",
    defaultMessage:
      "This is how you can take your picture and get verified in one instance",
  },
  NOTARIZED_DOCUMENT_SIDE_PANEL_HEADER: {
    id: "NOTARIZED_DOCUMENT_SIDE_PANEL_HEADER",
    defaultMessage: "How does it work?",
  },
  NOTORIZED_DOCUMENT_STEP_1: {
    id: "NOTORIZED_DOCUMENT_STEP_1",
    defaultMessage: "{link} here to download loan contract documents",
  },
  NOTORIZED_DOCUMENT_STEP_2: {
    id: "NOTORIZED_DOCUMENT_STEP_2",
    defaultMessage:
      "Sign loan contract document and get an approval from the notary centre.",
  },
  NOTORIZED_DOCUMENT_STEP_3: {
    id: "NOTORIZED_DOCUMENT_STEP_3",
    defaultMessage:
      "Take the signed and notarized documents to your nearest branch, wait 24 hours maximum to get the bank signature and stamp.",
  },
  NOTORIZED_DOCUMENT_STEP_4: {
    id: "NOTORIZED_DOCUMENT_STEP_4",
    defaultMessage:
      "Take picture of your stamped and signed documents and upload it below.",
  },
  NOTORIZED_DOCUMENT_STEP_5: {
    id: "NOTORIZED_DOCUMENT_STEP_5",
    defaultMessage:
      "Visit any of our branch or the digital mall to get the repayment plan after disbursement.",
  },
  UPLOAD_SELFIE_STEP_1: {
    id: "UPLOAD_SELFIE_STEP_1",
    defaultMessage: "On a blank piece of paper, clearly {subtext} below it",
  },
  UPLOAD_SELFIE_DESCRIPTOR_STEP_1: {
    id: "UPLOAD_SELFIE_DESCRIPTOR_STEP_1",
    defaultMessage: "write today’s date and “LetsGo Loan” ",
  },
  UPLOAD_SELFIE_STEP_2: {
    id: "UPLOAD_SELFIE_STEP_2",
    defaultMessage: "Hold both this paper and your ID {subtext}",
  },
  UPLOAD_SELFIE_DESCRIPTOR_STEP_2: {
    id: "UPLOAD_SELFIE_DESCRIPTOR_STEP_2",
    defaultMessage: "in one hand so both can be seen clearly",
  },
  UPLOAD_SELFIE_STEP_3: {
    id: "UPLOAD_SELFIE_STEP_3",
    defaultMessage:
      "Take a selfie of you holding your ID and the note. All three elements {subtext}",
  },
  UPLOAD_SELFIE_DESCRIPTOR_STEP_3: {
    id: "UPLOAD_SELFIE_DESCRIPTOR_STEP_3",
    defaultMessage: "must be clearly visible",
  },
  UPLOAD_SELFIE_STEP_4: {
    id: "UPLOAD_SELFIE_STEP_4",
    defaultMessage:
      "Take the photo in a brightly lit room and remember {subtext} with your fingers or other object.",
  },
  UPLOAD_SELFIE_DESCRIPTOR_STEP_4: {
    id: "UPLOAD_SELFIE_DESCRIPTOR_STEP_4",
    defaultMessage: "not to cover any information",
  },
  SAVINGS_UPLOAD_SELFIE_STEP_1: {
    id: "SAVINGS_UPLOAD_SELFIE_STEP_1",
    defaultMessage: "On a blank piece of white paper {subtext} below it",
  },
  SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_1: {
    id: "SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_1",
    defaultMessage: 'write today\'s date and "LetsGo Save"',
  },
  SAVINGS_UPLOAD_SELFIE_STEP_2: {
    id: "SAVINGS_UPLOAD_SELFIE_STEP_2",
    defaultMessage: "Hold both this paper and your ID {subtext}",
  },
  SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_2: {
    id: "SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_2",
    defaultMessage: "in one hand so we can clearly see both",
  },
  SAVINGS_UPLOAD_SELFIE_STEP_3: {
    id: "SAVINGS_UPLOAD_SELFIE_STEP_3",
    defaultMessage:
      "Take a selfie of you holding your ID and the note. All three elements {subtext}",
  },
  SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_3: {
    id: "SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_3",
    defaultMessage: "must be clearly visible",
  },
  SAVINGS_UPLOAD_SELFIE_STEP_4: {
    id: "SAVINGS_UPLOAD_SELFIE_STEP_4",
    defaultMessage:
      "Take the photo in a brightly lit room and {subtext} with your fingers or other object.",
  },
  SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_4: {
    id: "SAVINGS_UPLOAD_SELFIE_DESCRIPTOR_STEP_4",
    defaultMessage: "remember not to cover any information",
  },
  SAVINGS_UPLOAD_SELFIE_STEP_5: {
    id: "SAVINGS_UPLOAD_SELFIE_STEP_5",
    defaultMessage: "If required, ask someone else to take the photo of you.",
  },
  SELFIE_SENT_VERIFICATION: {
    id: "SELFIE_SENT_VERIFICATION",
    defaultMessage: "Selfie sent for verification",
  },
  SELFIE_SENT_VERIFICATION_INFO: {
    id: "SELFIE_SENT_VERIFICATION_INFO",
    defaultMessage:
      "Our team will review your documents and verify your identity.",
  },
  NOTARIZED_DOCUMENT_SENT_VERIFICATION: {
    id: "NOTARIZED_DOCUMENT_SENT_VERIFICATION",
    defaultMessage: "Notarized document sent for verification",
  },
  NOTARIZED_DOCUMENT_SENT_VERIFICATION_INFO: {
    id: "NOTARIZED_DOCUMENT_SENT_VERIFICATION_INFO",
    defaultMessage:
      "Our team will review your documents and verify your identity.",
  },
  NOTIFICATION_NUMBER_TEXT: {
    id: "NOTIFICATION_NUMBER_TEXT",
    defaultMessage:
      "You will get a call on your registered mobile no. {phoneNumber} for any additional information required",
  },
  LOAN_EXPIRED_TEXT: {
    id: "LOAN_EXPIRED_TEXT",
    defaultMessage: "Uh-oh! Your loan offer has expired.",
  },
  REAPPLY_LOAN_TEXT: {
    id: "REAPPLY_LOAN_TEXT",
    defaultMessage:
      "Don’t worry you can re-apply without having to fill all your details again",
  },
  REVIEW_YOUR_ELIGIBILITY: {
    id: "REVIEW_YOUR_ELIGIBILITY",
    defaultMessage: "Apply again",
  },
  DONOT_WORRY_TEXT: { id: "DONOT_WORRY_TEXT", defaultMessage: "Don’t worry!" },
  EXPIRE_LOAN_SECONDARY_TEXT: {
    id: "EXPIRE_LOAN_SECONDARY_TEXT",
    defaultMessage: "There's always another chance.",
  },
  EXPIRE_LOAN_PARA: {
    id: "EXPIRE_LOAN_PARA",
    defaultMessage: "You can re-apply for a loan anytime you want.",
  },
  DISCOVERY_PRODUCTS_TITLE: {
    id: "DISCOVERY_PRODUCTS_TITLE",
    defaultMessage: "Explore other products",
  },
  DISCOVERY_PRODUCTS_SUBTITLE: {
    id: "DISCOVERY_PRODUCTS_SUBTITLE",
    defaultMessage: "LetsGo offers",
  },
  DISCOVERY_PRODUCTS_MSE_PURCHASE: {
    id: "DISCOVERY_PRODUCTS_MSE_PURCHASE",
    defaultMessage: "MSE Purchase",
  },
  DISCOVERY_PRODUCTS_MSE_PURCHASE_GH: {
    id: "DISCOVERY_PRODUCTS_MSE_PURCHASE_GH",
    defaultMessage: "MSE Products",
  },
  DISCOVERY_PRODUCTS_ORDER_FINANCING: {
    id: "DISCOVERY_PRODUCTS_ORDER_FINANCING",
    defaultMessage: "MSE Products",
  },
  DISCOVERY_PRODUCTS_SUBTEXT: {
    id: "DISCOVERY_PRODUCTS_SUBTEXT",
    defaultMessage:
      "Access finance against purchase orders & invoicing discount to fund your venture and project needs.",
  },
  DISCOVERY_PRODUCT1_TITLE_GH: {
    id: "DISCOVERY_PRODUCT1_TITLE_GH",
    defaultMessage: "Edusolution",
  },
  DISCOVERY_PRODUCT2_TITLE_GH: {
    id: "DISCOVERY_PRODUCT2_TITLE_GH",
    defaultMessage: "Local Purchase Order (LPO) Financing Support",
  },
  DISCOVERY_PRODUCT2_DESCRIPTION_GH: {
    id: "DISCOVERY_PRODUCT2_DESCRIPTION_GH",
    defaultMessage:
      "Our LPO Financing Support is tailored to assist businesses that have obtained genuine purchase orders to deliver goods and services or executive contracts awarded to them, to ensure continuity of their operations.",
  },
  DISCOVERY_PRODUCT1_DESCRIPTION_GH: {
    id: "DISCOVERY_PRODUCT1_DESCRIPTION_GH",
    defaultMessage:
      "Designed to support micro, small and medium-sized entrepreneurs in the educational sector.",
  },
  DISCOVERY_DISCLAIMER_GHANA: {
    id: "DISCOVERY_DISCLAIMER_GHANA",
    defaultMessage:
      "Letshego Ghana Savings and Loans PLC is licensed by the BOG.",
  },
  DISCOVERY_DISCLAIMER_NAMIBIA: {
    id: "DISCOVERY_DISCLAIMER_NAMIBIA",
    defaultMessage:
      "Disclaimer: Letshego Bank, a financial institutions regulated by the Bank of Namibia Licence Number. BIC 011",
  },
  PAYROLL_LOAN_SUBTEXT: {
    id: "PAYROLL_LOAN_SUBTEXT",
    defaultMessage: "Simple unsecured financing to help you improve your life.",
  },
  PAYROLL_LOAN_CRITERIA_ONE: {
    id: "PAYROLL_LOAN_CRITERIA_ONE",
    defaultMessage: "Between 18 to 60 years of age",
  },
  PAYROLL_LOAN_CRITERIA_TWO: {
    id: "PAYROLL_LOAN_CRITERIA_TWO",
    defaultMessage:
      "Employed permanently or long-term contract by the Government of Uganda",
  },
  PAYROLL_LOAN_CRITERIA_THREE: {
    id: "PAYROLL_LOAN_CRITERIA_THREE",
    defaultMessage: "Recieved atleast 2 months of Salary",
  },
  MICROFINANCE_LOAN_SUBTEXT: {
    id: "MICROFINANCE_LOAN_SUBTEXT",
    defaultMessage:
      "Access quick funding for your working capital needs, business expansion and asset financing",
  },
  MICROFINANCE_LOAN_CRITERIA_ONE: {
    id: "MICROFINANCE_LOAN_CRITERIA_ONE",
    defaultMessage: "Sole Proprietors",
  },
  MICROFINANCE_LOAN_CRITERIA_TWO: {
    id: "MICROFINANCE_LOAN_CRITERIA_TWO",
    defaultMessage: "Registered Micro Small and Medium Enterprises",
  },
  MICROFINANCE_LOAN_CRITERIA_THREE: {
    id: "MICROFINANCE_LOAN_CRITERIA_THREE",
    defaultMessage: "Group Lending",
  },
  DISCOVERY_PRODUCTS_CRITERIA_ONE: {
    id: "DISCOVERY_PRODUCTS_CRITERIA_ONE",
    defaultMessage: "Duly registered & operating Small & Micro enterprises",
  },
  DISCOVERY_PRODUCTS_CRITERIA_TWO: {
    id: "DISCOVERY_PRODUCTS_CRITERIA_TWO",
    defaultMessage: "Signed Purchase Order/Offer Letter",
  },
  DISCOVERY_PRODUCTS_CRITERIA_THREE: {
    id: "DISCOVERY_PRODUCTS_CRITERIA_THREE",
    defaultMessage: "Future Business/Standing Contract",
  },
  DISCOVERY_PRODUCTS_CRITERIA_ONE_NAM: {
    id: "DISCOVERY_PRODUCTS_CRITERIA_ONE_NAM",
    defaultMessage: "Individual working in the public sector",
  },
  DISCOVERY_PRODUCTS_CRITERIA_TWO_NAM: {
    id: "DISCOVERY_PRODUCTS_CRITERIA_TWO_NAM",
    defaultMessage: "Individual working in the private sector",
  },
  DISCOVERY_PRODUCTS_CRITERIA_THREE_NAM: {
    id: "DISCOVERY_PRODUCTS_CRITERIA_THREE_NAM",
    defaultMessage: "Fast, easy and quick loan disbursement",
  },
  DISCOVERY_PRODUCTS_GET_CALLBACK: {
    id: "DISCOVERY_PRODUCTS_GET_CALLBACK",
    defaultMessage: "Get a call back",
  },
  DISCOVERY_PRODUCTS_LOAN: {
    id: "DISCOVERY_PRODUCTS_LOAN",
    defaultMessage: "Loan",
  },
  DISCOVERY_PRODUCTS_HOUSING_LOAN: {
    id: "DISCOVERY_PRODUCTS_HOUSING_LOAN",
    defaultMessage: "Housing loan",
  },
  DISCOVERY_PRODUCTS_STEPUP_TITLE: {
    id: "DISCOVERY_PRODUCTS_STEPUP_TITLE",
    defaultMessage: "STEP UP",
  },
  DISCOVERY_PRODUCTS_LETSGOCARD_TITLE: {
    id: "DISCOVERY_PRODUCTS_LETSGOCARD_TITLE",
    defaultMessage: "LetsGo Cards",
  },
  DISCOVERY_PRODUCTS_LETSGOCARD_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_LETSGOCARD_DESCRIPTION",
    defaultMessage:
      "Our LetsGo cards are accepted by all ATM’s and are ideal for everyone looking for accessible, simple and flexible financial solutions that save you cash in fees. The card can be used for online transfers, purchases and bill payments.",
  },
  DISCOVERY_PRODUCTS_SAVIND_DEPOSIT_TITLE: {
    id: "DISCOVERY_PRODUCTS_SAVIND_DEPOSIT_TITLE",
    defaultMessage: "Savings and Fixed Deposit",
  },
  DISCOVERY_PRODUCTS_SAVIND_DEPOSIT_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_SAVIND_DEPOSIT_DESCRIPTION",
    defaultMessage:
      "Save with Letshego and you will be guaranteed access to your money whenever and wherever you need it. We offer competitive interest rates on our savings and fixed deposits?",
  },
  DISCOVERY_PRODUCTS_STEPUP_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_STEPUP_DESCRIPTION",
    defaultMessage:
      "Our Step Up loans are for micro, small and medium enterprises such as…",
  },
  DISCOVERY_PRODUCTS_PERSONAL_LOAN: {
    id: "DISCOVERY_PRODUCTS_PERSONAL_LOAN",
    defaultMessage: "Non MOU Personal",
  },
  DISCOVERY_PRODUCTS_BUISNESS_TITLE: {
    id: "DISCOVERY_PRODUCTS_BUISNESS_TITLE",
    defaultMessage: "MSE/Business",
  },
  DISCOVERY_PRODUCTS_STEPUP_DESCRIPTION_KE: {
    id: "DISCOVERY_PRODUCTS_STEPUP_DESCRIPTION_KE",
    defaultMessage:
      "Payroll loans targeting salaried customers whose employers are not in  MOU partnerships with Letshego Kenya",
  },
  BUISNESS_LOAN: {
    id: "BUISNESS_LOAN",
    defaultMessage: "Business Loan",
  },
  EXPLORE_BUISNESS_LOAN_SUBTITLE: {
    id: "EXPLORE_BUISNESS_LOAN_SUBTITLE",
    defaultMessage:
      "Tailored financial solutions for individual entrepreneurs who require working capital to boost stock and future sales potential.",
  },
  BUISNESS_LOAN_CRITERIA_ONE: {
    id: "BUISNESS_LOAN_CRITERIA_ONE",
    defaultMessage:
      "Duly Registered Business (Sole Proprietor / Partnership / Limited Liability Company) with small and medium level of business",
  },
  BUISNESS_LOAN_CRITERIA_TWO: {
    id: "BUISNESS_LOAN_CRITERIA_TWO",
    defaultMessage:
      "Official documents according to type of registered business",
  },
  BUISNESS_LOAN_CRITERIA_THREE: {
    id: "BUISNESS_LOAN_CRITERIA_THREE",
    defaultMessage:
      "Valid trading licenses and Lease agreement for business premise",
  },
  HOME_LOAN: {
    id: "HOME_LOAN",
    defaultMessage: "Home Loan",
  },
  EXPLORE_HOME_LOAN_SUBTITLE: {
    id: "EXPLORE_HOME_LOAN_SUBTITLE",
    defaultMessage:
      "Structured solutions to provide financial support to consumers who may find it challenging to access traditional housing finance or Home Loans.",
  },
  HOME_LOAN_CRITERIA_ONE: {
    id: "HOME_LOAN_CRITERIA_ONE",
    defaultMessage:
      "Viable housing project - for commercial purposes or owner occupier",
  },
  HOME_LOAN_CRITERIA_TWO: {
    id: "HOME_LOAN_CRITERIA_TWO",
    defaultMessage: "Prove of project ownership",
  },
  HOME_LOAN_CRITERIA_THREE: {
    id: "HOME_LOAN_CRITERIA_THREE",
    defaultMessage:
      "Evidence of compliance to the building  regulations - approved plans",
  },
  HOME_LOAN_CRITERIA_FOUR: {
    id: "HOME_LOAN_CRITERIA_FOUR",
    defaultMessage:
      "Demonstration of  ability to pay – through existing income ( rental income ,Business income) ",
  },
  HOME_LOAN_CRITERIA_FIVE: {
    id: "HOME_LOAN_CRITERIA_FIVE",
    defaultMessage: "Securitization of the  Loan",
  },
  EXPLORE_EDUCATION_SUBTITLE_KE: {
    id: "EXPLORE_STEP_UP_SUBTITLE_KE",
    defaultMessage:
      "For schools with an aim to support multiple stakeholders within a school environment, be it the school itself, teachers, parents, as well as providing fundamental financial skills training for learners",
  },
  EXPLORE_HOME_LOAN_SUBTITLE_KE: {
    id: "EXPLORE_HOME_LOAN_SUBTITLE_KE",
    defaultMessage:
      "Structured solutions to provide financial support to consumers who may find it challenging to access traditional housing finance or Home Loans",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_ONE_KE: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_ONE_KE",
    defaultMessage:
      "Duly registered school with proof  of full compliance  (  education licenses )",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_TWO_KE: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_TWO_KE",
    defaultMessage: "Viable school project ( infrastructure ,working capital)",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_THREE_KE: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_THREE_KE",
    defaultMessage: "Ownership proof",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_FOUR_KE: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_FOUR_KE",
    defaultMessage:
      "Demonstrate ability to repay in the current sources of income",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_FIVE_KE: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_FIVE_KE",
    defaultMessage: "securitization of the loan ",
  },
  EXPLORE_STEP_UP_SUBTITLE_KE: {
    id: "EXPLORE_STEP_UP_SUBTITLE_KE",
    defaultMessage:
      "Providing small scale farmers with inputs to increase the quality of agricultural outputs and harvest volumes to achieve expanded income for sustainable growth",
  },
  AGRICULTURE_LOAN: {
    id: "AGRICULTURE_LOAN",
    defaultMessage: "Agriculture Loan",
  },
  EXPLORE_AGRICULTURE_LOAN_CRITERIA_ONE_KE: {
    id: "EXPLORE_AGRICULTURE_LOAN_CRITERIA_ONE_KE",
    defaultMessage:
      "Viable housing project - for commercial purposes or owner occupier",
  },
  EXPLORE_AGRICULTURE_LOAN_CRITERIA_TWO_KE: {
    id: "EXPLORE_AGRICULTURE_LOAN_CRITERIA_TWO_KE",
    defaultMessage: "Prove of ownership of the project",
  },
  EXPLORE_AGRICULTURE_LOAN_CRITERIA_THREE_KE: {
    id: "EXPLORE_AGRICULTURE_LOAN_CRITERIA_THREE_KE",
    defaultMessage:
      "Demonstration of ability to repay the loan through existing sources of income",
  },
  EXPLORE_AGRICULTURE_LOAN_CRITERIA_FOUR_KE: {
    id: "EXPLORE_AGRICULTURE_LOAN_CRITERIA_FOUR_KE",
    defaultMessage: "Securitization of the loan ",
  },
  DISCOVERY_PRODUCTS_HEALTH_TITLE: {
    id: "DISCOVERY_PRODUCTS_HEALTH_TITLE",
    defaultMessage: "Health",
  },
  DISCOVERY_PRODUCTS_HEALTH_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_HEALTH_DESCRIPTION",
    defaultMessage:
      "Our Health loans are accessible to businesses in the health ecosystem…",
  },
  DISCOVERY_PRODUCTS_EDUCATION_TITLE: {
    id: "DISCOVERY_PRODUCTS_EDUCATION_TITLE",
    defaultMessage: "Education",
  },
  DISCOVERY_PRODUCTS_EDUCATION_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_EDUCATION_DESCRIPTION",
    defaultMessage:
      "Our Education loans are suitable for schools, tutorial canters and creches…",
  },
  DISCOVERY_PRODUCTS_EDUCATION_DESCRIPTION_KE: {
    id: "DISCOVERY_PRODUCTS_EDUCATION_DESCRIPTION_KE",
    defaultMessage:
      "For schools with an aim to support multiple stakeholders within a school environment, be it the school itself, teachers, parents, as well as providing fundamental financial skills training for learners",
  },
  DISCOVERY_PRODUCTS_AFFORDABLE_TITLE: {
    id: "DISCOVERY_PRODUCTS_AFFORDABLE_TITLE",
    defaultMessage: "Affordable",
  },
  DISCOVERY_PRODUCTS_AFFORDABLE_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_AFFORDABLE_DESCRIPTION",
    defaultMessage:
      "Our Housing loans are appropriate for individuals in the public & private secto…",
  },
  DISCOVERY_PRODUCTS_AFFORDABLE_DESCRIPTION_KE: {
    id: "DISCOVERY_PRODUCTS_AFFORDABLE_DESCRIPTION_KE",
    defaultMessage:
      "Structured solutions to provide financial support to consumers who may find it challenging to access traditional housing finance or Home Loans.",
  },
  DISCOVERY_PRODUCTS_AFFORDABLE_DESCRIPTION_NAM: {
    id: "DISCOVERY_PRODUCTS_AFFORDABLE_DESCRIPTION_NAM",
    defaultMessage:
      "Get appropriate access to easy and affordable housing loans.",
  },
  DISCOVERY_PRODUCTS_AGRICULTURE_TITLE: {
    id: "DISCOVERY_PRODUCTS_AGRICULTURE_TITLE",
    defaultMessage: "Agriculture",
  },
  DISCOVERY_PRODUCTS_AGRICULTURE_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_AGRICULTURE_DESCRIPTION",
    defaultMessage:
      "Our Agriculture loans support businesses in the agricultural ecosystem like farm…",
  },
  DISCOVERY_PRODUCTS_AGRICULTURE_DESCRIPTION_KE: {
    id: "DISCOVERY_PRODUCTS_AGRICULTURE_DESCRIPTION_KE",
    defaultMessage:
      "Providing small scale farmers with inputs to increase the quality of agricultural outputs and harvest volumes to achieve expanded income for sustainable growth",
  },
  DISCOVERY_PRODUCTS_EASY_TITLE: {
    id: "DISCOVERY_PRODUCTS_EASY_TITLE",
    defaultMessage: "Easy",
  },
  DISCOVERY_PRODUCTS_EASY_DESCRIPTION: {
    id: "DISCOVERY_PRODUCTS_EASY_DESCRIPTION",
    defaultMessage:
      "Our Easy loans are appropriate for employees of reputable organizations…",
  },
  DISCOVERY_PRODUCTS_EASY_DESCRIPTION_KE: {
    id: "DISCOVERY_PRODUCTS_EASY_DESCRIPTION_KE",
    defaultMessage:
      "Tailored financial solutions for individual entrepreneurs who require working capital to boost stock and future sales potential.",
  },
  DISCOVERY_PRODUCTS_CALLBACK_SUBTITLE: {
    id: "DISCOVERY_PRODUCTS_CALLBACK_SUBTITLE",
    defaultMessage: "To get a call back",
  },
  DISCOVERY_PRODUCTS_CALLBACK_DESC: {
    id: "DISCOVERY_PRODUCTS_CALLBACK_DESC",
    defaultMessage:
      "Provide you details to know more about MSE Purchase Order Financing and we will get back to you soon.",
  },
  FAQ_NEW_CUSTOMER_QUESTION_FIRST: {
    id: "FAQ_NEW_CUSTOMER_QUESTION_FIRST",
    defaultMessage: "What are the documents required to apply for a loan?",
  },
  FAQ_NEW_CUSTOMER_BOTSWANA_ANSWER_FIRST: {
    id: "FAQ_NEW_CUSTOMER_BOTSWANA_ANSWER_FIRST",
    defaultMessage:
      "The required documents for a new loan application are: <br />\n    • Identity Document: National ID card (Omang) for Botswana citizens and Passport for non Botswana citizens <br/>\n    • 3 month pay slip<br/>\n    • 3 month bank statement<br/>\n    • Confirmation letter of employment<br/>\n    • Marriage certificate, if you are married<br/>\n    • Spousal consent, if you are married in community of property<br/>\n    • Settlement quotation letter, if you would like Letshego to settle your existing payroll loans with other banks",
  },
  FAQ_NEW_CUSTOMER_NIGERIA_ANSWER_FIRST: {
    id: "FAQ_NEW_CUSTOMER_NIGERIA_ANSWER_FIRST",
    defaultMessage:
      "The required documents for a new loan application are:<br/>\n    • Identity Document: Passport, Driving License, Permanent Voter Card, NIMC<br/>\n    • 3 month recent pay slip<br/>\n    • 3 month bank statement <br/>\n    • Confirmation letter of employment, if you work for a private company<br/>\n    • Settlement quotation letter, if you would like Letshego to settle your existing payroll loans with other banks",
  },
  FAQ_NEW_CUSTOMER_QUESTION_SECOND: {
    id: "FAQ_NEW_CUSTOMER_QUESTION_SECOND",
    defaultMessage: "How can I borrow more ?",
  },
  FAQ_NEW_CUSTOMER_ANSWERS_SECOND: {
    id: "FAQ_NEW_CUSTOMER_ANSWERS_SECOND",
    defaultMessage:
      "If you have an ongoing loan with LetsGO.<br/>\n      a) Go to your Letsgo payroll app home screen.<br/>\n      c) Enter the required amount to start application OR select your personalized offer<br/>\n      d) Confirm your personal and employment information.<br/>\n      e) Upload all required documents.<br/>\n      f) Click 'continue' to calculate affordability.<br/>\n      g) View and adjust your loan request as per your affordability.<br/>\n      h) Submit request to borrow more.<br/>\n      i) Get your spouse's consent if married in community of property<br/>\n      j) Wait for final offer from LetsGO.<br/>\n      k) Accept the final offer after reading all offer and contract documents.<br/>\n      l) Your loan application is complete. Your account will be credited within 48 hours.",
  },
  FAQ_NEW_CUSTOMER_QUESTION_THIRD: {
    id: "FAQ_NEW_CUSTOMER_QUESTION_THIRD",
    defaultMessage:
      "How soon can I get funds if I apply for a DAS payroll loan with Letshego?",
  },
  FAQ_NEW_CUSTOMER_BOTSWANA_ANSWERS_THIRD: {
    id: "FAQ_NEW_CUSTOMER_BOTSWANA_ANSWERS_THIRD",
    defaultMessage:
      "You will receive your loan funds within 48 hours from you loan approval.  Even sooner if your account is with FNB, ABSA and Standard Chartered",
  },
  FAQ_NEW_CUSTOMER_NIGERIA_ANSWERS_THIRD: {
    id: "FAQ_NEW_CUSTOMER_NIGERIA_ANSWERS_THIRD",
    defaultMessage:
      "You will receive your loan funs within 48 hours from your loan approval",
  },
  FAQ_EXISTING_CUSTOMER_QUESTION_FIRST: {
    id: "FAQ_EXISTING_CUSTOMER_QUESTION_FIRST",
    defaultMessage: "Can I change the term of my ongoing loan ?",
  },
  FAQ_EXISTING_CUSTOMER_ANSWER_FIRST: {
    id: "FAQ_EXISTING_CUSTOMER_ANSWER_FIRST",
    defaultMessage:
      "Yes you can! Please contact us to change the terms of your loan",
  },
  FAQ_EXISTING_CUSTOMER_QUESTION_SECOND: {
    id: "FAQ_EXISTING_CUSTOMER_QUESTION_SECOND",
    defaultMessage:
      "I already have a loan with another Bank, can I consolidate that with the my ongoing loan ?",
  },
  FAQ_EXISTING_CUSTOMER_ANSWER_SECOND: {
    id: "FAQ_EXISTING_CUSTOMER_ANSWER_SECOND",
    defaultMessage:
      "Yes! we can consolidate your other loans into one Letshego loan.",
  },
  FAQ_EXISTING_CUSTOMER_QUESTION_THIRD: {
    id: "FAQ_EXISTING_CUSTOMER_QUESTION_THIRD",
    defaultMessage:
      "Can I payoff my loan early? If yes what are the procedures ?",
  },
  FAQ_EXISTING_CUSTOMER_ANSWER_THIRD: {
    id: "FAQ_EXISTING_CUSTOMER_ANSWER_THIRD",
    defaultMessage:
      "Customers can settle their loans early. However, early settlement will require a 3-month notice, alternatively the customer can pay a penalty fee and settle in less than three months from giving notice of intent to settle in full. Please whatsapp or call us for more details.",
  },
  FAQ_EXISTING_CUSTOMER_NIGERIA_ANSWER_THIRD: {
    id: "FAQ_EXISTING_CUSTOMER_NIGERIA_ANSWER_THIRD",
    defaultMessage:
      "Yes, all you need is to make your request known by sending SMS, email or call, a settlement quotation will be generated and sent to customer, when payment is made into Letshego’s account, the loan will be closed and a confirmation sent",
  },
  FAQ_PAIDOFF_CUSTOMER_QUESTION_FIRST: {
    id: "FAQ_PAIDOFF_CUSTOMER_QUESTION_FIRST",
    defaultMessage:
      "To apply for another loan, will I need to resubmit my documents ?",
  },
  FAQ_PAIDOFF_CUSTOMER_ANSWER_FIRST: {
    id: "FAQ_PAIDOFF_CUSTOMER_ANSWER_FIRST",
    defaultMessage:
      "Yes! Everytime a Top-up is processed, we need the customer to submit their required documents to confirm your current levels of affordability.  Letshego helps customers to apply for loans they can afford, so you avoid unnecessary financial pressure. ",
  },
  FAQ_PAIDOFF_CUSTOMER_QUESTION_SECOND: {
    id: "FAQ_PAIDOFF_CUSTOMER_QUESTION_SECOND",
    defaultMessage: "On what basis will my eligibility change ?",
  },
  FAQ_PAIDOFF_CUSTOMER_ANSWER_SECOND: {
    id: "FAQ_PAIDOFF_CUSTOMER_ANSWER_SECOND",
    defaultMessage:
      "Eligibility will change on various reasons like salary, having additional allowances, settling a third party and reducing your loan balance.",
  },
  FAQ_PAIDOFF_CUSTOMER_QUESTION_THIRD: {
    id: "FAQ_PAIDOFF_CUSTOMER_QUESTION_THIRD",
    defaultMessage: "What other channels can I use to apply for a loan ?",
  },
  FAQ_PAIDOFF_CUSTOMER_ANSWER_THIRD: {
    id: "FAQ_PAIDOFF_CUSTOMER_ANSWER_THIRD",
    defaultMessage:
      "At Letshego we like to provde customers with a CHOICE in how they can contact us - whatsapp, email, call or visit us in one of our branches.<br/> \n    Branch: Visit your nearest Letshego Branch with the required documents.<br/>\n    Call Centre: Call our Call Centre on +267 3643300 and one of our consultants will assist you.<br/>\n    Web: Apply through our web portal at https://appforms.letshego.com<br/>\n    WhatsApp: Apply through WhatsApp at +267 71234567<br/>\n    USSD: Apply through USSD by dialing *184#",
  },
  FAQ_PAIDOFF_CUSTOMER_NIGERIA_ANSWER_THIRD: {
    id: "FAQ_PAIDOFF_CUSTOMER_NIGERIA_ANSWER_THIRD",
    defaultMessage:
      "At Letshego we like to provde customers with a CHOICE in how they can contact us - whatsapp, email, call or visit us in one of our branches - Branch: Visit your nearest Letshego Branch with the required documents.<br/>\n      Call Centre: Call our Call Centre on +234 9053911450 and one of our consultants will assist you.<br/>\n      Web: Apply through our web portal at https://appforms.letshego.com<br/>\n      WhatsApp: Apply through WhatsApp at +234 8159393199 <br/>\n      USSD: Apply through USSD by dialing *5102#<br/>",
  },
  FAQ_COMMON_ANSWER: {
    id: "FAQ_COMMON_ANSWER",
    defaultMessage:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  },
  PREVIEW_LABEL: {
    id: "PREVIEW_LABEL",
    defaultMessage:
      "Preview your picture and ensure all details on your ID document are clearly visible",
  },
  PICTURE_UPLOADED_QUESTION: {
    id: "PICTURE_UPLOADED_QUESTION",
    defaultMessage: "Is the picture clear and okay to upload?",
  },
  RETAKE_BUTTON: { id: "RETAKE_BUTTON", defaultMessage: "No , retake" },
  UPLOAD_BUTTON: { id: "UPLOAD_BUTTON", defaultMessage: "Yes, Upload" },
  WEB_CAM_NOT_DETECTED: {
    id: "WEB_CAM_NOT_DETECTED",
    defaultMessage: "Webcam not detected",
  },
  WEB_CAM_NOT_DETECTED_CONTENT: {
    id: "WEB_CAM_NOT_DETECTED_CONTENT",
    defaultMessage:
      "Please plug in your webcam, use a different device with a camera or {download}",
  },
  SETTLEMENT_AMOUNT: {
    id: "SETTLEMENT_AMOUNT",
    defaultMessage: "Settlement amount",
  },
  GET_IN_ACCOUNT: {
    id: "GET_IN_ACCOUNT",
    defaultMessage: "You will get in account",
  },
  LOAN_SETTLEMENT_SUCCESS: {
    id: "LOAN_SETTLEMENT_SUCCESS",
    defaultMessage: "This amount includes your new loan and settlement amount",
  },
  NEW_CUSTOMER_STEPPER_FILL: {
    id: "NEW_CUSTOMER_STEPPER_FILL",
    defaultMessage: "Fill",
  },
  NEW_CUSTOMER_STEPPER_APPLICATION: {
    id: "NEW_CUSTOMER_STEPPER_APPLICATION",
    defaultMessage: "application",
  },
  NEW_CUSTOMER_STEPPER_GET: {
    id: "NEW_CUSTOMER_STEPPER_GET",
    defaultMessage: "Get",
  },
  NEW_CUSTOMER_STEPPER_DESCISION: {
    id: "NEW_CUSTOMER_STEPPER_DESCISION",
    defaultMessage: "decision",
  },
  NEW_CUSTOMER_STEPPER_UPLOAD: {
    id: "NEW_CUSTOMER_STEPPER_UPLOAD",
    defaultMessage: "Upload",
  },
  NEW_CUSTOMER_STEPPER_DOCUMENTS: {
    id: "NEW_CUSTOMER_STEPPER_DOCUMENTS",
    defaultMessage: "documents",
  },
  HOW_LABEL: { id: "HOW_LABEL", defaultMessage: "How" },
  PAYROLL_WORK_LABEL: {
    id: "PAYROLL_WORK_LABEL",
    defaultMessage: "Payroll works?",
  },
  NON_PAYROLL_WORK_LABEL: {
    id: "NON_PAYROLL_WORK_LABEL",
    defaultMessage: "Non Payroll works?",
  },
  NON_PAYROLL_WORK_LABEL_BOTSWANA: {
    id: "NON_PAYROLL_WORK_LABEL_BOTSWANA",
    defaultMessage: "LetsGo Motshegetsi Loan works?",
  },
  NON_PAYROLL_WORK_LABEL_NIGERIA: {
    id: "NON_PAYROLL_WORK_LABEL_NIGERIA",
    defaultMessage: "Easy Loan works?",
  },
  ID_DOCUMENT_LABEL: { id: "ID_DOCUMENT_LABEL", defaultMessage: "ID Document" },
  NIGERIA_ID_DOCUMENT_LABEL: {
    id: "NIGERIA_ID_DOCUMENT_LABEL",
    defaultMessage:
      "Your valid National Identity card, Passport, Voter card, Driving license or NIMC card",
  },
  CAPTURE: { id: "CAPTURE", defaultMessage: "Capture" },
  REJECTED_PARA_TEXT: {
    id: "REJECTED_PARA_TEXT",
    defaultMessage: "Explore more lending options or re-apply for a new loan",
  },
  REJECTED_LOAN_HEADING: {
    id: "REJECTED_LOAN_HEADING",
    defaultMessage: "Sorry! Your loan application is not approved.",
  },
  REJECTED_LOAN_PARA: {
    id: "REJECTED_LOAN_PARA",
    defaultMessage:
      "Loan request not approved as a few of your details don't match our payroll loan criteria.",
  },
  REJECTED_TOAST: {
    id: "REJECTED_TOAST",
    defaultMessage: "Your loan offer has been rejected",
  },
  CANCELLED_TOAST: {
    id: "CANCELLED_TOAST",
    defaultMessage: "Your loan offer has been cancelled",
  },
  EXPIRED_TOAST: {
    id: "EXPIRED_TOAST",
    defaultMessage: "Your loan offer has expired.",
  },
  ACCESS_TITLE: { id: "ACCESS_TITLE", defaultMessage: "Access the" },
  SPOUSE_CONSENT_TITLE: {
    id: "SPOUSE_CONSENT_TITLE",
    defaultMessage: "Spouse consent!",
  },
  ENTER_MOBILE_NUMBER: {
    id: "ENTER_MOBILE_NUMBER",
    defaultMessage: "Enter mobile number",
  },
  ENTER_OTP_TEXT_SPOUSE: {
    id: "ENTER_OTP_TEXT_SPOUSE",
    defaultMessage: "Enter the OTP sent on {phone}",
  },
  HELLO: { id: "HELLO", defaultMessage: "Hello" },
  CONSENT_INFO: {
    id: "CONSENT_INFO",
    defaultMessage:
      "As the spouse of applicant {name}, I consent to the above loan application on {date} to Letshego Lending Pvt. Ltd. as a co-principal debtor for the loan.",
  },
  CONSENT_YES: { id: "CONSENT_YES", defaultMessage: "I consent" },
  CONSENT_NO: { id: "CONSENT_NO", defaultMessage: "I don't consent" },
  SPOUSE_CONSENT_MODAL_SUBTEXT: {
    id: "SPOUSE_CONSENT_MODAL_SUBTEXT",
    defaultMessage: "We will send a loan update to your spouse.",
  },
  RESPONSE_SUBMITTED: {
    id: "RESPONSE_SUBMITTED",
    defaultMessage: "Response submitted!",
  },
  SPOUSE_CONSENT_MODAL_TITLE: {
    id: "SPOUSE_CONSENT_MODAL_TITLE",
    defaultMessage: "You didn’t consent to the loan application.",
  },
  CONFIRM_CONSENT_MODAL_TITLE: {
    id: "CONFIRM_CONSENT_MODAL_TITLE",
    defaultMessage: "Don’t want to consent",
  },
  CONFIRM_CONSENT_MODAL_CONTENT: {
    id: "CONFIRM_CONSENT_MODAL_CONTENT",
    defaultMessage:
      "Your spouse will loose this loan offer. Are you sure you don’t want to consent?",
  },
  CONFIRM_CONSENT_MODAL_BUTTON: {
    id: "CONFIRM_CONSENT_MODAL_BUTTON",
    defaultMessage: "Yes, don’t consent",
  },
  CUSTOMER_SERVICE_SPOUSE_HELP_TEXT: {
    id: "CUSTOMER_SERVICE_SPOUSE_HELP_TEXT",
    defaultMessage:
      "If your spouse hasn’t initiated this or the above details are not yours, please contact {link}.",
  },
  SPOUSE_TERMS_CONDITIONS: {
    id: "SPOUSE_TERMS_CONDITIONS",
    defaultMessage:
      "By providing consent I confirm that I have read the {link} and am aware of the consequences of providing false information.",
  },
  CUSTOMER_SERVICE: {
    id: "CUSTOMER_SERVICE",
    defaultMessage: "customer service",
  },
  TERMS_CONDITIONS: {
    id: "TERMS_CONDITIONS",
    defaultMessage: "terms & conditions",
  },
  MR: { id: "MR", defaultMessage: "Mr." },
  MRS: { id: "MRS", defaultMessage: "Mrs." },
  EXPLORE_LOANS_TITLE_ONE: {
    id: "EXPLORE_LOANS_TITLE_ONE",
    defaultMessage: "What loans",
  },
  EXPLORE_LOANS_TITLE_TWO: {
    id: "EXPLORE_LOANS_TITLE_TWO",
    defaultMessage: "Are you eligible for?",
  },
  EXPLORE_PAYROLL_LOANS_SUBTITLE: {
    id: "EXPLORE_PAYROLL_LOANS_SUBTITLE",
    defaultMessage: "Simple unsecured financing to help you improve your life.",
  },
  EXPLORE_MSE_ORDER_SUBTITLE: {
    id: "EXPLORE_MSE_ORDER_SUBTITLE",
    defaultMessage:
      "Access finance against purchase orders & invoicing discount to fund your venture and project needs.",
  },
  EXPLORE_STEP_UP_SUBTITLE: {
    id: "EXPLORE_STEP_UP_SUBTITLE",
    defaultMessage:
      "Access finance for your business needs with a short turn-around time and competitive interest rates.",
  },
  EXPLORE_STEP_UP_CRITERIA_TWO: {
    id: "EXPLORE_STEP_UP_CRITERIA_TWO",
    defaultMessage: "Duly registered Micro, Small and Medium enterprises",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_ONE: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_ONE",
    defaultMessage: "Duly registered Schools, Tutorial centers & Creches",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_TWO: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_TWO",
    defaultMessage: "Operational for 6 months",
  },
  EXPLORE_EDUCATION_LOAN_CRITERIA_THREE: {
    id: "EXPLORE_EDUCATION_LOAN_CRITERIA_THREE",
    defaultMessage: "Teachers, parents & school suppliers",
  },
  EXPLORE_HEALTH_LOAN_CRITERIA_ONE: {
    id: "EXPLORE_HEALTH_LOAN_CRITERIA_ONE",
    defaultMessage:
      "Duly registered Hospitals, Pharmacies, Health Centers, Clinics & medical facilities",
  },
  SURVEY_MODAL_TITLE: {
    id: "SURVEY_MODAL_TITLE",
    defaultMessage: "How did you discover LetsGo App?",
  },
  SUBMIT_RESPONSE: { id: "SUBMIT_RESPONSE", defaultMessage: "Submit response" },
  THANK_YOU_FOR_RESPONSE: {
    id: "THANK_YOU_FOR_RESPONSE",
    defaultMessage: "Thank you for your response.",
  },
  LOAN_TENURE: { id: "LOAN_TENURE", defaultMessage: "Loan tenure" },
  REMAINING_TENURE: {
    id: "REMAINING_TENURE",
    defaultMessage: "Remaining tenure",
  },
  LAST_INSTALLMENT: {
    id: "LAST_INSTALLMENT",
    defaultMessage: "Last installment",
  },
  FIRST_INSTALLMENT: {
    id: "FIRST_INSTALLMENT",
    defaultMessage: "First installment",
  },
  MONTHELY_EMI: { id: "MONTHELY_EMI", defaultMessage: "Monthly EMI" },
  FEES_AND_CHARGES: {
    id: "FEES_AND_CHARGES",
    defaultMessage: "Fees and charges",
  },
  LETSHEGO_INSURANCE: {
    id: "LETSHEGO_INSURANCE",
    defaultMessage: "Letshego Insurance",
  },
  VIEW_LOAN_DETAILS: {
    id: "VIEW_LOAN_DETAILS",
    defaultMessage: "View Loan Details",
  },
  CONTRACT_DOCUMENTS: {
    id: "CONTRACT_DOCUMENTS",
    defaultMessage: "Contract documents",
  },
  CONTRACT_DOCUMENTS_TITLE: {
    id: "CONTRACT_DOCUMENTS_TITLE",
    defaultMessage:
      "Click on each link to read all offer documents carefully before accepting.",
  },
  DOC_LON_APLCTN: { id: "DOC_LON_APLCTN", defaultMessage: "Loan application" },
  DOC_LON_AGR: { id: "DOC_LON_AGR", defaultMessage: "Loan agreement" },
  DOC_LON_SCH: { id: "DOC_LON_SCH", defaultMessage: "Loan schedule" },
  DOC_REPAYMENT_SCHED: {
    id: "DOC_REPAYMENT_SCHED",
    defaultMessage: "Loan repayment Schedule",
  },
  DOC_DED_AUTHLTR: {
    id: "DOC_DED_AUTHLTR",
    defaultMessage: "Deduction authority",
  },
  STEP_UP_LOAN: { id: "STEP_UP_LOAN", defaultMessage: "Step-up loan" },
  EDUCATION_LOAN: { id: "EDUCATION_LOAN", defaultMessage: "Education loan" },
  HEALTH_LOAN: { id: "HEALTH_LOAN", defaultMessage: "Health loan" },
  MSE_PURCHASE: {
    id: "MSE_PURCHASE",
    defaultMessage: "MSE purchase order loan",
  },
  MICRO_FINANCE_LOAN: {
    id: "MICRO_FINANCE_LOAN",
    defaultMessage: "Micro finance loan",
  },
  EXPLORE_REFERRAL_TITLE_ONE: {
    id: "EXPLORE_REFERRAL_TITLE_ONE",
    defaultMessage: "Refer a friend &",
  },
  EXPLORE_REFERRAL_TITLE_TWO: {
    id: "EXPLORE_REFERRAL_TITLE_TWO",
    defaultMessage: "Get rewarded!",
  },
  EXPLORE_REFERRAL_SUBTITLE: {
    id: "EXPLORE_REFERRAL_SUBTITLE",
    defaultMessage: "Share your code with your friends to get rewarded",
  },
  COPY: { id: "COPY", defaultMessage: "Copy" },
  COPY_TOKEN: { id: "COPY_TOKEN", defaultMessage: "Copy Token" },
  EXPLORE_MANAGE_MONEY_TITLE_ONE: {
    id: "EXPLORE_MANAGE_MONEY_TITLE_ONE",
    defaultMessage: "Manage my",
  },
  EXPLORE_MANAGE_MONEY_TITLE_TWO: {
    id: "EXPLORE_MANAGE_MONEY_TITLE_TWO",
    defaultMessage: "Money better!",
  },
  SAVE_REFERRAL_MESSAGE: {
    id: "SAVE_REFERRAL_MESSAGE",
    defaultMessage: "Great! Thank you for rewarding",
  },
  SAVE_REFERRAL_MESSAGE_FAILURE: {
    id: "SAVE_REFERRAL_MESSAGE_FAILURE",
    defaultMessage:
      "Something went wrong, we could not register your referral code. Please try again.",
  },
  CONTACT_DETAILS_TEXT: {
    id: "CONTACT_DETAILS_TEXT",
    defaultMessage: "Contact details",
  },
  ADDRESS_TEXT: { id: "ADDRESS_TEXT", defaultMessage: "Address" },
  EMAIL_ADDRESS: { id: "EMAIL_ADDRESS", defaultMessage: "Email address" },
  LOAN_TEXT: { id: "LOAN_TEXT", defaultMessage: "Loan" },
  HISTORY_TEXT: { id: "HISTORY_TEXT", defaultMessage: "History" },
  PURPOSE_TEXT: { id: "PURPOSE_TEXT", defaultMessage: "Purpose" },
  TAKEN_ON: { id: "TAKEN_ON", defaultMessage: "Taken on" },
  CLOSED_ON: { id: "CLOSED_ON", defaultMessage: "Closed on" },
  STATUS_TEXT: { id: "STATUS_TEXT", defaultMessage: "Status" },
  REFER_A_FRIEND_TEXT: {
    id: "REFER_A_FRIEND_TEXT",
    defaultMessage: "Refer a friend",
  },
  SHARE_CODE_TEXT: {
    id: "SHARE_CODE_TEXT",
    defaultMessage: "Share this code to earn rewards",
  },
  REWARD_TEXT: {
    id: "REWARD_TEXT",
    defaultMessage:
      "If you were helped by a Letshego staff/agent or referred by a friend/family ",
  },
  REWARD_NOW_TEXT: { id: "REWARD_NOW_TEXT", defaultMessage: "Reward now" },
 
  PROFILE_TABS: { id: "PROFILE_TABS", defaultMessage: "Profile Tabs" },
  VIEW_PROFILE: { id: "VIEW_PROFILE", defaultMessage: "View Profile" },
  LOAN_HISTORY: { id: "LOAN_HISTORY", defaultMessage: "Loan History" },
  MANAGE_SETTINGS: { id: "MANAGE_SETTINGS", defaultMessage: "Manage Settings" },
  FULL_NAME_TEXT: { id: "FULL_NAME_TEXT", defaultMessage: "Full name" },
  MARITAL_STATUS_TEXT: {
    id: "MARITAL_STATUS_TEXT",
    defaultMessage: "Marital status",
  },
  CHANGE_PASSWORD_TEXT: {
    id: "CHANGE_PASSWORD_TEXT",
    defaultMessage: "Change Password",
  },
  PROFILE_PHOTO_TEXT: {
    id: "PROFILE_PHOTO_TEXT",
    defaultMessage: "Profile photo",
  },
  VIEW_TEXT: { id: "VIEW_TEXT", defaultMessage: "View" },
  LOAN_REQUEST_TIMED_OUT: {
    id: "LOAN_REQUEST_TIMED_OUT",
    defaultMessage:
      "Loan request timed out! No worries. You may start over by checking your eligibility",
  },
  EXPLORE_ALERT_TEXT: {
    id: "EXPLORE_ALERT_TEXT",
    defaultMessage:
      "At the moment you do not qualify for LetsGo payroll loan. For additional queries contact us on {contactNumber}",
  },
  EXPLORE_MANAGE_MONEY_BUDGET_TITLE: {
    id: "EXPLORE_MANAGE_MONEY_BUDGET_TITLE",
    defaultMessage: "What is ",
  },
  EXPLORE_MANAGE_MONEY_BUDGET_SUBTITLE: {
    id: "EXPLORE_MANAGE_MONEY_BUDGET_SUBTITLE",
    defaultMessage: "Budget?",
  },
  EXPLORE_MANAGE_MONEY_BUDGET_DESCRIPTION: {
    id: "EXPLORE_MANAGE_MONEY_BUDGET_DESCRIPTION",
    defaultMessage:
      "A simple tool that helps you take control of your finances",
  },
  EXPLORE_MANAGE_MONEY_SAVING_TITLE: {
    id: "EXPLORE_MANAGE_MONEY_SAVING_TITLE",
    defaultMessage: "Budget",
  },
  EXPLORE_MANAGE_MONEY_SAVING_SUBTITLE: {
    id: "EXPLORE_MANAGE_MONEY_SAVING_SUBTITLE",
    defaultMessage: "Saving tips",
  },
  EXPLORE_MANAGE_MONEY_SAVING_DESCRIPTION: {
    id: "EXPLORE_MANAGE_MONEY_SAVING_DESCRIPTION",
    defaultMessage: "Learn to use the 50 - 30 - 20 Rule",
  },
  EXPLORE_MANAGE_MONEY_CREATION_TITLE: {
    id: "EXPLORE_MANAGE_MONEY_CREATION_TITLE",
    defaultMessage: "Budget",
  },
  EXPLORE_MANAGE_MONEY_CREATION_SUBTITLE: {
    id: "EXPLORE_MANAGE_MONEY_CREATION_SUBTITLE",
    defaultMessage: "Creation tips",
  },
  EXPLORE_MANAGE_MONEY_CREATION_DESCRIPTION: {
    id: "EXPLORE_MANAGE_MONEY_CREATION_DESCRIPTION",
    defaultMessage: "Simple steps to make a budget",
  },
  LOADING: { id: "LOADING", defaultMessage: "LOADING..." },
  VERIFY_CARD: {
    id: "VERIFY_CARD",
    defaultMessage: "Please wait while we verify your card details",
  },
  CARD_VERIFICATION_DISCLAIMER_1: {
    id: "CARD_VERIFICATION_DISCLAIMER_1",
    defaultMessage:
      "To complete your application, please link your debit card.",
  },
  CARD_VERIFICATION_DISCLAIMER_2: {
    id: "CARD_VERIFICATION_DISCLAIMER_2",
    defaultMessage:
      "You will be redirected to PAYSTACK. Our trusted partner's gateway screen.",
  },
  CARD_VERIFICATION_DISCLAIMER_3: {
    id: "CARD_VERIFICATION_DISCLAIMER_3",
    defaultMessage: "Please read the disclaimer carefully before proceeding.",
  },
  PAYMENT_METHOD: { id: "PAYMENT_METHOD", defaultMessage: "Payment method" },
  PAYMENT_METHOD_TITLE: {
    id: "PAYMENT_METHOD_TITLE",
    defaultMessage: "Sending funds from",
  },
  BANK_VERIFICATION_TITLE: {
    id: "BANK_VERIFICATION_TITLE",
    defaultMessage:
      "Enter your card details, this card will be charged for your future instalments",
  },
  CURRENT_PASSWORD: {
    id: "CURRENT_PASSWORD",
    defaultMessage: "Current password",
  },
  SET_NEW_PASSWORD: {
    id: "SET_NEW_PASSWORD",
    defaultMessage: "Set new password",
  },
  NEW_PASSWORD_SETTING_CONFIG_TEXT: {
    id: "NEW_PASSWORD_SETTING_CONFIG_TEXT",
    defaultMessage:
      "Use a combination of letters & numbers. Min 4 - max 32 characters long",
  },
  ID_VERIFICATION_MESSAGE: {
    id: "ID_VERIFICATION_MESSAGE",
    defaultMessage: "Complete your ID validation to view your profile.",
  },
  RESUME_APPLICATION_TITLE: {
    id: "RESUME_APPLICATION_TITLE",
    defaultMessage: "Complete you application",
  },
  RESUME_APPLICATION_CONTENT: {
    id: "RESUME_APPLICATION_CONTENT",
    defaultMessage: "you can resume your application from where you left off.",
  },
  PASSWORD_MATCH_ERROR: {
    id: "PASSWORD_MATCH_ERROR",
    defaultMessage: "Your password does not match",
  },
  PASSWORD_SUCCESSFULLY_CHANGED: {
    id: "PASSWORD_SUCCESSFULLY_CHANGED",
    defaultMessage:
      "Your password has been reset successfully.  You can proceed to log-in",
  },
  PASSWORD_CHANGE_ERROR: {
    id: "PASSWORD_CHANGE_ERROR",
    defaultMessage: "Your provided existing password does not match.",
  },
  PASSWORD_CHANGE_FAILURE: {
    id: "PASSWORD_CHANGE_FAILURE",
    defaultMessage: "Password change failed. Please re-try.",
  },
  CURRENT_PASSWORD_ERROR: {
    id: "CURRENT_PASSWORD_ERROR",
    defaultMessage: "Please enter your current password.",
  },
  SORRY_NO_DATA_FOUND: {
    id: "SORRY_NO_DATA_FOUND",
    defaultMessage: "No Records Found",
  },
  CONFIRM_PASSWORD_TEXT: {
    id: "CONFIRM_PASSWORD_TEXT",
    defaultMessage:
      "Use a combination of letters & numbers. Min 4 - max 32 characters long",
  },
  ENTER_REFCODE_OTHER: {
    id: "ENTER_REFCODE_OTHER",
    defaultMessage: "Please enter a code other than your own",
  },
  INVALID_REFCODE: {
    id: "INVALID_REFCODE",
    defaultMessage: "Please enter a valid code",
  },
  CAPTURE_UPLOAD: { id: "CAPTURE_UPLOAD", defaultMessage: "Capture & upload" },
  DISCOVERY_PRODUCTS_ACTION_START: {
    id: "DISCOVERY_PRODUCTS_ACTION_START",
    defaultMessage: "Start application online",
  },
  OCR_BACK_BOTTOM_TEXT: {
    id: "OCR_BACK_BOTTOM_TEXT",
    defaultMessage:
      "Upload a picture of your ID for a simple and seamless application process.",
  },
  CANCEL: { id: "CANCEL", defaultMessage: "Cancel" },
  YES: { id: "YES", defaultMessage: "Yes" },
  CLICK_PHOTO_TEXT: { id: "CLICK_PHOTO_TEXT", defaultMessage: "Click photo" },
  UPLOAD_PHOTO_TEXT: {
    id: "UPLOAD_PHOTO_TEXT",
    defaultMessage: "Upload photo",
  },
  REMOVE_PHOTO_TEXT: {
    id: "REMOVE_PHOTO_TEXT",
    defaultMessage: "Remove photo",
  },
  POLICIES_PERMISSION_TEXT: {
    id: "POLICIES_PERMISSION_TEXT",
    defaultMessage: "Privacy and permissions",
  },
  POLICIES_PERMISSION_DETAILS: {
    id: "POLICIES_PERMISSION_DETAILS",
    defaultMessage:
      "When you download the Letshego Digital Platform we will ask you to provide personal information in order to verify your identity and level of affordability, ultimately helping us to ensure that we offer you a loan or top-up that fits your need and your personal budget. We take privacy very seriously and your personal information will never be shared without your direct permission.",
  },
  REFERRAL_CLIP_BOARD_TEXT: {
    id: "REFERRAL_CLIP_BOARD_TEXT",
    defaultMessage: "Copied to Clipboard",
  },
  LOAN_BACK_BTN_OCR_UPLOAD_PASSPORT: {
    id: "LOAN_BACK_BTN_OCR_UPLOAD_PASSPORT",
    defaultMessage: "Upload your passport",
  },
  OCR_BACK_BOTTOM_TEXT_PASSPORT: {
    id: "OCR_BACK_BOTTOM_TEXT_PASSPORT",
    defaultMessage:
      "Upload a picture of your passport for simple and seamless loan application process",
  },
  SECURITY_MESSAGE_TOAST: {
    id: "SECURITY_MESSAGE_TOAST",
    defaultMessage: "Your data is 100% secure with us",
  },
  APP_STORE: { id: "APP_STORE", defaultMessage: "App Store" },
  FUNDS_DISBURSAL_MESSAGE: {
    id: "FUNDS_DISBURSAL_MESSAGE",
    defaultMessage: "Funds will be disbursed to you soon.",
  },
  TERMS_AND_TEXT: { id: "TERMS_AND_TEXT", defaultMessage: "Terms and" },
  CONDITIONS_TEXT: { id: "CONDITIONS_TEXT", defaultMessage: "Conditions" },
  PRIVACY_TEXT: { id: "PRIVACY_TEXT", defaultMessage: "Privacy" },
  POLICY_TEXT: { id: "POLICY_TEXT", defaultMessage: "Policy" },
  TAB_TITLE: { id: "TAB_TITLE", defaultMessage: "LetsGo Digital" },
  ELIGIBILITY: { id: "ELIGIBILITY", defaultMessage: "Eligibility" },
  SUPPORT: { id: "SUPPORT", defaultMessage: "Support" },
  LOAN_APPLICATION: {
    id: "LOAN_APPLICATION",
    defaultMessage: "Loan Application",
  },
  DISBURSAL_REPAYMENT: {
    id: "DISBURSAL_REPAYMENT",
    defaultMessage: "Disbursal & Repayment",
  },
  ABOUT_LETSHEGO: { id: "ABOUT_LETSHEGO", defaultMessage: "About Letshego" },
  THANKYOU_FOR_ANSWER: {
    id: "THANKYOU_FOR_ANSWER",
    defaultMessage: "Thank you for your answer!",
  },
  RECOMMEND_FOR_REFERRAL: {
    id: "RECOMMEND_FOR_REFERRAL",
    defaultMessage:
      "Recommend the LetsGo App to your friends and family and receive your reward as well.",
  },
  THANKYOU_FOR_ANSWER_STAFF: {
    id: "THANKYOU_FOR_ANSWER_STAFF",
    defaultMessage: "Thank you for your answer!",
  },
  THANKYOU_FOR_ANSWER_AGENT: {
    id: "THANKYOU_FOR_ANSWER_AGENT",
    defaultMessage: "Thank you for helping us reward our employees",
  },
  RECOMMEND_FOR_REFERRAL_STAFF: {
    id: "RECOMMEND_FOR_REFERRAL_STAFF",
    defaultMessage:
      "Recommend the LetsGo App to your friends and family and receive your reward as well.",
  },
  RECOMMEND_FOR_REFERRAL_AGENT: {
    id: "RECOMMEND_FOR_REFERRAL_AGENT",
    defaultMessage:
      "Our team ensures to provide better service to our customers.",
  },
  CONFIRM_DETAILS: { id: "CONFIRM_DETAILS", defaultMessage: "Confirm details" },
  OCR_COMPARISION_TEXT: {
    id: "OCR_COMPARISION_TEXT",
    defaultMessage:
      "The details entered by you do not match with your ID. Please pick the correct option to confirm details details",
  },
  NAME: { id: "NAME", defaultMessage: "NAME" },
  FILL_ALL_FIELDS: {
    id: "FILL_ALL_FIELDS",
    defaultMessage:
      "Please check all the fields highlighted in red before submitting",
  },
  LOAN_APPLICATION_IN_PROGRESS: {
    id: "LOAN_APPLICATION_IN_PROGRESS",
    defaultMessage: "You have an application in progress",
  },
  LOAN_APPLICATION_IN_PROGRESS_DISCLAIMER: {
    id: "LOAN_APPLICATION_IN_PROGRESS_DISCLAIMER",
    defaultMessage:
      "You can not apply for another loan. For any queries on your open application contact customer care team on: {phone}",
  },
  TO_LETSGO: { id: "TO_LETSGO", defaultMessage: "To Letsgo!" },
  ONE_APPLICATION_AT_A_TIME: {
    id: "ONE_APPLICATION_AT_A_TIME",
    defaultMessage: "You can have only one open application at a time.",
  },
  EMAIL_CHANGE_LINK_DESC: {
    id: "EMAIL_CHANGE_LINK_DESC",
    defaultMessage: "Not receiving OTP after several attempts? {updateEmail}",
  },
  EMAIL_CHANGE_LINK_REENTER: {
    id: "EMAIL_CHANGE_LINK_REENTER",
    defaultMessage: "Re-enter email address",
  },
  EMAIL_CHANGE_LINK: {
    id: "EMAIL_CHANGE_LINK",
    defaultMessage: "Change Email Address",
  },
  EMAIL_CHANGE_OTP_VERIFICATION_MSG: {
    id: "EMAIL_CHANGE_OTP_VERIFICATION_MSG",
    defaultMessage: "Enter the OTP sent on your email {email}",
  },
  EMAIL_CHANGE_NEW_EMAIL_MODAL_TITLE: {
    id: "EMAIL_CHANGE_NEW_EMAIL_MODAL_TITLE",
    defaultMessage: "Provide email address",
  },
  EMAIL_CHANGE_NEW_EMAIL_MODAL_DESC: {
    id: "EMAIL_CHANGE_NEW_EMAIL_MODAL_DESC",
    defaultMessage:
      "Enter your email address you would like to register with Letsgo",
  },
  EMAIL_CHANGE_NEW_EMAIL_MODAL_INPUT_LABEL: {
    id: "EMAIL_CHANGE_NEW_EMAIL_MODAL_INPUT_LABEL",
    defaultMessage: "Enter email address",
  },
  EMAIL_CHANGED_SUCCESS_MSG: {
    id: "EMAIL_CHANGED_SUCCESS_MSG",
    defaultMessage: "Email address changed",
  },
  EMAIL_CHANGED_SUCCESS_DESC: {
    id: "EMAIL_CHANGED_SUCCESS_DESC",
    defaultMessage: "Your email address has been successfully changed",
  },
  PRINCIPAL_LOAN_AMOUNT_MSG: {
    id: "PRINCIPAL_LOAN_AMOUNT_MSG",
    defaultMessage: "*Includes monthly instalment of your existing loan",
  },
  INVALID_SPOUSE_PHONE_NUMBER: {
    id: "INVALID_SPOUSE_PHONE_NUMBER",
    defaultMessage: "Invalid Number. Please re-enter.",
  },
  SPOUSE_INVALID_OTP: {
    id: "SPOUSE_INVALID_OTP",
    defaultMessage: "Invalid OTP. Please re-enter.",
  },
  SPOUSE_EXPIRED_OTP: {
    id: "SPOUSE_EXPIRED_OTP",
    defaultMessage: "OTP expired.",
  },
  EC_CALCULATIONS_INCLUDE_DISCLAIMER: {
    id: "EC_CALCULATIONS_INCLUDE_DISCLAIMER",
    defaultMessage: "The calculations include your existing loan amount",
  },
  EC_CALCULATIONS_LOAN_RECAP_DISCLAIMER: {
    id: "EC_CALCULATIONS_LOAN_RECAP_DISCLAIMER",
    defaultMessage: "*Includes monthly installment of your existing loan",
  },
  EC_LOAN_SETTLEMENT_SUCCESS: {
    id: "EC_LOAN_SETTLEMENT_SUCCESS",
    defaultMessage:
      "This amount includes your existing loan, settlement amount and top-up",
  },
  EC_LOAN_SETTLEMENT_FAILURE: {
    id: "EC_LOAN_SETTLEMENT_FAILURE",
    defaultMessage: "This amount includes your existing loan and top-up",
  },
  EC_LOAN_DASHBOARD_DISCLAIMER: {
    id: "EC_LOAN_DASHBOARD_DISCLAIMER",
    defaultMessage:
      "The loan details are indicative and the final loan offer may vary. Call <custom>customer service</custom> for more information.",
  },
  COMMON_EDIT: { id: "COMMON_EDIT", defaultMessage: "Edit" },
  MIN_TAKE_HOME_MSG: {
    id: "MIN_TAKE_HOME_MSG",
    defaultMessage:
      "Please ensure you have at least {amount} left from your salary, after payment of your installment every month.",
  },
  DATE_INVALID_FORMAT_ERROR: {
    id: "DATE_INVALID_FORMAT_ERROR",
    defaultMessage: "Enter date in valid format - dd/mm/yyyy",
  },
  LOAN_PROFILE_MULTIPLE_NID: {
    id: "LOAN_PROFILE_MULTIPLE_NID",
    defaultMessage: "ID Type",
  },
  LOAN_PROFILE_MUIT: {
    id: "LOAN_PROFILE_MUIT",
    defaultMessage: "NUIT",
  },
  TPRS_AFFORDABILITY_LABEL: {
    id: "TPRS_AFFORDABILITY_LABEL",
    defaultMessage: "TPRS Affordability",
  },
  TOTAL_DEDUCTIONS_LABEL: {
    id: "TOTAL_DEDUCTIONS_LABEL",
    defaultMessage: "What is your total deductions?",
  },
  PAYMENTS: {
    id: "PAYMENTS",
    defaultMessage: "Payments",
  },
  WALLET_BALANCE: {
    id: "WALLET_BALANCE",
    defaultMessage: "Wallet balance",
  },
  TOTAL_DUE_THIS_MONTH: {
    id: "TOTAL_DUE_THIS_MONTH",
    defaultMessage: "Total due this month",
  },
  VERIFY_ID: {
    id: "VERIFY_ID",
    defaultMessage: "Verify your ID",
  },
  VERIFY_ONLINE: {
    id: "VERIFY_ONLINE",
    defaultMessage: "Verify online",
  },
  LETS_GET_VERIFIED: {
    id: "LETS_GET_VERIFIED",
    defaultMessage:
      "Let’s get your verified online instantly transfer money, pay bills  and receive rewards!",
  },
  ADD_FUNDS: {
    id: "ADD_FUNDS",
    defaultMessage: "Add Funds",
  },
  ADD_FUNDS_MODAL_DESC: {
    id: "ADD_FUNDS_MODAL_DESC",
    defaultMessage: "Paying from your wallet balance",
  },
  WITHDRAW_FUNDS_MODAL_TITLE: {
    id: "WITHDRAW_FUNDS_MODAL_TITLE",
    defaultMessage: "Withdraw",
  },
  WITHDRAW_FUNDS_MODAL_DESC: {
    id: "WITHDRAW_FUNDS_MODAL_DESC",
    defaultMessage: "Transferring funds to your wallet balance",
  },
  ADD_FUNDS_MESSAGE: {
    id: "ADD_FUNDS_MESSAGE",
    defaultMessage: "has been added to your goal successfuly!",
  },
  WITHDRAW_FUNDS_MESSAGE: {
    id: "WITHDRAW_FUNDS_MESSAGE",
    defaultMessage: "has been withdrawn from your goal balance successfuly!",
  },
  ADD_FUNDS_MINIGOAL_MESSAGE: {
    id: "ADD_FUNDS_MINIGOAL_MESSAGE",
    defaultMessage:
      'has been added to your mini-goal " {miniGoalTitle} " successfuly!',
  },
  PAYMENT_DETAILS: {
    id: "PAYMENT_DETAILS",
    defaultMessage: "Payment details",
  },
  PAY: {
    id: "PAY",
    defaultMessage: "Pay",
  },
  SEND_FUNDS: {
    id: "SEND_FUNDS",
    defaultMessage: "Send Funds",
  },
  RECEIVE: {
    id: "RECEIVE",
    defaultMessage: "Receive",
  },
  VIEW_HISTORY: {
    id: "VIEW_HISTORY",
    defaultMessage: "View History",
  },

  PAYMENTS_HIGHLIGHT: {
    id: "PAYMENT_HIGHLIGHT",
    defaultMessage: "Start using your wallet",
  },
  COMING_SOON: {
    id: "COMING_SOON",
    defaultMessage: "Coming Soon",
  },
  COMING_SOON_MESSAGE: {
    id: "COMING_SOON_MESSAGE",
    defaultMessage: "We are working hard to get this up and running",
  },
  WELCOME2: {
    id: "WELCOME2",
    defaultMessage: "Welcome!",
  },

  EWALLET_WELCOME: {
    id: "EWALLET_WELCOME",
    defaultMessage:
      "With your loan, you get access to a LetsGo Pay to instantly start making payments and transactions.",
  },
  DISCLAIMER_MOZ: {
    id: "DISCLAIMER_MOZ",
    defaultMessage:
      "The amount to be granted as a reinforcement will be the result of the value of the new Credit minus the balance of the current Credit.",
  },

  START_EWALLET: {
    id: "START_EWALLET",
    defaultMessage: "Start using your LetsGo Wallet",
  },
  ONLINE_VERIFICATION: {
    id: "ONLINE_VERIFICATION",
    defaultMessage: "Online verification",
  },
  ONLINE_VERIFICATION_HELP_TEXT: {
    id: "ONLINE_VERIFICATION_HELP_TEXT",
    defaultMessage: "Fill in your details and verify yourself",
  },
  SECURITY_MESSAGE_E_WALLET: {
    id: "SECURITY_MESSAGE_E_WALLET",
    defaultMessage:
      "We follow strict security protocol and will never share your details with a third party",
  },
  SOURCE_OF_INCOME_DONATION: {
    id: "SOURCE_OF_INCOME_DONATION",
    defaultMessage: "Donation",
  },
  SOURCE_OF_INCOME_SALARY: {
    id: "SOURCE_OF_INCOME_SALARY",
    defaultMessage: "Salary",
  },
  SOURCE_OF_INCOME_SAVINGS: {
    id: "SOURCE_OF_INCOME_SAVINGS",
    defaultMessage: "Savings",
  },
  SOURCE_OF_INCOME: {
    id: "SOURCE_OF_INCOME",
    defaultMessage: "Source of income",
  },
  REGION: {
    id: "REGION",
    defaultMessage: "Region",
  },
  STREET_NAME: {
    id: "STREET_NAME",
    defaultMessage: "Street name",
  },
  DISTRICT: {
    id: "DISTRICT",
    defaultMessage: "District",
  },
  HOUSE_NUMBER: {
    id: "HOUSE_NUMBER",
    defaultMessage: "House number",
  },
  SELFIE_ID_UPLOAD_DESC: {
    id: "SELFIE_ID_UPLOAD_DESC",
    defaultMessage: "Upload your passport size photo",
  },
  PASSPORT_SIZE_SELFIE_VERIFICATION: {
    id: "PASSPORT_SIZE_SELFIE_VERIFICATION",
    defaultMessage: "Verify online by taking a passport size photo",
  },
  NATIONAL_ID_WITH_SELFIE_ID_UPLOAD_DESC: {
    id: "NATIONAL_ID_WITH_SELFIE_ID_UPLOAD_DESC",
    defaultMessage: "Upload your selfie with your National ID",
  },
  TENANCY_AGREEMENT: {
    id: "TENANCY_AGREEMENT",
    defaultMessage: " Tenancy Agreement",
  },
  UTILITY_BILL: {
    id: "UTILITY_BILL",
    defaultMessage: "Utility Bill",
  },
  INCOME_TAX_CERTIFICATE: {
    id: "INCOME_TAX_CERTIFICATE",
    defaultMessage: "Income Tax Certificate",
  },
  EXT_BANK_STATEMENT: {
    id: "EXT_BANK_STATEMENT",
    defaultMessage: "Ext Bank Statement",
  },
  INDIVIDUAL_LOAN_ACCOUNT_NO: {
    id: "INDIVIDUAL_LOAN_ACCOUNT_NO",
    defaultMessage: "Non Payroll Loan",
  },
  EMPLOYEE_REFERENCE: {
    id: "EMPLOYEE_REFERENCE",
    defaultMessage: "Employee Refference",
  },
  ADDITIONAL_DOCUMENT: {
    id: "ADDITIONAL_DOCUMENT",
    defaultMessage: "Additional documents",
  },
  PROOF_OF_INCOME: {
    id: "PROOF_OF_INCOME",
    defaultMessage: "Proof of Income",
  },
  ADDITIONAL_DOCS_DESC: {
    id: "ADDITIONAL_DOCS_DESC",
    defaultMessage: "Upload your documents",
  },
  ADDITIONAL_DOCS_ALERT: {
    id: "ADDITIONAL_DOCS_ALERT",
    defaultMessage:
      "Upload additional document to get higher limits on your transaction",
  },
  INCOMPLETE_WORKFLOW_ALERT: {
    id: "INCOMPLETE_WORKFLOW_ALERT",
    defaultMessage: "Kindly contact the call center for further assistance.",
  },
  ADDITIONAL_DOCS: {
    id: "ADDITIONAL_DOCS",
    defaultMessage: "Additional docs",
  },
  PAY_VERIFICATION: {
    id: "PAY_VERIFICATION",
    defaultMessage: "PAY",
  },

  PAY_HIGHLIGHT: {
    id: "PAY_HIGHLIGHT",
    defaultMessage: "Pay your peers anytime, anywhere",
  },
  PAY_TEXT: {
    id: "PAY_TEXT",
    defaultMessage: "There are two ways you can pay :",
  },

  PAY_OPTION_ONE: {
    id: "PAY_OPTION_ONE",
    defaultMessage: "Search by Phone Number",
  },
  PAY_OPTION_TWO: {
    id: "PAY_OPTION_TWO",
    defaultMessage: "Send to Bank Accounts",
  },
  PAY_OPTION_THREE: {
    id: "PAY_OPTION_THREE",
    defaultMessage: "Scan QR code",
  },
  RECEIVE_HIGHLIGHT: {
    id: "RECEIVE_HIGHLIGHT",
    defaultMessage: "Receive money in a jiffy",
  },

  RECEIVE_TEXT: {
    id: "RECEIVE_TEXT",
    defaultMessage: "There are two ways you can receive :",
  },

  RECEIVE_OPTION_ONE: {
    id: "RECEIVE_OPTION_ONE",
    defaultMessage: "Send a request",
  },
  RECEIVE_OPTION_TWO: {
    id: "RECEIVE_OPTION_TWO",
    defaultMessage: "Scan QR code",
  },
  ROLL_OVER_SETTLEMENT_AMOUNT: {
    id: "ROLL_OVER_SETTLEMENT_AMOUNT",
    defaultMessage: "Rollover Settlement Amount",
  },
  EXISTING_LOAN_AMOUNT: {
    id: "EXISTING_LOAN_AMOUNT",
    defaultMessage: "Existing loan Amount",
  },
  CURRENT_OUTSTANDING_AMOUNT: {
    id: "CURRENT_OUTSTANDING_AMOUNT",
    defaultMessage: "Current Outstanding Amount",
  },
  CURRENT_LOAN_BALANCE: {
    id: "CURRENT_LOAN_BALANCE",
    defaultMessage: "Current loan balance",
  },
  DISCHARGE_AMOUNT: {
    id: "DISCHARGE_AMOUNT",
    defaultMessage: "Discharge amount",
  },
  NET_PAYOUT_AMOUNT: {
    id: "NET_PAYOUT_AMOUNT",
    defaultMessage: "Net payout amount",
  },
  DISCLAIMER_TEXT_EC: {
    id: "DISCLAIMER_TEXT_EC",
    defaultMessage:
      "Total amount to borrow is calculated as your disburse balance added to the top-up amount.",
  },
  NET_AMOUNT_DISBURSE: {
    id: "NET_AMOUNT_DISBURSE",
    defaultMessage: "Net Amount to Disburse",
  },
  ADD_FUND_TEXT: {
    id: "ADD_FUND_TEXT",
    defaultMessage:
      "Add funds to your wallet to start making payments and transacting. You can add funds from:",
  },

  ADD_FUND_OPTION_ONE: {
    id: "ADD_FUND_OPTION_ONE",
    defaultMessage: "LetsGo account",
  },

  ADD_FUND_OPTION_TWO: {
    id: "ADD_FUND_OPTION_TWO",
    defaultMessage: "Other wallets",
  },

  PAYMENTS_TEXT: {
    id: "PAYMENTS_TEXT",
    defaultMessage:
      "Add funds to your wallet to start making payments and transacting. You can add funds from:",
  },

  PAYMENTS_OPTION_ONE: {
    id: "PAYMENTS_OPTION_ONE",
    defaultMessage: "LetsGo account",
  },

  PAYMENTS_OPTION_TWO: {
    id: "PAYMENTS_OPTION_TWO",
    defaultMessage: "Other wallets",
  },

  VERIFY_ONLINE_ACTION: {
    id: "VERIFY_ONLINE_ACTION",
    defaultMessage: "Verify online",
  },

  ONLINE_VERIFY_ALERT: {
    id: "ONLINE_VERIFICATION_ALERT",
    defaultMessage:
      "Let's get your verified online so you can start using LetsGo wallet",
  },

  ADD_FUND_OPTION: {
    id: "ADD_FUND_OPTION",
    defaultMessage: "LetsGo account",
  },
  SAVE: {
    id: "SAVE",
    defaultMessage: "Save",
  },
  LEAVING_MESSAGE: {
    id: "LEAVING_MESSAGE",
    defaultMessage:
      "Leaving already? Don’t forget to save your details so you can continue from where you left off",
  },
  INCOMPLETE: {
    id: "INCOMPLETE",
    defaultMessage: "Incomplete",
  },
  START_OVER: {
    id: "START_OVER",
    defaultMessage: "Start over",
  },
  CONTINUE_VERIFICATION: {
    id: "CONTINUE_VERIFICATION",
    defaultMessage: "Continue verification",
  },
  REVERIFY_ONLINE: {
    id: "REVERIFY_ONLINE",
    defaultMessage: "Re-verify online",
  },
  INCOMPLETE_VERIFICATION: {
    id: "INCOMPLETE_VERIFICATION",
    defaultMessage:
      "You did not finish your verification. We have saved your progress, so pick up where you left off.",
  },
  INPROGRESS_VERIFICATION: {
    id: "INPROGRESS_VERIFICATION",
    defaultMessage:
      "We have received your details and are currently reviewing them. We will notify you once it’s verified.",
  },
  COMPLETED_VERIFICATION: {
    id: "COMPLETED_VERIFICATION",
    defaultMessage:
      "Hurray! Your ID verification was successful. You can start using our services now!",
  },
  FAILED_VERFICATION: {
    id: "FAILED_VERFICATION",
    defaultMessage:
      "Your ID could not be verified. The uploaded ID did not match with your details.  Please try again.",
  },
  FAILED_VERFICATION_2: {
    id: "FAILED_VERFICATION_2",
    defaultMessage:
      "Sorry! Your ID verification was unsuccessful. We request you to try verifying again.",
  },
  ATTEMPTS_REMAINING: {
    id: "ATTEMPTS_REMAINING",
    defaultMessage: "Attempts remaining: ",
  },
  REJECTED_VERIFICATION: {
    id: "REJECTED_VERIFICATION",
    defaultMessage:
      "Unable to process your request. Don’t worry, let us help you re-apply.",
  },
  APPLICATION_REJECTED: {
    id: "APPLICATION_REJECTED",
    defaultMessage: "Sorry! Your application is rejected",
  },
  CONTACT_CUSTOMER_CARE: {
    id: "CONTACT_CUSTOMER_CARE",
    defaultMessage: "Contact customer care",
  },
  UPLOAD_ID: {
    id: "UPLOAD_ID",
    defaultMessage:
      "Tap to upload your ID and auto-populate details for an easy process",
  },
  ID_EXPERT_TIP: {
    id: "ID_EXPERT_TIP",
    defaultMessage:
      "By providing this document now you can use eWallet to instantly start making payments and transactions.",
  },
  ID_EXPERT_TIP_INSTANTLOAN: {
    id: "ID_EXPERT_TIP_INSTANTLOAN",
    defaultMessage:
      "These documents are required as part of a loan application. By providing legible images, we can extract information and can save your form filling time.",
  },
  ID_PICTURE: {
    id: "ID_PICTURE",
    defaultMessage:
      "Take a picture of your ID for simple and seamless loan application process",
  },
  UPLOAD_YOUR: {
    id: "UPLOAD_YOUR",
    defaultMessage: "Upload your",
  },
  GO_BACK: {
    id: "GO_BACK",
    defaultMessage: "Go back",
  },
  DETAILS_SENT_FOR_VERIFICATION: {
    id: "DETAILS_SENT_FOR_VERIFICATION",
    defaultMessage: "Details sent for verification",
  },
  CARD_DETAILS_SENT_FOR_VERIFICATION: {
    id: "CARD_DETAILS_SENT_FOR_VERIFICATION",
    defaultMessage:
      "Our team will review your details and verify your identity.",
  },
  EWALLET_APPLICATION_SUCCSS_SUB_HEADING: {
    id: "EWALLET_APPLICATION_SUCCSS_SUB_HEADING",
    defaultMessage:
      "Our team will review your details and verify your identity.",
  },
  EWALLET_APPLICATION_SUCCESS_INFO: {
    id: "EWALLET_APPLICATION_SUCCESS_INFO",
    defaultMessage:
      "You will get a call on your registered mobile no. { phoneNumber } for any additional information required.",
  },
  EWALLET_APPLICATION_FAILURE_HEADING_MSG: {
    id: "EWALLET_APPLICATION_FAILURE_HEADING_MSG",
    defaultMessage: "We could not upload your details",
  },
  EWALLET_APPLICATION_FAILURE_HEADING_SUB_MSG: {
    id: "EWALLET_APPLICATION_FAILURE_HEADING_SUB_MSG",
    defaultMessage:
      "Please try again after 1 hour or contact customer service for further assistance.",
  },
  ACTION_GO_TO_DASHBOARD: {
    id: "ACTION_GO_TO_DASHBOARD",
    defaultMessage: "Go to dashboard",
  },
  ADD_FUND_TRANSACTION_SLIP_PAID: {
    id: "ADD_FUND_TRANSACTION_SLIP_PAID",
    defaultMessage: "Paid to {personName}",
  },
  ADD_FUND_TRANSACTION_SLIP_NOT_PAID: {
    id: "ADD_FUND_TRANSACTION_SLIP_NOT_PAID",
    defaultMessage: "Payment to {personName}",
  },
  ADD_FUND_NEED_HELP_TEXT: {
    id: "ADD_FUND_NEED_HELP_TEXT",
    defaultMessage: "If you need any help?",
  },
  ADD_FUNDS_TRANSACTION_TITLE_TEXT: {
    id: "ADD_FUNDS_TRANSACTION_TITLE_TEXT",
    defaultMessage: "Transaction",
  },
  ADD_FUNDS_TRANSACTION_DETAILS_TEXT: {
    id: "ADD_FUNDS_TRANSACTION_DETAILS_TEXT",
    defaultMessage: "Details",
  },
  ADD_FUNDS_TRANSACTION_SHARE: {
    id: "ADD_FUNDS_TRANSACTION_SHARE",
    defaultMessage: "Share",
  },
  ADD_FUNDS_TRANSACTION_DOWNLOAD: {
    id: "ADD_FUNDS_TRANSACTION_DOWNLOAD",
    defaultMessage: "Download",
  },
  ADD_FUNDS_TRANSACTION_PAID_FROM: {
    id: "ADD_FUNDS_TRANSACTION_PAID_FROM",
    defaultMessage: "Paid from",
  },
  ADD_FUNDS_TRANSACTION_WALLET_BALANCE: {
    id: "ADD_FUNDS_TRANSACTION_WALLET_BALANCE",
    defaultMessage: "Wallet Balance",
  },
  ADD_FUNDS_TRANSACTION_SLIP_ID: {
    id: "ADD_FUNDS_TRANSACTION_SLIP_ID",
    defaultMessage: "Transaction ID",
  },
  ADD_FUNDS_TRANSACTION_DATE_AND_TIME: {
    id: "ADD_FUNDS_TRANSACTION_DATE_AND_TIME",
    defaultMessage: "Date & Time",
  },
  ADD_FUNDS_TRANSACTION_FAILED_TEXT: {
    id: "ADD_FUNDS_TRANSACTION_FAILED_TEXT",
    defaultMessage:
      "We were unable to send funds. Please try again later or choose another method.",
  },
  RECEIVE_FUNDS_TRANSACTION_FAILED_TEXT: {
    id: "RECEIVE_FUNDS_TRANSACTION_FAILED_TEXT",
    defaultMessage:
      "We were unable to request funds. Please try again later or choose another method.",
  },
  INVITE_LINK: {
    id: "INVITE_CONTACTS",
    defaultMessage: "Invite CTA",
  },
  NO_WALLET_INVITE_CONTACTS_TITLE: {
    id: "NO_WALLET_INVITE_CONTACTS_TITLE",
    defaultMessage: "Does not have a LetsGo wallet",
  },
  NO_WALLET_INVITE_CONTACTS_MESSAGE: {
    id: "NO_WALLET_INVITE_CONTACTS_MESSAGE",
    defaultMessage:
      "You can invite them to download the app or share the below link ",
  },
  NO_WALLET_INVITE_CONTACTS_COPY_LINK: {
    id: "NO_WALLET_INVITE_CONTACTS_COPY_LINK",
    defaultMessage: "Copy link",
  },
  INVITE_BUTTON: {
    id: "INVITE_BUTTON",
    defaultMessage: "Invite",
  },
  INVITE_TRANSFER_BUTTON: {
    id: "INVITE_TRANSFER_BUTTON",
    defaultMessage: "Transfer to another wallet",
  },
  TRANSFERRED_FUNDS_FROM: {
    id: "TRANSFERRED_FUNDS_FROM",
    defaultMessage: "Transferred funds from your",
  },
  TRANSFERRED_FROM: {
    id: "TRANSFERRED_FROM",
    defaultMessage: "Transferred from",
  },
  ADDED_TO: {
    id: "ADDED_TO",
    defaultMessage: "Added to",
  },
  ADDED_FROM: {
    id: "ADDED_FROM",
    defaultMessage: "Added from",
  },
  RECEIVED_INTO: {
    id: "RECEIVED_INTO",
    defaultMessage: "Received into",
  },
  LINK_COPIED_SUCCESS: {
    id: "LINK_COPIED_SUCCESS",
    defaultMessage: "Link copied successfully",
  },
  TOKEN_COPIED_SUCCESS: {
    id: "TOKEN_COPIED_SUCCESS",
    defaultMessage: "Token copied successfully",
  },
  INVITE_SENT_SUCCESS: {
    id: "INVITE_SENT_SUCCESS",
    defaultMessage: "Contact invited successfully",
  },
  INVITE_SENT_FAILURE: {
    id: "INVITE_SENT_FAILURE",
    defaultMessage: "Contact invite sent failed",
  },
  ADD_FUNDS_SUBHEADER: {
    id: "ADD_FUNDS_SUBHEADER",
    defaultMessage:
      "Enter the amount you wish to add and select the payment method",
  },
  ENTER_AMOUNT: {
    id: "ENTER_AMOUNT",
    defaultMessage: "Enter amount",
  },
  APPROVE_AMOUNT: {
    id: "APPROVE_AMOUNT",
    defaultMessage: "Approve amount",
  },
  SELFIE_SIDEBAR_OPTION_1: {
    id: "SELFIE_SIDEBAR_OPTION_1",
    defaultMessage:
      "On a blank piece of white paper write today’s date and “LetsGo Loan” below it",
  },
  SELFIE_SIDEBAR_OPTION_2: {
    id: "SELFIE_SIDEBAR_OPTION_2",
    defaultMessage:
      "Hold both this paper and your ID in one hand so we can clearly see both",
  },
  SELFIE_SIDEBAR_OPTION_3: {
    id: "SELFIE_SIDEBAR_OPTION_3",
    defaultMessage:
      "Take a selfie of you, your ID, and your handwritten note. All three elements must be clearly visible",
  },
  SELFIE_SIDEBAR_OPTION_4: {
    id: "SELFIE_SIDEBAR_OPTION_4",
    defaultMessage:
      "Take the photo in a well-lit room and don’t cover any information with your fingers",
  },

  SELFIE_VERIFICATION_STEP: {
    id: "SELFIE_VERIFICATION_STEP",
    defaultMessage:
      "Here is how you can take picture and get verified in one go",
  },
  LETSHEGO_ACCOUNTS: {
    id: "LETSHEGO_ACCOUNTS",
    defaultMessage: "Letshego accounts",
  },
  BALANCE: {
    id: "BALANCE",
    defaultMessage: "Balance",
  },

  WALLET_PAYMENTS: {
    id: "WALLET_PAYMENTS",
    defaultMessage: "Wallet payments",
  },
  NO_WALLETS: {
    id: "NO_WALLETS",
    defaultMessage: "No wallets found",
  },
  WALLETS_LIST: {
    id: "WALLETS_LIST",
    defaultMessage:
      "All your wallets linked to registered mobile number {phone} will be shown here.",
  },
  NO_ACCOUNTS: {
    id: "NO_ACCOUNTS",
    defaultMessage: "No account found",
  },
  NO_ACCOUNTS_MESSAGE_1: {
    id: "NO_ACCOUNTS_MESSAGE_1",
    defaultMessage: "If you have an account,",
  },
  NO_ACCOUNTS_MESSAGE_2: {
    id: "NO_ACCOUNTS_MESSAGE_2",
    defaultMessage: "and our team will reach out to you.",
  },
  TO_PEOPLE: {
    id: "TO_PEOPLE",
    defaultMessage: "To Wallets",
  },
  TO_BANK_ACCOUNTS: {
    id: "TO_BANK_ACCOUNTS",
    defaultMessage: "To Bank accounts",
  },
  MOBILE_SEND_FUNDS: {
    id: "MOBILE_SEND_FUNDS",
    defaultMessage: "Enter Mobile Number to send funds",
  },
  WALLET_NOT_FOUND: {
    id: "WALLET_NOT_FOUND",
    defaultMessage: "Wallet not Found!",
  },
  ERROR_INSUFFICIENT_BALANCE: {
    id: "ERROR_INSUFFICIENT_BALANCE",
    defaultMessage: "Insufficient Balance",
  },
  CONFIRM_PAYMENT: {
    id: "CONFIRM_PAYMENT",
    defaultMessage: "Confirm payment",
  },
  REQUESTED_FROM: {
    id: "REQUESTED_FROM",
    defaultMessage: "Requested from",
  },
  DESCRIPTION: {
    id: "DESCRIPTION",
    defaultMessage: "Description",
  },
  ADD_FUNDS_TRANSACTION_RECEIVED_INTO: {
    id: "ADD_FUNDS_TRANSACTION_RECEIVED_INTO",
    defaultMessage: "Received into",
  },
  ADD_FUNDS_TRANSACTION_REQUESTED_INTO: {
    id: "ADD_FUNDS_TRANSACTION_REQUESTED_INTO",
    defaultMessage: "Requested into",
  },

  EWALLET_INVITE_SENT_SUCCESS: {
    id: "EWALLET_INVITE_SENT_SUCCESS",
    defaultMessage: "User Invited Successfully.",
  },
  EWALLET_INVITE_SENT_FAIL: {
    id: "EWALLET_INVITE_SENT_FAIL",
    defaultMessage: "Unknown error occurred while processing.",
  },
  SEND_FUNDS_RECIPIENT_DETAILS_HEADING: {
    id: "SEND_FUNDS_RECIPIENT_DETAILS_HEADING",
    defaultMessage: "Recipient details",
  },
  RECIPIENT_DETAILS_FORM_HEADING: {
    id: "RECIPIENT_DETAILS_FORM_HEADING",
    defaultMessage: "Enter Mobile Number to send funds",
  },
  RECIPIENT_DETAILS_WALLET_NAME: {
    id: "RECIPIENT_DETAILS_WALLET_NAME",
    defaultMessage: "Wallet name",
  },
  RECIPIENT_DETAILS_MIDDLE_NAME: {
    id: "RECIPIENT_DETAILS_MIDDLE_NAME",
    defaultMessage: "Middle name (Optional)",
  },
  RECENT: {
    id: "RECENT",
    defaultMessage: "Recent",
  },
  RECENT_PEOPLE: {
    id: "RECENT_PEOPLE",
    defaultMessage: "Recent people",
  },
  TRANSACTION_HISTORY_TEXT: {
    id: "TRANSACTION_HISTORY_TEXT",
    defaultMessage: "View the log of all your transactions made so far",
  },
  TRANSACTION_HISTORY_LINK: {
    id: "TRANSACTION_HISTORY_LINK",
    defaultMessage: "View transaction history",
  },
  TRANSACTION_HISTORY_TAB_ALL: {
    id: "TRANSACTION_HISTORY_TAB_ALL",
    defaultMessage: "All",
  },
  TRANSACTION_HISTORY_TAB_PAID: {
    id: "TRANSACTION_HISTORY_TAB_PAID",
    defaultMessage: "Paid",
  },
  TRANSACTION_HISTORY_TAB_RECEIVED: {
    id: "TRANSACTION_HISTORY_TAB_RECEIVED",
    defaultMessage: "Received",
  },
  TRANSACTION_HISTORY_REQUEST_STATUS_CANCELED: {
    id: "TRANSACTION_HISTORY_REQUEST_STATUS_CANCELED",
    defaultMessage: "Canceled",
  },
  TRANSACTION_HISTORY_REQUEST_STATUS_DECLINED: {
    id: "TRANSACTION_HISTORY_REQUEST_STATUS_DECLINED",
    defaultMessage: "Declined",
  },
  TRANSACTION_HISTORY_REQUEST_STATUS_EXPIRED: {
    id: "TRANSACTION_HISTORY_REQUEST_STATUS_EXPIRED",
    defaultMessage: "Expired",
  },
  TRANSACTION_HISTORY_REQUEST_STATUS_PENDING: {
    id: "TRANSACTION_HISTORY_REQUEST_STATUS_PENDING",
    defaultMessage: "Request expires on",
  },
  TRANSACTION_HISTORY_EMPTY_TITLE: {
    id: "TRANSACTION_HISTORY_EMPTY_TITLE",
    defaultMessage: "Nothing to show",
  },
  TRANSACTION_HISTORY_EMPTY_DESC: {
    id: "TRANSACTION_HISTORY_EMPTY_DESC",
    defaultMessage: "There is no history for these transactions yet",
  },
  YOU_ARE_SENDING: {
    id: "YOU_ARE_SENDING",
    defaultMessage: "You’re sending funds to {userFullName}’s {walletName}",
  },
  YOU_CAN_ADD_UPTO: {
    id: "YOU_CAN_ADD_UPTO",
    defaultMessage: "You can add up to {currencySymbol} {amount}",
  },
  DESCRIPTION_OPTIONAL: {
    id: "DESCRIPTION_OPTIONAL",
    defaultMessage: "Description (optional)",
  },
  PAYING_TO: {
    id: "PAYING_TO",
    defaultMessage: "Paying to",
  },
  UPCOMING_PAYMENT: {
    id: "UPCOMING_PAYMENT",
    defaultMessage: "Upcoming payment",
  },
  TAP_ON_EDIT_TO_CHANGE_ACCOUNT: {
    id: "TAP_ON_EDIT_TO_CHANGE_ACCOUNT",
    defaultMessage: "Tap on edit to change your account and amount preferences",
  },
  VERIFY_YOURSELF: {
    id: "VERIFY_YOURSELF",
    defaultMessage: "Verify yourself",
  },
  SAVINGS_CONFIRM: {
    id: "SAVINGS_CONFIRM",
    defaultMessage: "Confirm",
  },
  SAVINGS_PAYMENT: {
    id: "SAVINGS_PAYMENT",
    defaultMessage: "Payment",
  },
  LETSGO_WALLET_TITLE: {
    id: "LETSGO_WALLET_TITLE",
    defaultMessage: "LetsGo Wallet",
  },
  LETSGO_WALLET: {
    id: "LETSGO_WALLET",
    defaultMessage: "Letsgo Wallet",
  },
  REVIEW_AND_CONFIRM: {
    id: "REVIEW_AND_CONFIRM",
    defaultMessage: "Review and confirm",
  },
  ERROR_INSUFFICIENT_FUNDS_INPUT: {
    id: "ERROR_INSUFFICIENT_FUNDS_INPUT",
    defaultMessage: "Insufficient funds! Please enter the correct amount",
  },
  ERROR_INSUFFICIENT_FUNDS_IN_WALLET: {
    id: "ERROR_INSUFFICIENT_FUNDS_IN_WALLET",
    defaultMessage: "Insufficient funds! Please {link}",
  },
  TRANSACTION_HISTORY_REPEAT_PAYMENT_CTA: {
    id: "TRANSACTION_HISTORY_REPEAT_PAYMENT_CTA",
    defaultMessage: "Repeat this transaction",
  },
  TRANSACTION_HISTORY_PAYMENT_RECEIVED_FROM: {
    id: "TRANSACTION_HISTORY_PAYMENT_RECEIVED_FROM",
    defaultMessage: "Received from {personName}",
  },
  TRANSACTION_HISTORY_PAYMENT_REQUESTED_FROM: {
    id: "TRANSACTION_HISTORY_PAYMENT_REQUESTED_FROM",
    defaultMessage: "Requested from {personName}",
  },
  TRANSACTION_HISTORY_PAYMENT_REQUESTED_TO: {
    id: "TRANSACTION_HISTORY_PAYMENT_REQUESTED_TO",
    defaultMessage: "Requested from {personName}",
  },
  TRANSACTION_HISTORY_PAYMENT_SENT_REQUESTED_TO: {
    id: "TRANSACTION_HISTORY_PAYMENT_SENT_REQUESTED_TO",
    defaultMessage: "Requested sent to {personName}",
  },
  TRANSACTION_HISTORY_PAYMENT_REQUEST_EXPIRE: {
    id: "TRANSACTION_HISTORY_PAYMENT_REQUEST_EXPIRE",
    defaultMessage: "Request expires on {expiryDate}",
  },
  TRANSACTION_HISTORY_PAYMENT_APPROVE_PAYMENT: {
    id: "TRANSACTION_HISTORY_PAYMENT_APPROVE_PAYMENT",
    defaultMessage: "Approve payment",
  },
  TRANSACTION_HISTORY_PAYMENT_DECLINE_PAYMENT: {
    id: "TRANSACTION_HISTORY_PAYMENT_DECLINE_PAYMENT",
    defaultMessage: "Decline payment",
  },
  ADD_FUNDS_TRANSACTION_RECEIVED_FROM: {
    id: "ADD_FUNDS_TRANSACTION_RECEIVED_FROM",
    defaultMessage: "Received from",
  },
  ADD_FUNDS_TRANSACTION_REQUESTED_FROM: {
    id: "ADD_FUNDS_TRANSACTION_REQUESTED_FROM",
    defaultMessage: "Requested from",
  },
  PAYMENT_FAILED: {
    id: "PAYMENT_FAILED",
    defaultMessage: "Payment failed!",
  },
  RETRY_PAYMENT_TEXT: {
    id: "RETRY_PAYMENT_TEXT",
    defaultMessage: "Retry Payment",
  },
  SUCCESSFUL: {
    id: "SUCCESSFUL",
    defaultMessage: "Successful",
  },
  REQUEST_ID: {
    id: "REQUEST_ID",
    defaultMessage: "Request ID",
  },
  ACCOUNT_NUMBER_TEXT: {
    id: "ACCOUNT_NUMBER_TEXT",
    defaultMessage: "A/c No.",
  },
  LETSHEGO_ACCOUNT_NUMBER: {
    id: "LETSHEGO_ACCOUNT_NUMBER",
    defaultMessage: "Letshego A/c no. xxxx {accountNumber}",
  },
  FLEXISAVE_ACCOUNT_NUMBER: {
    id: "FLEXISAVE_ACCOUNT_NUMBER",
    defaultMessage: "FlexiSave A/c no. xxxx {accountNumber}",
  },
  KIN_RELATION_SPOUSE: {
    id: "KIN_RELATION_SPOUSE",
    defaultMessage: "Spouse",
  },
  KIN_RELATION_PARTNER: {
    id: "KIN_RELATION_PARTNER",
    defaultMessage: "Partner",
  },
  KIN_RELATION_FATHER: {
    id: "KIN_RELATION_FATHER",
    defaultMessage: "Father",
  },
  KIN_RELATION_MOTHER: {
    id: "KIN_RELATION_MOTHER",
    defaultMessage: "Mother",
  },
  KIN_RELATION_BROTHER: {
    id: "KIN_RELATION_BROTHER",
    defaultMessage: "Brother",
  },
  KIN_RELATION_SISTER: {
    id: "KIN_RELATION_SISTER",
    defaultMessage: "Sister",
  },
  KIN_RELATION_SON: {
    id: "KIN_RELATION_SON",
    defaultMessage: "Son",
  },
  KIN_RELATION_DAUGHTER: {
    id: "KIN_RELATION_DAUGHTER",
    defaultMessage: "Daughter",
  },
  KIN_RELATION_AUNT: {
    id: "KIN_RELATION_AUNT",
    defaultMessage: "Aunt",
  },
  DEFAULT_ERROR_MESSAGE: {
    id: "DEFAULT_ERROR_MESSAGE",
    defaultMessage: "Please enter a valid ",
  },
  PREFERED_LANGUAGE: {
    id: "PREFERED_LANGUAGE",
    defaultMessage: "Preferred Language",
  },
  KIN_RELATION_UNCLE: {
    id: "KIN_RELATION_UNCLE",
    defaultMessage: "Uncle",
  },
  KIN_RELATION_GRANDFATHER: {
    id: "KIN_RELATION_GRANDFATHER",
    defaultMessage: "Grandfather",
  },
  KIN_RELATION_GRANDMOTHER: {
    id: "KIN_RELATION_GRANDMOTHER",
    defaultMessage: "Grandmother",
  },
  KIN_RELATION_COUSIN: {
    id: "KIN_RELATION_COUSIN",
    defaultMessage: "Cousin",
  },
  RECIEVE_MONEY_SEND_PAYMENT: {
    id: "RECIEVE_MONEY_SEND_PAYMENT",
    defaultMessage: "Receive money and send payment requests to people",
  },
  PAYER_DETAILS: {
    id: "PAYER_DETAILS",
    defaultMessage: "Payer details",
  },
  FILL_DETAILS_TO_REQUEST_FUNDS: {
    id: "FILL_DETAILS_TO_REQUEST_FUNDS",
    defaultMessage: "Fill in the required details to request funds",
  },
  REVIEW_DETAILS_BEFORE_REQUEST: {
    id: "REVIEW_DETAILS_BEFORE_REQUEST",
    defaultMessage: "Check your details before proceeding",
  },
  PENDING_REQUESTS: {
    id: "PENDING_REQUESTS",
    defaultMessage: "Pending requests ({count})",
  },
  COMMON_ACCOUNT: {
    id: "COMMON_ACCOUNT",
    defaultMessage: "Account",
  },
  WANT_TO_REQUEST: {
    id: "WANT_TO_REQUEST",
    defaultMessage: "Want to request?",
  },
  AMOUNT_TO_RECEIVE: {
    id: "AMOUNT_TO_RECEIVE",
    defaultMessage: "Total amount to receive",
  },
  REQUESTING_MONEY_FROM: {
    id: "REQUESTING_MONEY_FROM",
    defaultMessage: "Requesting money into",
  },
  COMMON_REQUEST_AMOUNT: {
    id: "COMMON_REQUEST_AMOUNT",
    defaultMessage: "Request {amountWithCurrency}",
  },
  RECENT_BENEFICIARIES: {
    id: "RECENT_BENEFICIARIES",
    defaultMessage: "Recent beneficiaries",
  },
  SAVED_BENEFICIARIES: {
    id: "SAVED_BENEFICIARIES",
    defaultMessage: "Saved beneficiaries",
  },
  ADD_NEW: {
    id: "ADD_NEW",
    defaultMessage: "Add new",
  },
  MY_ACCOUNTS: {
    id: "MY_ACCOUNTS",
    defaultMessage: "My accounts",
  },
  OTHER_PEOPLE: {
    id: "OTHER_PEOPLE",
    defaultMessage: "Other people",
  },
  REQUEST_TO: {
    id: "REQUEST_TO",
    defaultMessage: "Request to",
  },
  FILL_DETAILS_TO_MAKE_PAYMENT: {
    id: "FILL_DETAILS_TO_MAKE_PAYMENT",
    defaultMessage: "Fill in the required details to make payment",
  },
  ENTER_ACCOUNT_DETAILS: {
    id: "ENTER_ACCOUNT_DETAILS",
    defaultMessage: "Enter account details",
  },
  BILLER_NAME: {
    id: "BILLER_NAME",
    defaultMessage: "Biller name",
  },
  METER_NUMBER: {
    id: "METER_NUMBER",
    defaultMessage: "Meter number",
  },
  METER_NUMBER_HELPER_TEXT: {
    id: "METER_NUMBER_HELPER_TEXT",
    defaultMessage: "Eg: 765TR98660",
  },
  MOBILE_NUMBER_HELPER_TEXT: {
    id: "MOBILE_NUMBER_HELPER_TEXT",
    defaultMessage: "E.g Cell :  MTC  081XXXXXXX TN Mobile 085XXXXXXX",
  },
  SAVE_VAS_ACCOUNT: {
    id: "SAVE_VAS_ACCOUNT",
    defaultMessage: "Save account for future bill payments?",
  },
  PAYMENT_TYPE: {
    id: "PAYMENT_TYPE",
    defaultMessage: "Payment type:",
  },
  COMMON_MYSELF: {
    id: "COMMON_MYSELF",
    defaultMessage: "Myself",
  },
  ACCOUNT_HOLDER_NAME: {
    id: "ACCOUNT_HOLDER_NAME",
    defaultMessage: "Account holder's name",
  },
  NICK_NAME_OPTIONAL: {
    id: "NICK_NAME_OPTIONAL",
    defaultMessage: "Nickname  (optional)",
  },
  PHONE_NUMBER_OPTIONAL: {
    id: "PHONE_NUMBER_OPTIONAL",
    defaultMessage: "Phone number  (optional)",
  },
  ENTER_BENEFICIARY_DETAILS: {
    id: "ENTER_BENEFICIARY_DETAILS",
    defaultMessage: "Enter beneficiary details",
  },
  ACCOUNT_HOLDER_LABEL: {
    id: "ACCOUNT_HOLDER_LABEL",
    defaultMessage: "Account holder's name",
  },
  RE_ENTER_ACCOUNT_NO_LABEL: {
    id: "RE_ENTER_ACCOUNT_NO_LABEL",
    defaultMessage: "Re-enter account number",
  },
  NICK_NAME_LABEL: {
    id: "NICK_NAME_LABEL",
    defaultMessage: "Nickname (optional)",
  },
  MOBILE_NAME_LABEL: {
    id: "MOBILE_NAME_LABEL",
    defaultMessage: "Mobile number (optional)",
  },
  PERSNAL_ACCOUNT_SWITCH_LABEL: {
    id: "PERSNAL_ACCOUNT_SWITCH_LABEL",
    defaultMessage: "This is my personal account",
  },
  ADD_BENEFICIARY_NAME_HEALPER: {
    id: "ADD_BENEFICIARY_NAME_HEALPER",
    defaultMessage: "Search from saved beneficiaries or enter new name",
  },
  ACCOUNT_VERIFICATION_ERROR: {
    id: "ACCOUNT_VERIFICATION_ERROR",
    defaultMessage: "The account numbers do not match",
  },
  ACCOUNT_VERIFICATION_NUMERIC_ERROR: {
    id: "ACCOUNT_VERIFICATION_NUMERIC_ERROR",
    defaultMessage: "Please enter the numeric values",
  },
  PROCEED_TO_PAY: {
    id: "PROCEED_TO_PAY",
    defaultMessage: "Proceed to pay",
  },
  PAY_NEW_BENEFICIARY: {
    id: "PAY_NEW_BENEFICIARY",
    defaultMessage: "Pay new beneficiary",
  },
  PAY_NEW_BENEFICIARY_SUBTITLE: {
    id: "PAY_NEW_BENEFICIARY_SUBTITLE",
    defaultMessage: "Add details of a new beneficiary to make payment",
  },
  SEARCH_BENEFICIARY: {
    id: "SEARCH_BENEFICIARY",
    defaultMessage: "Search by name or account number",
  },
  SEARCH_RESULTS: {
    id: "SEARCH_RESULTS",
    defaultMessage: "Search results",
  },
  NO_BENEFICIARY_FOUND: {
    id: "NO_BENEFICIARY_FOUND",
    defaultMessage: "No beneficiary found!",
  },
  NO_BENEFICIARY_FOUND_SUBTEXT: {
    id: "NO_BENEFICIARY_FOUND_SUBTEXT",
    defaultMessage: "Please enter a valid name or account number or you may ",
  },
  RECEIVE_SUB_TITILE: {
    id: "RECEIVE_SUB_TITILE",
    defaultMessage: "Receive money and send payment requests to people",
  },
  EWALLET_RECEIVE_REQUEST_TO: {
    id: "EWALLET_RECEIVE_REQUEST_TO",
    defaultMessage: "Request to",
  },
  EWALLET_RECEIVE_REQUEST_PENDING: {
    id: "EWALLET_RECEIVE_REQUEST_PENDING",
    defaultMessage: "Pending requests",
  },
  EWALLET_RECEIVE_REQUEST_PENDING_STATUS_EXPIRED: {
    id: "EWALLET_RECEIVE_REQUEST_PENDING_STATUS_EXPIRED",
    defaultMessage: "Request Expired",
  },
  ATTACHMENT: {
    id: "ATTACHMENT",
    defaultMessage: "Attachment",
  },
  SEND_REQUEST_REMINDER: {
    id: "SEND_REQUEST_REMINDER",
    defaultMessage: "Send reminder",
  },
  CANCEL_REQUEST_REMINDER: {
    id: "CANCEL_REQUEST_REMINDER",
    defaultMessage: "Cancel request",
  },
  RESEND_REQUEST_REMINDER: {
    id: "RESEND_REQUEST_REMINDER",
    defaultMessage: "Resend request",
  },
  APPROVE_REQUEST_REMINDER: {
    id: "APPROVE_REQUEST_REMINDER",
    defaultMessage: "Approve",
  },
  DECLINE_REQUEST_REMINDER: {
    id: "DECLINE_REQUEST_REMINDER",
    defaultMessage: "Decline",
  },
  EWALLET_RECEIVE_REQUEST_ID: {
    id: "EWALLET_RECEIVE_REQUEST_ID",
    defaultMessage: "Request ID",
  },
  EWALLET_RECEIVE_REQUEST_NEED_HELP_TEXT: {
    id: "EWALLET_RECEIVE_REQUEST_NEED_HELP_TEXT",
    defaultMessage: "For any queries please call",
  },
  CONTACT_CUSTOMER_SERVICE: {
    id: "CONTACT_CUSTOMER_SERVICE",
    defaultMessage: "customer service",
  },
  REQUESTING_TO: {
    id: "REQUESTING_TO",
    defaultMessage: "Requesting to",
  },
  NOT_AVAILABLE_APPLICABLE: {
    id: "NOT_AVAILABLE_APPLICABLE",
    defaultMessage: "N/A",
  },
  ADD_BENEFICIARY: {
    id: "ADD_BENEFICIARY",
    defaultMessage: "Add beneficiary",
  },
  BENEFICIARY_ADDED: {
    id: "BENEFICIARY_ADDED",
    defaultMessage: "Beneficiary added",
  },
  SUCCESSFULLY: {
    id: "SUCCESSFULLY",
    defaultMessage: "Successfully!",
  },
  PAY_BENEFICIARY: {
    id: "PAY_BENEFICIARY",
    defaultMessage: "Pay beneficiary",
  },
  SOMETHING_WENT: {
    id: "SOMETHING_WENT",
    defaultMessage: "Something went",
  },
  WRONG_ERROR: {
    id: "WRONG_ERROR",
    defaultMessage: "Wrong!",
  },
  SAVE_BENEFICIARY_FAILURE_MESSAGE: {
    id: "SAVE_BENEFICIARY_FAILURE_MESSAGE",
    defaultMessage:
      "We were unable to add beneficiary. Please try again later. ",
  },
  INCORRECT_DETAILS: {
    id: "INCORRECT_DETAILS",
    defaultMessage: "Incorrect details",
  },
  SAVE_BENEFICIARY_ERROR_MESSAGE: {
    id: "SAVE_BENEFICIARY_ERROR_MESSAGE",
    defaultMessage:
      "The beneficiary details entered seem to be incorrect. Please try again.",
  },
  DECLINE_REQUEST: {
    id: "DECLINE_REQUEST",
    defaultMessage: "Decline request",
  },
  PAYING: {
    id: "PAYING",
    defaultMessage: "Paying",
  },
  DECLINE_REQUEST_QUESTION_TEXT: {
    id: "DECLINE_REQUEST_QUESTION_TEXT",
    defaultMessage:
      "Are you sure you want to decline the payment request made by {name}?",
  },
  DONT_DECLINE: {
    id: "DONT_DECLINE",
    defaultMessage: "Don't decline",
  },
  INSUFFICIENT_FUNDS: {
    id: "INSUFFICIENT_FUNDS",
    defaultMessage: "Insufficient funds",
  },
  INSUFFICIENT_FUNDS_MESSAGES: {
    id: "INSUFFICIENT_FUNDS_MESSAGES",
    defaultMessage: "The amount requested is higher than your wallet balance",
  },
  REMINDER_SENT_SUCCESSFULLY: {
    id: "REMINDER_SENT_SUCCESSFULLY",
    defaultMessage: "Reminder sent \n successfully !",
  },
  REMINDER_SENT_SUCCESSFULLY_DESC: {
    id: "REMINDER_SENT_SUCCESSFULLY_DESC",
    defaultMessage:
      "A reminder has been sent to {customerNames} for payment of {amountRequested}. We will notify you once they have made the payment.",
  },
  OK: {
    id: "OK",
    defaultMessage: "Ok",
  },
  REMINDER_CANCEL_REQUEST_DESC: {
    id: "REMINDER_CANCEL_REQUEST_DESC",
    defaultMessage:
      "Are you sure you want to cancel the payment request made to {customerName}?",
  },
  REMINDER_CANCEL_REQUEST_ALLOW: {
    id: "REMINDER_CANCEL_REQUEST_ALLOW",
    defaultMessage: "Yes cancel",
  },
  REMINDER_CANCEL_REQUEST_SUCCESS: {
    id: "REMINDER_CANCEL_REQUEST_SUCCESS",
    defaultMessage: "Request cancelled successfully",
  },
  SELF_REFERENCE: {
    id: "SELF_REFERENCE",
    defaultMessage: "Self Reference",
  },
  BENEFICIARY_REFERENCE: {
    id: "BENEFICIARY_REFERENCE",
    defaultMessage: "Beneficiary Reference",
  },
  PAY_AMOUNT: {
    id: "PAY_AMOUNT",
    defaultMessage: "Pay {currency} {amount}",
  },
  SAVE_BENEFICIARY: {
    id: "SAVE_BENEFICIARY",
    defaultMessage: "Save this beneficiary for future transactions",
  },
  MOBILE_RECEIVE_FUNDS: {
    id: "MOBILE_RECEIVE_FUNDS",
    defaultMessage: "Enter mobile number",
  },
  BILL_PAYMENT: {
    id: "BILL_PAYMENT",
    defaultMessage: "Make Bill Payment",
  },
  BILL_PAYMENT_DESC: {
    id: "BILL_PAYMENT_DESC",
    defaultMessage: "Enter a few details to pay your bill instantly",
  },
  FREQUENTLY_PAID: {
    id: "FREQUENTLY_PAID",
    defaultMessage: "Frequently paid",
  },
  SAVED_ACCOUNTS: {
    id: "SAVED_ACCOUNTS",
    defaultMessage: "Saved accounts",
  },
  ACCOUNTS_NOT_FOUND: {
    id: "ACCOUNTS_NOT_FOUND",
    defaultMessage: "No accounts found!",
  },
  SAVE_ACCOUNT_BILL_PAY: {
    id: "SAVE_ACCOUNT_BILL_PAY",
    defaultMessage: "Save accounts by making new bill payment",
  },
  SEARCH_RESULT_NOT_FOUND: {
    id: "SEARCH_RESULT_NOT_FOUND",
    defaultMessage: "Please enter a valid name or meter number or you may ",
  },
  PAY_NEW_BILL_LINK: {
    id: "PAY_NEW_BILL_LINK",
    defaultMessage: "Pay new bill",
  },
  VAS_CATEGORY_HEADER_DEC: {
    id: "VAS_CATEGORY_HEADER_DEC",
    defaultMessage: "Pay your {component} bills in a few clicks",
  },
  UTILITY_PAYEMENT: {
    id: "UTILITY_PAYEMENT",
    defaultMessage: "Utility Payments",
  },
  SEARCH_UTILITY: {
    id: "SEARCH_UTILITY",
    defaultMessage: "Search by name or meter number",
  },
  BENEFICIARY_DETAILS_HEADING: {
    id: "BENEFICIARY_DETAILS_HEADING",
    defaultMessage: "Beneficiary details",
  },
  GO_BACK_TO_DASHBOARD: {
    id: "GO_BACK_TO_DASHBOARD",
    defaultMessage: "Back to Dashboard",
  },
  MAKE_PAYMENT: {
    id: "MAKE_PAYMENT",
    defaultMessage: "Make payment",
  },
  BENEFICIARY: {
    id: "BENEFICIARY",
    defaultMessage: "Beneficiary",
  },
  LOGIN_WITH_TITLE: {
    id: "LOGIN_WITH_TITLE",
    defaultMessage: "Log-in with",
  },
  LOGIN_EMAIL_OPTION: {
    id: "LOGIN_EMAIL_OPTION",
    defaultMessage: "Email ID",
  },
  PROFILE_DETAILS: {
    id: "PROFILE_DETAILS",
    defaultMessage: "Profile details",
  },
  PROFILE_DETAILS_TEXT: {
    id: "PROFILE_DETAILS_TEXT",
    defaultMessage: "View mobile number, email address or verify ID details",
  },
  MY_PRODUCTS: {
    id: "MY_PRODUCTS",
    defaultMessage: "My products",
  },
  MY_PRODUCTS_TEXT: {
    id: "MY_PRODUCTS_TEXT",
    defaultMessage: "Manage and view history of products bought",
  },
  SAVED_ACCOUNTS_CARDS: {
    id: "SAVED_ACCOUNTS_CARDS",
    defaultMessage: "Saved accounts and cards",
  },
  SAVED_ACCOUNTS_CARDS_TEXT: {
    id: "SAVED_ACCOUNTS_CARDS_TEXT",
    defaultMessage: "Add or modify saved bank, cards and wallets",
  },
  SAVED_CONTACTS_BENEFICIARY: {
    id: "SAVED_CONTACTS_BENEFICIARY",
    defaultMessage: "Saved contacts and beneficiary",
  },
  SAVED_CONTACTS_BENEFICIARY_TEXT: {
    id: "SAVED_CONTACTS_BENEFICIARY_TEXT",
    defaultMessage: "Add or manage saved contacts and beneficiaries",
  },
  REFERRALS: {
    id: "REFERRALS",
    defaultMessage: "Referrals",
  },
  COMMON_BORROW: {
    id: "COMMON_BORROW",
    defaultMessage: "Borrow",
  },
  SWITCH_INSTITUTION: {
    id: "SWITCH_INSTITUTION",
    defaultMessage: "Switch Institution",
  },
  SWITCH_INSTITUTION_TEXT: {
    id: "SWITCH_INSTITUTION_TEXT",
    defaultMessage: "Change Institution",
  },
  LOGIN_SELECT_INSTITUTION_TEXT: {
    id: "LOGIN_SELECT_INSTITUTION",
    defaultMessage: "Please select institution to continue",
  },
  SWITCH_INSTITUTION_PROMPT_TEXT: {
    id: "SWITCH_INSTITUTION_PROMPT_TEXT",
    defaultMessage:
      "You are about to switch to a new institution. Are you sure?",
  },
  SWITCH_INSTITUTION_PROMPT_SUB_TEXT: {
    id: "SWITCH_INSTITUTION_PROMPT_SUB_TEXT",
    defaultMessage:
      "Kindly register again and select your preferred institution to proceed.",
  },
  LETSGO_BORROW: {
    id: "LETSGO_BORROW",
    defaultMessage: "LetsGo <custom>Borrow</custom>",
  },
  LETSGO_BORROW_HOME_TITLE: {
    id: "LETSGO_BORROW_HOME_TITLE",
    defaultMessage: "Explore all our Loan products",
  },
  LETSGO_SAVE: {
    id: "LETSGO_SAVE",
    defaultMessage: "LetsGo <custom>Save</custom>",
  },
  LETSGO_INSURE: {
    id: "LETSGO_INSURE",
    defaultMessage: "LetsGo <custom>Insure</custom>",
  },
  LETSGO_INSURE_FAMILY_PROTECTION: {
    id: "LETSGO_INSURE_FAMILY_PROTECTION",
    defaultMessage: "Family Protection",
  },
  LETSGO_INSURE_HOME_INSURANCE: {
    id: "LETSGO_INSURE_HOME_INSURANCE",
    defaultMessage: "Home Insurance",
  },
  LETSGO_INSURE_LIFE_INSURANCE: {
    id: "LETSGO_INSURE_LIFE_INSURANCE",
    defaultMessage: "Life Insurance",
  },
  LETSGO_INSURE_CAR_INSURANCE: {
    id: "LETSGO_INSURE_CAR_INSURANCE",
    defaultMessage: "Car Insurance",
  },
  LETSGO_INSURE_EDUCATION_INSURANCE: {
    id: "LETSGO_INSURE_EDUCATION_INSURANCE",
    defaultMessage: "Education Insurance",
  },
  LETSGO_ACADEMY: {
    id: "LETSGO_ACADEMY",
    defaultMessage: "Digital Academy",
  },
  LETSGO_LIFE_STYLE: {
    id: "LETSGO_LIFE_STYLE",
    defaultMessage: "LetsGo <custom>Lifestyle</custom>",
  },
  LETSGO_MARKET_PLACE: {
    id: "LETSGO_MARKET_PLACE",
    defaultMessage: "Market Place",
  },
  LETSGO_PAY: {
    id: "LETSGO_PAY",
    defaultMessage: "LetsGo <custom>Pay</custom>",
  },
  FLEXIBLE_SAVINGS: {
    id: "FLEXIBLE_SAVINGS",
    defaultMessage: "FlexiSave",
  },
  SAVINGS_GOALS: {
    id: "SAVINGS_GOALS",
    defaultMessage: " Group Savings",
  },
  LETSGO_INSTANT_LOAN: {
    id: "LETSGO_INSTANT_LOAN",
    defaultMessage: "{instant} <custom>{loan}</custom>",
  },
  LETSGO_PAYROLL_LOAN: {
    id: "LETSGO_PAYROLL_LOAN",
    defaultMessage: "Payroll Loan",
  },
  LETSGO_FLASH_CASH: {
    id: "LETSGO_FLASH_CASH",
    defaultMessage: "Flash Cash",
  },
  LETSGO_NON_PAYROLL_LOAN: {
    id: "LETSGO_PAYROLL_LOAN",
    defaultMessage: "Non-Payroll Loan",
  },
  LETSGO_NON_PAYROLL_LOAN_NIGERIA: {
    id: "LETSGO_NON_PAYROLL_LOAN_NIGERIA",
    defaultMessage: "Easy Loan",
  },
  LETSGO_NON_PAYROLL_LOAN_BOTSWANA: {
    id: "LETSGO_NON_PAYROLL_LOAN_BOTSWANA",
    defaultMessage: "LetsGo Motshegetsi",
  },
  LETSGO_HOUSING_LOAN: {
    id: "LETSGO_HOUSING_LOAN",
    defaultMessage: "Affordable Housing",
  },
  LETSGO_BUSINESS_LOAN: {
    id: "LETSGO_BUSINESS_LOAN",
    defaultMessage: "Business Loan",
  },
  REFERRALS_TEXT: {
    id: "REFERRALS_TEXT",
    defaultMessage: "Send referrals",
  },
  APP_SETTINGS: {
    id: "APP_SETTINGS",
    defaultMessage: "App settings",
  },
  APP_SETTINGS_TEXT: {
    id: "APP_SETTINGS_TEXT",
    defaultMessage: "Notifications and change password",
  },
  LOG_OUT_TEXT: {
    id: "LOG_OUT_TEXT",
    defaultMessage: "Logout",
  },
  KYC_VERIFIED: {
    id: "KYC_VERIFIED",
    defaultMessage: "Fully verified",
  },
  KYC_PARTIALLY_VERIFIED: {
    id: "KYC_PARTIALLY_VERIFIED",
    defaultMessage: "ID Partially verified",
  },
  KYC_NOT_VERIFIED: {
    id: "KYC_NOT_VERIFIED",
    defaultMessage: "ID not verified",
  },
  TRANSACTION_FEE_NOTE: {
    id: "TRANSACTION_FEE_NOTE",
    defaultMessage:
      "A small transaction fee of {currencySymbol}{amount} will be applicable",
  },
  NOTE: {
    id: "Note",
    defaultMessage: "Note",
  },
  SEND_FUND_ALERT: {
    id: "SEND_FUND_ALERT",
    defaultMessage:
      "Your verification is in progress and you can instantly pay your friends and family once it’s done",
  },
  VERIFICATION_PENDING_CUSTOMER_CALL: {
    id: "VERIFICATION_PENDING_CUSTOMER_CALL",
    defaultMessage:
      "For any queries regarding your loan application contact {link}",
  },
  SAVINGS_TRACK_APPLICATION_QUERIES_TEXT: {
    id: "SAVINGS_TRACK_APPLICATION_QUERIES_TEXT",
    defaultMessage:
      "For any queries regarding your savings application contact {link}",
  },
  OTP_VERIFY_TITLE: {
    id: "OTP_VERIFY_TITLE",
    defaultMessage: "Enter the OTP sent to {phone}",
  },
  ATTEMPTS_EXCEEDED_CONTACT: {
    id: "ATTEMPTS_EXCEEDED_CONTACT",
    defaultMessage:
      "Maximum resend tries exceeded. Please try after 1 hour or contact customer care.",
  },
  NOTIFICATION_AND_EMAIL_TEXT: {
    id: "NOTIFICATION_AND_EMAIL_TEXT",
    defaultMessage: "Notifications and emails",
  },
  NOTIFICATION_AND_EMAIL_SUB_TEXT: {
    id: "NOTIFICATION_AND_EMAIL_SUB_TEXT",
    defaultMessage: "Manage how we show you important notifications",
  },
  CHANGE_PASSWORD_SUB_TEXT: {
    id: "CHANGE_PASSWORD_SUB_TEXT",
    defaultMessage: "Fill in the required details to change password",
  },
  SETTING_PUSH_NOTIFICATION: {
    id: "SETTING_PUSH_NOTIFICATION",
    defaultMessage: "Promotional Messages",
  },
  SETTING_PUSH_NOTIFICATION_CAPTION: {
    id: "SETTING_PUSH_NOTIFICATION_CAPTION",
    defaultMessage:
      "These are notifications on promotions, campaigns and new products on the LetsGo Mall",
  },
  SETTING_EMAIL: {
    id: "SETTING_EMAIL",
    defaultMessage: "Emails",
  },
  SETTING_SMS: {
    id: "SETTING_SMS",
    defaultMessage: "SMS",
  },
  SETTING_PROFILE_TEXT: {
    id: "SETTING_PROFILE_TEXT",
    defaultMessage:
      "Upgrade now to avail more benefits  like higher transaction limit, open saving plan, qualify for a loan and more!",
  },
  SETTING_PROFILE_UPGRADE: {
    id: "SETTING_PROFILE_UPGRADE",
    defaultMessage: "Upgrade now",
  },
  SETTING_PROFILE_BENEFITS: {
    id: "SETTING_PROFILE_BENEFITS",
    defaultMessage: "Check benefits",
  },
  CUSTOM_AMOUNT: {
    id: "CUSTOM_AMOUNT",
    defaultMessage: "Custom Amount",
  },
  FIXED_PACKAGE: {
    id: "FIXED_PACKAGE",
    defaultMessage: "Fixed Package",
  },
  SELECT_PACKAGES: {
    id: "SELECT_PACKAGES",
    defaultMessage: "Select package",
  },
  METER_NO: {
    id: "METER_NO",
    defaultMessage: "Meter No.",
  },
  PACKAGE: {
    id: "PACKAGE",
    defaultMessage: "Package",
  },
  TOKEN_NUMBER: {
    id: "TOKEN_NUMBER",
    defaultMessage: "Token number",
  },
  PAYMENT_TO_PERSON: {
    id: "PAYMENT_TO_PERSON",
    defaultMessage: "Payment to {personName}",
  },
  BENEFITS_HEADING_ONE: {
    id: "BENEFITS_HEADING_ONE",
    defaultMessage: "Start enjoying the below benefits right away",
  },
  RECEIVE_FUNDS: {
    id: "RECEIVE_FUNDS",
    defaultMessage: "Receive funds",
  },
  CASH_OUT: {
    id: "CASH_OUT",
    defaultMessage: "Cash out",
  },
  BENEFITS_HEADING_TWO: {
    id: "BENEFITS_HEADING_TWO",
    defaultMessage: "Upgrade now to enjoy exciting benefits like",
  },
  SEND_RECEIVE_MONEY: {
    id: "SEND_RECEIVE_MONEY",
    defaultMessage: "Send and receive money",
  },
  BUY_AIRTIME_UTITLITY: {
    id: "BUY_AIRTIME_UTITLITY",
    defaultMessage: "Buy Airtime and make utitlity payments",
  },
  LETS_GO_WALLET_UTILITY: {
    id: "LETS_GO_WALLET_UTILITY",
    defaultMessage: "Found your LetsGo wallet from other",
  },
  WALLET_AND_ACCOUNT_UTILITY: {
    id: "WALLET_AND_ACCOUNT_UTILITY",
    defaultMessage: "Wallets and your Debit/Credit Cards",
  },
  CASH_OUT_FUND_UTILITY: {
    id: "CASH_OUT_FUND_UTILITY",
    defaultMessage: "Cash out your funds",
  },
  VIRTUAL_CARDS: {
    id: "VIRTUAL_CARDS",
    defaultMessage: "Virtual cards",
  },
  SMART_SAVING_DEPOSITS: {
    id: "SMART_SAVING_DEPOSITS",
    defaultMessage: "Smart Saving Deposits",
  },
  OPEN_BANK_ACCOUNT: {
    id: "OPEN_BANK_ACCOUNT",
    defaultMessage: "Opening a bank account",
  },
  HIGHER_TRANSACTION_LIMIT: {
    id: "HIGHER_TRANSACTION_LIMIT",
    defaultMessage: "Higher transaction limits",
  },
  LOANS_INTRACTIVE_RATE: {
    id: "LOANS_INTRACTIVE_RATE",
    defaultMessage: "Loans with attractive interest rates",
  },
  LUCRATIVE_INVESTMENT: {
    id: "LUCRATIVE_INVESTMENT",
    defaultMessage: "Lucrative Investments",
  },
  FUND_DIRECTLY_FROM_BANK: {
    id: "FUND_DIRECTLY_FROM_BANK",
    defaultMessage: "Funding wallet directly from LS bank account",
  },
  LEVEL_FOUR_HEADING_ONE: {
    id: "LEVEL_FOUR_HEADING_ONE",
    defaultMessage: "Your current benefits",
  },
  AIR_TIME_VAS_PAYMENT: {
    id: "AIR_TIME_VAS_PAYMENT",
    defaultMessage: "Airtime/VAS payments",
  },
  FUND_FROM_EXTERNAL_WALLET: {
    id: "FUND_FROM_EXTERNAL_WALLET",
    defaultMessage: "Funds from extrnal wallet",
  },
  FUNDS_FROM_CC: {
    id: "FUNDS_FROM_CC",
    defaultMessage: "Funds from CC",
  },
  P2P_PAYMENTS: {
    id: "P2P_PAYMENTS",
    defaultMessage: "P2P payments",
  },
  LEVEL_FOUR_HEADING_TWO: {
    id: "LEVEL_FOUR_HEADING_TWO",
    defaultMessage: "Upgrade yourself to avail more benefits",
  },
  SAVINGS: {
    id: "SAVINGS",
    defaultMessage: "Saving",
  },
  LETSGO_SAVING: {
    id: "LETSGO_SAVING",
    defaultMessage: "LetsGo Save",
  },
  REMITA_LOAN: {
    id: "REMITA_LOAN",
    defaultMessage: "Remita",
  },
  SUB10_LOAN: {
    id: "SUB10_LOAN",
    defaultMessage: "Instant Top-up",
  },
  FUND_FROM_LS_ACCOUNT: {
    id: "FUND_FROM_LS_ACCOUNT",
    defaultMessage: "Funds from LS bank account",
  },
  OPEN_A_BANK_ACCOUNT: {
    id: "OPEN_A_BANK_ACCOUNT",
    defaultMessage: "Open a bank account",
  },
  INVESTMENTS: {
    id: "INVESTMENTS",
    defaultMessage: "Investments",
  },
  YOUR_BENEFITS: {
    id: "YOUR_BENEFITS",
    defaultMessage: "Your benefits",
  },
  UPGRADE_NOW: {
    id: "UPGRADE_NOW",
    defaultMessage: "Upgrade now",
  },
  PAYMENT_INITIATED: {
    id: "PAYMENT_INITIATED",
    defaultMessage: "Payment initiated to {personName}",
  },
  REQUEST_SENT_SUCCESSFULLY: {
    id: "REQUEST_SENT_SUCCESSFULLY",
    defaultMessage: "Request Sent successfully!",
  },
  ERROR_SENDING_REQUEST: {
    id: "ERROR_SENDING_REQUEST",
    defaultMessage: "Error in sending Request",
  },
  TRANSACTION_MODAL_PAID_FOR: {
    id: "TRANSACTION_MODAL_PAID_FOR",
    defaultMessage: "Paid for {personName}",
  },
  WRONG_DETAILS_GENERIC: {
    id: "WRONG_DETAILS_GENERIC",
    defaultMessage:
      "The details entered seem to be incorrect. Please try again. ",
  },
  INITIATE_TRANSFER_CTA: {
    id: "INITIATE_TRANSFER_CTA",
    defaultMessage: "Initiate transfer",
  },
  ADD_FUND_ALERT: {
    id: "ADD_FUND_ALERT",
    defaultMessage:
      "Your verification is in progress and you can start using LetsGo wallet once it’s done",
  },
  RECEIVE_FUND_ALERT: {
    id: "RECEIVE_FUND_ALERT",
    defaultMessage:
      "Your verification is in progress and you can instantly get paid by your friends and family once it’s done",
  },
  PAYMENTS_ALERT: {
    id: "PAYMENTS_ALERT",
    defaultMessage:
      "Your verification is in progress and you can instantly pay bills once it’s done",
  },
  HEY_THERE_TEXT: { id: "HEY_THERE_TEXT", defaultMessage: "Hey there!" },
  HEY_LETS_GET: { id: "HEY_LETS_GET", defaultMessage: "Hey, let's get to" },
  KNOW_YOU: { id: "KNOW_YOU", defaultMessage: "know you" },
  PROFILE_COMPLETE_TEXT: {
    id: "PROFILE_COMPLETE_TEXT",
    defaultMessage: "{percentage} profile completed",
  },
  PROFILE_KYC_LEVEL_COMMON_COMPLETE_TEXT: {
    id: "PROFILE_KYC_LEVEL_COMMON_COMPLETE_TEXT",
    defaultMessage:
      "Complete your profile to unlock exclusive benefits across all services",
  },
  PROFILE_KYC_LEVEL_2_COMPLETE_TEXT: {
    id: "PROFILE_KYC_LEVEL_2_COMPLETE_TEXT",
    defaultMessage:
      "Explore and apply for our loan products to complete your profile successfully",
  },
  PROFILE_KYC_LEVEL_3_COMPLETE_TEXT: {
    id: "PROFILE_KYC_LEVEL_3_COMPLETE_TEXT",
    defaultMessage:
      "Congratulation! You have access to exclusive benefits across all services",
  },
  VIEW_BENEFITS_CTA_LABEL: {
    id: "VIEW_BENEFITS_CTA_LABEL",
    defaultMessage: "View benefits",
  },
  COMPLETE_PROFILE_CTA_LABEL: {
    id: "COMPLETE_PROFILE_CTA_LABEL",
    defaultMessage: "Complete your profile",
  },
  YOUR_PROFILE: {
    id: "YOUR_PROFILE",
    defaultMessage: "Your profile",
  },
  COMPLETE_PERSONAL_PROFILE_TXT: {
    id: "COMPLETE_PERSONAL_PROFILE_TXT",
    defaultMessage:
      "Complete your personal profile to continue enjoying exciting benefits on the digital mall.",
  },
  CONTINUE_TO_PROFILE: {
    id: "CONTINUE_TO_PROFILE",
    defaultMessage: "Continue to your profile",
  },
  DO_THIS_LATER: {
    id: "DO_THIS_LATER",
    defaultMessage: "Do this later",
  },
  PROFILE_EMAIL_ID_LABEL: {
    id: "PROFILE_EMAIL_ID_LABEL",
    defaultMessage: "Email ID",
  },
  PROFILE_UPDATE_SUB_HEADING: {
    id: "PROFILE_UPDATE_SUB_HEADING",
    defaultMessage: "Fill in your details and unlock benefits",
  },
  Gallery_LABEL: {
    id: "Gallery_LABEL",
    defaultMessage: "Gallery",
  },
  CHANGE_PICTURE: {
    id: "CHANGE_PICTURE",
    defaultMessage: "Change picture",
  },
  REMOVE: {
    id: "REMOVE",
    defaultMessage: "Remove",
  },
  PROFILE_COMPLETENESS_MESSAGE: {
    id: "PROFILE_COMPLETENESS_MESSAGE",
    defaultMessage: "Your profile ({kycLevel} completed)",
  },
  EWALLET_TNC_TITLE: {
    id: "EWALLET_TNC_TITLE",
    defaultMessage: "Updated terms & conditions",
  },
  EWALLET_TNC_TEXT: {
    id: "EWALLET_TNC_TEXT",
    defaultMessage:
      "To access wallet services, please accept all terms & conditions.",
  },
  ACCEPT_CONTINUE: {
    id: "ACCEPT_CONTINUE",
    defaultMessage: "Accept & Continue",
  },
  ON_GOING_LOAN: {
    id: "ON_GOING_LOAN",
    defaultMessage: "On-going loan",
  },
  PROFILE_ACHIEVE_GOAL_HEADER: {
    id: "PROFILE_ACHIEVE_GOAL_HEADER",
    defaultMessage: "Have a goal you wish to achieve?",
  },
  PROFILE_ACHIEVE_GOAL_TEXT: {
    id: "PROFILE_ACHIEVE_GOAL_TEXT",
    defaultMessage:
      "Identify your dreams, set aside some amount each month into the savings jar and achieve your goal.",
  },
  OPEN_SAVINGS_GOAL_CTA: {
    id: "OPEN_SAVINGS_GOAL_CTA",
    defaultMessage: "Open savings goal",
  },
  MY_TEXT: {
    id: "MY_TEXT",
    defaultMessage: "My",
  },
  PRODUCTS_TEXT: {
    id: "PRODUCTS_TEXT",
    defaultMessage: "Products",
  },
  CLEAR_DUES: {
    id: "CLEAR_DUES",
    defaultMessage: "Clear dues",
  },
  NO_GOALS_FOUND: {
    id: "NO_GOALS_FOUND",
    defaultMessage: "No goals found!",
  },
  NO_GOALS_YET: {
    id: "NO_GOALS_YET",
    defaultMessage: "You have not created any goals yet,",
  },
  ADD_NEW_GOAL_TEXT: {
    id: "ADD_NEW_GOAL_TEXT",
    defaultMessage: "{link} and get started!",
  },
  YOUR_GOALS: {
    id: "YOUR_GOALS",
    defaultMessage: "Your goals =",
  },
  OUR_RESPONSIBLITY: {
    id: "OUR_RESPONSIBLITY",
    defaultMessage: "Our responsibility",
  },
  REACH_YOUR_GOALS: {
    id: "REACH_YOUR_GOALS",
    defaultMessage: "Reach your goals at lightning speed by saving daily!",
  },
  MY_GOALS: {
    id: "MY_GOALS",
    defaultMessage: "My goals",
  },
  GOALS_SUB_HEADING: {
    id: "GOALS_SUB_HEADING",
    defaultMessage: "Create, track and reach your goals by saving daily!",
  },
  ADD_NEW_GOAL: {
    id: "ADD_NEW_GOAL",
    defaultMessage: "Add new goal",
  },
  GOAL_WITHDRAW_TEXT: {
    id: "GOAL_WITHDRAW_TEXT",
    defaultMessage: "Withdraw only after goal is completed?",
  },
  GOAL_EDIT_AMOUNT_FIELD: {
    id: "GOAL_EDIT_AMOUNT_FIELD",
    defaultMessage: "Goal amount",
  },
  GOAL_EDIT_TITLE_FIELD: {
    id: "GOAL_EDIT_TITLE_FIELD",
    defaultMessage: "Goal Title",
  },
  GOAL_EDIT_DESCRIPTION_FIELD: {
    id: "GOAL_EDIT_DESCRIPTION_FIELD",
    defaultMessage: "Description (Optional)",
  },
  GOAL_EDIT_DATE_FIELD: {
    id: "GOAL_EDIT_DATE_FIELD",
    defaultMessage: "End date (Optional)",
  },
  GOAL_SAVE_BUTTON: {
    id: "GOAL_SAVE_BUTTON",
    defaultMessage: "Save changes",
  },
  MONTHS_LEFT: {
    id: "MONTHS_LEFT",
    defaultMessage: "{months} months left",
  },
  WITHDRAW_FUNDS: {
    id: "WITHDRAW_FUNDS",
    defaultMessage: "Withdraw",
  },
  WITHDRAW_FUNDS_LABEL: {
    id: "WITHDRAW_FUNDS_LABEL",
    defaultMessage: "Withdraw funds",
  },
  MY_ACTIVITY_TEXT: {
    id: "MY_ACTIVITY_TEXT",
    defaultMessage: "My activity",
  },
  WEEKLY_GOAL: {
    id: "WEEKLY_TARGET",
    defaultMessage: "Weekly Target",
  },
  WEEKLY_GOAL_DESC: {
    id: "WEEKLY_GOAL_DESC",
    defaultMessage: "Reach your goals in time!",
  },
  COLLABORATE_TEXT: {
    id: "COLLABORATE_TEXT",
    defaultMessage: "Collaborate!",
  },
  COLLABORATE_CTA_DESC: {
    id: "COLLABORATE_CTA_DESC",
    defaultMessage: "Request friends to contribute to your goal",
  },
  CLOSE_GOAL_CTA: {
    id: "CLOSE_GOAL_CTA",
    defaultMessage: "Close goal",
  },
  SAVED: {
    id: "SAVED",
    defaultMessage: "Saved",
  },
  ACCOUNT_AND_CARDS: {
    id: "ACCOUNT_AND_CARDS",
    defaultMessage: "Accounts and cards",
  },
  LETS_GO_BANK_ACCOUNT: {
    id: "LETS_GO_BANK_ACCOUNT",
    defaultMessage: "LetsGo bank accounts",
  },
  CREDIT_AND_DEBIT_CARDS: {
    id: "CREDIT_AND_DEBIT_CARDS",
    defaultMessage: "Credit and debit cards",
  },
  EXPIRES_ON: {
    id: "EXPIRES_ON",
    defaultMessage: "Expires on",
  },
  VALID: {
    id: "VALID",
    defaultMessage: "VALID",
  },
  THRU: {
    id: "THRU",
    defaultMessage: "THRU",
  },
  CARD_NAME: {
    id: "CARD_NAME",
    defaultMessage: "Card Name",
  },
  VALIDITY: {
    id: "VALIDITY",
    defaultMessage: "Validity",
  },
  VALID_TILL: {
    id: "VALID_TILL",
    defaultMessage: "Valid till",
  },
  REMOVE_CARD: {
    id: "REMOVE_CARD",
    defaultMessage: "Remove Card",
  },
  WALLETS: {
    id: "WALLETS",
    defaultMessage: "Wallets",
  },

  CONTINUE_MODAL_CTA_TITLE: {
    id: "CONTINUE_MODAL_CTA_TITLE",
    defaultMessage: "Continue to fill more details",
  },
  CONTINUE_MODAL_CTA_DESCRIPTION: {
    id: "CONTINUE_MODAL_CTA_DESCRIPTION",
    defaultMessage:
      "Provide few more details to complete your profile and avail exciting benefits.",
  },
  SKIP_SUBMIT_CTA: {
    id: "SKIP_SUBMIT_CTA",
    defaultMessage: "Skip and Submit",
  },
  PROFILE_KYC_VERIFICATION_FAILURE_TEXT: {
    id: "PROFILE_KYC_VERIFICATION_FAILURE_TEXT",
    defaultMessage:
      "We could not verify the details sent by you. Please try again",
  },
  PROFILE_KYC_NOT_VERIFIED_TEXT: {
    id: "PROFILE_KYC_NOT_VERIFIED_TEXT",
    defaultMessage: "Details not verified",
  },
  MY_LETSGO_WALLET: {
    id: "MY_LETSGO_WALLET",
    defaultMessage: "My LetsGo Pay",
  },
  LETSINSURE: {
    id: "LETSINSURE",
    defaultMessage: "Letshego Insurance",
  },
  ADD_GOAL_TITLE: {
    id: "ADD_GOAL_TITLE",
    defaultMessage: "What goal do you",
  },
  ADD_GOAL_SUBTITLE: {
    id: "ADD_GOAL_SUBTITLE",
    defaultMessage: "Wish to save for?",
  },
  ADD_GOAL_TITLE_2: {
    id: "ADD_GOAL_TITLE_2",
    defaultMessage: "How much do you",
  },
  ADD_GOAL_SUBTITLE_2: {
    id: "ADD_GOAL_SUBTITLE_2",
    defaultMessage: "Wish to save?",
  },
  ADD_GOAL_TITLE_3: {
    id: "ADD_GOAL_TITLE_3",
    defaultMessage: "Choose a photo",
  },
  ADD_GOAL_SUBTITLE_3: {
    id: "ADD_GOAL_SUBTITLE_3",
    defaultMessage: "For your goal",
  },
  PAGINATION_OFFSET: {
    id: "PAGINATION_OFFSET",
    defaultMessage: " of ",
  },
  GOAL_TITLE: {
    id: "GOAL_TITLE",
    defaultMessage: "Goal title",
  },
  GOALS_SUBTEXT: {
    id: "GOALS_SUBTEXT",
    defaultMessage: "Track and reach your goals by saving daily",
  },
  GOAL_AMOUNT: {
    id: "GOAL_AMOUNT",
    defaultMessage: "Goal amount",
  },
  GOAL_CONDITION: {
    id: "GOAL_CONDITION",
    defaultMessage: "Allow withdrawal  only after goal is completed?",
  },
  END_DATE_OPTIONAL: {
    id: "END_DATE_OPTIONAL",
    defaultMessage: "End Date (optional)",
  },
  NEXT: {
    id: "NEXT",
    defaultMessage: "Next",
  },
  PREVIOUS: {
    id: "PREVIOUS",
    defaultMessage: "Previous",
  },
  PAY_BILLS: {
    id: "PAY_BILLS",
    defaultMessage: "Pay Bills",
  },
  KYC_BENEFITS_TITLE: {
    id: "KYC_BENEFITS_TITLE",
    defaultMessage: "Complete your profile to avail exclusive benefits",
  },
  KYC_BENEFITS_LEVEL_COMPLETENESS: {
    id: "KYC_BENEFITS_LEVEL_COMPLETENESS",
    defaultMessage: "{percentage}% Completed!",
  },
  KYC_BENEFITS_LEVEL_COMPLETION: {
    id: "KYC_BENEFITS_LEVEL_COMPLETION",
    defaultMessage: "{percentage}% Completion",
  },
  COMPLETE_PROFILE_NOW_CTA_LABEL: {
    id: "COMPLETE_PROFILE_NOW_CTA_LABEL",
    defaultMessage: "Complete profile now",
  },
  OUT_OF: {
    id: "OUT_OF",
    defaultMessage: "Out of {value}",
  },
  GOAL_BALANCE: {
    id: "GOAL_BALANCE",
    defaultMessage: "Balance : {currency} {balance}",
  },
  COMPLETE_YOUR_PROFILE: {
    id: "COMPLETE_YOUR_PROFILE",
    defaultMessage: "Complete your profile",
  },
  CONTINUE_YOUR_PROFILE: {
    id: "CONTINUE_YOUR_PROFILE",
    defaultMessage: "Continue profile completion",
  },
  KYC_0_ADD_FUNDS_INFO: {
    id: "KYC_0_ADD_FUNDS_INFO",
    defaultMessage: "Help us with a few details to start using LetsGo wallet",
  },
  KYC_0_SEND_FUNDS_INFO: {
    id: "KYC_0_SEND_FUNDS_INFO",
    defaultMessage:
      "Help us with a few details so you can instantly pay your friends and family",
  },
  KYC_0_PAY_BILLS_INFO: {
    id: "KYC_0_PAY_BILLS_INFO",
    defaultMessage: "Help us with a few details so you can instantly pay bills",
  },
  PAY_YOUR_BILLS_INSTANTLY: {
    id: "PAY_YOUR_BILLS_INSTANTLY",
    defaultMessage: "Pay your bills instantly",
  },
  QUICK_HASSLE_FREE_PAYMENT: {
    id: "QUICK_HASSLE_FREE_PAYMENT",
    defaultMessage: "Quick and hassle free way to pay your bills on time",
  },
  MOBILE_BILLS: {
    id: "MOBILE_BILLS",
    defaultMessage: "Mobile Bills",
  },
  ELECTRICITY_BILL: {
    id: "ELECTRICITY_BILL",
    defaultMessage: "Electricity Bill",
  },
  WATER_BILL: {
    id: "WATER_BILL",
    defaultMessage: "Water Bill",
  },
  OTHER_BILL: {
    id: "OTHER_BILL",
    defaultMessage: "Other Bill",
  },
  GOAL_COMPLETE_TEXT: {
    id: "GOAL_COMPLETE_TEXT",
    defaultMessage:
      "Your goal is complete! You may now withdraw from your accumulated funds",
  },
  GOAL: {
    id: "GOAL",
    defaultMessage: "Goal",
  },
  NO_PRODUCTS_PURCHASE: {
    id: "NO_PRODUCTS_PURCHASE",
    defaultMessage: "You haven’t purchased any products yet!",
  },
  APPLY_NEW_LOAN: {
    id: "APPLY_NEW_LOAN",
    defaultMessage: "Want to apply for a new loan?",
  },
  APPLY_NEW_LOAN_DESC: {
    id: "APPLY_NEW_LOAN_DESC",
    defaultMessage:
      "Get a payroll loan in just 3 easy steps, start by filling your application.",
  },
  PAY_INSTALLMENT_TEXT: {
    id: "PAY_INSTALLMENT_TEXT",
    defaultMessage: "Pay installment",
  },
  APPLY_FOR_TOP_UP: {
    id: "APPLY_FOR_TOP_UP",
    defaultMessage: "Apply for top-up",
  },
  CALCULATE_EMI: {
    id: "CALCULATE_EMI",
    defaultMessage: "Calculate EMI",
  },
  DAS_LOAN_ACCOUNT_NUMBER: {
    id: "DAS_LOAN_ACCOUNT_NUMBER",
    defaultMessage: "Das loan A/C no. {accountNumber}",
  },
  OF_AMOUNT_TEXT: {
    id: "OF_AMOUNT_TEXT",
    defaultMessage: "of {loanAmount}",
  },
  ALOMST_THERE_TEXT: {
    id: "ALOMST_THERE_TEXT",
    defaultMessage: "You’re almost there!",
  },
  APPLY_LOAN_DASHBOARD: {
    id: "APPLY_LOAN_DASHBOARD",
    defaultMessage:
      "You’re applying for {loanAmount}, continue applying for the loan",
  },
  GOAL_BALANCE_TRANSFER: {
    id: "GOAL_BALANCE_TRANSFER",
    defaultMessage:
      "Your goal balance: {currency} {amount} will be transferred to your wallet.",
  },
  ZERO_GOAL_BALANCE: {
    id: "ZERO_GOAL_BALANCE",
    defaultMessage: "Your goal balance: {currency} {amount}",
  },
  CLOSE_GOAL: {
    id: "CLOSE_GOAL",
    defaultMessage: "Close goal",
  },
  AMOUNT_TRANSFERRED_SUCCESSFULLY: {
    id: "AMOUNT_TRANSFERRED_SUCCESSFULLY",
    defaultMessage:
      "{currency} {amount} has been transferred to your wallet balance successfuly!",
  },
  TRANSFER_FUND_CLOSE_GOAL: {
    id: "TRANSFER_FUND_CLOSE_GOAL",
    defaultMessage: "Transfer funds & close goal",
  },
  UTILITY_PAYEMENT_SUBTEXT: {
    id: "UTILITY_PAYEMENT_SUBTEXT",
    defaultMessage: "Make instant payment towards all types of utility bills",
  },
  ACTIVITY: {
    id: "ACTIVITY",
    defaultMessage: "Activity in",
  },
  INCOMING: {
    id: "INCOMING",
    defaultMessage: "Incoming",
  },
  OUTGOING: {
    id: "OUTGOING",
    defaultMessage: "Outgoing",
  },
  DAYS_LEFT: {
    id: "DAYS_LEFT",
    defaultMessage: "{days} days left",
  },
  INDIVIDUAL: {
    id: "INDIVIDUAL",
    defaultMessage: "Individual Loan",
  },
  INDIVIDUAL_BOTSWANA: {
    id: "INDIVIDUAL_BOTSWANA",
    defaultMessage: "LetsGo Motshegetsi",
  },
  INDIVIDUAL_NIGERIA: {
    id: "INDIVIDUAL_NIGERIA",
    defaultMessage: "Easy Loan",
  },
  DAYS_EXCEDDED: {
    id: "DAYS_EXCEDDED",
    defaultMessage: "{days} days past deadline",
  },
  MONTH_EXCEDDED: {
    id: "MONTH_EXCEDDED",
    defaultMessage: "{months} months past deadline",
  },
  GOAL_IMAGE: {
    id: "GOAL_IMAGE",
    defaultMessage: "Contribute to my goal",
  },
  USER_WALLET_CARD_TAG_LINE: {
    id: "USER_WALLET_CARD_TAG_LINE",
    defaultMessage:
      "You’re sending funds to {userFullName}’s {walletName}. Want to send to another wallet?",
  },
  PAYROLL_LOANS: {
    id: "PAYROLL_LOANS",
    defaultMessage: "Payroll loans",
  },
  EXPLORE: {
    id: "EXPLORE",
    defaultMessage: "Explore",
  },
  EXPLORE_OTHER_PRODUCTS: {
    id: "EXPLORE_OTHER_PRODUCTS",
    defaultMessage: "Explore other products",
  },
  NEW_LOAN_TITLE: {
    id: "NEW_LOAN_TITLE",
    defaultMessage: "Want to apply for a new loan?",
  },
  NEW_LOAN_SUB_TITLE: {
    id: "NEW_LOAN_SUB_TITLE",
    defaultMessage:
      "Get a payroll loan in just 3 easy steps, start by filling your application.",
  },
  PAYING_HIGHER_AMOUNT_TITLE: {
    id: "PAYING_HIGHER_AMOUNT_TITLE",
    defaultMessage: "Pay higher amounts",
  },
  PAYING_HIGHER_AMOUNT_SUB_TITLE: {
    id: "PAYING_HIGHER_AMOUNT_SUB_TITLE",
    defaultMessage:
      "You have reached your daily transaction limit of {currencySymbol} {amount}. To increase your transaction limit, upgrade now.",
  },
  UPGRADE_WALLET_LIMIT: {
    id: "UPGRADE_WALLET_LIMIT",
    defaultMessage: "Upgrade wallet limit",
  },
  SAVE_DETAILS: {
    id: "SAVE_DETAILS",
    defaultMessage: "Save Details",
  },
  GOAL_CHOOSE_IMAGE: {
    id: "GOAL_CHOOSE_IMAGE",
    defaultMessage: "Choose an image for your goal",
  },
  NUIT_LABEL: {
    id: "NUIT_LABEL",
    defaultMessage: "NUIT",
  },
  WITHDRAW_CONFIRMATION_TITLE: {
    id: "WITHDRAW_CONFIRMATION_TITLE",
    defaultMessage:
      "You had chosen to withdraw only after you completed the goal.",
  },
  CONFIRM_WITHDRAW_WARNING_TEXT: {
    id: "CONFIRM_WITHDRAW_WARNING_TEXT",
    defaultMessage: "Confirm withdrawal?",
  },
  WARNING_TEXT_NVR_ASK: {
    id: "WARNING_TEXT_NVR_ASK",
    defaultMessage: "Don't ask me again",
  },
  INSTANT_LOAN_POINT_1: {
    id: "INSTANT_LOAN_POINT_1",
    defaultMessage: "No need to fill a form",
  },
  INSTANT_LOAN_POINT_2: {
    id: "INSTANT_LOAN_POINT_2",
    defaultMessage: "No documents required",
  },
  INSTANT_LOAN_PROGRESS_TEXT: {
    id: "INSTANT_LOAN_PROGRESS_TEXT",
    defaultMessage:
      "We have got all your details, our team is currently reviewing your application",
  },
  INSTANT_LOAN_REQUEST_CHANGE_IN_KYC: {
    id: "INSTANT_LOAN_REQUEST_CHANGE_IN_KYC",
    defaultMessage:
      "Unfortunately, we cannot proceed with your application at the moment.\n" +
      "Please visit the branch in order to request change in your KYC details",
  },
  REJECT_REMITA_ACTION_NO: {
    id: "REJECT_REMITA_ACTION_NO",
    defaultMessage: "No, I want to accept",
  },

  AIRTIME_SEARCH_UTILITY: {
    id: "AIRTIME_SEARCH_UTILITY",
    defaultMessage: "Search by name or phone number",
  },
  ACC_NAME_SEARCH_UTILITY: {
    id: "ACC_NAME_SEARCH_UTILITY",
    defaultMessage: "Search by name or account number",
  },
  INERNET_UTILITY: {
    id: "INERNET_UTILITY",
    defaultMessage: "Search by name or subscription account number",
  },
  ACC_NAME_SUBSCRIPTION_SEARCH_UTILITY: {
    id: "ACC_NAME_SUBSCRIPTION_SEARCH_UTILITY",
    defaultMessage: "Search by name or subscription account number",
  },
  NAME_ACCOUNT_SEARCH_UTILITY: {
    id: "NAME_ACCOUNT_SEARCH_UTILITY",
    defaultMessage: "Search by name or account number",
  },
  ERROR_NO_SPACES_REQUIRED: {
    id: "ERROR_NO_SPACES_REQUIRED",
    defaultMessage: "Please remove trailing spaces",
  },
  MOZAMBIQUE: {
    id: "MOZAMBIQUE",
    defaultMessage: "Moçambique",
  },
  INSTANT_LOAN_TITLE: {
    id: "INSTANT_LOAN_TITLE",
    defaultMessage: "Get {productName}",
  },
  INSTANT_LOAN_SUB_TITLE: {
    id: "INSTANT_LOAN_SUB_TITLE",
    defaultMessage: "of {amount}",
  },
  INSTANT_LOAN_AMOUNT_BORROW_TEXT: {
    id: "INSTANT_LOAN_AMOUNT_BORROW_TEXT",
    defaultMessage: "I need to borrow",
  },
  OFFER_MODAL_SELECTION_TEXT: {
    id: "OFFER_MODAL_SELECTION_TEXT",
    defaultMessage: "Select a new or on-going loan",
  },
  ACCEPT_LOAN_OFFER_BUTTON: {
    id: "ACCEPT_LOAN_OFFER_BUTTON",
    defaultMessage: "Accept loan offer",
  },
  TRANSACTION_FEE_APPLY: {
    id: "TRANSACTION_FEE_APPLY",
    defaultMessage:
      "A small transaction fee might be applicable. To know more {link}",
  },
  READ_FAQ: {
    id: "READ_FAQ",
    defaultMessage: "read FAQs.",
  },
  READ: {
    id: "READ",
    defaultMessage: "READ",
  },
  WALLET: {
    id: "WALLET",
    defaultMessage: "Wallet",
  },
  YOUR_GOALS_OUR_RESPONSIBILITY: {
    id: "YOUR_GOALS_OUR_RESPONSIBILITY",
    defaultMessage: "Your goals = our responsibility",
  },
  CREATE_TRACK_GOALS: {
    id: "CREATE_TRACK_GOALS",
    defaultMessage: "Create and track goal",
  },
  ADD_WITHDRAW_FUNDS: {
    id: "ADD_WITHDRAW_FUNDS",
    defaultMessage: "Add and withdraw funds",
  },
  REQUEST_FRIENDS_TO_COLLABORATE: {
    id: "REQUEST_FRIENDS_TO_COLLABORATE",
    defaultMessage: "Request friends to contribute",
  },
  KYC_0_GOALS_INFO: {
    id: "KYC_0_GOALS_INFO",
    defaultMessage:
      "Help us with a few details so you can start creating goals",
  },
  GOALS_VERIFICATION_ALERT: {
    id: "GOALS_VERIFICATION_ALERT",
    defaultMessage:
      "Your verification is in progress and you can start creating goals once it’s done",
  },
  EXPLORE_NOW: {
    id: "EXPLORE_NOW",
    defaultMessage: "Explore now",
  },
  READ_TERMS_POLICY: {
    id: "READ_TERMS_POLICY",
    defaultMessage: "Kindly view the Terms and Conditions and Privacy Policy",
  },
  TRANSACTION_HISTORY_PAYMENT_REQUESTED_BY: {
    id: "TRANSACTION_HISTORY_PAYMENT_REQUESTED_BY",
    defaultMessage: "Requested by {personName}",
  },
  ACCOUNT_VERIFICATION_PATTERN_ERROR: {
    id: "ACCOUNT_VERIFICATION_PATTERN_ERROR",
    defaultMessage: "Please enter a valid account number",
  },
  COMPANY_HELP_TEXT_PERMANENT_LS: {
    id: "COMPANY_HELP_TEXT_PERMANENT_LS",
    defaultMessage:
      "You need to be employed for minimum 6 months with the government or private employer to apply online",
  },
  COMPANY_HELP_TEXT_PERMANENT_NA: {
    id: "COMPANY_HELP_TEXT_PERMANENT_NA",
    defaultMessage:
      "You need to be employed for minimum 3 months with the government or private employer to apply online",
  },
  FILL_ALL_MEDICAL_FIELDS: {
    id: "FILL_ALL_MEDICAL_FIELDS",
    defaultMessage:
      "Seems like you have missed to fill some fields on Health Declaration form. Please check before submitting",
  },
  NO_SAVED_BENEFICIARY_TEXT: {
    id: "NO_SAVED_BENEFICIARY_TEXT",
    defaultMessage: "No saved beneficiary!",
  },
  ADD_NEW_BENEFICIARY_HELPER_TEXT: {
    id: "ADD_NEW_BENEFICIARY_HELPER_TEXT",
    defaultMessage: "Add a new beneficiary to start transacting",
  },
  INSTANT_LOAN_SIDEBAR_TITLE: {
    id: "INSTANT_LOAN_SIDEBAR_TITLE",
    defaultMessage: "Get {productName} of {amount}",
  },
  INSTANT_LOAN: {
    id: "INSTANT_LOAN",
    defaultMessage: "{productName}",
  },
  INSTANT_LOAN_PRODUCT_BASE_NAME: {
    id: "INSTANT_LOAN_PRODUCT_BASE_NAME",
    defaultMessage: "Instant Loan",
  },
  INSTANT_LOAN_INTRO_DESC: {
    id: "INSTANT_LOAN_INTRO_DESC",
    defaultMessage:
      "You are at the right place to get  quick and hassle-free funds instantly.",
  },
  INSTANT_LOAN_DISBURSEMENT_DESC: {
    id: "INSTANT_LOAN_DISBURSEMENT_DESC",
    defaultMessage: "Fast & immediate disbursement to method of your choice",
  },
  INSTANT_LOAN_DISBURSEMENT_SHORT_DESC: {
    id: "INSTANT_LOAN_DISBURSEMENT_SHORT_DESC",
    defaultMessage: "Fast and immediate disbursement",
  },
  HOW: {
    id: "HOW",
    defaultMessage: "How",
  },
  INSTANT_LOAN_WORKS: {
    id: "INSTANT_LOAN_WORKS",
    defaultMessage: "Instant loan works?",
  },
  SELECT_LOAN_AMOUNT: {
    id: "SELECT_LOAN_AMOUNT",
    defaultMessage: "Select loan amount",
  },
  PROVIDE_DETAILS: {
    id: "PROVIDE_DETAILS",
    defaultMessage: "Provide details",
  },
  RECEIVE_MONEY: {
    id: "RECEIVE_MONEY",
    defaultMessage: "Receive money",
  },
  NID_FRONT_AND_BACK: {
    id: "NID_FRONT_AND_BACK",
    defaultMessage: "Front and back image of the National ID",
  },
  MOBILE_WALLET_DETAILS: {
    id: "MOBILE_WALLET_DETAILS",
    defaultMessage: "Mobile wallet details",
  },
  BANK_ACCOUNT_DETAILS: {
    id: "BANK_ACCOUNT_DETAILS",
    defaultMessage: "Bank account details",
  },
  REPAYMENT_METHOD: {
    id: "REPAYMENT_METHOD",
    defaultMessage: "Repayment Method",
  },
  PAYMENT_METHOD_DETAILS: {
    id: "PAYMENT_METHOD_DETAILS",
    defaultMessage: "Payment Method Details",
  },
  WALLET_MNO_COLLECTION: {
    id: "WALLET_MNO_COLLECTION",
    defaultMessage: "Mobile Wallet (MNO)",
  },
  REPAYMENT_AMOUNT_HELPER_TEXT: {
    id: "REPAYMENT_AMOUNT_HELPER_TEXT",
    defaultMessage:
      "You can repay the entire amount or a portion of it. Please enter the amount you wish to repay.",
  },
  REPAYMENT_AMOUNT_ERROR_TEXT: {
    id: "REPAYMENT_AMOUNT_ERROR_TEXT",
    defaultMessage:
      "Please enter an amount between UGX 500 and your repayment amount",
  },
  REPAYMENT_DATE_CURRENT_OUTSTANDING: {
    id: "REPAYMENT_DATE_CURRENT_OUTSTANDING",
    defaultMessage: "Repayment date & Current outstanding",
  },
  WHAT_YOU_NEED_TO_APPLY: {
    id: "WHAT_YOU_NEED_TO_APPLY",
    defaultMessage: "What you need to apply",
  },
  FLASH_CARD: {
    id: "FLASH_CARD",
    defaultMessage: "Flash cash",
  },
  INSTANT_LOAN_TNC_HEADER: {
    id: "INSTANT_LOAN_TNC_HEADER",
    defaultMessage:
      "These terms govern the use of this website. Please read them carefully before accessing the site. If you do not agree with these terms do not access the website. By accessing the website or any of it’s pages you agree to be bound by these terms of use.",
  },
  INSTANT_LOAN_TNC_TEXT: {
    id: "INSTANT_LOAN_TNC_TEXT",
    defaultMessage:
      "By clicking on Get Loan, I consent to Letshego MFB obtaining information from relevant third parties as may be necessary, on my employment details, salary payment history, loans and other related data, to make a decision on my loan application. I also consent to the loan amounts being deducted from my salary at source before credit to my account; and any outstanding loans being recovered automatically from any BVN accounts linked to me in the case of default.",
  },
  INSTANT_LOAN_TNC: {
    id: "INSTANT_LOAN_TNC",
    defaultMessage:
      "I accept that I have read and agreed to all the {termsAndCondition}",
  },
  FLASH_CASH_LOAN: {
    id: "FLASH_CASH_LOAN",
    defaultMessage: "Flash cash loan",
  },
  VAS_VALIDATION_TEXT: {
    id: "VAS_VALIDATION_TEXT",
    defaultMessage: "must be valid",
  },
  ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT: {
    id: "ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT",
    defaultMessage: "Want to add funds through another bank account?",
  },
  FOLLOW_INSTRUCTIONS_TEXT: {
    id: "FOLLOW_INSTRUCTIONS_TEXT",
    defaultMessage: "Follow these {stepCount} simple steps",
  },
  ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_ONE: {
    id: "ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_ONE",
    defaultMessage: "Open your non-Letshego bank app/website",
  },
  ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_TWO: {
    id: "ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_TWO",
    defaultMessage:
      "Transfer funds that you wish to be credited to your wallet to this dedicated bank a/c: <account number>",
  },
  ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_THREE: {
    id: "ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_THREE",
    defaultMessage:
      "Include your phone number linked to your LetsGo wallet in the beneficiary reference in the following format: <phone number format>",
  },
  ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_FOUR: {
    id: "ADD_FUNDS_THROUGH_ANOTHER_ACCOUNT_STEP_FOUR",
    defaultMessage:
      "The funds will be credited to your wallet as soon as we receive the funds!",
  },
  SHARE_THIS_CODE: {
    id: "SHARE_THIS_CODE",
    defaultMessage: "Share this code",
  },
  SHARE_THIS_LINK: {
    id: "SHARE_THIS_LINK",
    defaultMessage: "Share this link",
  },
  MANAGE: {
    id: "MANAGE",
    defaultMessage: "Manage",
  },
  MANAGE_MEMBERS: {
    id: "MANAGE_MEMBERS",
    defaultMessage: "Manage members",
  },
  CURRENT_MEMBERS_COUNT: {
    id: "CURRENT_MEMEBERS_COUNT",
    defaultMessage: "Current members ({currentMembers})",
  },
  INVITE_NEW_MEMBER_LABEL: {
    id: "INVITE_NEW_MEMBER_LABEL",
    defaultMessage: "Invite New Members",
  },
  INVITE_NEW_MEMBER_SUB_LABEL: {
    id: "INVITE_NEW_MEMBER_SUB_LABEL",
    defaultMessage: "You can host upto {limit} members on your goal",
  },
  ONGOING_GOALS: {
    id: "ONGOING_GOALS",
    defaultMessage: "Ongoing ({count})",
  },
  PENDING_GOALS: {
    id: "PENDING_GOALS",
    defaultMessage: "Requests ({count})",
  },
  INVITED_GOALS: {
    id: "INVITED_GOALS",
    defaultMessage: "Invited Goals",
  },
  GOAL_DETAILS_MANAGE: {
    id: "GOAL_DETAILS_MANAGE",
    defaultMessage: "Manage",
  },
  INVITE_REQUEST_GOAL: {
    id: "INVITE_REQUEST_GOAL",
    defaultMessage: "{personName} invited you to join this goal",
  },
  GOALS_LIMIT_EXCEED: {
    id: "GOALS_LIMIT_EXCEED",
    defaultMessage:
      "You’ve reached maximum limit of creating a goal. Close an old goal to create a new goal.",
  },
  REMOVE_MEMBER: {
    id: "REMOVE_MEMBER",
    defaultMessage: "Remove Member ?",
  },
  REMOVE_MEMBER_DESC_TEXT: {
    id: "REMOVE_MEMBER_DESC_TEXT",
    defaultMessage:
      "Are you sure you want to remove {memberName} from this goal?",
  },
  INTRODUCING_REWARDS_TITLE: {
    id: "INTRODUCING_REWARDS_TITLE",
    defaultMessage: "Introducing LetsGo Rewards",
  },
  INTRODUCING_REWARDS_SUBTITLE: {
    id: "INTRODUCING_REWARDS_SUBTITLE",
    defaultMessage:
      "Using the LetsGo app now brings you amazing privileges. Look for exciting rewards coming your way.",
  },
  SPIN_WHEEL_CTA: {
    id: "SPIN_WHEEL_CTA",
    defaultMessage: "Spin the wheel!",
  },
  SPIN_WHEEL_TITLE: {
    id: "SPIN_WHEEL_TITLE",
    defaultMessage: "Give the wheel",
  },
  SPIN_WHEEL_SUBTITLE: {
    id: "SPIN_WHEEL_SUBTITLE",
    defaultMessage: "A good spin!",
  },
  SPIN_WHEEL_SUBTITLE_TWO: {
    id: "SPIN_WHEEL_SUBTITLE_TWO",
    defaultMessage:
      "Brace yourself, we have an exciting reward in store for you",
  },
  REWARDS_DETAILS_SECONDARY: {
    id: "REWARDS_DETAILS_SECONDARY",
    defaultMessage: "View my rewards",
  },
  CAMERA: {
    id: "CAMERA",
    defaultMessage: "Camera",
  },
  UPLOAD_IMAGE: {
    id: "UPLOAD_IMAGE",
    defaultMessage: "Upload image",
  },
  REWARDS: {
    id: "REWARDS",
    defaultMessage: "Rewards",
  },
  REWARDS_TEXT: {
    id: "REWARDS_TEXT",
    defaultMessage: "View and claim the rewards you earned",
  },
  REWARDS_EARNED_TEXT: {
    id: "REWARDS_EARNED_TEXT",
    defaultMessage: "Earned so far",
  },
  REWARDS_EMPTY_TEXT: {
    id: "REWARDS_EMPTY_TEXT",
    defaultMessage: "No rewards to show here!",
  },
  REWARDS_EMPTY_SUB_TEXT: {
    id: "REWARDS_EMPTY_SUB_TEXT",
    defaultMessage: "Stay tuned for rewards every Friday, 5pm.",
  },
  REWARDS_COPIED_TEXT: {
    id: "REWARDS_COPIED_TEXT",
    defaultMessage: "Copied",
  },
  REWARDS_COUPON_REMOVE_HEADER_TEXT: {
    id: "REWARDS_COUPON_REMOVE_HEADER_TEXT",
    defaultMessage: "Do you want to \n remove the coupon?",
  },
  REWARDS_COUPON_REMOVE_HEADER_SUB_TEXT: {
    id: "REWARDS_COUPON_REMOVE_HEADER_SUB_TEXT",
    defaultMessage:
      "You won't be able to recover the code once \n you delete it.",
  },
  REWARDS_COUPON_REMOVE_CTA_TEXT: {
    id: "REWARDS_COUPON_REMOVE_CTA_TEXT",
    defaultMessage: "Remove the coupon",
  },
  REMAINING_DAYS_DEADLINE: {
    id: "REMAINING_DAYS_DEADLINE",
    defaultMessage: "You are {days} days past your goal deadline!",
  },
  END_DATE_DEADLINE: {
    id: "END_DATE_DEADLINE",
    defaultMessage: "End date: {endDate}",
  },
  EDIT_DATE_DEADLINE: {
    id: "EDIT_DATE_DEADLINE",
    defaultMessage: "Edit goal to extend your end date.",
  },
  EDIT_GOAL: {
    id: "EDIT_GOAL",
    defaultMessage: "Edit goal",
  },
  INVITE_MEMBERS_TEXT: {
    id: "INVITE_MEMBERS_TEXT",
    defaultMessage: "Invite members",
  },
  INVITE_MEMBERS_SUBTEXT: {
    id: "INVITE_MEMBERS_SUBTEXT",
    defaultMessage: "You can invite upto {count} friends",
  },
  EDIT_GOAL_BALANCE: {
    id: "EDIT_GOAL_BALANCE",
    defaultMessage:
      "Amount cannot be under your goal balance: {currency} {amount}",
  },
  APPROVE_PROMPT: {
    id: "APPROVE_PROMPT",
    defaultMessage: "You will receive a prompt to approve this payment",
  },
  PAYMENT_APPROVAL_TEXT: {
    id: "PAYMENT_APPROVAL_TEXT",
    defaultMessage: "Payment approval pending",
  },
  DIAL_CODE: {
    id: "DIAL_CODE",
    defaultMessage: "*170#",
  },
  APPROVE_NAVIGATION: {
    id: "APPROVE_NAVIGATION",
    defaultMessage: "My Wallet>> My approvals",
  },
  ADD_FUNDS_GOALS_ADDTO: {
    id: "ADD_FUNDS_GOALS_ADDTO",
    defaultMessage: "Add to",
  },
  GOAL_DETAILS_TOTAL_FUNDS_ADDED: {
    id: "GOAL_DETAILS_TOTAL_FUNDS_ADDED",
    defaultMessage: "Total funds added",
  },
  GOAL_DETAILS_TOTAL_WITHDRAWALS: {
    id: "GOAL_DETAILS_TOTAL_WITHDRAWALS",
    defaultMessage: "Total withdrawals",
  },
  GOAL_DETAILS_BALANCE: {
    id: "GOAL_DETAILS_BALANCE",
    defaultMessage: "Goal balance",
  },
  MEMBER_ACTIVITY_LABLE: {
    id: "MEMBER_ACTIVITY_LABLE",
    defaultMessage: "Member activity",
  },
  NO_ACTIVITY: {
    id: "NO_ACTIVITY",
    defaultMessage: "No activity yet",
  },
  NO_ACTIVITY_SUB: {
    id: "NO_ACTIVITY_SUB",
    defaultMessage: "You can begin viewing activity when members add funds",
  },
  ADD_MINI_GOAL: {
    id: "ADD_MINI_GOAL",
    defaultMessage: "Add mini goal",
  },
  MINI_GOAL_SUBHEADING: {
    id: "MINI_GOAL_SUBHEADING",
    defaultMessage: "Create and track smaller goals within your main goal",
  },
  ADD_CTA: {
    id: "ADD_CTA",
    defaultMessage: "Add",
  },
  EDIT_MINI_GOAL: {
    id: "EDIT_MINI_GOAL",
    defaultMessage: "Edit mini goal",
  },
  DELETE_CTA: {
    id: "DELETE_CTA",
    defaultMessage: "Delete",
  },
  DELETE_MINI_GOAL_TITLE: {
    id: "DELETE_MINI_GOAL_TITLE",
    defaultMessage: "Delete Mini-Goal ‘{title}’?",
  },
  DELETE_MINI_GOAL_SUBTITLE: {
    id: "DELETE_MINI_GOAL_SUBTITLE",
    defaultMessage:
      "{currency} {amount} will be added to your main goal balance",
  },
  MINI_GOAL_TITLE: {
    id: "MINI_GOAL_TITLE",
    defaultMessage: "Mini Goals",
  },
  MINI_GOAL_SUBTITLE: {
    id: "MINI_GOAL_SUBTITLE",
    defaultMessage: "Total funds collected",
  },
  COMMON_YOU: {
    id: "COMMON_YOU",
    defaultMessage: "You",
  },
  COMMON_ADMIN: {
    id: "COMMON_ADMIN",
    defaultMessage: "Admin",
  },
  MINI_GOALS_SUB_HEADER: {
    id: "MINI_GOALS_SUB_HEADER",
    defaultMessage: "Add funds for specific needs within the goal",
  },
  MINI_GOAL_LIMIT_TEXT: {
    id: "MINI_GOAL_LIMIT_TEXT",
    defaultMessage:
      "You’ve reached the maximum limit of creating mini-goals. Delete a mini-goal if you wish to create a new one.",
  },
  NO_GOALS_INVITED: {
    id: "NO_GOALS_INVITED",
    defaultMessage: "No invited goals yet!",
  },
  NO_INVITED_GOAL_SUB_TEXT: {
    id: "NO_INVITED_GOAL_SUB_TEXT",
    defaultMessage:
      "They will appear here when a friend invites you to join their goal",
  },
  MONEY_MANAGE: {
    id: "MONEY_MANAGE",
    defaultMessage: "Money Manager",
  },
  MONEY_MANAGE_SUB_HEAD: {
    id: "MONEY_MANAGE_SUB_HEAD",
    defaultMessage: "View and analyse your transaction activity",
  },
  DAILY: {
    id: "DAILY",
    defaultMessage: "Daily",
  },
  WEEKLY: {
    id: "WEEKLY",
    defaultMessage: "Weekly",
  },
  MONTHLY: {
    id: "MONTHLY",
    defaultMessage: "Monthly",
  },
  QUARTERLY: {
    id: "QUARTERLY",
    defaultMessage: "Quarterly",
  },
  INCOMING_OUTGOING_LABEL: {
    id: "INCOMING_OUTGOING_LABLE",
    defaultMessage: "Incoming and Outgoing",
  },
  ACTIVITY_TIME_FRAME: {
    id: "ACTIVITY_TIME_FRAME",
    defaultMessage: "Activity during {timestamp}",
  },
  EXPENSE_TRACKER_LABEL: {
    id: "EXPENSE_TRACKER_LABEL",
    defaultMessage: "Expense tracker",
  },
  EXPENSE_TRACKER_SUB_LABEL: {
    id: "EXPENSE_TRACKER_SUB_LABEL",
    defaultMessage: "Track your monthly expenses",
  },
  WEEKLY_CALCULATION_MSG: {
    id: "WEEKLY_CALCULATION_MSG",
    defaultMessage: "Week is calculated from Monday to Sunday",
  },
  WICODE: {
    id: "WICODE",
    defaultMessage: "Wicode",
  },
  WICode_HEADER_LINE_ONE: {
    id: "WICode_HEADER_LINE_ONE",
    defaultMessage: "Start transacting",
  },
  WICode_HEADER_LINE_SECOND: {
    id: "WICode_HEADER_LINE_SECOND",
    defaultMessage: "With wicode!",
  },
  WICODE_DESC_TEXT: {
    id: "WICODE_DESC_TEXT",
    defaultMessage:
      "Deposit, withdraw and send funds via wicode within seconds.",
  },
  WICODE_GENERATE_CODE: {
    id: "WICODE_GENERATE_CODE",
    defaultMessage: "Generate wicode",
  },
  WICODE_SHARE_CODE: {
    id: "WICODE_SHARE_CODE",
    defaultMessage: "Share wicode",
  },
  WICODE_AUTH_TRANSACTION: {
    id: "WICODE_AUTH_TRANSACTION",
    defaultMessage: "Redeem Wicode",
  },
  WICODE_CHOOSE_TRANSACTION_TYPE: {
    id: "WICODE_CHOOSE_TRANSACTION_TYPE",
    defaultMessage: "Choose type of transaction",
  },
  WICODE_CASH_IN: {
    id: "WICODE_CASH_IN",
    defaultMessage: "Cash-in",
  },
  WICODE_CASH_OUT: {
    id: "WICODE_CASH_OUT",
    defaultMessage: "Cash-out",
  },
  WICODE_PAY_TO_MERCHENT: {
    id: "WICODE_PAY_TO_MERCHENT",
    defaultMessage: "Pay to merchant",
  },
  WICODE_GENERATE_INFO_TEXT: {
    id: "WICODE_GENERATE_INFO_TEXT",
    defaultMessage: "Click on generate to get a wicode",
  },
  WICODE_GENERATE_BUTTON_TEXT: {
    id: "WICODE_GENERATE_BUTTON_TEXT",
    defaultMessage: "Generate",
  },
  INCOMING_AVERAGE: {
    id: "INCOMING_AVERAGE",
    defaultMessage: "Incoming Average",
  },
  OUTGOING_AVERAGE: {
    id: "OUTGOING_AVERAGE",
    defaultMessage: "Outgoing Average",
  },
  INCOMING_AVERAGE_VALUE: {
    id: "INCOMING_AVERAGE_VALUE",
    defaultMessage: "Incoming Avg. {currency} {value}",
  },
  OUTGOING_AVERAGE_VALUE: {
    id: "OUTGOING_AVERAGE_VALUE",
    defaultMessage: "Outgoing Avg. {currency} {value}",
  },
  NOTHING_TO_SEE_YET: {
    id: "NOTHING_TO_SEE_YET",
    defaultMessage: "Nothing to see yet!",
  },
  NOTHING_TO_SEE_YET_SUBTEXT: {
    id: "NOTHING_TO_SEE_YET_SUBTEXT",
    defaultMessage:
      "Track your incoming and outgoing as and when you make transactions using your LetsGo wallet",
  },
  GROUPS: {
    id: "GROUPS",
    defaultMessage: "Groups",
  },
  NO_GROUPS: {
    id: "NO_GROUPS",
    defaultMessage: "No groups yet",
  },
  NO_GROUPS_SUB_TITLE: {
    id: "NO_GROUPS_SUB_TITLE",
    defaultMessage:
      "If you need to split rent, trip or other expenses with more than one person, you can split the bill, create a group, and keep track of who’s paid.",
  },
  RECENT_GROUPS: {
    id: "RECENT_GROUPS",
    defaultMessage: "Recent Groups",
  },
  NO_RESULTS: {
    id: "NO_RESULTS",
    defaultMessage: "No results found",
  },
  NO_RESULTS_SUB_TITLE: {
    id: "NO_RESULTS_SUB_TITLE",
    defaultMessage:
      "We could not find anyone with the mobile number {mobileNumber}. Please enter a valid mobile number to request funds. ",
  },

  ASK_FOR_FUNDS: {
    id: "ASK_FOR_FUNDS",
    defaultMessage: "Receive funds",
  },
  REQUEST_FUNDS: {
    id: "REQUEST_FUNDS",
    defaultMessage: "Request funds",
  },
  RECEIVE_INFO_1: {
    id: "RECEIVE_INFO_1",
    defaultMessage: "Receiving money made easier!",
  },
  RECEIVE_INFO_1_CONTENT: {
    id: "RECEIVE_INFO_1_CONTENT",
    defaultMessage: "Request funds from your fellow LetsGo wallet members",
  },
  RECEIVE_INFO_2: {
    id: "RECEIVE_INFO_2",
    defaultMessage: "Request from multiple people!",
  },
  RECEIVE_INFO_2_CONTENT: {
    id: "RECEIVE_INFO_2_CONTENT",
    defaultMessage:
      "Want to split the bill you cleared? Send payment requests to many friends at once.",
  },
  REQUEST_FROM_MULTIPLE: {
    id: "REQUEST_FROM_MULTIPLE",
    defaultMessage: "Request from multiple people",
  },
  REQUEST_EXPIRY: {
    id: "REQUEST_EXPIRY",
    defaultMessage: "Request will expire in 15 days!",
  },
  SEND_REQUEST: {
    id: "SEND_REQUEST",
    defaultMessage: "Send request",
  },
  SPLITTING_BILL_HELPER: {
    id: "SPLITTING_BILL_HELPER",
    defaultMessage: "Eg: splitting the bill from last night’s dinner",
  },
  REQUEST_FUNDS_PEOPLE: {
    id: "REQUEST_FUNDS_PEOPLE",
    defaultMessage: "Request funds from more people?",
  },
  ADD_PEOPLE: {
    id: "ADD_PEOPLE",
    defaultMessage: "Add people",
  },
  REQUESTING_FROM: {
    id: "REQUESTING_FROM",
    defaultMessage: "Requesting from",
  },
  REMOVE_PAYER: {
    id: "REMOVE_PAYER",
    defaultMessage: "Remove Payer?",
  },
  REMOVE_PAYER_CONFIRM: {
    id: "REMOVE_PAYER_CONFIRM",
    defaultMessage:
      "Are you sure you want to remove {name} from this split payment?",
  },
  DONT_REMOVE: {
    id: "DONT_REMOVE",
    defaultMessage: "Don't remove",
  },
  MOBILE_UNREGISTERED: {
    id: "MOBILE_UNREGISTERED",
    defaultMessage: "This mobile number is not registered with Letshego.",
  },
  FREQUENT_TRANSACTIONS: {
    id: "FREQUENT_TRANSACTIONS",
    defaultMessage: "Frequent transactions with the same people?",
  },
  CREATE_GROUP: {
    id: "CREATE_GROUP",
    defaultMessage: "Create a group",
  },
  ADD_GROUP_NAME: {
    id: "ADD_GROUP_NAME",
    defaultMessage: "Add a group name",
  },
  GROUP_EXISTS: {
    id: "GROUP_EXISTS",
    defaultMessage:
      "This group name already exists. Please select another name",
  },
  MEMBERS: {
    id: "MEMBERS",
    defaultMessage: "Members",
  },
  GROUP_CREATED: {
    id: "GROUP_CREATED",
    defaultMessage: "Group created successfully!",
  },
  CONFIRM_DELETE_BENEFICIARY: {
    id: "CONFIRM_DELETE_BENIFICIARY",
    defaultMessage: "Are you sure you wish to delete beneficiary added?",
  },
  SEND_FUNDS_MINIMUM_ALLOWED_AMOUNT: {
    id: "SEND_FUNDS_MINIMUM_ALLOWED_AMOUNT",
    defaultMessage: "Amount must be greater than or equal to 1",
  },
  MOBILE_NUMBER_HELPER_TEXT_ALTERNATE: {
    id: "MOBILE_NUMBER_HELPER_TEXT_ALTERNATE",
    defaultMessage: "E.g Cell :  MTN 024xxxxx Voda 020xxxxx",
  },
  SPLIT_PAYMENT: {
    id: "SPLIT_PAYMENT",
    defaultMessage: "Split payment",
  },
  SPLIT_PAYMENT_TAGLINE: {
    id: "SPLIT_PAYMENT_TAGLINE",
    defaultMessage:
      "You can now split this payment by requesting funds from multiple people",
  },
  SPLIT_PAYMENT_SPLIT_STATUS_EQUAL: {
    id: "SPLIT_PAYMENT_SPLIT_STATUS_EQUAL",
    defaultMessage:
      "{currencySymbol} {amount} of {amount} split equally.{balanceAmount}",
  },
  SPLIT_PAYMENT_SPLIT_STATUS_UNEQUAL: {
    id: "SPLIT_PAYMENT_SPLIT_STATUS_UNEQUAL",
    defaultMessage:
      "{currencySymbol} {amount} of {amount} split unequally.{balanceAmount}",
  },
  SPLIT_PAYMENT_HIGH_AMOUNT_ERROR: {
    id: "SPLIT_PAYMENT_HIGH_AMOUNT_ERROR",
    defaultMessage:
      "The amount entered is higher than the total amount. Balance:  -{currencySymbol} {balanceAmount}",
  },
  SPLIT_PAYMENT_VALUE_DONT_ADD: {
    id: "SPLIT_PAYMENT_VALUE_DONT_ADD",
    defaultMessage: "The values don't add up.",
  },
  FIXED_DATA_PACKAGE: {
    id: "FIXED_DATA_PACKAGE",
    defaultMessage: "Fixed Data Package",
  },
  TRANSACTION_FEE_MESSAGE_SUCCESS: {
    id: "TRANSACTION_FEE_MESSAGE_SUCCESS",
    defaultMessage:
      "Note: A small transaction fee of {currencySymbol} {fees} has been charged",
  },
  EDIT_BENEFICIARY: {
    id: "EDIT_BENEFICIARY",
    defaultMessage: "Edit beneficiary",
  },
  DELETE_BENEFICIARY: {
    id: "DELETE_BENEFICIARY",
    defaultMessage: "Delete beneficiary",
  },
  EDIT_BENEFICIARY_CTA_SUB: {
    id: "EDIT_BENEFICIARY_CTA_SUB",
    defaultMessage: "Are you sure you wish to \n edit beneficiary? ",
  },
  EDIT_BENEFICIARY_DETAILS: {
    id: "EDIT_BENEFICIARY_DETAILS",
    defaultMessage: "Edit beneficiary details",
  },
  UPDATE_BENEFICIARY_SUCCESS: {
    id: "UPDATE_BENEFICIARY_SUCCESS",
    defaultMessage:
      "Beneficiary details of {fullName} has been successfully edited.",
  },
  UPDATE_BENEFICIARY_FAILURE: {
    id: "UPDATE_BENEFICIARY_FAILURE",
    defaultMessage:
      "Beneficiary details of {fullName} could not be editted. Please try again.",
  },
  SELECT_USER_SEND_REMINDER: {
    id: "SELECT_USER_SEND_REMINDER",
    defaultMessage: "Select members to send reminder",
  },
  SELECT_USER_CANCEL_REMINDER: {
    id: "SELECT_USER_CANCEL_REMINDER",
    defaultMessage: "Select members for whom you wish to cancel request.",
  },
  SELFIE_WITH_NATIONAL_ID: {
    id: "SELFIE_WITH_NATIONAL_ID",
    defaultMessage: "Selfie with { documentType }",
  },
  ACCOUNT_BALANCE: {
    id: "ACCOUNT_BALANCE",
    defaultMessage: "Account balance",
  },
  ACCOUNT_NUMBER_MASK: {
    id: "ACCOUNT_NUMBER_MASK",
    defaultMessage: "A/c No. {accountNumber}",
  },
  VIEW_STATEMENT: {
    id: "VIEW_STATEMENT",
    defaultMessage: "View statement",
  },
  LETSHEGO_BANK: {
    id: "LETSHEGO_BANK",
    defaultMessage: "Letshego Bank",
  },
  WITHDRAW_FUNDS_GOALS_WITHDRAWFROM: {
    id: "WITHDRAW_FUNDS_GOALS_WITHDRAWFROM",
    defaultMessage: "Withdraw from",
  },
  WITHDRAW_FUNDS_MINIGOAL_MESSAGE: {
    id: "WITHDRAW_FUNDS_MINIGOAL_MESSAGE",
    defaultMessage:
      'has been withdrawn from your mini-goal " {miniGoalTitle} " successfuly!',
  },
  GOAL_TARGET_LABEL: {
    id: "GOAL_TARGET_LABEL",
    defaultMessage: "Goal Target",
  },

  REGISTERED_NUMBER_INACTIVE: {
    id: "REGISTERED_NUMBER_INACTIVE",
    defaultMessage:
      "If the nummber registered with national ID is currentely inactive, contact out {link}",
  },
  SELECT_ID_TEXT: {
    id: "SELECT_ID_TEXT",
    defaultMessage: "Please select ID",
  },
  INSUFFICIENT_FUNDS_AFTER_FEE: {
    id: "INSUFFICIENT_FUNDS_AFTER_FEE",
    defaultMessage:
      "We are unable to complete the transaction due to insufficient funds. Please add {link} and retry",
  },
  ADD_FUNDS_SMALL_CASE: {
    id: "ADD_FUNDS_SMALL_CASE",
    defaultMessage: "add funds",
  },
  REVIEW_LOAN_APPROVED_FOR: {
    id: "REVIEW_LOAN_APPROVED_FOR",
    defaultMessage: "You are approved for",
  },
  SUB_TEN_LOAN_REVIEW_SUBTEXT: {
    id: "SUB_TEN_LOAN_REVIEW_SUBTEXT",
    defaultMessage:
      "We’re happy to inform you that we can settle your loans. Here’s the loan break-up",
  },
  SUB_TEN_INFORMATION_TEXT: {
    id: "SUB_TEN_INFORMATION_TEXT",
    defaultMessage: "The final amount may vary based on document verification",
  },
  SUB_TEN_ADDITIONAL_CHARGES: {
    id: "SUB_TEN_ADDITIONAL_CHARGES",
    defaultMessage: "Additional charges apply for early repayment",
  },
  SUB_TEN_CTA_TEXT: {
    id: "SUB_TEN_CTA_TEXT",
    defaultMessage: "Now get a top-up instantly",
  },
  SUB_TEN_CTA_SUB_TEXT: {
    id: "SUB_TEN_CTA_SUB_TEXT",
    defaultMessage:
      "Get money disbursed to your account in less than 10 minutes.",
  },
  SUB_TEN_TERM_AND_CONDITION: {
    id: "SUB_TEN_TERM_AND_CONDITION",
    defaultMessage:
      "By checking the boxes I accept that I have read and agreed to all the {termsAndCondition} in the contract documents.",
  },
  WANT_TO_GET_A: {
    id: "WANT_TO_GET_A",
    defaultMessage: "Want to get a",
  },
  TOP_UP_QUESTION: {
    id: "TOP_UP_QUESTION",
    defaultMessage: "Top-up?",
  },
  SUB10_SUBTITLE: {
    id: "SUB10_SUBTITLE",
    defaultMessage:
      "Calculate your personalised top-up offer base on your affordability and get loan disbursed instantly.",
  },
  SUB10_LOAN_CTA: {
    id: "SUB10_LOAN_CTA",
    defaultMessage: "Get loan now",
  },
  DO_NOT_QUALIFY_SUB10: {
    id: "DO_NOT_QUALIFY_SUB10",
    defaultMessage: "You do not qualify for sub 10",
  },
  CUSTOMER_REGISTRATION_FAILED: {
    id: "CUSTOMER_REGISTRATION_FAILED",
    defaultMessage: "Customer registration failed",
  },
  SUB10_LOAN_CANCELLED: {
    id: "SUB10_LOAN_CANCELLED",
    defaultMessage:
      "Unfortunately, we are not able to process your application right now, kindly apply again after 30 minutes",
  },
  REWARD_MESSAGE_DAYS: {
    id: "REWARD_MESSAGE_DAYS",
    defaultMessage:
      "You will no longer be able to refer after 30 days from date of Signup",
  },
  REWARD_MESSAGE_AMOUNT: {
    id: "REWARD_MESSAGE_AMOUNT",
    defaultMessage: "The final amount may vary based on document verification",
  },
  LOAN_SUMMARY_REWARD_MSG: {
    id: "LOAN_SUMMARY_REWARD_MSG",
    defaultMessage: "If you were helped by a Letshego staff / agent",
  },
  DUPLICATE_RECORD: {
    id: "DUPLICATE_RECORD",
    defaultMessage: "The referral code already exist",
  },
  BALANCE_SHORT: {
    id: "BALANCE_SHORT",
    defaultMessage: "Bal",
  },
  INVITE_VIA_SMS_HEADER: {
    id: "INVITE_VIA_SMS_HEADER",
    defaultMessage: "Invite via SMS",
  },
  INVITE_VIA_EMAIL_HEADER: {
    id: "INVITE_VIA_EMAIL_HEADER",
    defaultMessage: "Invite via Email",
  },
  INVITE_VIA_WHATSAPP_HEADER: {
    id: "INVITE_VIA_WHATSAPP_HEADER",
    defaultMessage: "Invite via WhatsApp",
  },
  INVITE_VIA_SMS_BODY: {
    id: "INVITE_VIA_SMS_BODY",
    defaultMessage:
      "You can invite your peers to download the app by sending them a SMS",
  },
  INVITE_VIA_EMAIL_BODY: {
    id: "INVITE_VIA_EMAIL_BODY",
    defaultMessage:
      "You can invite your peers to download the app by sending them an email",
  },
  INVITE_VIA_WHATSAPP_BODY: {
    id: "INVITE_VIA_WHATSAPP_BODY",
    defaultMessage:
      "You can invite your peers to download the app by sending them a WhatsApp",
  },
  LETSGO_INVITE_TEXT: {
    id: "LETSGO_INVITE_TEXT",
    defaultMessage:
      "Hey, I am inviting you to use LetsGo wallet, a simple and secure payments service by LetsGo. Make your life with LetsGo wallet by transacting with peer, making bill payments and much more. Download the app now at {app_link}",
  },
  SEND_INVITE: {
    id: "SEND_INVITE",
    defaultMessage: "Send invite",
  },
  REQUEST_SEND_SUCCESSFULLY: {
    id: "REQUEST_SEND_SUCCESSFULLY",
    defaultMessage: "Request was sent successfully",
  },
  FAILURE_SENDING_REQUEST: {
    id: "FAILURE_SENDING_REQUEST",
    defaultMessage: "Failure while sending request",
  },
  TO_BUSINESSES: {
    id: "TO_BUSINESSES",
    defaultMessage: "To Businesses",
  },
  MERCHANT_SEARCH_UTILITY: {
    id: "MERCHANT_SEARCH_UTILITY",
    defaultMessage: "Search by Merchant name",
  },
  MERCHANTS_LIST_LABEL: {
    id: "MERCHANTS_LIST_LABEL",
    defaultMessage: "Merchant list",
  },
  NO_MERCHANT_FOUND: {
    id: "NO_MERCHANT_FOUND",
    defaultMessage: "No merchant found!",
  },
  NO_SAVED_BUSINESS_TEXT: {
    id: "NO_SAVED_BUSINESS_TEXT",
    defaultMessage:
      "Start making payments and \n save your favourite businesses here!",
  },
  SAVE_MERCHANT: {
    id: "SAVE_MERCHANT",
    defaultMessage: "Save this business for future transactions",
  },
  TERMINAL_SEND_FUNDS: {
    id: "TERMINAL_SEND_FUNDS",
    defaultMessage: "Enter terminal ID to send funds",
  },
  TERMINAL_ID: {
    id: "TERMINAL_ID",
    defaultMessage: "Terminal ID",
  },
  DIAL_CODE_TEXT: {
    id: "DIAL_CODE_TEXT",
    defaultMessage:
      "An authorization prompt has been sent to your phone ({phoneNumber}). {br}Kindly authorize and click on OK when completed. {br}If you cannot see the prompt after 30 seconds, Dial {ussd} Go to My Wallet>> My approvals to approve payment immediately.",
  },
  DYNAMIC_NATIONAL_ID_WITH_SELFIE_ID_UPLOAD_DESC: {
    id: "DYNAMIC_NATIONAL_ID_WITH_SELFIE_ID_UPLOAD_DESC",
    defaultMessage: "Upload your selfie with your {documentType}",
  },
  DYNAMIC_NATIONAL_ID_UPLOAD_DESC: {
    id: "DYNAMIC_NATIONAL_ID_UPLOAD_DESC",
    defaultMessage: "Upload your {documentType} front and back.",
  },
  WELLNESS_CTA: {
    id: "WELLNESS_CTA",
    defaultMessage: "Visit our wellbeing section now",
  },
  WELLNESS_SUBTEXT: {
    id: "WELLNESS_SUBTEXT",
    defaultMessage:
      "Get access to exclusive and comprehensive wellbeing content!",
  },
  SYNTROP_TITLE: {
    id: "SYNTROP_TITLE",
    defaultMessage: "Syntrop Wellbeing",
  },
  WELLNESS_CTA_HEADING: {
    id: "WELLNESS_CTA_HEADING",
    defaultMessage: "Want to enhance your wellbeing?",
  },
  SAVINGS_HOME_TITLE: {
    id: "SAVINGS_HOME_TITLE",
    defaultMessage: "Explore all our Savings products",
  },
  SAVINGS_TRACK_APPLICATION_MORE_INFO: {
    id: "SAVINGS_TRACK_APPLICATION_MORE_INFO",
    defaultMessage:
      "To support your application, we may need more information from you. We will keep you informed.",
  },
  SAVINGS_TRACK_APPLICATION_ACTIONS_REQUIRED_TEXT: {
    id: "SAVINGS_TRACK_APPLICATION_ACTIONS_REQUIRED_TEXT",
    defaultMessage:
      "Dear customer, our commitment is to give you an outstanding experience at all times.",
  },
  SAVINGS_TRACK_APPLICATION_ACTIONS_REQUIRED_TEXT_2: {
    id: "SAVINGS_TRACK_APPLICATION_ACTIONS_REQUIRED_TEXT_2",
    defaultMessage:
      "Please visit the nearest branch with your documents for verification.",
  },
  HOME_WELCOME_SUBTITLE: {
    id: "HOME_WELCOME_SUBTITLE",
    defaultMessage: "It’s a sunny day",
  },
  WELLNESS_CTA_LINK_TEXT: {
    id: "WELLNESS_CTA_LINK_TEXT",
    defaultMessage: "Visit our wellbeing section now",
  },
  WELLNESS_REDIRECTING_YOU: {
    id: "WELLNESS_REDIRECTING_YOU",
    defaultMessage: "You’re about to enter the",
  },
  WELLNESS_SAFELY: {
    id: "WELLNESS_SAFELY",
    defaultMessage: "world of wellbeing",
  },
  WELLNESS_REDIRECT_TEXT: {
    id: "WELLNESS_REDIRECT_TEXT",
    defaultMessage:
      "Please wait while we take you safely to Syntro-P Wellbeing. ",
  },
  EXPIRED: { id: "EXPIRED", defaultMessage: "Expired" },
  TRANSACTION_HISTORY_TAB_PENDING: {
    id: "TRANSACTION_HISTORY_TAB_PENDING",
    defaultMessage: "Pending Requests",
  },
  SAVED_MERCHANT_LABEL: {
    id: "SAVED_MERCHANT_LABEL",
    defaultMessage: "Saved merchants",
  },
  DELETE_MERCHANT_LABEL: {
    id: "DELETE_MERCHANT_LABEL",
    defaultMessage: "Delete merchant",
  },
  DELETE_MERCHANT_SUB_LABEL: {
    id: "DELETE_MERCHANT_SUB_LABEL",
    defaultMessage: "Are you sure you wish to delete \n merchant added?",
  },
  TERMINAL_ID_LABEL: {
    id: "TERMINAL_ID_LABEL",
    defaultMessage: "Terminal ID:",
  },

  PASSPORT_UPLOAD_DESC_EXT: {
    id: "PASSPORT_UPLOAD_DESC_EXT",
    defaultMessage: "Upload your Passport",
  },
  VOTER_ID_UPLOAD_DESC_EXT: {
    id: "VOTER_ID_UPLOAD_DESC_EXT",
    defaultMessage: "Upload Voter ID",
  },
  NUIT_ID_LABEL: {
    id: "NUIT_ID_LABEL",
    defaultMessage: "NUIT ID",
  },
  NUIT_NUMBER_LABEL: {
    id: "NUIT_NUMBER_LABEL",
    defaultMessage: "NUIT Number",
  },
  DASHBOARD_HOME_WELCOME_TITLE1: {
    id: "DASHBOARD_HOME_WELCOME_TITLE1",
    defaultMessage: "What you",
  },
  DASHBOARD_HOME_WELCOME_TITLE2: {
    id: "DASHBOARD_HOME_WELCOME_TITLE2",
    defaultMessage: "Stand to Gain",
  },
  HOME_DASHBOARD_WELCOME_TITLE3: {
    id: "HOME_DASHBOARD_WELCOME_TITLE3",
    defaultMessage:
      "Explore our amazing product offerings from our bouquet of products & services.",
  },
  HOME_DASHBOARD_WELCOME_TEXT_1: {
    id: "HOME_DASHBOARD_WELCOME_TEXT_1",
    defaultMessage: "Get amazing loan offering.",
  },
  HOME_DASHBOARD_WELCOME_TEXT_2: {
    id: "HOME_DASHBOARD_WELCOME_TEXT_2",
    defaultMessage: "Get more from the market place.",
  },
  HOME_DASHBOARD_WELCOME_TEXT_3: {
    id: "HOME_DASHBOARD_WELCOME_TEXT_3",
    defaultMessage: "Get great insurance offers.",
  },
  HOME_DASHBOARD_WELCOME_TEXT_4: {
    id: "HOME_DASHBOARD_WELCOME_TEXT_4",
    defaultMessage: "Enhance your wellbeing.",
  },
  LETSGO_RECRUITMENT: {
    id: "LETSGO_RECRUITMENT",
    defaultMessage: "Recruitment",
  },
  NIMC_LABEL: {
    id: "NIMC_LABEL",
    defaultMessage: "NMIC number",
  },
  SELECT_DOCUMENT_LABEL: {
    id: "SELECT_DOCUMENT_LABEL",
    defaultMessage: "Select a document",
  },
  CURRENT_RESIDENCE_LABEL: {
    id: "CURRENT_RESIDENCE_LABEL",
    defaultMessage: "Current residence",
  },
  SAVINGS_FUND_TRANSFER_CREDIT_MSG: {
    id: "SAVINGS_FUND_TRANSFER_CREDIT_MSG",
    defaultMessage: "Add funds to flexi save account from",
  },
  SAVINGS_FUND_TRANSFER_DEBIT_MSG: {
    id: "SAVINGS_FUND_TRANSFER_DEBIT_MSG",
    defaultMessage: "Withdraw funds from flexi save account to",
  },
  SAVINGS_ACCOUNT_LABEL: {
    id: "SAVINGS_ACCOUNT_LABEL",
    defaultMessage: "FlexiSave",
  },
  SAVINGS_ACCOUNT_ACCORDION_TITLE: {
    id: "SAVINGS_ACCOUNT_ACCORDION_TITLE",
    defaultMessage: "account",
  },
  SAVINGS_FLEXISAVE_LABEL: {
    id: "SAVINGS_FLEXISAVE_LABEL",
    defaultMessage: "FlexiSave",
  },
  SAVINGS_WELCOME_LABEL: {
    id: "SAVINGS_WELCOME_LABEL",
    defaultMessage: "Welcome",
  },
  SAVINGS_CTA_LABEL: {
    id: "SAVINGS_CTA_LABEL",
    defaultMessage: "Open a FlexiSave account instantly!",
  },
  WICODE_NEW_GENERATE_CODE: {
    id: "WICODE_NEW_GENERATE_CODE",
    defaultMessage: "Generate a new wicode",
  },
  WICODE_SHARE_WITH_MERCHANT: {
    id: "WICODE_SHARE_WITH_MERCHANT",
    defaultMessage: "Share with merchant",
  },
  ABOUT_YOUR_BUSINESS: {
    id: "ABOUT_YOUR_BUSINESS",
    defaultMessage: "About your business",
  },
  BUSINESS_NAME: {
    id: "BUSINESS_NAME",
    defaultMessage: "Business name",
  },
  BUSINESS_SECTOR: {
    id: "BUSINESS_SECTOR",
    defaultMessage: "Business sector",
  },
  BUSINESS_ADDRESS: {
    id: "BUSINESS_ADDRESS",
    defaultMessage: "Business address",
  },
  YEARS_OF_OPERATION: {
    id: "YEARS_OF_OPERATION",
    defaultMessage: "No. of years of operation",
  },
  ANNUAL_TURNOVER: {
    id: "ANNUAL_TURNOVER",
    defaultMessage: "Annual turn over",
  },
  CONFIRM_PAGE_COMPLETE: {
    id: "CONFIRM_PAGE_COMPLETE",
    defaultMessage: "Complete",
  },
  CONFIRM_PAGE_YOUR_DETAILS: {
    id: "CONFIRM_PAGE_YOUR_DETAILS",
    defaultMessage: "Your details!",
  },
  BUSINESS_CERTIFICATE: {
    id: "BUSINESS_CERTIFICATE",
    defaultMessage: "Business certificate",
  },
  IMAGE_MSE: {
    id: "IMAGE_MSE",
    defaultMessage: "Upload Photo",
  },
  YOUR_DOCUMENTS: {
    id: "YOUR_DOCUMENTS",
    defaultMessage: "Your documents",
  },
  MSE_PURCHASE_ORDER: {
    id: "MSE_PURCHASE_ORDER",
    defaultMessage: "MSE Purchase Order",
  },
  SOURCE_OF_FUNDS: {
    id: "SOURCE_OF_FUNDS",
    defaultMessage: "Source of funds",
  },
  PURPOSE_OF_FUNDS: {
    id: "PURPOSE_OF_FUNDS",
    defaultMessage: "Purpose of funds",
  },
  MSE_STEP_ONE_HEADING: {
    id: "MSE_STEP_ONE_HEADING",
    defaultMessage: "It is nice having you here",
  },
  MSE_STEP_ONE_DESCRIPTION: {
    id: "MSE_STEP_ONE_DESCRIPTION",
    defaultMessage:
      "Some few questions to help calculate a special offer only for you",
  },
  PLAN_FOR_FUNDS: {
    id: "PLAN_FOR_FUNDS",
    defaultMessage: "Where do you plan to use the funds",
  },
  SUPPLIER_USED_BEFORE: {
    id: "SUPPLIER_USED_BEFORE",
    defaultMessage: "Have you used these suppliers before?",
  },
  PROOF_OF_PAYMENT: {
    id: "PROOF_OF_PAYMENT",
    defaultMessage: "Proof of payment",
  },
  PROOF_OF_PAYMENT_UPLOAD_DESC: {
    id: "PROOF_OF_PAYMENT_UPLOAD_DESC",
    defaultMessage: "Proof of payment, Invoice or receipt",
  },
  BOARD_RESOLUTION: {
    id: "BOARD_RESOLUTION",
    defaultMessage: "Board Resolution",
  },
  BOARD_RESOLUTION_UPLOAD_DESC: {
    id: "BOARD_RESOLUTION_UPLOAD_DESC",
    defaultMessage: "Upload your company Board Resolution",
  },
  SUPPLIER_QUOTATION: {
    id: "SUPPLIER_QUOTATION",
    defaultMessage: "Quotation from Supplier",
  },
  SUPPLIER_QUOTATION_UPLOAD_DESC: {
    id: "SUPPLIER_QUOTATION_UPLOAD_DESC",
    defaultMessage: "Upload the supplier quotations",
  },
  PURCHASE_ORDER_CERTIFICATE: {
    id: "PURCHASE_ORDER_CERTIFICATE",
    defaultMessage: "Purchase Order Certificate",
  },
  PURCHASE_ORDER_CERTIFICATE_UPLOAD_DESC: {
    id: "PURCHASE_ORDER_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Upload your Purchase Order certificate",
  },
  MSE_STEP_TWO_HEADING_1: {
    id: "MSE_STEP_TWO_HEADING_1",
    defaultMessage: "Few documents",
  },
  MSE_STEP_TWO_HEADING_2: {
    id: "MSE_STEP_TWO_HEADING_2",
    defaultMessage: "We need",
  },
  MSE_STEP_TWO_DESCRIPTION: {
    id: "MSE_STEP_TWO_DESCRIPTION",
    defaultMessage:
      "Your file should be in .pdf, .jpeg, .jpg, .png formats with less than 10 MB size",
  },
  INCOME_TAX_CERTIFICATE_UPLOAD_DESC: {
    id: "INCOME_TAX_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Upload your tax clearance documents",
  },
  GENERATE_STATEMENT: {
    id: "GENERATE_STATEMENT",
    defaultMessage: "Generate statement",
  },
  GENERATE_STATEMENT_TITLE: {
    id: "GENERATE_STATEMENT_TITLE",
    defaultMessage: "Generate statements of transactions you have made",
  },
  TRANSACTION_TYPE: {
    id: "TRANSACTION_TYPE",
    defaultMessage: "Transaction type",
  },
  TO: {
    id: "TO",
    defaultMessage: "To",
  },
  FROM: {
    id: "FROM",
    defaultMessage: "From",
  },
  EMAIL_STATEMENT: {
    id: "EMAIL_STATEMENT",
    defaultMessage: "Email Statement",
  },
  DOWNLOAD_STATEMENT: {
    id: "DOWNLOAD_STATEMENT",
    defaultMessage: "Download statement",
  },
  DURATION: {
    id: "DURATION",
    defaultMessage: "Duration",
  },
  CURRENT_MONTH: {
    id: "CURRENT_MONTH",
    defaultMessage: "Current month",
  },
  LAST_THREE_MONTH: {
    id: "LAST_THREE_MONTH",
    defaultMessage: "Last 3 months",
  },
  LAST_SIX_MONTH: {
    id: "LAST_SIX_MONTHS",
    defaultMessage: "Last six months",
  },
  CURRENT_YEAR: {
    id: "CURRENT_YEAR",
    defaultMessage: "Current year",
  },
  CUSTOM_RANGE: {
    id: "CUSTOM_RANGE",
    defaultMessage: "Custom range",
  },
  GENERATING_PDF: {
    id: "GENERATING_PDF",
    defaultMessage:
      "We are generating your statement date from { dateFrom } to {dateTo} of {transactionType} transactions.",
  },
  GENERATED_PDF: {
    id: "GENERATED_PDF",
    defaultMessage:
      "Statement has been generated date from {dateFrom} to {dateTo} of {transactionType} transactions.",
  },
  RECEIVED_BY: {
    id: "RECEIVED_BY",
    defaultMessage: "Received by",
  },
  PAID_TO: {
    id: "PAID_ID",
    defaultMessage: "Paid to",
  },
  TRANSACTIONS: {
    id: "TRANSACTIONS",
    defaultMessage: "transactions",
  },
  ADD_ANOTHER_NEXT_OF_KIN_LABEL: {
    id: "ADD_ANOTHER_NEXT_OF_KIN_LABEL",
    defaultMessage: "Add another next of kin",
  },
  WITHDRAW: {
    id: "WITHDRAW",
    defaultMessage: "Withdraw",
  },
  NO_TRANSACTIONS_FOUND: {
    id: "NO_TRANSACTIONS_FOUND",
    defaultMessage: "No Transactions found!",
  },
  LATEST_TRANSACTIONS: {
    id: "LATEST_TRANSACTIONS",
    defaultMessage: "Latest transactions",
  },
  MY_SAVINGS_ACCOUNT: {
    id: "MY_SAVINGS_ACCOUNT",
    defaultMessage: "My Savings Account",
  },
  AVAILABLE_BALANCE: {
    id: "AVAILABLE_BALANCE",
    defaultMessage: "Available balance",
  },
  SELECT_PAYMENT_METHOD: {
    id: "SELECT_PAYMENT_METHOD",
    defaultMessage: "Select payment method",
  },
  SAVINGS_BALANCE_API_FAIL: {
    id: "SAVINGS_BALANCE_API_FAIL",
    defaultMessage:
      "Your available balance is N$ 0. Please add funds to your account to start saving.",
  },
  SAVINGS_INTEREST_RATE_TITLE: {
    id: "SAVINGS_INTEREST_RATE_TITLE",
    defaultMessage: "Interest rates are shown below:",
  },
  SAVINGS_OPEN_ACCOUNT: {
    id: "SAVINGS_OPEN_ACCOUNT",
    defaultMessage: "Open account",
  },
  SAVINGS_ACCOUNT_TITLE: {
    id: "SAVINGS_ACCOUNT_TITLE",
    defaultMessage: "Grow your savings with flexible deposits",
  },
  SAVINGS_MONTHLY_FEE_TITLE: {
    id: "SAVINGS_MONTHLY_FEE_TITLE",
    defaultMessage: "No monthly service fee",
  },
  SAVINGS_WITHDRAWAL_TITLE: {
    id: "SAVINGS_WITHDRAWAL_TITLE",
    defaultMessage: "First withdrawal in a month is free",
  },
  SAVINGS_RATES_TITLE: {
    id: "SAVINGS_RATES_TITLE",
    defaultMessage: "Interest rates up to 3.75%",
  },
  BANK_ACCOUNT_NUMBER_LABEL: {
    id: "BANK_ACCOUNT_NUMBER_LABEL",
    defaultMessage: "Bank Account Number",
  },
  BANK_BRANCH_CODE: {
    id: "BANK_BRANCH_CODE",
    defaultMessage: "Bank Branch Code",
  },
  UPLOAD_DOCS_TAB_CONSOLIDATION: {
    id: "UPLOAD_DOCS_TAB_CONSOLIDATION",
    defaultMessage: "Would You like to consolidate your loans?",
  },
  LATEST: { id: "LATEST", defaultMessage: "Latest" },
  BLOG_POST: { id: "BLOG_POST", defaultMessage: "Blog post" },
  WHAT_WE_OFFER: {
    id: "WHAT_WE_OFFER",
    defaultMessage: "What we offer",
  },
  PRODUCT_OFFERING_TEXT1: {
    id: "PRODUCT_OFFERING_TEXT1",
    defaultMessage: "You our customers",
  },
  PRODUCT_OFFERING_TEXT2: {
    id: "PRODUCT_OFFERING_TEXT2",
    defaultMessage:
      "You can do so much on the digital mall, it’s all within your grasp.",
  },
  MAKE_PAYMENTS: {
    id: "MAKE_PAYMENTS",
    defaultMessage: "Make Payments",
  },
  MAKE_PAYMENTS_TEXT: {
    id: "MAKE_PAYMENTS_TEXT",
    defaultMessage: "Make transactions from anywhere in the world.",
  },
  SAVE_TOWARDS_A_GOAL: {
    id: "SAVE_TOWARDS_A_GOAL",
    defaultMessage: "Save towards a goal",
  },
  SAVE_TOWARDS_A_GOAL_TEXT: {
    id: "SAVE_TOWARDS_A_GOAL_TEXT",
    defaultMessage: "Keep something for the future you.",
  },
  BORROW_FUNDS: {
    id: "BORROW_FUNDS",
    defaultMessage: "Borrow Funds",
  },
  BORROW_FUNDS_TEXT: {
    id: "BORROW_FUNDS_TEXT",
    defaultMessage: "Gets funds to cover for important emergencies",
  },
  INSURE_EVERYTHING: {
    id: "INSURE_EVERYTHING",
    defaultMessage: "Insure everything",
  },
  INSURE_EVERYTHING_TEXT: {
    id: "INSURE_EVERYTHING_TEXT",
    defaultMessage: "Insure everything that matters to you.",
  },
  ENJOY_GREAT_HEALTH: {
    id: "ENJOY_GREAT_HEALTH",
    defaultMessage: "Enjoy great health",
  },
  ENJOY_GREAT_HEALTH_TEXT: {
    id: "ENJOY_GREAT_HEALTH_TEXT",
    defaultMessage: "Engage in a healthy lifestyle for free.",
  },
  BUY_AND_SELL: {
    id: "BUY_AND_SELL",
    defaultMessage: "Buy and sell",
  },
  BUY_AND_SELL_TEXT: {
    id: "BUY_AND_SELL_TEXT",
    defaultMessage: "Connect with over 10M of our customers.",
  },
  LETSGO_CAMPAIGN: { id: "LETSGO_CAMPAIGN", defaultMessage: "LetsGo BiG" },
  CAMPAIGN_BANNER_HEADING_PRIMARY: {
    id: "CAMPAIGN_BANNER_HEADING_PRIMARY",
    defaultMessage: "Go BiG with",
  },
  CAMPAIGN_BANNER_HEADING_SECONDARY: {
    id: "CAMPAIGN_BANNER_HEADING_SECONDARY",
    defaultMessage: "Your Family",
  },
  CAMPAIGN_BANNER_HEADING_PARA: {
    id: "CAMPAIGN_BANNER_HEADING_PARA",
    defaultMessage: "Let’s give your family the lifestyle they",
  },
  CAMPAIGN_BANNER_HEADING_PARA1: {
    id: "CAMPAIGN_BANNER_HEADING_PARA1",
    defaultMessage: "deserve. Join the <custom>LetsGo Nation</custom> today!",
  },
  CAMPAIGN_BANNER_HEADING_CTA: {
    id: "CAMPAIGN_BANNER_HEADING_CTA",
    defaultMessage: "Register now",
  },
  CAMPAIGN_BANNER_HEADING_PRIMARY_SECOND: {
    id: "CAMPAIGN_BANNER_HEADING_PRIMARY_SECOND",
    defaultMessage: "Go BiG with",
  },
  CAMPAIGN_BANNER_HEADING_SECONDARY_SECOND: {
    id: "CAMPAIGN_BANNER_HEADING_SECONDARY_SECOND",
    defaultMessage: "Your Business",
  },
  CAMPAIGN_BANNER_HEADING_PARA_SECOND: {
    id: "CAMPAIGN_BANNER_HEADING_PARA_SECOND",
    defaultMessage: "Trust the LetsGo process to take your",
  },
  CAMPAIGN_BANNER_HEADING_PARA_SECOND1: {
    id: "CAMPAIGN_BANNER_HEADING_PARA_SECOND1",
    defaultMessage: "business to the next level. Join the Nation",
  },
  CAMPAIGN_BANNER_HEADING_PARA_SECOND2: {
    id: "CAMPAIGN_BANNER_HEADING_PARA_SECOND2",
    defaultMessage:
      "free from borders. Join the <custom>LetsGo Nation</custom>!",
  },
  CAMPAIGN_BANNER_HEADING_CTA_SECOND: {
    id: "CAMPAIGN_BANNER_HEADING_CTA_SECOND",
    defaultMessage: "Register now",
  },
  CAMPAIGN_BANNER_HEADING_PRIMARY_THIRD: {
    id: "CAMPAIGN_BANNER_HEADING_PRIMARY_THIRD",
    defaultMessage: "Go BiG with",
  },
  CAMPAIGN_BANNER_HEADING_SECONDARY_THIRD: {
    id: "CAMPAIGN_BANNER_HEADING_SECONDARY_THIRD",
    defaultMessage: "Your Passion",
  },
  CAMPAIGN_BANNER_HEADING_PARA_THIRD: {
    id: "CAMPAIGN_BANNER_HEADING_PARA_THIRD",
    defaultMessage: "Your passions don’t have to be driven by",
  },
  CAMPAIGN_BANNER_HEADING_PARA_THIRD1: {
    id: "CAMPAIGN_BANNER_HEADING_PARA_THIRD1",
    defaultMessage:
      "just you. Join the <custom>LetsGo Nation</custom> today and",
  },
  CAMPAIGN_BANNER_HEADING_PARA_THIRD2: {
    id: "CAMPAIGN_BANNER_HEADING_PARA_THIRD2",
    defaultMessage: "turn your dreams into reality!",
  },
  CAMPAIGN_BANNER_HEADING_CTA_THIRD: {
    id: "CAMPAIGN_BANNER_HEADING_CTA_THIRD",
    defaultMessage: "Register now",
  },
  WELLBEING: {
    id: "WELLBEING",
    defaultMessage: "Wellbeing",
  },
  PROOF_OF_ADDRESS: {
    id: "PROOF_OF_ADDRESS",
    defaultMessage: "Proof of address",
  },
  PROOF_OF_ADDRESS_UPLOAD_DESC: {
    id: "PROOF_OF_ADDRESS_UPLOAD_DESC",
    defaultMessage: "Upload photo for proof",
  },
  BUSINESS_CERTIFICATE_UPLOAD_DESC: {
    id: "BUSINESS_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Upload your business certificate document",
  },
  PURCHASE_ORDER: {
    id: "PURCHASE_ORDER",
    defaultMessage: "Purchase Order",
  },

  WORKING_CAPITAL: {
    id: "WORKING_CAPITAL",
    defaultMessage: "Working Capital",
  },

  GREEN_FINANCING: {
    id: "GREEN_FINANCING",
    defaultMessage: "Green Financing",
  },
  MARKET_PLACE: {
    id: "MARKET_PLACE",
    defaultMessage: "Market Place",
  },
  EXPLORE_OUR: {
    id: "EXPLORE_OUR",
    defaultMessage: "Explore our",
  },
  OTHER_PRODUCTS: {
    id: "OTHER_PRODUCTS",
    defaultMessage: "Other Products",
  },
  EXPLORE_OUR_AFFORDABLE_SERVICES: {
    id: "EXPLORE_OUR_AFFORDABLE_SERVICES",
    defaultMessage: "Explore Our Affordable Services",
  },
  DOB_ERROR_VALIDATION_REQUIRED: {
    id: "DOB_ERROR_VALIDATION_REQUIRED",
    defaultMessage: "You Must be 18 Years and Above to Apply for Loan",
  },
  LOAN_PROFILE_ENTER_EMPLOYERNAME: {
    id: "LOAN_PROFILE_ENTER_EMPLOYERNAME",
    defaultMessage: "Enter Your Employer name",
  },
  WALLET_BALANCE_API_FAIL: {
    id: "WALLET_BALANCE_API_FAIL",
    defaultMessage:
      "{error} Dear Customer, our LetGoPay services are temporarily unavailable. {contactMessage} {signOff}",
  },
  EWALLET_SERVICES: {
    id: "EWALLET_SERVICES",
    defaultMessage: "LetsGo Pay (Payments) Services",
  },
  CONTACT_CALL_CENTER: {
    id: "CONTACT_CALL_CENTER",
    defaultMessage:
      "Please contact our call center to get more information and support",
  },
  INCONVENIENCE_APOLOGY: {
    id: "INCONVENIENCE_APOLOGY",
    defaultMessage: "We apologise for any inconvenience caused.",
  },
  INSURANCE_TITLE: {
    id: "INSURANCE_TITLE",
    defaultMessage: "Insurance",
  },
  INSURANCE_BRIISK_TITLE: {
    id: "INSURANCE_BRIISK_TITLE",
    defaultMessage: "Briisk Insurance",
  },
  INSURANCE_SELECT_PACKAGE_SELECT_TEXT: {
    id: "INSURANCE_SELECT_PACKAGE_SELECT_TEXT",
    defaultMessage: "Select",
  },
  INSURANCE_SELECT_PACKAGE_PACKAGE_TEXT: {
    id: "INSURANCE_SELECT_PACKAGE_PACKAGE_TEXT",
    defaultMessage: "Package",
  },
  INSURANCE_HERO_TITLE: {
    id: "INSURANCE_HERO_TITLE",
    defaultMessage: "Happy",
  },
  INSURANCE_HERO_SUB_TITLE: {
    id: "INSURANCE_HERO_SUB_TITLE",
    defaultMessage: "To have you onboard!",
  },
  INSURANCE_INSTRUCTION_TEXT: {
    id: "INSURANCE_INSTRUCTION_TEXT",
    defaultMessage: "Start by selecting which cover you need",
  },
  INSURANCE_DASHBOARD_CARD_TEXT: {
    id: "INSURANCE_DASHBOARD_CARD_TEXT",
    defaultMessage: "Benefits of having an",
  },
  INSURANCE_DASHBOARD_CARD_SUB_TEXT: {
    id: "INSURANCE_DASHBOARD_CARD_SUB_TEXT",
    defaultMessage: "Insurance Cover",
  },
  REDEEMED_ALREADY: {
    id: "REDEEMED_ALREADY",
    defaultMessage: "This coupon was redeemed",
  },
  REWARDS_TITLE: {
    id: "REWARDS_TITLE",
    defaultMessage: "{couponFrom} data coupon worth {currency}{amount} earned",
  },
  REWARDS_DESCRIPTION: {
    id: "REWARDS_DESCRIPTION",
    defaultMessage:
      "You can copy this coupon code and use it to buy data on your {owner} app anytime.",
  },
  LOAN_PROFILE_OMANG: {
    id: "LOAN_PROFILE_OMANG",
    defaultMessage: "Omang number",
  },
  LOAN_PROFILE_OMANG_ERROR: {
    id: "LOAN_PROFILE_OMANG_ERROR",
    defaultMessage: "Please enter a valid Omang number",
  },
  LETSGO_DOCTOR: {
    id: "LETSGO_DOCTOR",
    defaultMessage: "LetsGo <custom>Doctor</custom>",
  },
  LETSGO_DOCTOR_DASHBOARD: {
    id: "LETSGO_DOCTOR_DASHBOARD",
    defaultMessage: "LetsGo Doctor",
  },
  LETSGO_DOCTOR_GREETING_PART_ONE: {
    id: "LETSGO_DOCTOR_GREETING_PART_ONE",
    defaultMessage: "What would",
  },
  LETSGO_DOCTOR_GREETING_PART_TWO: {
    id: "LETSGO_DOCTOR_GREETING_PART_TWO",
    defaultMessage: "You like to do?",
  },
  LETSGO_DOCTOR_DASHBOARD_FIRST_OPTION_TITLE: {
    id: "LETSGO_DOCTOR_DASHBOARD_FIRST_OPTION_TITLE",
    defaultMessage: "Consult with a Health Professional",
  },
  LETSGO_DOCTOR_DASHBOARD_FIRST_OPTION_SUBTITLE: {
    id: "LETSGO_DOCTOR_DASHBOARD_FIRST_OPTION_SUBTITLE",
    defaultMessage:
      "Have a virtual consultation with a doctor easily over a call or chat.",
  },
  LETSGO_DOCTOR_DASHBOARD_SECOND_OPTION_TITLE: {
    id: "LETSGO_DOCTOR_DASHBOARD_SECOND_OPTION_TITLE",
    defaultMessage: "See Appointments",
  },
  LETSGO_DOCTOR_DASHBOARD_SECOND_OPTION_SUBTITLE: {
    id: "LETSGO_DOCTOR_DASHBOARD_SECOND_OPTION_SUBTITLE",
    defaultMessage: "View all your appointments here",
  },
  LETSGO_DOCTOR_DASHBOARD_THIRD_OPTION_TITLE: {
    id: "LETSGO_DOCTOR_DASHBOARD_THIRD_OPTION_TITLE",
    defaultMessage: "See Prescription History",
  },
  LETSGO_DOCTOR_DASHBOARD_THIRD_OPTION_SUBTITLE: {
    id: "LETSGO_DOCTOR_DASHBOARD_THIRD_OPTION_SUBTITLE",
    defaultMessage: "View all your prescriptions here",
  },
  LETSGO_DOCTOR_DASHBOARD_DOCTORS: {
    id: "LETSGO_DOCTOR_DASHBOARD_DOCTORS",
    defaultMessage: "LetsGo Doctor",
  },
  LETSGO_DOCTOR_DASHBOARD_BENEFITS: {
    id: "LETSGO_DOCTOR_DASHBOARD_BENEFITS",
    defaultMessage: "Benefits",
  },
  LETSGO_DOCTOR_DASHBOARD_BENEFIT_FIRST: {
    id: "LETSGO_DOCTOR_DASHBOARD_BENEFIT_FIRST",
    defaultMessage:
      "Affordable access to quality healthcare in the palm of your hand",
  },
  LETSGO_DOCTOR_DASHBOARD_BENEFIT_SECOND: {
    id: "LETSGO_DOCTOR_DASHBOARD_BENEFIT_SECOND",
    defaultMessage:
      "Talk to one of our Medical Professionals anywhere, anytime",
  },
  LETSGO_DOCTOR_DASHBOARD_BENEFIT_THIRD: {
    id: "LETSGO_DOCTOR_DASHBOARD_BENEFIT_THIRD",
    defaultMessage: "Consult on a safe and secure platform",
  },
  LETSGO_DOCTOR_DASHBOARD_BENEFIT_FOURTH: {
    id: "LETSGO_DOCTOR_DASHBOARD_BENEFIT_FOURTH",
    defaultMessage: "Request a script from the comfort of your home",
  },
  LETSGO_DOCTOR_DASHBOARD_BENEFIT_FIFTH: {
    id: "LETSGO_DOCTOR_DASHBOARD_BENEFIT_FIFTH",
    defaultMessage:
      "Option to book an immediate consultation or with your preferred Doctor's next availability",
  },
  LETSGO_DOCTOR_DASHBOARD_PAYMENT_STATUS_PROMP: {
    id: "LETSGO_DOCTOR_DASHBOARD_PAYMENT_STATUS_PROMP",
    defaultMessage:
      "Your LetsGo Doctor payment is pending. Please complete your payment to book an appointment.",
  },
  LETSGO_DOCTOR_DASHBOARD_PAYMENT_SUCCESS: {
    id: "LETSGO_DOCTOR_DASHBOARD_PAYMENT_SUCCESS",
    defaultMessage:
      "Your LetsGoDoctor payment has been successfully completed.",
  },
  LETSGO_DOCTOR_DASHBOARD_PAYMENT_FAIL: {
    id: "LETSGO_DOCTOR_DASHBOARD_PAYMENT_FAIL",
    defaultMessage:
      "Your LetsGoDoctor payment was not sufficient. Please {tryAgain}.",
  },
  LETSGO_DOCTOR_TRY_AGAIN: {
    id: "LETSGO_DOCTOR_TRY_AGAIN",
    defaultMessage: "Try again",
  },
  LETSGO_DOCTOR_APPOINTMENT_NO: {
    id: "LETSGO_DOCTOR_APPOINTMENT_NO",
    defaultMessage: "Your appointment number is: {appointmentNumber}",
  },
  LETSGO_DOCTOR_APPOINTMENT_TIME: {
    id: "LETSGO_DOCTOR_APPOINTMENT_TIME",
    defaultMessage:
      "Your LetsGo Doctor appointment will begin in approximately {appointmentTime}.",
  },
  LETSGO_DOCTOR_AVAILABILITY_INFO: {
    id: "LETSGO_DOCTOR_AVAILABILITY_INFO",
    defaultMessage:
      "Doctors are currently unavailable. The doctors are only available from {availabilityTimeSlot} every day. You can schedule an appointment for the next available time.",
  },
  SUCCESSFULLY_DELETED_COUPON: {
    id: "SUCCESSFULLY_DELETED_COUPON",
    defaultMessage: "Coupon removed successfully",
  },
  INSTANT_LOAN_SUPER_TEXT: {
    id: "INSTANT_LOAN_SUPER_TEXT",
    defaultMessage: "That's Super {customer}!",
  },
  INSTANT_LOAN_APPLY_FOR_TEXT: {
    id: "INSTANT_LOAN_APPLY_FOR_TEXT",
    defaultMessage: "You are applying for {amount} Instant loan",
  },
  INSTANT_LOAN_ALMOST_DONE_TEXT: {
    id: "INSTANT_LOAN_ALMOST_DONE_TEXT",
    defaultMessage:
      "Almost done! We have all your details just checking final loan amount and details.",
  },
  INSTANT_LOAN_CALCULATING_TEXT: {
    id: "INSTANT_LOAN_CALCULATING_TEXT",
    defaultMessage: "We are calculating your updated eligibility.",
  },
  INSTANT_LOAN_MAX_MSG_TEXT: {
    id: "INSTANT_LOAN_MAX_MSG_TEXT",
    defaultMessage:
      "Hey {customer}, you are eligible for {amount} which is more than what you requested.",
  },
  INSTANT_LOAN_MIN_MSG_TEXT: {
    id: "INSTANT_LOAN_MIN_MSG_TEXT",
    defaultMessage:
      "Hey {customer}, you are eligible for {amount} which is less than what you requested.",
  },
  INSTANT_LOAN_HEY_CUSTOMER: {
    id: "INSTANT_LOAN_HEY_CUSTOMER",
    defaultMessage: "Hey {customerFirstName}!",
  },
  INSTANT_LOAN_LOAN_REPAYMENT: {
    id: "INSTANT_LOAN_LOAN_REPAYMENT",
    defaultMessage: "Loan repayment",
  },
  INSTANT_LOAN_LOAN_REPAYMENT_INITIATED: {
    id: "INSTANT_LOAN_LOAN_REPAYMENT_INITIATED",
    defaultMessage: "Loan repayment request initiated",
  },
  INSTANT_LOAN_LOAN_REPAYMENT_DETAILS: {
    id: "INSTANT_LOAN_LOAN_REPAYMENT_DETAILS",
    defaultMessage:
      "You will receive a prompt to confirm this payment. We'll notify you through email or SMS when your payment request is approved.",
  },
  INSTANT_LOAN_CONTINUE_TEXT: {
    id: "INSTANT_LOAN_CONTINUE_TEXT",
    defaultMessage: "Continue with same amount",
  },
  INSTANT_LOAN_REPAYMENT_AMOUNT_TEXT: {
    id: "INSTANT_LOAN_REPAYMENT_AMOUNT_TEXT",
    defaultMessage: "Revised amount of repayment",
  },
  INSTANT_LOAN_REPAYMENT_AMOUNT_TEXT_1: {
    id: "INSTANT_LOAN_REPAYMENT_AMOUNT_TEXT_1",
    defaultMessage: "{currency} {amount}",
  },
  INSTANT_LOAN_HELP_MESSAGE: {
    id: "INSTANT_LOAN_HELP_MESSAGE",
    defaultMessage:
      "You are eligible for intant loan up to {currency} {amount}",
  },
  INSTANT_LOAN_MESSAGE: {
    id: "INSTANT_LOAN_MESSAGE",
    defaultMessage:
      "You are at the right place to get quick and hassle-free funds instantly.",
  },
  INSTANT_LOAN_SUB_TEXT: {
    id: "INSTANT_LOAN_SUB_TEXT",
    defaultMessage: "Borrow up to {amount}",
  },
  INSTANT_LOAN_SUB_TEXT_1: {
    id: "INSTANT_LOAN_SUB_TEXT_1",
    defaultMessage:
      "Interest rate from {nonTiredInterestRate}% as per the sum borrowed",
  },
  INSTANT_LOAN_SUB_TEXT_1_PER_MONTH: {
    id: "INSTANT_LOAN_SUB_TEXT_1_PER_MONTH",
    defaultMessage: "Interest rate from {nonTiredInterestRate}% per month",
  },
  INSTANT_LOAN_DETAIL_PROCESSING_FEE: {
    id: "INSTANT_LOAN_DETAIL_PROCESSING_FEE",
    defaultMessage: "Processing Fee - {processFee}% of Loan amount",
  },
  INSTANT_LOAN_SUB_TEXT_2: {
    id: "INSTANT_LOAN_SUB_TEXT_2",
    defaultMessage: "Immediate disbursement to account of your choice",
  },
  INSTANT_LOAN_SUB_TEXT_3: {
    id: "INSTANT_LOAN_SUB_TEXT_3",
    defaultMessage: "Repayment period of {nonTiredDuration} days",
  },
  INSTANT_LOAN_SUB_TEXT_4: {
    id: "INSTANT_LOAN_SUB_TEXT_4",
    defaultMessage: "Flexible fee structure",
  },
  INSTANT_LOAN_SUB_TEXT_5: {
    id: "INSTANT_LOAN_SUB_TEXT_5",
    defaultMessage:
      "Repayment period of {tiredDurationOne} days to {tiredDurationTwo} days",
  },
  INSTANT_LOAN_LATE_PENALTY_FEE: {
    id: "INSTANT_LOAN_LATE_PENALTY_FEE",
    defaultMessage: "Default Interest",
  },
  INSTANT_LOAN_PROCESSING_FEE: {
    id: "INSTANT_LOAN_PROCESSING_FEE",
    defaultMessage: "Processing Fee",
  },
  INSTANT_LOANS_TERMS_CONDITIONS: {
    id: "INSTANT_LOANS_TERMS_CONDITIONS",
    defaultMessage: "Terms & Conditions",
  },
  INSTANT_LOAN_TERMS_AND_CONDITIONS_TEXT: {
    id: "INSTANT_LOAN_TERMS_AND_CONDITIONS_TEXT",
    defaultMessage:
      "I allow Letshego to asses my personal information for Instant Credit request. I accept all",
  },
  INSTANT_LOAN_SIGNATURE_TEXT: {
    id: "INSTANT_LOAN_SIGNATURE_TEXT",
    defaultMessage:
      "I provide my signature as agreement to the Terms and Conditions of this loan contract submission.",
  },
  INSTANT_LOAN_PRIVACY_POLICY: {
    id: "INSTANT_LOAN_PRIVACY_POLICY",
    defaultMessage: "Privacy Policy",
  },
  INSTANT_LOAN_PRIVACY_POLICY_TEXT: {
    id: "INSTANT_LOAN_PRIVACY_POLICY_TEXT",
    defaultMessage:
      "I certify that all the information provided by me for this loan application is correct. I accept all",
  },
  INSTANT_LOAN_SUMMARY_ONCE_OFF_FEE_TEXT: {
    id: "INSTANT_LOAN_SUMMARY_ONCE_OFF_FEE_TEXT",
    defaultMessage: "Once-off fees",
  },
  INSTANT_LOAN_SUMMARY_ACCOUNT_DETAILS_TITLE: {
    id: "INSTANT_LOAN_SUMMARY_ACCOUNT_DETAILS_TITLE",
    defaultMessage: "Account details",
  },
  INSTANT_LOAN_SUMMARY_ACCOUNT_DETAILS_DESC: {
    id: "INSTANT_LOAN_SUMMARY_ACCOUNT_DETAILS_DESC",
    defaultMessage:
      "Details of wallet account where you will receive your funds",
  },
  INSTANT_LOAN_WALLET_NAME: {
    id: "INSTANT_LOAN_WALLET_NAME",
    defaultMessage: "Wallet Name",
  },
  INSTANT_LOAN_APPROVAL_TEXT: {
    id: "INSTANT_LOAN_APPROVAL_TEXT",
    defaultMessage:
      "We’re happy to inform you that your instant loan has been approved!",
  },
  APPLY_BTN: { id: "APPLY_BTN", defaultMessage: "Apply Now" },
  AUTO_PAY: {
    id: "AUTO_PAY",
    defaultMessage: "Auto Pay",
  },
  AUTO_PAY_SUB_HEAD: {
    id: "AUTO_PAY_SUB_HEAD",
    defaultMessage:
      "Set up automatic payments for your bills and to your peers",
  },
  SETUP_AUTO_PAY: {
    id: "SETUP_AUTO_PAY",
    defaultMessage: "Set up Auto-Pay",
  },
  NEVER_MISS_A_PAYMENT: {
    id: "NEVER_MISS_A_PAYMENT",
    defaultMessage: "Never miss a payment",
  },
  WE_GOT_YOU_COVERED: {
    id: "WE_GOT_YOU_COVERED",
    defaultMessage: "We got you covered for your bills and other payments",
  },
  SUGGESTIONS: {
    id: "SUGGESTIONS",
    defaultMessage: "Suggestions",
  },
  UPCOMING_PAYMENTS: {
    id: "UPCOMING_PAYMENTS",
    defaultMessage: "Upcoming Payments",
  },
  BILLS: { id: "BILLS", defaultMessage: "Bills" },
  DUE_ON: { id: "DUE_ON", defaultMessage: "Due on" },
  PAY_NOW: { id: "PAY_NOW", defaultMessage: "Pay Now" },
  VIEW_ALL_TRANSACTIONS: {
    id: "VIEW_ALL_TRANSACTIONS",
    defaultMessage: "View all Transactions",
  },
  PAUSE: { id: "PAUSE", defaultMessage: "Pause" },
  SUGGESTIONS_SUB_TEXT: {
    id: "SUGGESTIONS_SUB_TEXT",
    defaultMessage: "You’ve been making these payments often. Set up Auto-Pay?",
  },
  EDIT_AUTO_PAY: {
    id: "EDIT_AUTO_PAY",
    defaultMessage: "Edit Auto-Pay",
  },
  EDIT_DETAILS: {
    id: "EDIT_DETAILS",
    defaultMessage: "Edit Details",
  },
  FREQUENCY_TEXT: {
    id: "FREQUENCY_TEXT",
    defaultMessage: "Frequency",
  },
  PAYMENT_DATE_TEXT: {
    id: "PAYMENT_DATE_TEXT",
    defaultMessage: "Payment Date",
  },
  PAYMENT_DATE_INFO_TEXT: {
    id: "PAYMENT_DATE_INFO_TEXT",
    defaultMessage: "The {text} on which your amount will be deducted",
  },
  AMOUNT_INFO_TEXT: {
    id: "AMOUNT_INFO_TEXT",
    defaultMessage: "Will be deducted from your LetsGo wallet",
  },
  FREQUENCY_TIME_TEXT: {
    id: "FREQUENCY_TIME_TEXT",
    defaultMessage: "Set a time",
  },
  FREQUENCY_TIME_INFO_TEXT: {
    id: "FREQUENCY_TIME_INFO_TEXT",
    defaultMessage:
      "The time slot in which your amount will be deducted, eachday.",
  },
  AUTO_PAYMENT_TITLE_1: {
    id: "AUTO_PAYMENT_TITLE_1",
    defaultMessage: "What kind of payment",
  },
  AUTO_PAYMENT_SUBTITLE_1: {
    id: "AUTO_PAYMENT_SUBTITLE_1",
    defaultMessage: "Do you wish to automate?",
  },
  WHO_DO_YOU: {
    id: "WHO_DO_YOU",
    defaultMessage: "Who do you",
  },
  WISH_TO_PAY: {
    id: "WISH_TO_PAY",
    defaultMessage: "Wish to pay for?",
  },
  PAYMENT: {
    id: "PAYMENT",
    defaultMessage: "Payment",
  },
  VAS: {
    id: "VAS",
    defaultMessage: "Bill Payments",
  },
  ENTER_NAME: {
    id: "ENTER_NAME",
    defaultMessage: "Enter name",
  },
  FREQUENCY: {
    id: "FREQUENCY",
    defaultMessage: "Frequency",
  },
  SET_TIME: {
    id: "SET_TIME",
    defaultMessage: "Set a time",
  },
  PAYING_FROM: {
    id: "PAYING_FROM",
    defaultMessage: "Paying from",
  },
  PAYMENT_DAY: {
    id: "PAYMENT_DAY",
    defaultMessage: "Payment day",
  },
  PAYMENT_DATE: {
    id: "PAYMENT_DATE",
    defaultMessage: "Payment date",
  },
  AMOUNT_DEDUCTION_SUB_TEXT: {
    id: "AMOUNT_DEDUCTION_SUB_TEXT",
    defaultMessage: "Will be deducted from your LetsGo wallet",
  },
  DAY_DEDUCTION_TEXT: {
    id: "DAY_DEDUCTION_TEXT",
    defaultMessage: "The day on which your amount will be deducted",
  },
  DATE_DEDUCTION_TEXT: {
    id: "DATE_DEDUCTION_TEXT",
    defaultMessage: "The date on which your amount will be deducted",
  },
  TIME_SLOT_TEXT: {
    id: "TIME_SLOT_TEXT",
    defaultMessage:
      "The time slot in which your amount will be deducted, eachday.",
  },
  PAYMENT_STOP_DATE_TEXT: {
    id: "PAYMENT_STOP_DATE_TEXT",
    defaultMessage: "Automatic payments will stop on the provided date",
  },
  DELETE_AUTOPAY_LABEL: {
    id: "DELETE_AUTOPAY_LABEL",
    defaultMessage: "{action} Auto-Payment?",
  },
  DELETE_AUTOPAY_SUB_LABEL: {
    id: "DELETE_AUTOPAY_SUB_LABEL",
    defaultMessage:
      "Are you sure you want to delete this auto-payment? You may pause instead.",
  },
  PAUSE_BTN: { id: "PAUSE_BTN", defaultMessage: "Pause" },
  PAUSE_AUTOPAY_SUB_LABEL: {
    id: "PAUSE_AUTOPAY_SUB_LABEL",
    defaultMessage:
      "You can resume it any time you’d like. You may also set date for resuming.",
  },
  PAUSE_RESUMEON_TEXT: {
    id: "PAUSE_RESUMEON_TEXT",
    defaultMessage: "Resume on (optional)",
  },
  TRANSACTION_FEE_MESSAGE_AUTOPAY: {
    id: "TRANSACTION_FEE_MESSAGE_AUTOPAY",
    defaultMessage:
      "Note: A small transaction fee of {fees}{currencySymbol} will be applicable.",
  },
  NOUPCOMING_PAYMENT: {
    id: "NOUPCOMING_PAYMENT",
    defaultMessage: "No upcoming payments",
  },
  NOUPCOMING_PAYMENT_SUB_LABEL: {
    id: "NOUPCOMING_PAYMENT_SUB_LABEL",
    defaultMessage:
      "Set up Auto-Pay now and receive reward on your first payment! ",
  },
  METERNO_MUST_BE_VALID: {
    id: "METERNO_MUST_BE_VALID",
    defaultMessage: "Invalid Number. Please enter a valid meter number",
  },
  DESCRIPTION_OPTIONAL_HELPER_TEXT: {
    id: "DESCRIPTION_OPTIONAL_HELPER_TEXT",
    defaultMessage: "Eg: Room Rent",
  },
  NICK_NAME: {
    id: "NICK_NAME",
    defaultMessage: "Nickname",
  },
  NICK_NAME_HELPER_TEXT: {
    id: "NICK_NAME_HELPER_TEXT",
    defaultMessage: "Eg: Home/Office",
  },
  BILL_TYPE: {
    id: "BILL_TYPE",
    defaultMessage: "Bill Type",
  },

  BANK_ACCOUNT: {
    id: "BANK_ACCOUNT",
    defaultMessage: "Bank Account",
  },
  WALLET_ACCOUNT: {
    id: "WALLET_ACCOUNT",
    defaultMessage: "Wallet Account",
  },
  VAS_ACCOUNT: {
    id: "VAS_ACCOUNT",
    defaultMessage: "Bill Account",
  },
  MERCHANT: {
    id: "MERCHANT",
    defaultMessage: "Business Account",
  },
  DUE_ERROR_MESSAGE: {
    id: "DUE_ERROR_MESSAGE",
    defaultMessage:
      "Your wallet balance is lower than the amount due this month, please {link}",
  },
  AUTO_PAY_DETAILS: {
    id: "AUTO_PAY_DETAILS",
    defaultMessage: "Auto-Pay details",
  },
  SUCCESSFULLY_DELETE_AUTOPAY: {
    id: "SUCCESSFULLY_DELETE_AUTOPAY",
    defaultMessage: "Autopay removed successfully",
  },
  NO_DEVICE_ADDED: {
    id: "NO_DEVICE_ADDED",
    defaultMessage: "You have not added a \n     trusted device yet",
  },
  PRIMARY_DEVICE_TXT: {
    id: "PRIMARY_DEVICE_TXT",
    defaultMessage: "Primary Device",
  },
  OTHER_DEVICES_TXT: {
    id: "OTHER_DEVICES_TXT",
    defaultMessage: "Other Devices",
  },
  SET_UP_AUTO_PAYMENT: {
    id: "SET_UP_AUTO_PAYMENT",
    defaultMessage: "Setup Auto Payment",
  },
  RESUME: { id: "RESUME", defaultMessage: "Resume" },
  SAVINGS_DECLARATION_NATIONALITY: {
    id: "SAVINGS_DECLARATION_NATIONALITY",
    defaultMessage: "Nationality",
  },
  SAVINGS_OTHER_NATIONALITY_MSG: {
    id: "SAVINGS_OTHER_NATIONALITY_MSG",
    defaultMessage:
      "Please visit the nearest branch with documents to open a FlexiSave account",
  },
  SAVINGS_DECLARATION_MOBILE_NUMBER: {
    id: "SAVINGS_DECLARATION_MOBILE_NUMBER",
    defaultMessage: "Mobile Number",
  },
  SAVINGS_DECLARATION_FIRST_NAME: {
    id: "SAVINGS_DECLARATION_FIRST_NAME",
    defaultMessage: "First Name",
  },
  SAVINGS_DECLARATION_LAST_NAME: {
    id: "SAVINGS_DECLARATION_LAST_NAME",
    defaultMessage: "Last Name",
  },
  SAVINGS_DECLARATION_GENDER: {
    id: "SAVINGS_DECLARATION_GENDER",
    defaultMessage: "Gender",
  },
  SAVINGS_DECLARATION_DATE_OF_BIRTH: {
    id: "SAVINGS_DECLARATION_DATE_OF_BIRTH",
    defaultMessage: "Date of birth",
  },
  SAVINGS_DECLARATION_ID_TYPE: {
    id: "SAVINGS_DECLARATION_ID_TYPE",
    defaultMessage: "ID Type",
  },
  SAVINGS_DECLARATION_ID_NUMBER: {
    id: "SAVINGS_DECLARATION_ID_NUMBER",
    defaultMessage: "ID Number",
  },
  SAVINGS_DECLARATION_TITLE_TEXT: {
    id: "SAVINGS_DECLARATION_TITLE_TEXT",
    defaultMessage: "Declaration",
  },
  SAVINGS_DECLARATION_TITLE_SUB_TEXT: {
    id: "SAVINGS_DECLARATION_TITLE_SUB_TEXT",
    defaultMessage:
      "Please review and confirm your FlexiSave application details.",
  },
  GET_OFFER: { id: "GET_OFFER", defaultMessage: "Get Offer" },
  CUSTOMIZED_OFFER: {
    id: "CUSTOMIZED_OFFER",
    defaultMessage:
      " Get a <custom>Customised</custom> {productName} up to <custom> {amount}</custom>!",
  },
  PRE_APPROVEED_OFFER: {
    id: "PRE_APPROVEED_OFFER",
    defaultMessage:
      " <custom>Pre-approved</custom> offer of <custom> {amount}</custom> only for you!",
  },
  PRE_APPROVEED_OFFER_TEXT: {
    id: "PRE_APPROVEED_OFFER_TEXT",
    defaultMessage: "Get money disbursed to your account in 2 easy steps",
  },
  PRE_APPROVEED_OFFER_TEXT_1: {
    id: "PRE_APPROVEED_OFFER_TEXT_1",
    defaultMessage:
      "Get money disbursed to your account or wallet in less than 10 minutes.",
  },
  PRE_APPROVEED_OFFER_AMOUNT_TEXT: {
    id: "PRE_APPROVEED_OFFER_AMOUNT_TEXT",
    defaultMessage: "Application Fees",
  },
  PRE_APPROVEED_OFFER_AMOUNT_TEXT_1: {
    id: "PRE_APPROVEED_OFFER_AMOUNT_TEXT_1",
    defaultMessage: "Loan duration",
  },
  EXPLORE_HOUSING_PRODUCTS: {
    id: "EXPLORE_HOUSING_PRODUCTS",
    defaultMessage: "Explore our affordable housing products",
  },
  NOW_YOU_CAN: {
    id: "NOW_YOU_CAN",
    defaultMessage: "Now you can",
  },
  ENJOY_OUR_SERVICES: {
    id: "ENJOY_OUR_SERVICES",
    defaultMessage: "Enjoy our services",
  },
  IT_TAKES_A_FEW_SIMPLE_STEP: {
    id: "IT_TAKES_A_FEW_SIMPLE_STEP",
    defaultMessage: "It takes a few simple step to achieve your dreams",
  },
  LETS_GO_BUILD: {
    id: "LETS_GO_BUILD",
    defaultMessage: "LetsGo Build",
  },
  LETS_GO_BUY: {
    id: "LETS_GO_BUY",
    defaultMessage: "LetsGo Buy",
  },
  LETS_GO_PLOT: {
    id: "LETS_GO_PLOT",
    defaultMessage: "LetsGo Plot",
  },
  LETS_GO_RENOVATE: {
    id: "LETS_GO_RENOVATE",
    defaultMessage: "LetsGo Renovate",
  },
  START_NOW: {
    id: "START_NOW",
    defaultMessage: "Start now",
  },
  EXPLORE_OUR_OTHER_PRODUCTS: {
    id: "EXPLORE_OUR_OTHER_PRODUCTS",
    defaultMessage: "You can explore our other",
  },
  EXPLORE_OTHER_LETSGO_PRODUCTS: {
    id: "EXPLORE_OTHER_LETSGO_PRODUCTS",
    defaultMessage: "Explore other Letsgo products",
  },

  CHECK_YOUR_MOST_RECENT_LOANS: {
    id: "CHECK_YOUR_MOST_RECENT_LOANS",
    defaultMessage: "Check your most recent loans with us",
  },

  TAILORED_PURCHASE_ORDER_ORDER: {
    id: "TAILORED_PURCHASE_ORDER_ORDER",
    defaultMessage: "Tailored Purchase Order Offer just for you!",
  },

  APPLY_FOR_OUR_PURCHASE_ORDER_AND: {
    id: "APPLY_FOR_OUR_PURCHASE_ORDER_AND",
    defaultMessage:
      "Apply for our Purchase Order and let us help you grow your business!",
  },

  MINIMUM_LOAN_LIMITS: {
    id: "MINIMUM_LOAN_LIMITS",
    defaultMessage: "Minimum loan limits",
  },

  MAXIMUM_LOAN_LIMITS: {
    id: "MAXIMUM_LOAN_LIMITS",
    defaultMessage: "Maximum loan limits",
  },

  TENURE_UP_TO: {
    id: "TENURE_UP_TO",
    defaultMessage: "Tenure up to",
  },

  THESE_RATES_INCLUDE_AN_INTEREST: {
    id: "THESE_RATES_INCLUDE_AN_INTEREST",
    defaultMessage: "These rates include an interest rate of ",
  },
  THESE_RATES_INCLUDE_AN_INTEREST_CONTINUED: {
    id: "THESE_RATES_INCLUDE_AN_INTEREST_CONTINUED",
    defaultMessage: "per month and a Letshego insurance!",
  },
  ENTER_THE_PURCHASE_ORDER_VALUE: {
    id: "ENTER_THE_PURCHASE_ORDER_VALUE",
    defaultMessage: "Enter the purchase order value",
  },

  BUSINESS_LOANS: {
    id: "BUSINESS_LOANS",
    defaultMessage: "Business Loans",
  },
  YOU_HAVE_NO_LOANS: {
    id: "YOU_HAVE_NO_LOANS",
    defaultMessage: "You Have No Active Loans",
  },
  APPLY_NOW: {
    id: "APPLY_NOW",
    defaultMessage: "Apply Now",
  },
  LOAN_APPLY_HINT: {
    id: "LOAN_APPLY_HINT",
    defaultMessage:
      "Enter your purchase order value and start an exciting journey with us!",
  },
  MSE_LOAN_SUMMARY_REVIEW: {
    id: "MSE_LOAN_SUMMARY_REVIEW",
    defaultMessage:
      "Kindly review your Purchase Order request before submitting",
  },
  LEGAL_INSURANCE_TITLE: {
    id: "LEGAL_INSURANCE_TITLE",
    defaultMessage: "Would you like to choose legal insurance?",
  },
  LEGAL_INSURANCE_SUB_TITLE: {
    id: "LEGAL_INSURANCE_SUB_TITLE",
    defaultMessage:
      "The loan is inclusive of legal cover up to maximum of P 30,000 for the following service",
  },
  LEGAL_INSURANCE_POINT_1: {
    id: "LEGAL_INSURANCE_POINT_1",
    defaultMessage: "General legal advice",
  },
  LEGAL_INSURANCE_POINT_2: {
    id: "LEGAL_INSURANCE_POINT_2",
    defaultMessage: "Advise on regulatory and statutory matters",
  },
  LEGAL_INSURANCE_POINT_3: {
    id: "LEGAL_INSURANCE_POINT_3",
    defaultMessage: "Drafting of commercial contracts",
  },
  LEGAL_INSURANCE_POINT_4: {
    id: "LEGAL_INSURANCE_POINT_4",
    defaultMessage: "Sales agreements",
  },
  LEGAL_INSURANCE_POINT_5: {
    id: "LEGAL_INSURANCE_POINT_5",
    defaultMessage: "Lease agreements",
  },
  LEGAL_INSURANCE_POINT_6: {
    id: "LEGAL_INSURANCE_POINT_6",
    defaultMessage: "Contract advise & perusal",
  },
  MSE_TERMS_CONDITIONS: {
    id: "MSE_TERMS_CONDITIONS",
    defaultMessage:
      "I allow Letshego to asses my personal and financial information for a business loan and accept all.",
  },
  MSE_PRIVACY_POLICY: {
    id: "MSE_PRIVACY_POLICY",
    defaultMessage:
      "I certify that all the information provided by me for this loan application is correct.",
  },
  TERMS_AND_CONDITIONS_TEXT: {
    id: "TERMS_AND_CONDITIONS_TEXT",
    defaultMessage: "Terms & Conditions",
  },
  TAX_CLEARANCE: {
    id: "TAX_CLEARANCE",
    defaultMessage: "Tax Clearance",
  },
  TAX_CLEARANCE_UPLOAD_DESC: {
    id: "TAX_CLEARANCE_UPLOAD_DESC",
    defaultMessage: "Upload the Tax clearance",
  },
  PROOF_OF_PAST_TRANSACTION: {
    id: "PROOF_OF_PAST_TRANSACTION",
    defaultMessage: "Proof Of Past Transaction",
  },
  APPLICATION_SUBMITTED: {
    id: "APPLICATION_SUBMITTED",
    defaultMessage: "Application is submitted",
  },
  INTERESET_PER_MONTHS: {
    id: "INTERESET_PER_MONTHS",
    defaultMessage: "{rate}% Per Month",
  },
  AMAZING_PRODUCTS: {
    id: "AMAZING_PRODUCTS",
    defaultMessage: "Amazing products",
  },
  CHANGING_PLAN_FROM_CURRENT_PLAN: {
    id: "CHANGING_PLAN_FROM_CURRENT_PLAN",
    defaultMessage:
      "Changing plan from your current plan will require additional fees.",
  },
  YOU_DONT_QUALIFY_FOR_THIS_PRODUCT: {
    id: "YOU_DONT_QUALIFY_FOR_THIS_PRODUCT",
    defaultMessage: "You don’t qualify for this product",
  },
  FIRST_TIME_HOME_OWNER_QUESTION: {
    id: "FIRST_TIME_HOME_OWNER_QUESTION",
    defaultMessage: "Are you a first time home owner?",
  },
  ARE_YOU_BUYING_FROM_US: {
    id: "ARE_YOU_BUYING_FROM_US",
    defaultMessage: "Are you buying from us?",
  },
  DO_YOU_OWN_A_LAND: {
    id: "DO_YOU_OWN_A_LAND",
    defaultMessage: "Do you own a land?",
  },
  YOUR_EMPLOYER: {
    id: "YOUR_EMPLOYER",
    defaultMessage: "Your employer?",
  },
  DURATION_OF_EMPLOYMENT: {
    id: "DURATION_OF_EMPLOYMENT",
    defaultMessage: "Duration of employment?",
  },
  WHAT_IS_YOUR_NET_SALARY: {
    id: "WHAT_IS_YOUR_NET_SALARY",
    defaultMessage: "What is your net salary?",
  },
  WHAT_IS_YOUR_GROSS_SALARY: {
    id: "WHAT_IS_YOUR_GROSS_SALARY",
    defaultMessage: "What is your gross salary?",
  },
  MONTHLY_BUSINESS_TURNOVER: {
    id: "MONTHLY_BUSINESS_TURNOVER",
    defaultMessage: "What is your monthly business turnover?",
  },
  TAKE_HOME_AMOUNT_AFTER_DEDUCTION: {
    id: "TAKE_HOME_AMOUNT_AFTER_DEDUCTION",
    defaultMessage: "Your take home amount after deductions",
  },
  SELECT_DATE_OF_BIRTH: {
    id: "SELECT_DATE_OF_BIRTH",
    defaultMessage: "Please select your date of birth?",
  },
  DO_YOU_HAVE_ACTIVE_LOAN: {
    id: "DO_YOU_HAVE_ACTIVE_LOAN",
    defaultMessage: "Do you have an active loan?",
  },
  WHAT_IS_YOUR_OUTSTANDING_BALANCE: {
    id: "WHAT_IS_YOUR_OUTSTANDING_BALANCE",
    defaultMessage: "What is your outstanding balance?",
  },
  SELECT_PURPOSE_OF_FUNDS: {
    id: "SELECT_PURPOSE_OF_FUNDS",
    defaultMessage: "Select purpose of funds",
  },
  COMPLETE_MY_AFFORDABLE_HOME: {
    id: "COMPLETE_MY_AFFORDABLE_HOME",
    defaultMessage: "Complete my affordable home",
  },
  BUY_MY_AFFORDABLE_HOME: {
    id: "BUY_MY_AFFORDABLE_HOME",
    defaultMessage: "Buy my affordable home",
  },
  BUY_MY_AFFORDABLE_APARTMENT: {
    id: "BUY_MY_AFFORDABLE_APARTMENT",
    defaultMessage: "Buy my affordable apartment",
  },
  BUY_MY_AFFORDABLE_MULTI_UNIT: {
    id: "BUY_MY_AFFORDABLE_MULTI_UNIT",
    defaultMessage: "Buy my affordable multi unit",
  },
  BUILD_MY_AFFORDABLE_HOME: {
    id: "BUILD_MY_AFFORDABLE_HOME",
    defaultMessage: "Build my affordable home",
  },
  START_WITH_AFFORDABLE_HOME: {
    id: "START_WITH_AFFORDABLE_HOME",
    defaultMessage: "Start small with my <br/> affordable home",
  },
  BUILD_MY_AFFORDABLE_MULTI: {
    id: "BUILD_MY_AFFORDABLE_MULTI",
    defaultMessage: "Build my affordable multi <br/> unit",
  },
  YOU_ARE_NOT_ELIGIBLE_FOR_HOUSING_LOAN: {
    id: "YOU_ARE_NOT_ELIGIBLE_FOR_HOUSING_LOAN",
    defaultMessage: "You are not eligible for a Housing loan",
  },
  EMPLOYED_PART_TIME: {
    id: "EMPLOYED_PART_TIME",
    defaultMessage: "Employed on Contract",
  },
  CONGRATULATIONS_YOU_QUALIFY_UP_TO: {
    id: "CONGRATULATIONS_YOU_QUALIFY_UP_TO",
    defaultMessage:
      "Congratulations! you qualify to borrow up to {maxLoanAmount}",
  },
  LETS_GO_PLOT_DESCRIPTION: {
    id: "LETS_GO_PLOT_DESCRIPTION",
    defaultMessage:
      "We are currently building a platform that helps get a loan to buy plots and gives you the power to do more.",
  },
  LETS_GO_PLOT_RENOVATE: {
    id: "LETS_GO_PLOT_RENOVATE",
    defaultMessage:
      "We are currently building a platform that helps you get a loan to renovate and gives you the power to do more.",
  },
  PLOT: {
    id: "PLOT",
    defaultMessage: "Plot",
  },
  RENOVATE: {
    id: "RENOVATE",
    defaultMessage: "Renovate",
  },
  WRITE_KIN_DETAIL_EXCEPT_SPOUSE: {
    id: "WRITE_KIN_DETAIL_EXCEPT_SPOUSE",
    defaultMessage:
      "Please enter details of someone other than yourself and your spouse",
  },
  USER_ACTIONS_REQUIRED: {
    id: "USER_ACTIONS_REQUIRED",
    defaultMessage: "Actions required by you",
  },
  USER_ACTIONS_REQUIRED_DOCUMENTS: {
    id: "USER_ACTIONS_REQUIRED_DOCUMENTS",
    defaultMessage:
      "You are yet to complete your documents upload, kindly view them below.",
  },
  YOU_HAVE_TEXT: { id: "YOU_HAVE_TEXT", defaultMessage: "You have some" },
  PENDING_DOCUMENTS: {
    id: "PENDING_DOCUMENTS",
    defaultMessage: "Documents pending",
  },
  SAVE_AND_UPLOAD_LATER: {
    id: "SAVE_AND_UPLOAD_LATER",
    defaultMessage: "Save application to upload later",
  },
  BUILDING_LEVEL: {
    id: "BUILDING_LEVEL",
    defaultMessage: "Indicate level of completion",
  },
  BUILDING_LEVEL_1: {
    id: "BUILDING_LEVEL_1",
    defaultMessage: "Foundation Level",
  },
  BUILDING_LEVEL_2: {
    id: "BUILDING_LEVEL_2",
    defaultMessage: "Electrical/Painting",
  },
  BUILDING_LEVEL_3: {
    id: "BUILDING_LEVEL_3",
    defaultMessage: "Windows",
  },
  BUILDING_LEVEL_4: {
    id: "BUILDING_LEVEL_4",
    defaultMessage: "Plastering/Flooring",
  },
  BUILDING_LEVEL_5: {
    id: "BUILDING_LEVEL_5",
    defaultMessage: "Roofing",
  },
  DOCUMENT: {
    id: "DOCUMENT",
    defaultMessage: "Document",
  },
  PROPERTY_LEASE_DOCUMENT: {
    id: "PROPERTY_LEASE_DOCUMENT",
    defaultMessage: "Title Deed/lease of property",
  },
  PROPERTY_LEASE_DOCUMENT_DESC: {
    id: "PROPERTY_LEASE_DOCUMENT_DESC",
    defaultMessage: "Upload the title deed/lease of the property",
  },
  EDIT_LOAN_DETAILS: {
    id: "EDIT_LOAN_DETAILS",
    defaultMessage: "Edit loan details",
  },
  BOND_LODGING: {
    id: "BOND_LODGING",
    defaultMessage: "Bond lodging",
  },
  APPLICATION_ID: {
    id: "APPLICATION_ID",
    defaultMessage: "Application ID",
  },
  CHECK_OFFER_TO_BE_ABLE_TO_START: {
    id: "CHECK_OFFER_TO_BE_ABLE_TO_START",
    defaultMessage:
      "You have to check your offer to able be to start your application",
  },
  GO_TO_HOUSING_MARKET_PLACE: {
    id: "GO_TO_HOUSING_MARKET_PLACE",
    defaultMessage: "Go to market place",
  },
  GO_TO_HOUSING_MARKET_PLACE_TEXT: {
    id: "GO_TO_HOUSING_MARKET_PLACE",
    defaultMessage:
      "Shop for your housing needs directly from our market place.",
  },
  HOUSING_ACCOUNT_NO_LABEL: {
    id: "HOUSING_ACCOUNT_NO_LABEL",
    defaultMessage: "Housing loan A/C no",
  },
  FIND_BELOW_DETAILS_REQUIRED_BY_YOU: {
    id: "FIND_BELOW_DETAILS_REQUIRED_BY_YOU",
    defaultMessage:
      "Kindly find below the details required before you can proceed with your application.",
  },
  CUSTOMER_IDENTIFICATION: {
    id: "CUSTOMER_IDENTIFICATION",
    defaultMessage: "Customer Identification",
  },
  PAY_SLIP_2: {
    id: "PAY_SLIP_2",
    defaultMessage: "Pay slip",
  },
  TITLE_DEED: {
    id: "TITLE_DEED",
    defaultMessage: "Title Deed",
  },
  EMPLOYMENT_LETTER_4: {
    id: "EMPLOYMENT_LETTER_4",
    defaultMessage: "Employment letter",
  },
  DEEDS_OF_SALE: {
    id: "DEEDS_OF_SALE",
    defaultMessage: "Deed of Sale",
  },
  INCREMENTAL_HOUSING: {
    id: "INCREMENTAL_HOUSING",
    defaultMessage:
      "Incremental Housing (Start small with my <br/> affordable home)",
  },
  TYPE_YOUR_WORK_PLACE: {
    id: "TYPE_YOUR_WORK_PLACE",
    defaultMessage: "Type your work place",
  },
  YOU_HAVE_BEEN_PRE_APPROVED_FOR_THE_AMOUNT: {
    id: "YOU_HAVE_BEEN_PRE_APPROVED_FOR_THE_AMOUNT",
    defaultMessage: "You have been pre approved for the total loan amount of",
  },
  PLEASE_PROCESS_FOR_YOUR_DREAM_HOME: {
    id: "PLEASE_PROCESS_FOR_YOUR_DREAM_HOME",
    defaultMessage:
      "Please proceed to the Affordable housing market place to shop for your dream home!",
  },
  PLEASE_NOTE_THAT_AMOUNT_IS_INDICATIVE: {
    id: "PLEASE_NOTE_THAT_AMOUNT_IS_INDICATIVE",
    defaultMessage:
      "Please note the amount above is indicative and the final offer may differ subject to credit underwriting",
  },
  LOAN_HAS_BEEN_APPROVED: {
    id: "LOAN_HAS_BEEN_APPROVED",
    defaultMessage: "Loan has been approved",
  },
  LETSGO_SELECT_CATEGORY: {
    id: "LETSGO_SELECT_CATEGORY",
    defaultMessage: "Select Category",
  },
  LETSGO_DOCTOR_WHAT_KIND: {
    id: "LETSGO_DOCTOR_WHAT_KIND",
    defaultMessage: "What kind of Doctor",
  },
  LETSGO_DOCTOR_SELECT_DOCTOR_CATEGORY: {
    id: "LETSGO_DOCTOR_SELECT_DOCTOR_CATEGORY",
    defaultMessage: "Would you like?",
  },
  ACCEPT_LATEST_TEXT: {
    id: "ACCEPT_LATEST_TEXT",
    defaultMessage: "Accept latest",
  },
  ACCEPT_LATEST_PRIVACY_POLICY: {
    id: "ACCEPT_LATEST_PRIVACY_POLICY",
    defaultMessage:
      "Kindly accept the latest privacy policy to continue using the Letsgo platform.",
  },
  READ_MORE_TEXT: {
    id: "READ_MORE_TEXT",
    defaultMessage: "Read more",
  },
  ACCEPT_PRIVACY_POLICY: {
    id: "ACCEPT_PRIVACY_POLICY",
    defaultMessage: "Accept privacy policy",
  },
  I_DECLINE: {
    id: "I_DECLINE",
    defaultMessage: "I decline",
  },
  I_ACCEPT: {
    id: "I_ACCEPT",
    defaultMessage: " I accept",
  },
  COMMON_TERMS_AND_CONDITIONS: {
    id: "COMMON_TERMS_AND_CONDITIONS",
    defaultMessage: "Terms and Conditions",
  },
  COMMON_EXTERNAL_WALLET: {
    id: "COMMON_EXTERNAL_WALLET",
    defaultMessage: "External Wallet (MNO)",
  },
  COMMON_NAME_ON_CARD: {
    id: "COMMON_NAME_ON_CARD",
    defaultMessage: "Name on card",
  },
  COMMON_WALLET_TEXT: {
    id: "COMMON_WALLET_TEXT",
    defaultMessage:
      "I certify that all the information provided by me for this FlexiSave account application is correct.",
  },
  SAVINGS_TRANSACTION_SOURCE: {
    id: "SAVINGS_TRANSACTION_SOURCE",
    defaultMessage: "FlexiSave account",
  },
  SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_1: {
    id: "SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_1",
    defaultMessage: "Grow your savings with flexible deposits",
  },
  SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_2: {
    id: "SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_2",
    defaultMessage: "No monthly service fee",
  },
  SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_3: {
    id: "SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_3",
    defaultMessage: "First withdrawal in a month is free",
  },
  SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_4: {
    id: "SAVINGS_OPEN_ACCOUNT_WELCOME_TEXT_4",
    defaultMessage: "Interest rates up to {highestRate}",
  },
  SAVINGS_EXISTING_CUSTOMER_TEXT: {
    id: "SAVINGS_EXISTING_CUSTOMER_TEXT",
    defaultMessage:
      "Your details are already recorded with us. Please click proceed to review & confirm your application",
  },
  SAVINGS_EXISTING_CUSTOMER: {
    id: "SAVINGS_EXISTING_CUSTOMER",
    defaultMessage: " ",
  },
  GROUP_SAVINGS: {
    id: "GROUP_SAVINGS",
    defaultMessage: " Group Savings",
  },
  SAVINGS_CONGRATULATIONS: {
    id: "SAVINGS_CONGRATULATIONS",
    defaultMessage: "Congratulations!",
  },
  SAVINGS_CREATION_SUCCESS: {
    id: "SAVINGS_CREATION_SUCCESS",
    defaultMessage: "Your FlexiSave account is created successfully!",
  },
  SAVINGS_ACCOUNT_TYPE_LABEL: {
    id: "SAVINGS_ACCOUNT_TYPE_LABEL",
    defaultMessage: "Account type",
  },
  SAVINGS_ACCOUNT_NUMBER_LABEL: {
    id: "SAVINGS_ACCOUNT_NUMBER_LABEL",
    defaultMessage: "Account number",
  },
  SAVINGS_ADD_FUNDS_CTA: {
    id: "SAVINGS_ADD_FUNDS_CTA",
    defaultMessage: "Add funds",
  },
  SAVINGS_UPDATE_NOTIFICATION: {
    id: "SAVINGS_UPDATE_NOTIFICATION",
    defaultMessage:
      "You will get an update on your registered mobile number {phone} within 24 hours",
  },
  SAVINGS_GO_TO_DASHBOARD_CTA: {
    id: "SAVINGS_GO_TO_DASHBOARD_CTA",
    defaultMessage: "Go to Dashboard",
  },
  SAVINGS_RENDER_AMOUNT_BETWEEN_TYPE: {
    id: "SAVINGS_RENDER_AMOUNT_BETWEEN_TYPE",
    defaultMessage: "Between N$ {minAmount} - N$ {maxAmount}",
  },
  SAVINGS_RENDER_AMOUNT_GREATER_TYPE: {
    id: "SAVINGS_RENDER_AMOUNT_GREATER_TYPE",
    defaultMessage: "Greater than N$ {minAmount}",
  },
  SAVINGS_TABLE_AMOUNT_TITLE: {
    id: "SAVINGS_TABLE_AMOUNT_TITLE",
    defaultMessage: "Balance amount",
  },
  SAVINGS_TABLE_RATES_TITLE: {
    id: "SAVINGS_TABLE_RATES_TITLE",
    defaultMessage: "Interest rate",
  },
  DATA_SHARING_POLICY: {
    id: "DATA_SHARING_POLICY",
    defaultMessage:
      "We follow strict security protocol & will never share your details with a third party",
  },
  MISSING_FIELDS_ERROR: {
    id: "MISSING_FIELDS_ERROR",
    defaultMessage:
      "Please fill your missing details so we can proceed with your application smoothly!",
  },
  ADDED_FUNDS_TO: {
    id: "ADDED_FUNDS_TO",
    defaultMessage: "Added funds to your",
  },
  RENOVATE_MY_HOME: {
    id: "RENOVATE_MY_HOME",
    defaultMessage: "Renovate my home",
  },
  BUY_MY_AFFORDABLE_PLOT: {
    id: "BUY_MY_AFFORDABLE_PLOT",
    defaultMessage: "Buy my affordable Plot",
  },
  STEPS: {
    id: "STEPS",
    defaultMessage: "Steps",
  },
  GO_TO_NEAREST_BRANCH: {
    id: "GO_TO_NEAREST_BRANCH",
    defaultMessage:
      "Please to go your nearest branch to sign your loan agreement document",
  },
  BRING_YOUR_ID_CARD: {
    id: "BRING_YOUR_ID_CARD",
    defaultMessage: "Please bring your ID document with you",
  },
  GREAT_GOING: {
    id: "GREAT_GOING",
    defaultMessage: "Great going",
  },
  SALES_AGREEMENT_DOCUMENT_DESC: {
    id: "SALES_AGREEMENT_DOCUMENT_DESC",
    defaultMessage: "Upload your sales agreement here",
  },
  SALES_AGREEMENT_DOCUMENT: {
    id: "SALES_AGREEMENT_DOCUMENT",
    defaultMessage: "Deed of Sale/Sale Agreement",
  },
  BILL_OF_QUANTITIES_DOCUMENT: {
    id: "BILL_OF_QUANTITIES_DOCUMENT",
    defaultMessage: "Bill of Quantities",
  },
  BILL_OF_QUANTITIES_DESC: {
    id: "BILL_OF_QUANTITIES_DESC",
    defaultMessage: "Upload your bill of quantities",
  },
  BUILDING_PLAN_DOCUMENT: {
    id: "BUILDING_PLAN_DOCUMENT",
    defaultMessage: "Building Plan",
  },
  BUILDING_PLAN_DOCUMENT_DESC: {
    id: "BUILDING_PLAN_DOCUMENT_DESC",
    defaultMessage: "Upload your building plan",
  },
  LOAN_AGREEMENT_SIGN_CALL: {
    id: "LOAN_AGREEMENT_SIGN_CALL",
    defaultMessage:
      "Please be advised that you will be contacted to sign the loan agreement documents",
  },
  HOUSING_PRE_APPROVED_AMOUNT_TEXT: {
    id: "HOUSING_PRE_APPROVED_AMOUNT_TEXT",
    defaultMessage: "You have been pre-approved for",
  },
  LETSGO_BORROW_TITLE: {
    id: "LETSGO_BORROW_TITLE",
    defaultMessage: "LetsGo Borrow",
  },
  LETSGO_PAY_TITLE: {
    id: "LETSGO_PAY_TITLE",
    defaultMessage: "LetsGo Pay",
  },
  LETSGO_SAVE_TITLE: {
    id: "LETSGO_SAVE_TITLE",
    defaultMessage: "LetsGo Save",
  },
  LETSGO_INSURE_TITLE: {
    id: "LETSGO_INSURE_TITLE",
    defaultMessage: "LetsGo Insure",
  },
  LETSGO_WELLBEING_TITLE: {
    id: "LETSGO_WELLBEING_TITLE",
    defaultMessage: "LetsGo WellBeing",
  },
  LETSGO_MARKET_PLACE_TITLE: {
    id: "LETSGO_MARKET_PLACE_TITLE",
    defaultMessage: "LetsGo Market Place",
  },
  ACTION_SUCCESS: {
    id: "ACTION_SUCCESS",
    defaultMessage: "Done",
  },
  COMMON_SOURCE_OF_EARNING_FIXEDINCOME: {
    id: "COMMON_SOURCE_OF_EARNING_FIXEDINCOME",
    defaultMessage: "Fixed Income",
  },
  COMMON_SOURCE_OF_EARNING_GOVERNMENTGIFT: {
    id: "COMMON_SOURCE_OF_EARNING_GOVERNMENTGIFT",
    defaultMessage: "Government Gift",
  },
  FINAL_DECISION_INFO: {
    id: "FINAL_DECISION_INFO",
    defaultMessage:
      "Unfortunately, we cannot proceed with your application at the moment.Please visit the branch in order to request change in your KYC details",
  },
  COMMON_INSTANT_LOAN_HEADER: {
    id: "COMMON_INSTANT_LOAN_HEADER",
    defaultMessage: "Instant <custom>Loans</custom>",
  },
  COMMON_RECEIVE_LOAN_TEXT: {
    id: "COMMON_RECEIVE_LOAN_TEXT",
    defaultMessage:
      "You are about to receive instant loan in couple of minutes",
  },
  EXPLORE_INSTANT_LOAN_TEXT: {
    id: "EXPLORE_INSTANT_LOAN_TEXT",
    defaultMessage:
      "Explore our <custom>LetsGo</custom> {productName} up to  <custom>{amount}</custom> ",
  },
  DISBURSTMENT_MODE_QUESTION: {
    id: "DISBURSTMENT_MODE_QUESTION",
    defaultMessage: "Select Disburstment Mode",
  },
  DISBURSTMENT_REPAYMENT_MODE_QUESTION: {
    id: "DISBURSTMENT_REPAYMENT_MODE_QUESTION",
    defaultMessage: "Disbursement/repayment mode",
  },
  IL_REGISTER_YOUR_WALLLET: {
    id: "IL_REGISTER_YOUR_WALLLET",
    defaultMessage:
      "Register your wallet account for instant loan disbursement and repayment.",
  },
  IL_SECURITY_SAME_WALLET: {
    id: "IL_SECURITY_SAME_WALLET",
    defaultMessage:
      "We will use the same wallet account for disbursement and loan repayment.",
  },
  INSTANT_LOAN_GREAT_TEXT: {
    id: "INSTANT_LOAN_GREAT_TEXT",
    defaultMessage: "That's great",
  },
  INSTANT_LOAN_CHARGES_TEXT: {
    id: "INSTANT_LOAN_CHARGES_TEXT",
    defaultMessage:
      "Please note, you will be applicable for application fee <custom>{applicationFee}</custom>, on amount of <custom>{amount}</custom> for <custom>{tenure} days.</custom> Total amount to repay is <custom>{totalAmount}</custom>",
  },
  INSTANT_LOAN_CONFUSED_TEXT: {
    id: "INSTANT_LOAN_CONFUSED_TEXT",
    defaultMessage: "Confused? Know more about the charges",
  },
  COMMON_REJECT_LOAN_QUESTION: {
    id: "COMMON_REJECT_LOAN_QUESTION",
    defaultMessage:
      "You will loose this loan offer. Are you sure you want to reject?",
  },
  COMMON_SELECT_REASON_FOR_REJECTION: {
    id: "COMMON_SELECT_REASON_FOR_REJECTION",
    defaultMessage: "Select a reason for rejection",
  },
  COMMON_LOAN_OFFER_LOWER: {
    id: "COMMON_LOAN_OFFER_LOWER",
    defaultMessage: "Loan offer is much lower than was requestd",
  },
  COMMON_HAVE_ANOTHER_OFFER: {
    id: "COMMON_HAVE_ANOTHER_OFFER",
    defaultMessage: "I got a better offer with another lender",
  },
  COMMON_COLLECTION_METHOD_TEXT: {
    id: "COMMON_COLLECTION_METHOD_TEXT",
    defaultMessage: "Collection method",
  },
  COMMON_FNB_QUESTION_TEXT: {
    id: "COMMON_FNB_QUESTION_TEXT",
    defaultMessage: "Do you have a registered FNB Bank Account?",
  },
  COMMON_AUTO_DEBIT_INFO: {
    id: "COMMON_AUTO_DEBIT_INFO",
    defaultMessage: "Set Auto-debit mandate & worry less about late repayments",
  },
  COMMON_REPAYMENT_TEXT: {
    id: "COMMON_REPAYMENT_TEXT",
    defaultMessage: "Repayment",
  },
  COMMON_METHOD_TEXT: {
    id: "COMMON_METHOD_TEXT",
    defaultMessage: "Method",
  },
  COMMON_REPAYMENT_SUB_TEXT: {
    id: "COMMON_REPAYMENT_SUB_TEXT",
    defaultMessage: "Register your bank card to pay the monthly instalments",
  },
  REPAYMENT_SUB_TEXT: {
    id: "REPAYMENT_SUB_TEXT",
    defaultMessage:
      "Register your bank account for instant loan disbursement and repayment.",
  },
  COMMON_BANK_ACCOUNT: {
    id: "COMMON_BANK_ACCOUNT",
    defaultMessage: "Bank Account",
  },
  DISBURSEMENT_MODE_TITLE: {
    id: "DISBURSEMENT_MODE_TITLE",
    defaultMessage: "You did it",
  },
  DISBURSEMENT_MODE_SUB_TITLE: {
    id: "DISBURSEMENT_MODE_SUB_TITLE",
    defaultMessage: "Please tell us where do you wish to receive the funds?",
  },
  ALTERNATE_DETAILS_SUB_TITLE: {
    id: "ALTERNATE_DETAILS_SUB_TITLE",
    defaultMessage:
      "Please help us with alternative details as follow-up for fast disbursal",
  },
  CAN_YOU_PLEASE: {
    id: "CAN_YOU_PLEASE",
    defaultMessage: "Hey {customer}, Can you please",
  },
  COMMON_LINK_TO_WALLET: {
    id: "COMMON_LINK_TO_WALLET",
    defaultMessage: "Link your MNO wallet",
  },
  COMMON_AUTO_DEBIT_REPAYMENT_TEXT: {
    id: "COMMON_AUTO_DEBIT_REPAYMENT_TEXT",
    defaultMessage:
      "I would like to set mandate for enhanced-debit option for repayment",
  },
  COMMON_USER_COLLECTION_METHOD_TEXT: {
    id: "COMMON_COLLECTION_METHOD_TEXT",
    defaultMessage:
      "Hey {customer}, do you have a registered FNB Bank Account?",
  },
  COMMON_NO: {
    id: "COMMON_NO",
    defaultMessage: "No",
  },
  COMMON_BRANCH_LABEL: {
    id: "COMMON_BRANCH_LABEL",
    defaultMessage: "Branch",
  },
  COMMON_ACCEPT_ALL_LABEL: {
    id: "COMMON_ACCEPT_ALL_LABEL",
    defaultMessage: "I accept all",
  },
  COMMON_FNB_TEXT: {
    id: "COMMON_FNB_TEXT",
    defaultMessage: "FNB Account",
  },
  COMMON_CREDIT_CARD_TEXT: {
    id: "COMMON_CREDIT_CARD_TEXT",
    defaultMessage: "Credit Card",
  },
  LETSGO_HEALTH: {
    id: "LETSGO_HEALTH",
    defaultMessage: "LetsGo <custom>Health</custom>",
  },
  CONFIRM_YOUR_DETAILS: {
    id: "CONFIRM_YOUR_DETAILS",
    defaultMessage: "Confirm your details?",
  },
  WE_ARE_EXCITED: {
    id: "WE_ARE_EXCITED",
    defaultMessage: "We are excited",
  },
  TO_KNOW_YOU: {
    id: "TO_KNOW_YOU",
    defaultMessage: "To get to know you",
  },
  HOW_MANY_DEPENDENTS: {
    id: "HOW_MANY_DEPENDENTS",
    defaultMessage: "How many dependents do you have?",
  },
  OKAY: {
    id: "OKAY",
    defaultMessage: "Okay,",
  },
  THAT_NICE: {
    id: "THAT_NICE",
    defaultMessage: "That's nice",
  },
  RESIDENTIAL_QUESTION: {
    id: "RESIDENTIAL_QUESTION",
    defaultMessage: "What is your residential address?",
  },
  RESIDENTIAL_CITY: {
    id: "RESIDENTIAL_CITY",
    defaultMessage: "Your City?",
  },
  MORE_QUESTIONS_TO_OFFER: {
    id: "MORE_QUESTIONS_TO_OFFER",
    defaultMessage: "A few more questions to offer",
  },
  BEST_TAILORED_LOAN: {
    id: "BEST_TAILORED_LOAN",
    defaultMessage: "The best tailored loan",
  },
  DO_YOU_OWN_CAR: {
    id: "DO_YOU_OWN_CAR",
    defaultMessage: "Do you own a car?",
  },
  WHICH_CAR_YOU_OWN: {
    id: "WHICH_CAR_YOU_OWN",
    defaultMessage: "Amazing, which car do you own?",
  },
  MANUFACTURING_YEAR: {
    id: "MANUFACTURING_YEAR",
    defaultMessage: "What is the manufacturing year?",
  },
  HIGHEST_QUALIFICATION_QUESTION: {
    id: "HIGHEST_QUALIFICATION_QUESTION",
    defaultMessage: "What is your highest qualification?",
  },
  HOW_YOU_IDENTIFY_YOURSELF: {
    id: "HOW_YOU_IDENTIFY_YOURSELF",
    defaultMessage: "How do you identify yourself?",
  },
  INDUSTRY_OF_EMPLOYEMENT: {
    id: "INDUSTRY_OF_EMPLOYEMENT",
    defaultMessage: "What is the industry of employment?",
  },
  YEARLY_INCOME: {
    id: "YEARLY_INCOME",
    defaultMessage: "What is your yearly income?",
  },
  SOURCE_OF_EARNINGS: {
    id: "SOURCE_OF_EARNINGS",
    defaultMessage: "What is your source of earnings?",
  },
  ANY_RUNNING_LOANS: {
    id: "ANY_RUNNING_LOANS",
    defaultMessage: "Do you have any other running loans?",
  },
  TOTAL_REPAYMENT_SUM_DUE: {
    id: "TOTAL_REPAYMENT_SUM_DUE",
    defaultMessage: "What is your total monthly repayment sum due?",
  },
  FIXED_INCOME: {
    id: "FIXED_INCOME",
    defaultMessage: "Salary/Business",
  },
  GOVERMENT_GIFT: {
    id: "GOVERMENT_GIFT",
    defaultMessage: "Government allowance/Grant",
  },
  OOPS: {
    id: "OOPs",
    defaultMessage: "Oops!",
  },
  UHUH: {
    id: "UHUH",
    defaultMessage: "Uh-h!",
  },
  YEARLY_EARNINGS_QUESTION: {
    id: "YEARLY_EARNINGS_QUESTION",
    defaultMessage: "What is your yearly earnings?",
  },
  INCOME_SOURCE_QUESTION: {
    id: "INCOME_SOURCE_QUESTION",
    defaultMessage: "What is your source of earnings?",
  },
  HAVE_RUNNING_LOANS_QUESTION: {
    id: "HAVE_RUNNING_LOANS_QUESTION",
    defaultMessage: "Do you have any other running loans?",
  },
  TOTAL_REPAYMENT_DUE_QUESTION: {
    id: "TOTAL_REPAYMENT_DUE_QUESTION",
    defaultMessage: "What is your total monthly repayment sum due?",
  },
  COMMON_EMPLOYMENT_TYPE_QUESTION: {
    id: "COMMON_EMPLOYMENT_TYPE_QUESTION",
    defaultMessage: "How do you identify yourself?",
  },
  COMMON_EMPLOYMENT_INDUSTRY_QUESTION: {
    id: "COMMON_EMPLOYMENT_INDUSTRY_QUESTION",
    defaultMessage: "What is the industry of employment?",
  },
  COMMON_HIGHER_EDUCATION_QUESTION: {
    id: " COMMON_HIGHER_EDUCATION_QUESTION",
    defaultMessage: "What is your highest qualification? ",
  },
  COMMON_FEW_MORE_QUESTION: {
    id: "COMMON_FEW_MORE_QUESTIONS",
    defaultMessage: "A few more questions to offer",
  },
  COMMON_BEST_TAILORED_QUESTION: {
    id: "COMMON_BEST_TAILORED_QUESTION",
    defaultMessage: "The best tailored loan",
  },
  COMMON_REJECT_LOAN_OFFER: {
    id: "COMMON_REJECT_LOAN_OFFER",
    defaultMessage: "Reject loan offer",
  },
  COMMON_LOAN_AMOUNT_WELLDONE_TEXT: {
    id: "COMMON_WELLDONE_TEXT",
    defaultMessage:
      "Well done! Your loan amount of {amount} will be disbursed soon",
  },
  COMMON_LOAN_AMOUNT_DISBURSED_SOON_TEXT: {
    id: "COMMON_LOAN_AMOUNT_DISBURSED_SOON_TEXT",
    defaultMessage: "will be disbursed soon.",
  },
  COMMON_EXPLORE_TEXT: {
    id: "COMMON_EXPLORE_TEXT",
    defaultMessage:
      "Meanwhile, you can explore products and services on our Digital Mall.",
  },
  LETSGO_DOCTOR_SELECT_DOCTOR_HEADING_PART_ONE: {
    id: "LETSGO_DOCTOR_SELECT_DOCTOR_HEADING_PART_ONE",
    defaultMessage: "Please select",
  },
  LETSGO_DOCTOR_SELECT_DOCTOR_HEADING_PART_TWO: {
    id: "LETSGO_DOCTOR_SELECT_DOCTOR_HEADING_PART_TWO",
    defaultMessage: "Your Medical Expert",
  },
  LETSGO_SELECT_DOCTOR: {
    id: "LETSGO_SELECT_DOCTOR",
    defaultMessage: "Doctors",
  },
  INSTANT_LOAN_APPLIED_SIGNATURE_REQUIRED: {
    id: "INSTANT_LOAN_APPLIED_SIGNATURE_REQUIRED",
    defaultMessage:
      "Please visit the nearest branch to provide your signature to get the loan amount disbursed to your account. {signatureTimeLine}",
  },
  COMING_SOON_2: {
    id: "COMING_SOON_2",
    defaultMessage: "Coming soon",
  },
  SAVINGS_OPEN_ACCOUNT_WELCOME_COMMON_TEXT_1: {
    id: "SAVINGS_OPEN_ACCOUNT_WELCOME_COMMON_TEXT_1",
    defaultMessage: "Grow your savings with flexible deposits",
  },
  SAVINGS_OPEN_ACCOUNT_WELCOME_COMMON_TEXT_2: {
    id: "SAVINGS_OPEN_ACCOUNT_WELCOME_COMMON_TEXT_2",
    defaultMessage: "No monthly service fee",
  },
  SAVINGS_NAMIBIA_OPEN_ACCOUNT_WELCOME_TEXT_1: {
    id: "SAVINGS_NAMIBIA_OPEN_ACCOUNT_WELCOME_TEXT_1",
    defaultMessage: "First withdrawal in a month is free",
  },
  SAVINGS_NIGERIA_OPEN_ACCOUNT_WELCOME_TEXT_1: {
    id: "SAVINGS_NIGERIA_OPEN_ACCOUNT_WELCOME_TEXT_1",
    defaultMessage: "All withdrawals are free",
  },
  SAVINGS_OPEN_ACCOUNT_HIGHEST_RATES_TEXT_1: {
    id: "SAVINGS_OPEN_ACCOUNT_HIGHEST_RATES_TEXT_1",
    defaultMessage: "Interest rates up to {highestRate}",
  },
  SAVINGS_COMPLETE_ACTIONS_TEXT: {
    id: "SAVINGS_COMPLETE_ACTIONS_TEXT",
    defaultMessage:
      "Complete actions listed on the right to help us review your Savings application.",
  },
  SAVINGS_TITLE_TRACK_APPLICATION: {
    id: "SAVINGS_TITLE_TRACK_APPLICATION",
    defaultMessage: "Application is initiated",
  },
  SAVINGS_REFERENCE_NUMBER_LABEL: {
    id: "SAVINGS_REFERENCE_NUMBER_LABEL",
    defaultMessage: "Reference number",
  },
  SAVINGS_GO_TO_TRACK_APPLICATION_CTA: {
    id: "SAVINGS_GO_TO_TRACK_APPLICATION_CTA",
    defaultMessage: "Track your application",
  },
  SAVINGS_RENDER_AMOUNT_LESS_TYPE: {
    id: "SAVINGS_RENDER_AMOUNT_LESS_TYPE",
    defaultMessage: "Less than {currencySymbol} {maxAmount}",
  },
  COMMON_HEALTH: {
    id: "COMMON_HEALTH",
    defaultMessage: "Health",
  },
  ELIGIBILTY_INFO_PARA: {
    id: "ELIGIBILTY_INFO_PARA",
    defaultMessage:
      "We have registered your reason of rejection and will work towards providing you better service in the future.In case of queries, please get in touch with our team and they will help you out.",
  },
  ON_BOARDING_ELIGIBILTY_OFFER: {
    id: "ON_BOARDING_ELIGIBILTY_OFFER",
    defaultMessage: "Offer could not be get",
  },
  NOT_ELIGIBLE_EASY_LOAN: {
    id: "NOT_ELIGIBLE_EASY_LOAN",
    defaultMessage: "You are not eligible for an Easy loan",
  },
  NOT_ELIGIBLE_LETSGO_MOTSHEGETSI: {
    id: "NOT_ELIGIBLE_LETSGO_MOTSHEGETSI",
    defaultMessage: "You are not eligible for a Letsgo Motshegetsi",
  },
  SUBTYPE_BACK: {
    id: "SUBTYPE_BACK",
    defaultMessage: "BACK",
  },
  SAVINGS_APPLICATION_APPROVED: {
    id: "SAVINGS_APPLICATION_APPROVED",
    defaultMessage: "Your application is approved",
  },
  SUBTYPE_FRONT: {
    id: "SUBTYPE_FRONT",
    defaultMessage: "FRONT",
  },
  LETSGO_DOCTOR_DETAILS: {
    id: "LETSGO_DOCTOR_DETAILS",
    defaultMessage: "Doctor Details",
  },
  LETSGO_DOCTOR_YEAR_OF_EXPERIENCE_TEXT: {
    id: "LETSGO_DOCTOR_YEAR_OF_EXPERIENCE_TEXT",
    defaultMessage: "Experience: ",
  },
  LETSGO_DOCTOR_YEAR_OF_EXPERIENCE_YEAR: {
    id: "LETSGO_DOCTOR_YEAR_OF_EXPERIENCE_YEAR",
    defaultMessage: "{years} year(s)",
  },
  LETSGO_DOCTOR_YEAR_OF_PAYMENT_METHOD: {
    id: "LETSGO_DOCTOR_YEAR_OF_PAYMENT_METHOD",
    defaultMessage: "Paid using {paymentMethod}",
  },
  LETSGO_DOCTOR_VIEW_INVOICE: {
    id: "LETSGO_DOCTOR_VIEW_INVOICE",
    defaultMessage: "View invoice",
  },
  LETSGO_DOCTOR_YEAR_OF_EXPERIENCE: {
    id: "LETSGO_DOCTOR_YEAR_OF_EXPERIENCE",
    defaultMessage: "Experience: {years} years",
  },
  LETSGO_DOCTOR_SESSION_FEE: {
    id: "LETSGO_DOCTOR_SESSION_FEE",
    defaultMessage: "Session fee",
  },
  LETSO_DOCTOR_PER_SESSION: {
    id: "LETSO_DOCTOR_PER_SESSION",
    defaultMessage: "Per session",
  },
  LETSGO_DOCTOR_TIME_SLOT_PART_ONE: {
    id: "LETSGO_DOCTOR_TIME_SLOT_PART_ONE",
    defaultMessage: "Available",
  },
  LETSGO_DOCTOR_TIME_SLOT_PART_TWO: {
    id: "LETSGO_DOCTOR_TTime slotPART_TWO",
    defaultMessage: "Time slot",
  },
  LETSGO_DOCTOR_SYNTROP_DISCLAIMER: {
    id: "LETSGO_DOCTOR_SYNTROP_DISCLAIMER",
    defaultMessage:
      "Don’t worry! The information you share with your doctor will be stored within the LetsGoSyntro-P application and never be shared with anyone else.",
  },
  LETSGO_DOCTOR_AGREE_AND_BOOK_APPOINTMENT: {
    id: "LETSGO_DOCTOR_AGREE_AND_BOOK_APPOINTMENT",
    defaultMessage: "Agree & Book Appointment",
  },
  LETSGO_HEALTH_EXPLORE_PRODUCTS: {
    id: "LETSGO_HEALTH_EXPLORE_PRODUCTS",
    defaultMessage: "Explore all our health products",
  },
  LETSGO_DOCTOR_SLOT_NOT_AVAILABLE: {
    id: "LETSGO_DOCTOR_SLOT_NOT_AVAILABLE",
    defaultMessage:
      "This slot is no longer available. Please book another appointment slot to continue.",
  },
  LETSGO_DOCTOR_BOOK_APPOINTMENT: {
    id: "LETSGO_DOCTOR_BOOK_APPOINTMENT",
    defaultMessage: "Book Appointment",
  },
  LETSGO_DOCTOR_CONSULT_DOCTOR: {
    id: "LETSGO_DOCTOR_CONSULT_DOCTOR",
    defaultMessage: "Consult a Doctor",
  },
  LETSGO_DOCTOR_TERMS_AND_CONDITIONS: {
    id: "LETSGO_DOCTOR_TERMS_AND_CONDITIONS",
    defaultMessage: "Terms and conditions",
  },
  LETSGO_DOCTOR_PAYMENT_METHOD: {
    id: "LETSGO_DOCTOR_PAYMENT_METHOD",
    defaultMessage: "Payment method",
  },
  LETSGO_DOCTOR_MAKE_PAYMENT: {
    id: "LETSGO_DOCTOR_MAKE_PAYMENT",
    defaultMessage: "Make Payment",
  },
  LETSGO_DOCTOR_ADDITIONAL_DETAILS: {
    id: "LETSGO_DOCTOR_ADDITIONAL_DETAILS",
    defaultMessage: "Additional details",
  },
  LETSGO_DOCTOR_HOW_TO_GET_CONSULT_PART_ONE: {
    id: "LETSGO_DOCTOR_HOW_TO_GET_CONSULT_PART_ONE",
    defaultMessage: "How would you like",
  },
  LETSGO_DOCTOR_HOW_TO_GET_CONSULT_PART_TWO: {
    id: "LETSGO_DOCTOR_HOW_TO_GET_CONSULT_PART_TWO",
    defaultMessage: "your consultation?",
  },
  LETSGO_DOCTOR_SPEAK_TO_CONSULTANT: {
    id: "LETSGO_DOCTOR_SPEAK_TO_CONSULTANT",
    defaultMessage: "Speak live to consultant",
  },
  LETSGO_DOCTOR_CHAT_WITH_CONSULTANT: {
    id: "LETSGO_DOCTOR_CHAT_WITH_CONSULTANT",
    defaultMessage: "Chat live with consultant",
  },
  LETSGO_DOCTOR_PROCEED_TO_PAYMENT: {
    id: "LETSGO_DOCTOR_PROCEED_TO_PAYMENT",
    defaultMessage: "Proceed to payment",
  },
  LETSGO_DOCTOR_SUCCESSFULL_PAYMENT: {
    id: "LETSGO_DOCTOR_SUCCESSFULL_PAYMENT",
    defaultMessage: "Your payment was successful.",
  },
  LETSGO_DOCTOR_NO_APPOINTMENT_MSG: {
    id: "LETSGO_DOCTOR_NO_APPOINTMENT_MSG",
    defaultMessage: "You do not have any past appointments.",
  },
  LETSGO_DOCTOR_NO_APPOINTMENT_CONTENT: {
    id: "LETSGO_DOCTOR_NO_APPOINTMENT_CONTENT",
    defaultMessage:
      "Once you schedule an appointment your appoinment history will show here.",
  },
  LETSGO_DOCTOR_NOTHING_HERE: {
    id: "LETSGO_DOCTOR_NOTHING_HERE",
    defaultMessage: "Nothing Here!",
  },
  LETSGO_DOCTOR_NAME_CTA: {
    id: "LETSGO_DOCTOR_NAME_CTA",
    defaultMessage: "Hey {customerName}!",
  },
  LETSGO_DOCTOR_PAYMENT_INITIATED: {
    id: "LETSGO_DOCTOR_PAYMENT_INITIATED",
    defaultMessage: "Your payment request is initiated.",
  },
  LETSGO_DOCTOR_PAYMENT_INITIATE_FAIL: {
    id: "LETSGO_DOCTOR_PAYMENT_INITIATE_FAIL",
    defaultMessage: "Your payment request failed.",
  },
  LETSGO_DOCTOR_PAYMENT_INITIATED_MSG: {
    id: "LETSGO_DOCTOR_PAYMENT_INITIATED_MSG",
    defaultMessage:
      "We have sent a prompt to approve this payment. To proceed with your appointment, please complete your payment. You will be notified as soon as it is done.",
  },
  LETSGO_DOCTOR_PAYMENT_INITIATED_FAIL_MSG: {
    id: "LETSGO_DOCTOR_PAYMENT_INITIATED_FAIL_MSG",
    defaultMessage:
      "Sorry something went wrong, Please try again  to continue.",
  },
  LETSO_DOCTOR_APPOINTMENT_MESSAGE: {
    id: "LETSO_DOCTOR_APPOINTMENT_MESSAGE",
    defaultMessage:
      "You are number {appointmentNumber} in our line. Please be patient; your appointment will begin shortly. Keep an eye on your notifications and messages!",
  },
  LETSGO_DOCTOR_INVOICE_DETAILS_TITILE: {
    id: "LETSGO_DOCTOR_INVOICE_DETAILS_TITILE",
    defaultMessage: "Invoice Details",
  },
  LETSGO_DOCTOR_INVOICE_ITEMS_TITILE: {
    id: "LETSGO_DOCTOR_INVOICE_ITEMS_TITILE",
    defaultMessage: "Invoice items",
  },
  LETSGO_DOCTOR_INVOICE_DATE: {
    id: "LETSGO_DOCTOR_INVOICE_DATE",
    defaultMessage: "Invoice date",
  },
  LETSGO_DOCTOR_INVOICE_NUMBER: {
    id: "LETSGO_DOCTOR_INVOICE_NUMBER",
    defaultMessage: "Invoice number",
  },
  LETSGO_DOCTOR_DETAILS_SECTION: {
    id: "LETSGO_DOCTOR_DETAILS_SECTION",
    defaultMessage: "Doctor details",
  },
  LETSGO_DOCTOR_CONSULTATION_SECTION: {
    id: "LETSGO_DOCTOR_CONSULTATION_SECTION",
    defaultMessage: "Consultation details",
  },
  LETSGO_DOCTOR_PAYMENTS_SECTION: {
    id: "LETSGO_DOCTOR_PAYMENTS_SECTION",
    defaultMessage: "Payments details",
  },
  LETSGO_DOCTOR_CUSTOMER_ID: {
    id: "LETSGO_DOCTOR_CUSTOMER_ID",
    defaultMessage: "Customer ID",
  },
  LETSGO_DOCTOR_PATIENT_NAME: {
    id: "LETSGO_DOCTOR_PATIENT_NAME",
    defaultMessage: "Patient name",
  },
  LETSGO_DOCTOR_APPOINTMENT_TYPE: {
    id: "LETSGO_DOCTOR_APPOINTMENT_TYPE",
    defaultMessage: "Appointment type",
  },
  LETSGO_DOCTOR_APPOINTMENT_ID: {
    id: "LETSGO_DOCTOR_APPOINTMENT_ID",
    defaultMessage: "Appointment ID",
  },
  LETSGO_DOCTOR_APPOINTMENT_DATE: {
    id: "LETSGO_DOCTOR_APPOINTMENT_DATE",
    defaultMessage: "Appointment date",
  },
  LETSGO_DOCTOR_PAYMENT_AMOUNT: {
    id: "LETSGO_DOCTOR_PAYMENT_AMOUNT",
    defaultMessage: "Payment amount",
  },
  LETSGO_DOCTOR_PAYMENT_REFERENCE: {
    id: "LETSGO_DOCTOR_PAYMENT_REFERENCE",
    defaultMessage: "Payment reference",
  },
  LETSGO_DOCTOR_ITEM_NUMBER: {
    id: "LETSGO_DOCTOR_ITEM_NUMBER",
    defaultMessage: "Item number",
  },
  LETSGO_DOCTOR_DOWNLOAD_INVOICE: {
    id: "LETSGO_DOCTOR_DOWNLOAD_INVOICE",
    defaultMessage: "Download invoice",
  },
  LETSGO_DOCTOR_EMAIL_INVOICE: {
    id: "LETSGO_DOCTOR_EMAIL_INVOICE",
    defaultMessage: "Email invoice",
  },
  LETSGO_DOCTOR_DISCIPLINE: {
    id: "LETSGO_DOCTOR_DISCIPLINE",
    defaultMessage: "Discipline",
  },
  LETSGO_DOCTOR_NAME: {
    id: "LETSGO_DOCTOR_NAME",
    defaultMessage: "Doctor Name",
  },
  LETSGO_DOCTOR_ID: {
    id: "LETSGO_DOCTOR_ID",
    defaultMessage: "Doctor ID",
  },
  LETSGO_DOCTOR_INVOICE_DETAILS: {
    id: "LETSGO_DOCTOR_INVOICE_DETAILS",
    defaultMessage: "Invoice Details",
  },
  LETSGO_DOCTOR_GO_TO_HOME_CTA: {
    id: "LETSGO_DOCTOR_GO_TO_HOME_CTA",
    defaultMessage: "Go to Home",
  },
  LETSGO_DOCTOR_GO_TO_DASHBOARD_CTA: {
    id: "LETSGO_DOCTOR_GO_TO_DASHBOARD_CTA",
    defaultMessage: "Back to dashboard",
  },
  SERVER_ERROR_MSG: {
    id: "SERVER_ERROR_MSG",
    defaultMessage: "We experienced a server error",
  },
  LETSGO_DOCTOR_SERVER_ERROR_INFO: {
    id: "LETSGO_DOCTOR_SERVER_ERROR_INFO",
    defaultMessage:
      "Unfortunately, we are currently unable to load the necessary information for payment.{br}Please try again to proceed.",
  },
  SERVER_ERROR_VERIFICATION_CUSTOMER_INFO: {
    id: "SERVER_ERROR_VERIFICATION_CUSTOMER_INFO",
    defaultMessage: "For any queries please contact our {br}{link}",
  },
  TXT_DOWNLOAD_APP: {
    id: "TXT_DOWNLOAD_APP",
    defaultMessage: "Download App",
  },
  TXT_TRUSTABLE: {
    id: "TXT_TRUSTABLE",
    defaultMessage: "Your most trustable",
  },
  TXT_PAYMENT_APP: {
    id: "TXT_PAYMENT_APP",
    defaultMessage: "Payment App",
  },
  TXT_SEND_CASH: {
    id: "TXT_SEND_CASH",
    defaultMessage: "Send cash, receive cash, pay bills and do so much more.",
  },
  TXT_SEND_FUND: {
    id: "TXT_SEND_FUND",
    defaultMessage: "Send funds",
  },
  TXT_RECEIVE_FUND: {
    id: "TXT_RECEIVE_FUND",
    defaultMessage: "Receive funds",
  },
  TXT_PAY_BILLS: {
    id: "TXT_PAY_BILLS",
    defaultMessage: "Pay bills",
  },
  TXT_SEND_FUNDS_ANYWERE: {
    id: "TXT_SEND_FUNDS_ANYWERE",
    defaultMessage: "Send funds from anywhere to your friends & loved ones",
  },
  TXT_REQUEST_FOR_FUNDS: {
    id: "TXT_REQUEST_FOR_FUNDS",
    defaultMessage: "Request for funds from your friends & loved ones",
  },
  TXT_MAKE_PAYMENT: {
    id: "TXT_MAKE_PAYMENT",
    defaultMessage: "Make payment for all your bills in an instant",
  },
  TXT_PAYMENT_WORKS: {
    id: "TXT_PAYMENT_WORKS",
    defaultMessage: "How Payments",
  },
  TXT_MONEY_TRANSACTION: {
    id: "TXT_MONEY_TRANSACTION",
    defaultMessage: "Made money transactions simple",
  },
  TXT_REGISTER_WITH_US: {
    id: "TXT_REGISTER_WITH_US",
    defaultMessage: "Register with us on app or web",
  },
  TXT_COMPLETE_KYC: {
    id: "TXT_COMPLETE_KYC",
    defaultMessage: "Complete your KYC for better features",
  },
  TXT_SEND_OR_RECEIVE: {
    id: "TXT_SEND_OR_RECEIVE",
    defaultMessage: "Send or receive money easily",
  },
  TXT_SET_AUTO_PAY: {
    id: "TXT_SET_AUTO_PAY",
    defaultMessage: "Set auto pay and avoid to miss any bill payment",
  },
  TXT_SPLIT_BILLS: {
    id: "TXT_SPLIT_BILLS",
    defaultMessage: "Split bills with your friends and family",
  },

  TXT_EASILY_CAPTURE: {
    id: "TXT_EASILY_CAPTURE",
    defaultMessage: "Easily capture your",
  },
  TXT_CASH_BALANCE: {
    id: "TXT_CASH_BALANCE",
    defaultMessage: "Cash balance",
  },
  TXT_CAPTURE_WALLET: {
    id: "TXT_CAPTURE_WALLET",
    defaultMessage: "Easily capture your wallet and bank balance at a glance",
  },

  TXT_TRANSACTION_JUST_GOT: {
    id: "TXT_TRANSACTION_JUST_GOT",
    defaultMessage: "Transactions just got",
  },
  TXT_EASIER: {
    id: "TXT_EASIER",
    defaultMessage: "Easier",
  },
  TXT_EASILY_MAKE_TRANSACTION: {
    id: "TXT_EASILY_MAKE_TRANSACTION",
    defaultMessage:
      "Easily make transactions from any institution in the country",
  },

  TXT_KEEP_TRACK: {
    id: "TXT_KEEP_TRACK",
    defaultMessage: "Keep track of your",
  },
  TXT_MONEY_FLOW: {
    id: "TXT_MONEY_FLOW",
    defaultMessage: "Money’s flow",
  },
  GET_TO_TRACK: {
    id: "GET_TO_TRACK",
    defaultMessage:
      "Get to track your activites directly from your mobile device",
  },

  TXT_MONEY_TRANSFER: {
    id: "TXT_MONEY_TRANSFER",
    defaultMessage: "Money transfers in few clicks",
  },
  TXT_AVOID_MISSING_PAY_BILLS: {
    id: "TXT_AVOID_MISSING_PAY_BILLS",
    defaultMessage: "Avoid missing to pay bills",
  },
  TXT_REQUEST_FUNDS: {
    id: "TXT_REQUEST_FUNDS",
    defaultMessage: "Request funds",
  },
  TXT_BUY_AIR_TIME: {
    id: "TXT_BUY_AIR_TIME",
    defaultMessage: "Buy airtime & Data",
  },
  TXT_MAKE_PAYMENT_USING_BAR_CODE: {
    id: "TXT_MAKE_PAYMENT_USING_BAR_CODE",
    defaultMessage: "Make payment using bar code",
  },
  TXT_GET_STARTED_WEB: {
    id: "TXT_GET_STARTED_WEB",
    defaultMessage: "Get started via Web",
  },
  TXT_WORKS: {
    id: "TXT_WORKS",
    defaultMessage: "Works",
  },
  TXT_GET_ON_THE_TRAIN: {
    id: "TXT_GET_ON_THE_TRAIN",
    defaultMessage: "Get on the train",
  },
  TXT_DO_MUCH_MORE: {
    id: "TXT_DO_MUCH_MORE",
    defaultMessage: "And do much more",
  },
  TXT_START_NOW: {
    id: "TXT_START_NOW",
    defaultMessage: "Start now",
  },
  TXT_GET_STARTED: {
    id: "TXT_GET_STARTED",
    defaultMessage: "Get started",
  },
  TXT_TERMS_AND_CONDITIONS: {
    id: "TXT_TERMS_AND_CONDITIONS",
    defaultMessage: "Terms and conditions apply",
  },
  TXT_GET_EASY_QUICK: {
    id: "TXT_GET_EASY_QUICK",
    defaultMessage: "Get easy, quick",
  },
  TXT_FAST_LOAN: {
    id: "TXT_FAST_LOAN",
    defaultMessage: "And fast loans",
  },

  TXT_QUICK_TOP_UPS: {
    id: "TXT_QUICK_TOP_UPS",
    defaultMessage:
      "Quick top-ups in 3 easy steps, select your terms to match your needs!",
  },
  TXT_GET_PAYROLL_LOAN: {
    id: "TXT_GET_PAYROLL_LOAN",
    defaultMessage: "Get your payroll loan",
  },
  TXT_EASILY_APPLY: {
    id: "TXT_EASILY_APPLY",
    defaultMessage:
      "Easily apply for a payroll loan in <custom>3 easy steps </custom>",
  },
  TXT_PERSONAL_LOAN: {
    id: "TXT_PERSONAL_LOAN",
    defaultMessage: "Get a personal loan",
  },
  TXT_GET_PERSONAL_LOAN: {
    id: "TXT_GET_PERSONAL_LOAN",
    defaultMessage: "Get a personal loan for your expensis",
  },
  TXT_HOUSING_LOAN: {
    id: "TXT_HOUSING_LOAN",
    defaultMessage: "Get a housing loan",
  },
  TXT_GET_A_HOUSE: {
    id: "TXT_GET_A_HOUSE",
    defaultMessage: "Want to get a house soon, this is your best bet",
  },
  TXT_INSTANT_LOAN: {
    id: "TXT_INSTANT_LOAN",
    defaultMessage: "Get instant loan",
  },
  TXT_YOU_ARE_AT_RIGHT_PLACE: {
    id: "TXT_YOU_ARE_AT_RIGHT_PLACE",
    defaultMessage: "You are at the right place for fast & hassle-free loans",
  },
  TXT_BORROW: {
    id: "TXT_BORROW",
    defaultMessage: "How Borrow",
  },
  TXT_EASLY_GET_YOUR: {
    id: "TXT_EASLY_GET_YOUR",
    defaultMessage: "Easily get your loan when you need it",
  },
  TXT_APPLY_ONLINE: {
    id: "TXT_APPLY_ONLINE",
    defaultMessage: "Apply online",
  },
  TXT_GET_FAST_DECISIONS: {
    id: "TXT_GET_FAST_DECISIONS",
    defaultMessage: "Get  fast decisions",
  },
  TXT_MINIMUM_DOCUMENTS: {
    id: "TXT_MINIMUM_DOCUMENTS",
    defaultMessage: "Minimum documents required",
  },
  TXT_GET_INSTANT_LOANS: {
    id: "TXT_GET_INSTANT_LOANS",
    defaultMessage: "Get instant loans",
  },
  TXT_GET_HOUSING_LOANS: {
    id: "TXT_GET_HOUSING_LOANS",
    defaultMessage: "Get housing loans",
  },
  TXT_CHECK_YOUR: {
    id: "TXT_CHECK_YOUR",
    defaultMessage: "Check your",
  },
  TXT_ELIGIBILITY: {
    id: "TXT_ELIGIBILITY",
    defaultMessage: "Eligibility",
  },
  TXT_EASILY_CHECK_IF: {
    id: "TXT_EASILY_CHECK_IF",
    defaultMessage:
      "Easily check if you qualify for a loan in the easiest way possible",
  },
  TXT_GET_TO_SEE: {
    id: "TXT_GET_TO_SEE",
    defaultMessage: "Get to see the amount",
  },
  TXT_YOU_QUALIFY: {
    id: "TXT_YOU_QUALIFY",
    defaultMessage: "You qualify for",
  },
  TXT_GET_TO_SEE_INSTANTLY: {
    id: "TXT_GET_TO_SEE_INSTANTLY",
    defaultMessage:
      "Get to see instantly the amount you qualify for without having to go into a banking hall",
  },
  TXT_TRACK_YOUR: {
    id: "TXT_TRACK_YOUR",
    defaultMessage: "Track your",
  },
  TXT_LOAN_PROCESS: {
    id: "TXT_LOAN_PROCESS",
    defaultMessage: "Loan process",
  },
  GET_TO_TRACK_LOAN: {
    id: "GET_TO_TRACK_LOAN",
    defaultMessage:
      "Get to track your loan process directly from your mobile device",
  },
  TXT_INSTANTLY: {
    id: "TXT_INSTANTLY",
    defaultMessage: "Instantly open a",
  },
  TXT_SAVING_ACCOUNT: {
    id: "TXT_SAVING_ACCOUNT",
    defaultMessage: "Savings account",
  },
  TXT_CREATE_YOUR: {
    id: "TXT_CREATE_YOUR",
    defaultMessage: "Create your savings account and start saving today.",
  },
  TXT_CHOOSE_FLEXIBLE: {
    id: "TXT_CHOOSE_FLEXIBLE",
    defaultMessage: "Choose a flexible saving option",
  },
  TXT_EASILY_CREATE: {
    id: "TXT_EASILY_CREATE",
    defaultMessage: "Easily create a Flexisave account and start saving today",
  },
  TXT_SAVING_GOALS: {
    id: "TXT_SAVING_GOALS",
    defaultMessage: "Savings goals",
  },
  TXT_START_SMALL: {
    id: "TXT_START_SMALL",
    defaultMessage: "Start small or big to save your dreams",
  },
  TXT_OPEN_FIXED: {
    id: "TXT_OPEN_FIXED",
    defaultMessage: "Open Fixed deposit",
  },
  TXT_OPEN_FD: {
    id: "TXT_OPEN_FD",
    defaultMessage: "Open a FD account & save for your future",
  },
  TXT_HOW_SAVING: {
    id: "TXT_HOW_SAVING",
    defaultMessage: "How Savings",
  },
  TXT_OPEN_SAVING: {
    id: "TXT_OPEN_SAVING",
    defaultMessage: "Open a Savings account instantly",
  },
  TXT_PROVIDE_DETAILS: {
    id: "TXT_PROVIDE_DETAILS",
    defaultMessage: "Provide your details",
  },
  TXT_START_SAVING: {
    id: "TXT_START_SAVING",
    defaultMessage: "Start saving by adding funds",
  },
  TXT_OPEN_A: {
    id: "TXT_OPEN_A",
    defaultMessage: "Open a",
  },
  TXT_FLEXI_SAVE: {
    id: "TXT_FLEXI_SAVE",
    defaultMessage: "FlexiSave account",
  },
  TXT_YOU_CAN_NOW: {
    id: "TXT_YOU_CAN_NOW",
    defaultMessage:
      "You can now open a FlexiSave account instantly with minimum documents required",
  },
  TXT_GET_SEE: {
    id: "TXT_GET_SEE",
    defaultMessage: "Get to see your",
  },
  TXT_AVAILABLE_BALANCE: {
    id: "TXT_AVAILABLE_BALANCE",
    defaultMessage: "Available balance & details",
  },
  TXT_GET_SEE_INSTANTLY: {
    id: "TXT_GET_SEE_INSTANTLY",
    defaultMessage:
      "Get to see instantly the available balance & transaction details without having to go into a banking hall",
  },
  TXT_EASILY: {
    id: "TXT_EASILY",
    defaultMessage: "Easily",
  },
  TXT_ADD_WITHDRAW: {
    id: "TXT_ADD_WITHDRAW",
    defaultMessage: "Add & withdraw funds",
  },
  TXT_EASILY_TRANSFER: {
    id: "TXT_EASILY_TRANSFER",
    defaultMessage:
      "Easily transfer your money by adding and withdrawing funds between your accounts",
  },
  TXT_CHECK_YOUR_BALANCE: {
    id: "TXT_CHECK_YOUR_BALANCE",
    defaultMessage: "Check your available balance",
  },
  TXT_GROW_YOUR: {
    id: "TXT_GROW_YOUR",
    defaultMessage: "Grow your money by adding funds",
  },
  TXT_CHECK_LASTEST: {
    id: "TXT_CHECK_LASTEST",
    defaultMessage: "Check latest transactions",
  },
  TXT_WITHDRAW_FUNDS: {
    id: "TXT_WITHDRAW_FUNDS",
    defaultMessage: "Withdraw funds",
  },
  TXT_ENHANCE: {
    id: "TXT_ENHANCE",
    defaultMessage: "Enhance your",
  },
  TXT_WELLBEING: {
    id: "TXT_WELLBEING",
    defaultMessage: "Wellbeing",
  },
  TXT_ENJOY_WIDE: {
    id: "TXT_ENJOY_WIDE",
    defaultMessage:
      "Enjoy a wide range of wellbeing content for a healthier and more fulfilled life.",
  },
  TXT_ONBOARD: {
    id: "TXT_ONBOARD",
    defaultMessage: "Onboard Easily",
  },
  TXT_LOGIN_TO: {
    id: "TXT_LOGIN_TO",
    defaultMessage:
      "Login to the <custom> Digital Mall </custom> and create your account in just a few steps",
  },
  TXT_ENHANCE_YOUR: {
    id: "TXT_ENHANCE_YOUR",
    defaultMessage: "Enhance your Wellbeing",
  },
  TXT_HEAD_OVER: {
    id: "TXT_HEAD_OVER",
    defaultMessage: "Head over to the welbeing section for curated content",
  },
  TXT_LIVE: {
    id: "TXT_LIVE",
    defaultMessage: "Live Healthier",
  },
  TXT_ENJOY_RANGE: {
    id: "TXT_ENJOY_RANGE",
    defaultMessage: "Enjoy a range of content to improve your life",
  },
  TXT_HOW_WELLBEING: {
    id: "TXT_HOW_WELLBEING",
    defaultMessage: "How Wellbeing",
  },
  TXT_ENHANCE_YOUR_WELLBEING: {
    id: "TXT_ENHANCE_YOUR_WELLBEING",
    defaultMessage: "Enhance your wellbeing with ease",
  },
  TXT_LOGIN_TO_DIGITAL: {
    id: "TXT_LOGIN_TO_DIGITAL",
    defaultMessage: "Login to the Digital Mall",
  },
  TXT_TAP_THE: {
    id: "TXT_TAP_THE",
    defaultMessage: "Tap the wellbeing icon",
  },
  TXT_CONTINUE: {
    id: "TXT_CONTINUE",
    defaultMessage: "Continue to Syntro-P wellbeing",
  },
  TXT_GET_ACCESS: {
    id: "TXT_GET_ACCESS",
    defaultMessage: "Get access to a host of wellbeing content",
  },
  TXT_CONSULT: {
    id: "TXT_CONSULT",
    defaultMessage: "Consult a doctor",
  },
  TXT_NOURISH: {
    id: "TXT_NOURISH",
    defaultMessage: "Nourish",
  },
  TXT_YOURSELF: {
    id: "TXT_YOURSELF",
    defaultMessage: "Yourself",
  },
  TXT_CHOOSE_FROM: {
    id: "TXT_CHOOSE_FROM",
    defaultMessage:
      "Choose from a range of nourishment ideas and healthy recipes",
  },
  TXT_GET: {
    id: "TXT_GET",
    defaultMessage: "Get",
  },
  TXT_MOVING: {
    id: "TXT_MOVING",
    defaultMessage: "Moving",
  },
  TXT_LEARN_FROM: {
    id: "TXT_LEARN_FROM",
    defaultMessage:
      "Learn from exercise tips and yoga routines curated especially for you",
  },
  TXT_LEARN_AND: {
    id: "TXT_LEARN_AND",
    defaultMessage: "Learn and",
  },
  TXT_HEAL: {
    id: "TXT_HEAL",
    defaultMessage: "Heal",
  },
  TXT_WE_HAVE: {
    id: "TXT_WE_HAVE",
    defaultMessage:
      "We have a host of educational and holistic healing content for you to choose from.",
  },
  TXT_NOURISH_YOURSELF: {
    id: "TXT_NOURISH_YOURSELF",
    defaultMessage: "Nourish yourself",
  },
  TXT_EXERCISE_SMARTLY: {
    id: "TXT_EXERCISE_SMARTLY",
    defaultMessage: "Exercise smartly",
  },
  TXT_EDUCATE_YOURSELF: {
    id: "TXT_EDUCATE_YOURSELF",
    defaultMessage: "Educate yourself on your wellbeing",
  },
  TXT_HEAL_HOLISTICALLY: {
    id: "TXT_HEAL_HOLISTICALLY",
    defaultMessage: "Heal holistically",
  },
  TXT_GET_CONTENT: {
    id: "TXT_GET_CONTENT",
    defaultMessage: "Get content that is relevant to you",
  },
  TXT_GET_EXPERT: {
    id: "TXT_GET_EXPERT",
    defaultMessage: "Get expert advise",
  },
  TXT_MAKE_LIFE: {
    id: "TXT_MAKE_LIFE",
    defaultMessage: "Make life better",
  },
  TXT_WITH_INSURANCE: {
    id: "TXT_WITH_INSURANCE",
    defaultMessage: "With insurance cover",
  },
  TXT_QUICK_TOP: {
    id: "TXT_QUICK_TOP",
    defaultMessage:
      "Quick top-ups in 4 easy steps, select your terms to match your needs!",
  },
  TXT_CHOOSE_INSURANCE_PLAN: {
    id: "TXT_CHOOSE_INSURANCE_PLAN",
    defaultMessage:
      "Quick top-ups in 4 easy steps, select your terms to match your needs!",
  },
  TXT_ASSURED: {
    id: "TXT_ASSURED",
    defaultMessage: "Assured insurance plans",
  },
  TXT_CHOOSE_FROM_LIST: {
    id: "TXT_CHOOSE_FROM_LIST",
    defaultMessage: "Choose from our listed insured plans of choice",
  },
  TXT_FULLY_INSURED: {
    id: "TXT_FULLY_INSURED",
    defaultMessage: "Fully insured",
  },
  TXT_ACCESS_THE_BENIFITS: {
    id: "TXT_ACCESS_THE_BENIFITS",
    defaultMessage: "Access the benefits of insuring yourself and loved ones",
  },
  TXT_HOW_INSURANCE: {
    id: "TXT_HOW_INSURANCE",
    defaultMessage: "How insurance",
  },
  TXT_GET_INSURED: {
    id: "TXT_GET_INSURED",
    defaultMessage:
      "Get insured from the comfort of your home, or any any convenient place",
  },
  TXT_TAP_THE_INSURANCE_ICON: {
    id: "TXT_TAP_THE_INSURANCE_ICON",
    defaultMessage: "Tap the LetsGo Insure icon",
  },
  TXT_SELECT_BOUQUET: {
    id: "TXT_SELECT_BOUQUET",
    defaultMessage: "Select from our bouquet of insurance products",
  },
  TXT_ENJOY_BENEFITS: {
    id: "TXT_ENJOY_BENEFITS",
    defaultMessage: "Enjoy the benefits of insuring your loved ones",
  },
  TXT_INSURED: {
    id: "TXT_INSURED",
    defaultMessage: "Insured",
  },
  TXT_EASILY_CHOOSE_INSURANCE: {
    id: "TXT_EASILY_CHOOSE_INSURANCE",
    defaultMessage:
      "Easily choose from our array of insurance packages tailored for you",
  },
  TXT_EASILY_CHOOSE: {
    id: "TXT_EASILY_CHOOSE",
    defaultMessage: "Easily choose",
  },
  TXT_PREFERRED_PLAN: {
    id: "TXT_PREFERRED_PLAN",
    defaultMessage: "Preferred plan",
  },
  TXT_SELECT_COVER_PLAN: {
    id: "TXT_SELECT_COVER_PLAN",
    defaultMessage:
      "Select a cover plan that suits your need from our listed insurance options",
  },
  TXT_GET_A_COVER: {
    id: "TXT_GET_A_COVER",
    defaultMessage: "Get a cover for",
  },
  TXT_LOVED_ONES: {
    id: "TXT_LOVED_ONES",
    defaultMessage: "Your loved ones",
  },
  TXT_COVER_FOR_LOVED_ONES: {
    id: "TXT_COVER_FOR_LOVED_ONES",
    defaultMessage:
      "Easily get cover for those you love to minimize unforseen losses",
  },
  TXT_INSURE_YOURSELF: {
    id: "TXT_INSURE_YOURSELF",
    defaultMessage: "Insure yourself and other family members",
  },
  TXT_EASILY_CHOOSE_PLAN: {
    id: "TXT_EASILY_CHOOSE_PLAN",
    defaultMessage: "Easily choose plan that suits you",
  },
  TXT_EASILY_REQUEST: {
    id: "TXT_EASILY_REQUEST",
    defaultMessage: "Easily request for your claims",
  },
  TXT_EASILY_TRACK: {
    id: "TXT_EASILY_TRACK",
    defaultMessage: "Easily track progress of cover plan",
  },
  TXT_QUICK_AND_EFFICIENT: {
    id: "TXT_QUICK_AND_EFFICIENT",
    defaultMessage: "Quick and efficient claim payment",
  },
  TXT_CHOOSE_PREFERED_PAYMENT: {
    id: "TXT_CHOOSE_PREFERED_PAYMENT",
    defaultMessage: "Choose preferred payment method",
  },
  TXT_STOP: {
    id: "TXT_STOP",
    defaultMessage: "Shop without boundaries",
  },
  TXT_ON_THE: {
    id: "TXT_ON_THE",
    defaultMessage: "On the letsGo Market place",
  },
  TXT_STOP_WHENEVER: {
    id: "TXT_STOP_WHENEVER",
    defaultMessage: "Shop whenever ,with amazing flexible payment terms",
  },
  TXT_FLEXIABLE_PAYMENT: {
    id: "TXT_FLEXIABLE_PAYMENT",
    defaultMessage: "Flexible payment terms ",
  },
  TXT_ENJOY_THE: {
    id: "TXT_ENJOY_THE",
    defaultMessage:
      "Enjoy the flexibility to get what you want and pay over time ",
  },
  TXT_WIDE_RANGE: {
    id: "TXT_WIDE_RANGE",
    defaultMessage: "Wide range of products ",
  },
  TXT_BROWSE_FROM: {
    id: "TXT_BROWSE_FROM",
    defaultMessage: "Browse from our broad range of products",
  },
  TXT_HOW_MARKET_PLACE: {
    id: "TXT_HOW_MARKET_PLACE",
    defaultMessage: "How the Market Place",
  },
  TXT_SHOP_FROM: {
    id: "TXT_SHOP_FROM",
    defaultMessage:
      "Shop from the comfort of your home, or any convenient place",
  },
  TXT_REGISTER: {
    id: "TXT_REGISTER",
    defaultMessage: "Register with us on app or web.",
  },
  TXT_EASILY_BROWSE: {
    id: "TXT_EASILY_BROWSE",
    defaultMessage: "Easily browse our range of products",
  },
  TXT_QUICK_EASY: {
    id: "TXT_QUICK_EASY",
    defaultMessage: "Quick, easy registration for merchants",
  },
  TXT_SELECT_FLEXIABLE: {
    id: "TXT_SELECT_FLEXIABLE",
    defaultMessage: "Select  flexible payment terms & methods",
  },
  TXT_SHOP: {
    id: "TXT_SHOP",
    defaultMessage: "Shop conveniently",
  },
  TXT_FROM_ANYWHERE: {
    id: "TXT_FROM_ANYWHERE",
    defaultMessage: "From anywhere",
  },
  TXT_SHOP_COMFORT: {
    id: "TXT_SHOP_COMFORT",
    defaultMessage:
      "Shop from the comfort of your home on the LetsGo market place, anywhere, anytime",
  },
  TXT_EXPAND: {
    id: "TXT_EXPAND",
    defaultMessage: "Expand your customer base",
  },
  TXT_WITH_YOUR: {
    id: "TXT_WITH_YOUR",
    defaultMessage: "With your product listings",
  },
  TXT_QUICKLY_REGISTER: {
    id: "TXT_QUICKLY_REGISTER",
    defaultMessage:
      "Quickly register and list your products and reach customers from all corners of the world ",
  },
  TXT_PURCHASE: {
    id: "TXT_PURCHASE",
    defaultMessage: "Purchase",
  },
  TXT_KEEP_TAB: {
    id: "TXT_KEEP_TAB",
    defaultMessage: "Keep tabs with progress of your purchase anytime",
  },
  TXT_EASILY_REGISTER: {
    id: "TXT_EASILY_REGISTER",
    defaultMessage: "Easy registration on the mall",
  },
  TXT_SHOP_FROM_ANYHWERE: {
    id: "TXT_SHOP_FROM_ANYHWERE",
    defaultMessage: "Shop from anywhere anytime",
  },
  TXT_SELECT_FAVOURABLE: {
    id: "TXT_SELECT_FAVOURABLE",
    defaultMessage: "Select favourable payment terms",
  },
  TXT_ENJOY_FLEXIABLE: {
    id: "TXT_ENJOY_FLEXIABLE",
    defaultMessage: "Enjoy flexible payment options",
  },
  TXT_TRACK_YOUR_PURCHASE: {
    id: "TXT_TRACK_YOUR_PURCHASE",
    defaultMessage: "Track your purchase from start to end",
  },
  TXT_CONFIRM: {
    id: "TXT_CONFIRM",
    defaultMessage: "Confirm Your",
  },
  TXT_KYC: {
    id: "TXT_KYC",
    defaultMessage: "KYC Details",
  },
  TXT_VERIFY: {
    id: "TXT_VERIFY",
    defaultMessage:
      "Verify your National ID and KYC details with us to increase chances of approval.",
  },
  TXT_GET_SEE_AMOUNT: {
    id: "TXT_GET_SEE_AMOUNT",
    defaultMessage: "Get to see the amount",
  },
  TXT_EASILY_UPLOAD: {
    id: "TXT_EASILY_UPLOAD",
    defaultMessage: "Easy upload of",
  },
  TXT_MINIMUM: {
    id: "TXT_MINIMUM",
    defaultMessage: "Minimum documents",
  },
  TXT_UPLOAD_MANDATORY: {
    id: "TXT_UPLOAD_MANDATORY",
    defaultMessage: "Upload mandatory documents through mobile device",
  },
  TXT_QUICK_APPLICATION: {
    id: "TXT_QUICK_APPLICATION",
    defaultMessage: "Quick and easy application process",
  },
  TXT_EASY_ELIGIBILITY: {
    id: "TXT_EASY_ELIGIBILITY",
    defaultMessage: "Easy eligibility check ",
  },
  TXT_HOW_BUSINESS: {
    id: "TXT_HOW_BUSINESS",
    defaultMessage: "How Business finance",
  },
  TXT_ENHANCE_WELLBEING: {
    id: "TXT_ENHANCE_WELLBEING",
    defaultMessage: "How Business finance",
  },
  TXT_GET_HASSLE: {
    id: "TXT_GET_HASSLE",
    defaultMessage: "Get hassle free",
  },
  TXT_BUSINESS: {
    id: "TXT_BUSINESS",
    defaultMessage: "Business loans",
  },
  /*Housing*/
  LOAN_CALULATOR_HEADING_CTA_HOUSING: {
    id: "LOAN_CALULATOR_HEADING_CTA_HOUSING",
    defaultMessage: "Get started with your housing loan",
  },
  TXT_FOOTER_BECOME_A_PART: {
    id: "TXT_FOOTER_BECOME_A_PART",
    defaultMessage: "Become a part of",
  },
  TXT_FOOTER_JOIN_LETGO_NATION: {
    id: "TXT_FOOTER_JOIN_LETGO_NATION",
    defaultMessage: "The LetsGo nation today",
  },
  TXT_FOOTER_LETGO_NATION_COMMUNITY: {
    id: "TXT_FOOTER_LETGO_NATION_COMMUNITY",
    defaultMessage:
      "A community without boarders that is poised to help you grow.",
  },
  TXT_FOOTER_LETGO_NATION_JOIN_TODAY: {
    id: "TXT_FOOTER_LETGO_NATION_JOIN_TODAY",
    defaultMessage: "Join the letsGo Nation today",
  },
  TXT_HOUSING_HEADER_TITLE: {
    id: "TXT_HOUSING_HEADER_TITLE",
    defaultMessage: "Your dream property",
  },
  TXT_HOUSING_HEADER_SUBTITLE: {
    id: "TXT_HOUSING_HEADER_SUBTITLE",
    defaultMessage: "Is just a few steps away",
  },
  TXT_HOUSING_HEADER_CAPTION: {
    id: "TXT_HOUSING_HEADER_CAPTION",
    defaultMessage:
      "Get the best and affordable housing loan anyone can offer and start building, renovating and purchasing.",
  },
  TXT_HOUSING_OFFERING_TITLE: {
    id: "TXT_HOUSING_OFFERING_TITLE",
    defaultMessage: "We have variety of",
  },
  TXT_HOUSING_OFFERING_SUB_TITLE: {
    id: "TXT_HOUSING_OFFERING_SUB_TITLE",
    defaultMessage: "Housing loan offerings",
  },
  TXT_HOUSING_OFFERING_TERMS_1: {
    id: "TXT_HOUSING_OFFERING_TERMS_1",
    defaultMessage:
      "<custom>LetsGo build</custom> gives you the opportunity to build your home from scratch with or without an existing floor plan.",
  },
  TXT_HOUSING_OFFERING_TERMS_2: {
    id: "TXT_HOUSING_OFFERING_TERMS_2",
    defaultMessage:
      "<custom>LetsGo buy</custom>  offers you the opportunity to buy any home, apartment or multi-unit of your choice.",
  },
  TXT_HOUSING_OFFERING_TERMS_3: {
    id: "TXT_HOUSING_OFFERING_TERMS_3",
    defaultMessage:
      "<custom>LetsGo renovate</custom> offers existing home owners the opportunity to re-design their homes for better opportunities.",
  },
  TXT_HOUSING_OFFERING_TERMS_4: {
    id: "TXT_HOUSING_OFFERING_TERMS_4",
    defaultMessage:
      "<custom>LetsGo plot</custom> offers you the opportunity to invest in landed properties without the hassle of waiting.",
  },
  TXT_HOUSING_DISCOVERY_ELIGIBILITY_CRITERIA_ONE: {
    id: "TXT_HOUSING_DISCOVERY_ELIGIBILITY_CRITERIA_ONE",
    defaultMessage: "If you are self employed employed or permanently employed",
  },
  TXT_HOUSING_DISCOVERY_ELIGIBILITY_CRITERIA_TWO: {
    id: "TXT_HOUSING_DISCOVERY_ELIGIBILITY_CRITERIA_TWO",
    defaultMessage:
      "Must have worked for a minimum of 6 months, with the same employer",
  },
  TXT_HOUSING_DISCOVERY_ELIGIBILITY_CRITERIA_THREE: {
    id: "TXT_HOUSING_DISCOVERY_ELIGIBILITY_CRITERIA_THREE",
    defaultMessage: "Above 18 years of age",
  },
  TXT_HOUSING_DISCOVERY_ELIGIBILITY_SUBTITLE: {
    id: "TXT_HOUSING_DISCOVERY_ELIGIBILITY_SUBTITLE",
    defaultMessage: "Housing loan?",
  },
  TXT_HOUSING_AVAILABLE_ON_WEB_TITLE: {
    id: "TXT_HOUSING_AVAILABLE_ON_WEB_TITLE",
    defaultMessage: "Also available",
  },
  TXT_HOUSING_AVAILABLE_ON_WEB_SUBTITLE: {
    id: "TXT_HOUSING_AVAILABLE_ON_WEB_SUBTITLE",
    defaultMessage: "On web",
  },
  TXT_HOUSING_AVAILABLE_ON_WEB_CAPTION: {
    id: "TXT_HOUSING_AVAILABLE_ON_WEB_CAPTION",
    defaultMessage:
      "We help you reach your financial goals and needs while availing you several channels to access your funds.",
  },
  TXT_HOUSING_AVAILABLE_ON_WEB_CTA_TEXT: {
    id: "TXT_HOUSING_AVAILABLE_ON_WEB_CTA_TEXT",
    defaultMessage: "Get started via Web",
  },
  TXT_HOUSING_DOWNLOAD_SECTION_TITLE: {
    id: "TXT_HOUSING_DOWNLOAD_SECTION_TITLE",
    defaultMessage: "Download the",
  },
  TXT_HOUSING_DOWNLOAD_SECTION_SUB_TITLE: {
    id: "TXT_HOUSING_DOWNLOAD_SECTION_SUB_TITLE",
    defaultMessage:
      "You can download our app to have a full experience today via the channels below.",
  },
  TXT_HOUSING_DOWNLOAD_SECTION_CAPTION: {
    id: "TXT_HOUSING_DOWNLOAD_SECTION_CAPTION",
    defaultMessage: "LetsGo App today",
  },
  TXT_HOUSING_HOW_IT_WORKS_TITLE: {
    id: "TXT_HOUSING_HOW_IT_WORKS_TITLE",
    defaultMessage: "This is how",
  },
  TXT_HOUSING_HOW_IT_WORKS_SUB_TITLE: {
    id: "TXT_HOUSING_HOW_IT_WORKS_SUB_TITLE",
    defaultMessage: "House loan works",
  },
  TXT_HOUSING_HOW_IT_WORKS_CAPTION: {
    id: "TXT_HOUSING_HOW_IT_WORKS_CAPTION",
    defaultMessage:
      "Just in 3 easy steps you will get an approval for your preferred housing loan.",
  },
  DISCOVERY_FAQS_SUBHEADING_TEXT_HOUSING: {
    id: "DISCOVERY_FAQS_SUBHEADING_TEXT_HOUSING",
    defaultMessage:
      "Have some questions? Read our FAQ’s to learn more about Housing loan",
  },
  /*Housing*/
  TXT_PRUCHASE_ORDER: {
    id: "TXT_PRUCHASE_ORDER",
    defaultMessage: "Purchase Order Financing",
  },
  TXT_EASILY_APPLY_PRUCHASE: {
    id: "TXT_EASILY_APPLY_PRUCHASE",
    defaultMessage:
      "Easily apply for a purchase order loan in a few easy steps.",
  },
  TXT_WORKING: {
    id: "TXT_WORKING",
    defaultMessage: "Working Capital loan",
  },
  TXT_GET_LOAN: {
    id: "TXT_GET_LOAN",
    defaultMessage: "Get a loan for your business operating expenses",
  },
  TXT_GREEN: {
    id: "TXT_GREEN",
    defaultMessage: "Green Financing loan",
  },
  TXT_ACCESS: {
    id: "TXT_ACCESS",
    defaultMessage: "Access the benefits of insuring yourself and loved ones",
  },
  GET_YOUR_DREAM_HOME: {
    id: "GET_YOUR_DREAM_HOME",
    defaultMessage: "Get your dream home",
  },
  HOUSING_CAPTION: {
    id: "HOUSING_CAPTION",
    defaultMessage: "Get funds to finance your dream home.",
  },
  FUND_YOUR_BUSINESS: {
    id: "FUND_YOUR_BUSINESS",
    defaultMessage: "Fund your business",
  },
  MSE_CAPTION: {
    id: "MSE_CAPTION",
    defaultMessage: "Secure funds to finance your business.",
  },

  LETSGO_HEADER_PERSONAL: {
    id: "LETSGO_HEADER_PERSONAL",
    defaultMessage: "Personal",
  },
  LETSGO_HEADER_BUSINESS: {
    id: "LETSGO_HEADER_BUSINESS",
    defaultMessage: "Business",
  },
  LETSGO_HEADER_CAREER: {
    id: "LETSGO_HEADER_CAREER",
    defaultMessage: "Career",
  },
  COMMON_WE_ARE_SORRY_TEXT: {
    id: "COMMON_WE_ARE_SORRY_TEXT",
    defaultMessage: "We are Sorry {name}!",
  },
  COMMON_WALLET_NOT_VERIFIED: {
    id: "COMMON_WALLET_NOT_VERIFIED",
    defaultMessage: "We could not verify your wallet",
  },
  MSE_LOAN_ACCOUNT: {
    id: "MSE_LOAN_ACCOUNT",
    defaultMessage: "MSE loan A/c no.",
  },
  YOUR_INSTALLMENT: {
    id: "YOUR_INSTALLMENT",
    defaultMessage: "Your Installment",
  },
  AMOUNT_TO_REPAY: {
    id: "AMOUNT_TO_REPAY",
    defaultMessage: "Amount to repay",
  },
  AMOUNT_TO_DISBURSE: {
    id: "AMOUNT_TO_DISBURSE",
    defaultMessage: "Amount to Disburse",
  },
  CONG_YOU_QUALIFY_UPTO: {
    id: "CONG_YOU_QUALIFY_UPTO",
    defaultMessage: "Congratulations! you qualify to borrow  up to ",
  },
  AVAILABLE_LIMIT: {
    id: "AVAILABLE_LIMIT",
    defaultMessage: "Available Limit",
  },
  BUSINESS: {
    id: "BUSINESS",
    defaultMessage: "Business",
  },
  LOAN: {
    id: "LOAN",
    defaultMessage: "Loan",
  },
  BUSINESS_LOAN: {
    id: "BUSINESS_LOAN",
    defaultMessage: "Business Loan",
  },
  EXPLORE_BUSINESS_LOAN_PRODUCTS: {
    id: "EXPLORE_BUSINESS_LOAN_PRODUCTS",
    defaultMessage: "Explore all our business loan products",
  },
  INSURANCE_OF_THE_LOAN_AMOUNT: {
    id: "INSURANCE_OF_THE_LOAN_AMOUNT",
    defaultMessage: "{percent}% of the loan amount",
  },
  PO_BORROW_UP_TO: {
    id: "PO_BORROW_UP_TO",
    defaultMessage: "You can borrow Purchase Order upto",
  },
  MSE_MONTHLY_PAYMENT_CALCULATION_TEXT: {
    id: "MSE_MONTHLY_PAYMENT_CALCULATION_TEXT",
    defaultMessage:
      "Your above installment and amount to repay shows the maximum amount due at end of the maximum tenure of",
  },
  MSE_MONTHLY_PAYMENT_CALCULATION_TEXT_2: {
    id: "MSE_MONTHLY_PAYMENT_CALCULATION_TEXT_2",
    defaultMessage: "Please NOTE interest rate is ",
  },
  PER_MONTH_TXT: {
    id: "PER_MONTH_TXT",
    defaultMessage: "per month.",
  },
  RECEIVE_FUNDS_TEXT: {
    id: "RECEIVE_FUNDS_TEXT",
    defaultMessage: "Receiving funds made quick and hassel free!",
  },
  RECEIVE_FUNDS_HIHGLIGHT: {
    id: "RECEIVE_FUNDS_HIHGLIGHT",
    defaultMessage: "Receive funds from your peers anytime.",
  },
  KYC_0_RECEIVE_FUNDS_INFO: {
    id: "KYC_0_RECEIVE_FUNDS_INFO",
    defaultMessage:
      "Help us with a few details so you can instantly receive funds from your friends and family.",
  },
  RECEIVE_FUND_OPTION_ONE: {
    id: "RECEIVE_FUND_OPTION_ONE",
    defaultMessage: "Search by Phone Number",
  },
  RECEIVE_FUND_OPTION_TWO: {
    id: "RECEIVE_FUND_OPTION_TWO",
    defaultMessage: "Request from group",
  },
  RECEIVE_FUND_OPTION_THREE: {
    id: "RECEIVE_FUND_OPTION_THREE",
    defaultMessage: "Scan QR code",
  },
  COMMON_WAIT_WALLET_VERIFICATION: {
    id: "COMMON_WAIT_WALLET_VERIFICATION",
    defaultMessage: "Please wait while we verify your wallet details",
  },
  CARD_NUMBER_QUERY: {
    id: "CARD_NUMBER_QUERY",
    defaultMessage: "What is your Card number?",
  },
  COMMON_POBOX_ADDRESS: {
    id: "COMMON_POBOX_ADDRESS",
    defaultMessage: "P.O. Box No.",
  },
  COMMON_ESTATE_HOUSENO_ADDRESS: {
    id: "COMMON_ESTATE_HOUSENO_ADDRESS",
    defaultMessage: "Enter estate / house number",
  },
  COMMON_AREA_HOUSENO_ADDRESS: {
    id: "COMMON_AREA_HOUSENO_ADDRESS",
    defaultMessage: "Enter area / state where you live",
  },
  SELECT_BANK_ACCOUNT: {
    id: "SELECT_BANK_ACCOUNT",
    defaultMessage: "Select bank account",
  },
  LINKED_BANK_ACCOUNT: {
    id: "LINKED_BANK_ACCOUNT",
    defaultMessage: "Linked bank account",
  },
  LETSGO_DOCTOR_APPOINTMENT_HISTORY: {
    id: "LETSGO_DOCTOR_APPOINTMENT_HISTORY",
    defaultMessage: "Appointment History",
  },
  LETSGO_DOCTOR_FOLLOW_UP_DATE: {
    id: "LETSGO_DOCTOR_FOLLOW_UP_DATE",
    defaultMessage: "Follow-up Appointment",
  },
  LETSGO_DOCTOR_APPOINTMENT_DETAILS: {
    id: "LETSGO_DOCTOR_APPOINTMENT_DETAILS",
    defaultMessage: "Appointment Details",
  },
  LETSGO_DOCTOR_PRESCRIPTION_DETAILS: {
    id: "LETSGO_DOCTOR_PRESCRIPTION_DETAILS",
    defaultMessage: "Prescription details",
  },
  LETSGO_DOCTOR_SICK_NOTE: {
    id: "LETSGO_DOCTOR_SICK_NOTE",
    defaultMessage: "Sick Notes",
  },
  SERVICE_UNAVAILABLE_TEXT: {
    id: "SERVICE_UNAVAILABLE_TEXT",
    defaultMessage: "Oops! Wallet is currently unavailable",
  },
  WALLET_SERVICE_DOWN_MSG: {
    id: "WALLET_SERVICE_DOWN_MSG",
    defaultMessage:
      "Wallet payments are currently experiencing an interruption, please bear with us until wallet services are restored or call {customerService} We apologize for any inconvenience caused",
  },
  WALLET_FUNDS_ERROR: {
    id: "WALLET_FUNDS_ERROR",
    defaultMessage:
      "LetsGo wallet is currently experiencing an interruption, please select other payment method to proceed.",
  },
  COMMON_COLLECTION_GREAT_TEXT: {
    id: "COMMON_COLLECTION_GREAT_TEXT",
    defaultMessage: "That’s great. Please choose the method of collection",
  },
  COMMON_BANK_ACCOUNT_HEADER_TEXT: {
    id: "COMMON_BANK_ACCOUNT_HEADER_TEXT",
    defaultMessage: "Register your bank account to pay the monthly instalments",
  },
  COMMON_RENTER_DETAILS: {
    id: "COMMON_RENTER_DETAILS",
    defaultMessage: "Re-enter details",
  },
  TXT_GROW_BUSINESS: {
    id: "TXT_GROW_BUSINESS",
    defaultMessage:
      "Grow your business with us through fast, affordable business financing  ",
  },
  MANAGE_PORTFOLIO: {
    id: "MANAGE_PORTFOLIO",
    defaultMessage: "Manage your <custom>Portfolio</custom>",
  },
  INSTANT_LOAN_ACTIVE_LOAN_ALERT: {
    id: "INSTANT_LOAN_ACTIVE_LOAN_ALERT",
    defaultMessage:
      "Looks like you have an active loan with us. Repay your outstanding amount for a fresh instant loan",
  },
  INSTANT_LOAN_OVERDUE_LOAN_ALERT: {
    id: "INSTANT_LOAN_OVERDUE_LOAN_ALERT",
    defaultMessage:
      "<b>Your loan payment is past due</b>{br}Make your payment today. Please take note that failure to repay may result in a negative credit record. Ignore if you have already paid.{br}For any queries <a>call customer service</a>. T&Cs apply.",
  },
  INSTANT_LOAN_OVERDUE_LOAN_NOTICE: {
    id: "INSTANT_LOAN_OVERDUE_LOAN_NOTICE",
    defaultMessage:
      "Note: Due to late repayment, <b>default interest of {percent}%</b> will be applied.",
  },
  INSTANT_LOAN_REPAYMENT_DURATION: {
    id: "INSTANT_LOAN_REPAYMENT_DURATION",
    defaultMessage: "The loan repayment duration is 7 days, 30 days & 60 days",
  },
  INSTANT_LOAN_REPAYMENT_ALERT: {
    id: "INSTANT_LOAN_REPAYMENT_ALERT",
    defaultMessage:
      "We will use the same bank account for disbursement and loan repayment.",
  },
  INSTANT_LOAN_REPAYMENT_DURATION_LABEL: {
    id: "INSTANT_LOAN_REPAYMENT_DURATION_LABEL",
    defaultMessage: "Repayment Schedule",
  },
  INSTANT_LOAN_REPAYMENT_MODAL_HEADING: {
    id: "INSTANT_LOAN_REPAYMENT_MODAL_HEADING",
    defaultMessage:
      "Depending on the loan repayment duration and the amount, interest rates are applicable",
  },
  MOBILE_NUMBER_VERIFICATION_ERROR: {
    id: "MOBILE_NUMBER_VERIFICATION_ERROR",
    defaultMessage:
      "The disbursement details entered doesn't match our records. Please enter correct details.",
  },
  REFRESH: {
    id: "REFRESH",
    defaultMessage: "Refresh",
  },
  FAQ_BORROW: {
    id: "FAQ_BORROW",
    defaultMessage: "FAQ - Borrow",
  },
  AMOUNT_DEDUCTION_SUB_TEXT_FOR_ACCOUNT: {
    id: "AMOUNT_DEDUCTION_SUB_TEXT_FOR_ACCOUNT",
    defaultMessage: "Will be deducted from your LetsGo account",
  },
  PAYMENT_TIME: {
    id: "PAYMENT_TIME",
    defaultMessage: "Payment time",
  },
  TIME_DEDUCTION_TEXT: {
    id: "TIME_DEDUCTION_TEXT",
    defaultMessage: "The time on which your amount will be deducted",
  },
  COMMON_PAYMENT_OPTION_BANK: {
    id: "COMMON_PAYMENT_OPTION_BANK",
    defaultMessage: "Bank",
  },
  INSTANT_LOAN_SUBMISSION_FAILED_TEXT: {
    id: "INSTANT_LOAN_SUBMISSION_FAILED_TEXT",
    defaultMessage: "Loan application submission failed!",
  },
  INSTANT_LOAN_SUBMISSION_FAILED_SUB_TEXT: {
    id: "INSTANT_LOAN_SUBMISSION_FAILED_TEXT",
    defaultMessage:
      "We regret to inform you that the loan application could not be submitted. We are looking into it.You can get in touch with our team and they will help you out. Meanwhile, you may explore our other products & services.",
  },
  INSTANT_LOAN_LIMIT_REACHED_FAILED_SUB_TEXT_1: {
    id: "INSTANT_LOAN_LIMIT_REACHED_FAILED_SUB_TEXT_1",
    defaultMessage:
      "We currently cannot offer you any products, but please do get in touch with our customer service team who will be able to help you with am alternate solution.",
  },
  INSTANT_LOAN_LIMIT_REACHED_FAILED_SUB_TEXT_2: {
    id: "INSTANT_LOAN_LIMIT_REACHED_FAILED_SUB_TEXT_2",
    defaultMessage: "We hope to be able to serve you better next time.",
  },
  INSTANT_LOAN_CONGRATULATIONS: {
    id: "INSTANT_LOAN_CONGRATULATIONS",
    defaultMessage: "Congratulations {customer} !",
  },
  EXISTING_CUSTOMER_REPAYMENT_DATE: {
    id: "EXISTING_CUSTOMER_REPAYMENT_DATE",
    defaultMessage: "Repayment date:",
  },
  EXISTING_CUSTOMER_REPAYMENT_AMOUNT: {
    id: "EXISTING_CUSTOMER_REPAYMENT_AMOUNT",
    defaultMessage: "Repayment amount",
  },
  EXPLORE_DIGITAL_MALL: {
    id: "EXPLORE_DIGITAL_MALL",
    defaultMessage: "Explore Digital Mall",
  },
  INSTANT_LOAN_ACCOUNT_CREATION_FAILED: {
    id: "INSTANT_LOAN_ACCOUNT_CREATION_FAILED",
    defaultMessage: "Loan account creation failed!",
  },
  INSTANT_LOAN_ACCOUNT_CREATION_FAILED_SUB_TEXT: {
    id: "INSTANT_LOAN_ACCOUNT_CREATION_FAILED_SUB_TEXT",
    defaultMessage:
      "We regret to inform you that we couldn’t create loan account for you. We promise we are looking into this. ",
  },

  INSTANT_LOAN_ACCOUNT_CREATION_FAILED_SUB_TEXT_TWO: {
    id: "INSTANT_LOAN_ACCOUNT_CREATION_FAILED_SUB_TEXT",
    defaultMessage:
      "You can get in touch with our team and they will help you out.",
  },

  INSTANT_LOAN_DISBURSEMENT_FAILED: {
    id: "INSTANT_LOAN_DISBURSEMENT_FAILED",
    defaultMessage: "Funds disbursement failed!!",
  },
  INSTANT_LOAN_DISBURSEMENT_FAILED_SUB_TEXT: {
    id: "INSTANT_LOAN_DISBURSEMENT_FAILED_SUB_TEXT",
    defaultMessage:
      "We apologise, we were not able to disburse the funds. We promise we are looking into this. ",
  },
  INSTAN_LOAN_LOAN_OVERDUE_PROMPT_QUESTION: {
    id: "INSTAN_LOAN_LOAN_OVERDUE_PROMPT_QUESTION",
    defaultMessage: "What happens if I don't pay my loan on time?",
  },
  INSTAN_LOAN_LOAN_HISTORY_DESC: {
    id: "INSTAN_LOAN_LOAN_HISTORY_DESC",
    defaultMessage: "View the log of all your transactions made so far",
  },
  INSTANT_LOAN_LOAN_OVERDUE_MODAL_TITLE: {
    id: "INSTANT_LOAN_LOAN_OVERDUE_MODAL_TITLE",
    defaultMessage: "Effects of loan overdue",
  },
  INSTANT_LOAN_LOAN_OVERDUE_MODAL_OVERVIEW: {
    id: "INSTANT_LOAN_LOAN_OVERDUE_MODAL_OVERVIEW",
    defaultMessage:
      "Here's a quick read to help you understand the significance of timely loan repayment.",
  },
  INSTANT_LOAN_LOAN_OVERDUE_MODAL_CREDIT_SCORE_DESC: {
    id: "INSTANT_LOAN_LOAN_OVERDUE_MODAL_CREDIT_SCORE_DESC",
    defaultMessage:
      "A late or missed loan payment will negatively affect your credit score. This may result in the rejection of future loan applications.",
  },
  INSTANT_LOAN_LOAN_OVERDUE_MODAL_LATE_PENALTY_DESC: {
    id: "INSTANT_LOAN_LOAN_OVERDUE_MODAL_LATE_PENALTY_DESC",
    defaultMessage:
      "Failure to pay your loan on time will attract a late penalty fee. This will affect your future credit score and credit record.",
  },
  CREDIT_SCORE: {
    id: "CREDIT_SCORE",
    defaultMessage: "Credit Score",
  },
  LATE_PENALTY_FEE: {
    id: "LATE_PENALTY_FEE",
    defaultMessage: "Late Penalty Fee",
  },
  COMMON_OOPS_SORRY_TEXT: {
    id: "COMMON_OOPS_SORRY_TEXT",
    defaultMessage: "Oops, Sorry {name} !",
  },
  SUCCESSFULLY_PAUSED_AUTOPAY: {
    id: "SUCCESSFULLY_PAUSED_AUTOPAY",
    defaultMessage: "Autopay paused successfully",
  },
  SUCCESSFULLY_CREATED_AUTOPAY: {
    id: "SUCCESSFULLY_CREATED_AUTOPAY",
    defaultMessage: "Autopay created successfully",
  },
  SUCCESSFULLY_RESUMED_AUTOPAY: {
    id: "SUCCESSFULLY_RESUMED_AUTOPAY",
    defaultMessage: "Autopay resumed successfully",
  },
  INSTANT_LOAN_VALIDATING_SELFIE: {
    id: "INSTANT_LOAN_VALIDATING_SELFIE",
    defaultMessage: "Please wait,validating your details",
  },
  DAILY_DUE_DATE_FORMAT: {
    id: "DAILY_DUE_DATE_FORMAT",
    defaultMessage: "The amount will be deducted at {paymentTime} daily",
  },
  WEEKLY_DUE_DATE_FORMAT: {
    id: "WEEKLY_DUE_DATE_FORMAT",
    defaultMessage: "{paymentDay} of every week your amount will be deducted",
  },
  MONTHLY_DUE_DATE_FORMAT: {
    id: "MONTHLY_DUE_DATE_FORMAT",
    defaultMessage:
      "{paymentDate} of every month your amount will be deducted.",
  },
  CARD_VERIFICATION_DISCLAIMER_4: {
    id: "CARD_VERIFICATION_DISCLAIMER_4",
    defaultMessage:
      "You will be redirected to PAYM8. Our trusted partner's gateway screen.",
  },
  COLLECTION_BOOKING_FAILURE_TEXT: {
    id: "COLLECTION_BOOKING_FAILURE_TEXT",
    defaultMessage:
      "We apologise, we were not able to book your collections successfully. We promise we are looking into this.",
  },
  CUSTOMER_NOT_FOUND_IN_CBS: {
    id: "CUSTOMER_NOT_FOUND_IN_CBS",
    defaultMessage: "Customer not found in CBS",
  },
  CANCEL_APLICATION: {
    id: "CANCEL_APLICATION",
    defaultMessage: "Cancel application",
  },
  HOUSING_ACCEPT_LOAN_OFFER: {
    id: "HOUSING_ACCEPT_LOAN_OFFER",
    defaultMessage: "Accept loan offer",
  },
  HOUSING_ACCEPT_LOAN_OFFER_DESCRIPTION: {
    id: "HOUSING_ACCEPT_LOAN_OFFER_DESCRIPTION",
    defaultMessage:
      "Carefully review loan offer and documents and accept the loan offer. ",
  },
  HOUSING_REVIEW_OFFER_DOCS: {
    id: "HOUSING_REVIEW_OFFER_DOCS",
    defaultMessage: "Review loan agreement documents here",
  },
  HOUSING_OFFER_TERMS_AND_CONDITIONS_ACCEPT: {
    id: "HOUSING_OFFER_TERMS_AND_CONDITIONS_ACCEPT",
    defaultMessage:
      "By checking the box, I accept that I have read and agreed to all the terms & conditions in the loan offer documents.",
  },
  APPLIED_FOR_TEXT: {
    id: "APPLIED_FOR_TEXT",
    defaultMessage: "You have applied for",
  },
  BORROW_UPTO_AMOUNT_MSG_HOUSING: {
    id: "BORROW_UPTO_AMOUNT_MSG_HOUSING",
    defaultMessage: "Congratulations! You can borrow up to {amount}.",
  },
  LETS_GO_BIG_USER: {
    id: "LETS_GO_BIG_USER",
    defaultMessage: "{userFirstName}, Lets Go Big!",
  },
  LETSGO_DOCTOR_RESCHEDULE_APPOINTMENT: {
    id: "LETSGO_DOCTOR_RESCHEDULE_APPOINTMENT",
    defaultMessage: "Reschedule Appointment",
  },
  LETSGO_RESCHEDULE_SUCCESSFULLY: {
    id: "LETSGO_RESCHEDULE_SUCCESSFULLY",
    defaultMessage: "You have rescheduled successfully",
  },
  LETSGO_RESCEDULE_APPOINTMENT_MESSAGE: {
    id: "LETSGO_RESCEDULE_APPOINTMENT_MESSAGE",
    defaultMessage:
      "Your appointment is now on {date}. Details will be sent to you shortly.",
  },
  SERVICE_UNAVAILABLE_SUBTEXT: {
    id: "SERVICE_UNAVAILABLE_SUBTEXT",
    defaultMessage:
      "LetsGo wallet payments are experiencing an interruption at the moment We apologize for any inconvenience caused ",
  },
  TRANSACTION_AMOUNT: {
    id: "TRANSACTION_AMOUNT",
    defaultMessage: "Transaction Amount",
  },
  TOTAL_PAYABLE_AMOUNT: {
    id: "TOTAL_PAYABLE_AMOUNT",
    defaultMessage:
      "Total Payable amount of {currency} {amount} will include a transaction fee of {currency} {fee}",
  },
  WALLET_CHANGE_ALERT: {
    id: "WALLET_CHANGE_ALERT",
    defaultMessage:
      "LetsGo wallet is currently experiencing an interruption, please change the wallet type below to proceed",
  },
  LOAN_LATE_PENALTY_FEE_TEXT: {
    id: "LOAN_LATE_PENALTY_FEE_TEXT",
    defaultMessage: "Applicable default interest for {month}{postfix} month",
  },
  PENALTY_FEE_PERCENT: {
    id: "PENALTY_FEE_PERCENT",
    defaultMessage: "{penaltyFee}%",
  },
  AUTO_PAY_HIHGLIGHT: {
    id: "AUTO_PAY_HIHGLIGHT",
    defaultMessage: "Automate your transactions",
  },
  AUTO_PAY_TEXT: {
    id: "AUTO_PAY_TEXT",
    defaultMessage:
      "Easy and hassel free method to schedule your next payments!",
  },
  AUTO_PAY_OPTION_ONE: {
    id: "AUTO_PAY_OPTION_ONE",
    defaultMessage: "Schedule bill payments",
  },
  AUTO_PAY_OPTION_TWO: {
    id: "AUTO_PAY_OPTION_TWO",
    defaultMessage: "Auto-pay to people",
  },
  AUTO_PAY_OPTION_THREE: {
    id: "AUTO_PAY_OPTION_THREE",
    defaultMessage: "Setup auto-pay for businesses",
  },
  AUTO_PAY_OPTION_FOUR: {
    id: "AUTO_PAY_OPTION_FOUR",
    defaultMessage: "Automate bank transfers",
  },
  KYC_0_AUTO_PAY_INFO: {
    id: "KYC_0_AUTO_PAY_INFO",
    defaultMessage:
      "Help us with a few details so you can start your auto-pay setup for bill payments, business and to people",
  },
  AUTO_PAY_ALERT: {
    id: "AUTO_PAY_ALERT",
    defaultMessage:
      "Your verification is in progress and you can start using Auto-pay once it’s done",
  },
  ACTIVE_INSTANT_LOAN_FAILED_STATUS: {
    id: "ACTIVE_INSTANT_LOAN_FAILED_STATUS",
    defaultMessage: "Failed",
  },
  ACTIVE_INSTANT_LOAN_PENDING_STATUS: {
    id: "ACTIVE_INSTANT_LOAN_PENDING_STATUS",
    defaultMessage: "Pending",
  },
  ACTIVE_LOAN_APPLICATION_NUMBER: {
    id: "ACTIVE_LOAN_APPLICATION_NUMBER",
    defaultMessage: "Application reference no.",
  },
  ALTERNATIVE_PHONENO_TITLE_NUMBER: {
    id: "ALTERNATIVE_PHONENO_TITLE_NUMBER",
    defaultMessage: "Alternative mobile number",
  },
  SAVINGS_PICTURE_UPLOADED_QUESTION: {
    id: "SAVINGS_PICTURE_UPLOADED_QUESTION",
    defaultMessage: "Is the above picture clear and okay to upload?",
  },
  INSTANT_LOAN_REPAY_TEXT: {
    id: "INSTANT_LOAN_REPAY_TEXT",
    defaultMessage: "The loan repayment duration is {tenure} days",
  },
  LETSGO_SAVE_COMING_SOON_TEXT: {
    id: "LETSGO_SAVE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you save more, earn more and gives you the power to do more.",
  },
  LETSGO_PAY_COMING_SOON_TEXT: {
    id: "LETSGO_PAY_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you make payment and gives you the power to do more.",
  },
  LETSGO_BORROW_COMING_SOON_TEXT: {
    id: "LETSGO_BORROW_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you borrow better and gives you the power to do more.",
  },
  LETSGO_INSURE_COMING_SOON_TEXT: {
    id: "LETSGO_INSURE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps insure everything and gives you the power to do more.",
  },
  LETSGO_WELLBEING_COMING_SOON_TEXT: {
    id: "LETSGO_WELLBEING_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you stay healthy and gives you the power to do more.",
  },
  LETSGO_MARKET_PLACE_COMING_SOON_TEXT: {
    id: "LETSGO_MARKET_PLACE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that empowers you to reach more customers and gives you the power to do more.",
  },
  LETSGO_INSTANT_LENDING_COMING_SOON_TEXT: {
    id: "LETSGO_INSTANT_LENDING_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you borrow almost immediately and gives you the power to do more.",
  },
  LETSGO_EASY_LOAN_COMING_SOON_TEXT: {
    id: "LETSGO_EASY_LOAN_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you to get easy loan and gives you the power to do more.",
  },
  LETSGO_MOTSHEGETSI_LOAN_COMING_SOON_TEXT: {
    id: "LETSGO_MOTSHEGETSI_LOAN_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you to get motshegetsi loan and gives you the power to do more.",
  },

  LETSGO_AFFORDABLE_HOUSING_COMING_SOON_TEXT: {
    id: "LETSGO_AFFORDABLE_HOUSING_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you get affordable housing and gives you the power to do more.",
  },
  LETSGO_BUILD_COMING_SOON_TEXT: {
    id: "LETSGO_BUILD_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that gives you the power to build a house and gives you the power to do more.",
  },

  LETSGO_BUY_COMING_SOON_TEXT: {
    id: "LETSGO_BUY_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that gives you the power to own your own home and gives you the power to do more.",
  },
  LETSGO_RENOVATE_COMING_SOON_TEXT: {
    id: "LETSGO_RENOVATE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that gives you the ability to afford renovating your home and gives you the power to do more.",
  },
  LETSGO_PLOT_COMING_SOON_TEXT: {
    id: "LETSGO_PLOT_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that gives you the power to own your plot and gives you the power to do more.",
  },

  LETSGO_HEALTH_COMING_SOON_TEXT: {
    id: "LETSGO_HEALTH_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that educates your on health and gives you the power to do more.",
  },
  LETSGO_DOCTOR_COMING_SOON_TEXT: {
    id: "LETSGO_DOCTOR_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you connect with a doctor and gives you the power to do more.",
  },

  LETSGO_FLEXI_SAVE_COMING_SOON_TEXT: {
    id: "LETSGO_FLEXI_SAVE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you save in the easiest way possible and gives you the power to do more.",
  },
  LETSGO_GROUP_SAVE_COMING_SOON_TEXT: {
    id: "LETSGO_GROUP_SAVE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you, your friends and family save more and also gives you the power to do more.",
  },

  LETSGO_MSE_BUSINESS_LOAN_COMING_SOON_TEXT: {
    id: "LETSGO_MSE_BUSINESS_LOAN_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps your business thrive and gives you the power to do more.",
  },
  LETSGO_MSE_PURCHASE_ORDER_COMING_SOON_TEXT: {
    id: "LETSGO_MSE_PURCHASE_ORDER_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you to easily complete your deal and gives you the power to do more.",
  },
  LETSGO_MSE_WORKING_CAPITAL_COMING_SOON_TEXT: {
    id: "LETSGO_MSE_WORKING_CAPITAL_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps you start your business and gives you the power to do more.",
  },
  LETSGO_MSE_GREEN_FINANCING_COMING_SOON_TEXT: {
    id: "LETSGO_MSE_GREEN_FINANCING_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that gives you the power to change your world and gives you the power to do more.",
  },

  LETSGO_RECRUIT_COMING_SOON_TEXT: {
    id: "LETSGO_RECRUIT_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that enables you find your dream job and gives you the power to do more.",
  },
  LETSGO_NATION_COMING_SOON_TEXT: {
    id: "LETSGO_NATION_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform connects you to amazing people and gives you the power to do more.",
  },
  LETSGO_LIFESTYLE_COMING_SOON_TEXT: {
    id: "LETSGO_LIFESTYLE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform connects you to amazing people and gives you the power to do more.",
  },
  BUSINESS_DETAILS: {
    id: "BUSINESS_DETAILS",
    defaultMessage: "Business details",
  },
  LETSGO_INSURE_FAMILY_PROTECTION_COMING_SOON_TEXT: {
    id: "LETSGO_INSURE_FAMILY_PROTECTION_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps insure your family and gives you the power to do more.",
  },
  LETSGO_INSURE_EDUCATION_INSURANCE_COMING_SOON_TEXT: {
    id: "LETSGO_INSURE_EDUCATION_INSURANCE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps insure your education and gives you the power to do more.",
  },
  LETSGO_INSURE_HOME_INSURANCE_COMING_SOON_TEXT: {
    id: "LETSGO_INSURE_HOME_INSURANCE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps insure your home and gives you the power to do more.",
  },
  LETSGO_INSURE_LIFE_INSURANCE_COMING_SOON_TEXT: {
    id: "LETSGO_INSURE_LIFE_INSURANCE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps insure your life and gives you the power to do more.",
  },
  LETSGO_INSURE_MOTOR_INSURANCE_COMING_SOON_TEXT: {
    id: "LETSGO_INSURE_MOTOR_INSURANCE_COMING_SOON_TEXT",
    defaultMessage:
      "We are currently building a platform that helps insure your vehicles and gives you the power to do more.",
  },
  TXT_FREQUENTLY: {
    id: "TXT_FREQUENTLY",
    defaultMessage: "Frequently",
  },
  TXT_ASKED_QUESTIONS: {
    id: "TXT_ASKED_QUESTIONS",
    defaultMessage: "Asked questions",
  },
  TXT_CONTACT_US_VIA: {
    id: "TXT_CONTACT_US_VIA",
    defaultMessage:
      "Contact us VIA social media and our team will get back to you instantly",
  },
  HOW_YOU_IDENTIFIED_PLOT_TO_BUY: {
    id: "HOW_YOU_IDENTIFIED_PLOT_TO_BUY",
    defaultMessage: "Have you identified a plot to buy?",
  },
  SELF_EMPLOYED: {
    id: "SELF_EMPLOYED",
    defaultMessage: "Self Employed",
  },
  WHAT_BUSINESS_DO_YOU_DO: {
    id: "WHAT_BUSINESS_DO_YOU_DO",
    defaultMessage: "What business do you do?",
  },
  UPLOAD_YOUR_BANK_STATEMENT: {
    id: "UPLOAD_YOUR_BANK_STATEMENT",
    defaultMessage: "Upload your latest 6 months bank statement here",
  },
  PAYROAL_LOAN_JOINING_DATE_ERROR: {
    id: "PAYROAL_LOAN_JOINING_DATE_ERROR",
    defaultMessage: "Minimum tenure customer should be employed is 6 months",
  },
  PAYROAL_LOAN_JOINING_DATE_ERROR_2: {
    id: "PAYROAL_LOAN_JOINING_DATE_ERROR_2",
    defaultMessage: "Minimum tenure customer should be employed is 3 months",
  },
  LETS_GO_DISBURSAL_LOAN_DISBURSED_MSG: {
    id: "DISBURSAL_LOAN_DISBURSED_MSG",
    defaultMessage:
      "Loan amount of {loanAmountValue} has been disbursed to your LetsGo {accountNumberValue} account.",
  },
  COMMON_HI: {
    id: "COMMON_HI",
    defaultMessage: "Hi {customer}!",
  },
  COMMON_LETSGO_ACCOUNT: {
    id: "COMMON_LETSGO_ACCOUNT",
    defaultMessage: "You get a new LetsGo account !",
  },
  LETS_GO_ACCOUNT_SUB_TEXT: {
    id: "LETS_GO_ACCOUNT_SUB_TEXT",
    defaultMessage:
      "Your LetsGo account will be used to disburse funds for your personal Loan.",
  },
  LETS_GO_ACCOUNT_SUB_TEXT_ONE: {
    id: "LETS_GO_ACCOUNT_SUB_TEXT_ONE",
    defaultMessage:
      "It will come with a debit card that you can collect at your nearest branch. ",
  },
  LETS_GO_ACCOUNT_SUB_TEXT_TWO: {
    id: "LETS_GO_ACCOUNT_SUB_TEXT_TWO",
    defaultMessage:
      "Your account number will be generated before you accept the loan offer.",
  },
  COMMON_CONTINUE: {
    id: "COMMON_CONTINUE",
    defaultMessage: "Continue",
  },
  ELIGIBILITY_QUESTION_COMPANY_NAME_INDIVIDUAL: {
    id: "ELIGIBILITY_QUESTION_COMPANY_NAME_INDIVIDUAL",
    defaultMessage:
      "Where do you work? (Please select Other if your employer is not on the list)",
  },
  LOAN_PROFILE_EMPLOYERNAME_INDIVIDUAL: {
    id: "LOAN_PROFILE_EMPLOYERNAME_INDIVIDUAL",
    defaultMessage: "Employer name",
  },
  LOAN_OFFER_ADDITIONAL_CHARGES_TEXT: {
    id: "LOAN_OFFER_ADDITIONAL_CHARGES_TEXT",
    defaultMessage:
      "The above calculations are indicative and final offer may vary based on document verification",
  },
  LOAN_OFFER_SATISFACTORY_TEXT: {
    id: "LOAN_OFFER_SATISFACTORY_TEXT",
    defaultMessage:
      "We’re happy to inform you that we can settle your loans. This amount includes your new loan  and settlement amount and top-up",
  },
  LOAN_OFFER_APPLICATION_NUMBER: {
    id: "LOAN_OFFER_APPLICATION_NUMBER",
    defaultMessage: "Application number :{number}",
  },
  LETSGO_NON_PAYROLL_LOAN_NAMIBIA: {
    id: "LETSGO_NON_PAYROLL_LOAN_NAMIBIA",
    defaultMessage: "Personal Loan",
  },
  NON_PAYROLL_WORK_LABEL_NAMIBIA: {
    id: "NON_PAYROLL_WORK_LABEL_NAMIBIA",
    defaultMessage: "LetsGo Personal Loan works?",
  },
  PO_AMOUNT: {
    id: "PO_AMOUNT",
    defaultMessage: "Purchase Order Amount",
  },
  ELIGIBLE_AMOUNT_TO_BORROW: {
    id: "ELIGIBLE_AMOUNT_TO_BORROW",
    defaultMessage: "Eligible Amount to borrow",
  },

  PERSONAL_LOAN_EXPIRED_PARA: {
    id: "PERSONAL_LOAN_EXPIRED_PARA",
    defaultMessage:
      "The loan application was not accepted in the duration of 7 days",
  },
  REVIEW_YOUR_ELIGIBILITY_BTN_TEXT: {
    id: "REVIEW_YOUR_ELIGIBILITY_BTN_TEXT",
    defaultMessage: "Review your eligibility",
  },
  APPLICATION_EXPIRED_TEXT: {
    id: "APPLICATION_EXPIRED_TEXT",
    defaultMessage: "Your application expired on {day}.",
  },
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_APPLICATION: {
    id: "ARE_YOU_SURE_YOU_WANT_TO_CANCEL_APPLICATION",
    defaultMessage:
      "Are you sure you want to cancel your application? It will reject all the progress so far",
  },
  CANCEL_BUTTON: { id: "CANCEL_BUTTON", defaultMessage: "Yes, Cancel" },
  NO_TEXT: { id: "NO_TEXT", defaultMessage: "No" },
  PERSONAL_LOAN_EXPIRED_TITLE: {
    id: "PERSONAL_LOAN_EXPIRED_TITLE",
    defaultMessage: "Your loan application has",
  },
  NOT_ELIGIBLE_LETSGO_PERSONAL_LOAN: {
    id: "NOT_ELIGIBLE_LETSGO_PERSONAL_LOAN",
    defaultMessage: "You are not eligible for a Personal loan",
  },
  NOT_ELIGIBLE_LOAN_INFO_IND_NAMIBIA: {
    id: "NOT_ELIGIBLE_LOAN_TYPE_INFO_IND_NAMIBIA",
    defaultMessage:
      "Based on your details, we can't offer you a personal loan but don't worry, our team will get in touch with you to help.",
  },
  NOT_ELIGIBLE_LOAN_INFO_IND_BOTSWANA: {
    id: "NOT_ELIGIBLE_LOAN_TYPE_INFO_IND_BOTSWANA",
    defaultMessage:
      "Based on your details, we can't offer you a LetsGo Motshegetsi Loan but don't worry, our team will get in touch with you to help.",
  },
  NOT_ELIGIBLE_LOAN_INFO_IND_NIGERIA: {
    id: "NOT_ELIGIBLE_LOAN_TYPE_INFO_IND_NIGERIA:",
    defaultMessage:
      "Based on your details, we can't offer you an Easy Loan but don't worry, our team will get in touch with you to help.",
  },
  INSTANT_LOAN_AMOUNT_LABEL: {
    id: "INSTANT_LOAN_AMOUNT_LABEL",
    defaultMessage: "Loan amount",
  },
  INSTANT_LOAN_ACTIVE_LOAN_ALERT_FAILED: {
    id: "INSTANT_LOAN_ACTIVE_LOAN_ALERT_FAILED",
    defaultMessage:
      "Looks like your loan application was not successful. Kindly get in touch with our customer care.",
  },
  NOT_ELIGIBLE_MSE_LOAN_INFO: {
    id: "NOT_ELIGIBLE_MSE_LOAN_INFO",
    defaultMessage: "Based on your details, we can't offer you a mse loan.",
  },
  FLASH_CASH_DONT_QUALIFY: {
    id: "FLASH_CASH_DONT_QUALIFY",
    defaultMessage: "You don't qualify",
  },
  FLASH_CASH_LOOK_OTHER_PRODUCTS: {
    id: "FLASH_CASH_LOOK_OTHER_PRODUCTS",
    defaultMessage: "Have a look at our other products",
  },
  FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_0: {
    id: "FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_0",
    defaultMessage: "Second Installment",
  },
  FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_1: {
    id: "FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_1",
    defaultMessage: "Third Installment",
  },
  FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_2: {
    id: "FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_2",
    defaultMessage: "Fourth Installment",
  },
  FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_3: {
    id: "FLASH_CASH_LOAN_INSTALLMENT_DATE_LABEL_3",
    defaultMessage: "Fifth Installment",
  },
  KRA_TIN_CERTIFICATE: {
    id: "KRA_TIN_CERTIFICATE",
    defaultMessage: "KRA TIN certficate",
  },
  KRA_TIN_CERTIFICATE_UPLOAD_DESC: {
    id: "KRA_TIN_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Upload your KRA TIN certificate document",
  },
  SOURCE_OF_PURCHASE_ORDER: {
    id: "SOURCE_OF_PURCHASE_ORDER",
    defaultMessage: "Source of Purchase Order",
  },
  PURCHASE_ORDER_ISSUER: {
    id: "PURCHASE_ORDER_ISSUER",
    defaultMessage: "Purchase Order Issuer",
  },
  BUSINESS_REGISTRATION_CERTIFICATE: {
    id: "BUSINESS_REGISTRATION_CERTIFICATE",
    defaultMessage: "Business Registration Certificate",
  },
  BUSINESS_REGISTRATION_CERTIFICATE_UPLOAD_DESC: {
    id: "BUSINESS_REGISTRATION_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Upload your Business Registration Certificate document",
  },
  BUSINESS_LICENCE: {
    id: "BUSINESS_LICENCE",
    defaultMessage: "Business License",
  },
  BUSINESS_LICENCE_UPLOAD_DESC: {
    id: "BUSINESS_LICENCE_UPLOAD_DESC",
    defaultMessage: "Upload your Business License document",
  },
  APPLICABLE_REGULATORY_LICENSE: {
    id: "APPLICABLE_REGULATORY_LICENSE",
    defaultMessage: "Applicable Regulatory license",
  },
  APPLICABLE_REGULATORY_LICENSE_UPLOAD_DESC: {
    id: "APPLICABLE_REGULATORY_LICENSE_UPLOAD_DESC",
    defaultMessage: "Upload your Applicable Regulatory license document",
  },
  BANK_STATEMENT_MSE_UPLOAD_DESC: {
    id: "BANK_STATEMENT_MSE_UPLOAD_DESC",
    defaultMessage:
      "You can upload 6 months certified copies of Bank statement.",
  },
  HAVE_YOU_BORROWED_FROM_LETSHEGO_BEFORE: {
    id: "HAVE_YOU_BORROWED_FROM_LETSHEGO_BEFORE",
    defaultMessage: "Have you borrowed from Letshego before?",
  },
  ENTRY_KYC: {
    id: "ENTRY_KYC",
    defaultMessage: "Entry KYC",
  },
  ENTRY_KYC_INFORMATION: {
    id: "ENTRY_KYC_INFORMATION",
    defaultMessage: "Please provide your Entry KYC information",
  },
  KRA_PIN: {
    id: "KRA_PIN",
    defaultMessage: "KRA Pin",
  },
  DOCUMENT_DOWNLOAD_SUCCESS_MESSAGE: {
    id: "DOCUMENT_DOWNLOAD_SUCCESS_MESSAGE",
    defaultMessage:
      "You have successfully downloaded the file. Check your downloads folder.",
  },
  DOCUMENT_DOWNLOAD_ERROR_MESSAGE: {
    id: "DOCUMENT_DOWNLOAD_ERROR_MESSAGE",
    defaultMessage: "Unable to download file now. Try again later",
  },
  COMMON_SUCCESS: {
    id: "COMMON_SUCCESS",
    defaultMessage: "Success",
  },
  COMMON_ERROR: {
    id: "COMMON_ERROR",
    defaultMessage: "Error",
  },
  EXPIRED_DOCUMENT_DOWNLOAD_LINK_MESSAGE: {
    id: "EXPIRED_DOCUMENT_DOWNLOAD_LINK_MESSAGE",
    defaultMessage:
      "The download link provided has expired, please contact customer care on {contactNo} for assistance.",
  },
  LETSGO_DOCTOR_APPOINTMENT_COMING_UP: {
    id: "LETSGO_DOCTOR_APPOINTMENT_COMING_UP",
    defaultMessage: "coming up",
  },
  LETSGO_DOCTOR_BOOK_ANOTHER_APPOINTMENT: {
    id: "LETSGO_DOCTOR_BOOK_ANOTHER_APPOINTMENT",
    defaultMessage: "Book Another Appointment",
  },
  REMITA_ERROR_HEADER: {
    id: "REMITA_ERROR_HEADER",
    defaultMessage: "We are sorry!",
  },
  REMITA_ERROR_SUB_HEADER: {
    id: "REMITA_ERROR_SUB_HEADER",
    defaultMessage: "Something went wrong while processing your application",
  },
  REMITA_ERROR_SUB_CONTENT_1: {
    id: "REMITA_ERROR_SUB_CONTENT_1",
    defaultMessage:
      "Your KYC details are being reviewed, and your account will be created shortly and loan disbursed",
  },
  REMITA_ERROR_ACTION_LABEL: {
    id: "REMITA_ERROR_ACTION_LABEL",
    defaultMessage: "Back to Digital Mall",
  },
  VERIFY_MODAL_SUB_TEXT: {
    id: "VERIFY_MODAL_SUB_TEXT",
    defaultMessage:
      "Kindly confirm if you are an existing customer with an active product.",
  },
  VERIFY_MODAL_ACTIVE_PRODUCT_HEADER: {
    id: "VERIFY_MODAL_ACTIVE_PRODUCT_HEADER",
    defaultMessage: "Active products such as",
  },
  VERIFY_MODAL_VERIFY_YOUR_IDENTITY: {
    id: "VERIFY_MODAL_VERIFY_YOUR_IDENTITY",
    defaultMessage: "Your identity",
  },
  VERIFY_MODAL_DONT_HAVE_ACTIVE_PRODUCT: {
    id: "VERIFY_MODAL_DONT_HAVE_ACTIVE_PRODUCT",
    defaultMessage: "I do not have an active product",
  },
  ERROR_UNABLE_TO_FIND_SECONDARY: {
    id: "ERROR_UNABLE_TO_FIND_SECONDARY",
    defaultMessage: "Let me retry",
  },
  SPOUSE_DOCUMENTS: {
    id: "SPOUSE_DOCUMENTS",
    defaultMessage: "Spouse’s Documents",
  },
  SPOUSE_EMPLOYMENT_TYPE_HEADING: {
    id: "SPOUSE_EMPLOYMENT_TYPE_HEADING",
    defaultMessage: "What is your Spouse’s employment type?",
  },
  DEATH_CERTIFICATE_UPLOAD_DESC: {
    id: "DEATH_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Kindly upload the death certificate of your spouse",
  },
  DIVORCE_CERTIFICATE_UPLOAD_DESC: {
    id: "DIVORCE_CERTIFICATE_UPLOAD_DESC",
    defaultMessage: "Kindly upload your divorce certificate",
  },
  DEATH_CERTIFICATE: {
    id: "DEATH_CERTIFICATE",
    defaultMessage: "Death Certificate",
  },
  DIVORCE_CERTIFICATE: {
    id: "DIVORCE_CERTIFICATE",
    defaultMessage: "Divorce Certificate",
  },
  SPOUSE_BANK_DETAILS_UPLOAD_DESC: {
    id: "SPOUSE_BANK_DETAILS_UPLOAD_DESC",
    defaultMessage: "Upload your spouse’s 6 months bank statement",
  },
  SPOUSE_PAYSLIP_UPLOAD_DESC: {
    id: "SPOUSE_PAYSLIP_UPLOAD_DESC",
    defaultMessage: "Upload your spouse’s latest payslip",
  },
  SPOUSE_BANK_STATEMENT: {
    id: "SPOUSE_BANK_STATEMENT",
    defaultMessage: "Bank Statement",
  },
  SPOUSE_PAYSLIP: {
    id: "SPOUSE_PAYSLIP",
    defaultMessage: "Pay slip",
  },
  ACCEPT_LOAN_OFFER_MSE: {
    id: "ACCEPT_LOAN_OFFER_MSE",
    defaultMessage: "Accept loan offer",
  },
  AMOUNT_APPROVED: {
    id: "AMOUNT_APPROVED",
    defaultMessage: "Amount approved",
  },
  LOAN_APPROVED_FOR: {
    id: "LOAN_APPROVED_FOR",
    defaultMessage: "You have been are approved for",
  },
  PAYMENT_METHOD_SUB_TITLE: {
    id: "PAYMENT_METHOD_SUB_TITLE",
    defaultMessage: "Choose the payment method for sending funds",
  },
  NOT_ELIGIBLE_FOR_SUB10: {
    id: "NOT_ELIGIBLE_FOR_SUB10",
    defaultMessage: "You are not eligible for top-up at the moment",
  },
  SUB10_TRY_AGAIN_AFTERSOMETIME: {
    id: "SUB10_TRY_AGAIN_AFTERSOMETIME",
    defaultMessage: "Please try again after sometime or contact customer care",
  },
  GHANA_VERIFY_MODAL_VERIFY_YOUR_IDENTITY: {
    id: "GHANA_VERIFY_MODAL_VERIFY_YOUR_IDENTITY",
    defaultMessage: "Verify your identity",
  },
  GHANA_VERIFY_MODAL_VERIFY_YOUR_IDENTITY_VERIFIED_USER: {
    id: "GHANA_VERIFY_MODAL_VERIFY_YOUR_IDENTITY_VERIFIED_USER",
    defaultMessage: "You are an existing customer",
  },
  GHANA_VERIFY_MODAL_MOBILE_VERIFY_TEXT: {
    id: "GHANA_VERIFY_MODAL_MOBILE_VERIFY_TEXT",
    defaultMessage:
      "Due to regulatory requirements in Ghana please verify your ID card with selfie through the LetsGo Mobile App and continue after verification",
  },
  GHANA_VERIFY_MODAL_SUB_TEXT: {
    id: "GHANA_VERIFY_MODAL_SUB_TEXT",
    defaultMessage:
      "Due to regulatory requirements in Ghana kindly verify your ID with a selfie to continue",
  },
  GHANA_VERIFY_MODAL_SUB_TEXT_VERIFIED_USER: {
    id: "GHANA_VERIFY_MODAL_SUB_TEXT_VERIFIED_USER",
    defaultMessage:
      "You are an existing customer.Please verify your registered mobile number to personalize your experience",
  },
  GHANA_VERIFY_MODAL_ACTION_CTA: {
    id: "GHANA_VERIFY_MODAL_ACTION_CTA",
    defaultMessage: "Verify it’s you",
  },
  CESSION_AGREEMENT: {
    id: "CESSION_AGREEMENT",
    defaultMessage: "Cession Agreement",
  },
  CESSION_AGREEMENT_UPLOAD_DESC: {
    id: "CESSION_AGREEMENT_UPLOAD_DESC",
    defaultMessage: "Upload your Cession Agreement document",
  },
  TXT_YOU_ARE_ABOUT: {
    id: "TXT_YOU_ARE_ABOUT",
    defaultMessage: "You are about to",
  },
  TXT_KINDLY_NOTE_PARA: {
    id: "TXT_KINDLY_NOTE_PARA",
    defaultMessage:
      "Kindly note that closing your digital mall profile does not affect the account you currently have with Letshego, your personal information linked to the account you have will be retained in our records as per local regulatory requirement",
  },
  TXT_CLOSE_YOUR_PROFILE: {
    id: "TXT_CLOSE_YOUR_PROFILE",
    defaultMessage: "Close your LetsGo profile",
  },
  TXT_FOR_MORE_INFO: {
    id: "TXT_FOR_MORE_INFO",
    defaultMessage: "For more information, you can contact the",
  },
  TXT_CUSTOMER_CARE: {
    id: "TXT_CUSTOMER_CARE",
    defaultMessage: "customer care center here",
  },
  TXT_ACTIVE_PRODUCT: {
    id: "TXT_ACTIVE_PRODUCT",
    defaultMessage: "Active products",
  },
  TXT_INDIVIUAL_LOAN: {
    id: "TXT_INDIVIUAL_LOAN",
    defaultMessage: "Individual Loan",
  },
  TXT_FLEXI_SAVINGS: {
    id: "TXT_FLEXI_SAVINGS",
    defaultMessage: "Flexi savings",
  },
  TXT_AFFORDABLE_HOUSING: {
    id: "TXT_AFFORDABLE_HOUSING",
    defaultMessage: "Affordable Housing",
  },
  TXT_KINDLY_NOTE_THAT: {
    id: "TXT_KINDLY_NOTE_THAT",
    defaultMessage:
      "Kindly note that you are about to delete your profile with us, every information about you will be removed from all Letshego digital channels.",
  },
  TXT_DONT_DELETE: {
    id: "TXT_DONT_DELETE",
    defaultMessage: "Don't delete my profile",
  },
  FEEDBACK_MODAL_TITLE: {
    id: "FEEDBACK_MODAL_TITLE",
    defaultMessage: "Rate our service",
  },
  FEEDBACK_MODAL_DESCRIPTION: {
    id: "FEEDBACK_MODAL_DESCRIPTION",
    defaultMessage: " How satisfied are you with the quality of our service?",
  },
  TXT_DELETE_ACCOUNT: {
    id: "TXT_DELETE_ACCOUNT",
    defaultMessage: "Delete my profile",
  },
  TXT_CHECKBOX_LABEL_FOR_DELETE_ACCOUNT: {
    id: "TXT_CHECKBOX_LABEL_FOR_DELETE_ACCOUNT",
    defaultMessage:
      "I will like to be contacted in future by Letshego for new product or offerings",
  },
  TXT_PROFILE_DELETE: {
    id: "TXT_PROFILE_DELETE",
    defaultMessage: "Profile deletion",
  },
  TXT_KINDLY_NOTE_YOUR_PROFILE: {
    id: "TXT_KINDLY_NOTE_YOUR_PROFILE",
    defaultMessage:
      "Kindly note that your profile has been successfully deleted from all Letshego digital channels. We look forward to welcoming you back again.",
  },
  GENERATED_PDF_ERROR: {
    id: "GENERATED_PDF_ERROR",
    defaultMessage:
      "Failed to generate statement dated from {dateFrom} to {dateTo} of {transactionType} transactions. Please adjust your date range",
  },
  WELL_DONE_TXT: {
    id: "WELL_DONE_TXT",
    defaultMessage: "Well done on starting your purchase order application",
  },
  TXT_PP_AND_TC_NOT_READ_ERROR: {
    id: "TXT_PP_AND_TC_NOT_READ_ERROR",
    defaultMessage:
      "You have not read the Terms and Conditions and the Privacy Policy",
  },
  UPLOAD_DOCS_TAB_CONSOLIDATION_OTHER: {
    id: "UPLOAD_DOCS_TAB_CONSOLIDATION_OTHER",
    defaultMessage: "Would you like Letshego to settle your other loans?",
  },
  EMAIL_STATEMENT_DESC: {
    id: "EMAIL_STATEMENT_DESC",
    defaultMessage:
      "You can share your statment with anyone by your registered email.",
  },
  EMAIL_STATEMENT_RECEIVER: {
    id: "EMAIL_STATEMENT_RECEIVER",
    defaultMessage: "Receiver's Email",
  },

  EMAIL_STATEMENT_TITLE: {
    id: "EMAIL_STATEMENT_TITLE",
    defaultMessage: "Email Your Statement",
  },
  EASY_LOAN_ADDITIONAL_REQ_ALERT: {
    id: "EASY_LOAN_ADDITIONAL_REQ_ALERT",
    defaultMessage:
      "Please note we might contact you for additional requirements depending on your loan size",
  },
  LOAN_SECTION_EMPLOYMENT_NG: {
    id: "LOAN_SECTION_EMPLOYMENT_NG",
    defaultMessage: "Employment details",
  },
  INDIVIDUAL_LOAN_SECTION_EMPLOYMENT_HELPER_TEXT: {
    id: "INDIVIDUAL_LOAN_SECTION_EMPLOYMENT_HELPER_TEXT",
    defaultMessage: "Please select “Other” if your employer is not on the list",
  },
  LOAN_SECTION_REFEREEE_DETAILS: {
    id: "LOAN_SECTION_REFEREEE_DETAILS",
    defaultMessage: "Referee Details",
  },
  LOAN_PROFILE_REFEREE_NAME: {
    id: "LOAN_PROFILE_REFEREE_NAME",
    defaultMessage: "Referee Name",
  },
  LOAN_PROFILE_REFEREE_ADDRESS: {
    id: "LOAN_PROFILE_REFEREE_ADDRESS",
    defaultMessage: "Referee Address",
  },
  ERROR_PERSONAL_AND_REFEREE_MOBILE_SAME: {
    id: "ERROR_PERSONAL_AND_REFEREE_MOBILE_SAME",
    defaultMessage: "Personal and referee  contact number cannot be same.",
  },
  REFEREE_PHONE_NUMBER: {
    id: "REFEREE_PHONE_NUMBER",
    defaultMessage: "Referee number",
  },
  EMPLOYMENT_CONFIRMATION_LETTER: {
    id: "EMPLOYMENT_CONFIRMATION_LETTER",
    defaultMessage: "Employee confirmation/promotional letter",
  },
  EMPLOYMENT_CONFIRMATION_LETTER_UPLOAD_DESC: {
    id: "EMPLOYMENT_CONFIRMATION_LETTER_UPLOAD_DESC",
    defaultMessage: "Upload your employee confirmational/promotional letter",
  },
  LOAN_PURPOSE_MSE: {
    id: "LOAN_PURPOSE_MSE",
    defaultMessage: "Loan Purpose",
  },
  HELP_US_FINISH: {
    id: "HELP_US_FINISH",
    defaultMessage: "Help us finish",
  },
  BUSINESS_LOAN_DETAILS: {
    id: "BUSINESS_LOAN_DETAILS",
    defaultMessage: "Business Loan details",
  },
  ON_BOARDING_ERROR: {
    id: "ON_BOARDING_ERROR",
    defaultMessage:
      "Dear customer, unfortunately you do not have a LetsGo account, please visit any Letshego branch to open a LetsGo account or contact our {customerService} for any queries.",
  },
  WICODE_HIHGLIGHT: {
    id: "WICODE_HIHGLIGHT",
    defaultMessage: "Start using Wicode",
  },
  WICODE_TEXT: {
    id: "WICODE_TEXT",
    defaultMessage:
      "Easy and hassel free methods to instantly make your next payment",
  },
  WICODE_OPTION_ONE: {
    id: "WICODE_OPTION_ONE",
    defaultMessage: "Cash In funds instantly",
  },
  WICODE_OPTION_TWO: {
    id: "WICODE_OPTION_TWO",
    defaultMessage: "Cash Out funds hassel free",
  },
  WICODE_OPTION_THREE: {
    id: "WICODE_OPTION_THREE",
    defaultMessage: "Pay to merchants",
  },
  KYC_0_WICODE_INFO: {
    id: "KYC_0_WICODE_INFO",
    defaultMessage:
      "Help us with a few details so you can start using your Wicode to withdraw funds hassel free",
  },
  WICODE_PAY_ALERT: {
    id: "WICODE_PAY_ALERT",
    defaultMessage:
      "Your verification is in progress and you can start using Wicode once it’s done",
  },
  DOCTOR_PHONE_VALIDATION_NAMIBIA: {
    id: "DOCTOR_PHONE_VALIDATION_NAMIBIA",
    defaultMessage: "Doctors Phone Number should be 9 digits",
  },
  USER_REJECTED: { id: "USER_REJECTED", defaultMessage: "Rejected" },
  YOU_HAVE_REJECTED_OUR_TXT: {
    id: "YOU_HAVE_REJECTED_OUR_TXT",
    defaultMessage: "You have rejected our",
  },
  LOAN_OFFER_TXT: {
    id: "LOAN_OFFER_TXT",
    defaultMessage: "loan offer.",
  },
  USER_REJECTED_LOAN_PARA: {
    id: "USER_REJECTED_LOAN_PARA",
    defaultMessage:
      "Sorry to hear that we were  not able to make you a suitable loan offer as per your expectations.",
  },
  START_NEW_APPLICATION: {
    id: "START_NEW_APPLICATION",
    defaultMessage: "Start New Application",
  },
  REJECTED_LOAN_TXT_MSE: {
    id: "REJECTED_LOAN_TXT_MSE",
    defaultMessage:
      "Loan request not approved as a few of your details don't match our business loan criteria.",
  },
  REJECTED_LOAN_TOAST_MSE: {
    id: "REJECTED_LOAN_TXT_MSE",
    defaultMessage:
      "Oh no! Your loan application couldn’t be processed. Don’t worry, let us help you re-apply.",
  },
  EXPIRED_LOAN_TOAST_MSE: {
    id: "EXPIRED_LOAN_TOAST_MSE",
    defaultMessage:
      "Oh no! Looks like your loan offer has expired. Don’t worry, let us help you re-apply.",
  },
  TXT_OOPS_SORRY: {
    id: "TXT_OOPS_SORRY",
    defaultMessage: "Ooops! Sorry you have a ",
  },
  TXT_NAME: {
    id: "TXT_NAME",
    defaultMessage: "Name",
  },
  TXT_PENDING_APPLICATION: {
    id: "TXT_PENDING_APPLICATION",
    defaultMessage: " Pending application",
  },
  TXT_YOU_ARE_UNABLE: {
    id: "TXT_YOU_ARE_UNABLE",
    defaultMessage:
      "You are unable to start a new application due to a pending application on the following product. Would you like to complete it?",
  },
  TXT_YOU_MISMATCH_WORKFLOW: {
    id: "TXT_YOU_MISMATCH_WORKFLOW",
    defaultMessage:
      "Something went wrong with one of your open applications. Please click on the CTA below to reset and continue.",
  },
  TXT_CONTINUE_APPLICATION: {
    id: "TXT_CONTINUE_APPLICATION",
    defaultMessage: "Continue existing application",
  },
  TXT_CANCEL_AND_START_NEW_APP: {
    id: "TXT_CANCEL_AND_START_NEW_APP",
    defaultMessage: "Cancel and start a new application",
  },
  TXT_YOUR_PERSONAL_INFO: {
    id: "TXT_YOUR_PERSONAL_INFO",
    defaultMessage: "Your personal information is yet to be completed",
  },
  ADDITIONAL_DETAILS: {
    id: "ADDITIONAL_DETAILS",
    defaultMessage: "Additional details",
  },
  DAYS_TO_REPAY: {
    id: "DAYS_TO_REPAY",
    defaultMessage: "Days to repay",
  },
  MAXIMUM_ELIGIBLE_PO_AMOUNT_MSE: {
    id: "MAXIMUM_ELIGIBLE_PO_AMOUNT_MSE",
    defaultMessage: "Your Maximum Eligible Purchase Order Loan Amount",
  },
  TXT_OOOPS: {
    id: "TXT_OOOPS",
    defaultMessage: "Ooops!",
  },
  TXT_OOPS_NAME: {
    id: "TXT_OOPS_NAME",
    defaultMessage: "Oops {customerName}!",
  },
  TXT_SOMETHING_WENT_WRONG: {
    id: "TXT_SOMETHING_WENT_WRONG",
    defaultMessage: "Something went wrong",
  },
  TXT_UNABLE_TO_CONTINUE: {
    id: "TXT_UNABLE_TO_CONTINUE",
    defaultMessage:
      "Kindly note that you are unable to continue because something went wrong.",
  },
  WHAT_IS_PEP_PIP_CTA: {
    id: "WHAT_IS_PEP_PIP_CTA",
    defaultMessage: "What is PEP/PIP?",
  },
  CUSTOMER_ACCOUNT_DELETED: {
    id: "CUSTOMER_ACCOUNT_DELETED",
    defaultMessage: "Oops! Something went wrong.",
  },
  BANK_TO_BANK: {
    id: "BANK_TO_BANK",
    defaultMessage: "Bank to bank",
  },
  BANK_TO_WALLET: {
    id: "BANK_TO_WALLET",
    defaultMessage: "Bank to wallet",
  },
  BANK_TO_MERCHANT: {
    id: "BANK_TO_MERCHANT",
    defaultMessage: "Bank to Merchant",
  },
  BANK_TO_VAS: {
    id: "BANK_TO_VAS",
    defaultMessage: "Bank to VAS",
  },
  PAYMENT_SUBMITTED: {
    id: "PAYMENT_SUBMITTED",
    defaultMessage: "Payment Submitted",
  },
  TOTAL_PROCESSING_FEE: {
    id: "TOTAL_PROCESSING_FEE",
    defaultMessage: "Total Processing Fee",
  },
  AMOUNT_TO_BE_DISBURSED: {
    id: "AMOUNT_TO_BE_DISBURSED",
    defaultMessage: "Amount To Be Disbursed",
  },
  COMMON_BANK_TO_INTERNAL_BENEFICIARY: {
    id: "COMMON_BANK_TO_INTERNAL_BENEFICIARY",
    defaultMessage: "LetsGo Account to Internal Beneficiary",
  },
  COMMON_BANK_TO_EXTERNAL_BENEFICIARY: {
    id: "COMMON_BANK_TO_EXTERNAL_BENEFICIARY",
    defaultMessage: "LetsGo Account to External Beneficiary",
  },
  COMMON_BANK_TO_EXTERNAL_WALLET: {
    id: "COMMON_BANK_TO_EXTERNAL_WALLET",
    defaultMessage: "LetsGo Account to External Wallet",
  },
  COMMON_BANK_TO_INTERNAL_WALLET: {
    id: "COMMON_BANK_TO_INTERNAL_WALLET",
    defaultMessage: "LetsGo Account to LetsGo Wallet",
  },
  COMMON_BANK_TO_BILLERS: {
    id: "COMMON_BANK_TO_BILLERS",
    defaultMessage: "LetsGo Account to Billers",
  },
  COMMON_BANK_TO_ELECTRICITY: {
    id: "COMMON_BANK_TO_ELECTRICITY",
    defaultMessage: "LetsGo Account to Electricity",
  },
  COMMON_BANK_VAS_AIRTIME: {
    id: "COMMON_BANK_VAS_AIRTIME",
    defaultMessage: "LetsGo Account to Airtime",
  },
  COMMON_BANK_VAS_CABLE_TV: {
    id: "COMMON_BANK_VAS_CABLE_TV",
    defaultMessage: "LetsGo Account to TV",
  },
  COMMON_BANK_TO_MERCHANT: {
    id: "COMMON_BANK_TO_MERCHANT",
    defaultMessage: "LetsGo Account to Merchant",
  },
  WALLET_TO_MERCHANT: {
    id: "WALLET_TO_MERCHANT",
    defaultMessage: "LetsGo Wallet to Merchant",
  },
  COMMON_BANK_VAS_WATER: {
    id: "COMMON_BANK_VAS_WATER",
    defaultMessage: "LetsGo Account to Water",
  },
  COMMON_BANK_VAS_INTERNET: {
    id: "COMMON_BANK_VAS_INTERNET",
    defaultMessage: "LetsGo Account to Internet",
  },
  COMMON_BANK_VAS_VOICE_BUNDLE: {
    id: "COMMON_BANK_VAS_VOICE_BUNDLE",
    defaultMessage: "LetsGo Account to Voice",
  },
  BANK_TO_INTERNAL_BENEFICIARY: {
    id: "BANK_TO_INTERNAL_BENEFICIARY",
    defaultMessage: "Bank to Internal Beneficiary",
  },
  BANK_TO_EXTERNAL_BENEFICIARY: {
    id: "BANK_TO_EXTERNAL_BENEFICIARY",
    defaultMessage: "Bank to External Beneficiary",
  },
  BANK_TO_EXTERNAL_WALLET: {
    id: "BANK_TO_EXTERNAL_WALLET",
    defaultMessage: "Bank to External Wallet",
  },
  BANK_TO_INTERNAL_WALLET: {
    id: "BANK_TO_INTERNAL_WALLET",
    defaultMessage: "Bank to LetsGo Wallet",
  },
  BANK_TO_BILLERS: {
    id: "BANK_TO_BILLERS",
    defaultMessage: "Bank to Billers",
  },
  BANK_TO_ELECTRICITY: {
    id: "BANK_TO_ELECTRICITY",
    defaultMessage: "Bank to Electricity",
  },
  BANK_VAS_AIRTIME: {
    id: "BANK_VAS_AIRTIME",
    defaultMessage: "Bank to Airtime",
  },
  BANK_VAS_CABLE_TV: {
    id: "BANK_VAS_CABLE_TV",
    defaultMessage: "Bank to TV",
  },
  BANK_VAS_WATER: {
    id: "BANK_VAS_WATER",
    defaultMessage: "Bank to Water",
  },
  BANK_VAS_INTERNET: {
    id: "BANK_VAS_INTERNET",
    defaultMessage: "Bank to Internet",
  },
  BANK_VAS_VOICE_BUNDLE: {
    id: "BANK_VAS_VOICE_BUNDLE",
    defaultMessage: "Bank to Voice",
  },
  WALLET_TO_INERNAL_BENEFICIARY: {
    id: "WALLET_TO_INERNAL_BENEFICIARY",
    defaultMessage: "LetsGo Wallet to Internal Beneficiary",
  },
  WALLET_TO_EXTERNAL_BENEFICIARY: {
    id: "WALLET_TO_EXTERNAL_BENEFICIARY",
    defaultMessage: "LetsGo Wallet to External Beneficiary",
  },
  WALLET_TO_EXTERNAL_WALLET: {
    id: "WALLET_TO_EXTERNAL_WALLET",
    defaultMessage: "LetsGo Wallet to External Wallet",
  },
  WALLET_TO_INTERNAL_WALLET: {
    id: "WALLET_TO_INTERNAL_WALLET",
    defaultMessage: "LetsGo Wallet to LetsGo Wallet",
  },
  WALLET_TO_BILLERS: {
    id: "WALLET_TO_BILLERS",
    defaultMessage: "LetsGo Wallet to Billers",
  },
  WALLET_TO_ELECTRICITY: {
    id: "WALLET_TO_ELECTRICITY",
    defaultMessage: "LetsGo Wallet to Electricity",
  },
  WALLET_VAS_AIRTIME: {
    id: "WALLET_VAS_AIRTIME",
    defaultMessage: "LetsGo Wallet to Airtime",
  },
  WALLET_VAS_CABLE_TV: {
    id: "WALLET_VAS_CABLE_TV",
    defaultMessage: "LetsGo Wallet to TV",
  },
  WALLET_VAS_WATER: {
    id: "WALLET_VAS_WATER",
    defaultMessage: "LetsGo Wallet to Water",
  },
  WALLET_VAS_INTERNET: {
    id: "WALLET_VAS_INTERNET",
    defaultMessage: "LetsGo Wallet to Internet",
  },
  WALLET_VAS_VOICE_BUNDLE: {
    id: "WALLET_VAS_VOICE_BUNDLE",
    defaultMessage: "LetsGo Wallet to Voice",
  },
  BILL_CATEGORY: {
    id: "BILL_CATEGORY",
    defaultMessage: "Bill category",
  },
  WICODE_CASH_IN_HINT: {
    id: "WICODE_CASH_IN_HINT",
    defaultMessage:
      "Select the type of account you would like to Cash-in funds to.",
  },
  WICODE_CASH_OUT_HINT: {
    id: "WICODE_CASH_OUT_HINT",
    defaultMessage:
      "Select the type of account you would like to Cash-out funds from.",
  },
  WICODE_ACCOUNT_CTA_IN: {
    id: "WICODE_ACCOUNT_CTA_IN",
    defaultMessage: "Select Cash-in account",
  },
  WICODE_ACCOUNT_CTA_OUT: {
    id: "WICODE_ACCOUNT_CTA_OUT",
    defaultMessage: "Select Cash-out account",
  },
  WICODE_PAY_MERCHANT_HINT: {
    id: "WICODE_PAY_MERCHANT_HINT",
    defaultMessage:
      "Select the type of account you would like to Pay to Merchant from.",
  },
  TO_PEOPLE_AUTO_PAY: {
    id: "TO_PEOPLE_AUTO_PAY",
    defaultMessage: "To Wallets",
  },
  TO_BANK_ACCOUNT_AUTO_PAY: {
    id: "TO_BANK_ACCOUNT_AUTO_PAY",
    defaultMessage: "To Bank Accounts",
  },
  NOT_ELIGIBLE_LOAN_INFO_HOUSING: {
    id: "NOT_ELIGIBLE_LOAN_INFO_HOUSING",
    defaultMessage:
      "Based on your details, we can’t offer you a housing loan, our team will get in touch with you.",
  },
  DOCUMENTS_NOT_FOUND_APIERROR_HANDLE: {
    id: "DOCUMENTS_NOT_FOUND_APIERROR_HANDLE",
    defaultMessage:
      "Failed to retrieve your documents, please go to the nearest branch, or call {contactNo} for assistance",
  },
  LEGAL_INSURANCE_KE_MSE_TITLE: {
    id: "LEGAL_INSURANCE_KE_MSE_TITLE",
    defaultMessage:
      "The loan is inclusive of legal cover up with a annual cost of KSh 600 for the following service",
  },
  TENURE_IN_DAYS: {
    id: "TENURE_IN_DAYS",
    defaultMessage: "{tenure} Days",
  },
  DYNAMIC_ERROR_LABEL: {
    id: "DYNAMIC_ERROR_LABEL",
    defaultMessage: "Invalid Number. Please enter a valid {error}",
  },
  HOUSING_REJECTED_LOAN_TEXT: {
    id: "HOUSING_REJECTED_LOAN_TEXT",
    defaultMessage:
      "Loan request not approved as a few of your details don't match our affordable housing loan criteria.",
  },
  SUCCESSFULLY_EDITED_AUTOPAY: {
    id: "SUCCESSFULLY_EDITED_AUTOPAY",
    defaultMessage: "Autopay edited successfully",
  },
  TXT_LETSGO_NATION: {
    id: "TXT_LETSGO_NATION",
    defaultMessage: "LetsGo Nation",
  },
  COMMON_API_ERROR_CUSTOMER_CARE_MESSAGE: {
    id: "COMMON_API_ERROR_CUSTOMER_CARE_MESSAGE",
    defaultMessage:
      "The consultation fee is charged per session and this will be charged at {currencySymbol} {chargedAmount}",
  },
  LETSGO_DOCTOR_SELECT_PROFILE_TEXT_1: {
    id: "LETSGO_DOCTOR_SELECT_PROFILE_TEXT_1",
    defaultMessage: "Tell us",
  },
  LETSGO_DOCTOR_SELECT_PROFILE_TEXT_2: {
    id: "LETSGO_DOCTOR_SELECT_PROFILE_TEXT_2",
    defaultMessage: "Who are you",
  },
  DISCOVERY_LETSGO_BANK_TZ: {
    id: "DISCOVERY_LETSGO_BANK_TZ",
    defaultMessage: "Letshego Bank Tanzania",
  },
  DISCOVERY_LETSGO_FAIDIKA: {
    id: "DISCOVERY_LETSGO_FAIDIKA",
    defaultMessage: "Letshego Faidika",
  },
  DISCOVERY_ADDRESS_BANK_TZ: {
    id: "DISCOVERY_ADDRESS_BANK_TZ",
    defaultMessage:
      "Letshego Bank Tanzania Limited Plot 395, Ursino North, Mwai Kibaki Road. Dar Es Salaam, Tanzania.",
  },
  LETSGO_PROFILE_UPLOAD_PASSPORT_PHOTO: {
    id: "LETSGO_PROFILE_UPLOAD_PASSPORT_PHOTO",
    defaultMessage: "Upload your passport size photo",
  },
  LETSGO_PROFILE_GHANA_CARD_NUMBER: {
    id: "LETSGO_PROFILE_GHANA_CARD_NUMBER",
    defaultMessage: "Ghana Card Number",
  },
  SEND_FUNDS_HELP_TITLE: {
    id: "SEND_FUNDS_HELP_TITLE",
    defaultMessage: "Sending funds made quick and hassle free",
  },
  LOAN_AMOUNT_ERROR_MINIMUM: {
    id: "LOAN_AMOUNT_ERROR",
    defaultMessage: "Please enter amount greater than {min}",
  },
  WICODE_GENERATE_TAB: {
    id: "WICODE_GENERATE_TAB",
    defaultMessage: "Generate",
  },
  WICODE_PENDING_TAB: {
    id: "WICODE_PENDING_TAB",
    defaultMessage: "View pending request",
  },
  WICODE_TOKEN_HELP_TEXT: {
    id: "WICODE_TOKEN_HELP_TEXT",
    defaultMessage: "Your WiCode has been generated",
  },
  WICODE_ACCOUNT_NUMBER_MASK: {
    id: "WICODE_ACCOUNT_NUMBER_MASK",
    defaultMessage: "A/c {accountNumber}",
  },
  ADDING_FUNDS_INTO: {
    id: "ADDING_FUNDS_INTO",
    defaultMessage: "Adding funds into",
  },
  PROFILE_GHANA_ID_VERIFY_BTN: {
    id: "PROFILE_GHANA_ID_VEROIFY_BTN",
    defaultMessage: "Confirm ID status",
  },
  SELECT_ACCOUNT: {
    id: "SELECT_ACCOUNT",
    defaultMessage: "Select account",
  },
  WICODE_CASH_IN_SUB_TITLE: {
    id: "WICODE_CASH_IN_SUB_TITLE",
    defaultMessage: "Choose account you would like to cash in funds to",
  },
  WICODE_CASH_OUT_SUB_TITLE: {
    id: "WICODE_CASH_OUT_SUB_TITLE",
    defaultMessage: "Choose account you would like to cash out funds to",
  },
  WICODE_PAY_MERCHANT_SUB_TITLE: {
    id: "WICODE_PAY_MERCHANT_SUB_TITLE",
    defaultMessage: "Choose account you would like to pay to merchant funds to",
  },
  WICODE_TITLE: {
    id: "WICODE_TITLE",
    defaultMessage: "WiCode",
  },
  CREDIT_OR_DEBIT_CARD: {
    id: "CREDIT_AND_DEBIT_CARD",
    defaultMessage: "Credit or Debit Card",
  },
  GET_IN_TOUCH_INSTANT_LAON: {
    id: "GET_IN_TOUCH_INSTANT_LAON",
    defaultMessage:
      "In case of queries, please get in touch with our team and they will help you out.",
  },
  LETSGO_GHANA_KYC_PASSPORT_PHOTO: {
    id: "LETSGO_GHANA_KYC_PASSPORT_PHOTO",
    defaultMessage: "Take a passport size photo",
  },
  SIGNATURE_TIME_LINE: {
    id: "SIGNATURE_TIME_LINE",
    defaultMessage: "your loan offer will expire in {days} days on {date}",
  },
  DAYS_TXT: {
    id: "DAYS_TXT",
    defaultMessage: "days",
  },
  MONTHS_TXT: {
    id: "MONTHS_TXT",
    defaultMessage: "months",
  },
  INSTANT_LOAN_SUBMISSION_SIGNATURE_REQUIRED: {
    id: "INSTANT_LOAN_SUBMISSION_SIGNATURE_REQUIRED",
    defaultMessage: "Looks like we don’t have your signature copy with us.",
  },
  INSTANT_LOAN_SUBMISSION_SIGNATURE_REQUIRED_SUB_TEXT_1: {
    id: "INSTANT_LOAN_SUBMISSION_SIGNATURE_REQUIRED_SUB_TEXT_1",
    defaultMessage:
      "Please make a one-time visit to the nearest branch to provide your signature within 48 hours. We will disburse the loan amount once your signature is confirmed and verified",
  },
  INSTANT_LOAN_SUBMISSION_SIGNATURE_REQUIRED_SUB_TEXT_2: {
    id: "INSTANT_LOAN_SUBMISSION_SIGNATURE_REQUIRED_SUB_TEXT_2",
    defaultMessage: "For any queries contact {customerCare}",
  },
  MSE_KYC_MODAL_ADDRESS: {
    id: "MSE_KYC_MODAL_ADDRESS",
    defaultMessage: "Address",
  },
  EDIT_BANK_DETAILS: {
    id: "EDIT_BANK_DETAILS",
    defaultMessage: "Edit bank details",
  },
  BANK_DETAILS_NOT_FOUND_TXT: {
    id: "BANK_DETAILS_NOT_FOUND_TXT",
    defaultMessage: "Bank details not found",
  },
  BANK_DETAILS_NOT_FOUND_DESC: {
    id: "BANK_DETAILS_NOT_FOUND_DESC",
    defaultMessage:
      "The beneficiary’s bank details  doesn’t seem to exist.  Try another bank account or account number",
  },
  REENTER_DISBURSEMENT_DETAILS: {
    id: "REENTER_DISBURSEMENT_DETAILS",
    defaultMessage: "Re-enter {disburseMethod} details",
  },
  EXISTING_ACTIVE_LOAN_TXT: {
    id: "EXISTING_ACTIVE_LOAN_TXT",
    defaultMessage:
      "This {disburseMethod} account is already linked to an active {productName}. Please repay your {productName} before applying for another loan.",
  },
  LETSGO_PROFILE_GET_SUPPORT: {
    id: "LETSGO_PROFILE_GET_SUPPORT",
    defaultMessage: "Get support",
  },
  LETSGO_PROFILE_GET_SUPPORT_TEXT: {
    id: "LETSGO_PROFILE_GET_SUPPORT_TEXT",
    defaultMessage:
      "Give feedbacks and get help to better enhance your experience on the mall",
  },
  LETSGO_FEEDBACK_HEADER_NAV: {
    id: "LETSGO_FEEDBACK_HEADER_NAV",
    defaultMessage: "Share your feedback",
  },
  LETSGO_FEEDBACK_SUBHEADER_TEXT: {
    id: "LETSGO_FEEDBACK_SUBHEADER_TEXT",
    defaultMessage: "Easily give feedback and speak directly to an agent",
  },
  LETSGO_FEEDBACK_LIST_ITEM_HEADER: {
    id: "LETSGO_FEEDBACK_LIST_ITEM_HEADER",
    defaultMessage: "What you can do",
  },
  LETSGO_FEEDBACK_LIST_ITEM_ONE: {
    id: "LETSGO_FEEDBACK_LIST_ITEM_ONE",
    defaultMessage:
      "Share your complaints and compliments on any issue you have encountered or services rendered.",
  },
  LETSGO_FEEDBACK_LIST_ITEM_TWO: {
    id: "LETSGO_FEEDBACK_LIST_ITEM_TWO",
    defaultMessage:
      "Make enquiries on anything that has been bothering you and we would get back to you on how to resolve it.",
  },
  LETSGO_FEEDBACK_LIST_ITEM_THREE: {
    id: "LETSGO_FEEDBACK_LIST_ITEM_THREE",
    defaultMessage:
      "Speak directly to an agent and get your issues resolved in record time.",
  },
  LETSGO_FEEDBACK_SHARE_CTA_SUBHEADING: {
    id: "LETSGO_FEEDBACK_SHARE_CTA_SUBHEADING",
    defaultMessage:
      "Help us identify key issues we can better explore to give you a good experience.",
  },
  LETSGO_FEEDBACK_AGENT_CTA_HEADING: {
    id: "LETSGO_FEEDBACK_AGENT_CTA_HEADING",
    defaultMessage: "Speak to an agent",
  },
  LETSGO_FEEDBACK_AGENT_CTA_SUBHEADING: {
    id: "LETSGO_FEEDBACK_AGENT_CTA_SUBHEADING",
    defaultMessage:
      "Speak to a customer care representative to help guide you through the app",
  },
  LETSGO_FEEDBACK_TITLE_STEPPER_ONE: {
    id: "LETSGO_FEEDBACK_TITLE_STEPPER_ONE",
    defaultMessage: "How do you feel",
  },
  LETSGO_FEEDBACK_TITLE_STEPPER_TWO: {
    id: "LETSGO_FEEDBACK_TITLE_STEPPER_TWO",
    defaultMessage: "About our service",
  },
  LETSGO_FEEDBACK_UNSATISFIED: {
    id: "LETSGO_FEEDBACK_UNSATISFIED",
    defaultMessage: "Unsatisfied",
  },
  LETSGO_FEEDBACK_FAIRLY_SATISFIED: {
    id: "LETSGO_FEEDBACK_FAIRLY_SATISFIED",
    defaultMessage: "Fairly Satisfied",
  },
  LETSGO_FEEDBACK_SATISFIED: {
    id: "LETSGO_FEEDBACK_SATISFIED",
    defaultMessage: "Satisfied",
  },
  LETSGO_FEEDBACK_VERY_SATISFIED: {
    id: "LETSGO_FEEDBACK_VERY_SATISFIED",
    defaultMessage: "Very Satisfied",
  },
  LETSGO_FEEDBACK_HAVE_QUESTION: {
    id: "LETSGO_FEEDBACK_HAVE_QUESTION",
    defaultMessage: "Have Questions?",
  },
  LETSGO_FEEDBACK_SHARE_TITLE_STEPPER_ONE: {
    id: "LETSGO_FEEDBACK_SHARE_TITLE_STEPPER_ONE",
    defaultMessage: "Share your",
  },
  LETSGO_FEEDBACK_SHARE_TITLE_COMPLAINTS: {
    id: "LETSGO_FEEDBACK_SHARE_TITLE_COMPLAINTS",
    defaultMessage: "Complaints",
  },
  LETSGO_FEEDBACK_SHARE_TITLE_FEEDBACK: {
    id: "LETSGO_FEEDBACK_SHARE_TITLE_FEEDBACK",
    defaultMessage: "Feedback",
  },
  LETSGO_FEEDBACK_SHARE_TITLE_COMPLIMENT: {
    id: "LETSGO_FEEDBACK_SHARE_TITLE_COMPLIMENT",
    defaultMessage: "Compliment",
  },
  LETSGO_FEEDBACK_SHARE_TITLE_ENQUIRY: {
    id: "LETSGO_FEEDBACK_SHARE_TITLE_ENQUIRY",
    defaultMessage: "Enquiry",
  },
  LETSGO_FEEDBACK_SHARE_COMPLAINTS_SUBTITLE: {
    id: "LETSGO_FEEDBACK_SHARE_COMPLAINTS_SUBTITLE",
    defaultMessage:
      "Please tell us what you didn’t like so we can improve on it in the future",
  },
  LETSGO_FEEDBACK_COMPLAINT_CATEGORY_LABEL: {
    id: "LETSGO_FEEDBACK_COMPLAINT_CATEGORY_LABEL",
    defaultMessage: "Select complaint category",
  },
  LETSGO_FEEDBACK_COMPLAINT_TEXTFIELD_LABEL: {
    id: "LETSGO_FEEDBACK_COMPLAINT_TEXTFIELD_LABEL",
    defaultMessage: "Type your complaint here",
  },
  LETSGO_FEEDBACK_TEXTFIELD_LABEL: {
    id: "LETSGO_FEEDBACK_TEXTFIELD_LABEL",
    defaultMessage: "Type your feedback here",
  },
  LETSGO_FEEDBACK_COMPLIMENT_TEXTFIELD_LABEL: {
    id: "LETSGO_FEEDBACK_COMPLIMENT_TEXTFIELD_LABEL",
    defaultMessage: "Type your compliment here",
  },
  LETSGO_FEEDBACK_ENQUIRY_TEXTFIELD_LABEL: {
    id: "LETSGO_FEEDBACK_ENQUIRY_TEXTFIELD_LABEL",
    defaultMessage: "Type your enquiry here",
  },
  LETSGO_FEEDBACK_FAIRLY_SATISFIED_SUBTITLE: {
    id: "LETSGO_FEEDBACK_FAIRLY_SATISFIED_SUBTITLE",
    defaultMessage:
      "Please tell us how we can better improve on our services in the future",
  },
  LETSGO_FEEDBACK_VERY_SATISFIED_SUBTITLE: {
    id: "LETSGO_FEEDBACK_VERY_SATISFIED_SUBTITLE",
    defaultMessage:
      "Please tell us what we did right and what we can do better to enhance the digital mall",
  },
  LETSGO_FEEDBACK_HAVE_QUESTION_SUBTITLE: {
    id: "LETSGO_FEEDBACK_HAVE_QUESTION_SUBTITLE",
    defaultMessage: "Tell us in full details what you need us to help you with",
  },
  LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_LABEL_ONE: {
    id: "LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_LABEL_ONE",
    defaultMessage: "App functionality",
  },
  LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_SUBLABEL_ONE: {
    id: "LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_SUBLABEL_ONE",
    defaultMessage: "Blank page, Error screen, OTP, App responsiveness. etc",
  },
  LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_LABEL_TWO: {
    id: "LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_LABEL_TWO",
    defaultMessage: "Difficulty completing transactions",
  },
  LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_SUBLABEL_TWO: {
    id: "LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_SUBLABEL_TWO",
    defaultMessage: "General navigation. etc",
  },
  DOCTOR_PAYMENT_STATUS_SUB_TEXT: {
    id: "DOCTOR_PAYMENT_STATUS_SUB_TEXT",
    defaultMessage: "Process your transaction",
  },
  LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_LABEL_THREE: {
    id: "LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_LABEL_THREE",
    defaultMessage: "General feedback",
  },
  LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_SUBLABEL_THREE: {
    id: "LETSGO_FEEDBACK_UNSATISFIED_CATEGORY_SUBLABEL_THREE",
    defaultMessage: "Language, Colour, Accessibility. etc",
  },
  LETSGO_FEEDBACK_QUESTION_CATEGORY_LABEL: {
    id: "LETSGO_FEEDBACK_QUESTION_CATEGORY_LABEL",
    defaultMessage: "Select enquiry category",
  },
  LETSGO_FEEDBACK_TEXTFIELD_HELPERTEXT: {
    id: "LETSGO_FEEDBACK_TEXTFIELD_HELPERTEXT",
    defaultMessage: "Under 150 characters long",
  },
  LETSGO_FEEDBACK_TEXTFIELD_ERROR_MAXCHAR: {
    id: "LETSGO_FEEDBACK_TEXTFIELD_ERROR_MAXCHAR",
    defaultMessage: "Should be under 150 characters long",
  },
  LETSGO_FEEDBACK_SUCCESS_HEADER_ONE: {
    id: "LETSGO_FEEDBACK_SUCCESS_HEADER_ONE",
    defaultMessage: "Hurray! Thank you",
  },
  LETSGO_FEEDBACK_SUCCESS_HEADER_TWO: {
    id: "LETSGO_FEEDBACK_SUCCESS_HEADER_TWO",
    defaultMessage: "For your feedback",
  },
  LETSGO_FEEDBACK_SUCCESS_MODAL_MESSAGE: {
    id: "LETSGO_FEEDBACK_SUCCESS_MODAL_MESSAGE",
    defaultMessage:
      "Your feedback has been successfully recorded, we appreciate your effort.",
  },
  M_PESA: {
    id: "M_PESA",
    defaultMessage: "M-Pesa",
  },
  LETSGO_DOCTOR_FAIL_PAYMENT: {
    id: "LETSGO_DOCTOR_FAIL_PAYMENT",
    defaultMessage: "Your payment was unsuccessful",
  },
  LOAN_REPAYMENT_INITIATED: {
    id: "LOAN_REPAYMENT_INITIATED",
    defaultMessage: "Loan repayment request initiated",
  },
  LOAN_REPAYMENT_FAIL: {
    id: "LOAN_REPAYMENT_FAIL",
    defaultMessage: "Loan repayment request failed",
  },
  LOAN_REPAYMENT_INITIATED_INFO: {
    id: "LOAN_REPAYMENT_INITIATED_INFO",
    defaultMessage:
      "You will receive a prompt to confirm this payment. We'll notify you through email or SMS when your payment request is approved.",
  },
  LOAN_REPAYMENT_FAIL_INFO: {
    id: "LOAN_REPAYMENT_FAIL_INFO",
    defaultMessage:
      "Unfortunately, we were not able to initiate your loan repayment request. Please try again later.",
  },
  LETSO_DOCTOR_APPOINTMENT_RETRY_MESSAGE: {
    id: "LETSO_DOCTOR_APPOINTMENT_RETRY_MESSAGE",
    defaultMessage: "Please try again to continue",
  },
  LETSGO_DOCTOR_BACK_TO_PAYMENT: {
    id: "LETSGO_DOCTOR_BACK_TO_PAYMENT",
    defaultMessage: "Back to Payment",
  },
  M_PESA_DETAILS_TEXT: {
    id: "M_PESA_DETAILS_TEXT",
    defaultMessage: "M-Pesa Details",
  },
  TRANSACTION_DESCRIPTION: {
    id: "TRANSACTION_DESCRIPTION",
    defaultMessage: "Transaction description",
  },
  MPESA_PAYMENT_WARNING_MESSAGE: {
    id: "MPESA_PAYMENT_WARNING_MESSAGE",
    defaultMessage:
      "You will receive a prompt to approve this payment outside the LetsGo Doctor",
  },
  RECIPIENT_NAME: {
    id: "RECIPIENT_NAME",
    defaultMessage: "Recipient name",
  },
  RECIPIENT_NOT_FOUND: {
    id: "RECIPIENT_NOT_FOUND",
    defaultMessage: "Recipient not found",
  },
  RECIPIENT_NOT_FOUND_MSG: {
    id: "RECIPIENT_NOT_FOUND_MSG",
    defaultMessage:
      "Please check if the wallet is linked to this mobile number.",
  },
  FROM_PEOPLE: {
    id: "FROM_PEOPLE",
    defaultMessage: "From people",
  },
  RECEIVE_FUNDS_SUBTITLE: {
    id: "RECEIVE_FUNDS_SUBTITLE",
    defaultMessage: "Receive funds made quick and hassel free",
  },
  AMOUNT_REQUESTED: {
    id: "AMOUNT_REQUESTED",
    defaultMessage: "Amount requested",
  },
  REQUESTED_FUNDS_INTO: {
    id: "REQUESTED_FUNDS_INTO",
    defaultMessage: "Request funds into",
  },
  SENDING_REQUEST_TO: {
    id: "SENDING_REQUEST_TO",
    defaultMessage: "Sending request to",
  },
  REQUEST_FUNDS_INTO_DETAILS: {
    id: "REQUEST_FUNDS_INTO_DETAILS",
    defaultMessage:
      "You're requesting funds from <custom>{user}</custom>'s <custom>{wallet}</custom> wallet",
  },
  CHOOSE_RECEIVE_ACCOUNT: {
    id: "CHOOSE_RECEIVE_ACCOUNT",
    defaultMessage: "Choose the account you want to request funds into",
  },
  CHOOSE_RECEIVE_ACCOUNT_COMMON: {
    id: "CHOOSE_RECEIVE_ACCOUNT_COMMON",
    defaultMessage:
      "Select the account where you would like to request funds into",
  },
  NEW_REQUEST: {
    id: "NEW_REQUEST",
    defaultMessage: "New request",
  },
  REQUEST_SUBMITTED: {
    id: "REQUEST_SUBMITTED",
    defaultMessage: "Request Submitted",
  },
  LETSGO_ENQUIRY_CATEGORY_TYPE: {
    id: "LETSGO_ENQUIRY_CATEGORY_TYPE",
    defaultMessage: "Category type",
  },
  INSTANT_LOAN_UPLOAD_PICTURE_HEADING: {
    id: "INSTANT_LOAN_UPLOAD_PICTURE_HEADING",
    defaultMessage:
      "Here’s how you can upload pictures of your ID front & back",
  },
  INSTANT_LOAN_UPLOAD_PICTURE_STEP_1: {
    id: "INSTANT_LOAN_UPLOAD_PICTURE_STEP_1",
    defaultMessage:
      "Take the photo in a well-lit room and <custom>don’t cover any information</custom> with your fingers",
  },
  INSTANT_LOAN_UPLOAD_PICTURE_STEP_2: {
    id: "INSTANT_LOAN_UPLOAD_PICTURE_STEP_2",
    defaultMessage:
      "All the elements such as ID number, name, date of birth, place of birth, expiry date, etc. <custom>must be clearly visible</custom>",
  },
  INSTANT_LOAN_UPLOAD_PICTURE_STEP_3: {
    id: "INSTANT_LOAN_UPLOAD_PICTURE_STEP_3",
    defaultMessage:
      "Please refer the <custom>placeholder images shown above</custom> and upload similar picture of your ID front and back",
  },
  INSTANT_LOAN_UPLOAD_PICTURE_STEP_4: {
    id: "INSTANT_LOAN_UPLOAD_PICTURE_STEP_4",
    defaultMessage:
      "This is to verify your <custom>picture</custom> with <custom>National ID number and to extract the necessary details</custom>",
  },
  GENERAL_ERROR_SUB_CONTENT_ONE: {
    id: "GENERAL_ERROR_SUB_CONTENT_ONE",
    defaultMessage:
      "We are currently unable to process your selfie. We are working to resolve the issue.",
  },
  GENERAL_ERROR_SUB_CONTENT_TWO: {
    id: "GENERAL_ERROR_SUB_CONTENT_TWO",
    defaultMessage:
      "If you have any queries, please contact our team, and they will assist you.",
  },
  OOPS_NAME: {
    id: "OOPS_NAME",
    defaultMessage: "Oops, {name}!",
  },
  INSTANT_LOAN_UNABLE_PROCESS_SELFIE: {
    id: "INSTANT_LOAN_UNABLE_PROCESS_SELFIE",
    defaultMessage: "We were unable to process your selfie.",
  },
  INSTANT_LOAN_RE_UPLOAD_TEXT: {
    id: "INSTANT_LOAN_RE_UPLOAD_TEXT",
    defaultMessage: "Please re-upload your selfie in order to proceed.",
  },
  LETSGO_DOCTOR_CONSULT_FEE_TEXT: {
    id: "LETSGO_DOCTOR_CONSULT_FEE_TEXT",
    defaultMessage: "Consultation fee",
  },
  LETSGO_DOCTOR_SESSION_TEXT: {
    id: "LETSGO_DOCTOR_SESSION_TEXT",
    defaultMessage: "Per session",
  },
  LETSHEGO_DOCTOR_CONSULT_DOCTOR_TERMS_AND_CONDITIONS: {
    id: "LETSHEGO_DOCTOR_CONSULT_DOCTOR_TERMS_AND_CONDITIONS",
    defaultMessage:
      "I allow Letshego Doctor to store my information for further consultations. I accept all {termsAndCondition}",
  },
  LETSHEGO_DOCTOR_CONSULT_DOCTOR_STATEMENT: {
    id: "LETSHEGO_DOCTOR_CONSULT_DOCTOR_STATEMENT",
    defaultMessage:
      "The consultation fee is charged per session and this will be charged at {currencySymbol} {chargedAmount}",
  },
  MSE_INSTALLMENT_INFORMATION_TEXT: {
    id: "MSE_INSTALLMENT_INFORMATION_TEXT",
    defaultMessage:
      "Your above installment and amount to repay shows the maximum amount due at end of the maximum tenure of {tenure} {tenureLabel}. Please NOTE interest rate is {interest} per month.",
  },
  LETSGO_PROFILE_GENERATE_STATEMENT: {
    id: "LETSGO_PROFILE_GENERATE_STATEMENT",
    defaultMessage: "Generate statement",
  },
  LETSGO_PROFILE_GENERATE_STATEMENT_SUBTEXT: {
    id: "LETSGO_PROFILE_GENERATE_STATEMENT_SUBTEXT",
    defaultMessage: "Manage, view and download your account statement",
  },
  LETSGO_PROFILE_GENERATE_STATEMENT_HEADER: {
    id: "LETSGO_PROFILE_GENERATE_STATEMENT_HEADER",
    defaultMessage: "Statement",
  },
  LETSGO_PROFILE_STATEMENT_ACCOUNT_NUMBER: {
    id: "LETSGO_PROFILE_STATEMENT_ACCOUNT_NUMBER",
    defaultMessage: "Acc no.",
  },
  LETSGO_STATEMENT_DURATION_MODAL_HEADER: {
    id: "LETSGO_STATEMENT_DURATION_MODAL_HEADER",
    defaultMessage: "Select your prefered duration",
  },
  LETSGO_STATEMENT_DURATION_MODAL_DESCRIPTION: {
    id: "LETSGO_STATEMENT_DURATION_MODAL_DESCRIPTION",
    defaultMessage:
      "Kindly select your preferred duration to download your statement",
  },
  DOCTOR_MPESA_DETAIL_DESCRIPTION: {
    id: "DOCTOR_MPESA_DETAIL_DESCRIPTION",
    defaultMessage: "Kindly enter your M-Pesa details to continue",
  },
  DOCTOR_CARD_DETAIL_DESCRIPTION: {
    id: "DOCTOR_CARD_DETAIL_DESCRIPTION",
    defaultMessage: "Kindly enter your additional details to continue",
  },
  DOCTOR_MPESA_DETAIL_TXT: {
    id: "DOCTOR_MPESA_DETAIL_TXT",
    defaultMessage: "M-Pesa Details",
  },
  DOCTOR_PAYMENT_OPTION_DESC: {
    id: "DOCTOR_PAYMENT_OPTION_DESC",
    defaultMessage: "Please choose your preferred payment method",
  },
  DOCTOR_REDIRECT_MSG_LOADER: {
    id: "DOCTOR_REDIRECT_MSG_LOADER",
    defaultMessage: "Redirecting you to your bank’s website",
  },
  LETSGO_DOCTOR_INVOICE_TITLE: {
    id: "LETSGO_DOCTOR_INVOICE_TITLE",
    defaultMessage: "Get your personal invoice",
  },
  LETSGO_DOCTOR_INVOICE_CONTENT: {
    id: "LETSGO_DOCTOR_INVOICE_CONTENT",
    defaultMessage:
      "Kindly enter your preferred email address to receive your invoice ",
  },
  LETSGO_DOCTOR_INVOICE_EMAIL_ADDRESS: {
    id: "LETSGO_DOCTOR_INVOICE_EMAIL_ADDRESS",
    defaultMessage: "Email address",
  },
  LETSGO_DOCTOR_GET_INVOICE: {
    id: "LETSGO_DOCTOR_GET_INVOICE",
    defaultMessage: "Get Invoice",
  },
  MSE_FAILED_CUSTOMER_VERIFICATION: {
    id: "MSE_FAILED_CUSTOMER_VERIFICATION",
    defaultMessage:
      "Register your KYC details with continue and proceed to your personalised dashboard, Or try again with existing details.",
  },
  LETSGO_ACCOUNT_PAYMENT_TITLE: {
    id: "LETSGO_ACCOUNT_PAYMENT_TITLE",
    defaultMessage: "LetsGo Account",
  },
  FLEXI_ACCOUNT_PAYMENT_TITLE: {
    id: "FLEXI_ACCOUNT_PAYMENT_TITLE",
    defaultMessage: "Flexi Account",
  },
  GH_TOTAL_PAYABLE_AMOUNT_TEXT: {
    id: "GH_TOTAL_PAYABLE_AMOUNT_TEXT",
    defaultMessage:
      "Total Payable amount of {currency} {amount} will include a transaction fee of {currency} {fee} and E levy of {currency} {elevyfee}",
  },
  MOZ_TOTAL_PAYABLE_AMOUNT_TEXT: {
    id: "MOZ_TOTAL_PAYABLE_AMOUNT_TEXT",
    defaultMessage:
      "Please note that you have 1 free transaction per day on your account and subsequent transaction you will be charged {currency} {fee}",
  },
  RECEIVE_FUND_NUMBER_HINT: {
    id: "RECEIVE_FUND_NUMBER_HINT",
    defaultMessage:
      "Enter the mobile number of the person you are requesting funds from",
  },
  ENTER_PAYER_DETAILS: {
    id: "ENTER_PAYER_DETAILS",
    defaultMessage: "Enter payer details",
  },
  REQUEST_FAILED: {
    id: "REQUEST_FAILED",
    defaultMessage: "Request Failed",
  },
  PHOTO_PREVIEW_MESSAGE: {
    id: "PHOTO_PREVIEW_MESSAGE",
    defaultMessage:
      "Preview your passport size photo and ensure it is clear and in a well lit room.",
  },
  OPEN_LETSGO_ACCOUNT_TITLE: {
    id: "OPEN_LETSGO_ACCOUNT_TITLE",
    defaultMessage: "Open an account with us.",
  },
  OPEN_LETSGO_ACCOUNT_SUBTITLE: {
    id: "OPEN_LETSGO_ACCOUNT_SUBTITLE",
    defaultMessage: "Open your LetsGo transaction account hassle free today !",
  },
  LETSGO_ACCOUNT_PAGE_TITLE: {
    id: "LETSGO_ACCOUNT_PAGE_TITLE",
    defaultMessage: "LetsGo Account",
  },
  LETSGO_ACCOUNT_PAGE_USER_TEXT: {
    id: "LETSGO_ACCOUNT_PAGE_USER_TEXT",
    defaultMessage: "Open your LetsGo transaction account here !",
  },
  NG_LETSGO_ACCOUNT_PAGE_USER_TEXT: {
    id: "NG_LETSGO_ACCOUNT_PAGE_USER_TEXT",
    defaultMessage: "Select your preferred LetsGo transaction account here !",
  },
  ACCOUNT_BENEFITS: {
    id: "ACCOUNT_BENEFITS",
    defaultMessage: "Account benefits",
  },
  TRANSACTIONAL_SAVINGS: {
    id: "TRANSACTIONAL_SAVINGS",
    defaultMessage: "Transactional Savings",
  },
  FLEXI_SAVINGS_TEXT1: {
    id: "FLEXI_SAVINGS_TEXT1",
    defaultMessage: "Higher interest rate {min}% and max of {max}%",
  },
  TRANSACTIONAL_SAVINGS_TEXT1: {
    id: "TRANSACTIONAL_SAVINGS_TEXT1",
    defaultMessage: "Interest rate {min}% and max of {max}%",
  },
  TRANSACTIONAL_SAVINGS_TEXT2: {
    id: "TRANSACTIONAL_SAVINGS_TEXT2",
    defaultMessage: "ATM card",
  },
  TRANSACTIONAL_SAVINGS_TEXT3: {
    id: "TRANSACTIONAL_SAVINGS_TEXT3",
    defaultMessage: "Can access loan product",
  },
  TRANSACTIONAL_SAVINGS_TEXT4: {
    id: "TRANSACTIONAL_SAVINGS_TEXT4",
    defaultMessage: "Zero minimum balance",
  },
  TRANSACTIONAL_SAVINGS_TEXT5: {
    id: "TRANSACTIONAL_SAVINGS_TEXT5",
    defaultMessage: "Minimum open balance - {currency}{amount}",
  },
  NEW_ACCOUNT_KYC3_DESC: {
    id: "NEW_ACCOUNT_KYC3_DESC",
    defaultMessage:
      "Your details are saved with us. Please confirm your details to open your LetsGo account.",
  },
  NEW_ACCOUNT_KYC1_DESC: {
    id: "NEW_ACCOUNT_KYC1_DESC",
    defaultMessage:
      "Your KYC details need to be completed to open a LetsGo account. Click below to complete your details",
  },
  NEW_ACCOUNT_USER_DETAILS_TITLE: {
    id: "NEW_ACCOUNT_USER_DETAILS_TITLE",
    defaultMessage: "Review and confirm details",
  },
  NEW_ACCOUNT_USER_CERTIFY_CHECKBOX_TITILE: {
    id: "NEW_ACCOUNT_USER_DETAILS_TITLE",
    defaultMessage:
      "I certify that all the information provided by me for this LetsGo account application is correct.",
  },
  NEW_ACCOUNT_USER_T_C_TITLE: {
    id: "NEW_ACCOUNT_USER_T_C_TITLE",
    defaultMessage:
      "I allow Letshego to access my personal information for account opening. I accept all {terms}",
  },
  NEW_ACCOUNT_USER_REVIEW_CONFIRM_TEXT: {
    id: "NEW_ACCOUNT_USER_REVIEW_CONFIRM_TEXT",
    defaultMessage: "Please review and confirm your application details.",
  },

  APPLIED_LOAN: {
    id: "APPLIED_LOAN",
    defaultMessage: "Applied loan",
  },
  ENTER: {
    id: "ENTER",
    defaultMessage: "Enter",
  },
  TERMINAL_ID_ERROR: {
    id: "TERMINAL_ID_ERROR",
    defaultMessage: "Kindly enter the correct Terminal ID",
  },
  MERCHANT_FUNDS_INTO_DETAILS: {
    id: "MERCHANT_FUNDS_INTO_DETAILS",
    defaultMessage: "You're sending funds to <custom>{user}</custom>",
  },
  PAYMENT_METHOD_MERCHANT_SUB_TITLE: {
    id: "PAYMENT_METHOD_MERCHANT_SUB_TITLE",
    defaultMessage:
      "Select the account where you would like to send funds from",
  },
  WALLET_FUNDS_TITLE: {
    id: "WALLET_FUNDS_TITLE",
    defaultMessage: "Enter wallet account number",
  },
  WALLET_ACCOUNT_NUMBER: {
    id: "WALLET_ACCOUNT_NUMBER",
    defaultMessage: "Wallet account number",
  },
  WALLET_ACCOUNT_DISPLAY: {
    id: "WALLET_ACCOUNT_DISPLAY",
    defaultMessage: "Wallet A/c No : {account}",
  },
  LETSGO_STATEMENT_EMAIL_MODAL_HEADER: {
    id: "LETSGO_STATEMENT_EMAIL_MODAL_HEADER",
    defaultMessage: "Get your personal statement",
  },
  LETSGO_STATEMENT_EMAIL_MODAL_DESCRIPTION: {
    id: "LETSGO_STATEMENT_EMAIL_MODAL_DESCRIPTION",
    defaultMessage:
      "Kindly select your duration and enter your email address to download your statement",
  },
  LETSGO_STATEMENT_EMAIL_MODAL_BUTTON: {
    id: "LETSGO_STATEMENT_EMAIL_MODAL_BUTTON",
    defaultMessage: "Email statement",
  },
  LETSGO_STATEMENT_DOWNLOAD_MODAL_BUTTON: {
    id: "LETSGO_STATEMENT_DOWNLOAD_MODAL_BUTTON",
    defaultMessage: "Download statement",
  },
  START_DATE: {
    id: "START_DATE",
    defaultMessage: "Start date",
  },
  END_DATE: {
    id: "END_DATE",
    defaultMessage: "End date",
  },
  LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT: {
    id: "LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT",
    defaultMessage: "Unable to fetch statement for this loan type",
  },
  LETSGO_STATEMENT_NO_LOANS_PRESENT: {
    id: "LETSGO_STATEMENT_NO_LOANS_PRESENT",
    defaultMessage: "You currently don’t have an active loan",
  },
  LETSGO_STATEMENT_CONTACT_CUSTOMER_SERVICE: {
    id: "LETSGO_STATEMENT_CONTACT_CUSTOMER_SERVICE",
    defaultMessage: "For more information, contact customer service below",
  },
  LETSGO_STATEMENT_NO_PRODUCT_CUSTOMER: {
    id: "LETSGO_STATEMENT_NO_PRODUCT_CUSTOMER",
    defaultMessage: "Contact <custom>customer care here</custom>",
  },
  LETSGO_STATEMENT_UNABLE_TO_FETCH_FOR_LOAN_HEADER: {
    id: "LETSGO_STATEMENT_UNABLE_TO_FETCH_FOR_LOAN_HEADER",
    defaultMessage: "Unable to fetch statement for this loan type",
  },
  LETSGO_STATEMENT_UNABLE_TO_FETCH_FOR_LOAN_SUBHEADER: {
    id: "LETSGO_STATEMENT_UNABLE_TO_FETCH_FOR_LOAN_SUBHEADER",
    defaultMessage:
      "Kindly note that we are unable to fetch the statement for this loan type, click on the link below to retry.",
  },
  LETSGO_STATEMENT_NO_STATEMENT_CUSTOMER: {
    id: "LETSGO_STATEMENT_NO_STATEMENT_CUSTOMER",
    defaultMessage:
      "If this is urgent and you are unable to generate your statement, kindly reach out to <custom>customer care here</custom>",
  },
  LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT_HEADER: {
    id: "LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT_HEADER",
    defaultMessage: "Unable to fetch loan statement",
  },
  LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT_SUBHEADER: {
    id: "LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT_SUBHEADER",
    defaultMessage:
      "We are unable to fetch your last 3 months statement, kindly click on the link below to select your preferred duration and download to your device or email statement.",
  },
  LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT_CTA: {
    id: "LETSGO_STATEMENT_UNABLE_TO_FETCH_STATEMENT_CTA",
    defaultMessage: "Select preferred duration",
  },
  LETSGO_ACCOUNT: {
    id: "LETSGO_ACCOUNT",
    defaultMessage: "LetsGo account",
  },
  REQUEST_ACCOUNT: {
    id: "REQUEST_ACCOUNT",
    defaultMessage: "Request Account",
  },
  CONFIRM_ACCOUNT: {
    id: "CONFIRM_ACCOUNT",
    defaultMessage: "Confirm Details",
  },
  APPLICATION_REVIEW: {
    id: "APPLICATION_REVIEW",
    defaultMessage: "Application Review",
  },
  APPLICATION_REVIEW_MESSAGE: {
    id: "APPLICATION_REVIEW_MESSAGE",
    defaultMessage:
      "Your application for a LetsGo account is under review. You will be notified shortly about it’s status.",
  },
  APPLICATION_REVIEW_NOTIFICATION: {
    id: "APPLICATION_REVIEW_NOTIFICATION",
    defaultMessage:
      "Your will get an update on your registered mobile no. {mobileNo} within 24 hours",
  },
  COMMON_SECOND_NETWORK_ERROR: {
    id: "COMMON_SECOND_NETWORK_ERROR",
    defaultMessage:
      "Sorry we are experiencing network issues. Confirm your details once you are online.",
  },
  PROFILE_DETAILS_FETCH_FAIL: {
    id: "PROFILE_DETAILS_FETCH_FAIL",
    defaultMessage: "Sorry we are experiencing technical issues.",
  },
  TRACK_APPLICATION_DESCRIPTION: {
    id: "TRACK_APPLICATION_DESCRIPTION",
    defaultMessage:
      "We will notify you shortly about the status of your application for a LetsGo account",
  },
  TRACK_APPLICATION_FAILED_DESCRIPTION: {
    id: "TRACK_APPLICATION_FAILED_DESCRIPTION",
    defaultMessage: "Please contact customer care for clarification",
  },
  YOUR_APPLICATION_HAS: {
    id: "YOUR_APPLICATION_HAS",
    defaultMessage: "Your application has",
  },
  APPLICATION_FAILED_DESCRIPTION: {
    id: "APPLICATION_FAILED_DESCRIPTION",
    defaultMessage:
      "Your application for a LetsGo account did not go through, please contact the branch for further clarifications.",
  },
  APPLICATION_FAILED: {
    id: "APPLICATION_FAILED",
    defaultMessage: "Your application has failed",
  },
  VISIT_THE_BRANCH: {
    id: "VISIT_THE_BRANCH",
    defaultMessage: "Visit the branch",
  },
  FLEXISAVE_TO_EXTERNAL_BANK: {
    id: "FLEXISAVE_TO_EXTERNAL_BANK",
    defaultMessage: "Flexi Save to External Beneficiary",
  },
  FLEXISAVE_TO_INTERNAL_BANK: {
    id: "FLEXISAVE_TO_INTERNAL_BANK",
    defaultMessage: "Flexi Save to Internal Beneficiary",
  },
  PAY_NEW_BENEFICIARY_NA: {
    id: "PAY_NEW_BENEFICIARY_NA",
    defaultMessage: "Once Off Payment",
  },
  MINIMUM_DEPOSIT_ALLOW_MESSAGE: {
    id: "MINIMUM_DEPOSIT_ALLOW_MESSAGE",
    defaultMessage:
      "Please note that your first deposit needs to be a minimum of {amount}",
  },
  LETSGO_EMAIL: {
    id: "EMAIL",
    defaultMessage: "Email",
  },
  LETSGO_STATEMENT_OPTION_INTO_DEVICE: {
    id: "LETSGO_STATEMENT_OPTION_INTO_DEVICE",
    defaultMessage: "Into your device",
  },
  CAMERA_NOT_WORKING: {
    id: "CAMERA_NOT_WORKING",
    defaultMessage: "Your camera isn’t working",
  },
  RETAKE_VIDEO: {
    id: "RETAKE_VIDEO",
    defaultMessage: "Retake Video",
  },
  SUBMIT_VEDIO: {
    id: "SUBMIT_VEDIO",
    defaultMessage: "Submit Video",
  },
  TOO_LONG_TEXT: {
    id: "TOO_LONG_TEXT",
    defaultMessage: "Looks like you took too long",
  },
  SELFIE_UPLOAD_SUCCESFUL: {
    id: "SELFIE_UPLOAD_SUCCESFUL",
    defaultMessage: "Selfie verification successful",
  },
  CAMERA_DISCONNECTED: {
    id: "CAMERA_DISCONNECTED",
    defaultMessage: "It maybe disconnected or not functional",
  },
  EIGHT_SECOND_ALERT: {
    id: "EIGHT_SECOND_ALERT",
    defaultMessage:
      "Complete recording in less than 8 seconds and then click Finish recording",
  },
  RECORD_AGAIN: {
    id: "RECORD_AGAIN",
    defaultMessage: "Record again",
  },
  VIDEO_START_TITLE: {
    id: "VIDEO_START_TITLE",
    defaultMessage: "When you’re done press Finish recording",
  },
  VIDEO_END_TITLE: {
    id: "VIDEO_END_TITLE",
    defaultMessage: "Position your face in the oval",
  },
  BLINK_YOUR_EYES: {
    id: "BLINK_YOUR_EYES",
    defaultMessage: "Blink your eyes <custom> three times </custom>",
  },
  FINISH_RECORDING: {
    id: "FINISH_RECORDING",
    defaultMessage: "Finish recording",
  },
  START_RECORDING: {
    id: "START_RECORDING",
    defaultMessage: "Start Recording",
  },
  FILE_UPLOAD_TITLE: {
    id: "FILE_UPLOAD_TITLE",
    defaultMessage: "File upload failed",
  },
  VIDEO_UPLOAD_FAILED: {
    id: "VIDEO_UPLOAD_FAILED",
    defaultMessage:
      "Video upload failed. Please check your internet connection and try again.",
  },
  INSTANT_LOAN_UPLOAD_VIDEO_STEP_1: {
    id: "INSTANT_LOAN_UPLOAD_VIDEO_STEP_1",
    defaultMessage:
      "Position your <custom>face in the oval on your camera screen</custom>",
  },
  INSTANT_LOAN_UPLOAD_VIDEO_STEP_2: {
    id: "INSTANT_LOAN_UPLOAD_VIDEO_STEP_2",
    defaultMessage:
      "Record a short video of you <custom>blinking your eyes 3 times</custom>",
  },
  INSTANT_LOAN_UPLOAD_VIDEO_STEP_3: {
    id: "INSTANT_LOAN_UPLOAD_VIDEO_STEP_3",
    defaultMessage: "Finish recording in <custom>less than 8 seconds</custom>",
  },
  REC_VIDEO_HEADING: {
    id: "REC_VIDEO_HEADING",
    defaultMessage:
      "Here’s how you can record your selfie video and get verified in one go",
  },
  REC_VIDEO_TITLE: {
    id: "REC_VIDEO_TITLE",
    defaultMessage: "Let’s make sure nobody’s impersonating you",
  },
  REC_SELFIE_VIDEO_TEXT: {
    id: "REC_VIDEO_TEXT",
    defaultMessage: "Record a selfie video",
  },
  REC_AND_UPLOAD_VIDEO_SELFIE: {
    id: "REC_AND_UPLOAD_VIDEO_SELFIE",
    defaultMessage: "Record & upload your selfie video",
  },
  VIDEO_RECORDED_TEXT: {
    id: "VIDEO_RECORDED_TEXT",
    defaultMessage: "Thank you! Your video has been recorded",
  },
  CHECK_YOUR_PICTURE_AND_GUIDELINES: {
    id: "CHECK_YOUR_PICTURE_AND_GUIDELINES",
    defaultMessage:
      "Check your picture, and follow the guidelines in the right side panel.",
  },
  GET_PAYROLL_LOAN: {
    id: "GET_PAYROLL_LOAN",
    defaultMessage: "Get a {name} loan",
  },
  GET_LOAN_SUB_TEXT: {
    id: "GET_LOAN_SUB_TEXT",
    defaultMessage:
      "Your current employer details indicate that you are eligible for a {name} loan",
  },
  VISI_BRANCH_TEXT: {
    id: "VISIT_BRANCH_TEXT",
    defaultMessage:
      "If you need to change employer details, please visit your nearest branch",
  },
  CHECKOUT_LOAN: {
    id: "CHECKOUT_LOAN",
    defaultMessage:
      " Click on {name} loan if you wish to top up your current loan amount.",
  },
  DOCUMENT_HAVE_NO_KEY: {
    id: "DOCUMENT_HAVE_NO_KEY",
    defaultMessage: "This document can't be viewed",
  },
  PREFERRED_LETSHEGO_BRANCH: {
    id: "PREFERRED_LETSHEGO_BRANCH",
    defaultMessage: "Preferred Letshego Branch",
  },
  PREFERRED_LETSHEGO_BRANCH_HELPER_TEXT: {
    id: "PREFERRED_LETSHEGO_BRANCH_HELPER_TEXT",
    defaultMessage: "Select which branch you will like to get assitance",
  },
  FLEXI_TOTAL_PAYABLE_AMOUNT_TEXT: {
    id: "FLEXI_TOTAL_PAYABLE_AMOUNT_TEXT",
    defaultMessage:
      "Please note that your first withdrawal for the month is free, thereafter {currency} {fee} fee is charged",
  },
  TOTAL_PAYABLE_AMOUNT_ELEVY_TEXT: {
    id: "TOTAL_PAYABLE_AMOUNT_ELEVY_TEXT",
    defaultMessage:
      "Total Payable amount of {currency} {amount} will include an E Levy of {currency} {eLevyFee}",
  },
  NEXT_INSTALMENT: {
    id: "NEXT_INSTALMENT",
    defaultMessage: "Next Installment",
  },
  TRANSACTION_ELEVY_MESSAGE_SUCCESS: {
    id: "TRANSACTION_ELEVY_MESSAGE_SUCCESS",
    defaultMessage:
      "Note: A small Elevy fee of {currencySymbol} {elevyFee} has been charged",
  },
  TRANSACTION_AND_ELEVY_MESSAGE_SUCCESS: {
    id: "TRANSACTION_AND_ELEVY_MESSAGE_SUCCESS",
    defaultMessage:
      "Note: A small transaction fee of {currencySymbol} {fees} and Elevy of {currencySymbol} {elevyFee} has been charged",
  },
  LOAN_PROFILE_DEPARTMENT_CODE: {
    id: "LOAN_PROFILE_DEPARTMENT_CODE",
    defaultMessage: "Department code",
  },
  SPOUSE_ADDRESS: {
    id: "SPOUSE_ADDRESS",
    defaultMessage: "Spouse address",
  },
  SAVINGS_UPLOAD_TEXT_OCR: {
    id: "SAVINGS_UPLOAD_TEXT_OCR",
    defaultMessage:
      "Your file should be in .jpeg, .jpg, .png formats with less than 10 MB size. \nWe recommend an image size above 1000 x 1000 pixels. This will process your application faster.",
  },
  SAVINGS_DOCUMENT_UPLOAD_ERROR_TEXT_OCR: {
    id: "SAVINGS_DOCUMENT_UPLOAD_ERROR_TEXT_OCR",
    defaultMessage: "Image uploaded is {width} x {height} pixels.",
  },
  COOL_OFF_PERIOD: {
    id: "COOL_OFF_PERIOD",
    defaultMessage:
      "Note: You have the option to cancel your loan without paying any application fee within 2 hours of loan disbursement.",
  },
  TOTAL_REPAYMENT: {
    id: "TOTAL_REPAYMENT",
    defaultMessage: "Total Repayment",
  },
  GENERATE_STATEMENT_CUSTOM_DATE_ERROR: {
    id: "GENERATE_STATEMENT_CUSTOM_DATE_ERROR",
    defaultMessage:
      "Please select a date range of a maximum of 3 months. Visit the branch for statements with date range greater than 3 months.",
  },
  SEND_FUND_TO_OWN_WALLET: {
    id: "SEND_FUND_TO_OWN_WALLET",
    defaultMessage: "Send to my wallet",
  },
  INVALID_RANGE: {
    id: "INVALID_RANGE",
    defaultMessage: "Please select valid date range.",
  },
  COMMON_RATE_OUR: {
    id: "COMMON_RATE_OUR",
    defaultMessage: "Rate our",
  },
  COMMON_SERVICE: {
    id: "COMMON_SERVICE",
    defaultMessage: "Service",
  },
  LETSGO_PRODUCT_NOT_SUPPORTED_ON_DM: {
    id: "LETSGO_PRODUCT_NOT_SUPPORTED_ON_DM",
    defaultMessage:
      "This product is currently not accessible on the Digital Mall",
  },
  REPAYMENT_STATUS: {
    id: "REPAYMENT_STATUS",
    defaultMessage: "Repayment status",
  },
  AMOUNT_TO_BORROW_DISCLAIMER_TEXT: {
    id: "AMOUNT_TO_BORROW_DISCLAIMER_TEXT",
    defaultMessage:
      "*This loan amount is indicative and the final amount will vary.",
  },
  LETSGO_REQUEST_REFUND_CTA_HEADING: {
    id: "LETSGO_REQUEST_REFUND_CTA_HEADING",
    defaultMessage: "Check for refund",
  },
  LETSGO_REQUEST_REFUND_CTA_SUBHEADING: {
    id: "LETSGO_REQUEST_REFUND_CTA_SUBHEADING",
    defaultMessage:
      "Check for a cash reversal if your loan account has been overcharged.",
  },
  LETSGO_REQUEST_REFUND_LANDING_TITLE_ONE: {
    id: "LETSGO_REQUEST_REFUND_LANDING_TITLE_ONE",
    defaultMessage: "Check if you",
  },
  LETSGO_REQUEST_REFUND_LANDING_TITLE_TWO: {
    id: "LETSGO_REQUEST_REFUND_LANDING_TITLE_TWO",
    defaultMessage: "Qualify for a refund",
  },
  LETSGO_REQUEST_REFUND_LANDING_INFO_TEXT: {
    id: "LETSGO_REQUEST_REFUND_LANDING_INFO_TEXT",
    defaultMessage:
      "You are about to automate your refund process directly from the digital mall by selecting your prefered account.",
  },
  LETSGO_REFUND_ELIGIBLE_HEADER: {
    id: "LETSGO_REFUND_ELIGIBLE_HEADER",
    defaultMessage: "Who is eligible to check for a refund",
  },
  LETSGO_REFUND_ELIGIBLE_OPTION_ONE: {
    id: "LETSGO_REFUND_ELIGIBLE_OPTION_ONE",
    defaultMessage:
      "Any customer with an overcharged account over the duration of a loan period.",
  },
  LETSGO_REFUND_ELIGIBLE_OPTION_TWO: {
    id: "LETSGO_REFUND_ELIGIBLE_OPTION_TWO",
    defaultMessage:
      "Customers with a running or closed loan who’s account has been overcharged over the duration of the loan period.",
  },
  LETSGO_REFUND_ELIGIBLE_OPTION_THREE: {
    id: "LETSGO_REFUND_ELIGIBLE_OPTION_THREE",
    defaultMessage:
      "Customers who overpaid for a transaction or service rendered.",
  },
  LETSGO_REFUND_STATUS_HEADER: {
    id: "LETSGO_REFUND_STATUS_HEADER",
    defaultMessage: "Refund status",
  },
  LETSGO_REFUND_AMOUNT: {
    id: "LETSGO_REFUND_AMOUNT",
    defaultMessage: "Refund amount",
  },
  LETSGO_REFUND_INFO_MESSAGE: {
    id: "LETSGO_REFUND_INFO_MESSAGE",
    defaultMessage:
      "If you have any questions you can contact customer service on {contactLinks}",
  },
  LETSGO_ERROR_NO_ACTIVE_REFUND: {
    id: "LETSGO_ERROR_NO_ACTIVE_REFUND",
    defaultMessage: "You do not have any active refunds",
  },
  LETSGO_CHECK_FOR_REFUND_CTA: {
    id: "LETSGO_CHECK_FOR_REFUND_CTA",
    defaultMessage: "Continue",
  },
  LETSGO_REFUND_STATUS_NO_REFUND: {
    id: "LETSGO_REFUND_STATUS_NO_REFUND",
    defaultMessage: "You don't have a refund",
  },
  LETSGO_REFUND_STATUS_REFUND_UNDER_REVIEW: {
    id: "LETSGO_REFUND_STATUS_REFUND_UNDER_REVIEW",
    defaultMessage: "Refund Under Review",
  },
  LETSGO_REFUND_STATUS_REFUND_SUCCESSFUL: {
    id: "LETSGO_REFUND_STATUS_REFUND_SUCCESSFUL",
    defaultMessage: "Refund processed successfully",
  },
  BANK_TO_BLUE_WALLET: {
    id: "BANK_TO_BLUE_WALLET",
    defaultMessage: "Bank to Paypulse Wallet",
  },
  CONTINUE_APPLY_LABEL: {
    id: "CONTINUE_APPLY_LABEL",
    defaultMessage: "Continue <custom>To Apply</custom>",
  },
  IL_RESUME_YOUR_APPLICATION: {
    id: "IL_RESUME_YOUR_APPLICATION",
    defaultMessage:
      "Resume your pending application. It will just take few minutes to finish your application.",
  },
  MINIMUM_ALLOWED_AMOUNT: {
    id: "MINIMUM_ALLOWED_AMOUNT",
    defaultMessage: "Minimum allowed amount is {currencySymbol} {amount}",
  },
  GO_TO_TRACKER: {
    id: "GO_TO_TRACKER",
    defaultMessage: "Go to tracker",
  },
  CERTIFICATE_OF_INCORPORAION_LABEL: {
    id: "CERTIFICATE_OF_INCORPORAION_LABEL",
    defaultMessage: "Upload your certificate of incorporation",
  },
  CUSTOMER_NAME: {
    id: "CUSTOMER_NAME",
    defaultMessage: "Customer name",
  },
  UPLOAD_PICTURE_LABEL: {
    id: "UPLOAD_PICTURE_LABEL",
    defaultMessage: "Upload picture",
  },
  UPLOAD_PRODUCT_PICTURE_LABEL: {
    id: "UPLOAD_PRODUCT_PICTURE_LABEL",
    defaultMessage: "Upload product picture",
  },
  UPLOAD_SERVICE_PICTURE_LABEL: {
    id: "UPLOAD_SERVICE_PICTURE_LABEL",
    defaultMessage: "Upload picture of this service",
  },
  UPLOAD_SERVICE_VIDEO_LABEL: {
    id: "UPLOAD_SERVICE_VIDEO_LABEL",
    defaultMessage: "Upload the videos of this service",
  },
  UPLOAD_SERVICE_VIDEO_OPTIONAL: {
    id: "UPLOAD_SERVICE_VIDEO_OPTIONAL",
    defaultMessage: "Upload video (optional)",
  },
  MARKET_PLACE_ID_DESC: {
    id: "MARKET_PLACE_ID_DESC",
    defaultMessage: "You can upload a maximum of 2 pictures",
  },
  MARKET_PLACE_BUSINESS_REG_CERT_DESC: {
    id: "MARKET_PLACE_BUSINESS_REG_CERT_DESC",
    defaultMessage: "You can upload a maximum of 3 pdf or 10 images",
  },
  MARKET_PLACE_BUSINESS_REG_CERT_LABEL: {
    id: "MARKET_PLACE_BUSINESS_REG_CERT_LABEL",
    defaultMessage: "Upload business registration certificate",
  },
  COMPLETE_YOUR_LABEL: {
    id: "COMPLETE_YOUR_LABEL",
    defaultMessage: "Complete your",
  },
  UNIT_ITEM_SIZE: {
    id: "UNIT_ITEM_SIZE",
    defaultMessage: "Unit/Item size",
  },
  MARKETPLACE_BOOST_YOUR_BUSINESS_LABEL: {
    id: "MARKETPLACE_BOOST_YOUR_BUSINESS_LABEL",
    defaultMessage: "Boost your business on the Letsgo Market",
  },
  MARKET_PLACE_UPLOAD_PICTURE_DESC: {
    id: "MARKET_PLACE_UPLOAD_PICTURE_DESC",
    defaultMessage: "You can upload a maximum of 5 pictures",
  },
  MARKET_PLACE_UPLOAD_VIDEO_DESC: {
    id: "MARKET_PLACE_UPLOAD_VIDEO_DESC",
    defaultMessage: "You can upload a maximum of 5 videos",
  },
  MARKET_PLACE_DEMO_TEST_RATING_DESCRIPTION: {
    id: "MARKET_PLACE_DEMO_TEST_RATING_DESCRIPTION",
    defaultMessage:
      "We are revolutionalising how Africans discover and buy property through the LetGos market place",
  },
  MARKET_PLACE_DEMO_TEST_RATING_TITLE: {
    id: "MARKET_PLACE_DEMO_TEST_RATING_TITLE",
    defaultMessage: "KYC Corp",
  },
  MARKET_PLACE_MERCHANT_GOALS_1: {
    id: "MARKET_PLACE_MERCHANT_GOALS_1",
    defaultMessage: "Attract, convert and retain customers",
  },
  MARKET_PLACE_SELLERS_REVIEWS_TITLE: {
    id: "MARKETP_PLACE_SELLERS_REVIEWS_TITLE",
    defaultMessage: "Success stories from LetsGo Market place sellers",
  },
  MARKET_PLACE_MERCHANT_GOALS_2: {
    id: "MARKET_PLACE_MERCHANT_GOALS_2",
    defaultMessage: "Flexible payment options",
  },
  MARKET_PLACE_MERCHANT_GOALS_3: {
    id: "MARKET_PLACE_MERCHANT_GOALS_3",
    defaultMessage: "Performance-driven marketing",
  },
  MARKET_PLACE_ID_TYPE: {
    id: "MARKET_PLACE_ID_TYPE",
    defaultMessage: "ID Type",
  },
  PHOTO_UPLOAD_LIST_ITEM_1: {
    id: "PHOTO_UPLOAD_LIST_ITEM_1",
    defaultMessage: "Take the photo in a well-lit room.",
  },
  PHOTO_UPLOAD_LIST_ITEM_2: {
    id: "PHOTO_UPLOAD_LIST_ITEM_2",
    defaultMessage:
      "The photo should be placed on a white background or a clean and clear background.",
  },
  PHOTO_UPLOAD_LIST_ITEM_3: {
    id: "PHOTO_UPLOAD_LIST_ITEM_3",
    defaultMessage: "The photo should be professionally taken.",
  },
  PHOTO_UPLOAD_LIST_ITEM_4: {
    id: "PHOTO_UPLOAD_LIST_ITEM_4",
    defaultMessage: "The photo should be in a JPEG and PNG format.",
  },
  MARKET_PLACE_SERVICE_TYPE: {
    id: "MARKET_PLACE_SERVICE_TYPE",
    defaultMessage: "Service type",
  },
  MARKET_PLACE_PRODUCT_TYPE: {
    id: "MARKET_PLACE_PRODUCT_TYPE",
    defaultMessage: "Product type",
  },
  MARKET_PLACE_PRODUCT_NAME: {
    id: "MARKET_PLACE_PRODUCT_NAME",
    defaultMessage: "Product name",
  },
  MARKET_PLACE_SERVICE_NAME: {
    id: "MARKET_PLACE_SERVICE_NAME",
    defaultMessage: "Service name",
  },
  MARKET_PLACE_PRODUCT_DESCRIPTION: {
    id: "MARKET_PLACE_PRODUCT_DESCRIPTION",
    defaultMessage: "Product description",
  },
  MARKET_PLACE_PRODUCT_PRICE: {
    id: "MARKET_PLACE_PRODUCT_PRICE",
    defaultMessage: "Product price",
  },
  MARKET_PLACE_PROPERTY_SIZE: {
    id: "MARKET_PLACE_PROPERTY_SIZE",
    defaultMessage: "Property size",
  },
  MARKET_PLACE_PROPERTY_SIZE_DESC: {
    id: "MARKET_PLACE_PROPERTY_SIZE_DESC",
    defaultMessage: "Please enter the size of the unit or item",
  },
  NO_OF_BATHROOMS: {
    id: "NO_OF_BATHROOMS",
    defaultMessage: "No. of bathrooms",
  },
  MARKET_PLACE_SERVICE_DESCRIPTION: {
    id: "MARKET_PLACE_SERVICE_DESCRIPTION",
    defaultMessage: "Service description",
  },
  MARKET_PLACE_LOCATION: {
    id: "MARKET_PLACE_LOCATION",
    defaultMessage: "Location",
  },
  MARKET_PLACE_LOCATION_LABEL: {
    id: "MARKET_PLACE_LOCATION_LABEL",
    defaultMessage: "Please enter the location this service is located",
  },
  MARKET_PLACE_SERVICE_PRICE: {
    id: "MARKET_PLACE_SERVICE_PRICE",
    defaultMessage: "Service price",
  },
  MARKET_PLACE_LISTING_LABEL: {
    id: "MARKET_PLACE_LISTING_LABEL",
    defaultMessage: "Your listing",
  },
  MARKET_PLACE_OFFERING: {
    id: "MARKET_PLACE_OFFERING",
    defaultMessage: "What is your offering",
  },
  MARKET_PLACE_PICTURE_UPLOAD_TITLE: {
    id: "MARKET_PLACE_PICTURE_UPLOAD_TITLE",
    defaultMessage: "Photo upload details",
  },
  MARKET_PLACE_PICTURE_UPLOAD: {
    id: "MARKET_PLACE_PICTURE_UPLOAD",
    defaultMessage:
      "Kindly find below the details that are required to upload images.",
  },
  DASHBOARD: {
    id: "DASHBOARD",
    defaultMessage: "Dashboard",
  },
  OFFERS: {
    id: "OFFERS",
    defaultMessage: "Offers",
  },
  QUOTES: {
    id: "QUOTES",
    defaultMessage: "Quotes",
  },
  WELCOME_TO_THE_LABEL: {
    id: "WELCOME_TO_THE_LABEL",
    defaultMessage: "Welcome to the",
  },
  MERCHANT_PORTAL_LABEL: {
    id: "MERCHANT_PORTAL_LABEL",
    defaultMessage: "Merchant Portal",
  },
  MERCHANT_SUBTITLE: {
    id: "MERCHANT_SUBTITLE",
    defaultMessage: "Reach active customers globally today.",
  },
  MANAGE_SERVICES_SUSBTITLE: {
    id: "MANAGE_SERVICES_SUSBTITLE",
    defaultMessage: "Reach active buyers globally through the LetsGo Mall",
  },
  MARKETPLACE_CUSTOMER_LABEL: {
    id: "MARKETPLACE_CUSTOMER_LABEL",
    defaultMessage: "I am a customer",
  },
  MARKETPLACE_MERCHANT_LABEL: {
    id: "MARKETPLACE_MERCHANT_LABEL",
    defaultMessage: "I am a merchant",
  },
  MARKETPLACE_CUSTOMER_HELPER_TEXT_LABEL: {
    id: "MARKETPLACE_CUSTOMER_HELPER_TEXT_LABEL",
    defaultMessage:
      "You can view and purchase items uploaded by merchants on the market place.",
  },
  MARKETPLACE_MERCHANT_HELPER_TEXT_LABEL: {
    id: "MARKETPLACE_MERCHANT_HELPER_TEXT_LABEL",
    defaultMessage:
      "You can upload your products or services for customer purchase on the market place.",
  },
  MARKET_PLACE_KYC_SUB_TEXT: {
    id: "MARKET_PLACE_KYC_SUB_TEXT",
    defaultMessage:
      "Please update your profile to start operating on the market place!",
  },
  SERVICES_PRODUCT_OFFER: {
    id: "SERVICES_PRODUCT_OFFER",
    defaultMessage: "Services/products offer",
  },
  SELECT_YOUR_TEXT: {
    id: "SELECT_YOUR_TEXT",
    defaultMessage: "Select your",
  },
  BUSINESS_TYPE_TEXT: {
    id: "BUSINESS_TYPE_TEXT",
    defaultMessage: "Business type",
  },
  UPDATE_PROFILE_TEXT: {
    id: "UPDATE_PROFILE_TEXT",
    defaultMessage: "Update profile",
  },
  SOLE_PROPRIETOR_TITLE: {
    id: "SOLE_PROPRIETOR_TITLE",
    defaultMessage: "Sole Proprietor",
  },
  LIMITED_LIABILITY_TITLE: {
    id: "LIMITED_LIABILITY_TITLE",
    defaultMessage: "Limited company",
  },
  PARTNERSHIP_TITLE: {
    id: "PARTNERSHIP_TITLE",
    defaultMessage: "Partnership company",
  },
  SOLE_PROPRIETOR_SUB_TEXT: {
    id: "SOLE_PROPRIETOR_SUB_TEXT",
    defaultMessage:
      "Kindly find below the details required before you can proceed with updating your profile",
  },
  SOLE_PROPRIETOR_DESC_TEXT_1: {
    id: "SOLE_PROPRIETOR_DESC_TEXT_1",
    defaultMessage: "Certificate of Incoporation",
  },
  SOLE_PROPRIETOR_DESC_TEXT_2: {
    id: "SOLE_PROPRIETOR_DESC_TEXT_2",
    defaultMessage: "Trading license/Certificate",
  },
  TRADING_LICENSE: {
    id: "TRADING_LICENSE",
    defaultMessage: "Trading license",
  },
  TRADING_LICENSE_UPLOAD_LABEL: {
    id: "TRADING_LICENSE_UPLOAD_LABEL",
    defaultMessage: "Upload the trading license",
  },
  SOLE_PROPRIETOR_DESC_TEXT_3: {
    id: "SOLE_PROPRIETOR_DESC_TEXT_3",
    defaultMessage: "IDs of Directors",
  },
  SOLE_PROPRIETOR_DESC_TEXT_4: {
    id: "SOLE_PROPRIETOR_DESC_TEXT_4",
    defaultMessage: "Business Registration certificate",
  },
  SOLE_PROPRIETOR_DESC_TEXT_5: {
    id: "SOLE_PROPRIETOR_DESC_TEXT_5",
    defaultMessage: "Board of Resolution",
  },
  COMPANY_TYPE: {
    id: "COMPANY_TYPE",
    defaultMessage: "Company type",
  },
  NUMBER_OF_YEARS_OF_OPRATION: {
    id: "NUMBER_OF_YEARS_OF_OPRATION",
    defaultMessage: "Number of years of Operation",
  },
  TAX_IDENTIFICATION_NUMBER: {
    id: "TAX_IDENTIFICATION_NUMBER",
    defaultMessage: "Tax Identification Number (TIN)",
  },
  BUSINESS_DESCRIPTION: {
    id: "BUSINESS_DESCRIPTION",
    defaultMessage: "Business description",
  },
  BUSINESS_DESCRIPTION_DETAILS: {
    id: "BUSINESS_DESCRIPTION_DETAILS",
    defaultMessage:
      "Please type a brief description of your company, not more that {letters} letters.",
  },
  PROCEED_AND_SEND: {
    id: "PROCEED_AND_SEND",
    defaultMessage: "Proceed and send directors consent",
  },
  UPLOAD_COMPANY_LOGO_OPTIONAL: {
    id: "UPLOAD_COMPANY_LOGO_OPTIONAL",
    defaultMessage: "Upload company logo (optional)",
  },
  UPLOAD_PICTURE_COMPANY_LOGO: {
    id: "UPLOAD_PICTURE_COMPANY_LOGO",
    defaultMessage: "Upload pictures of company logo",
  },
  UPLOAD_COMPANY_LOGO_DESC: {
    id: "UPLOAD_COMPANY_LOGO_DESC",
    defaultMessage: "Please upload a logo of your company",
  },
  DIRECTOR_CONSENT_TITLE: {
    id: "DIRECTOR_CONSENT_TITLE",
    defaultMessage: "Director consent",
  },
  BUSINESS_CONTACT_NUMBER: {
    id: "BUSINESS_CONTACT_NUMBER",
    defaultMessage: "Business contact number",
  },
  PROFILE_TYPE_LABEL: {
    id: "PROFILE_TYPE_LABEL",
    defaultMessage: "Profile type",
  },
  MARTKEPLACE_SUB_HEADING_LABEL: {
    id: "MARTKEPLACE_SUB_HEADING_LABEL",
    defaultMessage:
      "Select your preferred market place profile and explore amazing offers.",
  },
  DIRECTOR_NAME: {
    id: "DIRECTOR_NAME",
    defaultMessage: "Director name",
  },
  DIRECTOR_NUMBER: {
    id: "DIRECTOR_NUMBER",
    defaultMessage: "Director number",
  },
  ADD_DIRECTOR_BTN: {
    id: "ADD_DIRECTOR_BTN",
    defaultMessage: "Add another director",
  },
  REMOVE_DIRECTOR_BTN: {
    id: "REMOVE_DIRECTOR_BTN",
    defaultMessage: "Remove director",
  },
  ONE_FIVE_YEARS: {
    id: "ONE_FIVE_YEARS",
    defaultMessage: "1-5 years",
  },
  FIVE_TEN_YEARS: {
    id: "FIVE_TEN_YEARS",
    defaultMessage: "5-10 years",
  },
  TEN_TWENTY_YEARS: {
    id: "TEN_TWENTY_YEARS",
    defaultMessage: "10-20 years",
  },
  TWENTY_PLUS_YEARS: {
    id: "TWENTY_PLUS_YEARS",
    defaultMessage: "20+ years",
  },
  UPDATE_INFORMATION_SUB_TITLE: {
    id: "UPDATE_INFORMATION_SUB_TITLE",
    defaultMessage: "Please update your information",
  },
  WELCOME_NOTES_DIRECTOR: {
    id: "WELCOME_NOTES_DIRECTOR",
    defaultMessage: "To the mall Leo",
  },
  DIRECTOR_WELCOME_DESCRIPTION: {
    id: "DIRECTOR_WELCOME_DESCRIPTION",
    defaultMessage:
      "You’ve been assigned as a director for {name} and we will need a few more details from you to complete the process",
  },
  VERIFY_YOURSELF_NOW: {
    id: "VERIFY_YOURSELF_NOW",
    defaultMessage: "Verify yourself now",
  },
  DOCUMENTS_UPLOAD: {
    id: "DOCUMENTS_UPLOAD",
    defaultMessage: "Documents upload",
  },
  DOCUMENTS_LABEL: {
    id: "DOCUMENTS_LABEL",
    defaultMessage: "Documents",
  },
  DOCUMENTS_UPLOAD_INFO: {
    id: "DOCUMENTS_UPLOAD_INFO",
    defaultMessage: "You can upload maximum of {image} pictures",
  },
  UPLOAD_ID_INFO: {
    id: "UPLOAD_ID_INFO",
    defaultMessage: "Upload ID",
  },
  DIRECTOR_CONSENT_LINK_HAS_BEEN_SENT: {
    id: "DIRECTOR_CONSENT_LINK_HAS_BEEN_SENT",
    defaultMessage:
      "Link has been sent to the director and you will be notified once completed",
  },
  PREVIEW_BUSINESS_DETAILS: {
    id: "PREVIEW_BUSINESS_DETAILS",
    defaultMessage: "Preview business details",
  },
  DIRECTOR_LINK: {
    id: "DIRECTOR_LINK",
    defaultMessage: "Director link",
  },
  FORM_COMPLETION: {
    id: "FORM_COMPLETION",
    defaultMessage: "Form completion",
  },
  ACTIVITIES: {
    id: "ACTIVITIES",
    defaultMessage: "Activities",
  },
  SENT: {
    id: "SENT",
    defaultMessage: "Sent",
  },
  INFORMATION_SUBMITTED: {
    id: "INFORMATION_SUBMITTED",
    defaultMessage: "Information submitted",
  },
  SUCCESSFULLY_SUBMITTED: {
    id: "SUCCESSFULLY_SUBMITTED",
    defaultMessage:
      "You have successfully submitted your details for your business application",
  },
  PREVIEW_PROFILE: {
    id: "PREVIEW_PROFILE",
    defaultMessage: "Preview profile",
  },
  MARKET_PLACE_PROFILE_TITLE: {
    id: "MARKET_PLACE_PROFILE_TITLE",
    defaultMessage: "Atenu Developers",
  },
  MARKET_PLACE_PROFILE_SUB_TITLE: {
    id: "MARKET_PLACE_PROFILE_SUB_TITLE",
    defaultMessage:
      "We are a company with the mindset to make housing affordable, easy to purchase with less hassle.",
  },
  SERVICES_TEXT: {
    id: "SERVICES_TEXT",
    defaultMessage: "Services",
  },
  STORE_PRODUCT_TITLE: {
    id: "STORE_PRODUCT_TITLE",
    defaultMessage: "1 bedroom, 1 bathroom extendable",
  },
  STORE_PRODUCT_DESC: {
    id: "STORE_PRODUCT_DESC",
    defaultMessage:
      "Basic option: Superbrick walls, plaster, IBR roofing, wood trusses, rhino board ceilings, light fittings, floor tiles, show.",
  },
  READ_MORE: {
    id: "READ_MORE",
    defaultMessage: "Read more",
  },
  EDIT_PROFILE: {
    id: "EDIT_PROFILE",
    defaultMessage: "Edit profile",
  },
  MARKET_PLACE_DOCUMENT_REQUIRED_ERROR: {
    id: "MARKET_PLACE_DOCUMENT_REQUIRED_ERROR",
    defaultMessage:
      "Please upload minimum {image} Upload ID with Selfie documents.",
  },
  UPLOAD_SERVICE_IMAGE_TEXT: {
    id: "UPLOAD_SERVICE_IMAGE_TEXT",
    defaultMessage: "Upload service images",
  },
  SERVICE_IMAGE_MAX_UPLOADED: {
    id: "SERVICE_IMAGE_MAX_UPLOADED",
    defaultMessage: "You have uploaded the maximum number of images required.",
  },
  SERVICE_REMOVE_TITLE: {
    id: "SERVICE_REMOVE_TITLE",
    defaultMessage: "Remove service",
  },
  SERVICE_REMOVE_CONFIRM_TEXT: {
    id: "SERVICE_REMOVE_CONFIRM_TEXT",
    defaultMessage: "Are you sure you want to remove this service?",
  },
  SERVICE_REMOVE_CTA: {
    id: "SERVICE_REMOVE_CTA",
    defaultMessage: "Yes, Remove",
  },
  MARKET_PLACE_PRODUCT_TEXT: {
    id: "MARKET_PLACE_PRODUCT_TEXT",
    defaultMessage: "Product",
  },
  MARKET_PLACE_SERVICE_TEXT: {
    id: "MARKET_PLACE_SERVICE_TEXT",
    defaultMessage: "Service",
  },
  MARKET_PLACE_TYPE_TEXT: {
    id: "MARKET_PLACE_TYPE_TEXT",
    defaultMessage: "{type} type",
  },
  MARKET_PLACE_NAME_TEXT: {
    id: "MARKET_PLACE_NAME_TEXT",
    defaultMessage: "{type} name",
  },
  MARKET_PLACE_DESCRIPTION_TEXT: {
    id: "MARKET_PLACE_DESCRIPTION_TEXT",
    defaultMessage: "{type} description",
  },
  MARKET_PLACE_PRICE_TEXT: {
    id: "MARKET_PLACE_PRICE_TEXT",
    defaultMessage: "{type} price",
  },
  DOCUMENTS_VIDEO_UPLOAD_INFO: {
    id: "DOCUMENTS_VIDEO_UPLOAD_INFO",
    defaultMessage: "You can upload maximum of {image} videos",
  },
  SERVICE_VIDEO_MAX_UPLOADED: {
    id: "SERVICE_VIDEO_MAX_UPLOADED",
    defaultMessage: "You have uploaded the maximum number of vedios required.",
  },
  MARKET_PLACE_UPLOAD_IMAGE_LABEL: {
    id: "MARKET_PLACE_UPLOAD_IMAGE_LABEL",
    defaultMessage: "Upload the picture of this {label}",
  },
  MARKET_PLACE_UPLOAD_VIDEO_LABEL: {
    id: "UPLOAD_SERVICE_VIDEO_LABEL",
    defaultMessage: "Upload the videos of this {label}",
  },
  MARKET_PLACE_UPLOAD_LABEL: {
    id: "MARKET_PLACE_UPLOAD_LABEL",
    defaultMessage: "Upload {label} picture",
  },
  VIEW_COMPANY_PROFILE: {
    id: "VIEW_COMPANY_PROFILE",
    defaultMessage: "View company profile",
  },
  VERIFY_YOUR_PROFILE_FIRST: {
    id: "VERIFY_YOUR_PROFILE_FIRST",
    defaultMessage: "Verify your profile to view products &services",
  },
  BUSINESS_REPRESENTATIVE: {
    id: "BUSINESS_REPRESENTATIVE",
    defaultMessage: "Business Representative",
  },
  DECLINE_VERIFICATION: {
    id: "DECLINE_VERIFICATION",
    defaultMessage: "Decline Verification",
  },
  ARE_YOU_SURE_TO_REJECT_DIRECTOR_VERIFICATION: {
    id: "ARE_YOU_SURE_TO_REJECT_DIRECTOR_VERIFICATION",
    defaultMessage:
      "You will not be added as a director for this company. Are you sure you want to reject?",
  },
  YES_DECLINE_VERIFICATION: {
    id: "YES_DECLINE_VERIFICATION",
    defaultMessage: "Yes, Decline verification",
  },
  NO_CONTINUE_TO_VERIFY: {
    id: "NO_CONTINUE_TO_VERIFY",
    defaultMessage: "No, continue to verify",
  },
  REJECTION: {
    id: "REJECTION",
    defaultMessage: "Rejection",
  },
  NOTED: {
    id: "NOTED",
    defaultMessage: "noted",
  },
  WE_REGISTERED_YOUR_REJECTION: {
    id: "WE_REGISTERED_YOUR_REJECTION",
    defaultMessage:
      "We registered your rejection and will convey it to sender of the request.",
  },
  MARKETPLACE_SUCCESSFULLY_INITIATED: {
    id: "MARKETPLACE_SUCCESSFULLY_INITIATED",
    defaultMessage:
      "You have successfully registered on the LetsGo Market place.",
  },
  YOU_WILL_GET_AN_UPDATE_ON_YOUR_REGISTERED_PHONE_NUMBER: {
    id: "YOU_WILL_GET_AN_UPDATE_ON_YOUR_REGISTERED_PHONE_NUMBER",
    defaultMessage:
      "Your will get an update on your registered mobile number as soon as possible",
  },
  APPLICATION_APPROVE: {
    id: "APPLICATION_APPROVE",
    defaultMessage: "Application Approved",
  },
  ACTIONS_REQUIRED_BY_YOU: {
    id: "ACTIONS_REQUIRED_BY_YOU",
    defaultMessage: "Actions required from you",
  },
  OUR_TEAM_HAS_RECEIVED_YOUR_DETAILS: {
    id: "OUR_TEAM_HAS_RECEIVED_YOUR_DETAILS",
    defaultMessage:
      "Our team has received your details and is reviewing your application",
  },
  DIRECTOR_CONSENT_IS_SENT_WE_WAIT_FOR_THEIR_APPROVAL: {
    id: "DIRECTOR_CONSENT_IS_SENT_WE_WAIT_FOR_THEIR_APPROVAL",
    defaultMessage:
      "Director consent has been sent and you are waiting their approval to proceed",
  },
  FAILED_DIRECTOR_CONSENT: {
    id: "FAILED_DIRECTOR_CONSENT",
    defaultMessage: "failed Director consent",
  },
  PLEASE_ADDRESS_THE_ACTIONS_REQUIRED_BY_YOU: {
    id: "PLEASE_ADDRESS_THE_ACTIONS_REQUIRED_BY_YOU",
    defaultMessage:
      "Please address the actions required from you for your application to be approved",
  },
  DIRECTOR_CONSENT_STATUS: {
    id: "DIRECTOR_CONSENT_STATUS",
    defaultMessage: "Director consent status",
  },
  DIRECTOR_VERIFICATION_HAS_FAILED: {
    id: "DIRECTOR_VERIFICATION_HAS_FAILED",
    defaultMessage: "Director verification has failed!",
  },
  DIRECTOR_VERIFICATION_HAS_FAILED_DESCRIPTION: {
    id: "DIRECTOR_VERIFICATION_HAS_FAILED_DESCRIPTION",
    defaultMessage:
      "Director verification has failed due to the director rejecting to consent, please try again",
  },
  MARKETPLACE_MERCHANT_Q: {
    id: "MARKETPLACE_MERCHANT_Q",
    defaultMessage: "Are you a merchant?",
  },
  MARKETPLACE_MERCHANT_DESC: {
    id: "MARKETPLACE_MERCHANT_DESC",
    defaultMessage:
      "You can upload your products or services for customes to  purchase on the market place",
  },
  MARKETPLACE_SUBHEADING: {
    id: "MARKETPLACE_SUBHEADING",
    defaultMessage:
      "Explore our products and services on the LetsGo Market Place, shop for properties,furniture,hardware materials from the comfort of your home",
  },
  MARKETPLACE_SUBHEADING_2: {
    id: "MARKETPLACE_SUBHEADING_2",
    defaultMessage: "Your home’s one-stop-shop is here",
  },
  MARKETPLACE_DASHBOARD_BENEFITS: {
    id: "MARKETPLACE_DASHBOARD_BENEFITS",
    defaultMessage: "Benefits",
  },
  MARKETPLACE_BENEFITS_1: {
    id: "MARKETPLACE_BENEFITS_1",
    defaultMessage: "Best in-class products & services",
  },
  MARKETPLACE_BENEFITS_2: {
    id: "MARKETPLACE_BENEFITS_2",
    defaultMessage: "Verified and trusted Merchants",
  },
  MARKETPLACE_BENEFITS_3: {
    id: "MARKETPLACE_BENEFITS_3",
    defaultMessage: "Great customer support",
  },
  CLICK_HERE_NOW: {
    id: "CLICK_HERE_NOW",
    defaultMessage: "Click here now",
  },
  DIRECTOR_TXT: {
    id: "DIRECTOR_TXT",
    defaultMessage: "Are you a director?",
  },
  DIRECTOR_SUB_TITLE: {
    id: "DIRECTOR_SUB_TITLE",
    defaultMessage:
      "Please add company directors, they will be sent a notification to consent to the application",
  },
  PASSWORD_NOT_SET: {
    id: "PASSWORD_NOT_SET",
    defaultMessage:
      "You are yet to set a new passwords! Please set up your password to continue using the digital mall.",
  },
  LIMITED_LIABILITY_DOCUMENTS_REQUIREMENTS: {
    id: "LIMITED_LIABILITY_DOCUMENTS_REQUIREMENTS",
    defaultMessage:
      "Kindly find below the details required before you can proceed with updating your profile.",
  },
  LOAN_EXPIRED_TEXT_LBT_HEADER: {
    id: "LOAN_EXPIRED_TEXT_LBT_HEADER",
    defaultMessage: "Uh-oh! Your loan",
  },
  LOAN_EXPIRED_TEXT_LBT_SUB_HEADER: {
    id: "LOAN_EXPIRED_TEXT_LBT_SUB_HEADER",
    defaultMessage: "application is expired",
  },
  DESCRIPTION_REQUIRED: {
    id: "DESCRIPTION_REQUIRED",
    defaultMessage: "Description (Required)",
  },
  CURRENT_PACKAGE: {
    id: "CURRENT_PACKAGE",
    defaultMessage: "Current package",
  },
  SUBSCRIPTION_AMOUNT: {
    id: "SUBSCRIPTION_AMOUNT",
    defaultMessage: "Subscription amount",
  },
  PERIOD: {
    id: "PERIOD",
    defaultMessage: "Period",
  },
  SMART_CARD_NO: {
    id: "SMART_CARD_NO",
    defaultMessage: "Smart Card No.",
  },
  SMART_CARD_NUMBER: {
    id: "SMART_CARD_NUMBER",
    defaultMessage: "Smart card number",
  },
  LOAN_NET_AMOUNT_TO_BORROW: {
    id: "LOAN_NET_AMOUNT_TO_BORROW",
    defaultMessage: "Net amount to borrow",
  },
  FREQUENCY_WITH_DATE: {
    id: "FREQUENCY_WITH_DATE",
    defaultMessage: "{frequency}, {date}",
  },
  AUTO_PAY_TO: {
    id: "AUTO_PAY_TO",
    defaultMessage: "Autopay to",
  },
  SCAM_ALERT_DASHBOARD: {
    id: "SCAM_ALERT_DASHBOARD",
    defaultMessage:
      "Stay protected and learn more about scam alerts you’d likely get from fraudsters. <custom> Read more</custom>",
  },
  SCAM_ALERT_DASHBOARD_NAVIGATION: {
    id: "SCAM_ALERT_DASHBOARD_NAVIGATION",
    defaultMessage: "Scam alerts notices",
  },
  SCAM_ALERT_HEADER_PART_ONE: {
    id: "SCAM_ALERT_HEADER_PART_ONE",
    defaultMessage: "Stay on guide with",
  },
  SCAM_ALERT_HEADER_PART_TWO: {
    id: "SCAM_ALERT_HEADER_PART_TWO",
    defaultMessage: "This scam alert notices",
  },
  SCAM_ALERT_SUBHEADER: {
    id: "SCAM_ALERT_SUBHEADER",
    defaultMessage:
      "Secure yourself by learning more about these scam tactics.",
  },
  SCAM_LIST_ITEM_ONE: {
    id: "SCAM_LIST_ITEM_ONE",
    defaultMessage:
      "Fraudsters use pressure and scare tactics to confuse you. DO NOT respond to emails, SMS or calls demanding urgent action on your account.",
  },
  SCAM_LIST_ITEM_TWO: {
    id: "SCAM_LIST_ITEM_TWO",
    defaultMessage:
      "Be Alert! Letshego employees won’t call you frantically claiming your account or personal information need to be updated through a link on email or SMS.",
  },
  SCAM_LIST_ITEM_THREE: {
    id: "SCAM_LIST_ITEM_THREE",
    defaultMessage:
      "Be Alert! Letshego will NEVER send customers emails or SMS asking them to access their account via a link or demand urgent action on their account.",
  },
  SCAM_LIST_ITEM_FOUR: {
    id: "SCAM_LIST_ITEM_FOUR",
    defaultMessage:
      "Be Alert for Emails that don't address you by name, are poorly formatted or contain spelling errors or demand urgent action.",
  },
  SCAM_LIST_ITEM_FIVE: {
    id: "SCAM_LIST_ITEM_FIVE",
    defaultMessage:
      "Be Alert! It is the festive season when fraudsters increase their activity. Letshego will NEVER ask customers to make a payment from your account through a link.",
  },
  SCAM_LIST_ITEM_SIX: {
    id: "SCAM_LIST_ITEM_SIX",
    defaultMessage:
      "Be Alert! DO NOT click on links or respond to unsolicited emails or phone calls asking for personal information. Remember: Letshego’s email domain ends with @letshego.com.",
  },
  SCAM_LIST_ITEM_SEVEN: {
    id: "SCAM_LIST_ITEM_SEVEN",
    defaultMessage:
      "Be Alert! Letshego will NEVER send customers emails or SMS asking them to access their account via a link or demanding urgent action on their account.",
  },
  SCAM_LIST_ITEM_EIGHT: {
    id: "SCAM_LIST_ITEM_EIGHT",
    defaultMessage:
      "FRAUD TIP! Don't share verification codes or PINs with anyone. Fraudsters may use the SMS verification code sent to your phone number to take over your account.",
  },
  SCAM_LIST_ITEM_NINE: {
    id: "SCAM_LIST_ITEM_NINE",
    defaultMessage:
      "Stay Alert! Avoid online fraud. Look out for websites with spelling errors, time-limited offers, rewards, flash sales or unusual payment requests.",
  },
  SCAM_LIST_ITEM_TEN: {
    id: "SCAM_LIST_ITEM_TEN",
    defaultMessage:
      "Be Alert! Fraudsters use unjustified pressure and scare tactics to confuse you. DO NOT respond to emails, SMS or phone calls pressuring you to act.",
  },
  SCAM_LIST_ITEM_ELEVEN: {
    id: "SCAM_LIST_ITEM_ELEVEN",
    defaultMessage:
      "FRAUD TIP! Beware of online deals and calls made in the pretext of Letshego, promising you quick profits or benefits of early loan settlement.",
  },
  SCAM_LIST_ITEM_TWELVE: {
    id: "SCAM_LIST_ITEM_TWELE",
    defaultMessage:
      "Be Alert! Do NOT share your private photos or videos online. Scammers can use them to blackmail you and make financial demands.",
  },
  SCAM_LIST_ITEM_THIRTEEN: {
    id: "SCAM_LIST_ITEM_THERTEEN",
    defaultMessage:
      "Be Alert! We all love to connect online and fraudsters do too. Be wary of what you post on social media and other online sites. Scammers can use these details to scam you.",
  },
  SCAM_LIST_ITEM_FOURTEEN: {
    id: "SCAM_LIST_ITEM_FOURTEEN",
    defaultMessage:
      "Be Alert! Protect your online info from identity theft. Opt-in for Two-Factor Authentication, for added security on your account logins at all times.",
  },
  SCAM_LIST_ITEM_FIFTEEN: {
    id: "SCAM_LIST_ITEM_FIFTEEN",
    defaultMessage:
      "Be Alert! Secure your online accounts from fraudsters. Use a different and unique complex password with letters, numbers and special symbols for every online account.",
  },
  SCAM_LIST_ITEM_SIXTEEN: {
    id: "SCAM_LIST_ITEM_SIXTEEN",
    defaultMessage:
      "Be Alert! Are your identity details safe from fraudsters? Store hard and soft copies of your identity documents and statements securely. Destroy them if they are no longer required.",
  },
  SCAM_ALERT_CONTACT_HEADER_PART_ONE: {
    id: "SCAM_ALERT_CONTACT_HEADER_PART_ONE",
    defaultMessage: "If unsure",
  },
  SCAM_ALERT_CONTACT_SUB_HEADER: {
    id: "SCAM_ALERT_CONTACT_SUB_HEADER",
    defaultMessage:
      "If unsure at any point, you can contact us using the details below.",
  },
  DIRECTOR_CONSENT_PRIVACY_CONTENT: {
    id: "DIRECTOR_CONSENT_PRIVACY_CONTENT",
    defaultMessage:
      "I certify that all the information provided by me for this director consent is correct.",
  },
  DIRECTOR_CONSENT_TERMS_AND_CONDITION: {
    id: "DIRECTOR_CONSENT_TERMS_AND_CONDITION",
    defaultMessage:
      "I allow Letshego to asses my personal  information for director consent and accept all.",
  },
  UPDATE_PERSONAL_ID_REJECTED_ACTION: {
    id: "UPDATE_PERSONAL_ID_REJECTED_ACTION",
    defaultMessage: "Re-try profile update",
  },
  TZ_KYC_UPDATE_ERROR_TEXT: {
    id: "TZ_KYC_UPDATE_ERROR_TEXT",
    defaultMessage: "Thank You for information shared",
  },
  TZ_KYC_UPDATE_ERROR_SUB_TEXT: {
    id: "TZ_KYC_UPDATE_ERROR_SUB_TEXT",
    defaultMessage:
      "Please proceed with next steps in order to complete your KYC update",
  },
  CUSTOMER_INSTITUTION_EXPECTED: {
    id: "CUSTOMER_INSTITUTION_EXPECTED",
    defaultMessage:
      " Customer is expected to have initiated institution registration for verification",
  },
  REQUESTED_TO_RESET_PASSWORD_KINDLY_CHANGE: {
    id: "REQUESTED_TO_RESET_PASSWORD_KINDLY_CHANGE",
    defaultMessage:
      "You are requested to reset your password. Kindly change your password now.",
  },
  VERIFY_YOUR_BVN_TITLE: {
    id: "VERIFY_YOUR_BVN_TITLE",
    defaultMessage: "Enter your Bank Verification Number",
  },
  VERIFY_YOUR_BVN_HELPER_TEXT: {
    id: "VERIFY_YOUR_BVN_HELPER_TEXT",
    defaultMessage: "Please provide your Bank Verification Number",
  },
  VERIFY_YOUR_BVN_DESCRIPTION: {
    id: "VERIFY_YOUR_BVN_DESCRIPTION",
    defaultMessage:
      "To enable you to transact on Letshego's platform by verifying your identity.",
  },
  VERIFY_YOUR_BVN_CONSENT_INFORMATION: {
    id: "VERIFY_YOUR_BVN_CONSENT_INFORMATION",
    defaultMessage:
      "Please click the ‘Verify BVN’ button below to verify and consent that you are giving Letshego permission to use your BVN. This is a condition of the CBN regulations.",
  },
  ALLOW_CONSENT_CTA: {
    id: "ALLOW_CONSENT_CTA",
    defaultMessage: "Allow consent",
  },
  ALLOW_CONSENT_TITLE: {
    id: "ALLOW_CONSENT_TITLE",
    defaultMessage: "Allow BVN consent",
  },
  ALLOW_CONSENT_HEPLER: {
    id: "ALLOW_CONSENT_HEPLER",
    defaultMessage:
      "Due to regulatory requirements by CBN, you are required to allow BVN consent to continue using the digital mall. Kindly note that you will be referred to a third-party page within the application to complete this procedure. Ensure you have access to the phone numbers or email you used when you registered for BVN.",
  },
  TRANSFER_FUNDS: {
    id: "TRANSFER_FUNDS",
    defaultMessage: "Transfer Funds",
  },
  DAILY_LIMIT: {
    id: "DAILY_LIMIT",
    defaultMessage: "Daily Limit",
  },
  DAILY_TRANSACTION_LIMIT_SUB_TITLE_NG: {
    id: "DAILY_TRANSACTION_LIMIT_SUB_TITLE_NG",
    defaultMessage:
      "You have reached the maximum cumulative limit of {currencySymbol} {dailyLimit} allowed per day. Please reduce the amount to transfer or upgrade your KYC.",
  },
  DAILY_TRANSFER_LIMIT_SUB_TITLE_NG: {
    id: "DAILY_TRANSFER_LIMIT_SUB_TITLE_NG",
    defaultMessage:
      "You have reached your daily transaction limit of {currencySymbol} {dailyLimit} . To increase your transaction limit, upgrade now.",
  },
  DAILY_TRANSACTION_LIMIT_SUB_TITLE_1: {
    id: "DAILY_TRANSACTION_LIMIT_SUB_TITLE_1",
    defaultMessage:
      "You have reached the acceptable daily limit of {dailyLimit}, and you only have {availableAmount} left to transfer online today. Please reduce the amount to transfer. If you have any queries reach out to our customer centre on {contact}",
  },
  DAILY_TRANSACTION_LIMIT_SUB_TITLE_2: {
    id: "DAILY_TRANSACTION_LIMIT_SUB_TITLE_2",
    defaultMessage:
      "You have reached the maximum cummulative limit of {currencySymbol} {dailyLimit} allowed per day. For further queries please reach out to our customer centre on {contact}",
  },
  PASSWORD_HELPER: {
    id: "PASSWORD_HELPER",
    defaultMessage:
      "In order to protect your account, make sure your password.",
  },
  PASSWORD_MINIMUM_ERROR: {
    id: "PASSWORD_MINIMUM_ERROR",
    defaultMessage: "Is longer than 8 characters.",
  },
  PASSWORD_CASE_ERROR: {
    id: "PASSWORD_CASE_ERROR",
    defaultMessage: "Has 1 or more lowercase & uppercase letters.",
  },
  PASSWORD_NUMBER_ERROR: {
    id: "PASSWORD_NUMBER_ERROR",
    defaultMessage: "Has 1 or more numbers.",
  },
  PASSWORD_SPECIAL_ERROR: {
    id: "PASSWORD_SPECIAL_ERROR",
    defaultMessage: "Has 1 or more special characters i.e. @, $. !",
  },
  PASSWORD_EXPIRED: {
    id: "PASSWORD_EXPIRED",
    defaultMessage:
      "Your password has expired, you are required to reset your password by clicking on the reset button below.",
  },
  CURRENT_ACCOUNT: {
    id: "CURRENT_ACCOUNT",
    defaultMessage: "Current Account",
  },
  SAVINGS_ACCOUNT: {
    id: "SAVINGS_ACCOUNT",
    defaultMessage: "Savings Account",
  },
  ID_HINT_TEXT: {
    id: "ID_HINT_TEXT",
    defaultMessage: "Kindly enter your {IDType} in this format {format}",
  },
  DOS_TEXT: {
    id: "DOS_TEXT",
    defaultMessage: "Dos",
  },
  DONTS_TEXT: {
    id: "DONTS_TEXT",
    defaultMessage: "Dont's",
  },
  DOS_MAIN: {
    id: "DOS_MAIN",
    defaultMessage:
      "Below are ways you should take your pictures and get verified with ease",
  },
  TAKE_SELFIE: {
    id: "TAKE_SELFIE",
    defaultMessage: "Take Selfie",
  },
  VIEW_TEXT: {
    id: "VIEW_TEXT",
    defaultMessage: "View your",
  },
  DETAILS_TEXT: {
    id: "DETAILS_TEXT",
    defaultMessage: "Details !",
  },
  ACCOUNT_PENDING_WORKFLOW_MESSAGE: {
    id: "ACCOUNT_PENDING_WORKFLOW_MESSAGE",
    defaultMessage:
      "You have a pending loan/account application and will be able to open a Letshego account when done",
  },
  ACCOUNT_PENDING_WORKFLOW_MESSAGE_NG: {
    id: "ACCOUNT_PENDING_WORKFLOW_MESSAGE_NG",
    defaultMessage:
      "You have a pending loan/account application and will be able to open a Letshego account when completed. Please call us on {contactNumber}",
  },

  ACCOUNT_OPENING_ID_MESSAGE: {
    id: "ACCOUNT_OPENING_ID_MESSAGE",
    defaultMessage:
      "A few details needed to set up your account and you’re on for a great banking experience",
  },
  ACCOUNT_OPENING_ID_TYPE_HINT: {
    id: "ACCOUNT_OPENING_ID_TYPE_HINT",
    defaultMessage: "ID type",
  },
  ACCOUNT_OPENING_ID_TYPE_VALIDATION_ERROR: {
    id: "ACCOUNT_OPENING_ID_TYPE_VALIDATION_ERROR",
    defaultMessage: "Please enter a correct {idType}.",
  },
  APPLICATION_REVIEW_MESSAGE_NG: {
    id: "APPLICATION_REVIEW_MESSAGE_NG",
    defaultMessage:
      "Your application for a Savings account is under review. You will be notified shortly about its status.",
  },
  TXT_TRANSACTIONAL_SAVING_ACCOUNT: {
    id: "TXT_TRANSACTIONAL_SAVING_ACCOUNT",
    defaultMessage: "Transactional savings account",
  },
  TRANSACTION_APPLICATION_REVIEW_MESSAGE_NG: {
    id: "TRANSACTION_APPLICATION_REVIEW_MESSAGE_NG",
    defaultMessage:
      "Your application for a Transactional savings account is under review. You will be notified shortly about its status.",
  },
  APPLICATION_FAILED_DESCRIPTION_NG: {
    id: "APPLICATION_FAILED_DESCRIPTION_NG",
    defaultMessage:
      "Your application for a Savings account did not go through, please contact the branch for further clarifications.",
  },
  TRACK_APPLICATION_DESCRIPTION_NG: {
    id: "TRACK_APPLICATION_DESCRIPTION_NG",
    defaultMessage:
      "Please wait whiles we prepare your Savings account. This will take a few minutes.",
  },
  VERIFYING: { id: "VERIFYING", defaultMessage: "Verifying" },
  IDENTITY_TEXT: { id: "IDENTITY_TEXT", defaultMessage: "Your Identity!" },
  VERIFYING_TEXT: {
    id: "VERIFYING_TEXT",
    defaultMessage:
      "We are currently verifying your identity. We will get back to you soon. Please click Proceed to continue with your application.",
  },
  INSTANT_LOAN_KENYA_CONSENT: {
    id: "INSTANT_LOAN_KENYA_CONSENT",
    defaultMessage:
      "I verify that the information provided here is correct and I consent to provide this personal information to  third party Credit Scoring and ID verification organisation to check for my eligibility for this product",
  },
  SAFARI_COM_WARNING: {
    id: "SAFARI_COM_WARNING",
    defaultMessage: "Product currently available for Safaricom users Only.",
  },
  ON_QUALIFYING_FOR_A_LOAN: {
    id: "ON_QUALIFYING_FOR_A_LOAN",
    defaultMessage: "On qualifying for a loan",
  },
  LOAN_QUALIFICATION_IS_BASED_ON_YOUR_CURRENT_AFFORDABILITY: {
    id: "LOAN_QUALIFICATION_IS_BASED_ON_YOUR_CURRENT_AFFORDABILITY",
    defaultMessage:
      "Loan qualification is based on your current affordability and employment details and we encourage you to update your details to increase your chances of a higher loan offer.",
  },
  OK_SURE: { id: "OK_SURE", defaultMessage: "Ok, sure" },
  BASED_ON_YOUR_DETAILS_NO_QUALIFICATION_FOR_INSTANT_LOAN: {
    id: "BASED_ON_YOUR_DETAILS_NO_QUALIFICATION_FOR_INSTANT_LOAN",
    defaultMessage:
      "Based on your personal details you do not qualify for an Instant loan. Call <custom> customer service</custom> for more information.",
  },
  INSTANT_LOAN_REVIEW_YOUR_APPLICATION_DESCRIPTION_TEXT: {
    id: "INSTANT_LOAN_REVIEW_YOUR_APPLICATION_DESCRIPTION_TEXT",
    defaultMessage:
      "Won't be long! Please note that we are still finalising your application and we will provide you with feedback soon",
  },
  YOUR_APPLICATION_IS_NOT_SUCCESSFUL: {
    id: "YOUR_APPLICATION_IS_NOT_SUCCESSFUL",
    defaultMessage: "Your application is not successful",
  },
  SORRY_BASED_YOUR_DETAILS_WE_ARE_NOT_ABLE_TO_HELP_YOU: {
    id: "SORRY_BASED_YOUR_DETAILS_WE_ARE_NOT_ABLE_TO_HELP_YOU",
    defaultMessage:
      "We are sorry, based on your details, we are not able to help you now. Please contact our Helpline for further enquiry.",
  },
  WITHDRAWAL_LIMIT: {
    id: "WITHDRAWAL_LIMIT",
    defaultMessage: "Withdrawal Limit",
  },
  WITHDRAWAL_LIMIT_COUNT_MESSAGE: {
    id: "WITHDRAWAL_LIMIT_COUNT_MESSAGE",
    defaultMessage:
      "You have reached your limit of {limitCount} withdrawals per month on your savings account. please select another account for your transaction.",
  },
  UPDATE_PERSONAL_ID: {
    id: "UPDATE_PERSONAL_ID",
    defaultMessage: "Update your personal ID",
  },
  UPDATE_PERSONAL_ID_DESC: {
    id: "UPDATE_PERSONAL_ID_DESC",
    defaultMessage:
      "Due to regulatory requirements in Tanzania, a change has been introduced to replace the Check number with any of your National ID’s for Tanzania Faidika, as it’s recognized for all government and non-government employees. Click on continue to proceed ",
  },
  UPDATE_PERSONAL_ID_REJECTED: {
    id: "UPDATE_PERSONAL_ID_REJECTED",
    defaultMessage: "Sorry! Your profile update is unsuccessful",
  },
  UPDATE_PERSONAL_ID_REJECTED_DESC: {
    id: "UPDATE_PERSONAL_ID_REJECTED_DESC",
    defaultMessage:
      "Kindly update your profile information by clicking on the “Retry profile update” button below.",
  },
  OPTIONAL: {
    id: "OPTIONAL",
    defaultMessage: "Optional",
  },
  PROFILE_UPDATE_REVIEW: {
    id: "PROFILE_UPDATE_REVIEW",
    defaultMessage: "Profile update pending review",
  },
  PROFILE_UPDATE_REVIEW_DESC: {
    id: "PROFILE_UPDATE_REVIEW_DESC",
    defaultMessage:
      "Your submission is currently in progress. You would be notified once it is approved.",
  },
  TRANSFER_LIMIT: {
    id: "TRANSFER_LIMIT",
    defaultMessage: "Transfer Limit",
  },
  TRANSFER_LIMIT_MESSAGE: {
    id: "TRANSFER_LIMIT_MESSAGE",
    defaultMessage:
      "You have reached the transfer limit of {transferLimit} allowed per day. Please reduce the amount to transfer. For further queries please reach out to our customer centre on {contactNumber}",
  },
  SAVE_ACCOUNT_BENEFICIARY: {
    id: "SAVE_ACCOUNT_BENEFICIARY",
    defaultMessage: "Save this account for future transactions",
  },
  TRANSACTION_HISTORY_EMPTY_TITLE_MZ: {
    id: "TRANSACTION_HISTORY_EMPTY_TITLE_MZ",
    defaultMessage: "Nothing to see yet!",
  },
  BUSINESS_REG_CERTIFICATE: {
    id: "BUSINESS_REG_CERTIFICATE",
    defaultMessage: "Application for National ID",
  },
  APPLICATION_NATIONAL_ID: {
    id: "APPLICATION_NATIONAL_ID",
    defaultMessage: "Business Registration Certificate",
  },
  ACCOUNT_NUMBER_LABEL_MOZ_ALT: {
    id: "ACCOUNT_NUMBER_LABEL_MOZ_ALT",
    defaultMessage: "NIB/ Account Number",
  },

  STATEMENT_FEE: {
    id: "STATEMENT_FEE",
    defaultMessage: "Statement fee",
  },
  STATEMENT_FEE_CHARGE: {
    id: "STATEMENT_FEE_CHARGE",
    defaultMessage:
      "To generate statement you will incur a small fee of {statementFee}",
  },
  OK_CONTINUE: {
    id: "OK_CONTINUE",
    defaultMessage: "Ok, Continue",
  },
  TRANSACTION_LIMIT: {
    id: "TRANSACTION_LIMIT",
    defaultMessage: "Transaction Limit",
  },
  TRANSACTION_LIMIT_MESSAGE: {
    id: "TRANSACTION_LIMIT_MESSAGE",
    defaultMessage:
      "You have reached the maximum transaction limit of {transactionLimit} allowed per transaction!",
  },
  DAILY_TRANSACTION_LIMIT_SUB_TITLE_RW: {
    id: "DAILY_TRANSACTION_LIMIT_SUB_TITLE_RW",
    defaultMessage:
      "You have reached the acceptable daily limit of {dailyLimit} allowed per day!",
  },
  NETWORK_NAME: {
    id: "NETWORK_NAME",
    defaultMessage: "Network name",
  },
  OWN_NUMBER: {
    id: "OWN_NUMBER",
    defaultMessage: "Own number?",
  },
  HELPER_TEXT_MOBILE_NUMBER: {
    id: "HELPER_TEXT_MOBILE_NUMBER",
    defaultMessage: "Eg: 71234567",
  },
  CABLE_TV_LABLE: {
    id: "CABLE_TV_LABLE",
    defaultMessage: "Smart card number",
  },
  UPLOAD_ID_TYPE_LABEL: {
    id: "UPLOAD_ID_TYPE_LABEL",
    defaultMessage: "Upload ID type image",
  },
  UPLOAD_FRONT_ID_LABEL: {
    id: "UPLOAD_FRONT_ID_LABEL",
    defaultMessage: "Upload ID type front image",
  },
  UPLOAD_BACK_ID_LABEL: {
    id: "UPLOAD_BACK_ID_LABEL",
    defaultMessage: "Upload ID type back image",
  },
  UPLOAD_UTILITY_BILL_LABEL: {
    id: "UPLOAD_UTILITY_BILL_LABEL",
    defaultMessage: "Upload utility bill",
  },
  NATIONAL_ID_UPLOAD_DESC_HINT: {
    id: "NATIONAL_ID_UPLOAD_DESC_HINT",
    defaultMessage: "Upload your {documentType} {side}.",
  },
  KYC_TIER_LEVEL: {
    id: "KYC_TIER_LEVEL",
    defaultMessage: "KYC Level {kycLevel}",
  },
  KYC_UPGRADE_PROFILE: {
    id: "KYC_UPGRADE_PROFILE",
    defaultMessage: "Upgrade KYC",
  },
  KYC_UPGRADE_PROFILE_TEXT: {
    id: "KYC_UPGRADE_PROFILE_TEXT",
    defaultMessage: "Manage and view history of products bought",
  },
  UPGRADE_COMMON: {
    id: "UPGRADE_COMMON",
    defaultMessage: "Upgrade",
  },
  KYC_COMMON: {
    id: "KYC_COMMON",
    defaultMessage: "KYC",
  },
  UPGRADE_ACCOUNT: {
    id: "UPGRADE_ACCOUNT",
    defaultMessage: "Upgrade account",
  },
  UPGRADE_ACCOUNT_HEADING: {
    id: "UPGRADE_ACCOUNT_HEADING",
    defaultMessage: "Upgrade account limit",
  },
  UPGRADE_ACCOUNT_SUB_HEADING: {
    id: "UPGRADE_ACCOUNT_SUB_HEADING",
    defaultMessage: "Fill in your details to unlock benefits",
  },
  WITHDRAWAL_DEPOSIT_LIMIT: {
    id: "WITHDRAWAL_DEPOSIT_LIMIT",
    defaultMessage:
      "Your deposit limit is {depositLimit} and withdrawal limit is {withdrawalLimit}",
  },
  NC_TAKE_SELFIE_TEXT: {
    id: "NC_TAKE_SELFIE_TEXT",
    defaultMessage: "Upload your selfie / passport photo",
  },
  NC_TAKE_SELFIE_TITLE: {
    id: "NC_TAKE_SELFIE_TITLE",
    defaultMessage: "Take a selfie / upload a passport photo",
  },
  TAKE_SELFIE_HELPER: {
    id: "TAKE_SELFIE_HELPER",
    defaultMessage:
      "Your file should be in .jpeg, .jpg, .png formats with less than 10 MB size",
  },
  UPLOAD_SIGNATURE: {
    id: "UPLOAD_SIGNATURE",
    defaultMessage: "Upload signature",
  },
  UPLOAD_YOUR_SIGNATURE: {
    id: "UPLOAD_YOUR_SIGNATURE",
    defaultMessage: "Upload your signature",
  },
  VERYFY_ME_PROVIDE_DETAIL: {
    id: "VERYFY_ME_PROVIDE_DETAIL",
    defaultMessage: "Please provide your ID details",
  },
  ATTACH_DOCUMENT: {
    id: "ATTACH_DOCUMENT",
    defaultMessage: "Attach Document",
  },
  DOS_DESCRIPTION: {
    id: "DOS_DESCRIPTION",
    defaultMessage:
      "The images below describes how your passport photograph should be taken in the right and accurate manner.",
  },
  SELFIE_TEXT: {
    id: "SELFIE_TEXT",
    defaultMessage: "Selfie",
  },
  SELFIE_HELPER_TOP: {
    id: "SELFIE_HELPER_TOP",
    defaultMessage: "Best forms to take your passport photo",
  },
  SELFIE_HELPER_SUB: {
    id: "SELFIE_HELPER_SUB",
    defaultMessage:
      "We advice that for maximum efficiency, it would be imperative that you follow this proposed form of taking a passport photo with your laptop or desktop device.",
  },
  UPLOAD_SELFIE_SIDE_PANEL_HEADER_CUSTOM: {
    id: "UPLOAD_SELFIE_SIDE_PANEL_HEADER_CUSTOM",
    defaultMessage:
      "Here's how you can take your pitcure and get verified in one go",
  },
  NG_UPLOAD_SELFIE_STEP_1: {
    id: "NG_UPLOAD_SELFIE_STEP_1",
    defaultMessage: "You need good lighting, {subtext} ",
  },
  NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_1: {
    id: "NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_1",
    defaultMessage: "but avoid bright lights behind you",
  },
  NG_UPLOAD_SELFIE_STEP_2: {
    id: "NG_UPLOAD_SELFIE_STEP_2",
    defaultMessage:
      "Its all about the face, not too far away or too close {subtext} ",
  },
  NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_2: {
    id: "NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_2",
    defaultMessage: "look straight forward",
  },
  NG_UPLOAD_SELFIE_STEP_3: {
    id: "NG_UPLOAD_SELFIE_STEP_3",
    defaultMessage: "Remove anything in the way, that could {subtext} ",
  },
  NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_3: {
    id: "NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_3",
    defaultMessage: "block or cover capturing you face",
  },
  NG_UPLOAD_SELFIE_STEP_4: {
    id: "NG_UPLOAD_SELFIE_STEP_4",
    defaultMessage: "A selfie is a {subtext} - ensure no friends photo bomb",
  },
  NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_4: {
    id: "NG_UPLOAD_SELFIE_DESCRIPTOR_STEP_4",
    defaultMessage: "solo act",
  },
  VERIFIED_DETIALD_FAILED: {
    id: "VERIFIED_DETIALD_FAILED",
    defaultMessage: "Your details are incorrect",
  },
  FACE_UNABLE_TO_VALIDATE: {
    id: "FACE_UNABLE_TO_VALIDATE",
    defaultMessage:
      "We are unable to verify your details, Please retry to proceed with the application",
  },
  FLEXI_SAVE: {
    id: "FLEXI_SAVE",
    defaultMessage: "FlexiSave account",
  },
  PROFILE_DETAILS_TEXT_EXT: {
    id: "PROFILE_DETAILS_TEXT_EXT",
    defaultMessage:
      "View your mobile number, email address or verify ID details. Upgrade your account to enjoy unlimited benefits",
  },
  TRUSTED_DEVICE_TITLE: {
    id: "TRUSTED_DEVICE_TITLE",
    defaultMessage: "Security update",
  },
  TRUSTED_DEVICE_TITLE_SUBTITLE: {
    id: "TRUSTED_DEVICE_TITLE_SUBTITLE",
    defaultMessage: "We have a new and",
  },
  TRUSTED_DEVICE_TITLE_SUBTITLE_2: {
    id: "TRUSTED_DEVICE_TITLE_SUBTITLE_2",
    defaultMessage: "Improved security update",
  },
  TRUSTED_DEVICE_UPDATE_SECURITY_DESC: {
    id: "TRUSTED_DEVICE_UPDATE_SECURITY_DESC",
    defaultMessage:
      "To better protect your account, we have updated our security.",
  },
  TRUSTED_DEVICE_UPDATE_SECURITY_LIST_TITLE: {
    id: "TRUSTED_DEVICE_UPDATE_SECURITY_LIST_TITLE",
    defaultMessage: "Here’s what to expect with the new update",
  },
  TRUSTED_DEVICE_UPDATE_SECURITY_LIST_1: {
    id: "TRUSTED_DEVICE_UPDATE_SECURITY_LIST_1",
    defaultMessage:
      "Keep track and manage the activities of the devices that access your account.",
  },
  TRUSTED_DEVICE_UPDATE_SECURITY_LIST_2: {
    id: "TRUSTED_DEVICE_UPDATE_SECURITY_LIST_2",
    defaultMessage:
      "Easily add devices you trust and remove devices you don’t trust.",
  },
  CLICK_BTN_BELOW_TXT: {
    id: "CLICK_BTN_BELOW_TXT",
    defaultMessage: "To trust this device, click on the button below",
  },
  TRUSTED_DEVICE_MODAL_CLICK_HERE_TXT: {
    id: "TRUSTED_DEVICE_MODAL_CLICK_HERE_TXT",
    defaultMessage:
      "If you don’t trust this device, continue by clicking {link}",
  },
  HERE_TXT: {
    id: "HERE_TXT",
    defaultMessage: "here",
  },
  TRUST_THIS_DEVICE_TXT: {
    id: "TRUST_THIS_DEVICE_TXT",
    defaultMessage: "Trust this device",
  },
  TRUST_TXT: {
    id: "TRUST_TXT",
    defaultMessage: "Trust",
  },
  DEVICE_TXT: {
    id: "DEVICE_TXT",
    defaultMessage: "Device",
  },
  TRUST_DEVICE_SECURITY_UPDATE_DESC: {
    id: "TRUST_DEVICE_SECURITY_UPDATE_DESC",
    defaultMessage:
      "To better protect your account, kindly confirm that you trust this device.",
  },
  WHAT_TO_EXPECT_TXT: {
    id: "WHAT_TO_EXPECT_TXT",
    defaultMessage: "Here's what to expect next",
  },
  WHAT_TO_EXPECT_LIST_1: {
    id: "WHAT_TO_EXPECT_LIST_1",
    defaultMessage:
      "Trusting a device is one time and we wouldn’t require you to trust a device when next you try to log in.",
  },
  COTINUE_WITH_OTP: {
    id: "COTINUE_WITH_OTP",
    defaultMessage: "Continue with OTP",
  },
  CONFIRM_OTP: {
    id: "CONFIRM_OTP",
    defaultMessage: "Confirm OTP",
  },
  TRSUTED_DEVICE_OTP_MODAL_DESC: {
    id: "TRSUTED_DEVICE_OTP_MODAL_DESC",
    defaultMessage:
      "To verify this device, we have sent an OTP to your registered mobile number to continue.",
  },
  ENTER_OTP: {
    id: "ENTER_OTP",
    defaultMessage: "Enter OTP",
  },
  TRUSTED_DEVICE_ALERT_MSG: {
    id: "TRUSTED_DEVICE_ALERT_MSG",
    defaultMessage:
      "By continuing with this process, this device won’t be added to your list of trusted devices, so every time you try to log in, you’d be requested to enter an OTP sent to your registered mobile number.",
  },
  REMOVE_DEVICE_TITLE: {
    id: "REMOVE_DEVICE_TITLE",
    defaultMessage: "Remove device from the list of devices",
  },
  REMOVE_ADDED_DEVICE_DESC: {
    id: "REMOVE_ADDED_DEVICE_DESC",
    defaultMessage:
      "You are about to remove {deviceName} - {deviceOs} from your list of trusted devices.",
  },
  DEVICE_TXT: {
    id: "DEVICE_TXT",
    defaultMessage: "Device",
  },
  DEVICE_IDENTIFIED_TXT: {
    id: "DEVICE_IDENTIFIED_TXT",
    defaultMessage: "Device identified",
  },
  AUTHENTICATE_FROM_ANOTHER_DEVICE: {
    id: "AUTHENTICATE_FROM_ANOTHER_DEVICE",
    defaultMessage: "Authenticate this device from your primary trusted device",
  },
  AUTHENTICATE_FROM_ANOTHER_DEVICE_DESC: {
    id: "AUTHENTICATE_FROM_ANOTHER_DEVICE_DESC",
    defaultMessage:
      "We have sent you an authentication message to your primary device, kindly accept to add this device as part of your trusted devices.",
  },
  IDENTIFIED_TXT: {
    id: "IDENTIFIED_TXT",
    defaultMessage: "Identified",
  },
  DEVICE_IDENTIFIED_DESC: {
    id: "DEVICE_IDENTIFIED_DESC",
    defaultMessage:
      "Note that by clicking on the continue button, you are accepting to add this device to your list of trusted device.",
  },
  DEVICE_ADDED: {
    id: "DEVICE_ADDED",
    defaultMessage: "Device added successfully",
  },
  HAS_BEEN_ADDED_TXT: {
    id: "HAS_BEEN_ADDED_TXT",
    defaultMessage:
      "{deviceName} has been successfully added to your list of trusted devices.",
  },
  DEPOSIT: {
    id: "DEPOSIT",
    defaultMessage: "Deposit limit",
  },
  WITHDRAWAL: {
    id: "WITHDRAWAL",
    defaultMessage: "Withdrawal limit",
  },
  ADDITIONAL_DOCS_HELPER: {
    id: "ADDITIONAL_DOCS_HELPER",
    defaultMessage:
      "Your file should be in .pdf, .jpeg, .jpg, .png formats with less than 10 MB size. Kindly do not upload password protected document.",
  },
  MY_DEVICES: {
    id: "MY_DEVICES",
    defaultMessage: "My devices",
  },
  MY_DEVICES_DESC: {
    id: "MY_DEVICES_DESC",
    defaultMessage:
      "View and manage the devices that have access to your account.",
  },
  FAILED_AUTH: {
    id: "FAILED_AUTH",
    defaultMessage: "If authentication fails, kindly",
  },
  LOCATION: {
    id: "LOCATION",
    defaultMessage: "Location: ",
  },
  CONTINUE_WITH_OTP: {
    id: "CONTINUE_WITH_OTP",
    defaultMessage: "Continue with OTP",
  },
  TRUSTED_DEVICE__TIMER: {
    id: "TRUSTED_DEVICE__TIMER",
    defaultMessage: "{time}",
  },
  AUTH_EXPIRED: {
    id: "AUTH_EXPIRED",
    defaultMessage: "Authentication expired",
  },
  AUTH_EXPIRED_DESC: {
    id: "AUTH_EXPIRED_DESC",
    defaultMessage: "Authentication process has expired, login to try again.",
  },
  LAST_SEEN_TXT: {
    id: "LAST_SEEN_TXT",
    defaultMessage: "Last seen, ",
  },
  NIDA_LABEL_EXT: {
    id: "NIDA_LABEL_EXT",
    defaultMessage: "NIDA number",
  },
  ENTREPRENEUR_TZ_ACCOUNT_NAME: {
    id: "ENTREPRENEUR_TZ_ACCOUNT_NAME",
    defaultMessage: "NIDA number",
  },
  APPLICATION_FAILED_DESCRIPTION_EXT: {
    id: "APPLICATION_FAILED_DESCRIPTION_EXT",
    defaultMessage:
      "Your application for a {accountType} account did not go through, please contact the branch for further clarifications.",
  },
  APPLICATION_REVIEW_MESSAGE_EXT: {
    id: "APPLICATION_REVIEW_MESSAGE_EXT",
    defaultMessage:
      "Please wait whiles we prepare your {accountType} account. This will take a few minutes",
  },
  GRANT_PRIMARY_ACCESS: {
    id: "GRANT_PRIMARY_ACCESS",
    defaultMessage: "Grant device primary access",
  },
  GRANT_DEVICE_ACCESS: {
    id: "GRANT_DEVICE_ACCESS",
    defaultMessage: "Grant device access",
  },
  GRANT_DEVICE: {
    id: "GRANT_DEVICE",
    defaultMessage: "Grant device",
  },
  PRIMARY_ACCESS: {
    id: "PRIMARY_ACCESS",
    defaultMessage: "Primary access",
  },
  PRIMARY_ACCESS_GRANTED: {
    id: "PRIMARY_ACCESS_GRANTED",
    defaultMessage: "Primary access granted",
  },
  PRIMARY_ACCESS_GRANTED_DESC: {
    id: "PRIMARY_ACCESS_GRANTED_DESC",
    defaultMessage:
      "{deviceName} - {deviceOs} has been successfully granted access to be your primary trusted device.",
  },
  GRANT_ACESS_DESC: {
    id: "GRANT_ACESS_DESC",
    defaultMessage:
      "To make {deviceName} your primary device, kindly enter the OTP sent to your registered mobile number.",
  },
  REASONS_FOR_REPORTING: {
    id: "REASONS_FOR_REPORTING",
    defaultMessage: "Select reason for reporting",
  },
  GRANT_PRIMARY_ACCESS_DESC: {
    id: "GRANT_PRIMARY_ACCESS_DESC",
    defaultMessage:
      "You can make this device your primary device so you can continue granting access to other devices seamlessly.",
  },
  REPORTED_DEVICE: {
    id: "REPORTED_DEVICE",
    defaultMessage: "You have successfully reported device",
  },
  REPORTED_DEVICE_DESC: {
    id: "REPORTED_DEVICE_DESC",
    defaultMessage:
      "{deviceName} - {deviceOs}, this device would be removed and  permanently barred from using your account",
  },
  REMOVE_DEVICE: {
    id: "REMOVE_DEVICE",
    defaultMessage: "Remove device",
  },
  REMOVE_DEVICE_DESC: {
    id: "REMOVE_DEVICE_DESC",
    defaultMessage:
      "Removing the device from the list of trusted devices, would automatically log out your account from the device.",
  },
  REPORT_DEVICE: {
    id: "REPORT_DEVICE",
    defaultMessage: "Report device",
  },
  REPORT_DEVICE_DESC: {
    id: "REPORT_DEVICE_DESC",
    defaultMessage:
      "To better protect your account, kindly select your reason for reporting this device",
  },
  WHAT_IS_YOUR_EMPLOYEE_NUMBER: {
    id: "WHAT_IS_YOUR_EMPLOYEE_NUMBER",
    defaultMessage: "What is your employee number?",
  },
  PENDING_ACTIONS_HEADER: {
    id: "PENDING_ACTIONS_HEADER",
    defaultMessage: "You Have Pending Actions",
  },
  PENDING_ACTIONS_TEXT: {
    id: "PENDING_ACTIONS_TEXT",
    defaultMessage:
      "Your input is required to help us process your submitted loan application promptly. Please attend to these now. ",
  },
  PENDING_ACTIONS_TEXT_2: {
    id: "PENDING_ACTIONS_TEXT_2",
    defaultMessage: "Please attend to these now. ",
  },
  PENDING_ACTIONS_TEXT_3: {
    id: "PENDING_ACTIONS_TEXT_3",
    defaultMessage: "Continue to Tracker",
  },
  VERIFYME_UNAVAILABILITY_EXT: {
    id: "VERIFYME_UNAVAILABILITY_EXT",
    defaultMessage:
      "Note: Your selected ID can not be verified now, please select another, OR proceed with selected ID.",
  },
  PAY_LOAN: {
    id: "PAY_LOAN",
    defaultMessage: "Pay loan",
  },
  LETSGO_LOAN_ACCOUNT_HELPER_TEXT: {
    id: "LETSGO_LOAN_ACCOUNT_HELPER_TEXT",
    defaultMessage: "Paying loan made quick and hassle free",
  },
  RECIPIENT_LOAN_DETAILS: {
    id: "RECIPIENT_LOAN_DETAILS",
    defaultMessage: "Enter Recipient loan details",
  },
  SAME_ACCOUNT_ERROR: {
    id: "SAME_ACCOUNT_ERROR",
    defaultMessage:
      "Can’t send to the same account number, please enter another account number",
  },
  VERIFYME_SUCCESS_MESSAGE: {
    id: "VERIFYME_SUCCESS_MESSAGE",
    defaultMessage:
      "Verification successful!\n We have successfully verified your ID details.",
  },
  VERIFYME_MANUAL_REQUEST_MESSAGE: {
    id: "VERIFYME_MANUAL_REQUEST_MESSAGE",
    defaultMessage:
      "Please note your selected ID could not be verified at the moment, and would be done following your completed application submission",
  },
  UNCLAIMED: {
    id: "UNCLAIMED",
    defaultMessage: "Unclaimed",
  },
  DORMANT: {
    id: "DORMANT",
    defaultMessage: "Dormant",
  },
  INACTIVE: {
    id: "INACTIVE",
    defaultMessage: "Inactive",
  },
  CLOSED: {
    id: "CLOSED",
    defaultMessage: "Closed",
  },
  ACCOUNT_STATUS_INFO_MESSAGE: {
    id: "ACCOUNT_STATUS_INFO_MESSAGE",
    defaultMessage:
      "Your {accountType} account is {status}. To reactivate, please visit our nearest branch.",
  },
  CLOSED_ACCOUNT_STATUS_INFO_MESSAGE: {
    id: "CLOSED_ACCOUNT_STATUS_INFO_MESSAGE",
    defaultMessage: "Your {accountType} account is closed.",
  },
  MY_ACCOUNT: {
    id: "MY_ACCOUNT",
    defaultMessage: "My Account",
  },
  MY_WALLET: {
    id: "MY_ACCOUNT",
    defaultMessage: "My Wallet",
  },
  PAY_NEW_LOAN_CTA_MAIN_TEXT: {
    id: "PAY_NEW_LOAN_CTA_MAIN_TEXT",
    defaultMessage: "Pay new loan",
  },
  PAY_NEW_LOAN_CTA_MAIN_SUBTEXT: {
    id: "PAY_NEW_LOAN_CTA_MAIN_SUBTEXT",
    defaultMessage: "Add details of a new loan to make payment",
  },
  RECENT_LOANS: {
    id: "RECENT_LOANS",
    defaultMessage: "Recent  Loans",
  },
  NO_LOAN_ACCOUNTS_FOUND: {
    id: "NO_LOAN_ACCOUNTS_FOUND",
    defaultMessage: "No accounts found!",
  },
  NO_LOAN_ACCOUNTS_FOUND_SUBTEXT: {
    id: "NO_LOAN_ACCOUNTS_FOUND_SUBTEXT",
    defaultMessage: "Please enter a valid name or account number",
  },
  VERIFYME_UNAVAILABILITY: {
    id: "VERIFYME_UNAVAILABILITY",
    defaultMessage:
      "Note: Your selected ID can not be verified at the moment, please select another ID type you have OR proceed with current selection",
  },
  RECEIVE_ACCOUNT: {
    id: "RECEIVE_ACCOUNT",
    defaultMessage: "You will receive in your account",
  },
  SELECT_ACCOUNT_EXT: {
    id: "SELECT_ACCOUNT_EXT",
    defaultMessage: "Select account number",
  },
  ENTER_ACCOUNT: {
    id: "ENTER_ACCOUNT",
    defaultMessage: "Enter account number",
  },
  LOAN_ACCOUNT_HOLDER_TEXT_HELPER: {
    id: "LOAN_ACCOUNT_HOLDER_TEXT_HELPER",
    defaultMessage: "Search from saved loan account or enter new name",
  },
  LOAN_ACCOUNT_DETAILS_NOT_FOUND_DESC: {
    id: "LOAN_ACCOUNT_DETAILS_NOT_FOUND_DESC",
    defaultMessage:
      "The loan account details  doesn’t seem to exist. Please correct the number or try another loan account number",
  },
  LOAN_BALANCE_HELPER_TEXT: {
    id: "LOAN_BALANCE_HELPER_TEXT",
    defaultMessage: "Your loan balance is {loanBalance}",
  },
  ACCOUNT_BALANCE_LESS_LOAN_AMOUNT: {
    id: "ACCOUNT_BALANCE_LESS_LOAN_AMOUNT",
    defaultMessage: "You can’t pay more than your expected loan amount",
  },
  BANK_TO_LOAN: {
    id: "BANK_TO_LOAN",
    defaultMessage: "You can’t pay more than your expected loan amount",
  },
  LETS_HELP_YOUR_EMPLOYEES: {
    id: "LETS_HELP_YOUR_EMPLOYEES",
    defaultMessage: "Let’s help your employees",
  },
  FOCUS_ON_WHAT_MATTERS: {
    id: "FOCUS_ON_WHAT_MATTERS",
    defaultMessage: "Focus on what matters",
  },
  YOUR_EMPLOYEES_ARE_THE_BEDROCK_TEXT: {
    id: "YOUR_EMPLOYEES_ARE_THE_BEDROCK_TEXT",
    defaultMessage:
      "Your employees are the bedrock of your business, let us help you with access to products that would help them focus on the most vital and productive part of their everyday lives.",
  },
  LEARN_MORE: {
    id: "LEARN_MORE",
    defaultMessage: "Learn more",
  },
  LETS_HELP_YOU_FIND_A: {
    id: "LETS_HELP_YOU_FIND_A",
    defaultMessage: "Let’s help you find a",
  },
  PRODUCT_THAT_FITS: {
    id: "PRODUCT_THAT_FITS",
    defaultMessage: "Product that fits",
  },
  YOUR_BUSINESS_NEEDS_GOALS_AND_EMPLOYEE_REQUIREMENTS: {
    id: "YOUR_BUSINESS_NEEDS_GOALS_AND_EMPLOYEE_REQUIREMENTS",
    defaultMessage:
      "Your business needs, corporate goals and employee requirements.",
  },
  LETS_TALK: {
    id: "LETS_TALK",
    defaultMessage: "Let’s talk",
  },
  WHAT_LETS_GO_AT_WORK: {
    id: "WHAT_LETS_GO_AT_WORK",
    defaultMessage: "What LetsGo at work",
  },
  OFFERS_YOUR_EMPLOYEES: {
    id: "OFFERS_YOUR_EMPLOYEES",
    defaultMessage: "Offers your employees",
  },
  WALLET_TO_LOAN: {
    id: "WALLET_TO_LOAN",
    defaultMessage: "LetsGo Wallet to Loan",
  },
  ENTER_YOUR_BUSINESS_DETAILS: {
    id: "ENTER_YOUR_BUSINESS_DETAILS",
    defaultMessage: "Enter your business details",
  },
  ORGANIZATION_NAME: {
    id: "ORGANIZATION_NAME",
    defaultMessage: "Organisation name",
  },
  BUSINESS_EMAIL_ADDRESS: {
    id: "BUSINESS_EMAIL_ADDRESS",
    defaultMessage: "Business email address",
  },
  AVERAGE_NUMBER_OF_EMPLOYEES: {
    id: "AVERAGE_NUMBER_OF_EMPLOYEES",
    defaultMessage: "Average number of employees",
  },
  AND_ABOVE: {
    id: "AND_ABOVE",
    defaultMessage: "and above",
  },
  ENTER_YOUR_PERSONAL_DETAILS: {
    id: "ENTER_YOUR_PERSONAL_DETAILS",
    defaultMessage: "Enter your personal details",
  },
  PERSONAL_EMAIL_ADDRESS: {
    id: "PERSONAL_EMAIL_ADDRESS",
    defaultMessage: "Personal email address",
  },
  DO_YOU_WANT_US_TO_KNOW_ANYTHING: {
    id: "DO_YOU_WANT_US_TO_KNOW_ANYTHING",
    defaultMessage: "Do you want us to know anything else?",
  },
  LETS_GO_AT_WORK: {
    id: "LETS_GO_AT_WORK",
    defaultMessage: "LetsGo@work",
  },
  KINDLY_FILL_OUT_THE_INFORMATION_BELOW_AND_WE_WOULD_GET_IN_TOUCH: {
    id: "KINDLY_FILL_OUT_THE_INFORMATION_BELOW_AND_WE_WOULD_GET_IN_TOUCH",
    defaultMessage:
      "Kindly fill out the information below and we would get in touch with you.",
  },
  OUR_OFFERING_COVERS_ALL_THE_BOUQUET_PRODUCTS_LISTED_BELOW: {
    id: "OUR_OFFERING_COVERS_ALL_THE_BOUQUET_PRODUCTS_LISTED_BELOW",
    defaultMessage:
      "Our offering covers all the bouquet of products listed below to cater for your employees needs.",
  },
  BORROW_LOANS: {
    id: "BORROW_LOANS",
    defaultMessage: "Borrow loans",
  },
  LET_US_KNOW: {
    id: "LET_US_KNOW",
    defaultMessage: "Let us know",
  },
  WHAT_YOU_NEED: {
    id: "WHAT_YOU_NEED",
    defaultMessage: "What you need",
  },
  BILL: {
    id: "BILL",
    defaultMessage: "Bill",
  },
  BUY_AIRTIME: {
    id: "BUY_AIRTIME",
    defaultMessage: "Buy Airtime",
  },
  PAY_FROM: {
    id: "PAY_FROM",
    defaultMessage: "Pay from",
  },
  APPLY_AS_A_BUSINESS: {
    id: "APPLY_AS_A_BUSINESS",
    defaultMessage: "Apply as a business",
  },
  APPLY_AS_AN_UNION: {
    id: "APPLY_AS_AN_UNION",
    defaultMessage: "Apply as a union",
  },
  APPLY_AS_AN_EMPLOYEE: {
    id: "APPLY_AS_AN_EMPLOYEE",
    defaultMessage: "Apply as an employee",
  },
  ENTER_YOUR_UNION_ASSOCIATION_DETAILS: {
    id: "ENTER_YOUR_UNION_ASSOCIATION_DETAILS",
    defaultMessage: "Enter your union/association details",
  },
  LETSHEGO_ACCOUNT: {
    id: "LETSHEGO_ACCOUNT",
    defaultMessage: "Letshego account",
  },
  OTHER_ACCOUNT: {
    id: "OTHER_ACCOUNT",
    defaultMessage: "Other Account",
  },
  INDIVIDUAL_WALLET: {
    id: "INDIVIDUAL_WALLET",
    defaultMessage: "Individual",
  },
  MERCHANT_WALLET: {
    id: "MERCHANT_WALLET",
    defaultMessage: "Merchant",
  },
};

const handler = {
  get: function (target, name) {
    return target.hasOwnProperty(name)
        ? target[name]
        : { id: "DEFAULT_VALUE", defaultMessage: " " };
  },
};
const messagesWithDefaultValue = new Proxy(messageKeys, handler);
const messages = defineMessages(messagesWithDefaultValue);

export default messages;
