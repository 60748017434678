import React from "react";
import { Link } from "react-router-dom";
import messages from "i18n/messages";
import { useIntl } from "react-intl";
import { getMobileMenus } from "utils/menus";

const MobileMenu = ({ setIsMenuOpen, activeMenu, setActiveMenu}) => {

  const intl = useIntl();
  const { formatMessage } = intl;

  const menus = getMobileMenus();

  return (
    <div className="header-menu-mobile-items" itemType="https://schema.org/SiteNavigationElement" role="menu">
      {menus.map((data) => {
        const menu = JSON.parse(JSON.stringify(data));
        return (
          <>
            {menu?.isActive && (
              <div key={`${menu?.label}`} className={`header-menu-mobile-item ${menu?.hasSubMenus && 'has-sub-menu'} ${menu?.label === activeMenu ? "active" : ""}`}>
                {(menu?.label && menu?.hasSubMenus) && 
                  <p className="header-menu-mobile-item-label" onClick={()=> setActiveMenu(menu?.label !== activeMenu ? menu?.label : "")}>
                    {formatMessage(messages[menu?.label])}
                  </p>                
                }
                {menu?.hasSubMenus && (
                  <div className="header-sub-menu-mobile" style={{ display: `${menu?.label === activeMenu ? "block" : "none"}` }}>
                    {(menu?.columns || [])?.map((subMenu, i) => {
                      return (
                        <div key={i} className="header-sub-menu-mobile-item">
                          {subMenu?.title && <p className="header-sub-menu-mobile-title">{formatMessage(messages[subMenu?.title])}</p>}                      
                          {(subMenu?.links || [])?.map((link, x) => {
                            return (
                              link?.isActive ?
                                <div key={x} className="header-sub-menu-mobile-item">
                                  <a href={link?.link} onClick={()=> {
                                    setActiveMenu(null);
                                    setIsMenuOpen(false);
                                  }} className="header-sub-menu-mobile-link">
                                    <span>{formatMessage(messages[link?.name])}</span>
                                  </a>
                                </div>
                                :
                              ""
                            );
                          })}
                        </div>
                      )
                    })}
                  </div>
                )}
                {(menu?.label && !menu?.hasSubMenus) &&(
                  <Link to={menu?.mainLink} className="header-menu-item-label">
                    <span>{formatMessage(messages[menu?.label])}</span>
                  </Link>
                )}
              </div>
            )}
          </>
        )
      })}

      <div className="header-menu-mobile-item header-red-button">
        <a href="/contact-us" className="header-menu-mobile-item-label header-red-button-label" >
          <span >{formatMessage(messages.CONTACT_US)}</span>
        </a>
      </div>

      <div className="header-menu-mobile-contacts">
        <a href={`tel:${formatMessage(messages.COMPANY_PHONE_NUMBER)}`} className="header-menu-mobile-phone">
          <i className="icon-s-phone-bordered"></i>
          <span className="header-menu-mobile-phone-text" dir="ltr">{formatMessage(messages.COMPANY_PHONE_NUMBER)}</span>
        </a>
        <a href={`mailto:${formatMessage(messages.COMPANY_EMAIL_ADDRESS)}`} className="header-menu-mobile-email">
          <i className="icon-s-envelope"></i>
          <span className="header-menu-mobile-email-text" dir="ltr">{formatMessage(messages.COMPANY_EMAIL_ADDRESS)}</span>
        </a>
      </div>
    </div>
  );
};

export default MobileMenu;
